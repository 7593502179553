import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { DenaliVisibilitySelect } from 'src/denali-components/Form/DenaliVisibilitySelect'

export const SelectVisibility = ({
  values,
  disabled=false
}) => {
  return (
    <div className={formStyles.visibilityWrapper}>
      <FontAwesomeIcon icon={values?.visibility.toString() === 'true' ? faEye : faEyeSlash} className={[formStyles.visibilityIcon, values?.visibility.toString() === 'true' ? formStyles.visible : formStyles.notVisible].join(' ')}/>
      <DenaliFormElement id="visibilitySelect" data-testid="visibilitySelect" name="visibility" customOnChange={true}>
        <DenaliVisibilitySelect 
          className={formStyles.visibilitySelect}
          value={values?.visibility.toString()} 
          disabled={disabled}
        />
      </DenaliFormElement>
    </div>
  )
}