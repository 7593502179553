import {
  createContext,
  SVGLineElementAttributes,
  useContext,
  useEffect,
  useMemo
} from 'react'
import {
  SeriesExtentPoints,
  SeriesPoints,
  SeriesPointsCollection
} from '../../algorithm/RBT'
import TooltipCanvas from './TooltipCanvas'
import { TooltipTemplate } from './TooltipTemplate'

export interface TooltipContext<T, V> {
  init: (isExtent: boolean) => SeriesPoints<T, V> | SeriesExtentPoints<T, V>
  subscribe: (callback: (item: V[]) => void) => void
  mouseIn: (event: MouseEvent, xPosition: T) => void
  mouseOut: () => void
  destroy: () => void
}

const defaultContext: TooltipContext<unknown, any> = {
  init: () => new SeriesPoints(new SeriesPointsCollection()),
  subscribe: () => null,
  mouseIn: () => null,
  mouseOut: () => null,
  destroy: () => null
}

const TooltipContext = createContext<TooltipContext<any, any>>(defaultContext)

export function useTooltipContext<T, V>() {
  return useContext<TooltipContext<T, V>>(TooltipContext)
}

interface TooltipNewProps {
  canvasHeight: number
  canvasWidth: number
  children: React.ReactNode
  crosslineProps?: Partial<SVGLineElementAttributes<any>>
  hide?: boolean
  link?: boolean
  Template?: React.FunctionComponent
  triggerBy?: 'axis' | 'item'
  tooltipTemplateProps?: any
  disableClip?: boolean,
  renderingAreaClipPath?: any
  isTranslate?: boolean
}

export default function TooltipProvider({
  canvasHeight,
  canvasWidth,
  children,
  crosslineProps,
  hide = false,
  link = false,
  Template = null,
  triggerBy = 'axis',
  tooltipTemplateProps = {},
  disableClip = false,
  renderingAreaClipPath = '',
  isTranslate=false
}: TooltipNewProps) {
  const instance = useMemo<TooltipContext<number, any>>(
    () => new SeriesPointsCollection(),
    []
  )

  // Clean up the instance before unmount the component
  useEffect(() => () => instance.destroy(), [])

  return (
    <TooltipContext.Provider value={instance}>
      <TooltipCanvas
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        triggerBy={triggerBy}
        hide={hide}
        crosslineProps={crosslineProps}
        linkPointer={link}
        disableClip={disableClip}
      >
        {children}
      </TooltipCanvas>
      <TooltipTemplate
        isTranslate={isTranslate}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        Template={Template}
        hide={hide}
        renderingAreaClipPath={renderingAreaClipPath}
        {...tooltipTemplateProps}
      />
    </TooltipContext.Provider>
  )
}
