import styled from 'styled-components'

export const OpenVPNModalContainer = styled.div`
  .font-13 {
    font-size: 13px;
    font-weight: 600;
  }

  .installer-download-spinner {
    height: 10px;
    width: 10px;
    font-size: 10px;
    border: none;
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline;
  }

  .copy-ip-address-btn {
    background: green;
    padding: 3px 5px;
    color: white;
    border-radius: 3px;
    font-weight: 600;
  }

  .action-btn {
    height: 22px;
    margin-top: 5px;
    border-radius: 1px;
    text-align: center;
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
    background-color: green;
    color: white;

    &.secondary {
      background: #6e6e6e;
      margin-top:20px;
    }
  }

  .icon-unavailable-x {
    color: red;
    font-size: 10px;
  }

  .icon-check {
    color: green;
    font-size: 10px;
  }

  .steps-title {
    font-weight: 700;
    font-size: 15px;
    margin-top: 10px;
  }

  .padding-5 {
    padding: 5px;
  }

  .connection-status-text {
    font-size: 13px;
  }

  ol {
    padding: 10px 30px;
    list-style: number;
  }

  li {
    margin-bottom:2px;
  }

  .download-installer {
    border: none;
    padding: 0;
    color: #335d9e;
    font-size: 12px;
    text-transform: initial;
    background: none;
    height: 30px;
    margin: 0px;

    &:hover {
      background: none;
    }

    &:active {
      color: #0064ff;
    }
  }

  .section-spinner .spin-wheel {
    font-size: 15px;
    font-weight: 800;
  }
`

export const StrongText = styled.p`
  font-weight: 600;
  font-size: 11px;
`

export const SecionHeader = styled.span`
  font-weight: 600;
  font-size: 13px;
`

export const LinkText = styled.span`
  font-weight: 800;
  color: #335d9e;
  cursor: pointer;
  margin-right: 3px;
  font-size: 14px;
`
export const AvailTooltip = styled.div`
display:flex;
gap: 7px;
`
export const AvailHelpTooltip = styled.div`
display:flex;
margin: 2px 10px 0 6px;
 .content {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  overflow: auto;
  text-transform: none;
 }
`
export const AvailabilityIcon = styled.div`
margin-left: 18px;
`