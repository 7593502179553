import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { ACTIONS } from './constants'
import { NEW_DEVICE } from './connectionTypes'
import LocationDetailsModel from 'src/components/legacy/buildingSetup/common/location-details'
import { Offerings } from '../buildingDetails/sections/offerings'
import { offerings } from 'src/components/legacy/common/offerings'
//import memoizeOne from "memoize-one"
import { BUILDING_AREA_UNITS_CONVERSION } from './helpers'
import { parseSalesOffice } from '../helpers'
import moment from 'moment'
import { getConnectionTypeObj } from 'src/components/legacy/common/connection-types'
import store from 'src/redux/store';
import _isEmpty from 'lodash/isEmpty'

const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
const UOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"

export const buildingAreaUnits = [
  { id: 'squareMetre', name: 'Square Meters' },
  { id: 'squareFeet', name: 'Square Feet' }
]

export const defaultBuildingAreaUnits = UOM === 'SI' ? buildingAreaUnits[0] : buildingAreaUnits[1]

export const parseRequest = async (req, regions, refetchLocationTimezone) => {
  let region = req.address.region
  let timeZone = moment.tz.guess()
  req.floorAreaSquareFeet = req.floorAreaSquareFeet
    ? Number(req.floorAreaSquareFeet)
    : 0
  if (
    req.floorAreaSquareFeet &&
    typeof BUILDING_AREA_UNITS_CONVERSION[req.floorAreaUnits] === 'function'
  ) {
    req.floorAreaSquareFeet =
      Math.round(
        BUILDING_AREA_UNITS_CONVERSION[req.floorAreaUnits](
          req.floorAreaSquareFeet
        ) * 10000
      ) / 10000
  }
  if (!region && regions[req.address?.countryCode]?.length > 0) {
    region = regions[req.address.countryCode].find(
      (region) => region.regionCode === req.address.regionCode
    )?.regionName
  }
  if (req.locationLatitude && req.locationLongitude) {
    timeZone = await refetchLocationTimezone({
      lat: req.locationLatitude,
      long: req.locationLongitude,
      timestamp: `${new Date().getTime() / 1000}`
    })
  }
  return {
    name: req.locationName,
    address: req.address.line1,
    city: req.address.city,
    state: region || '',
    postalCode: req.address.postalCode,
    country: req.address.country,
    lat: req.locationLatitude || 0,
    long: req.locationLongitude || 0,
    crmCustId: req.customerCRMSiteId || '',
    floorArea: req.floorAreaSquareFeet,
    isActive: 1,
    // collectedDataTime: AWSTimestamp
    //connectionToleranceInMins: Float
    tz: req.locationTimeZone || timeZone,
    // owners: [String],
    //groups: [String],
    accountName: req.accountName,
    //accessGroups: [String]
    //adminAccessGroups: [String]
    // isActive: req.activeStatusState === "Active",
    accountId: req.organizationId,
    salesOfficeId: req.salesOffice.tisTraneOfficeId,
    salesOfficeCode: req.salesOffice.code,
    salesOfficeName: req.salesOffice.name,
    salesOfficeDistrict: req.salesOffice.district
  }
}

export const parseDeviceResponse = (data) => {
  return data.devices.items.filter(device => device.type !== "BCU").map((_device) => {
    return {
      device: {
        deviceName: _device.name,
        deviceType: _device.type,
        identifier: _device.serial,
        deviceId: _device.id,
        isActive: _device.isActive
      },
      status: { connected: _device.status, lastTimestamp: '' },
      new: false
    }
  })
}

export const parseDeviceRequest = (data) => {
  const devices = data.locationConnectivity.devices
    .map((_device) => {
      const { device: __device } = _device
      if (__device.deviceType && __device.identifier) {
        return {
          buildingId: data.id,
          name: __device.deviceName,
          serial: __device.identifier,
          type: __device.deviceType,
          uiDisplayName: getConnectionTypeObj(__device.deviceType)?.value || '',
          uiShortDisplayName:
            getConnectionTypeObj(__device.deviceType)?.listValue || '',
          ...(__device.deviceId ? { id: __device.deviceId } : {}),
          isActive: __device.isActive || 1
        }
      }
    })
    .filter(Boolean)
  return devices
}

export const parseResponse = (res, countries, regions, selectedUOM) => {
  let selectedCountryCode, selectedRegionCode
  if (countries?.length > 0) {
    selectedCountryCode = countries.find(
      (country) => country.countryName === res.country
    )?.countryCode
  }
  if (regions[selectedCountryCode]?.length > 0) {
    selectedRegionCode = regions[selectedCountryCode].find(
      (region) => region.regionName === res.state
    )?.regionCode
  }

  const floorAreaVal = selectedUOM === 'SI' ? CovertSquareFeetToMetre(Number(res.floorArea)) : res.floorArea
  return {
    address: {
      city: res.city,
      country: res.country,
      countryCode: selectedCountryCode,
      countryDescription: res.country,
      // "countryId": 5,
      line1: res.address,
      postalCode: res.postalCode,
      //  For Upsert building without state(region)
      region: !_isEmpty(res.state) ? res.state : null,
    },
    //"regionCode": "IL",
    //"regionDescription": "Illinois",
    //"regionId": 3,
    locationAddressString: res.address,
    accountName: res.accountName,
    locationName: res.name,
    // "boundToWxStation": true,
    // "createdBy": {
    //     "__typename": "User",
    //     "firstName": null,
    //     "lastName": null,
    //     "username": "infa"
    // },
    // "createdByTimestamp": "2014-10-09T02:28:05Z",
    // "createdByUser": "infa",
    facilityId: res.accountId,
    organizationId: res.accountId,
    locationId: res.id,
    salesOffice: {
      tisTraneOfficeId: res.salesOfficeId,
      code: res.salesOfficeCode || '',
      name: res.salesOfficeName || '',
      district: res.salesOfficeDistrict || '',
      officeFullName: parseSalesOffice(res)
    },
    // "sourceEnvironment": "prod",
    // "statusName": "TisAudited",
    customerCRMSiteId: res.crmCustId,
    locationLatitude: res.lat,
    locationLongitude: res.long,
    locationTimeZone: res.tz,
    floorAreaUnits: res.floorAreaUnits,
    floorAreaSquareFeet: floorAreaVal, // res.floorArea,
    locationConnectivity: {
      devices: parseDeviceResponse(res)
    }
    //"connectivityTypes": "CM",
    //"weatherData": "Yes",
    // "offeringsString": "BP, AM, BP, EP",
    // "createdOn": "October 9, 2014"
  }
}

export const prepareInitialValues = (
  allTisOfferings,
  editAction,
  buildingData,
  countries,
  regions
) => {
  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const UOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"
  const ldefaultBuildingAreaUnits = UOM === 'SI' ? buildingAreaUnits[0] : buildingAreaUnits[1]
  const details =
    editAction === ACTIONS.CREATE
      ? new LocationDetailsModel({
          locationConnectivity: {
            devices: [NEW_DEVICE]
          },
          floorAreaUnits: ldefaultBuildingAreaUnits.id
        })
      : parseResponse(buildingData, countries, regions, UOM)
  details.organizationId = `${details.organizationId}`
  if (!details.floorAreaUnits) {
    details.floorAreaUnits = ldefaultBuildingAreaUnits.id
  }
  const necessaryOfferingData = {
    startDate: '',
    expirationDate: '',
    contractNumber: '',
    checked: false
  }
  if (editAction === ACTIONS.EDIT) {
    const devices = _get(details, 'locationConnectivity.devices') || []
    if (!devices.length) {
      details.locationConnectivity = {
        ...details.locationConnectivity,
        devices: [NEW_DEVICE]
      }
    }
  }

  return {
    ..._omit(details, 'accountId'),
    offeringSourceMaps: Object.values(allTisOfferings.byId)
      .filter((offering) => offering.activeStatus)
      .reduce((resultOfferingsList, offering) => {
        const availableLocationOfferings = (
          details.offeringSourceMaps || []
        ).filter(({ shortName }) => shortName === offering.shortName)
        const offeringsToAdd = availableLocationOfferings.length
          ? availableLocationOfferings.map((availableLocationOffering) => ({
              ...necessaryOfferingData,
              ...offering,
              ...availableLocationOffering,
              checked:
                availableLocationOffering.checked !== undefined
                  ? availableLocationOffering.checked
                  : true
            }))
          : [{ ...necessaryOfferingData, ...offering }]

        return [...resultOfferingsList, ...offeringsToAdd].map((offering) => {
          offering.startDate = Offerings.getFormattedDate(
            offering.startDate || moment()
          )
          if (offering.expirationDate) {
            offering.expirationDate = Offerings.getFormattedDate(
              offering.expirationDate
            )
          }
          if (offering.shortName === offerings.tenantServices.shortName) {
            !offering.expirationDate &&
              (offering.expirationDate = Offerings.getFormattedDate(
                moment(moment()).add(1, 'y')
              ))
            !offering.subdomain && (offering.subdomain = '')
          } else {
            !offering.expirationDate &&
              (offering.expirationDate = Offerings.getFormattedDate(moment()))
          }
          return offering
        })
      }, [])
  }
}

export const CovertSquareFeetToMetre = (value) => {
  return Math.round(value / 10.7639 * 10000) / 10000
}
