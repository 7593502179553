import clsx from 'clsx'
import { Legend, LegendsGroup } from '../types'

interface ClickEvent {
  onClick: (args: any) => void
}

export function LegendGroupTemplate(props: LegendsGroup) {
  const { group, items = [], onClick = () => null } = props
  const isGroupItem = group?.isGroup

  return (
    <div className="t-chart-legend-item-group">
      {isGroupItem && (
        <LegendItemTemplate {...group} onClick={onClick} isGroup />
      )}
      {items.map((item) => {
        return (
          <LegendItemTemplate key={item.uuid} {...item} onClick={onClick} />
        )
      })}
    </div>
  )
}

export function LegendItemTemplate(props: Legend & ClickEvent) {
  const {
    clickable,
    containerClassName,
    containerStyle,
    disabled,
    hide,
    Icon,
    iconProps,
    iconContainerClassName,
    isGroup,
    onClick,
    Template,
    text,
    textClassName,
    textStyle
  } = props

  if (hide) return null

  if (Template)
    return (
      <div
        onClick={() => onClick({ ...props })}
        className={clsx('t-chart-legend-item', {
          't-chart-legend-item-group-title': isGroup,
          't-chart-disabled-item': disabled,
          't-chart-clickable-item-true': clickable,
          't-chart-clickable-item-false': !clickable
        })}
      >
        <Template {...props} />
      </div>
    )

  return (
    <div
      className={clsx('t-chart-legend-item', containerClassName, {
        't-chart-disabled-item': disabled,
        't-chart-legend-item-group-title': isGroup
      })}
      style={containerStyle}
    >
      {!isGroup && Icon && (
        <div
          className={clsx('t-chart-legend-item-icon', iconContainerClassName)}
        >
          {Icon && <Icon {...iconProps} />}
        </div>
      )}
      <div
        onClick={() => onClick({ ...props })}
        className={clsx('t-chart-legend-item-text', textClassName, {
          't-chart-clickable-item-true': clickable,
          't-chart-clickable-item-false': !clickable
        })}
        style={textStyle}
      >
        <span title={text}>{text}</span>
      </div>
    </div>
  )
}
