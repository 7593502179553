export type CollapsibleComponentProps = {
  initialState?: boolean
  // If the state of the collapsible should be stored in local storage.
  useLocalStorage?: boolean
  // The key to use in local storage.
  localStorageKey?: string
  className?: string
  children: React.ReactNode
  // A function that should return a node based on the collapsed state, also passes a callable
  // function that should be called to change the state.
  headerBuilder?: ((isCollapsed: boolean, onToggle: () => void) => React.ReactNode)
  // If the collapsible header should have a larger set height. 50% taller than the default.
  style?: CollapsibleStyle

  // Optional callbacks for when the collapsible is opened or closed. Only used when not providing the
  // header builder and the default header is used.
  header?: React.ReactNode
  onOpen?: () => void
  onClose?: () => void
  // Optional callback for when the collapsible is toggled.
  onToggle?: (isCollapsed: boolean) => void
}

export enum CollapsibleStyle {
  default = 'default',
  tall = 'tall',
}