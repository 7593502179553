import { Dialog } from 'src/denali-components/Dialog/Dialog'
import dialogStyles from 'src/denali-components/Dialog/dialog.module.scss'
import { Button, Input, Link, SelectField } from '@aws-amplify/ui-react'
import { TranslateComponent } from 'src/common/translations'

export const DialogChartSettings = ({
  toggleModal,
  children,
  modalOpen,
  chartView,
  value,
  floorArea,
  updateFloorArea,
  updateUOM,
  floorAreaUnitOptions,
  settingsConfig,
  setDefaults,
  submit
}) => {
  return (
    <>
      <Link onClick={toggleModal}>{children}</Link>
      {modalOpen && (
        <Dialog
          title='Chart Settings'
          onClose={toggleModal}
          width='500px'
          className={dialogStyles.withDivider}
          data-testid='chartSettingsDialog'
        >
          <span className={dialogStyles.subHeader}>
            Total Building Area*
          </span>
          {chartView && (
            <div className={dialogStyles.dialogRow}>
              <Input
                className={dialogStyles.denaliInput}
                type="number"
                placeholder={value || ''}
                value={floorArea ? floorArea : ''}
                step="any"
                min="0"
                onChange={(e) => updateFloorArea(e.target.value)}
              />
              <SelectField
                className={dialogStyles.denaliSelect}
                label="Unit"
                labelHidden
                options={Object.keys(floorAreaUnitOptions)}
                onChange={updateUOM}
              />
            </div>
          )}

          {settingsConfig && settingsConfig.inputMessage && chartView && (
            <div className={dialogStyles.message}>
              {settingsConfig.inputMessage}
            </div>
          )}

          <div className={dialogStyles.dialogRow}>
            <span className={dialogStyles.required}><TranslateComponent>* required fields</TranslateComponent></span>
            <div className={dialogStyles.actions}>
              <Button
                onClick={setDefaults}
                className='button-gray'
              >
                <TranslateComponent>Cancel</TranslateComponent>
              </Button>
              <Button
                onClick={submit}
                type='submit'
                disabled={!floorArea && chartView}
              >
                <TranslateComponent>Save</TranslateComponent>
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
