import styled from 'styled-components'
import COLORS from 'src/components/layouts/colors.json'
export const InputWrapper = styled.div`
  margin: 9px 0 0;
  padding: 0 6px 9px;
  display: flex;
  flex-direction: column;
  input{
    margin:0;
  }
  p {
    color: ${COLORS.RED};
    font-size: 12px !important;
    font-weight: 600;
    font-style: italic;
  }
`

export const AccountExistError = styled.div`
  display:flex;
  gap:.5rem;
  color: ${COLORS.RED};
  align-items: center;
  >svg path{
    fill: ${COLORS.RED};
  }
`

export const InputEmailWrapper = styled.div`
  margin: 9px 0 0;
  padding: 0 6px 9px;
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    input {
      width: 70%;
    }
  }
  .icon-error {
    color: ${COLORS.RED};
  }
  p {
    color: ${COLORS.RED};
    font-size: 12px !important;
    font-weight: 600;
    font-style: italic;
  }
`

export const TableWrapper = styled.div`
  position: relative;
`

export const RightSideWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  button {
    margin-right: 5px;
  }
`
export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  label {
    text-transform: uppercase;
  }
`

export const AlarmLabel =  styled.div`
  font-size:12px;
  margin-bottom:1rem;
`

export const PriorityInput = styled.div`
  display:flex;
  align-items:center;
  gap:0.5rem;
  margin-bottom:1rem;
  input{
    margin:0px;
  }
`

export const IconButton = styled.button`
  background-color:${(props) => props.color};
  color:#fff;
  border:none;
  padding:4px 6px;
  i{
    padding:0;
  }
`