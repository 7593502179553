import _get from 'lodash/get'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from 'src/hooks/APIHooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { ConsultationViewWrapper, DateRangeContainer } from '../styles'
import { Container, Content } from 'src/components/layouts/index'
import Header from 'src/components/legacy/components/header/header'
import Toolbar from 'src/components/legacy/components/toolbar/toolbar'
import InlineHelp from 'src/components/legacy/components/inline-help/inline-help'
import classNames from 'classnames'
import { MODES } from '../constants'
import LabelBlank from 'src/components/LabelBlank/index'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  MAX_PAGE_WIDTH,
  formatOrganizationOrBuildings
} from 'src/components/legacy/common/consultation.js'
import COLORS from 'src/components/legacy/common/colors.json'
import { pickDeep } from 'src/components/legacy/common/helpers.js'
import TextEditor from 'src/components/text-editor'
import ConsultationToggleBox from '../consultation-blocks/consultationToggleBox/index'
import Highlights from '../consultation-blocks/highlights/index'
import { CHART_TYPES } from '../consultation-blocks/chart-image/common'
import ChartImage from '../consultation-blocks/chart-image/index'
import {
  GET_CONSULTATION,
  SEARCH_BUILDINGS_BY_ACCOUNT_ID,
  GET_CONSULTATION_ASSUMPTION_VALUES
} from '../graphql'
import WhereWeGoFromHere from '../consultation-blocks/where-we-go-from-here/index'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import ConsultationDetailDownload from '../consultation-blocks/consultation-detail-download/consultation-detail-download'
import { useAttachment } from 'src/hooks/attachment'
import { useDocumentsAndReportsContext } from '../DocumentsAndReportsContextProvider'
import { MODAL_TYPE } from '../../opportunities/constants'
import { consultationType, fetchConsultationWithImages } from '../helper'
import { ACTIONS } from 'src/constants'
import WhatWeSaved from 'src/pages/documentsAndReports/WhatWeSaved'
import ActionTracking from 'src/chart-library/Charts/ActionTracking'
import {
  GET_ISSUES_BY_BUILDING_ID,
  GET_OPPORTUNITIES_BY_BUILDING_ID
} from 'src/pages/opportunities/graphql'
import {
  getChartData,
  Error_Message_KeyMatricsChart,
  Error_Message_ActionTackingChart,
  Error_Message_OpportunityAndIssues_Missing,
  formatedData1,
  formatAssumptionValues,
  getActionChartData,
  chartConfigActionTracking,
  checkActionChartLength,
  getConsultationTestName
} from './helperFunction'
import { 
  SEARCH_ISSUES_BY_BUILDING_IDS, 
  SEARCH_OPPORTUNITIES_BY_BUILDING_IDS 
} from 'src/pages/opportunities/graphql'
import moment from 'moment'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import ErrorAlert from 'src/components/ErrorAlert'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'



const ConsultationView = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const [consultationDetails, setConsultationDetails] = useState<any>({})
  const { setModalType, setDocReportRow, setMode, error, setError } =
    useDocumentsAndReportsContext()
  const [opportunityListData, setOpportunityListData] = useState<any>([])
  const [issuesListData, setIssuesListData] = useState<any>([])
  const [buildingId, setBuildingId] = useState('')
  const [t] = useTranslation()
  const { width } = useBreakPoint()
  const { downloadFileURL } = useAttachment()
  const [assumptionValues, setAssumptionValues] = useState(null)
  const [chartData, setChartData] = useState([])
  const [chartActionData, setChartActionData] = useState([])
  const [formatedGoalData, setFormatedGoalData] = useState({})
  const userAccess = useSelector(selectUserAccess)
  const inlineHelpText = [
    {
      heading: t('consultations:Consultation'),
      body: t('consultations:ConsultationInlineHelpDesc')
    }
  ]

  const { loading: loadingBuildings, refetch: refetchSearchBuildingsList } =
    useQuery({
      query: SEARCH_BUILDINGS_BY_ACCOUNT_ID,
      disableInitialLoad: true,
      errorPolicy: 'ignore',
      dataPath: 'data.searchBuildings.items'
    })

  const { loading, refetch: refetchConsultationDetails } = useQuery({
    query: GET_CONSULTATION,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getConsultation',
    onSuccess: (consultation) => {
      if (consultation) {
        if (
          !consultation?.visibility &&
          !accessControlFunc({
            id: 'tc.pages.documents-and-reports.view-private',
            userAccess
          })
        ) {
          setError({
            errorMsg: 'The consultation you are looking for is not accessible.',
            isError: true
          })
        }
        
        const newConsultation = JSON.parse(JSON.stringify(consultation))
        const buildingIDs = consultation?.buildingIds ?? []
        handleConsultationData(newConsultation, buildingIDs);
        (async () => {
          await refetchSearchBuildingsList({
            filter: { accountId: { eq: consultation?.accountId } },
            limit: 1000
          }).then(async (b) => {
            const locationDetails =
              b
                ?.filter((f) => buildingIDs?.includes(f?.key))
                ?.map((m) => ({ locationName: m?.value })) ?? []
            newConsultation.account = {
              id: newConsultation?.accountId,
              name: b?.[0]?.accountName || ''
            }
            newConsultation.locations = locationDetails
            setBuildingId(buildingIDs[0])
          })
        })()
      }else{
        setError({
          errorMsg: 'The consultation you are looking for does not exist.',
          isError: true
        })
      }
    }
  })

  const {
    data: issuesFindingsList,
    refetch: refetchSearchIssuesFindingsList,
    loading: loadingIssues
  } = useQuery({
    query: SEARCH_ISSUES_BY_BUILDING_IDS,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.searchIssues.items'
  })

  const {
    data: oppoData,
    refetch: refetchSearchOpportunityList,
    loading: loadingOpportunities
  } = useQuery({
    query: SEARCH_OPPORTUNITIES_BY_BUILDING_IDS,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data',
  })

  const {
    data: assumptionValuesData,
    refetch: fetchRecentAssumptionValues, 
    loading:loadingAssumption
  } = useQuery({
    query: GET_CONSULTATION_ASSUMPTION_VALUES,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getConsultationValueAssumption'
  })

  useEffect(() => {
    if (id) {
      setMode(ACTIONS.VIEW)
      refetchConsultationDetails({ id: id })
    }
  }, [id])

  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId)
        .then((res) => {
          item.original = res
          item.enabled = true
        })
        .catch((err) => {
          console.log('err')
        })
    }
    return item
  }

  const handleConsultationData = async (consultation, buildingIds) => {
    const variable = { id: consultation?.assumptionValueId }
    if (variable?.id) {
      const variables = {
        filter: {
          or: buildingIds?.map(id => {
            return {
              "buildingId": { "eq": id }
            }
          })
        },
        limit: 1000,
        sort: [
          {
            "direction": "asc",
            "field": "title"
          }
        ]
      }
      refetchSearchIssuesFindingsList(variables)
      refetchSearchOpportunityList(variables)
      fetchRecentAssumptionValues(variable)
    }
    const consultationData = await fetchConsultationWithImages(
      consultation,
      fetchImage
    )
    setConsultationDetails(consultationData)
  }

  useEffect(()=> {
    setAssumptionValues(assumptionValuesData)
  }, [assumptionValuesData])

  useEffect(()=> {
    setOpportunityListData(
      oppoData?.searchOpportunities?.items?.filter((it)=>(it?.isVisible === true)) || []
    )
  }, [oppoData])

  useEffect(() => {
    setIssuesListData(
      issuesFindingsList
        ?.filter((it) => (it?.opportunities?.items?.length === 0)&&(it?.isVisible === true))
        ?.map((it) => ({ ...it, type: 'finding' })) || []
    )
  }, [issuesFindingsList])


  useEffect(() => {
    if (!assumptionValues || (!issuesListData?.length && !opportunityListData?.length)) {
      return
    }
  
    const renderKpiData = formatAssumptionValues(assumptionValues);
    const { startDate: rangeFrom, endDate: rangeTo } = consultationDetails || {}
  
    // For KeyMetric Chart
    const { chartData = [], formatedGoalData = {} } = getChartData({
      data: formatedData1(assumptionValues),
      renderKpiData,
      rangeFrom,
      rangeTo,
      opportunityListData,
      issuesListData,
      assumptionValues,
    }) || {};
    setChartData(chartData);
    setFormatedGoalData(formatedGoalData);
  
    // For ActionTrackingChart
    const { chartActionData = [] } = getActionChartData(
      chartConfigActionTracking,
      renderKpiData,
      opportunityListData,
      issuesListData,
      rangeFrom,
      rangeTo
    ) || {};
    setChartActionData(chartActionData);
  }, [issuesListData, assumptionValues, opportunityListData, consultationDetails]);  
  

  const displayPDFDownload = () => {
    return (
      consultationDetails && (
        <ConsultationDetailDownload downloadData={consultationDetails} />
      )
    )
  }

  const handleNavigation = (path, location, buildingId) => {
    if (location && buildingId) {
      navigate(`/${path}?organization=${location}&location=${buildingId}&startDate=${consultationDetails?.startDate}&endDate=${consultationDetails?.endDate}&consultationId=${id}`)
    }
  }
  const chartImageProps = pickDeep(consultationDetails, [
    'account',
    'account',
    'id',
    'settings.valueAssumption',
    'startDate',
    'endDate'
  ])
  const chartLoading = loadingAssumption || loadingOpportunities || loadingIssues

  if(error?.isError){
    return <ErrorAlert errorMsg={error?.errorMsg} /> 
  }

  if (loading || loadingBuildings || chartLoading) {
    return <Spinner />
  }

  const handleDelete = () => {
    setDocReportRow({
      id: consultationDetails?.id,
      title: consultationDetails?.title,
      filterType: consultationType,
      accountId: consultationDetails?.accountId,
      buildingIds: consultationDetails?.buildingIds
    })
    setMode(ACTIONS.DELETE)
    setModalType(MODAL_TYPE.CONFIRMATION)
  }
  return consultationDetails?.id ? (
    <>
      <ConsultationViewWrapper
        data-testid={getConsultationTestName('detail-view')}
      >
        <div className="detail-view-page">
          <Container
            sColumns={12}
            mColumns={12}
            lColumns={12}
            xlColumns={12}
            width={width}
            maxWidth={`${MAX_PAGE_WIDTH}px`}
          >
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <Header
                hideToolBar={false}
                titleTools={
                  <>
                    <InlineHelp
                      inlineHelpText={inlineHelpText}
                      inlineHelpHeading={t('consultations:Consultation')}
                    />
                      <span
                        className={`icon-${
                          consultationDetails?.visibility
                            ? 'visible green'
                            : 'hidden'
                        } tooltip-container`}
                      >
                        <div className="tooltip center-top">
                          {consultationDetails?.visibility
                            ? t('opportunities:Visible')
                            : t('opportunities:NotVisibleToCustomer')}
                        </div>
                      </span>
                    
                  </>
                }
                pageTitle={consultationDetails?.title}
              >
                <Toolbar>
                  <AccessControl id="tc.pages.documents-and-reports.consultation.add">
                    <button
                      className="action small"
                      data-testid="consultation-add"
                      onClick={() => {
                        navigate({
                          pathname: `/reports/Consultations/add`,
                          search: `?organization=${consultationDetails?.accountId
                            }${consultationDetails?.buildingIds[0]
                              ? `&location=${consultationDetails?.buildingIds[0]}`
                              : ''
                            }`
                        })
                      }}
                    >
                      {t('components:toolbar>CreateNew')}
                    </button>
                  </AccessControl>
                  <div data-testid="consultation-download">
                    {displayPDFDownload()}
                  </div>
                  <AccessControl id="tc.pages.documents-and-reports.consultation.copy">
                    <span
                      className={classNames(
                        'icon',
                        'icon-duplicate',
                        'tooltip-container'
                      )}
                      data-testid="consultation-copy"
                      onClick={() => {
                        navigate({
                          pathname: `/reports/Consultations/copy/${consultationDetails?.id}`,
                          search
                        })
                      }}
                    >
                      <div className="tooltip dark center-top">
                        {t('components:toolbar>Copy', { item: 'Consultation' })}
                      </div>
                    </span>
                  </AccessControl>
                  <AccessControl id="tc.pages.documents-and-reports.consultation.edit">
                    <span
                      className={classNames(
                        'icon',
                        'icon-edit',
                        'tooltip-container'
                      )}
                      data-testid="consultation-edit"
                      onClick={() => {
                        navigate({
                          pathname: `/reports/Consultations/edit/${consultationDetails?.id}`,
                          search
                        })
                      }}
                    >
                      <div className="tooltip dark center-top">
                        {t('components:toolbar>Edit', { item: 'Consultation' })}
                      </div>
                    </span>
                  </AccessControl>
                  <AccessControl id="tc.pages.documents-and-reports.consultation.delete">
                    <span
                      data-testid="consultation-delete"
                      className={classNames(
                        'icon',
                        'icon-trash2',
                        'tooltip-container'
                      )}
                      {...(true && {
                        onClick: () => handleDelete()
                      })}
                    >
                      <div className="tooltip dark left-top">
                        {t('components:toolbar>Delete', { item: 'Consultation' })}
                      </div>
                    </span>
                  </AccessControl>
                </Toolbar>
              </Header>
            </Content>
            <Content
              xlColumn={8}
              lColumn={7}
              mColumn={7}
              sColumn={12}
              border={'none'}
              width={width}
              className="created-info"
            >
              <LabelBlank
                className="created-info-item"
                labelText={'Organization or Building name:'}
                value={formatOrganizationOrBuildings(consultationDetails)}
                testName="OrganizationOrBuildingName"
              />
              <LabelBlank
                className="created-info-item"
                labelText={`${t('common:DateRange')}:`}
                value={`${consultationDetails?.startDate} - ${consultationDetails?.endDate}`}
                testName="DateRange"
              />
              <LabelBlank
                className="created-info-item"
                labelText={`${t('common:Status')}:`}
                value={consultationDetails?.status}
                testName="Status"
              />
            </Content>
            <Content
              xlColumn={4}
              lColumn={5}
              mColumn={5}
              sColumn={12}
              width={width}
              border={'none'}
              className="created-user-info"
            >
              {consultationDetails?.contact && (
                <p data-testid="contact">{consultationDetails?.contact}</p>
              )}
              {consultationDetails?.phoneNumber && (
                <span data-testid="phone">
                  {consultationDetails?.phoneNumber}
                </span>
              )}
              {consultationDetails?.email && (
                <span data-testid="email">{consultationDetails?.email}</span>
              )}
            </Content>
            {consultationDetails?.theBigPictureDisplay &&
              (consultationDetails?.theBigPictureTitle ||
                consultationDetails?.theBigPictureValueDetails?.value?.blocks
                  ?.map(({ text }) => text)
                  .join('')) && (
                <Content
                  xlColumn={12}
                  lColumn={12}
                  mColumn={12}
                  sColumn={12}
                  width={width}
                  background={COLORS.WHITE}
                >
                  <ConsultationToggleBox
                    title={consultationDetails?.theBigPictureTitle}
                    showExpandIcon={false}
                  >
                    <TextEditor
                      content={
                        consultationDetails.theBigPictureValueDetails.value
                      }
                      isViewMode={true}
                    />
                  </ConsultationToggleBox>
                </Content>
              )}
            {consultationDetails?.showHighlight && (
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
                border={'none'}
                className="consultation-highlights"
              >
                <Highlights
                  highlights={consultationDetails?.highlights}
                  mode={MODES.VIEW}
                />
              </Content>
            )}
            {consultationDetails?.whatWeHaveSavedDisplay && (
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
                background={COLORS.WHITE}
              >
                {consultationDetails?.whatWeHaveSavedType ===
                "Image Upload" &&
                <ChartImage
                  mode={MODES.VIEW}
                  chartType={CHART_TYPES.WHAT_WE_HAVE_SAVED}
                  {...chartImageProps}
                  settings={{
                    ...consultationDetails?.whatWeHaveSaved,
                    title: consultationDetails?.whatWeHaveSavedTitle
                  }}
                /> }
                <DateRangeContainer>
                  <div className="label-dateRange">Date Range</div>
                  <div className="label-dateRange">{`${moment(
                    consultationDetails?.startDate
                  ).format(DATE_FORMAT)} - ${moment(
                    consultationDetails?.endDate
                  ).format(DATE_FORMAT)}`}</div>
                </DateRangeContainer>
                {consultationDetails?.whatWeHaveSavedType ===
                'Key Metric Savings' && (
                  <div>
                    {assumptionValues ? (
                      <div>
                        {chartData?.length ? (
                          <WhatWeSaved
                            chartData={chartData}
                            chartGoalData={formatedGoalData}
                          />
                        ) : (
                          Error_Message_OpportunityAndIssues_Missing
                        )}
                      </div>
                    ) : (
                      Error_Message_KeyMatricsChart
                    )}
                  </div>
                ) }
              </Content>
            )}
            {consultationDetails?.whereHaveYouBeenDisplay && (
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
                background={COLORS.WHITE}
              >
                <ChartImage
                  mode={MODES.VIEW}
                  chartType={CHART_TYPES.WHERE_WE_HAVE_BEEN}
                  {...chartImageProps}
                  settings={{
                    ...consultationDetails.whereWeHaveBeen,
                    title: consultationDetails?.whereHaveYouBeenTitle
                  }}
                />
              </Content>
            )}
            {consultationDetails?.whatWeHaveDoneDisplay && (
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
                background={COLORS.WHITE}
              >
                 {consultationDetails?.whatWeHaveDoneType ===
                "Image Upload" &&
                <ChartImage
                  mode={MODES.VIEW}
                  chartType={CHART_TYPES.WHAT_WE_HAVE_DONE}
                  {...chartImageProps}
                  settings={{
                    ...consultationDetails?.whatWeHaveDone,
                    title: consultationDetails?.whatWeHaveDoneTitle
                  }}
                /> }
                <DateRangeContainer>
                  <div className="label-dateRange">Date Range</div>
                  <div className="label-dateRange">{`${moment(
                    consultationDetails?.startDate
                  ).format(DATE_FORMAT)} - ${moment(
                    consultationDetails?.endDate
                  ).format(DATE_FORMAT)}`}</div>
                </DateRangeContainer>
                {consultationDetails?.whatWeHaveDoneType ===
                "Action Tracking" && (
                  <div>
                    {assumptionValues ? (
                      <div data-testid="action-tracking-wrapper">
                        {checkActionChartLength(chartActionData) ? (
                          <ActionTracking {...chartActionData} />
                        ) : (
                          Error_Message_OpportunityAndIssues_Missing
                        )}
                      </div>
                    ) : (
                      Error_Message_ActionTackingChart
                    )}
                  </div>
                )}
              </Content>
            )}
            {consultationDetails?.whereWeGoFromHereDisplay && (
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
                background={COLORS.WHITE}
              >
                <WhereWeGoFromHere
                  mode={MODES.VIEW}
                  width={width}
                  opportunities={consultationDetails?.opportunities}
                  settings={consultationDetails?.whereWeGoFromHere}
                />
              </Content>
            )}
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              width={width}
              border={'none'}
              className="consultation-footer"
            >
              <button
                onClick={() =>
                  handleNavigation(
                    `${PAGE_NAMES.FINDINGS}`,
                    consultationDetails?.accountId,
                    consultationDetails?.buildingIds[0]
                  )
                }
                className="action small included-findings"
              >
                view Included Findings
              </button>
              <button
                onClick={() =>
                  handleNavigation(
                    `${PAGE_NAMES.OPPORTUNITIES}`,
                    consultationDetails?.accountId,
                    consultationDetails?.buildingIds[0]
                  )
                }
                className="action small included-opportunities"
              >
                view Included Opportunities
              </button>
              {displayPDFDownload()}
            </Content>
          </Container>
        </div>
      </ConsultationViewWrapper>
    </>
  ) : (
    <>
      <Spinner />
    </>
  )
}

export default ConsultationView
