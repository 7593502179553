import Table from '../../components/Table/graphQLTable'
import {
  // SEARCH_CONTRACTOR_COMPANIES,
  SEARCH_BUILDINGS,
  SEARCH_SALES_OFFICES,
  SEARCH_SALES_OFFICE_CONTRACTOR_COMPANIES
} from './graphql/queries'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { TranslateComponent } from 'src/common/translations'
import { useNavigate } from 'react-router'
import { pages } from 'src/pages/pages.js'
import Select from 'src/denali-ui/components/Select'
import Option from 'src/denali-ui/components/Option'
import { useEffect, useState } from 'react'
import { NewStyles } from 'src/NewStyles'
import { ACTIONS } from 'src/constants'
import { AccessControl } from 'src/components/accessControl'


export const ServiceCompanies = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])
  const [selectedFilter, setSelectedFilter] = useState("Contractor Companies")
  const nameHeadings = [
    {
      name: 'name',
      title: <TranslateComponent>{selectedFilter === "Contractor Companies" ? "Contractor Company/Building" : "Sales Office/Building" }</TranslateComponent>,
      key: 'name',
      maxWidth: '120px',
      onDataClick: (rowData) => {
        if (selectedFilter === "Contractor Companies") {
          return navigate(
            pages['Service Provider Management Details'].href.replace(':id', rowData.id).replace(":type", "ContractorCompany")
          )
        } else if (selectedFilter === "Sales Offices") {
          return navigate(
            pages['Service Provider Management Details'].href.replace(':id', rowData.id).replace(":type", "SalesOffice")
          )
        } else {
          return navigate(
            pages['Building Setup/:id'].href.replace(':id', rowData.id)
          )
        }
      },
    },
    {
      name: 'address',
      title: <TranslateComponent>address</TranslateComponent>,
      key: 'address',
      maxWidth: '120px'
    },
    {
      name: 'city',
      title: <TranslateComponent>City</TranslateComponent>,
      key: 'city',
      maxWidth: '120px'
    },
    {
      name: 'state',
      title: <TranslateComponent>State/Region</TranslateComponent>,
      key: 'state',
      maxWidth: '120px'
    },
    {
      name: 'country',
      title: <TranslateComponent>Country</TranslateComponent>,
      key: 'country',
      maxWidth: '120px'
    },
    {
      name: 'accountName',
      title: <TranslateComponent>Organization</TranslateComponent>,
      key: 'accountName',
      maxWidth: '120px'
    },
    {
      name: 'actions',
      key: 'actions',
      disabled: true,
      alignRight: true
    },
  ]
  return (
    <NewStyles>
      <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            key={`pageHeader${selectedFilter}`}
            pageTitle={<TranslateComponent>Service Provider Management</TranslateComponent>}
            titleTools={<AccessControl id={ selectedFilter === "Contractor Companies" ? "tc.pages.service-provider-management.contractor-company-create" : "tc.pages.service-provider-management.sales-office-create"}>
            <Button onClick={() => {
                let url = pages['Service Provider Management Add'].href
                if (selectedFilter === "Contractor Companies") {
                  url = url.replace(":type", "ContractorCompany")
                } else {
                  url = url.replace(":type", "SalesOffice")
                }
                navigate(
                  url,
                  {state:{mode:ACTIONS.ADD}}
                 )
              }}>
                <TranslateComponent>{selectedFilter === "Contractor Companies" ? "Create Contractor Company" : "Create Sales Office"}</TranslateComponent> {' '}
              </Button>
          </AccessControl>
            }
          />
        </Content>
        <Content>
          <Select
            onChange={(e) => {
              setSelectedFilter(e.target.value)
            }}
            placeholder="Default state"
            popoverConfig={{
              strategy: 'fixed'
            }}
            value={selectedFilter}
          >
            <Option value="Contractor Companies">Contractor Companies</Option>
            <Option value="Sales Offices">Sales Offices</Option>
          </Select>
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {selectedFilter === "Contractor Companies" ? <Table
            key="contractorCompanyTable"
            header={nameHeadings}
            showSpinner={true}
            query={{
              query: SEARCH_SALES_OFFICE_CONTRACTOR_COMPANIES,
              dataPath: 'data.searchSalesOffices',
              variables: {filter: { or: [
                {type: {eq: "ASC-Contractor"}},
                {type: {eq: "LC3-Contractor"}}
              ]
              }}
            }}
            search={true}
            searchFields={['name']}
            subData={{
              query: SEARCH_BUILDINGS,
              filters: {
                type: 'eq',
                subFieldName: 'salesOfficeId',
                sourceFilterValue: 'id'
              }
            }}
          />
          : <Table
          key="salesOfficeTable"
          header={nameHeadings}
          showSpinner={true}
          query={{
            query: SEARCH_SALES_OFFICES,
            dataPath: 'data.searchSalesOffices',
            variables: {filter: {
              and: [
                {type: {ne: "ASC-Contractor"}},
                {type: {ne: "LC3-Contractor"}}
              ]
            }}
          }}
          search={true}
          searchFields={['name']}
          subData={{
            query: SEARCH_BUILDINGS,
            filters: {
              type: 'eq',
              subFieldName: 'salesOfficeId',
              sourceFilterValue: 'id'
            }
          }}
        />
    }
        </Content>
      </Container>
    </NewStyles>
  )
}
