import { TranslateComponent } from "src/common/translations"
import styles from "./table.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Chip = ({
  text=null, 
  color=ChipColor.default, 
  children=null, 
  size="", 
  weight=ChipWeight.default, 
  icon=null
}) => {
  return (
    <div className={styles.chip} data-color={color} data-size={size} data-weight={weight}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {text != null && <TranslateComponent>{truncateText(text)}</TranslateComponent>}
      {children}
    </div>
  )
}

export enum ChipColor {
  none='transparent',
  default='default',
  blue='blue',
  orange='orange',
  purple='purple',
  green='green',
  darkGreen='darkGreen',
  black='black',
  transparentBlack='transparentBlack',
  transparentGray='transparentGray',
  transparentWhite='transparentWhite',
  gold='gold',
  pink='pink',
  red='red',
  redInverse='redInverse',
}

export enum ChipWeight {
  default='default',
  w600='600',
}

const truncateText = (text: string) => {
  return text.length > 30 ? text.substring(0, 30) + '...' : text
}