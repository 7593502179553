import { Dashboard, Dashboards } from './dashboards'
import { Widgets } from './widgets'
import { OrgManagement } from './organizationManagement'
import { OrgManagementAdd } from './organizationManagement/createOrganization'
import { ConnectToADevice } from './connectToADevice'
import { RoleManagement } from './roleManagement'
import { RoleManagementAdd } from './roleManagement/createRole'
import { PermissionManagement } from './permissionManagementOld'
import { PermissionManagementAdd } from './permissionManagementOld/createPermission'
import { CreateBuilding, BuildingDetails, BuildingSetup } from './buildingSetup'
import { EquipmentSetup, EquipmentDetails } from './equipmentSetup'
import { Notes } from './notes'
import { RateStructureLibrary } from './rateStructureLibrary'
import { CreateRateStructure } from './rateStructureLibrary/createRateStructure'
import { CreateCharge } from './rateStructureLibrary/createCharge'
import { Opportunities } from './opportunities'
import { UtilityBillData } from './utilityBillData'
import { EnergyIntensity } from './energyIntensity'
import { DocumentsAndReports } from './documentsAndReports'
import { AutomatedTestSettings } from './automatedTestSettings'
import { AutomatedTestSuppressions } from './automatedTestSuppressions'
import { EventMarkers } from './eventMarkers'
import { ApplicationManagement } from './applicationManagement'
import { UserTypeManagement } from './userTypeManagement'
import { GroupManagement } from "./groupManagement"
import { IssuesFindings } from './issuesFindings'
import { BaseLines } from './baselines'
import { BuildingData } from './buildingData'
import { UserManagement } from './userManagement/userList'
import { UserManagementDetails } from './userManagement/userDetails'
import { CreateUser } from './userManagement/createUser/user-management-create-user-new'
import PageWrapper from 'src/components/pageWrapper'
import { ServiceCompanies } from './serviceCompanies'
import { ServiceCompanyDetails } from './serviceCompanies/details'
import { FindingEdit } from './issuesFindings/finding-edit-page'
import { FindingViewEdit } from './issuesFindings/finding-view-edit-page'
import Alarms from './alarmNotifications'
import { PAGE_TYPE } from './opportunities/constants'
import CreateEditDocumentAndReport from './documentsAndReports/CreateEditDocumentAndReport'
import ResourceNotFound from "./resourceNotFound"
import Consultation from './documentsAndReports/consultation/Consultation'
import { PortfolioScorecard } from './portfolioScorecard'
import { EnergyCostSummary } from './energyCostSummary'
import { EnergyConsumption } from './energyConsumption'
import { ElectricalDemand } from './electricalDemand'
import { LoadAnalysis } from './loadAnalysis'
import { USER_EVENTS } from "src/amplitude-categories"
import { TranslateComponent } from '../common/translations'
import NotificationSettings from "./notifications"
import { ACTIONS } from './buildingSetup/createBuilding/constants'
import ContractsAndOfferings from './contracts-and-offerings'
import {CreateContract} from './contracts-and-offerings/add'
import {ViewOnAction} from './contracts-and-offerings/ViewOnAction'
import { DataReprocess } from "src/pages/datareprocess"
import { PropertyMetadataManagement } from 'src/pages/propertyMetadataManagement'
import {ATSHistory} from './automatedTestSettings/automatedTestHistory/index'
import { Home } from 'src/denali-pages/Home/Home'
import { VideoOnboarding } from 'src/denali-pages/VideoOnboarding/VideoOnboarding'
import { isUBMEnabled } from '../common/featureFlags'

const energyPerformanceSection = isUBMEnabled ? 'Utility & Energy' : 'Energy Performance'
const utilityManagementSection = isUBMEnabled ? 'Utility & Energy' : 'Utility Management'

export const pages = {
  'Home': {
    name: "Home",
    href: '/home',
    element: <Home />,
    section: 'Dashboards',
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
    },
  },
  'Dashboards/view': {
    name: "Dashboards",
    href: '/dashboard/:id',
    access: "tc.pages.dashboards",
    section: 'Dashboards',
    element: <Dashboard />,
  },
  Dashboards: {
    name: "Dashboards",
    href: '/dashboards',
    section: 'Dashboards',
    access: "tc.pages.dashboards",
    element: <Dashboards />,
  },
  'Widget Library': {
    name: "Widget Library",
    href: '/widget-library',
    section: 'Dashboards',
    access: "tc.pages.widget-library",
    element: <Widgets />,
    amplitudeTag: USER_EVENTS.DASHBOARDS.events.VIEW_WIDGET_LIBRARY,
    navigation: true,
    navigationProps: {
      groupByTag: ['organization','location','sales-office']
    },
    breadcrumbs: [{ name: "Widget Library", href: '/widget-library' }]
  },
  'Video Onboarding': {
    name: <TranslateComponent>Video Onboarding</TranslateComponent>,
    href: '/video-onboarding',
    element: <VideoOnboarding />,
    section: 'Dashboards',
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
    },
  },
  Opportunities: {
    name: "Opportunities",
    navigation: true,
    navigationProps: {
      updateTokenOrgBuildings: true,
      claims: ["OpportunityAdmin", "AttachmentAdmin"],
      denaliBuildingPicker: true
    },
    access: "tc.pages.opportunities",
    href: '/opportunities',
    section: 'Service',
    amplitudeTag: USER_EVENTS.OPPORTUNITIES.events.VIEW_OPPORTUNITIES_PAGE,
    element: <Opportunities action={PAGE_TYPE.LIST} />,
    breadcrumbs: [{ name: "Opportunities", href: '/opportunities' }]
  },
  'Opportunities/add': {
    name: "Opportunities",
    navigation: false,
    access: "tc.pages.opportunities.add",
    href: '/opportunities/add',
    section: 'Service',
    element: <Opportunities />,
    breadcrumbs: [{ name: "Opportunities", href: '/opportunities' }]
  },
  'Opportunities/:id': {
    name: "Opportunities",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true
    },
    access: "tc.pages.opportunities",
    href: '/opportunities/:id',
    section: 'Service',
    element: <Opportunities />,
    breadcrumbs: [{ name: "Opportunities", href: '/opportunities' }]
  },
  'Issues/Findings': {
    name: "Issues/Findings",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
      claims: ["IssueAdmin", "AttachmentAdmin"],
      updateTokenOrgBuildings: true,
    },
    access: "tc.pages.findings",
    href: '/findings',
    section: 'Service',
    amplitudeTag: USER_EVENTS.ISSUES_AND_FINDINGS.events.VIEW_ISSUES_AND_FINDINGS_LIST_PAGE,
    element: <IssuesFindings />,
    breadcrumbs: [{ name: "Issues/Findings", href: '/findings' }]
  },
  'Issues/Findings/add': {
    name: "Issues/Findings",
    access: "tc.pages.findings.add",
    amplitudeTag: USER_EVENTS.ISSUES_AND_FINDINGS.events.CLICK_CREATE_FINDING_BUTTON,
    href: '/findings/add',
    section: 'Service',
    element: <FindingEdit />,
    breadcrumbs: [{ name: "Issues/Findings", href: '/findings' }]
  },
  'Issues/Findings/:id': {
    name: "Issues/Findings",
    access: "tc.pages.findings",
    amplitudeTag: (state) => {
      return state?.mode === "View" ? USER_EVENTS.ISSUES_AND_FINDINGS.events.VIEW_ISSUE_FINDING : state?.mode === "Edit" ? USER_EVENTS.ISSUES_AND_FINDINGS.events.CLICK_EDIT_ISSUE_FINDING_BUTTON : state?.mode === "Copy" ? USER_EVENTS.ISSUES_AND_FINDINGS.events.CLICK_COPY_ISSUE_FINDING_BUTTON : undefined
    },
    href: '/findings/:id',
    section: 'Service',
    element: <FindingViewEdit />,
    breadcrumbs: [{ name: "Issues/Findings", href: '/findings' }]
  },
  'Baseline Library': {
    name: "Baseline Library",
    navigation: true,
    navigationProps: {
      updateTokenOrg: true,
      claims: ["BaselineAdmin"],
      denaliBuildingPicker: true,
    },
    href: '/baseline-library',
    access: "tc.pages.baseline-library",
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.BASELINE_LIBRARY.events.VIEW_BASELINE_LIBRARY_PAGE,
    element: <BaseLines />,
    breadcrumbs: [{ name: "Baseline Library", href: '/baseline-library' }]
  },
  'Building Data': {
    access: "tc.pages.building-data",
    amplitudeTag: USER_EVENTS.BUILDING_DATA.events.VIEW_BUILDING_DATA_PAGE,
    breadcrumbs: [{ name: 'Building Data', href: '/building-data' }],
    element: <BuildingData />,
    href: '/building-data',
    name: "Building Data",
    navigation: true,
    navigationProps: {
      updateTokenOrg: true,
      claims: ["BuildingDataChartAdmin"],
      denaliBuildingPicker: true,
    },
    section: 'Building Performance',
  },
  'Portfolio Scorecard': {
    access: "tc.pages.portfolio-scorecard",
    amplitudeTag: USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_PORTFOLIO,
    breadcrumbs: [{ name: 'Portfolio Scorecard', href: '/portfolio-scorecard' }],
    element: <PortfolioScorecard />,
    href: '/portfolio-scorecard',
    name: "Portfolio Scorecard",
    navigation: true,
    navigationProps: {
      groupByTag: ['organization', 'location'],
      denaliBuildingPicker: true,
    },
    section: energyPerformanceSection
  },
  'Energy Cost Summary': {
    access: "tc.pages.energy-cost-summary",
    amplitudeTag: USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_COST,
    breadcrumbs: [{ name: 'Energy Cost Summary', href: '/energy-cost-summary' }],
    element: <EnergyCostSummary />,
    href: '/energy-cost-summary',
    name: "Energy Cost Summary",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
    },
    section: energyPerformanceSection,
  },
  'Energy Consumption': {
    access: "tc.pages.energy-consumption",
    amplitudeTag: USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_CONSUMPTION,
    breadcrumbs: [{ name: 'Energy Consumption', href: '/energy-consumption' }],
    element: <EnergyConsumption />,
    href: '/energy-consumption',
    name: "Energy Consumption",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
    },
    section: energyPerformanceSection
  },
  'Electrical Demand': {
    access: "tc.pages.electrical-demand",    
    amplitudeTag: USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_DEMAND,
    breadcrumbs: [{ name: 'Electrical Demand', href: '/electrical-demand' }],
    element: <ElectricalDemand /> ,
    href: '/electrical-demand',
    name: "Electrical Demand",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
    },
    section: energyPerformanceSection,
  },
  'Load Analysis': {
    access: "tc.pages.load-analysis",
    amplitudeTag: USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_LOAD,
    breadcrumbs: [{ name: 'Load Analysis', href: '/load-analysis' }],
    element: <LoadAnalysis />,
    href: '/load-analysis',
    name: "Load Analysis",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
    },
    section: energyPerformanceSection,
  },
  'Building Setup': {
    name: "Building Setup",
    navigation: true,
    access: "tc.pages.building-setup",
    href: '/building-setup',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.BUILDING_SETUP.events.VIEW_BUILDING_LIST,
    navigationProps:{
      claims:["AttachmentAdmin"],
      allowDisabledLocations: true,
    },
    element: <BuildingSetup />,
    breadcrumbs: [{ name: 'Building Setup', href: '/building-setup' }]
  },
  'Building Setup/:id': {
    name: "Building Setup",
    access: "tc.pages.building-setup",
    href: '/building-setup/:id',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.BUILDING_SETUP.events.VIEW_BUILDING_SETUP,
    element: <BuildingDetails />,
    breadcrumbs: [{ name: 'Building Setup', href: '/building-setup' }]
  },
  'Building Setup/:id/edit': {
    name: "Building Setup",
    access: "tc.pages.building-setup.edit",
    href: '/building-setup/:id/edit',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.BUILDING_SETUP.events.CLICK_EDIT_BUILDING,
    element: <BuildingDetails action={ACTIONS.EDIT} />,
    breadcrumbs: [{ name: 'Building Setup', href: '/building-setup' }]
  },
  'Building Setup/add': {
    name: "Building Setup",
    access: "tc.pages.building-setup.add",
    href: '/building-setup/add',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.BUILDING_SETUP.events.CLICK_CREATE_BUILDING,
    element: <CreateBuilding />,
    breadcrumbs: [{ name: 'Building Setup', href: '/building-setup' }]
  },
  'Equipment Setup': {
    name: "Equipment Setup",
    access: "tc.pages.equipment-setup",
    navigation: true,
    navigationProps: {
      claims: ["ConfigurationMessageHistoryAdmin", "ConfigurationMessageHistoryUser"],
      denaliBuildingPicker: true,
    },
    href: '/equipment-setup',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_EQUIPMENT_LIST,
    element: <EquipmentSetup />,
    breadcrumbs: [{ name: 'Equipment Setup', href: '/equipment-setup' }]
  },
  'Equipment Setup/details': {
    name: "Equipment Setup",
    access: "tc.pages.equipment-setup",
    href: '/equipment-setup/details',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_EQUIPMENT_SETUP,
    element: <EquipmentDetails />,
    breadcrumbs: [{ name: 'Equipment Setup', href: '/equipment-setup' }]
  },
  'User Management': {
    name: "User Management",
    href: '/user-management',
    section: 'Admin Tools',
    access: "tc.pages.user-management",
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_USER_LIST,
    element: <UserManagement />,
    breadcrumbs: [{ name: 'User Management', href: '/user-management' }]
  },
  'User Management/add': {
    name: "User Management",
    href: '/user-management/add',
    section: 'Admin Tools',
    access: "tc.pages.user-management.add",
    element: <CreateUser />,
    breadcrumbs: [
      { name: "User Management", href: '/user-management' },
      { name: "User Management Create User" }
    ]
  },
  'User Management/edit': {
    name: "User Management",
    href: '/user-management/edit/:loc/:id',
    access: "tc.pages.user-management.edit",
    section: 'Admin Tools',
    element: <CreateUser />,
    breadcrumbs: [
      { name: "User Management", href: '/user-management' },
      { name: "User Management Edit User" }
    ]
  },
  'User Management/user-details/:id': {
    name: "User Details",
    href: '/user-management/user-details/:id',
    access: "tc.pages.user-management",
    section: 'Admin Tools',
    element: <UserManagementDetails />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_USER,
    breadcrumbs: [
      { name: "User Management", href: '/user-management' },
      { name: "User Details" }
    ]
  },
  'Service Provider Management': {
    name:"Service Provider Management",
    href: '/service-companies',
    access: "tc.pages.service-provider-management",
    section: 'Admin Tools',
    element: <ServiceCompanies />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_SERVICE_PROVIDER,
  },
  'Service Provider Management Details': {
    name: "Service Provider Management Details",
    href: '/service-company/:type/:id',
    access: "tc.pages.service-provider-management",
    section: 'Admin Tools',
    element: <ServiceCompanyDetails />
  },
  'Service Provider Management Add': {
    name: "Add Service Provider",
    href: '/service-company/:type/add',
    section: 'Admin Tools',
    access: "tc.pages.service-provider-management",
    element: <ServiceCompanyDetails />,
  },
  'Role Management': {
    name: "Role Management",
    href: '/role-management',
    access: "tc.pages.role-management",
    section: 'Admin Tools',
    element: <RoleManagement />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_ROLE_MANAGEMENT,
  },
  'Role Management/:id': {
    name: "Role Management",
    href: '/role-management/:id',
    access: "tc.pages.role-management",
    section: 'Admin Tools',
    element: <RoleManagementAdd />,
    breadcrumbs: [
      { name: "Role Management", href: '/role-management' },
    ]
  },
  'Role Management/Add': {
    name: "Role Management",
    href: '/role-management/add',
    access: "tc.pages.role-management",
    section: 'Admin Tools',
    element: <RoleManagementAdd />,
    breadcrumbs: [
      { name: "Role Management", href: '/role-management' },
    ]
  },
  'Application Management': {
    name: "Application Management",
    href: '/application-management',
    access: "tc.pages.application-management",
    section: 'Admin Tools',
    element: <ApplicationManagement />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_APPLICATION_MANAGEMENT,
  },
  'User Type Management': {
    name: "User Type Management",
    href: '/user-type-management',
    access: "tc.pages.user-type-management",
    section: 'Admin Tools',
    element: <UserTypeManagement />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_USER_TYPE_MANAGEMENT,
  },
  'Group Management': {
    name: "Group Management",
    href: '/group-management',
    access: "tc.pages.group-management",
    section: 'Admin Tools',
    element: <GroupManagement />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_GROUP_MANAGEMENT,
    breadcrumbs: [
      { name: "Group Management", href: '/group-management' },
    ]
  },
  'Permission Management': {
    name: "Permission Management",
    href: '/permission-management',
    access: "tc.pages.permission-management",
    section: 'Admin Tools',
    element: <PermissionManagement />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_PERMISSION_MANAGEMENT,
  },
  'Permission Management/:id': {
    name: "Permission Management",
    href: '/permission-management/:id',
    section: 'Admin Tools',
    element: <PermissionManagementAdd />,
    access: "tc.pages.permission-management",
    breadcrumbs: [
      { name: 'Permission Management', href: '/permission-management' },
    ]
  },
  'Permission Management/Add': {
    name: "Permission Management",
    href: '/permission-management/add',
    section: 'Admin Tools',
    access: "tc.pages.permission-management",
    element: <PermissionManagementAdd />,
    breadcrumbs: [
      { name: "Permission Management", href: '/permission-management' },
    ]
  },
  'Organization Management': {
    name: "Organization Management",
    access: "tc.pages.organization-management",
    href: '/organization-management',
    section: 'Admin Tools',
    navigation: true,
    navigationProps: {
      groupByTag: ['sales-office'],
      showLocations: false,
    },
    element: <OrgManagement />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_ORGANIZATION_MANAGEMENT,
  },
  'Organization Management/Add': {
    name: "Organization Management",
    href: '/organization-management/add',
    section: 'Admin Tools',
    access: "tc.pages.organization-management.add",
    element: <OrgManagementAdd />,
    breadcrumbs: [
      { name: "Organization Management", href: '/organization-management' },
    ]
  },
  'Organization Management/:id': {
    name: "Organization Management",
    href: '/organization-management/:id',
    section: 'Admin Tools',
    access: "tc.pages.organization-management",
    element: <OrgManagementAdd />,
    breadcrumbs: [
      { name: "Organization Management", href: '/organization-management' },
    ]
  },
  'Data Reprocess': {
    name: "Data Reprocess",
    href: '/data-reprocess',
    access: "tc.pages.data-reprocess",
    section: 'Admin Tools',
    navigation: true,
    navigationProps: {
      groupByTag: ['organization'],
      denaliBuildingPicker: true
    },
    element: <DataReprocess />,
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_DATA_REPROCESS,
    breadcrumbs: [
      { name: "Data Reprocess", href: '/data-reprocess' },
    ]
  },
  'Property Metadata': {
    name: "Property Metadata Management",
    href: '/property-metadata-management',
    access: "tc.pages.property-metadata",
    section: 'Admin Tools',
    navigation: false,
    navigationProps: {
      groupByTag: ['organization']
    },
    element: <PropertyMetadataManagement />,
    breadcrumbs: [
      { name: "Property Metadata Management", href: '/property-metadata-management' },
    ]
  },
  'Connect to a Device': {
    name: "Connect to a Device",
    navigation: true,
    navigationProps: {
      groupByTag: ['organization','location'],
      denaliBuildingPicker: true
    },
    href: '/connect-device',
    section: 'Remote Access',
    access: "tc.pages.connect-to-a-device",
    amplitudeTag: USER_EVENTS.REMOTE_ACCESS.events.VIEW_DEVICE_LIST,
    element: <ConnectToADevice />,
    breadcrumbs: [
      { name: "Connect to a Device", href: '/connect-device' },
    ]
  },
  // 'Manage Devices': {
  //   name: "Manage Devices",
  //   href: '/manage-devices',
  //   access: "tc.pages.manage-devices",
  //   section: 'Remote Access'
  // },
  'Alarm Notification': {
    navigation: true,
    name: "Alarm Notification",
    access: "tc.pages.alarm-notification",
    href: '/alarm-notification',
    section: 'Building Configuration',
    element: <Alarms />,
    amplitudeTag: USER_EVENTS.BUILDING_SETUP.events.VIEW_ALARM_NOTIFICATION,
    breadcrumbs: [{ name: 'Alarm Notification', href: '/alarm-notification' }],
    navigationProps: {
      denaliBuildingPicker: true
    },
  },
  Notes: {
    access: "tc.pages.notes",
    amplitudeTag: USER_EVENTS.NOTES.events.VIEW_NOTES_PAGE,
    breadcrumbs: [{ name: 'Notes', href: '/notes' }],
    element: <Notes />,
    href: '/notes',
    name: "Notes",
    navigation: true,
    navigationProps: {
      updateTokenOrgBuildings: true,
      claims: ["NoteUser", "NoteAdmin", "HistoryUser", "HistoryAdmin", "AttachmentAdmin"],
      denaliBuildingPicker: true
    },
    section: 'Building Configuration',
  },
  'Notes/add': {
    navigation: true,
    name: "Notes",
    href: '/notes/add',
    access: "tc.pages.notes.add",
    section: 'Building Configuration',
    element: <Notes />,
    navigationProps: {
      denaliBuildingPicker: true
    },
  },
  'Event Markers': {
    name: "Event Markers",
    navigation: true,
    navigationProps: {
      denaliBuildingPicker: true,
      claims: ["EventMarkerAdmin"],
      updateTokenOrg: true
    },
    access: "tc.pages.event-markers",
    href: '/event-markers',
    section: 'Building Configuration',
    amplitudeTag: USER_EVENTS.EVENT_MARKERS.events.VIEW_EVENT_MARKERS,
    element: <EventMarkers />,
    breadcrumbs: [{ name: 'Event Markers', href: '/event-markers' }]
  },
  'Rate Structure Library': {
    navigation: true,
    navigationProps: {
      groupByTag: ["sales-office"],
      showLocations: false,
    },
    name: "Rate Structure Library",
    access: "tc.pages.rate-structure-library",
    href: '/rate-structure-library',
    section: energyPerformanceSection,
    amplitudeTag: USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_RATE_LIST,
    element: <RateStructureLibrary />,
    breadcrumbs: [{ name: 'Rate Structure Library', href: '/rate-structure-library' }]
  },
  'Create Rate Structure': {
    name: "Create Rate Structure",
    access: "tc.pages.rate-structure-library.add",
    href: '/rate-structure-library/:type/:salesOfficeId/create',
    section: energyPerformanceSection,
    element: <CreateRateStructure />,
    breadcrumbs: [{ name: "Rate Structure Library", href: '/rate-structure-library' }]
  },
  'Edit Rate Structure': {
    name: "Edit Rate Structure",
    access: "tc.pages.rate-structure-library.edit",
    href: '/rate-structure-library/:rateStructureId',
    section: energyPerformanceSection,
    element: <CreateRateStructure />,
    breadcrumbs: [{ name: "Rate Structure Library", href: '/rate-structure-library' }]
  },
  'Edit Rate Structure Charge': {
    name: "Create Rate Structure Charge",
    access: "tc.pages.rate-structure-library.charge.edit",
    href: '/charge/:chargeId',
    section: energyPerformanceSection,
    element: <CreateCharge id={"chargedetails"} />,
    breadcrumbs: [{ name: "Rate Structure Library", href: '/rate-structure-library' }]
  },
  'Create Rate Structure Charge': {
    name: "Create Rate Structure Charge",
    access: "tc.pages.rate-structure-library.charge.add",
    href: '/charge/create/:rateStructureId',
    section: energyPerformanceSection,
    element: <CreateCharge id={"createCharge"} />,
    breadcrumbs: [{ name: "Rate Structure Library", href: '/rate-structure-library' }]
  },
  ...(isUBMEnabled
    ? {
      'Utility Bill Upload': {
        access: "tc.pages.utility-bill-data",
        amplitudeTag: USER_EVENTS.UTILITY_MANAGEMENT.events.VIEW_BILL,
        breadcrumbs: [{ name: "Utility Bill Upload", href: '/utility-bill-data' }],
        element: <UtilityBillData />,
        href: '/utility-bill-data',
        name: "Utility Bill Upload",
        navigation: true,
        navigationProps: {
          claims: ["EnergyUsageReportAdmin"],
          updateTokenOrg: true,
          denaliBuildingPicker: true,
        },
        section: utilityManagementSection,
      }
    }
    : {
      'Utility Bill Data': {
        access: "tc.pages.utility-bill-data",
        amplitudeTag: USER_EVENTS.UTILITY_MANAGEMENT.events.VIEW_BILL,
        breadcrumbs: [{ name: "Utility Bill Data", href: '/utility-bill-data' }],
        element: <UtilityBillData />,
        href: '/utility-bill-data',
        name: "Utility Bill Data",
        navigation: true,
        navigationProps: {
          claims: ["EnergyUsageReportAdmin"],
          updateTokenOrg: true,
          denaliBuildingPicker: true,
        },
        section: utilityManagementSection,
      }
    }
  ),
  'Energy Intensity': {
    access: "tc.pages.energy-intensity",
    amplitudeTag: USER_EVENTS.UTILITY_MANAGEMENT.events.VIEW_ENERGY,
    breadcrumbs: [{ name: "Energy Intensity", href: '/energy-intensity'}],
    element: <EnergyIntensity />,
    href: '/energy-intensity',
    name: "Energy Intensity",
    navigation: true,
    navigationProps: {
      updateTokenOrg: true,
      claims: ["BenchMarkerAdmin", "EnergyUsageReportAdmin", "EventMarkerAdmin"],
      denaliBuildingPicker: true,
    },
    section: utilityManagementSection,    
  },
  'Documents & Reports': {
    navigation: true,
    navigationProps: {
      claims: ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"],
      updateTokenOrg: true,
      denaliBuildingPicker: true,
    },
    name: "Documents & Reports",
    access: "tc.pages.documents-and-reports",
    href: '/reports',
    section: 'Reports',
    amplitudeTag: USER_EVENTS.DOCUMENTS_REPORTS.events.VIEW_DOCUMENTS_REPORTS,
    element: <DocumentsAndReports  action={PAGE_TYPE.LIST}/>,
    breadcrumbs: [{ name: 'Documents & Reports', href: '/reports' }]
  },
  'Documents & Reports/:id': {
    name: "Documents & Reports",
    navigationProps: {
      denaliBuildingPicker: true,
    },
    // access: "pages.reports",
    href: '/reports/:id',
    section: 'Reports',
    element: <DocumentsAndReports />,
    breadcrumbs: [
      { name: "Documents & Reports", href: '/reports' }
    ]
  },
  'Documents & Reports/energy-reports/:id': {
    name: "Documents & Reports",
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/energy-reports/:id',
    section: 'Reports',
    element: <DocumentsAndReports />,
    breadcrumbs: [
      { name: "Energy Usage Report", href: '/reports' }
    ]
  },
  'Documents & Reports/add': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/add',
    section: 'Reports',
    element: <CreateEditDocumentAndReport mode="Add" />,
    breadcrumbs: [{ name: "Documents & Reports", href: '/reports' }],
  },
  'Documents & Reports/edit': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/edit/:id',
    section: 'Reports',
    element: <CreateEditDocumentAndReport mode="Edit" />,
    breadcrumbs: [{ name: "Documents & Reports", href: '/reports' }]
  },
  'Documents & Reports/energy/add': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/energy-reports/add',
    section: 'Reports',
    element: <CreateEditDocumentAndReport mode="Add" />,
    breadcrumbs: [{ name: "Energy Usage Report", href: '/reports' }]
  },
  'Documents & Reports/energy/edit': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/energy-reports/edit/:id',
    section: 'Reports',
    element: <CreateEditDocumentAndReport mode="Edit" />,
    breadcrumbs: [{ name: "Energy Usage Report", href: '/reports' }]
  },
  'Documents & Reports/consultations/:id': {
    name: "Documents & Reports",
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/consultations/:id',
    section: 'Reports',
    element: <DocumentsAndReports />,
    breadcrumbs: [
      { name: "Consultations", href: '/reports' }
    ]
  },
  'Documents & Reports/consultations/add': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/consultations/add',
    section: 'Reports',
    element: <Consultation mode="Add" />,
    breadcrumbs: [{ name: 'Consultations', href: '/reports' }]
  },
  'Documents & Reports/consultations/edit': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/consultations/edit/:id',
    section: 'Reports',
    element: <Consultation mode="Edit" />,
    breadcrumbs: [{ name: 'Consultations', href: '/reports' }]
  },
  'Documents & Reports/consultations/copy': {
    name: 'DocumentsReports',
    navigationProps: {
      denaliBuildingPicker: true,
    },
    href: '/reports/consultations/copy/:id',
    section: 'Reports',
    element: <Consultation mode="Copy" />,
    breadcrumbs: [{ name: 'Consultations', href: '/reports' }]
  },
  'Automated Test Settings': {
    navigation: true,
    name: "Automated Test Settings",
    access: "tc.pages.automated-test-settings",
    href: '/automated-test-settings',
    section: 'Building Performance',
    element: <AutomatedTestSettings />,
    amplitudeTag: USER_EVENTS.UTILITY_MANAGEMENT.events.VIEW_AUTOMATED_TEST_SETTINGS,
    navigationProps: {
      denaliBuildingPicker: true
    },
    breadcrumbs: [{ name: 'Automated Test Settings', href: '/automated-test-settings' }]
  },
  'Automated Test History': {
    navigation: true,
    name: "Automated Test History",
    access: "tc.pages.automated-test-settings",
    href: '/automated-test-history',
    section: 'Building Performance',
    element: <ATSHistory />,
    navigationProps: {
      denaliBuildingPicker: true
    },
    breadcrumbs: [{ name: 'Automated Test Settings', href: '/automated-test-settings' }]
  },
  'Contracts & Offerings': {
    access: "tc.pages.contracts-and-offerings",
    amplitudeTag: USER_EVENTS.ADMIN_TOOLS.events.VIEW_CONTRACTS_OFFERINGS,
    breadcrumbs: [{ name: 'Contracts & Offerings', href: '/contracts-and-offerings' }],
    element: <ContractsAndOfferings />,
    href: '/contracts-and-offerings',
    name: "Contracts & Offerings",
    navigation: true,
    navigationProps: {
      claims: ["ContractAdmin", "ContractUser", "AttachmentAdmin"],
      denaliBuildingPicker: true,
      updateTokenOrg: true,
    },
    section: 'Admin Tools'
  },
  'Contracts & Offerings Add': {
    access: "tc.pages.contracts-and-offerings",
    breadcrumbs: [{ name: 'Contracts & Offerings', href: '/contracts-and-offerings' }],
    element: <CreateContract />,
    href: '/contracts-and-offerings/add',
    name: "Request Activation",
    navigationProps: {
      denaliBuildingPicker: true
    },
    section: 'Admin Tools'
  },
  'Contracts & Offerings Edit': {
    access: "tc.pages.contracts-and-offerings",
    element: <ViewOnAction />,
    href: '/contracts-and-offerings/:id',
    name: "Edit Contract",
    navigationProps: {
      denaliBuildingPicker: true
    },
    section: 'Admin Tools'
  },
  'Automated Test Suppressions': {
    navigation: true,
    navigationProps: {
      groupByTag: ['location'],
      denaliBuildingPicker: true
    },
    access: "tc.pages.automated-test-suppressions",
    name: "Automated Test Suppressions",
    href: '/automated-test-suppressions',
    section: 'Building Performance',
    element: <AutomatedTestSuppressions />,
    amplitudeTag: USER_EVENTS.UTILITY_MANAGEMENT.events.VIEW_AUTOMATED_TEST_SUPPRESION,
    breadcrumbs: [{ name: 'Automated Test Suppressions', href: '/automated-test-suppressions' }]
  },
  'Command Center': {
    type: "commandCenter",
    navigation: true,
    name: "Command Center",
    access: "tc.pages.command-center",
    href: '/buildings?view=tiles',
    section: 'Building Performance',
  },
  'Notification Settings': {
    name: "Notification Settings",
    href: '/notification-settings',
    section: 'Notifications',
    element: <NotificationSettings />
  },
  'Resource Not Found':{
    navigation: false,
    name: "Resource Not Found",
    section: 'Resource Not Found',
    href: '/resource-not-found',
    element: <ResourceNotFound />
  }

}

export const indexPage = pages[Object.keys(pages).find((p) => pages[p].index)]

const generatePage = (page) => {
  return <PageWrapper navigation={page.navigation} navigationProps={page?.navigationProps} key={page.groupKey || typeof page.amplitudeTag === "function" ? null : page.amplitudeTag} amplitudeTag={page.amplitudeTag}>{page.element}</PageWrapper>
}

export const pageRoutes = [
  { path: '/', element: indexPage?.element },
  ...Object.keys(pages).map((p) => ({
    path: pages[p].href,
    handle: { page: pages[p] },
    element: generatePage(pages[p])
  }))
]

export default pages

export const ROLES = {
  REMOTE_ACCESS: 'RemoteAccess',
  GENERAL_USER: 'GeneralUser',
  GS_PRIMARY_USER: 'GS-PrimaryUser',
  GS_TECHNICIAN: 'GS-Technician',
  GS_VIEWER: 'GS-Viewer',
  GS_EXTERNAL_TECHNICIAN: 'GS-External-Technician',
  TECHNICIAN: 'Technician',
  TECH_SUPPORT: 'TechSupport',
  SUPER_ADMIN: 'SuperAdmin',
  TCC4_USER: 'TCC4',
  TCC_TECHNICIAN: 'TCC_Technician',
  ANALYTICS_TECHNICIAN: 'AnalyticsTechnician',
  CUSTOMER_TECHNICIAN: 'CustomerTechnician',
  CUSTOMER_ASSETS_TECHNICIAN: 'CustomerAssetsTechnician',
  KIOSK: 'Kiosk',
  DEVICEMANAGER: 'DeviceManager',
  ENSEMBLE_ADMINISTRATOR: 'Ensemble-Administrator',
  ENSEMBLE_ADVANCE_BUILDING_OPERATOR: 'Ensemble-AdvanceBuildingOperator',
  ENSEMBLE_BUILDING_OPERATOR: 'Ensemble-BuildingOperator',
  ENSEMBLE_VIEW: 'Ensemble-View',
  ENSEMBLE_TENANT_EMPLOYEE: 'Ensemble-TenantEmployee',
  RC_TECHNICIAN: 'RC-Technician',
  RC_ADMIN: 'RC_ADMIN',
  RC_DATABASE_ADMIN: 'RC_DB_ADMIN',
  CONTRACTOR_TECHNICIAN: 'ContractorTechnician',
  SERVICE_COMPANY_ADMIN: 'ServiceCompanyAdmin',
  DMS_BUILDINGOWNER: 'DMS_BUILDINGOWNER',
  DMS_SERVICER: 'DMS_SERVICER',
  CONTRACT_MANAGER: 'ContractManager',
  OFFERING_MANAGER: 'OfferingManager'
}

