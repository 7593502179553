import { useEffect, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'

const GET_PROPERTIES_SYMBOL = `query configByTypeAndSubtype($nextToken: String) {
    configByTypeAndSubtype(type: "Dimension", nextToken: $nextToken, limit: 5000) {
      items {
        name
        type
        value
        subType
      }
      nextToken
    }
  }
  `

export const useDimensionsHook = () => {
  const [loadingDimensions, setLoadingDimension] = useState([])

  const [dimensionsList, setDimensionList] = useState([])

  const [allResponseData, setAllresponseData] = useState([])

  const [defaultDimensionGroup, setDefaultDimensionGroup] = useState({})

  const [allDimensions, setAllDimensions] = useState([])
  const { data: propertySymbols, refetch: getPropertySymbol } = useQuery({
    query: GET_PROPERTIES_SYMBOL,
    dataPath: 'data.configByTypeAndSubtype',
    disableInitialLoad: true
  })

  const getAllDimensions = async () => {
    setLoadingDimension(true)
    await getPropertySymbol()
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const formatDimensionGroup = (responseData) => {
    try {
      if (responseData?.length > 0) {
        const defaultGroup = []

        responseData?.map((x) => {
          try {
            const isValueJSON = isJsonString(x?.value)
            if (isValueJSON) {
              let jsonValues = null
              jsonValues = JSON.parse(x?.value)
              if (typeof jsonValues !== 'object') {
                jsonValues = JSON.parse(jsonValues)
                if (typeof jsonValues !== 'object') {
                  jsonValues = null
                }
              }
              jsonValues !== null &&
                defaultGroup.push({ ...x, value: jsonValues })
            }
          } catch (error) {}
        })

        const result = defaultGroup.reduce((x, y) => {
          (x[y.subType?.toLowerCase()] =
            x[y?.subType?.toLowerCase()] || [])?.push(y)
          return x
        }, {})

        setDefaultDimensionGroup(result)
      }
    } catch (error) {}
  }

  const formatDimension = (propertySymbols) => {
    if (propertySymbols && propertySymbols?.length > 0) {
      const parsedSymbols = []
      propertySymbols.map((x) => {
        try {
          const isValueJSON = isJsonString(x?.value)
          let result = null
          if (isValueJSON) {
            result = JSON.parse(x?.value)
            if (typeof result === 'string') {
              result = JSON.parse(result)
            } else if (typeof result === 'string') {
              result = JSON.parse(result)
            }
          }
          result !== null && parsedSymbols.push({ dimId: x?.name, subType: x?.subType, ...result })
        } catch (error) {}
      })
      return parsedSymbols
    }
  }

  useEffect(() => {
    try {
      if (propertySymbols && propertySymbols?.items?.length > 0) {
        const formatResponse = formatDimension(propertySymbols?.items)
        setDimensionList([...dimensionsList, ...formatResponse])
        setAllresponseData([...allResponseData, ...propertySymbols?.items])
        if (propertySymbols?.nextToken === null) {
          setLoadingDimension(false)
          setAllDimensions([...dimensionsList, ...formatResponse])
          formatDimensionGroup([...allResponseData, ...propertySymbols?.items])
        } else {
          getPropertySymbol({
            filter: { type: { eq: 'Dimension' } },
            nextToken: propertySymbols?.nextToken
          })
        }
      } else {
        setAllDimensions([])
      }
    } catch (error) {
      setAllDimensions([])
    }
  }, [propertySymbols])

  return {
    allDimensions,
    getAllDimensions,
    loadingDimensions,
    defaultDimensionGroup
  }
}
