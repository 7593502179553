import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./table.module.scss"
import { TranslateComponent } from "src/common/translations"
import { Flex } from "@aws-amplify/ui-react"

export const Loading = ({}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems='center'
      justifyContent='center'
      padding='50px'
      data-testid='denali-loading-spinner'
    >
      <FontAwesomeIcon icon={faSpinner} className={styles.spinner} />
      <TranslateComponent>Loading</TranslateComponent>
    </Flex>
  )
}