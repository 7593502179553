import React from "react"
import PropTypes from "prop-types"
import {
    Container,
    Color,
    ElementContainer,
    Label
} from "./styles"
import { TranslateComponent } from "src/common/translations"

const Element = (props) => {
	Element.propTypes = {
		onToggle: PropTypes.func,
		item: PropTypes.object,
		disabledItems: PropTypes.array.isRequired,
		numItems: PropTypes.number.isRequired,
	}

	Element.defaultProps = {
		onToggle: () => null,
	}

	const onToggle = () => {
		if (props.disabledItems.includes(props.item.key)) {
			props.onToggle(props.disabledItems.filter(i => i !== props.item.key))
		} else if (props.numItems > props.disabledItems.length + 1) {
			props.onToggle(props.disabledItems.concat([props.item.key]))
		}
	}

	return (
        <ElementContainer onClick={onToggle} {...props}>
            <Color color={props.item.color} />
            <Label><TranslateComponent>{props.item.label}</TranslateComponent></Label>
	    </ElementContainer>
    )
}

const ColorLegend = ({ items, onToggle, disabledItems }) => (
	<Container>
		{items.map(item => <Element key={item.key} item={item} disabledItems={disabledItems} numItems={items.length} onToggle={onToggle} />)}
	</Container>
)

ColorLegend.propTypes = {
	onToggle: PropTypes.func,
	items: PropTypes.arrayOf(PropTypes.shape({
		color: PropTypes.string.isRequred,
		label: PropTypes.string.isRequired,
		key: PropTypes.string.isRequired,
	})).isRequired,
	disabledItems: PropTypes.array,
}

ColorLegend.defaultProps = {
	onToggle: () => null,
	disabledItems: [],
}

export default ColorLegend