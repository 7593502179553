export const CURVE_TYPES = {
  CONDENSER_APPROACH_TEMPERATURE: 'Condenser Approach Temperature',
  EVAPORATOR_APPROACH_TEMPERATURE: 'Evaporator Approach Temperature'
}

export const curveTypes = [
  {
    key: 'Condenser Approach Temperature',
    value: 'Condenser Approach Temperature'
  },
  {
    key: 'Evaporator Approach Temperature',
    value: 'Evaporator Approach Temperature'
  }
]

export const CURVE_TYPES_BY_NUMBER = {
  [CURVE_TYPES.CONDENSER_APPROACH_TEMPERATURE]: 1,
  [CURVE_TYPES.EVAPORATOR_APPROACH_TEMPERATURE]: 2
}

export const CHANGE_PROPERTY_NAMES = {
  name: 'Name',
  minValueXAxis: 'X Axis Range Min Value',
  maxValueXAxis: 'X Axis Range Max Value',
  minValueYAxis: 'Y Axis Range Min Value',
  maxValueYAxis: 'Y Axis Range Max Value',
  points: 'Points',
  runAnalytic: 'Enable Automated Test',
  xAxisTargetKey: 'X Axis'
}

export const CHARACTERISTIC_CURVE_UOM = { IP: "fahrenheit", SI: "celsius" }
