// Buildings By Name
export const GET_BUILDINGS = /* GraphQL */ `
  query SearchBuildingsList(
    $filter: SearchableBuildingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        state
        address
        city
        name
        accountId
        accountName
        id
        postalCode
        country
        isActive
      }
    }
  }
`

export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
  $sort: [SearchableBuildingSortInput]
) {
    searchBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        state
        address
        city
        name
        accountId
        accountName
        id
        postalCode
        country
        isActive
        groupUser
        groupAdmin
      }
      nextToken
      total
    }
  }`

export const ADD_ACCOUNT = /* GraphQL */ `
    mutation createAccount($input: CreateAccountInput!) {
        createAccount(input: $input) {
            id
        }
  } 
`

export const UPDATE_ACCOUNT = /* GraphQL */ `
    mutation updateAccount($input: UpdateAccountInput!) {
        updateAccount(input: $input) {
            id
        }
  } 
`

export const UPDATE_BUILDING = /* GraphQL */ `
    mutation updateBuilding($input: UpdateBuildingInput!) {
        updateBuilding(input: $input) {
            id
        }
  } 
`

export const GET_ACCOUNT_BY_ID = `
  query getAccountById($id: ID!) {
    getAccount(id: $id) {
      id
      name
      buildings {
        items {
          buildingId
          id
          name
          state
          address
          city
          accountId
          accountName
          postalCode
          country
          isActive
        }
      }
    }
  }
`
export const GET_UTILITY_ACCOUNTS_BY_ORG_ID = `
  query getUtilityAccountsByOrg($orgId: ID!, $limit: Int) {
    listUtilityAccountByAccount(accountId: $orgId, limit: $limit) {
      items {
        accountId
        accountName
        accountNumber
        id
        providerName
      }
    }
  }
`
