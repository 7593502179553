// Library imports
import { Flex } from '@aws-amplify/ui-react'
import { useCallback } from 'react'

// Denali component imports
import { faExclamationTriangle } from 'src/denali-components/lib/pro-solid-svg-icons'

// Local imports
import styles from './error-message.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Pass children to be rendered as the message and an icon that defaults to an error triangle.
type ErrorMessageProps = {
  children: React.ReactElement,
  icon?: React.ReactElement,  
}

// A reusable component for displaying an error message inline on the page.
// Typically used when there is data missing for rendering, like needing to select a building.
const ErrorMessage = ({ icon, children }: ErrorMessageProps) => {
  const renderDefaultIcon = useCallback(() => (
    <div data-color="red">
      <FontAwesomeIcon data-color="red" icon={faExclamationTriangle} />
    </div>
  ), [icon])
  
  return <div className={styles.errorContainer}>
    <Flex direction="row" gap={16} justifyContent="flex-start" alignItems="center">
      <div className={styles.errorIcon}>{icon ?? renderDefaultIcon()}</div>
      <div className={styles.errorMessage}>
        {children}
      </div>
    </Flex>
  </div>
}

export default ErrorMessage
