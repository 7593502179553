import { Flex, Input, Label, RadioGroupField } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useMemo, useRef, useState } from 'react'
import { useController } from 'react-hook-form';

// Project imports.
import translate, { TranslateComponent } from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions'

// Denali imports.
import { CardRadio } from 'src/denali-components/Form/CardRadio'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { faBuildings, faMagnifyingGlass, faRotateRight } from 'src/denali-components/lib/pro-solid-svg-icons';
import { getColor as getRandomColor, randomizeString } from 'src/denali-components/helpers'

const InlineBuildingSelectorComponent = ({
  buildings,
  disabled=false
}) => {
  // const [value, setValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [activeScroll, setActiveScroll] = useState(true)
  const { buildingId } = getSearchParams()
  const { field: { onChange, value } } = useController({ name: 'location.locationId' })
  const buildingsGroup = useRef(null)
  const searchBuildings = useRef(null)
  const filteredBuildings = buildings.searchOptions !== undefined ? buildings.searchOptions : buildings
  const searchPlaceholder = translate('Search...')
  const searchDesc = translate('Search Buildings')
  
  // Calculate icon colors for each building and memoize it.
  const iconColors = useMemo(() => {
    return filteredBuildings.map(building => getRandomColor(randomizeString(building.value)))
  }, [filteredBuildings])

  useMemo(() => {
    filteredBuildings.sort(building => {
      if(building.key === buildingId) return -1
      return 0;
    })
  }, [filteredBuildings])
  

  return (
    <Flex direction='column' data-testid='inlineBuildingSelector'>
      <Flex className={formStyles.inlineBuildingSelectorHeader}>
        <FontAwesomeIcon icon={faBuildings} />
        <Flex direction='column' gap='0'>
          {buildings[0].accountName}
          <span className={formStyles.subHeader}><TranslateComponent>Organization</TranslateComponent></span>
        </Flex>
      </Flex>
      <Label htmlFor='search' position='relative'>
        <TranslateComponent>Select a Building*</TranslateComponent>
        <div className={formStyles.searchInner}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <Input
            id='SearchBuildings'
            placeholder={searchPlaceholder}
            aria-description={searchDesc}
            type='search'
            ref={searchBuildings}
            onChange={(event) => {
              onBuildingSearchChange(
                event,
                buildings,
                setSearchTerm
              )
            }}
          />
        </div>
        { activeScroll && filteredBuildings.length > 0 && <div className={formStyles.scrollRightOverlay}></div> }
        {filteredBuildings.length === 0 && (
          <Flex>
            <button className={formStyles.noBuildingResults} onClick={() => resetSearch(event, buildings, setSearchTerm, searchBuildings)}>
              <TranslateComponent>No Buildings found</TranslateComponent>
              <div className={formStyles.noBuildingsSubText}>
                <FontAwesomeIcon icon={faRotateRight}></FontAwesomeIcon>
                <TranslateComponent>Reset Filters</TranslateComponent>
              </div>
            </button>
          </Flex>
        )}
        {filteredBuildings.length > 0 && <Flex
          className={formStyles.horizontalWrapper}
          ref={buildingsGroup}
          onScroll={() => {handleInnerScrollFade(buildingsGroup, setActiveScroll)}}
        >
          <RadioGroupField
            label='Buildings'
            labelHidden
            name='location.locationId'
            value={value}
            onChange={onChange}
            className={formStyles.cardRadioGroup}
          >
            {filteredBuildings.map((building, index) => {
              const iconColor = iconColors[index]
              
              return <CardRadio
                key={building.key}
                name={building.value}
                value={building.key}
                color={iconColor}
                disabled={disabled}
              />
            })}
          </RadioGroupField>
        </Flex>}
      </Label>
    </Flex>
  )
}

export const InlineBuildingSelector = memo(InlineBuildingSelectorComponent)

function onBuildingSearchChange(
  event,
  buildings,
  setSearchTerm
) {
  const searchTerm = event.target.value.toLowerCase();
  if (searchTerm.length > 0) {
    buildings.searchOptions = buildings.filter(building =>
      building?.value?.toLowerCase().includes(searchTerm)
    )
  } else {
      buildings.searchOptions = undefined
  }

  setSearchTerm(searchTerm)
}

function handleInnerScrollFade(buildingsGroup, setActiveScroll) {
  const optionsWrapper = buildingsGroup?.current;
  const optionsList = optionsWrapper?.firstElementChild;
  const optionsRight = optionsList?.clientWidth - optionsWrapper?.scrollLeft;
  
  setActiveScroll(optionsRight -10 >= optionsWrapper?.clientWidth);
}

function resetSearch(e, buildings, setSearchTerm, searchBuildings) {
  e.preventDefault();
  searchBuildings.current.value = '';
  onBuildingSearchChange(e, buildings, setSearchTerm)
}