import { Button, ButtonGroup, Grid, Heading, View } from "@aws-amplify/ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"
import translate, { TranslateComponent } from "src/common/translations"
import { faGrid2 } from "src/denali-components/lib/pro-solid-svg-icons"
import { BackgroundColor, Page } from "src/denali-components/Page/Page"
import { selectUiMode, selectUserInfo, setSelectedLanguage } from "src/redux/slicers/appData"
import { CardVertical, CardVerticalColor } from "src/denali-components/Cards/CardVertical/CardVertical"
import { Chip, ChipColor } from "src/denali-components/Table/Chip"
import { faBolt, faPlay } from "@fortawesome/free-solid-svg-icons"
import ConnectDeviceImage from "src/assets/images/homepage-modules/connect-device-image.svg"
import DocumentsImage from "src/assets/images/homepage-modules/documents-image.svg"
import { CardHighlightActions } from "src/denali-components/Cards/CardHighlightActions/CardHighlightActions"
import { Chips } from "src/denali-components/Table/Chips"
import styles from "./home.module.scss"
import { Navigate, useNavigate } from "react-router"
import { UiMode } from "src/redux/types/AppTypes"
import { WeatherCard } from "./WeatherCard"
import { accessControlFunc } from "src/components/accessControl"
import { useDispatch } from "react-redux"
import { setDashboard } from "src/redux/slicers/dashboard"
import { useEffect } from "react"

export const Home = ({}) => {
  const user = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intro = user.firstName
    ? translate('Welcome, ') + user.firstName
    : translate('Welcome');
  const uiMode = useSelector(selectUiMode)
  if(uiMode !== UiMode.denali) {
    return <Navigate to={`/`} />;
  }


  return (
    <Page
      title={intro}
      icon={<FontAwesomeIcon icon={faGrid2} />}
      backgroundColor={BackgroundColor.alt}
      actions={[]}
      testid="homepage"
    >
      <Grid
        columnGap="1.5rem"
        rowGap="1.5rem"
        templateColumns={{base: '1fr', medium: '1fr 1fr 1fr'}}
      >
        <View columnSpan={2} data-testid="homeWidgetQuickStart">
          <CardHighlightActions
            backgroundImage={require('src/assets/images/homepage-modules/quick-start.jpg')}
            tags={
              <Chips>
                <Chip color={ChipColor.transparentBlack}><FontAwesomeIcon icon={faBolt} /><TranslateComponent>Quick Tips</TranslateComponent></Chip>
                <Chip color={ChipColor.transparentBlack}><TranslateComponent>2 to 5 min</TranslateComponent></Chip>
              </Chips>
            }
            title={<Heading level={2} className="heading-gradient h1"><TranslateComponent>Quick Start With Trane Connect</TranslateComponent></Heading>}
            subTitle={
              <Heading level={3} className="h6">
                <TranslateComponent>Watch our</TranslateComponent> <span className="text-highlight"><TranslateComponent>2 video series</TranslateComponent></span>
              </Heading>
            }
            actions={
              <ButtonGroup>
                <Button className="button-transparent" onClick={()=>navigate('/video-onboarding')}>
                  <TranslateComponent>All Episodes</TranslateComponent>
                </Button>
                <Button className="button-inverse" onClick={()=> navigate('/video-onboarding?openDialog=true&cardIndex=%5')}><FontAwesomeIcon icon={faPlay} /><span><TranslateComponent>Watch Episode 1</TranslateComponent></span></Button>
              </ButtonGroup>
            }
            vimeoId='1007803081'
            vimeoTitle='Background design showcase'
            vimeoParams='h=197ff7b13a&transparent=0'
          />
        </View>
        <CardVertical
          color={CardVerticalColor.darkGreen}
          icon={<FontAwesomeIcon icon={faBolt} />}
          backgroundImage={require('src/assets/images/homepage-modules/energy-usage-setup.jpg')}
          backgroundSize="394px 243px"
          button={
            <Button className="button-green" onClick={()=>navigate('/energy-consumption')} disabled={!accessControlFunc({ id: 'tc.pages.energy-consumption' })}>
              <TranslateComponent>Get Started</TranslateComponent>
            </Button>
          }
          testId="homeWidgetEnergy"
        >
          <Chip color={ChipColor.darkGreen}><TranslateComponent>3 Minutes</TranslateComponent></Chip>
          <Heading level={2} className="h3"><TranslateComponent>Energy Usage Setup</TranslateComponent></Heading>
          <p><TranslateComponent>Set up your energy usage dashboard to proactively monitor and optimize usage for better efficiency and savings.</TranslateComponent></p>
            
        </CardVertical>
        <WeatherCard />
        <View rowSpan={2}>
          <CardVertical
            image={<img src={ConnectDeviceImage} alt="" />}
            backgroundImage={require('src/assets/images/homepage-modules/connect-device.jpg')}
            backgroundSize="668px 522px"
            backgroundColor='#F3FFFB'
            backgroundPosition="center -20px"
            button={
              <Button className="button-gray" onClick={()=>navigate('/connect-device')} disabled={!accessControlFunc({ id: 'tc.pages.connect-to-a-device' })}>
                <TranslateComponent>Go to Remote Connect</TranslateComponent>
              </Button>
            }
            testId="homeWidgetRemoteConnect"
          >
            <Chip color={ChipColor.black}><TranslateComponent>Remote Connect</TranslateComponent></Chip>
            <Heading level={2}><TranslateComponent>Connect to a Device</TranslateComponent></Heading>
            <p><TranslateComponent>Connect to a local device to easily manage and control your HVAC system.</TranslateComponent></p>
              
          </CardVertical>
        </View>
        <View rowSpan={2}>
          <CardVertical
            image={<img src={DocumentsImage} alt="" />}
            backgroundImage={require('src/assets/images/homepage-modules/documents-background.png')}
            backgroundSize="439px 205px"
            backgroundPosition="center top"
            gradient='linear-gradient(0deg, #FFFBFB 60%, #F8CCF8 90%)'
            button={
              <Button className="button-gray" onClick={()=>navigate('/reports')} disabled={!accessControlFunc({ id: 'tc.pages.documents-and-reports' })}>
                <TranslateComponent>Go to Reports</TranslateComponent>
              </Button>
            }
            testId="homeWidgetDocuments"
          >
            <Chip color={ChipColor.black}><TranslateComponent>Updated</TranslateComponent></Chip>
            <Heading level={2}><TranslateComponent>New Documents and Reports</TranslateComponent></Heading>
            <p><TranslateComponent>Discover the latest resources and insights for helpful data on your HVAC system.</TranslateComponent></p>
              
          </CardVertical>
        </View>
        <div className={styles.dashboards} data-testid="homeWidgetDashboards">
          <img src={require('src/assets/images/homepage-modules/fpo-dashboard-icon.png')} alt="" />
          <div>
            <Heading level={2} className="h5"><TranslateComponent>Create your Custom Dashboard</TranslateComponent></Heading>
            <p><TranslateComponent>Take a moment to personalize your dashboard to help make HVAC management a breeze.</TranslateComponent></p>
          </div>
          <Button className="button-gray" onClick={() => dispatch( setDashboard({ actionType: 'Create' }) )}>
            <TranslateComponent>Create a Dashboard</TranslateComponent>
          </Button>
        </div>
      </Grid>
    </Page>
  )
}