import { type AnyAction, createSlice, type ThunkDispatch } from '@reduxjs/toolkit'
import { AppTypes } from '../types'
import { TStore, AppDispatch } from '../store'
import { Auth, Amplify, API } from 'aws-amplify'
import { State } from '../types'
import { UiMode } from '../types/AppTypes'
import { getbuildingIdsFromToken } from 'src/common/helperFunctions.js'
import { setSelectedbuildingOfferings } from './buildingPicker'
import { LOCATION, ORGANIZATION, SALES_OFFICE } from 'src/components/buildingNavigation/constants'
import { UOM_STRINGS as UOM } from 'src/common/UOMConversions'
// import { getSearchParams, getbuildingIdsFromToken } from './../../common/helperFunctions.js'
import { identifyEvent } from 'src/amplitude.js'
import { updateUserAttributes } from 'src/pages/userManagement/graphql/mutations'

const initialState: AppTypes = {
  breadcrumbs: [],
  selectedLanguage: 'en',
  userInfo: {
    data: null,
    // pages: null
  },
  uiMode: UiMode.standard,
  betaUser: false,
  access: new Map([]),
  roles: [],
  userAccessibleBuildingIds: [],
  dismissedOnboarding: true,
}

export const appData = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setUserAccess: (state, action) => {
      state.access = action.payload
    },
    setUserRoles: (state, action) => {
      state.roles = action.payload
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload
    },
    setUserAccessibleBuildingIds: (state, action) => {
      // This holds ids based on user selection in pages dropdown to get new token,
      //  doesn't mean user has access to only these buildings
      state.userAccessibleBuildingIds = action.payload
    },
    setUiMode: (state, action) => {
      state.uiMode = action.payload
      localStorage.setItem('uiMode', action.payload === UiMode.denali ? 'denali' : 'legacy')
    },
    setBetaUser: (state, action) => {
      state.betaUser = action.payload
      localStorage.setItem('betaUser', action.payload ? 'true' : 'false')
    },
    setDismissedOnboarding: (state, action) => {
      // Use date as the key in case we update the onboarding content in the future and want to show it even
      // if the user has dismissed an older one.
      state.dismissedOnboarding = ((action.payload === true) || (action.payload?.key === '20241003'));
      localStorage.setItem('dismissedOnboarding', state.dismissedOnboarding ? '20241003' : '');
    },
  }
})

export const getSession = async(isJWT = false) => {
  const session = await Auth.currentSession()
  if(!isJWT) return session;
  return session.getIdToken().getJwtToken()
}

const refreshSessionPromise = async (refreshToken) => {
  return await new Promise(async (resolve, reject) => {
    const user = await Auth.currentAuthenticatedUser();
    return await user.refreshSession(refreshToken, async (err, data) => {
      if (err) {
        await reject(err);
      } else {
        await user.setSignInUserSession(data);
        Amplify.configure({
          API: {
            graphql_headers: async () => {
              return {
                Authorization: data?.idToken?.jwtToken
              }
            }
          }
        })
        await resolve(data);
      }
    });
  });
}

export const newRefreshToken = (buildingIds, accountIds, salesOfficeIds, claims = null) => {
  return async (dispatch: AppDispatch, getState: any) => {
    const state: State = getState()
    const { data: userInfo }: any = state.appData?.userInfo
    if (userInfo) {
      const user = await Auth.currentAuthenticatedUser()
      let update = false
      // To prevent issues with attributes not being strings and causing a parsing issue in the API we
      // filter out any non-string values.
      const newAccessControlObj = {
        b: buildingIds && buildingIds?.filter(e => e).filter(e => typeof e === 'string') || undefined,
        a: accountIds || undefined,
        s: salesOfficeIds || undefined,
        cl: claims ? [...claims, "BuildingAdmin", "BuildingUser", "TraneServiceB"] : [] || undefined
      }
      const existingAttributes = JSON.parse(user.attributes['custom:accessControl'] || "{}")
      Object.keys(newAccessControlObj).forEach(k => {
          newAccessControlObj[k]?.forEach(e => {
            if (!existingAttributes?.[k] || existingAttributes?.[k].indexOf(e) === -1) {
              update = true
            }
          })
      })
      if (update) {
        const response = await API.graphql({
          query: updateUserAttributes,
          variables: {
            requestedAccess: JSON.stringify(newAccessControlObj)
          }
        })
        
        if (response) {
          const session = await Auth.currentSession();
          return await refreshSessionPromise(session.getRefreshToken())
        }
      }
    }
  }
}

// Moved from src/components/buildingNavigation/building-navigation.tsx
export const checkAccessToken = (
  token,
  entityType,
  selectedEntityId,
  updateOrg,
  updateSalesOffice
) => {
  let userHasAccessToEntity = true
  const { bIds, aIds, sIds, isSuperAdmin } = getbuildingIdsFromToken(token)
  if (!isSuperAdmin) {
    if (entityType === LOCATION) {
      if (
        Array.isArray(selectedEntityId)
          ? !selectedEntityId.every((e) => bIds.includes(e))
          : !bIds.includes(selectedEntityId)
      ) {
        userHasAccessToEntity = false
        // setTokenFetchError("You don't have access to selected building")
      }
    } else if (entityType === ORGANIZATION && updateOrg) {
      if (
        Array.isArray(selectedEntityId)
          ? !selectedEntityId.every((e) => aIds.includes(e))
          : !aIds.includes(selectedEntityId)
      ) {
        userHasAccessToEntity = false
        //setTokenFetchError("You don't have access to selected organization")
      }
    } else if (entityType === SALES_OFFICE && updateSalesOffice) {
      if (
        Array.isArray(selectedEntityId)
          ? !selectedEntityId.every((e) => sIds.includes(e))
          : !sIds.includes(selectedEntityId)
      ) {
        userHasAccessToEntity = false
        // setTokenFetchError("You don't have access to selected sales office")
      }
    }
  }
  return userHasAccessToEntity
}

// Moved from src/components/buildingNavigation/building-navigation.tsx
export const refreshTokenFromSelection = async (
  selected,
  dispatch:ThunkDispatch<State, any, AnyAction>,
  fetchBuildings,
  claims:string[],
  updateTokenOrg:boolean,
  updateTokenOrgBuildings:boolean,
  updateTokenSO:boolean
) => {
  if (selected.type === ORGANIZATION) {
    if (selected?.id && updateTokenOrg) {
      await dispatch<any>(
        newRefreshToken(null, [selected?.id], null, claims)
      )
      const token = await getSession(true)
      const hasAccess = checkAccessToken(
        token,
        ORGANIZATION,
        selected.id,
        updateTokenOrg,
        false
      )
      if (!hasAccess) {
        // This is where error could be thrown if not proper access
        return
      }
    } else if (selected?.id && updateTokenOrgBuildings) {
      const selectedBuildings =
        (selected?.buildings?.length && selected.buildings) ||
        (await fetchBuildings({
          filter: {
            and: [
              { isActive: { eq: 1 } },
              { accountId: { eq: selected.id } }
            ]
          }
        }))
      if (selectedBuildings.length < 45) {
        const idList = selectedBuildings?.map((e) => e.id)
        await dispatch<any>(
          newRefreshToken(idList || null, null, null, claims)
        )
        const token = await getSession(true)
        const hasAccess = checkAccessToken(
          token,
          ORGANIZATION,
          idList,
          false,
          false
        )
        if (!hasAccess) {
          // This is where error could be thrown if not proper access
          return
        }
      } else {
        await dispatch<any>(
          newRefreshToken(null, [selected?.id], null, claims)
        )
        const token = await getSession(true)
        const hasAccess = checkAccessToken(
          token,
          ORGANIZATION,
          selected.id,
          updateTokenOrg,
          false
        )
        if (!hasAccess) {
          // This is where error could be thrown if not proper access
          return
        }
      }
    }
  } else if (selected.type === LOCATION) {
    if (selected?.id) {
      await dispatch<any>(
        newRefreshToken(
          [selected?.id],
          updateTokenOrg ? [selected?.accountId] : null,
          updateTokenSO ? [selected?.salesOfficeId] : null,
          claims
        )
      )

      const token = await getSession(true)
      const hasAccess = checkAccessToken(
        token,
        LOCATION,
        selected.id,
        updateTokenOrg,
        updateTokenSO
      )
      if (!hasAccess) {
        return
      }
    }
  } else if (selected.type === SALES_OFFICE) {
    if (selected?.id) {
      await dispatch<any>(
        newRefreshToken(
          null,
          null,
          updateTokenSO ? [selected?.salesOfficeId] : null,
          claims
        )
      )
      const token = await getSession(true)
      const hasAccess = checkAccessToken(
        token,
        SALES_OFFICE,
        selected.id,
        false,
        updateTokenSO
      )
      if (!hasAccess) {
        return
      }
    }
  }
}

// Moved from src/components/buildingNavigation/building-navigation.tsx
export const getBuildingOfferings = async (buildingId: any, refetchBuildingOfferings, dispatch: AppDispatch) => {
  try {
    const offerings =
      buildingId && (await refetchBuildingOfferings({ id: buildingId }))
    if (offerings?.length > 0) {
      const offeringCodes = offerings?.map((x) => {
        // isActive status will be 1, if the offering is approved or grace period
        if (x?.isActive) {
          return x?.code
        }
      })
      dispatch(setSelectedbuildingOfferings(offeringCodes))
    }
  } catch (e) {
    console.error(e)
  }
}


// export const refreshTokenMultipleBuildingIds = (buildingIds = []) => {
//   return async (dispatch: AppDispatch, getState: any) => {
//     if (buildingIds.length === 0) return
//     const {buildingId: mainBuildingSelection} = getSearchParams();
//     mainBuildingSelection && buildingIds.some(x=>x !== mainBuildingSelection) && buildingIds.push(mainBuildingSelection)
//     const state: State = getState()
//     const { data: userInfo }: any = state.appData?.userInfo
//     if (!userInfo?.roles?.indexOf('SuperAdmin') || userInfo?.roles?.indexOf('SuperAdmin') === -1) {
//       const user = await Auth.currentAuthenticatedUser()
//       // const buildingJSONString = JSON.stringify(buildingIds)
//       const newAccessControlObj = JSON.stringify({
//         b: buildingIds || undefined,
//         // a: accountIds || undefined,
//         // s: salesOfficeIds || undefined,
//       })
//       if (user.attributes['custom:accessControl'] !== newAccessControlObj) {
//         return await Auth.updateUserAttributes(user, {
//           'custom:accessControl': newAccessControlObj
//         }).then(async (res) => {
//           if (res) {
//             const token = await getSession(true)
//             Amplify.configure({
//               API: {
//                 graphql_headers: async () => {
//                   return {
//                     Authorization: token
//                   }
//                 }
//               }
//             })
//             const accessibleBuildingIds = getbuildingIdsFromToken(token)
//             dispatch(setUserAccessibleBuildingIds(accessibleBuildingIds))
//             // handled if user doesn't have building
//              return true
//           }
//         }).catch((error) => {
//             console.log("Multiple buildings auth error")
//         })
//       }
//     }
//   }
// }


export const {
  setBreadcrumbs,
  setSelectedLanguage,
  setUserInfo,
  setUserAccess,
  setUserRoles,
  setUserAccessibleBuildingIds,
  setUiMode,
  setBetaUser,
  setDismissedOnboarding
} = appData.actions

export const selectLanguage = (state: TStore) => state.appData.selectedLanguage
export const selectUserInfo = (state: TStore) => state.appData.userInfo.data
export const selectUserPreferredUoM = (state: TStore) => {
  const IP_UOM = UOM['im']
  try { 
    const profileSettings = state.appData?.userInfo?.data?.profileSettings
    if (profileSettings) { 
      const parsedSettings = JSON.parse(profileSettings)
      return UOM[parsedSettings.unitOfMeasurement] || IP_UOM
    } else { 
      return IP_UOM
    } 
  } catch (error) { 
    console.error("Error parsing profileSettings:", error)
    return IP_UOM
  }
}
export const selectUserAccess = (state: TStore) => state.appData.access
export const selectUserRoles = (state: TStore) => state.appData.roles
export const selectUiMode = (state: TStore) => state.appData.uiMode
export const selectBetaUser = (state: TStore) => state.appData.betaUser
export const selectDismissedOnboarding = (state: TStore) => state.appData.dismissedOnboarding
// export const selectUserPermissions = (state: TStore) =>
//   state.appData.userInfo.pages
export const selectUserAccessibleBuildingIds = (state: TStore) => 
  state.appData.userAccessibleBuildingIds

