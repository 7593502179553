import { Chip, ChipColor } from "./Chip"

export const ChipPriority = ({ priorityText }:Props) => {
  const color = getColor(priorityText);
  return (
    <Chip text={priorityText} color={color} />
  )
}

interface Props {
  priorityText: ChipPriorities
}

export type ChipPriorities =
  'Not Prioritized'
  | 'Low'
  | 'Medium'
  | 'High'
  | 'Urgent'

function getColor(priorityText) {
  switch(priorityText.toLowerCase()) {
    case 'not prioritized':
      return ChipColor.default
    case 'low':
      return ChipColor.blue
    case 'medium':
      return ChipColor.orange
    case 'high':
      return ChipColor.red
    case 'urgent!':
      return ChipColor.redInverse
  }
  return ChipColor.default
}