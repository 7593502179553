import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { PortfolioBuildingWrapper } from './styles'
import { pointFormat, titleForType, colorForType, allTypes } from './helpers'
import {
  GET_BUILDING_METER_TOTAL_EQUIPMENT,
  UPDATE_BUILDING,
  ListBuildingEquipments,
  getAssociatedMeterChargeData
} from './graphql'
import { useEnergyCostDataHook } from 'src/pages/energyCostSummary/EnergyCostDataHook'
import moment from 'moment'
import {
  BACKEND_DATE_FORMAT,
  WHOLE_MONTH_FORMAT
} from 'src/components/legacy/common/time-helpers'
import COLORS from 'src/components/legacy/common/colors.json'
import { formatCurrencyPrecision } from 'src/common/currency'
import Tooltip from 'src/components/legacy/components/tooltip/tooltip'
import ProgressBar from 'src/components/legacy/components/progress-bar/progress-bar'
import { Link } from 'react-router-dom'
import { getSearchParams } from 'src/common/helperFunctions.js'
import ChartSettingsLink from '../../components/legacy/chartSettingsLink'
import { usePortfolioScorecardContext } from './PortfolioScorecardContextProvider'
import translate from 'src/common/translations'

const settingsConfig = {
  heading: 'Chart Settings',
  subHeading: 'Add/Edit Building Area',
  inputHeading: 'Total Building Area',
  inputMessage: ['Do not include non-heated/air-conditioned spaces']
}

const PortfolioBuilding = ({
  building,
  buildingsOrder = {},
  chartDates,
  updateTotalCost,
  selectedUnit,
  theHighestTotal,
  disabledTypes
}) => {
  const { meterTotalAllBuildings, setMeterTotalAllbuilding } =
    usePortfolioScorecardContext() || {}

  const [availableCostTypes, setAvailableCostTypes] = useState([])
  const [buildingOrder, setBuildingOrder] = useState(1)
  const [buildingError, setBuildingError] = useState('')
  const [buildingTotal, setBuildingTotal] = useState(null)
  const [totalCostData, setTotalCostData] = useState({
    MonthlyFee: 0,
    ConsumptionCost: 0,
    DemandCost: 0,
    GasCost: 0,
    total: 0
  })

  const translatedTypes = [{
    key: "ConsumptionCost", value: translate("Electric consumption cost")
  },
  {
    key: "DemandCost", value: translate("Electric Demand Cost")
  },
  {
    key: "GasCost", value: translate("Gas cost")
  },
  {
    key: "MonthlyFee", value: translate("Electric Monthly fee")
  },
  ]

  const defaultStartDate = moment()
    .subtract(1, 'months')
    .format(BACKEND_DATE_FORMAT)

  const maxEndDate = moment().format(BACKEND_DATE_FORMAT)

  const displayStartDate = moment(
    chartDates?.chartStartDate || defaultStartDate
  ).format(WHOLE_MONTH_FORMAT)

  const displayEndDate = moment(chartDates?.chartEndDate || maxEndDate).format(
    WHOLE_MONTH_FORMAT
  )
  const { organizationId } = getSearchParams()

  const { loadingEnergyCostData, getEnergyCostData, energyCostData } =
    useEnergyCostDataHook()

  const {
    data: meterTotal,
    refetch: getBuildingTotalMeterEquipment,
    loading: loadingBuildingTotal
  } = useQuery({
    query: GET_BUILDING_METER_TOTAL_EQUIPMENT,
    dataPath: 'data.getBuildingTotalMeterEquipment',
    disableInitialLoad: true
  })

  const {
    data: associatedMeterData,
    refetch: getAssociatedMeterData,
    loading: loadingAssociatedMeterChargeData
  } = useQuery({
    query: getAssociatedMeterChargeData,
    dataPath: 'data.getAssociatedMeterChargeData',
    disableInitialLoad: true
  })

  const { onSubmit: onUpdateBuilding } = useMutation({
    query: UPDATE_BUILDING,
    dataPath: 'data.updateBuilding'
    //errorPolicy: 'global'
  })

  // On ComponentMount - building useEffect will be called
  useEffect(() => {
    if (building?.key) {
      if (!building?.floorArea) {
        setBuildingError('floorarea')
      }

      getBuildingTotalMeterEquipment({
        body: JSON.stringify({ bId: building?.key })
      })
    }
  }, [building])

  // This Effect will be called based on the response of getBuildingTotalMeterEquipment from building useEffect
  useEffect(() => {
    try {
      const res = JSON.parse(meterTotal)
      const totalMetersList = meterTotalAllBuildings
      if (res && res.body) {
        const equipments = res.body?.equipments || []
        if (equipments.length > 0) {
          setBuildingError('')
          const buildingMeterTotal = equipments[0]
          buildingMeterTotal.type = 'VirtualMeter'
          buildingMeterTotal.key = buildingMeterTotal.id
          totalMetersList.push({
            buildingId: building?.key,
            ...buildingMeterTotal
          })
          setMeterTotalAllbuilding([...totalMetersList])
          setBuildingTotal(buildingMeterTotal)
          //Adding Meters availability check and estimates
          const body = {
            bId: building?.key,
            eId: buildingMeterTotal.key,
            eName: buildingMeterTotal?.name,
            eType: buildingMeterTotal.type
          }
          getAssociatedMeterData({
            body: JSON.stringify(body)
          })
        } else {
          setBuildingError('noBuildingMeterTotal')
        }
      } else {
        setBuildingError('unknownerror')
      }
    } catch (error) {
      setBuildingError('unknownerror')
    }
  }, [meterTotal])

  // This will be called once the assocated Meter data is feteched by above useEffect
  useEffect(() => {
    try {
      const res = JSON.parse(associatedMeterData)
      if (res && res.body) {
        const equipments = res.body?.equipments || []
        if (equipments.length > 0) {
          setBuildingError('')
          const chargesAvailable = equipments
            ?.map((eq) => eq.charge)
            ?.some((value) => value)
          if (chargesAvailable) {
            getEnergyCostFromQuery(buildingTotal)
          } else {
            setBuildingError('noestimate')
          }
        } else {
          setBuildingError('noAssociatedMeters')
        }
      } else {
        setBuildingError('unknownerror')
      }
    } catch (error) {
      setBuildingError('unknownerror')
    }
  }, [associatedMeterData])

  // It should run only when date or selected unit is updated
  useEffect(() => {
    const buildingMeterTotal = meterTotalAllBuildings.find(
      (x) => building?.key === x?.buildingId
    )
    if (buildingMeterTotal && buildingError === '') {
      getEnergyCostFromQuery(buildingTotal)
    }
  }, [chartDates, selectedUnit])

  useEffect(() => {
    if (energyCostData) {
      const availableCostTypes = []
      //   formatData()
      let ConsumptionCost = 0,
        MonthlyFee = 0,
        DemandCost = 0,
        GasCost = 0,
        total = 0
      if (energyCostData?.isDataAvailable) {
        ConsumptionCost = energyCostData?.consumptionCost?.values?.reduce(
          (a, b) => a + +b.value,
          0
        )
        MonthlyFee = energyCostData?.additionalCost?.values?.reduce(
          (a, b) => a + +b.value,
          0
        )
        DemandCost = energyCostData?.demandCost?.values?.reduce(
          (a, b) => a + +b.value,
          0
        )
        GasCost = energyCostData?.gasConsumptionCost?.values?.reduce(
          (a, b) => a + +b.value,
          0
        )

        if (ConsumptionCost !== 0) {
          availableCostTypes.push('ConsumptionCost')
        }

        if (MonthlyFee !== 0) {
          availableCostTypes.push('MonthlyFee')
        }

        if (GasCost !== 0) {
          availableCostTypes.push('GasCost')
        }

        if (DemandCost !== 0) {
          availableCostTypes.push('DemandCost')
        }

        setAvailableCostTypes(availableCostTypes)

        if (!building?.floorArea) {
          ConsumptionCost =
            selectedUnit && selectedUnit.key === '$' ? ConsumptionCost : 0
          MonthlyFee = selectedUnit && selectedUnit.key === '$' ? MonthlyFee : 0
          DemandCost = selectedUnit && selectedUnit.key === '$' ? DemandCost : 0
          GasCost = selectedUnit && selectedUnit.key === '$' ? GasCost : 0
        } else if (selectedUnit && selectedUnit.key !== '$') {
          if (selectedUnit.key === '$/ft²') {
            ConsumptionCost /= building.floorArea
            MonthlyFee /= building.floorArea
            DemandCost /= building.floorArea
            GasCost /= building.floorArea
          } else {
            ConsumptionCost /= building.floorArea * 0.09290304
            MonthlyFee /= building.floorArea * 0.09290304
            DemandCost /= building.floorArea * 0.09290304
            GasCost /= building.floorArea * 0.09290304
          }
        }
        // shall re-write in a better way
        total =
          total +
            (disabledTypes.includes('ConsumptionCost') ? 0 : ConsumptionCost) +
            (disabledTypes.includes('MonthlyFee') ? 0 : MonthlyFee) +
            (disabledTypes.includes('DemandCost') ? 0 : DemandCost) +
            (disabledTypes.includes('GasCost') ? 0 : GasCost) || 0
        setTotalCostData({
          MonthlyFee,
          ConsumptionCost,
          DemandCost,
          GasCost,
          total
        })
        updateTotalCost(total, building?.key)
      }
    }
  }, [energyCostData, disabledTypes])

  // Instead of reordering the building list by total cost, creating new order list on the parent component that will be shared with each child
  // By the order from the parent, The component sets itself the DOM position using css order property.
  useEffect(() => {
    setBuildingOrder(buildingsOrder?.[building?.key] ?? 1)
  }, [buildingsOrder?.[building?.key] ?? 1])

  const updateLocationArea = async ({ variables }) => {
    try {
      if (variables?.floorAreaSquareFeet) {
        const input = {
          floorArea: variables.floorAreaSquareFeet,
          id: variables.locationId
        }

        await onUpdateBuilding({ input })
      }
    } catch (error) {}
  }

  const getEnergyCostFromQuery = (selectedEquipment) => {
    const request = {
      startDate: chartDates?.chartStartDate,
      endDate: chartDates?.chartEndDate,
      interval: '1m',
      buildingId: building?.key,
      equipmentId: selectedEquipment?.key,
      equipmentType: selectedEquipment?.type,
      uomDetails: selectedUnit?.key
    }
    getEnergyCostData(request)
  }

  const suffixUnits = selectedUnit.key.replace('$', '')

  return (
    <PortfolioBuildingWrapper
      availableTypesForTooltTip={
        availableCostTypes?.length - disabledTypes?.length
      }
      style={{
        order: buildingOrder
      }}
    >
      <div
        data-testid={`portfolio-scrore-location-${building?.key}`}
        className="locationGraph"
      >
        <div className="locationTitle">
          <Link
            className="tooltip-anchor"
            to={{
              pathname: '/energy-cost-summary',
              search: `?organization=${organizationId}&location=${building?.key}&startDate=${chartDates?.chartStartDate}&endDate=${chartDates?.chartEndDate}`
            }}
          >
            <span className="icon-location" />{' '}
            <span className="locationTitleText">{building.value}</span>
          </Link>
        </div>
        {loadingEnergyCostData ||
        loadingBuildingTotal ||
        loadingAssociatedMeterChargeData ? (
          <div className="progressContainer">
            <div className="progressLabel">Metric Calculating...</div>
            <ProgressBar progress={1} />
          </div>
        ) : hasEffectiveError(buildingError, selectedUnit.value) ? (
          <div className="locationError">
            {buildingError === 'noBuildingMeterTotal' && (
              <>
                There is no building meter total designated for this
                location.&nbsp;
                <RenderEquipmentSetupLink
                  organizationId={organizationId}
                  buildingId={building?.key}
                />
              </>
            )}
            {buildingError === 'noAssociatedMeters' && (
              <>
                There are no meters associated with the building meter
                total.&nbsp;
                <RenderEquipmentSetupLink
                  organizationId={organizationId}
                  buildingId={building?.key}
                />
              </>
            )}
            {buildingError === 'noestimate' && (
              <>
                There are no rates associated with one or more meters at this
                location.&nbsp;
                <>
                  Add{' '}
                  <Link
                    to={{
                      pathname: '/rate-structure-library',
                      search: `?salesOfficeId=${building.salesOfficeId}`
                    }}
                  >
                    electric and gas rates
                  </Link>{' '}
                  as appropriate.
                </>
              </>
            )}
            {buildingError === 'unknownerror' && (
              <span className="error">
                <span className="icon-error" />
                An unknown error has occurred. Please contact your Trane
                Representative.
              </span>
            )}
            {buildingError === 'floorarea' && (
              <>
                No floor area is set for this building.&nbsp;
                <ChartSettingsLink
                  locationId={building.key}
                  value={building.floorArea}
                  settingsConfig={settingsConfig}
                  updateLocationArea={updateLocationArea}
                  chartView={true}
                >
                  {' '}
                  Edit Floor Area
                </ChartSettingsLink>
              </>
            )}
          </div>
        ) : (
          <>
            {energyCostData?.isDataAvailable ? (
              <>
                <Tooltip
                  horizontalPosition="top-center"
                  contentIsSafeHTML={true}
                  content={getGraphTooltipContent(
                    disabledTypes,
                    totalCostData,
                    displayStartDate,
                    displayEndDate,
                    suffixUnits,
                    translatedTypes
                  )}
                  SpanOrDiv="div"
                >
                  <div
                    className="locationBar"
                    style={{
                      width: `${(
                        100 *
                        (totalCostData.total / theHighestTotal)
                      ).toFixed(2)}%`
                    }}
                  >
                    {allTypes
                      ?.filter((t) => !disabledTypes.includes(t))
                      ?.map((t) => (
                        <div
                          key={t}
                          className={`location${t}Bar`}
                          style={{
                            width: `${
                              Math.floor(
                                10000 *
                                  ((totalCostData[t] || 0) /
                                    totalCostData.total)
                              ) / 100
                            }%`
                          }}
                        />
                      ))}
                  </div>
                </Tooltip>
              </>
            ) : (
              <div>No Data</div>
            )}
          </>
        )}
      </div>
      <>
        {energyCostData?.isDataAvailable && (
          <div className="locationTotal">
            <span>
              ${formatCurrencyPrecision(totalCostData.total).toLocaleString()}
            </span>
            {selectedUnit.key !== '$' && (
              <span className="locationTotalUnit">
                {selectedUnit.key.replace('$', '')}
              </span>
            )}
          </div>
        )}
      </>
    </PortfolioBuildingWrapper>
  )
}

// If the location doesn't have floor area, that only matters if a unit with floor area is selected
const hasEffectiveError = (error, currentUnit) =>
  error && !(error === 'floorarea' && currentUnit === '$')

const getGraphTooltipContent = (
  disabledTypes,
  totalCostData,
  startDate,
  endDate,
  suffixUnits = '',
  translatedTypes
) => {
  return `<table style="width: 100%">
          <tr>
              <th colspan="2" style="border-bottom: 1px solid ${
                COLORS.TBA_LIGHT_GREY
              }">
                  ${startDate} &ndash; ${endDate}
              </th>
          </tr>
          ${translatedTypes
            .filter(
              (type) =>
                Boolean(totalCostData[type.key]) && !disabledTypes?.includes(type.key)
            )
            .map((type) =>
              pointFormat(
                `$${formatCurrencyPrecision(
                  totalCostData[type.key]
                )}${suffixUnits}`,
                true,
                type.value,
                null,
                colorForType(type.key)
              )
            )
            .join('')}</table>` // eslint-disable-line
}

const RenderEquipmentSetupLink = ({ organizationId, buildingId }) => (
  <>
    Please modify{' '}
    <Link
      to={{
        pathname: '/equipment-setup',
        search: `?organization=${organizationId}&location=${buildingId}`
      }}
    >
      equipment setup
    </Link>{' '}
    as appropriate.
  </>
)

export default React.memo(PortfolioBuilding)
