import { useState, useEffect, useRef, useCallback } from 'react'
import { API } from 'aws-amplify'
import { getPropByPath } from 'src/utils'
import { useContext } from 'react'
import { LoadTimeContext } from 'src/context'
import { useAppDispatch } from 'src/redux/store'
import { setGlobalError } from '../redux/slicers/globalErrorSlice'
import { newRefreshToken } from 'src/redux/slicers/appData'

export interface IUseQuery {
  query: any
  variables?: any
  onSuccess?: any
  onError?: any
  dataPath?: string
  disableInitialLoad?: boolean
  errorPolicy?: 'none' | 'all' | 'ignore' | 'global'
}

export type IUseQueryRefetchProps = (
  variablesParam?: any,
  extraData?: any,
  newQuery?: IUseQuery
) => Promise<any>

interface IUseMutation {
  query: any
  onSuccess?: any
  onError?: any
  dataPath?: string
  refreshTokenBuildingVariable?: any
  refreshTokenAccountVariable?: any
  refreshTokenSalesOfficeVariable?: any
}

export const useQuery = ({
  query,
  variables,
  onSuccess,
  onError,
  dataPath,
  disableInitialLoad = false,
  errorPolicy = 'none'
}: IUseQuery) => {
  const [data, setData] = useState(null)
  // Add-on : Exposing actual API response to caller of the hook for access other infomration from it.
  const rawDataRef = useRef(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [responseTime, setResponseTime] = useState(null)
  const loadTimeContext = useContext(LoadTimeContext)
  const dispatch = useAppDispatch()

  const setRawData = useCallback(
    (apiData) => {
      rawDataRef.current = apiData
    },
    [rawDataRef]
  )

  const getRawData = useCallback(() => {
    return rawDataRef.current
  }, [rawDataRef])

  useEffect(() => {
    if (!disableInitialLoad) {
      fetchData(variables)
    }
  }, [])

  const fetchData = async (
    variablesParam?: any,
    extraData?: any,
    newQuery: any = query
  ): Promise<any> => {
    query = newQuery
    let apiData: any = {}
    const startTime: any = new Date()
    setResponseTime(null)
    setLoading(true)
    setError(null)
    try {
      apiData = await API.graphql({
        query,
        variables: variablesParam ? variablesParam : variables
      })
      setLoading(false)
      const endTime: any = new Date()
      const timeDiff: any = (endTime - startTime) / 1000
      setResponseTime(timeDiff)
      loadTimeContext?.setLoadContext((loads) => [
        { query, responseTime: timeDiff, type: 'query' },
        ...loads
      ])
      if (apiData && !apiData.errors) {
        // Actual API data
        setRawData(apiData)

        if (dataPath?.length) {
          apiData = getPropByPath(apiData, dataPath, null)
        }
        setData(apiData)
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(apiData)
        }
      } else {
        // Actual API data
        setRawData(null)

        setData(null)
        setError(apiData.errors)
        if (onError && typeof onError === 'function') {
          onError(error)
        }
      }
    } catch (errorData) {
      if (errorData.data) {
        // Actual API data
        setRawData(errorData)

        if (errorPolicy === 'all') {
          let _apiData = errorData

          if (dataPath?.length) {
            _apiData = getPropByPath(_apiData, dataPath, null)
            setData(_apiData)
          } else {
            setData(_apiData.data)
          }
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(_apiData)
          }
          apiData = _apiData
        } else if (errorPolicy === 'ignore') {
          let _apiData = errorData
          if (dataPath?.length) {
            _apiData = getPropByPath(_apiData, dataPath, null)
            setData(_apiData)
          } else {
            setData(_apiData.data)
          }
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(_apiData)
          }
          apiData = _apiData
        } else if (errorPolicy === 'global') {
          let _apiData = errorData
          if (dataPath?.length) {
            _apiData = getPropByPath(_apiData, dataPath, null)
            setData(_apiData)
          } else {
            setData(_apiData.data)
          }
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(_apiData)
          }
          dispatch<any>(setGlobalError({ error: errorData.errors[0].message }))
          apiData = _apiData
        }
        // if (onError && typeof onError === 'function') {
        //   onError(errorData?.errors?.[0])
        // }
      } else {
        // Actual API data
        setRawData(null)

        // TODO: Check error policy here and throw a global error if needed.
        setData(null)
        setError(errorData)
        if (onError && typeof onError === 'function') {
          onError(errorData)
        } else {
          setGlobalError({
            error: errorData?.errors?.[0]?.message || errorData
          })
        }
      }
      setLoading(false)
      const endTime: any = new Date()
      const timeDiff: any = (endTime - startTime) / 1000
      setResponseTime(timeDiff)
      loadTimeContext?.setLoadContext((loads) => [
        { query, responseTime: timeDiff, type: 'query' },
        ...loads
      ])
    }
    return apiData
  }

  return {
    data,
    error,
    loading,
    getRawData,
    refetch: fetchData,
    setError: setError,
    setData: setData,
    responseTime
  }
}

const getObjectValueByPeriodSeparatedString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  const a = s.split('.')
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

export const useMutation = ({
  query,
  onSuccess,
  onError,
  dataPath,
  refreshTokenBuildingVariable,
  refreshTokenAccountVariable,
  refreshTokenSalesOfficeVariable
}: IUseMutation) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [responseTime, setResponseTime] = useState(null)
  const dispatch = useAppDispatch()
  const loadTimeContext = useContext(LoadTimeContext)
  const updateData = async (variablesParam: any) => {
    let apiData: any = {}
    const startTime: any = new Date()
    setLoading(true)
    setError(null)
    try {
      const buildings = refreshTokenBuildingVariable
        ? getObjectValueByPeriodSeparatedString(
            variablesParam,
            refreshTokenBuildingVariable
          )
        : null
      const tempAccount = refreshTokenAccountVariable
        ? getObjectValueByPeriodSeparatedString(
            variablesParam,
            refreshTokenAccountVariable
          )
        : null
      const tempSalesOffice = refreshTokenSalesOfficeVariable
        ? getObjectValueByPeriodSeparatedString(
            variablesParam,
            refreshTokenSalesOfficeVariable
          )
        : null
      await dispatch<any>(
        newRefreshToken(
          buildings ? [buildings] : null,
          tempAccount ? [tempAccount] : null,
          tempSalesOffice ? [tempSalesOffice] : null
        )
      ).then(async () => {
        apiData = await API.graphql({
          query,
          variables: variablesParam
        })
        setLoading(false)
        const endTime: any = new Date()
        const timeDiff: any = (endTime - startTime) / 1000
        setResponseTime(timeDiff)
        loadTimeContext?.setLoadContext((loads) => [
          { query, responseTime: timeDiff, type: 'mutation' },
          ...loads
        ])
        if (
          apiData &&
          !apiData.errors &&
          typeof apiData.data[Object.keys(apiData.data)[0]] !== 'string'
        ) {
          if (dataPath?.length) {
            apiData = getPropByPath(apiData, dataPath, null)
          }
          setData(apiData)
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(apiData)
          }
        } else if (
          typeof apiData.data[Object.keys(apiData.data)[0]] === 'string'
        ) {
          try {
            const responseObj = JSON.parse(
              apiData.data[Object.keys(apiData.data)[0]]
            )
            if (responseObj.statusCode >= 300) {
              dispatch<any>(
                setGlobalError({
                  error: JSON.parse(apiData.data[Object.keys(apiData.data)[0]])
                    .body
                })
              )
            } else {
              // AWSJSON without error
              if (dataPath?.length) {
                apiData = getPropByPath(apiData, dataPath, null)
              }
              setData(apiData)
              if (onSuccess && typeof onSuccess === 'function') {
                onSuccess(apiData)
              }
            }
          } catch (error) {
            // Failed to parse. May just be a string and not awsjson response
            if (dataPath?.length) {
              apiData = getPropByPath(apiData, dataPath, null)
            }
            setData(apiData)
            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess(apiData)
            }
          }
        } else {
          setData(null)
          setError(apiData.errors)
          if (onError && typeof onError === 'function') {
            onError(error)
          } else {
            dispatch<any>(setGlobalError({ error: apiData.errors[0].message }))
          }
        }
      })
    } catch (error) {
      setLoading(false)
      const endTime: any = new Date()
      const timeDiff: any = (endTime - startTime) / 1000
      setResponseTime(timeDiff)
      loadTimeContext?.setLoadContext((loads) => [
        { query, responseTime: timeDiff, type: 'mutation' },
        ...loads
      ])
      setError(error)
      setData(null)
      if (onError && typeof onError === 'function') {
        onError(error)
      } else {
        dispatch<any>(
          setGlobalError({
            error:
              error?.errors?.[0]?.message || 'We have encountered an error.'
          })
        )
      }
      apiData = { error }
      console.log('error has occurred', error)
      return apiData
    }
    return apiData
  }

  return {
    data,
    error,
    loading,
    onSubmit: updateData,
    setError: setError,
    responseTime
  }
}
