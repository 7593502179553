import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BuildingNavigator from 'src/components/buildingNavigation'
import {useRef} from "react"
import { useLocation, useParams } from 'react-router-dom'
import { trackEvent } from "src/amplitude.js"
import { getSearchParams } from "src/common/helperFunctions.js"
import { useSelector } from 'react-redux'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'
import { selectUiMode } from '../../redux/slicers/appData'
import { UiMode } from '../../redux/types/AppTypes'

const Wrapper = styled.div`
  min-height: 100%;
  padding: 0px 30px 50px 20px;
  position: relative;
`

export const PageWrapper = ({ children, navigation = false, navigationProps = {}, amplitudeTag }) => {
  const ref = useRef()
  const { state } = useLocation()
  const [currState, setCurrState] = useState(state && JSON.stringify(state) || JSON.stringify({}))
  const [sentTracking, setSentTracking] = useState(false)  
  const {buildingId, organizationId, salesOfficeId} = getSearchParams()

  React.useEffect(() => {
    if (amplitudeTag && navigation && !sentTracking) {
        if (buildingId || salesOfficeId || organizationId) {
          trackEvent(typeof amplitudeTag === "function" ? amplitudeTag(state) : amplitudeTag, {})
          setSentTracking(true)
        }
    } else if (amplitudeTag && !sentTracking) {
      // No query params needed so can send metrics off immediately
      trackEvent(typeof amplitudeTag === "function" ? amplitudeTag(state) : amplitudeTag)
      setSentTracking(true)
    }
  }, [buildingId, organizationId, salesOfficeId, sentTracking]);

  React.useEffect(() => {
    if (state && JSON.stringify(state) !== currState) {
      setCurrState(JSON.stringify(state))
      setSentTracking(false)
    }
  }, [currState, state]);

  const uiMode = useSelector(selectUiMode);
  const selectedFromRedux = useSelector(selectBuilding)
  const isDenaliTheme = (uiMode === UiMode.denali);
  const showDenaliBuildingSelector = isDenaliTheme && navigationProps?.denaliBuildingPicker;
  const onlySalesOfficeSelectionAllowed = navigationProps?.groupByTag?.length === 1 && navigationProps?.groupByTag[0] === "sales-office"
  return (
    <Wrapper ref={ref} data-test='pageWrapper'>
      { !showDenaliBuildingSelector
        && <BuildingNavigator isShowBuildingPicker={navigation} {...navigationProps} wrapperRef={ref}/>
      }
      {navigation && !onlySalesOfficeSelectionAllowed ? (selectedFromRedux?.id && <>{children}</>) : <>{children}</>   }
    </Wrapper>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node
}

export default PageWrapper
