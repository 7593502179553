import { timeDay, timeFormat, utcFormat, timeMonths, timeWeeks } from 'd3'
import { cond, gt, isNil, T, __ } from 'ramda'
import { parseISO, format } from 'date-fns'

/**
 * function to find days between given date range
 *
 * @param start
 * @param end
 * @returns
 */
function daysBetween(start, end) {
  const now = new Date()
  return timeDay.count(start || now, end || now)
}

/**
 * conditional to provide differenent tick ranges based on the date ranges
 */
const xAxisTimeFormat = cond([
  // if more than three year, display ticks on every two month with the format
  [
    gt(__, 1095),
    () => [
      (start, end) => timeMonths(start, end, 4),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more than two year, display ticks on every two month with the format
  [
    gt(__, 730),
    () => [
      (start, end) => timeMonths(start, end, 3),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more than a year, display ticks on every two month with the format
  [
    gt(__, 365),
    () => [
      (start, end) => timeMonths(start, end, 2),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more than 9 month, display ticks on every month with the format
  [
    gt(__, 270),
    () => [
      (start, end) => timeMonths(start, end, 1),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more than 4 month, display ticks on every two weeks with the format
  [
    gt(__, 124),
    () => [
      (start, end) => timeWeeks(start, end, 2),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more than 8 weeks, display ticks on every weeks with the format
  [
    gt(__, 56),
    () => [
      (start, end) => timeDay.range(start, end, 7),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more than 3 weeks, display ticks on every alternate day with the format
  [
    gt(__, 21),
    () => [
      (start, end) => timeDay.range(start, end, 2),
      timeFormat('%a, %b %d, %Y')
    ]
  ],

  // if more less 3 weeks, display ticks on every day with the format
  [
    T,
    () => [
      (start, end) => timeDay.range(start, end, 1),
      timeFormat('%a, %B %d, %Y')
    ]
  ]
])

/**
 * function to calculate tick values for given date range
 *
 * @param start
 * @param end
 * @returns
 */
export function calculateTickValuesFromDateRange(start, end) {
  const sDay = timeDay.floor(new Date(start))
  const eDay = timeDay.ceil(new Date(end))

  const count = daysBetween(sDay, eDay)

  const [tickSpecifier, formatter] = xAxisTimeFormat(count)

  const ticks = tickSpecifier(sDay, eDay) as Date[]
  const formattedTicks: string[] = ticks.map(utcFormat('%Y-%m-%d'))

  return [formattedTicks, formatter]
}

export function toDates(data = []) {
  return [...data].map((d) => parseISO(d).getTime())
}

/**
 * DEVELOPER can implement own transformer logic. Its not mandatory one.
 *
 * Mock function to transform legacy TC api data to required format for HeatMap chart
 *
 * Implemented for testing purpose with api resonse
 *
 * @param data - Api data
 * @param legend
 * @param yAxisStartDate - Inorder to generate yAxis in 15 mins range, we are using some random date in yAxis
 * @returns
 */
export function dataTransformer(
  data = [],
  legend = 'Demand',
  unit: any,
  yAxisStartDate = '1990-01-11T'
) {
  const bucket: HeatMap[] = []

  const length = data.length

  for (let i = 0; i < length; i++) {
    const d = { ...data[i] }

    const ts = d.timeStamp.replace(' ', 'T')
    d.timeStamp = ts
    d.unit = unit

    const dateTime = ts.split('T')

    const time = dateTime[1]

    bucket.push([
      parseISO(dateTime[0]).getTime(),
      parseISO(yAxisStartDate + time).getTime(),
      isNil(d.value) || isNaN(+d.value)
        ? { value: 'No Data', color: 'rgb(220, 220, 220)' }
        : d.value, // if No data scenario, value should be passed as key value pair to set colors
      legend,
      d // for tooltip purpose this has been passed as additional data
    ])
  }

  return bucket as HeatMap[]
}
