import { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  round,
  getField,
  convertStringToValidNumber
} from 'src/components/legacy/common/helpers.js'
import {
  formatPrice,
  TIME_TO_USE_LIST,
  LIFECYCLE_LIST
} from 'src/components/legacy/common/opportunity.js'

import Select from 'src/components/legacy/components/select/select'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import TimeIntervalSelector from 'src/components/time-interval-selector/time-interval-selector'
import CostSaving from '../cost-savings-view'
import { SelectItemWrapper } from '../../../styles'
import translate, { TranslateComponent } from 'src/common/translations'

const PAYBACK_ROI_OPTIONS = new Map([
  [
    'paybackCalculation',
    {
      title: 'Simple Payback',
      label: 'Provide details to calculate',
      description:
        'Calculation: Cost to implement divided by savings per year.',
      infoText:
        'opportunities:SimplePaybackCalculationPriceDividedBySavingsPerYear',
      timeIntervalLabel: 'Amount of time for payback'
    }
  ],
  [
    'roiCalculation',
    {
      title: 'Simple ROI',
      label: 'Provide details to calculate',
      description:
        'Calculation: Actual savings divided by cost to implement.',
      infoText:
        'opportunities:ReturnOnInvestmentCalculationSavingsDividedByPrice',
      timeIntervalLabel: 'Estimated Opportunity Lifecycle Time'
    }
  ]
])

export function PaybackROIItem({
  paybackCalculation,
  roiCalculation,
  costToImplement,
  costSavings,
  timeToUse,
  lifecycle,
  update,
  errors
}) {
  const [t] = useTranslation()
  const [selectedCalculation, setSelectedCalculation] =
    useState('paybackCalculation')

  useEffect(() => {
    // run once to set default value
    if (roiCalculation.enabled) {
      setSelectedCalculation('roiCalculation')
      update(false, 'paybackCalculation.enabled')
    } else {
      setSelectedCalculation('paybackCalculation')
      update(false, 'roiCalculation.enabled')
    }
  }, [])

  const isPayback = selectedCalculation === 'paybackCalculation'
  const period = formatPrice(costToImplement.priceType, t)
  const isActive = isPayback
    ? Boolean(paybackCalculation.enabled && paybackCalculation.value)
    : Boolean(roiCalculation.enabled && roiCalculation.value)
  const currentCalculation = useMemo(
    () => PAYBACK_ROI_OPTIONS.get(selectedCalculation),
    [selectedCalculation]
  )
  const OPTIONS_LIST = 
    Array.from(PAYBACK_ROI_OPTIONS, ([key, { title }]) => ({
      key,
      value: title
    }))
    
  const value = useMemo(() => {
    if (isPayback && paybackCalculation.enabled && paybackCalculation.value) {
      return `${round(
        costSavings.paybackCalculation.value,
        2
      ).toLocaleString()} ${t('common:yrs')}`
    } else if (roiCalculation.enabled && roiCalculation.value) {
      return `${round(costSavings.roiCalculation.value, 2).toLocaleString()} %`
    } else {
      return null
    }
  }, [isPayback, paybackCalculation, roiCalculation])

  return (
    <SelectItemWrapper>
      <div className="cost-savings-item">
        <Select
          options={OPTIONS_LIST}
          onChange={(value) => {
            if (value === 'paybackCalculation') {
              update(false, 'roiCalculation.enabled')
            } else {
              update(false, 'paybackCalculation.enabled')
            }
            setSelectedCalculation(value)
          }}
          selectedItem={currentCalculation.title}
        />
        {!isActive ? (
          <p className="deactive">
            <span className="main-label"><TranslateComponent>{(currentCalculation.label)}</TranslateComponent></span>
            <span className="desc"><TranslateComponent>{(currentCalculation.description)}</TranslateComponent></span>
          </p>
        ) : (
          <CostSaving
            value={value}
            period={period}
            isEditMode={true}
            infoText={t(currentCalculation.infoText)}
            isPayBackCalculation={true}
          />
        )}
        <div className="custom-content">
          <Checkbox
            label={`${t('common:Show')} ${(currentCalculation.title)}`}
            isChecked={
              isPayback ? paybackCalculation.enabled : roiCalculation.enabled
            }
            id={isPayback ? 'payback' : 'roi'}
            toggleCheck={() => {
              isPayback
                ? update(
                    !paybackCalculation.enabled,
                    'paybackCalculation.enabled'
                  )
                : update(!roiCalculation.enabled, 'roiCalculation.enabled')
            }}
          />
        </div>
        <TimeIntervalSelector
          name={isPayback ? 'payback' : 'roi'}
          label={translate(currentCalculation.timeIntervalLabel)}
          tagList={isPayback ? TIME_TO_USE_LIST : LIFECYCLE_LIST}
          time={
            isPayback
              ? {
                  tag: timeToUse.tag,
                  value: convertStringToValidNumber(timeToUse.value)
                }
              : {
                  tag: lifecycle.tag,
                  value: convertStringToValidNumber(lifecycle.value)
                }
          }
          onChange={(newValue) => {
            isPayback
              ? update(newValue, 'timeToUse')
              : update(newValue, 'lifecycle')
          }}
          validation={
            isPayback
              ? getField(errors, 'timeToUse')
              : getField(errors, 'lifecycle')
          }
        />
      </div>
    </SelectItemWrapper>
  )
}

PaybackROIItem.propTypes = {
  paybackCalculation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  roiCalculation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  costToImplement: PropTypes.shape({
    priceType: PropTypes.string.isRequired
  }).isRequired,
  costSavings: PropTypes.object,
  timeToUse: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  lifecycle: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  update: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default PaybackROIItem
