// Library imports
import { Button, Card, Divider, Flex, Menu, MenuItem, Text } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useRef } from 'react'

// Denali component imports
import {
  faAdd,
  faChevronLeft,
  faChevronRight,
  faDollarSign,
  faEye,
  faEyeSlash
} from 'src/denali-components/lib/pro-solid-svg-icons'

import { formatDollarToPrecision } from 'src/common/currency'

// Local imports
import { useEnergyIntensityBenchmarksAndMarkersContext } from './hooks/use-energy-intensity-benchmarks-and-markers-context'
import styles from './energy-intensity.module.scss'
import { EnergyIntensitySidebarProps } from './types'

const EnergyIntensitySidebar = ({
  isOpen,
  onOpenChange,
  isEUIActive,
  isECIActive,
  onEUIClick,
  onECIClick,
  addBenchmark,
  addEventMarker
}: EnergyIntensitySidebarProps) => {
  const scrollRef = useRef(null)

  const icon = isOpen ? faChevronRight : faChevronLeft
  const euiIcon = isEUIActive ? faEye : faEyeSlash
  const eciIcon = isECIActive ? faEye : faEyeSlash
  const iconColor = 'var(--amplify-colors-neutral-50)'

  const { 
    benchmarks, 
    markers, 
    onToggleBenchmark, 
    onToggleMarker,
    totalEnergyCost,
    chartDates,
    dateRange
  } = useEnergyIntensityBenchmarksAndMarkersContext()

  const benchmarksEnabled = new Set(benchmarks?.filter((b) => b.checked).map((b) => b.id))
  const markersEnabled = new Set(markers?.filter((m) => m.checked).map((m) => m.id))
  const sidebarShouldScroll = benchmarks.length + markers.length > 4;

  // TODO: When Energy Cost + Savings APIs are ready to use, we'll add this back in.
  {/* When collapsed we only show an icon */}
  const energyCost = (isOpen && totalEnergyCost > 0) ? (
    <Flex direction="column" justifyContent="stretch">
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        gap="var(--amplify-space-xxs)"
      >
        <Text fontSize="1rem" fontWeight="500">
          Energy Cost
        </Text>
        <Text fontWeight="600" fontSize="1.75rem">
          {formatDollarToPrecision(totalEnergyCost)}
        </Text>
        <Text fontSize="0.875rem" color="var(--amplify-colors-neutral-50)">
          {moment(chartDates?.chartStartDate).format('MMM DD')} - {dateRange === 'CUSTOM' ? moment(chartDates?.chartEndDate).format('MMM DD') : 'Today'}
        </Text>
      </Flex>
      {/* <Flex
        direction="row"
        justifyContent="flex-start"
        className={styles.energyIntensitySidebarEnergyCostSavings}
      >
        <FontAwesomeIcon icon={faArrowTrendUp} />
        <Text>-$21,349 from the last year</Text>
      </Flex> */}
    </Flex>
  ) : (
    <Button size="small">
      <FontAwesomeIcon icon={faDollarSign} />
    </Button>
  )

  return (
    <div className={`${styles.energyIntensitySidebar} ${isOpen ? '' : styles.collapsed} ${sidebarShouldScroll ? styles.energyIntensitySidebarManualInputsScroll : ''}`}>
      <Card
        variation="elevated"
        className={styles.energyIntensitySidebarCard}
      >
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems={`${isOpen ? 'stretch' : 'center'}`}
        >
          <div className={styles.energyIntensitySidebarToggle}>
            <Button
              variation="link"
              size="small"
              onClick={() => onOpenChange(!isOpen)}
            >
              <FontAwesomeIcon icon={icon} />
            </Button>
          </div>
          <div className={styles.energyIntensitySidebarDefaultToggles}>
            <Flex
              direction="row"
              justifyContent="space-between"
              className={`${styles.energyIntensitySidebarEUIToggle} ${
                isEUIActive ? '' : styles.disabled
              }`}
            >
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={styles.energyIntensitySidebarEUIToggleContent}
                onClick={onEUIClick}
              >
                <Text>Energy Use Intensity (EUI)</Text>
                <FontAwesomeIcon icon={euiIcon} color={iconColor} />
              </Flex>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              className={`${styles.energyIntensitySidebarECIToggle} ${
                isECIActive ? '' : styles.disabled
              }`}
            >
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={styles.energyIntensitySidebarECIToggleContent}
                onClick={onECIClick}
              >
                <Text>Energy Cost Intensity (ECI)</Text>
                <FontAwesomeIcon icon={eciIcon} color={iconColor} />
              </Flex>
            </Flex>
          </div>
          <div className={styles.energyIntensitySidebarDivider}>
            <Divider borderColor="var(--amplify-colors-neutral-10)" />
          </div>
          <div className={styles.energyIntensitySidebarManualInputToggles}>
            <Flex
              direction="column"
              alignItems="stretch"
              justifyContent="flex-start"
            >
              <Flex direction="row" justifyContent="space-between" alignItems="center">
                <Text color="var(--amplify-colors-gray-50)">Manual Inputs</Text>
                <Menu trigger={
                  <Button {...(isOpen ? {} : { variation: 'link' })} size="small">
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>
                }>
                  <MenuItem onClick={(e) => addEventMarker(e)}>Add Marker</MenuItem>
                  <MenuItem onClick={addBenchmark}>Add Benchmark</MenuItem>
                </Menu>
              </Flex>

              <div className={styles.energyIntensitySidebarManualInputsScrollContainer}>
                {sidebarShouldScroll && <div className="scrollOverlayWhiteFade"></div>}
                <div 
                  ref={scrollRef} 
                  className={styles.scrollContainer}
                >
                  {/* Benchmarks, render each one as a toggleable row similar to the ECI/EUI toggles. */}
                  {benchmarks && benchmarks.map((benchmark) => (
                    <Flex
                      key={benchmark.id}
                      direction="row"
                      justifyContent={isOpen ? 'space-between' : 'center'}
                      alignItems="center"
                      className={`${styles.energyIntensitySidebarBenchmarkToggle} ${
                        benchmarksEnabled.has(benchmark.id) ? '' : styles.disabled
                      }`}
                      onClick={() => onToggleBenchmark(benchmark)}
                    >
                      <Text>{benchmark.name}</Text>
                      <FontAwesomeIcon icon={benchmarksEnabled.has(benchmark.id) ? faEye : faEyeSlash} color={iconColor} />
                    </Flex>
                  ))}

                  {/* Markers, render each one as a toggleable row similar to the ECI/EUI toggles. */}
                  {markers && markers.map((marker) => (
                    <Flex
                      key={marker.id}
                      direction="row"
                      justifyContent={isOpen ? 'space-between' : 'center'}
                      alignItems="center"
                      className={`${styles.energyIntensitySidebarMarkerToggle} ${
                        markersEnabled.has(marker.id) ? '' : styles.disabled
                      }`}
                      onClick={() => onToggleMarker(marker)}
                    >
                      <Text>{marker.name}</Text>
                      <FontAwesomeIcon icon={markersEnabled.has(marker.id) ? faEye : faEyeSlash} color={iconColor} />
                    </Flex>
                  ))}
                </div>
              </div>
            </Flex>
          </div>
          <div className={styles.energyIntensitySidebarDivider}>
            <Divider borderColor="var(--amplify-colors-neutral-10)" borderWidth="1" />
          </div>
          <div className={styles.energyIntensitySidebarEnergyCost}>
            {energyCost}
          </div>
        </Flex>
      </Card>
    </div>
  )
}

export default EnergyIntensitySidebar
