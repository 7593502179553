// Library imports
import { Button, Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Appwide imports
import { getSearchParams, getSearchParamsString } from 'src/common/helperFunctions'
import translate, { TranslateComponent } from 'src/common/translations'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import ChartMinMaxSettings from 'src/components/chart-min-max-settings'
import ChartTypeSelector from 'src/components/chart-type-selector/chart-type-selector'
import { Drawer, DrawerSection, NotDrawer } from 'src/components/drawer/drawer'
import { Content } from 'src/components/layouts/'
import PageHeader from 'src/components/pageHeaderNew/header'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { useQuery } from 'src/hooks/APIHooks'
import { selectUiMode, selectBetaUser } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'

// Legacy components
import {
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import { GlobalStyle } from 'src/components/legacy/components/global-reset/global-reset'

// Denali components
import { Banner as DenaliBanner } from 'src/denali-components/Banner/Banner'
import EnergyIntensity from 'src/denali-pages/EnergyIntensity/EnergyIntensity'
import EnergyIntensityChartMinMaxSettings from 'src/denali-pages/EnergyIntensity/EnergyIntensityChartMinMaxSettings'
import { faBolt, faGear, faRotateLeft } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from "src/denali-components/Page/Page"

// Local imports
import { PAGE_TITLE, EUI_CHART_INITIAL_VIEW } from './constants'
import DataChart from './DataChart'
import { listUtilityDataByBuilding } from './graphql'
import Markers from './Markers'
import {
  ToolBarWrapper,
  ActionButton,
  EIContainer,
  MarkerDIV,
  ChartDIV,
  EnergyIntensityContainerWrapper,
  EnergyIntensitySettingsButton
} from './styles'
import {
  Error,
  ErrorContainer,
  ErrorIcon,
  TextWrapper
} from '../utilityBillData/styles'

const EnergyIntensityContainerMain = () => {
  const { width } = useBreakPoint()
  const searchParams = getSearchParams()
  const navigate = useNavigate()
  const uiMode = useSelector(selectUiMode)
  const betaUser = useSelector(selectBetaUser)
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId
  const translatedReportButtonText = translate('Create Report')
  const translatedBannerContent = translate('You are using the new design.')
  const [viewConfig, setViewConfig] = useState(EUI_CHART_INITIAL_VIEW)
  const [drawerIsOpen, setDrawerIsOpen] = useState(true)
  const [isChartSettingsOpen, setIsChartSettingsOpen] = useState(false)
  const [markers, setMarkers] = useState([])
  const [axisValues, setAxisValues] = useState({})
  const [benchmarks, setBenchmarks] = useState([])
  const toggleDrawer = () => setDrawerIsOpen((drawerIsOpen) => !drawerIsOpen)
  const [chartMinMax, setChartMinMax] = useState({
    section1min: 0,
    section1max: 100,
    section2min: 0,
    section2max: 100
  })
  const [customMinMax, setCustomMinMax] = useState({
    section1min: 0,
    section1max: 100,
    section2min: 0,
    section2max: 100
  })
  const [isDefaultYaxis, setIsDefaultYaxis] = useState(true)
  const [chartViewType, setChartViewType] = useState('line')
  const [toggleBenchmarks, setToggleBenchmarks] = useState()

  const settingsConfig = {
    heading: 'Chart Settings',
    subHeader: 'Add/Edit Building Area',
    inputHeading: 'Total Building Area',
    inputMessage: 'Do not include non-heated/air-conditioned spaces',
    chartPage: true,
    sectionHeader: translate('Edit Axis Ranges'),
    sections: [
      {
        key: 'section1',
        name: translate('Left Outer Axis'),
        displayDecimal: false,
        fields: {
          min: 0,
          max: 0
        },
        errors: null
      },
      {
        key: 'section2',
        name: translate('Right Outer Axis'),
        displayDecimal: true,
        fields: {
          min: 0,
          max: 0
        },
        errors: null
      }
    ]
  }

  const newSettingsConfig = {
    heading: 'Chart Settings',
    subHeader: 'Add/Edit Building Area',
    inputHeading: 'Total Building Area',
    inputMessage: 'Do not include non-heated/air-conditioned spaces',
    chartPage: true,
    sectionHeader: 'Edit EUI/ECI Axis Ranges',
    sections: [
      {
        key: 'section1',
        name: 'EUI Axis',
        displayDecimal: false,
        fields: {
          min: 0,
          max: 0
        },
        errors: null
      },
      {
        key: 'section2',
        name: 'ECI Axis',
        displayDecimal: true,
        fields: {
          min: 0,
          max: 0
        },
        errors: null
      }
    ]
  }

  const [chartDates, setChartDates] = useState(null)
  const [allChartDates, setAllChartDates] = useState(null)

  const { data: utilityData, refetch: refetchUtilityData } = useQuery({
    query: listUtilityDataByBuilding,
    dataPath: 'data.listUtilityDataByBuilding.items',
    variables: { accountId: organizationId },
    disableInitialLoad: true
  })

  useEffect(() => {
    if (buildingId) {
      const filter = { month: { between: [1, 12] } }
      refetchUtilityData({ filter, buildingId }).then((res) => {
        const dates = res
          .map((e) => moment([e.year, e.month - 1, 1]))
          .sort((a, b) => (a.valueOf() > b.valueOf() ? 1 : -1))
        // TODO: Update start and end date from url to the chart dates
        const dateToUse = {
          chartStartDate: dates.length
            ? dates[0].startOf('month').format(BACKEND_DATE_FORMAT)
            : moment()
                .subtract(1, 'year')
                .add(1, 'day')
                .format(BACKEND_DATE_FORMAT),
          chartEndDate: dates.length
            ? dates[dates.length - 1].endOf('month').format(BACKEND_DATE_FORMAT)
            : moment().format(BACKEND_DATE_FORMAT),
          dateError: false
        }
        setChartDates(dateToUse)
        setAllChartDates(dateToUse)
      })
    }
  }, [buildingId])

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError
    })
  }

  const onMarkersUpdate = (markersData) => {
    setMarkers(markersData)
  }

  const onBenchmarksUpdate = (benchmarksData) => {
    setBenchmarks(benchmarksData)
  }

  const navigateToCreateReport = () => {
    const search = getSearchParamsString()
    navigate(`/reports/energy-reports/add?${search}&reportType=EnergyUsageReport`)
  }

  const updateSettingsConfig = (values, isSaved) => {
    setCustomMinMax(values)
    if (isSaved) {
      setIsDefaultYaxis(false)
    }
  }

  const updateChartMinMax = useCallback((values) => {
    setChartMinMax(values)
  }, [])

  // Reset the chart settings to the default values.
  const resetChartSettings = useCallback(() => {
    setIsDefaultYaxis(true)
    setCustomMinMax({
      section1min: 0,
      section1max: 100,
      section2min: 0,
      section2max: 100
    })
  }, [])

  const showBenchmarks = useCallback((props) => {
    setToggleBenchmarks(props?.map(m=>m?.text?.toLowerCase()))
  }, [])

  if (uiMode === UiMode.denali && betaUser) {
    return (
      <>
        <Page
          title={<TranslateComponent>Energy Usage / Cost Intensity</TranslateComponent>}
          icon={<FontAwesomeIcon icon={faBolt} />}
          iconColor={IconColor.blue}
          testid="energy-intensity-page"
          actions={[
            <Button
              key='add'
              data-testid="addReportButton"
              ariaLabel={translatedReportButtonText}
              onClick={() => navigate(`/reports/add?organization=${organizationId}${buildingId ? `&location=${buildingId}` : ""}`)}>
              <TranslateComponent>Create Report</TranslateComponent>
            </Button>,
            <Menu
              key='settings'
              trigger={
                <EnergyIntensitySettingsButton data-testid="energyIntensitySettings">
                  <MenuButton className="button-icon" data-testid="createReportMenu"><FontAwesomeIcon icon={faGear} /></MenuButton>
                </EnergyIntensitySettingsButton>
              }>
                <MenuItem key="editChartSettings" onClick={() => setIsChartSettingsOpen(true)}>
                  <FontAwesomeIcon icon={faGear} />
                  <TranslateComponent>Edit Chart Settings</TranslateComponent>
                </MenuItem>
                <MenuItem key="resetChartSettings" onClick={resetChartSettings}>
                  <FontAwesomeIcon icon={faRotateLeft} />
                  <TranslateComponent>Reset Chart Settings</TranslateComponent>
                </MenuItem>
            </Menu>,
          ]}
        >
          <EnergyIntensity 
            testName='energy-intensity-chart-page'
            chartData={utilityData}
            chartDates={chartDates}
            allChartDates={allChartDates}
            markers={markers}
            benchmarks={benchmarks}
            onChartControlUpdate={onChartControlUpdate}
            onChangeChartViewType={(chartView) =>
              setChartViewType(chartView)
            }
            onMarkersUpdate={onMarkersUpdate}
            onBenchmarksUpdate={onBenchmarksUpdate}
            selectMonth={true}
            updateChartMinMax={updateChartMinMax}
            isDefaultYAxis={isDefaultYaxis}
            customMinMax={customMinMax}
          />
          {isChartSettingsOpen && 
            <EnergyIntensityChartMinMaxSettings
              settingsConfig={newSettingsConfig}
              updateSettingsConfig={updateSettingsConfig}
              initialSectionValues={
                isDefaultYaxis ? chartMinMax : customMinMax
              }
              setIsChartSettingsOpen={setIsChartSettingsOpen}
            />
          }
        </Page>
      </>
    )
  }

  return (
    <EnergyIntensityContainerWrapper testName="energy-intensity-chart-page">
        <GlobalStyle />
        {!buildingId && organizationId ? (
          <ErrorContainer>
            <Error>
              <ErrorIcon className="icon icon-error" />
              <TextWrapper className="text-container">
                Organizational EUI/ECI indices are not yet available, but coming
                soon! Please select a building to see its EUI/ECI score.
              </TextWrapper>
            </Error>
          </ErrorContainer>
        ) : (
          <>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              {(uiMode === UiMode.denali) && 
                <div style={{ marginBottom: '2rem' }}>
                  <DenaliBanner content={translatedBannerContent} />
                </div>
              }
              <PageHeader
                pageTitle={<div><TranslateComponent>{PAGE_TITLE}</TranslateComponent></div>}
                titleTools={
                  <ToolBarWrapper testName="energy-intensity-toolbar">
                    <ChartTypeSelector
                      viewConfig={viewConfig}
                      setViewConfig={setViewConfig}
                      types={['line', 'bar']}
                      series={['eui', 'eci']}
                      onChangeChartViewType={(chartView) =>
                        setChartViewType(chartView)
                      }
                    />
                    <ActionButton
                      className="action medium"
                      onClick={navigateToCreateReport}
                    >
                      <TranslateComponent>CREATE REPORT</TranslateComponent>
                    </ActionButton>
                    <ChartMinMaxSettings
                      locationId={buildingId}
                      settingsConfig={settingsConfig}
                      update={updateSettingsConfig}
                      initialSectionValues={
                        isDefaultYaxis
                          ? axisValues?.y2?.domain && axisValues?.y1?.domain 
                            ? {
                              section1min: axisValues.y1.domain?.[0],
                              section1max: axisValues.y1.domain?.[1],
                              section2min: axisValues.y2.domain?.[0],
                              section2max: axisValues.y2.domain?.[1]
                            }
                            : chartMinMax
                          : customMinMax
                      }
                    ></ChartMinMaxSettings>
                  </ToolBarWrapper>
                }
              />
            </Content>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              {chartDates && (
                <EIContainer
                  testName="energy-intensity-container"
                  key={buildingId}
                >
                  <Drawer drawerIsOpen={drawerIsOpen} toggleDrawer={toggleDrawer} testName='energy-intersity'>
                    <DrawerSection>
                      <MarkerDIV>
                        <Markers
                          chartDates={chartDates}
                          onMarkersUpdate={onMarkersUpdate}
                          onBenchmarksUpdate={onBenchmarksUpdate}
                          toggleBenchmarks={toggleBenchmarks?.join(',')}
                        />
                      </MarkerDIV>
                    </DrawerSection>
                  </Drawer>
                  <ChartDIV testName='energy-intersity-chart-container'>
                    <NotDrawer>
                      <ChartDateControls
                        testName='energy-intersity'
                        startDate={chartDates?.chartStartDate}
                        endDate={chartDates?.chartEndDate}
                        startDisableAfterGivenDate={chartDates?.chartEndDate}
                        endDisableBeforeGivenDate={chartDates?.chartStartDate}
                        onUpdate={onChartControlUpdate}
                        rangesList={['1W', '1M', '3M', '6M', 'YTD', '1Y']}
                        selectMonth={true}
                      />
                      <DataChart
                        markers={markers}
                        benchmarks={benchmarks}
                        chartDates={chartDates}
                        data={utilityData}
                        isDefaultYaxis={isDefaultYaxis}
                        customMinMax={customMinMax}
                        updateChartMinMax={updateChartMinMax}
                        chartViewType={chartViewType}
                        showBenchmarks={showBenchmarks}
                        toggleBenchmarks={toggleBenchmarks?.join(',')}
                        setAxisValues={setAxisValues}
                      />
                    </NotDrawer>
                  </ChartDIV>
                </EIContainer>
              )}
            </Content>
          </>
        )}
      </EnergyIntensityContainerWrapper>
  )
}

export default EnergyIntensityContainerMain
