import { TranslateComponent } from 'src/common/translations'
import { TrendlineContainer } from './CustomKPIPointStyle'
import {
  getIcon,
  getValuesCurrentBg,
  GOAL_TYPE_POINT,
  WIDGET_STYLES,
  getStartAndEndDate
} from './helper'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import { getDateFormatByLocale } from "../../../../common/chartHelperFunctions"

const CustomKPIPointPreview = ({ widgetDetails }) => {
  const [t] = useTranslation()
  const currentBG = getValuesCurrentBg(
    widgetDetails?.previousPeroid,
    widgetDetails?.currentPeriod,
    widgetDetails?.goalCurrentPeriod,
    widgetDetails?.trendUpIsGood
  )
  const selectedTimePeriod =
    widgetDetails?.selectedTimePeriod === 'all-time-available'
      ? 'current-month'
      : widgetDetails?.selectedTimePeriod

  const date = getStartAndEndDate(selectedTimePeriod, DATE_FORMAT)
  const translateGoalType = {
    "met": "Met",
    "beat": "Beat",
    "missed": "Missed",
  }
  return (
    <TrendlineContainer>
      <div
        className={`icon icon-${getIcon(
          widgetDetails?.selectedKpiMeasurement
        )} icon-type item1`}
      ></div>
      <div
        className="item2"
        style={{
          background:
            widgetDetails?.goalType === GOAL_TYPE_POINT.missed
              ? WIDGET_STYLES.goalBlockBgMissed
              : WIDGET_STYLES.goalBlockBg,
          color: WIDGET_STYLES.textLight
        }}
      >
        <TranslateComponent>{`${translateGoalType[widgetDetails?.goalType]} Goal`}</TranslateComponent>
        <p className="date-container">{`${moment(date?.startDate).format(getDateFormatByLocale('MM/DD/YYYY'))} - ${moment(date?.endDate).format(getDateFormatByLocale('MM/DD/YYYY'))}`}</p>
      </div>
      <div className="item3">
        <TranslateComponent>{widgetDetails?.selectedKpiMeasurement
          ?.split(/(?=[A-Z])/)
          .join(' ')
          .toUpperCase()}</TranslateComponent>
      </div>
      <div className="row-2">
        <div
          className="previous"
          style={{ background: WIDGET_STYLES.valuesBlockBg }}
        >
         <TranslateComponent>PREVIOUS</TranslateComponent> 
          <p className="kpi-values">{widgetDetails?.previousPeroid}</p>
        </div>
        <div
          className="current"
          style={{
            background: currentBG,
            color:
              currentBG === WIDGET_STYLES.valuesBlockBgYellow
                ? WIDGET_STYLES.textDark
                : WIDGET_STYLES.textLight
          }}
        >
          <TranslateComponent>CURRENT</TranslateComponent> 
          <p className="kpi-values">{widgetDetails?.currentPeriod}</p>
        </div>
        <div
          className="goal"
          style={{ background: WIDGET_STYLES.valuesBlockBg }}
        >
          <TranslateComponent>GOAL</TranslateComponent> 
          <p className="kpi-values">{widgetDetails?.goalCurrentPeriod}</p>
        </div>
      </div>
    </TrendlineContainer>
  )
}
export default CustomKPIPointPreview
