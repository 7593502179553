import moment from 'moment'

export const ACCEPTED_FILE_TYPES = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pot',
  'pptx',
  'potx',
  'jpg',
  'png',
  'gif',
  'bmp',
  'mpg',
  'pdf',
  'txt',
  'csv',
  'jpeg'
]

export const CONFIG_TYPES = ['Contract', 'Offering']

export const getOfferingDetailsBySubscriptionName = (
  contractsConfig,
  offeringsConfig,
  contract
) => {
  const offeringCode = contractsConfig.find(
    (cc) => cc.Contract === contract
  )?.Offering
  const offering = offeringsConfig.find((cc) => cc.Code === offeringCode)
  return offering
}

export const OFFERING_MANAGER = 'Trane Offerings Manager'

const ALL_AM = [
  'AM DX 30min',
  'AM DX 1hr',
  'AM DX 2h',
  'AM Chiller 30min',
  'AM Chiller 1hr',
  'AM Chiller 2h',
  'AM DX/Chiller 30min',
  'AM DX/Chiller 1hr',
  'AM DX/Chiller 2h',
  'AM On Demand 2h',
  'AM On Demand 30min',
  'AM IVR/On Demand 2h',
  'AM IVR/On Demand 30min'
]
const ALL_CAP = ['CAP Data', 'CAPxPRO', 'CAPxM&V']
const CAP_DRIVE = ['CAP TestDrive']
const ALL_CMSA = ['DI-CH', 'DI-AHU']
const CS_BR = ['BR', 'CS']
const UBM = ['Utility Bill Management']
const ALL_CONTRACTS = [
  ...ALL_AM,
  ...ALL_CAP,
  ...ALL_CMSA,
  ...CAP_DRIVE,
  ...CS_BR,
  ...UBM,
]

export const hasCMSA = (offering) =>
  Array.isArray(offering)
    ? offering.some((o) => ALL_CMSA.includes(o))
    : ALL_CMSA.includes(offering)

// Default Contract Period is set here but can be updated with a data from API
export const OFFERING_LIST = new Map([
  [
    'CAP TestDrive',
    {
      order: 0,
      allowedSubscriptions: [...CS_BR, ...UBM],
      defaultContractPeriod: 90
    }
  ],
  [
    'CAP Data',
    {
      order: 1,
      allowedSubscriptions: [...ALL_AM, ...CS_BR, ...UBM],
      defaultContractPeriod: 365
    }
  ],

  [
    'CAPxPRO',
    {
      order: 2,
      allowedSubscriptions: [...ALL_AM, ...CS_BR, ...UBM],
      defaultContractPeriod: 365
    }
  ],
  [
    'CAPxM&V',
    {
      order: 3,
      allowedSubscriptions: [...ALL_AM, ...CS_BR, ...UBM],
      defaultContractPeriod: 365
    }
  ],
  [
    'DI-AHU',
    {
      order: 4,
      allowedSubscriptions: [...ALL_AM, ...CS_BR, ...ALL_CMSA, ...UBM]
    }
  ],
  [
    'DI-CH',
    {
      order: 5,
      allowedSubscriptions: [...ALL_AM, ...CS_BR, ...ALL_CMSA, ...UBM]
    }
  ],
  [
    'AM DX 30min',
    {
      order: 6,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM DX 1hr',
    {
      order: 7,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM DX 2h',
    {
      order: 8,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM Chiller 30min',
    {
      order: 9,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM Chiller 1hr',
    {
      order: 10,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM Chiller 2h',
    {
      order: 11,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM DX/Chiller 30min',
    {
      order: 12,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM DX/Chiller 1hr',
    {
      order: 13,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM DX/Chiller 2h',
    {
      order: 14,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM On Demand 2h',
    {
      order: 15,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM On Demand 30min',
    {
      order: 16,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM IVR/On Demand 2h',
    {
      order: 17,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'AM IVR/On Demand 30min',
    {
      order: 18,
      allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA, ...CS_BR, ...UBM],
      defaultContractPeriod: null
    }
  ],
  [
    'BR',
    {
      order: 19,
      allowedSubscriptions: [
        ...ALL_CAP,
        ...ALL_CMSA,
        ...ALL_AM,
        'CS',
        ...CAP_DRIVE,
        ...UBM,
      ],
      defaultContractPeriod: null
    }
  ],
  [
    'CS',
    {
      order: 20,
      allowedSubscriptions: [
        ...ALL_CAP,
        ...ALL_CMSA,
        ...ALL_AM,
        'BR',
        ...CAP_DRIVE,
        ...UBM,
      ],
      defaultContractPeriod: null
    }
  ],
  [
    'Utility Bill Management',
    {
      allowedSubscriptions: ALL_CONTRACTS,
    }
  ],
])

export const getOfferingName = (config, name) => {
  if (!config) {
    return name
  }
  const contract = config.find((c) => c.Contract === name)
  return contract?.Subscription || name
}

export const CONTRACT_PERIODS = [
  {
    key: 'trial',
    label: '3 months',
    days: [89, 90, 91],
    value: { key: 'M', duration: 3 }
  },
  {
    key: '1-year',
    default: true,
    label: '1 Year',
    days: [364, 365],
    value: { key: 'Y', duration: 1 }
  },
  {
    key: '14-month',
    label: '14 months',
    days: [423, 424, 425],
    value: { key: 'M', duration: 14 }
  },
  {
    key: '2-year',
    label: '2 Years',
    days: [729, 730],
    value: { key: 'Y', duration: 2 }
  },
  {
    key: '3-year',
    label: '3 Years',
    days: [1094, 1095],
    value: { key: 'Y', duration: 3 }
  },
  {
    key: '4-year',
    label: '4 Years',
    days: [1460],
    value: { key: 'Y', duration: 4 }
  },
  {
    key: '5-year',
    label: '5 Years',
    days: [1825, 1826],
    value: { key: 'Y', duration: 5 }
  },
  {
    key: 'custom',
    label: 'Custom',
    days: [0],
    value: { key: 'd', duration: 0 }
  }
]

export const getDefautInitialContractPeriod = () =>
  CONTRACT_PERIODS.find((period) => period.default)?.key || ''

export const getDaysDiffBetweenDates = (startDate, endDate) =>
  moment(endDate).diff(moment(startDate).startOf('day'), 'days')

export const getExpirationDateByContractPeriod = ({
  contractStartDate,
  contractPeriod,
  expDate
}) => {
  if (contractPeriod !== 'custom') {
    const lengthInMonths = CONTRACT_PERIODS.find(
      ({ key }) => key === contractPeriod
    ).value
    const expirationDate = moment(
      moment(contractStartDate)
        .add(moment.duration({ [lengthInMonths.key]: lengthInMonths.duration }))
        .format('MM/DD/YYYY')
    )
      .subtract(1, 'day')
      .format('MM/DD/YYYY')
    return expirationDate
  }
  return expDate
}

export const getSubscriptionLengthInDaysByPeriod = ({
  contractPeriod,
  contractStartDate,
  expirationDate
}) => {
  if (contractPeriod !== 'custom') {
    const expirationDate = getExpirationDateByContractPeriod({
      contractStartDate,
      contractPeriod,
      expirationDate
    })
    const totalSubDays = moment(expirationDate).diff(
      moment(contractStartDate).startOf('day'),
      'days'
    )
    return totalSubDays
  } else {
    return getDaysDiffBetweenDates(contractStartDate, expirationDate)
  }
}

export const getContractPeriodByExpirationDate = ({
  contractStartDate = null,
  expirationDate = null
}) => {
  if (!expirationDate) {
    return getDefautInitialContractPeriod()
  }
  const noOfDays = getDaysDiffBetweenDates(contractStartDate, expirationDate)
  const defaultContractPeriodKey = CONTRACT_PERIODS.find(({ days }) =>
    days.includes(noOfDays)
  )?.key
  return defaultContractPeriodKey || 'custom'
}

export const STATUS_TO_SAVE = {
  APPROVED: 'Approved',
  REQUESTED: 'Requested',
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  DELETED: 'Deleted'
}

export const STATUS = new Map([
  ['Active', 'Active'],
  ['Expired', 'Expired'],
  ['Expiring', 'Expiring'],
  ['Requested', 'Requested'],
  ['Declined', 'Declined'],
  ['Cancelled', 'Cancelled']
])

export const STATUS_TO_DISPLAY_MAP = new Map([
  ['Active', 'Active'],
  ['Expired', 'Expired'],
  ['Expiring', 'Contract Expiring in 30 days'],
  ['Requested', 'Requested'],
  ['Declined', 'Declined'],
  ['Cancelled', 'Cancelled']
])

export const getStatusByValue = (searchValue) => {
  for (let [key, value] of STATUS_TO_DISPLAY_MAP.entries()) {
    if (key === searchValue) return key
  }
}

export const STATUS_OPTION_LIST = Array.from(STATUS, ([key, value]) => ({
  name: value,
  key
}))

export function getStatusOptionList(t) {
  if (!t) {
    return STATUS_OPTION_LIST
  }
  return Array.from(STATUS.keys(), (key) => ({
    name: key,
    value: t(`contracts-and-offerings:statuses>${key}`) || key
  }))
}

const OFFERING_TYPE_TRANS = {
  allOfferings: 'All Offerings',
  BP: 'BP Offerings',
  EP: 'EP Offerings',
  TS: 'Tenant Services Offerings'
}

const TYPE = new Map([
  ['BP', 'BP Offerings'],
  ['EP', 'EP Offerings'],
  ['TS', 'Tenant Services Offerings'],
  ['CAP', 'CAP'],
  ['DI-CH', 'Digital Inspections - Chiller'],
  ['DI-AHU', 'Digital Inspections - Air Handling Unit'],
  ['AM', 'AM'],
  ['BR', 'Baseline Report'],
  ['CS', 'Coaching Services']
])

export const TYPE_OPTION_LIST = Array.from(TYPE, ([key, value]) => ({
  name: key,
  value
}))

export function getTypeOptionList() {
  return Array.from(TYPE.keys(), (key) => ({
    value: TYPE.get(key),
    name: key == 'CAP' ? 'CAP Offerings' : OFFERING_TYPE_TRANS[key] || key
  }))
}

export const isOfferingDisabled = (
  selectedOfferings,
  offeringSubscriptionName
) => {
  if (!ALL_CONTRACTS.includes(offeringSubscriptionName)) {
    return false
  }
  if (!selectedOfferings.length) {
    return false
  } else {
    const filteredSelectedOfferings = selectedOfferings.filter((name) =>
      OFFERING_LIST.get(name)
    )
    if (filteredSelectedOfferings?.length == 0) {
      return false
    }
    return (
      filteredSelectedOfferings.some(
        (name) => !OFFERING_LIST.get(name)?.allowedSubscriptions?.length
      ) ||
      (filteredSelectedOfferings.length === 1
        ? !OFFERING_LIST.get(
            filteredSelectedOfferings[0]
          )?.allowedSubscriptions?.includes(offeringSubscriptionName)
        : !filteredSelectedOfferings.every((name) =>
            OFFERING_LIST.get(name)?.allowedSubscriptions?.includes(
              offeringSubscriptionName
            )
          ))
    )
  }
}

export const filterOfferings = (
  contractsConfig = [],
  selectedOfferings,
  disableSelected
) => {
  const data = contractsConfig
    .map(({ Contract: name, ...props }) => ({
      key: name,
      name,
      label: props.Subscription || name,
      fullLabel: props.SubscriptionFull || name,
      disabled: disableSelected
        ? selectedOfferings.some((sname) => sname === name) ||
          isOfferingDisabled(selectedOfferings, name)
        : isOfferingDisabled(selectedOfferings, name)
    }))
    .sort(
      (a, b) =>
        OFFERING_LIST.has(a.name) &&
        OFFERING_LIST.has(b.name) &&
        OFFERING_LIST.get(a.name).order - OFFERING_LIST.get(b.name).order
    )
  console.log(data)
  return data
}

export const getDefautContractPeriod = (
  contractsConfig,
  offering,
  currentContractPeriod
) => {
  if (
    [
      'CAP TestDrive',
      'CAPxPRO',
      'CAP Data',
      'CAPxM&V',
      'DI-CH',
      'DI-AHU',
      'Utility Bill Management',
    ].includes(offering)
  ) {
    const defaultContractPeriod = contractsConfig.find(
      (config) => config.Contract === offering
    )?.Duration
    const defaultContractPeriodKey =
      CONTRACT_PERIODS.find(({ days }) =>
        days.includes(parseInt(defaultContractPeriod))
      )?.key || getDefautInitialContractPeriod()
    return defaultContractPeriodKey
  }
  return currentContractPeriod
}

const isPeriodDisabled = (offering, currentPeriod) => {
  switch (offering) {
    case 'BP Test Drive':
      return currentPeriod !== 'trial'
    default:
      return false
  }
}

export const filterContractPeriods = (offerings) =>
  CONTRACT_PERIODS.map(({ key, label }) => ({
    key,
    label,
    disabled:
      offerings.length === 1 ? isPeriodDisabled(offerings[0], key) : false
  }))
