import styled from 'styled-components'

export const UtilityMessageContainer = styled.div`
  width: 50%;
  padding-left: 10px
`

export const UtilityMessageHeader = styled.h1`
  font-size: 20px;
`

export const UtilityMessageTextContainer = styled.div`
  height: 150px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 10px;
  background: white;
  text-align: center;
`

export const UtilityMessage = styled.p`
  font-size: 14px;
`
