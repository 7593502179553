import { Dispatch, useState, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

// Denali component imports
import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { Chip } from 'src/denali-components/Table/Chip';
import { Chips } from 'src/denali-components/Table/Chips';
import { ChipStatus } from 'src/denali-components/Table/ChipStatus';
import { DateRange } from 'src/denali-components/Table/DateRange';
import { IconCell } from 'src/denali-components/Table/IconCell';
import { Visibility } from 'src/denali-components/Table/Visibility';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/denali-components/Tooltip/Tooltip';
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble';

// Global imports
import { trackEvent } from "src/amplitude.js";
import { USER_EVENTS } from "src/amplitude-categories";
import { TranslateComponent } from 'src/common/translations';

// Local imports
import styles from "./reports.module.scss"
import { DocumentModal } from './DocumentModal';

/**
 * ReportsList
 * View replacement for src/pages/documentsAndReports/DocumentsAndReportsList.tsx
 */
export const ReportsList = ({ rows, optionsTranslated, getListRowControl, filtersList, downloadFile, listDataIsLoading, noTableDataAction, isTranslate=false, nestedArrayTranslate=false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState();
  const navigate = useNavigate();

  return (
    <>
      <ClientSideTable
        rows={rows}
        rowAction={rowActionCallback(setIsModalOpen, setDocumentId, navigate)}
        header={getHeadersWithRowRenderOverride(optionsTranslated)}
        search={true}
        searchFields={['title', 'building.name']}
        rowControl={getListRowControl}
        filtersList={filtersList}
        isDenaliTable={true}
        testName='reportsPage'
        listDataIsLoading={listDataIsLoading}
        noTableDataTitleText={'No documents or reports found'}
        noTableDataAction={noTableDataAction}
        isTranslate={isTranslate}
        nestedArrayTranslate={nestedArrayTranslate}
      />
      {isModalOpen &&
        <DocumentModal
          setIsModalOpen={setIsModalOpen}
          documentId={documentId}
          downloadFile={downloadFile}
          rowData={rows.filter(row => row.id === documentId)?.[0]}
        />
      }
    </>
  )
}

function rowActionCallback(setIsModalOpen: Dispatch<SetStateAction<boolean>>, setDocumentId: Dispatch<SetStateAction<string>>, navigate) {
  return (event, row) => {
    if(event?.target?.tagName === 'BUTTON') return;
    // If this is an energy report or a consultation we need to simply navigate to the right page.
    if(row.filterType === 'Energy Usage Report') {
      navigate(`/energy-reports/${row.id}?reportType=EnergyUsageReport`);
      return;
    }

    if (row.filterType === 'Intelligent Services Consultation') {
      navigate(`/Consultations/${row.id}`);
      return;
    }

    setDocumentId(row.id);
    setIsModalOpen(true);
    trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.VIEW_DOCUMENT, {
        "document id":row?.id,
        title:row?.title,
    });

    event?.preventDefault();
  }
}

function getHeadersWithRowRenderOverride(optionsTranslated) {
  return [
    {
      title: optionsTranslated.name,
      key: 'title',
      width: '100%',
      customComponent: titleCell,
    },
    {
      title: optionsTranslated.org,
      key: 'accountName',
      width: '200px',
      customComponent: accountCell
    },
    {
      title: optionsTranslated.status,
      key: 'status',
      width: '200px',
      customComponent: statusCell,
    }
  ]
}

function titleCell(row) {
  return (
    <IconCell iconType={row.filterType}>
      <Tooltip placement={'bottom-start'}>
        <TooltipTrigger testName="reportsRowTitleTrigger">
          <div className={styles.title} data-test="reportsRowTitle">
            {row.title}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>Report Name</TranslateComponent>
        </TooltipContent>
      </Tooltip>
      <Chips>
        {row.accountName && <Chip text={row.accountName} />}
        {row.buildingName && (
          <Tooltip>
            <TooltipTrigger testName="reportsPageBuildingNameTrigger">
              <Chip text={row.buildingName} />
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Building</TranslateComponent>
            </TooltipContent>
          </Tooltip>
        )}
        {row.filterType && (
          <Chip>
            <Tooltip>
              <TooltipTrigger testName="reportsPageTypeTrigger">
                <TranslateComponent>{row.filterType}</TranslateComponent>
              </TooltipTrigger>
              <TooltipContent>
                <TranslateComponent>Type</TranslateComponent>
              </TooltipContent>
            </Tooltip>
          </Chip>
        )}
      </Chips>
    </IconCell>
  )
}

function accountCell(row) {
  if(!row.contact) {
    return ( <DateRange start={row.start} end={row.end} /> );
  }

  return (
    <UserNameBubble
      showFullName
      showTooltip   
      firstName={row.contact}
      lastName=''
      subLine={( <Range row={row} /> )}/>
  )
}

function Range({row}) {
  return (
    <div className="body-md">
      {row.start && (
        <span className={styles.noWrap}>
          <Tooltip>
            <TooltipTrigger>
              <TranslateComponent>{row.start}</TranslateComponent>
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Creation Date</TranslateComponent>
            </TooltipContent>
          </Tooltip>
          {row.end && <span> - </span>}
        </span>
      )}
      {row.end && (
        <span className={styles.noWrap}>
          <TranslateComponent>{row.end}</TranslateComponent>
        </span>
      )}
    </div>
  )
}

function statusCell(row) {
  const visibilityTooltipText = row.visibility === 'OFF' ? 'Not Visible' : 'Visible';

  return (
    <div className={styles.status}>
      <Tooltip>
        <TooltipTrigger>
          <ChipStatus statusText={row.status} />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>Status</TranslateComponent>
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Visibility value={row.visibility} />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>{visibilityTooltipText}</TranslateComponent>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}