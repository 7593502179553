import { useEffect, useState } from 'react'
import XYChart from 'src/chart-library/Charts/XYChart'
import colors from 'src/components/layouts/colors.json'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import { useMemo } from 'react'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import moment from 'moment/moment'
import { format } from 'date-fns'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  formatDataForWidgetChart,
  getDefaultAggregation,
  aggreagatorSelection
} from 'src/common/chartHelperFunctions'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { WidgetNames } from 'src/pages/widgets/helpers'
import { WidgetPreviewChartParentContainer } from './style'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import {
  SimpleLegend,
  createSimpleLegendModel
} from '../../../../chart-library/CommonComponents/Legend'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import translate from 'src/common/translations'

const compareDataAggrTime = (selectedTime) => {
  switch (selectedTime) {
    case ':peak-past-30-days':
      return {
        timePeriod: 'past-30-days',
        aggregation: 'max',
        footerDisplayName: 'Peak last 30 days'
      }
    case ':peak-current-month':
      return {
        timePeriod: 'current-month',
        aggregation: 'max',
        footerDisplayName: 'Peak current month'
      }
    case ':peak-past-11-months':
      return {
        timePeriod: 'past-11-months',
        aggregation: 'max',
        footerDisplayName: 'Peak last 11 months'
      }
    default:
      return { timePeriod: null, aggregation: null }
  }
}

export const EnergyDemandWidgetPreview = ({ widgetDetails, dimension }) => {
  const width = dimension?.width || 300

  const xAxisTicksCount = width / 70
  // Dimension hook to get all Dimensions
  const { loadingDimensions, allDimensions, getAllDimensions } =
    useDimensionsHook()

  const selectedPeriod = widgetDetails?.selectedTimePeriod

  const selectedAggrValue = 'sum' // past 12 and 24 hours - aggrgating to hourly data

  const dataAggregation = getDefaultAggregation(selectedPeriod) || '15-minutes'

  const {
    timePeriod: compareTimePeriod,
    aggregation: compareTimeAggregation,
    footerDisplayName
  } = compareDataAggrTime(widgetDetails?.comparePointTo)

  const [aggrChartData, setAggrChartData] = useState([])
  
  const [peakDemand, setPeakDemand] = useState(0)

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(selectedPeriod, ':none', dataAggregation, widgetDetails?.timeZone)

  const {
    widgetChartData: compareTimeWidgetChartData,
    getWidgetChartData: getCompareTimeWidgetChartData,
    loadingWidgetChartData: loadingCompareTimeWidgetChartData
  } = useWidgetChartDataHook(compareTimePeriod, ':none', compareTimeAggregation, widgetDetails?.timeZone)

  useEffect(() => {
    try {
      if (
        widgetChartData?.data?.length > 0 ||
        compareTimeWidgetChartData?.data?.length > 0
      ) {

        const isDataAvailable =  widgetChartData?.data?.[0]?.isDataAvailable || compareTimeWidgetChartData?.data?.[0]?.isDataAvailable

        // if no data available for both current and compare, just returns
        // will be show no data component
        if(!isDataAvailable) {
          return
        }


        const chartData = widgetChartData?.data[0]

        const formattedData = formatDataForWidgetChart(
          widgetChartData?.data?.[0]?.values?.[0],
          'first',
          dataAggregation,
          selectedPeriod,
          widgetDetails?.timeZone,
          WidgetNames.EnergyDemand
        )

        const newValuesArray = { ...chartData, values: formattedData }

        const formattedTimeStamps = formattedData?.map((x) => x?.timeStamp)

        if (compareTimeWidgetChartData?.data?.length > 0) {
          const valuesArray =
            compareTimeWidgetChartData?.data?.[0]?.values?.[0] || []
          //peak demand
          const peakDemandValue = aggreagatorSelection(
            'peak',
            valuesArray,
            0,
            valuesArray?.length - 1
          )

          setPeakDemand(peakDemandValue)
        }

        setAggrChartData({
          data: [newValuesArray],
          timeStamps: formattedTimeStamps
        })
      }
    } catch (error) {}
  }, [widgetChartData, compareTimeWidgetChartData])

  const initializeData = async () => {
    await getAllDimensions()
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 15, // 15-minutes
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: selectedAggrValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    getWidgetChartData(widgetInfo)
  }

  const initializeCompareData = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440, // 1 day
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: 'max',
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    getCompareTimeWidgetChartData(widgetInfo)
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()

      if (widgetDetails?.comparePointTo !== ':none') {
        initializeCompareData()
      }
    }
  }, [widgetDetails])

  const getPlotLine = () => {
    if (compareTimeWidgetChartData?.data?.length > 0) {
      return [
        {
          id: "pl-1",
          axisKey: 'y1',
          canvasIndex: '0',
          show: true,
          visible: true,
          considerPlotLine: true,
          stroke: widgetDetails?.peakDemandColor,
          strokeWidth: 3,
          value: peakDemand,
          dashType: 'solid',
          type: 'plotLine',
          tooltipDataModelFormatter: ({value, ...props}) => ({
            ...props,
            value: `${value !== null ? Number(value)?.toFixed(2) : null} kw`,
            label: null,
          })
        }
      ]
    } else {
      return []
    }
  }
  const getLegends = () => {
    const legends = []
    aggrChartData?.data?.forEach((x, index) => {
      const legendObj = {
        id: index + 1,
        name: 'Energy-Demand',
        seriesKey: index + 1,
        elementProps: {
          stroke: widgetDetails?.currentDemandColor,
          strokeWidth: 3,
          fill: widgetDetails?.currentDemandColor,
          fillOpacity: 0.5
        },
        xAxisKey: 'x1',
        yAxisKey: 'y1',
        canvasIndex: '0',
        dashType: 'solid',
        properties: {},
        settings: {
          curve: {
            type: 'curveLinear',
            settings: {
              alpha: 0.5
            }
          },
          digits: {
            value: 3
          }
        },
        shape: 'Line',
        show: true,
        visible: true,
        type: 'area',
        tooltipDataModelFormatter: ({ value, xValue }) => ({
          label: null,
          value: `${value !== null ? Number(value)?.toFixed(2) : null} ${
            widgetDetails?.unitsOfMeasure || ''
          }`,
          title: format(new Date(xValue), 'h:mm a')
        })
      }
      legends.push(legendObj)
    })
    legends.push(...(getPlotLine()))
    return legends
  }

  const getData = () => {
    const data = {}
    aggrChartData &&
      aggrChartData?.data?.map((element, index) => {
        data[index + 1] = {
          data: element?.values?.map((x) => Number(x?.aggrValue || null))
        }
      })
    return data
  }

  const getPlotBands = () => {
    try {
      if (
        widgetDetails?.rateIncrease?.length > 0 &&
        widgetDetails?.isShowRateIncrease
      ) {
        const currentDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        const rateIncreseArray = widgetDetails?.rateIncrease
        const plotBands = rateIncreseArray?.map((rate) => {
          // as we are using same component for preview and dashboard, props name is different
          const fromTimestamp = `${currentDate} ${
            rate?.rateIncreaseStart || rate?.startTime
          }`
          const toTimestamp = `${currentDate} ${
            rate?.rateIncreaseEnd || rate?.endTime
          }`
          const fromTime = moment(fromTimestamp).utc().valueOf()
          const toTime = moment(toTimestamp).utc().valueOf()
          return {
            from: fromTime,
            to: toTime,
            text: translate(rate?.name),
            value: `${moment(rate?.startTime || rate?.rateIncreaseStart, ["HH:mm"]).format("hh:mm A")} - ${moment(rate?.endTime || rate?.rateIncreaseEnd, ["HH:mm"]).format("hh:mm A")}`,
            name: rate?.name,
            fill: widgetDetails?.rateIncreaseOverlayColor,
            fillOpacity: 0.4
          }
        })
        return plotBands
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  const getWidgetFooter = () => {
    if (widgetDetails?.comparePointTo === ':none') {
      return 'Energy Demand'
    } else {
      return `Latest demand vs ${footerDisplayName}`
    }
  }

  const createXAxis = () => {
    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleTime'
          },
          categories: aggrChartData?.timeStamps || [],
          scaleSettings: {}
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: false,
          hideLine: false
        },
        grid: [],
        plotBands: getPlotBands()
      }
    ]
  }


  const createYAxis = () => {
    return [
      {
        key: 'y1',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          name: {
            symbol: 'kW',
            text: '',
            alignment: 'start'
          },
          hideTicks: false,
          visibleAt: ['leftLeft'],
          notD3: true,
        },
        grid: [
          {
            stroke: 'gray',
            includeAxis: true
          }
        ],
      }
    ]
  }

  const customTooltip = (data) => {
    const tooltipData = data?.data ?? []
    const title = tooltipData?.find(f => typeof f?.title === 'string')?.title ?? ''
    return <>
      {tooltipData && <div className='custom-tooltip-container'>
        {title && <div className='title'>{title}</div>}
        <div className='item-container'>
          {tooltipData?.map((item, index) => {
            return <p className='tip-item-container' key={index}>
              <i className="icon" style={{ background: item?.color }}></i>
              <span className='tip-item'>
                {item?.label && <span className="value">{item?.label}</span>}
                <span className="value">{item?.value}</span>
              </span>
            </p>
          })}
        </div>
      </div>}
    </>
  }
 

  const chartConfigData = {
    canvas: {
      canvasDetails: {
        0: {
          renderAreaShade: {
            fill: colors.WHITE,
            opacity: '0.1'
          },
          tooltip: {
            type: 'x',
            referenceLineV: true,
            template: customTooltip,
            tooltipTemplateProps: {
              className: 'dashboard-widget-tooltip-line-series'
            }
          }
        }
      }
    },
    x: createXAxis(),
    y: createYAxis(),
    series: {
      types: getLegends(),
      data: getData()
    },
    width: '100%',
    height: '100%',
    dataLastUpdatedAt: moment().unix()
  }

  const simpleLegends = useMemo(() => {
    if (widgetDetails?.comparePointTo !== ':none') {
      return [
        createSimpleLegendModel({
          text: 'Demand',
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.currentDemandColor,
            size: 92
          }
        }),
        createSimpleLegendModel({
          text: footerDisplayName,
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.peakDemandColor,
            size: 92
          }
        })
      ]
    }

    return []
  }, [widgetDetails?.comparePointTo])

  const lDateFormat = getDateFormatByLocale('MMM DD, YYYY')

  return loadingWidgetChartData ||
    loadingDimensions ||
    loadingCompareTimeWidgetChartData ? (
    <Spinner />
  ) : aggrChartData?.data?.length > 0 &&
    Object.keys(chartConfigData?.series.data)?.length > 0 ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <WidgetPreviewChartParentContainer
        widgetType={WidgetNames.EnergyDemand}
        showLegends={widgetDetails?.comparePointTo !== ':none'}
      >
        <XYChart {...chartConfigData} />
      </WidgetPreviewChartParentContainer>
      <SimpleLegend
        className="dashboard-widget-simple-legend"
        hide={simpleLegends.length === 0}
        isStandaloneLegend
        legends={simpleLegends}
      />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        intervalInfo={
          selectedPeriod === 'past-24-hours'
            ? `${moment()
                .subtract(24, 'hours')
                ?.format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')}-${moment().format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')}`
            : `${moment().format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')}`
        }
        footerInfo={getWidgetFooter()}
        aggragatorInterval={dataAggregation}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
    />
  )
}
