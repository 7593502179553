import * as Yup from 'yup'
import { useFormikContext } from '../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import { useTranslation } from 'react-i18next'
import {
    GAS_METER,
    UOMS,
    getBaselineTimePeriodOptionsList
  } from '../../helpers'
import {
    BaseLineConfigurationWrapper, PanelLabel,
} from '../../styles'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { useEffect, useState } from 'react'
import moment from 'moment'
import i18next from 'i18next'
import translate, { TranslateComponent } from 'src/common/translations';
import { getDateFormatByLocale } from "../../../../common/chartHelperFunctions"

const DATE_FORMAT = 'MM/DD/YYYY'
  
export const BaseLineComparisonConfigurationSchema = Yup.object().shape({
    timePeriod: Yup.string().required("Time Period is required."),
})
export const BaseLineComparisonConfiguration = () => {
    const [t] = useTranslation()
    const formik = useFormikContext()
    const {selectedBaseLine} = useWidgetsContext()
    const[configDetails,setConfigDetails] = useState(null)
    const { values, errors, setFieldValue } = formik

    useEffect(() => {
        handleSettingConfigDetails()
    }, [])
    useEffect(() => {
        handleSettingConfigDetails()
    }, [selectedBaseLine])

    const handleSettingConfigDetails = () =>{
        const config = {
            equipmentName:"",
            locationName:"",
            orgName:"",
            startDt:"",
            endDt:"",
            equipmentType:"",
            displayValue:""
        }
        if(selectedBaseLine){
            const type = selectedBaseLine?.baselineEquipment?.type
            const uom = type === GAS_METER ? UOMS.KBTU.symbol : UOMS.KWH.symbol
            config.equipmentName = selectedBaseLine?.baselineEquipment?.name
            config.equipmentType = type
            config.locationName = selectedBaseLine?.baselineEquipment?.building?.name
            config.orgName = selectedBaseLine?.baselineEquipment?.building?.accountName
            config.startDt = moment(selectedBaseLine?.startPeriod).format(getDateFormatByLocale(DATE_FORMAT))
            config.endDt = moment(selectedBaseLine?.endPeriod).format(getDateFormatByLocale(DATE_FORMAT))
            config.displayValue = uom
        }
        setConfigDetails(selectedBaseLine ? config : null)
    }
    const inTranslate = translate("in")
    return (
        <BaseLineConfigurationWrapper>
            <div className="configuration-tab-main">
                <div>
                    <fieldset>
                        <PanelLabel><TranslateComponent>Energy Consumption Object</TranslateComponent></PanelLabel>
                        <div className="selected-label long">
                            {configDetails ? <> <span className="type-icon icon-electric-meter2object"></span>
                                <span>{` ${configDetails?.equipmentName} ${inTranslate} ${configDetails?.locationName}  (${inTranslate} ${configDetails?.orgName})`}</span></>
                                : <div className="selected-label"><TranslateComponent>Select a Target</TranslateComponent></div>}
                        </div>
                    </fieldset>
                    <fieldset>
                        <PanelLabel><TranslateComponent>Baseline Period</TranslateComponent></PanelLabel>
                        {configDetails ? <div className="selected-label">{`${configDetails?.startDt} - ${configDetails?.endDt}`}</div>
                            : <div className="selected-label"><TranslateComponent>Select a Target</TranslateComponent></div>}
                    </fieldset>
                    <fieldset>
                        <PanelLabel><TranslateComponent>Display Value</TranslateComponent></PanelLabel>
                        {configDetails ? <div className="selected-label"><TranslateComponent>Energy</TranslateComponent> {`(${configDetails?.displayValue})`}</div>
                            : <div className="selected-label"><TranslateComponent>Select a Target</TranslateComponent></div>}
                    </fieldset>
                    <SelectFormInput
                        fieldsetClass="inline-2"
                        labelName={"Time Period"}
                        optionsList={getBaselineTimePeriodOptionsList(t)}
                        placeholder={'— Select One —'}
                        update={(value) => {
                            setFieldValue('timePeriod', value)
                        }}
                        errorItem={errors?.timePeriod}
                        selectedItem={t(`time>${values?.timePeriod}`, {
                            nsSeparator: '>'
                        })}
                    />
                </div>
            </div>
        </BaseLineConfigurationWrapper>
    )
}