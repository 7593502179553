import { Button } from 'src/components/inputs/button'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import {
  MapContainer,
  Status,
  StatusText,
  DownloadSuccessNote,
  UpdatedTimeMessage,
  TimestampContainer,
  MessageLabel,
  MessageValueLabel
} from './style'
import {
  startMapDownload,
  mapDownloadStatus,
  getDeviceMessageGroupId
} from './graphql'
import Icon from 'src/components/Icon'
import { useEffect, useState } from 'react'
import COLORS from 'src/components/legacy/common/colors.json'
import { useMemo, useRef } from 'react'
import moment from 'moment/moment'
import { TEXT_DATE_FORMAT_WITH_MINUTES } from 'src/components/legacy/common/time-helpers'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { formatServerLocalTime } from '../helper'
import { TranslateComponent } from '../../../../common/translations'
import { getDateFormatByLocale } from '../../../../common/chartHelperFunctions'

const POLLING_TIME = 60000

const MapDownload = ({
  equipmentDetails,
  loadingEquipmentDetails,
  equipAssocatedDeviceDetails
}) => {
  let intervalId = useRef(null)

  const [mapDownloadState, setMapDownloadState] = useState('success')

  const [isPollingTimedOut, setIsPollingTimedOut] = useState(false)

  const [startPollingTime, setStartPollingTime] = useState(null)

  const [configurationMessageGroupId, setConfigurationMessageGroupId] =
    useState(null)

  const {
    data: mapDownloadStatusResponse,
    refetch: refetchMapDownloadStatus,
    loading
  } = useQuery({
    query: mapDownloadStatus,
    variables: {
      deviceId: equipmentDetails?.deviceId,
      configurationMessageGroupId: configurationMessageGroupId
    },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listConfigurationMessageHistoryByDevice.items'
  })

  const {
    data: deviceDetails,
    refetch: refetchDeviceDetails,
    loading: loadingDeviceDetails
  } = useQuery({
    query: getDeviceMessageGroupId,
    variables: {
      id: equipAssocatedDeviceDetails?.id
    },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDevice'
  })

  const lastDataCollectionTimestamp = useMemo(() => {
    try {
      if (deviceDetails) {
        // connectionTimeLocal - is not UTC, but stored in UTC format in backend
        // so remove the UTC format and format again
        const lastCollectedTime =
          deviceDetails?.deviceConnection.items?.[0]?.connectionTimeLocal
        const formattedTime = formatServerLocalTime(lastCollectedTime)
        return formattedTime || '--'
      } else {
        return '--'
      }
    } catch (e) {
      return '--'
    }
  }, [deviceDetails])

  // Have to update last updated map download
  const lastMapDownloadUpdateTimestamp = useMemo(() => {
    const timezone = deviceDetails?.building?.tz || moment.tz.guess()
    if (mapDownloadStatusResponse && mapDownloadStatusResponse?.length > 0) {
      const downloadStatus = mapDownloadStatusResponse?.find(
        (x) => x?.messageStatus === 'Success'
      )
      if (downloadStatus) {
        const lastUpdatedTime = downloadStatus?.createdAt
        const formattedTime = lastUpdatedTime
          ? moment(lastUpdatedTime)
              .tz(timezone)
              ?.format(getDateFormatByLocale(TEXT_DATE_FORMAT_WITH_MINUTES)) || '--'
          : '--'
        return formattedTime
      } else {
        return '--'
      }
    } else {
      return '--'
    }
  }, [mapDownloadStatusResponse])

  const { onSubmit: startMapDownloadMutation, loading: loadingStartDownload } =
    useMutation({
      query: startMapDownload,
      variables: { equipmentId: equipmentDetails?.id },
      disableInitialLoad: true,
      errorPolicy: 'global',
      dataPath: 'data'
    })

  // Get device details to know last map download status
  useEffect(() => {
    if (equipAssocatedDeviceDetails?.id) {
      refetchDeviceDetails()
    }
    return () => {
      stopPolling()
    }
  }, [equipmentDetails, equipAssocatedDeviceDetails])

  const mapDownloadStatusMessage = useMemo(() => {
    if (mapDownloadState) {
      if (mapDownloadState === 'success') {
        return 'Data Map matches Data Collection'
      } else if (mapDownloadState === 'error') {
        return 'Map download Failed'
      } else if (mapDownloadState === 'retry') {
        return 'Something went wrong, please try again later'
      } else {
        return 'Map download is in progress. 1 of 2 steps have been completed'
      }
    }
  }, [mapDownloadState])

  useEffect(() => {
    if (deviceDetails) {
      // if the message is success, only then get the configuration history message to get timestamp
      // last map download status is received
      const lastMapDownloadStatus =
        deviceDetails?.recentConfigurationMessageStatus?.split('#')?.[1]
      if (lastMapDownloadStatus === 'Success') {
        setMapDownloadState('success')
        setConfigurationMessageGroupId(
          deviceDetails?.recentConfigurationMessageStatus?.split('#')?.[0]
        )
        refetchMapDownloadStatus()
        stopPolling()
      } else if (lastMapDownloadStatus === 'Created') {
        setMapDownloadState('created')
        if (!startPollingTime) {
          setStartPollingTime(new Date().getTime?.())
          startPolling()
        } else {
          if ((new Date().getTime() - startPollingTime) / 1000 > 300) {
            setIsPollingTimedOut(true)
            stopPolling()
          }
        }
      } else if (lastMapDownloadStatus === 'Retry') {
        setMapDownloadState('retry')
        stopPolling()
      } else if (lastMapDownloadStatus === 'Failed') {
        setMapDownloadState('error')
        stopPolling()
      }
    }
  }, [deviceDetails])

  useEffect(() => {
    equipmentDetails?.deviceId &&
      configurationMessageGroupId &&
      refetchMapDownloadStatus()
  }, [configurationMessageGroupId])

  const startPolling = () => {
    setIsPollingTimedOut(false)
    intervalId.current = setInterval(() => {
      equipAssocatedDeviceDetails?.id && refetchDeviceDetails()
    }, POLLING_TIME)
  }

  const stopPolling = () => {
    try {
      clearInterval(intervalId.current)
      intervalId.current = null
    } catch (error) {}
  }

  const startMapDownloadHandler = async () => {
    try {
      // have to start new time stamp and have to call status for next 2 mins
      setStartPollingTime(new Date().getTime?.())
      await startMapDownloadMutation({
        equipmentId: equipmentDetails?.id
      })
      await refetchDeviceDetails()
      startPolling()
    } catch (error) {
      setMapDownloadState('error')
    }
  }

  return (
    <MapContainer>
      {equipmentDetails?.device?.type === 'LEC' ? (
        <UpdatedTimeMessage>
          <MessageLabel className="message-label">
            <TranslateComponent>Last Data Collection</TranslateComponent>:
          </MessageLabel>
          <MessageValueLabel className="message-value">
            {lastDataCollectionTimestamp!=='--' ? moment(lastDataCollectionTimestamp).format(getDateFormatByLocale("MMM D, YYYY h:mm A")) : "--"}
          </MessageValueLabel>
        </UpdatedTimeMessage>
      ) : (
        <>
          <h2><TranslateComponent>Map Download</TranslateComponent></h2>
          <Status>
            {mapDownloadState !== 'success' && (
              <Icon
                name="close"
                height="100%"
                width="13px"
                color={COLORS.RED}
                hover={COLORS.RED}
              />
            )}
            {mapDownloadState === 'success' && (
              <Icon
                name="check"
                height="100%"
                width="13px"
                color={COLORS.GREEN}
                hover={COLORS.GREEN}
              />
            )}
            <StatusText><TranslateComponent>{mapDownloadStatusMessage}</TranslateComponent></StatusText>
            {mapDownloadState === 'created' && isPollingTimedOut && (
              <button
                className="icon-loader"
                title="Check map download status"
                style={{
                  marginTop: '6px',
                  marginLeft: '0px',
                  background: 'none',
                  border: 'none'
                }}
                onClick={() => startPolling()} // TO DO
              />
            )}
            <button
              className={`${
                !equipmentDetails?.id || loadingStartDownload ? 'disabled' : ''
              } map-download-button`}
              style={{ marginLeft: 5 }}
              testName={DETAILS_TEXT_NAME_PREFIX('mapDwnld')}
              onClick={startMapDownloadHandler}
            >
             <TranslateComponent>Start Download</TranslateComponent>
            </button>
          </Status>
          {mapDownloadState === 'success' && (
            <DownloadSuccessNote>
              <TranslateComponent> All data has been updated with the latest collection results</TranslateComponent>
            </DownloadSuccessNote>
          )}

          <TimestampContainer>
            <UpdatedTimeMessage>
              <MessageLabel className="message-label">
              <TranslateComponent>Mapping Status Last Updated</TranslateComponent>:
              </MessageLabel>
              <MessageValueLabel className="message-value" style={{textTransform: "capitalize"}}>
                {lastMapDownloadUpdateTimestamp}
              </MessageValueLabel>
            </UpdatedTimeMessage>
            <UpdatedTimeMessage>
              <MessageLabel className="message-label">
              <TranslateComponent>Last Data Collection</TranslateComponent>:
              </MessageLabel>
              <MessageValueLabel className="message-value" style={{textTransform: "capitalize"}}>
                {lastDataCollectionTimestamp}
              </MessageValueLabel>
            </UpdatedTimeMessage>
          </TimestampContainer>
        </>
      )}
    </MapContainer>
  )
}
export default MapDownload
