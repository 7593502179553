import _uniqBy from 'lodash/uniqBy'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ContainerWrapper from 'src/components/Container'
import { getSearchParams, sortHelper } from 'src/common/helperFunctions'
import { useQuery } from 'src/hooks/APIHooks'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { usePaginateQuery } from 'src/hooks/usePaginateQuery'
import Label from 'src/components/legacy/components/label/label'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import {
  getField,
  hasEmptyProperties
} from 'src/components/legacy/common/helpers'
import { priorityOptionList } from 'src/components/legacy/common/opportunity'
import {
  formatFindingIssueFound,
  findingIssueFoundOptionList,
  statusOptionList,
  nextStepOptionList
} from 'src/components/legacy/common/finding'
import Select from 'src/components/legacy/components/select/select'
import { Content } from 'src/components/layouts'
import {
  GET_BUILDINGS_BY_ACCOUNT_ID,
  GET_EQUIPMENT_LIST
} from 'src/common/queries/datasource'
import {
  getEquipmentSelectOptions
} from 'src/components/target-path/helper'
import translate, { TranslateComponent } from 'src/common/translations'


import { ErrorText, FormWrapper } from '../styles'
import { deriveTestName } from '../helpers'
import { handleAddDetailClick, handleBuildingListChange, handleEquipmentChangeSelect } from './helpers'

// import { useMultipleBuildingsAccess } from "../../../hooks/multipleBuildingAccess"

const EQUIPMENT_LENGTH_WITH_CLEAR_ALL = 4

const ErrorMessage = ({ errorMessage }) => <ErrorText>{errorMessage}</ErrorText>

const QuickAddForm = (props) => {
  const {
    values,
    setFieldTouched,
    handleChange,
    setFieldValue,
    errors,
    setValues,
    touched,
    isValid,
    handleReset,
    onSubmit,
    onValidate
  } = props
  const {buildingId} = getSearchParams()
  const [buildings, setBuildings] = useState([])
  const [equipments, setEquipments] = useState([])
  const [locationId, setLocationId] = useState(values.location.locationId)
  const [selectedTisObjects, setSelectedTisObjects] = useState([])
  const organizationId = values.organization.organizationId
  const { width } = useBreakPoint()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const optionsTranslated = {
    issue: translate("Issue/Finding Name"),
    about: translate('About This Finding'),
    building: translate('Building'),
    placeholder: translate('Find Building...'),
    noResults: translate('No results. Change or clear your text above.'),
    selectOne: translate('Select One'),
    equipment: translate('Equipment'),
    noBuilding: translate('No building found'),
    findEquip: translate('Find equipment...'),
    selectMore: translate('Select One or More'),
    details: translate('Add Details'),
    save: translate('Save'),
    cancel: translate('Cancel'),

  }
  // const { setSeletectedBuildings, hasUnAuthorizedBuildsingsSelected } = useMultipleBuildingsAccess()

  const { data: buildingsList, refetch: refetchBuildingsList } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    variables: { id: organizationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })

  const { data: equipmentsList, refetch: refetchEquipmentsList } = usePaginateQuery({
    query: GET_EQUIPMENT_LIST,
    variables: { id: locationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment'
  })

  useEffect(() => {
    setSelectedTisObjects(
      values.tisObjects.map(({ tisObjectId }) => tisObjectId)
    )
  }, [values.tisObjects])

  useEffect(() => {    
    if (buildingsList) {      
      handleBuildingListChange({ buildingsList, buildingId, onBuildingChangeSelect, setBuildings })
    }
  }, [buildingsList])

  useEffect(() => {
    if (organizationId) {
      refetchBuildingsList({ id: organizationId })
    }
  }, [organizationId])

  useEffect(() => {
    if (equipmentsList) {
      const sortedEquipmentsList = sortHelper(equipmentsList)     
      setEquipments(sortedEquipmentsList)
    }
  }, [equipmentsList])


  // This is sample code to use multiple building selection in a page
  // Will remove if we don't find any use cases
  // useEffect(()=>{
  //   alert(`building access changed ${hasUnAuthorizedBuildsingsSelected}`)
  // }, [hasUnAuthorizedBuildsingsSelected])

  const handleDescriptionChange = (e) => {
    setFieldValue('comment', e.target.value)
  }

  // Handle when a new equipment item is selected.
  const onEquipmentChangeSelect = useCallback((value, tisObjectForSelector) => {
    const selectedTisObjects = handleEquipmentChangeSelect({value, values, tisObjectForSelector, equipments})
    setFieldValue('tisObjects', selectedTisObjects)
  }, [values, setFieldValue, equipments])

  const onBuildingChangeSelect = ({ key, value }) => {
    refetchEquipmentsList({ id: key })
    setLocationId(key)
    // setSeletectedBuildings(["72ac1cd4-50d8-47f4-9302-df85ec3ad909", "ffe5e1be-402d-40a9-834d-c63eb3101150"])
    setValues({
      ...values,
      location: {
        locationName: value,
        locationId: key,
        tisObjects: []
      }
    })
  }

  return (
    <FormWrapper data-testid={deriveTestName()+"_Quick_Add_Form"} width={width}>
      <ContainerWrapper
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        background={'#fff'}
      >
        <Content
          xlColumn={2}
          lColumn={2}
          mColumn={6}
          sColumn={12}
          border="none"
          xlRow={2}
          lRow={3}
          mRow={2}
          width={width}
          className={`${errors.name && touched.name ? 'invalid' : ''}`}
        >
          <legend className={`${errors.name && touched.name ? 'invalid' : ''}`}>
            {optionsTranslated.issue}:*
          </legend>
          <input
            name="name"
            type="text"
            value={values.name}
            onChange={(...args) => {
              // By default Formik makes fields touched after blur event, we need them to be touched after change event
              setFieldTouched('name')
              handleChange(...args)
            }}
            autoComplete="off"
          />
          {errors.name && touched.name && (
            <ErrorMessage errorMessage={errors.name} />
          )}
        </Content>
        <Content
          xlColumn={2}
          lColumn={2}
          mColumn={6}
          sColumn={12}
          border="none"
          xlRow={2}
          lRow={3}
          mRow={2}
          width={width}
        >
          <legend>{optionsTranslated.about}</legend>
          <textarea
            name="comment"
            value={getField(values, 'comment') || ''}
            onChange={handleDescriptionChange}
          />
        </Content>
        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={4}
          sColumn={12}
          border="none"
          width={width}
        >
          <legend>{optionsTranslated.building}:*</legend>
          <SelectrixWrapper
            isBuildingWrapper={true}
            arrow={true}
            className="location selectrix-wrapper--type-autocomplete"
            customKeys={{ key: 'key', label: 'value' }}
            defaultValue={locationId}
            disableStateVal={true}
            inputPlaceholder={optionsTranslated.placeholder}
            notFoundPrompt={optionsTranslated.noResults}
            onChange={onBuildingChangeSelect}
            options={buildings}
            placeholder={"— " + optionsTranslated.selectOne + " —"}
            searchable={true}
            searchBoxInside={true}
            searchIndex={false}
            value={values.location.locationName}
          />
        </Content>
        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={4}
          sColumn={12}
          border="none"
          width={width}
        >
          <legend>
            {optionsTranslated.equipment}:{' '}
            {values.tisObjects.length !== 0 && (
              <span className="item-selected">
                ({values.tisObjects.length} selected)
              </span>
            )}
          </legend>
          {!equipments.length ? (
            <Label
              text={optionsTranslated.noBuilding}
            />
          ) : (
            <SelectrixWrapper
              className={
                'selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select'
              }
              customKeys={{ key: 'key', label: 'value' }}
              defaultValue={
                selectedTisObjects.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL
                  ? [...selectedTisObjects, 'clear']
                  : selectedTisObjects
              }
              inputPlaceholder={optionsTranslated.findEquip}
              notFoundPrompt={optionsTranslated.noResults}
              onChange={(e) => onEquipmentChangeSelect(e, equipments)}
              multiple={true}
              stayOpen={true}
              options={getEquipmentSelectOptions(
                equipments,
                selectedTisObjects
              )}
              placeholder={"— " + optionsTranslated.selectMore + " —"}
              searchable={true}
              searchBoxInside={true}
              searchIndex={false}
            />
          )}
        </Content>
        <Content
          xlColumn={2}
          lColumn={4}
          mColumn={4}
          sColumn={12}
          border="none"
          marginTop={"-16px"}
          width={width}
        >
          <legend>{<TranslateComponent>Priority</TranslateComponent>}:</legend>
          <Select
            selectedItem={t(`components:priority>${values.priority}`)}
            onChange={(value) => setFieldValue('priority', value)}
            options={priorityOptionList(t)}
          />
        </Content>
        <Content
          xlColumn={2}
          lColumn={4}
          mColumn={4}
          sColumn={12}
          border="none"          
          marginTop={"-16px"}
          width={width}
        >
          <legend>{<TranslateComponent>Status</TranslateComponent>}:</legend>
          <Select
            selectedItem={t(`components:findingStatuses>${values.status}`)}
            onChange={(value) => setFieldValue('status', value)}
            options={statusOptionList(t)}
          />
        </Content>
        <Content
          xlColumn={2}
          lColumn={4}
          mColumn={4}
          sColumn={12}
          border="none"
          marginTop={"-16px"}
          width={width}
        >
          <legend>{<TranslateComponent>Next Step</TranslateComponent>}:</legend>
          <Select
            selectedItem={t(`components:nextStepsFinding>${values.nextStep}`)}
            onChange={(value) => setFieldValue('nextStep', value)}
            options={nextStepOptionList(t)}
            isUserdefinedOptions={true}
          />
        </Content>
        <Content
          xlColumn={2}
          lColumn={4}
          mColumn={4}
          sColumn={12}
          border="none"
          marginTop={"-16px"}
          width={width}
        >
          <legend>{<TranslateComponent>Found During</TranslateComponent>}:</legend>
          <Select
            selectedItem={formatFindingIssueFound(values.foundDuring, t)}
            onChange={(value) => setFieldValue('foundDuring', value)}
            options={findingIssueFoundOptionList(t)}
            isUserdefinedOptions={true}
          />
        </Content>
      </ContainerWrapper>
      <hr />
      <div className="buttons-group">
        <button
          data-testid={deriveTestName()+"_Quick_Add_Form_details"}
          className="secondary small"
          type="button"
          onClick={() => handleAddDetailClick(values, locationId, organizationId, navigate)}
        >
          {optionsTranslated.details}
        </button>
        <button
          data-testid={deriveTestName()+"_Quick_Add_Form_submit"}
          type="submit"
          className="primary small"
          disabled={
            !isValid ||
            (!hasEmptyProperties(touched) && !hasEmptyProperties(errors))
          }
        >
          {optionsTranslated.save}
        </button>
        <button
          data-testid={deriveTestName()+"_Quick_Add_Form_cancel"} 
          className="secondary small" 
          type="button" 
          onClick={handleReset}>
          {optionsTranslated.cancel}
        </button>
      </div>
    </FormWrapper>
  )
}

export default QuickAddForm
