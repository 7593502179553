import { Content } from 'src/components/layouts'
import Header from '../../components/legacy/components/header/header'
import { useTranslation } from 'react-i18next'
import {
  TEXT_EDITOR_TOOLBAR_OPTIONS,
  MAX_LENGTH_OF_NAME,
  MAX_PAGE_WIDTH
} from 'src/components/legacy/common/opportunity.js'
import { Formik, Form } from 'formik'
import {
  hasEmptyProperties,
  getField,
  setField
} from 'src/components/legacy/common/helpers.js'
import { PageTitleInput } from 'src/components/PageTitleInput/PageTitleInput'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { InlineEditFormWrapper } from './styles'
import COLORS from 'src/components/legacy/common/colors.json'
import { Container } from 'src/components/layouts'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import CostSavingsEdit from './OpportunityDetail/cost-savings/cost-savings-edit'
import TextEditor from 'src/components/text-editor'
import TargetPathEdit from 'src/components/target-path/TargetPathEdit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pages } from 'src/pages/pages.js'
import KPIEdit from 'src/components/legacy/components/kpi/kpi-edit'
import ImageBlockList from 'src/components/legacy/components/images/image-block-list/image-block-list'
import { useOpportunityContext } from '../../pages/opportunities/OpportunityContextProvider'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import Priority from 'src/components/Priority/Priority'
import StatusEdit from 'src/components/Priority/StatusEdit/StatusEdit'
import AssignedTo from 'src/components/AssignedTo'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import {
  statusOptionList,
  nextStepOptionList
} from 'src/components/legacy/common/opportunity'
import moment from 'moment'
import { newRefreshToken, selectUserAccess, selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { useMutation } from 'src/hooks/APIHooks'
import {
  CREATE_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
  CREATE_ISSUE_OPPORTUNITY,
  DELETE_ISSUE_OPPORTUNITY,
  DELETE_EQUIPMENT_OPPORTUNITY,
  CREATE_BULK_EQUIPMENT_OPPORTUNITY
} from './graphql'
import {
  getEquipmentsForUpsert,
  getFileForUpsert,
  getImagesForUpsert,
  getInitialOpportunity,
  getIssuesForUpsert,
  getOpportunityFormedInput
} from './helpers'
import { ACTIONS } from 'src/constants/actions'
import FileUploader from 'src/components/file-upload/file-upload'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import {
  CREATE_ATTACHMENT,
  CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT,
  UPDATE_ATTACHMENT
} from 'src/common/queries/attachment'
import { ATTACHMENT_CATEGORIES, handleSplitingElements } from 'src/common/helperFunctions.js'
import _omit from 'lodash/omit'
import { KPIEditWrapper } from '../issuesFindings/styles'
import { useDispatch } from 'react-redux'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate, { TranslateComponent } from 'src/common/translations'
import { useAttachment } from 'src/hooks/attachment'
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { accessControlFunc } from 'src/components/accessControl'

const MAX_SIZE_OF_NUMBERS = 10000000
const MAX_LENGTH_OF_COST_SAVINGS = 11
const MAX_LENGTH_OF_PROPOSED = 30
const CategoryType = {
  File: 'File',
  Image: 'Image'
}
const OpportunityDetailEdit = () => {
  const {
    setReloadEvents,
    mode,
    setModalType,
    buildingId,
    accountId,
    setOpportunityId,
    opportunity,
    setOpportunityInputValues,
    opportunityInputValues,
    setOpportunity,
    currentDate,
    userName
  } = useOpportunityContext()
  const [t] = useTranslation()
  const [notification, setNotification] = useState(null)
  const { width } = useBreakPoint()
  const [confirm, setConfirm] = useState(null)
  const [removeAllFindings, setRemoveAllFindings] = useState(false)
  const [upsertOpportunityId, setUpsertOpportunityId] = useState(null)
  const navigate = useNavigate()
  const userInfo = useSelector(selectUserInfo)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()  
  const { copyFileFunc } = useAttachment()
  const hasCRUDPermissions = true
  const isValidName = (name: string) => {
    return Boolean(name?.trim())
  }
  const userAccess = useSelector(selectUserAccess)
  const optionsTranslated = {
    cancel: translate('Cancel'),
    save: translate('Save'),
    title: translate("Title"),
    visible: translate("Visibility"),
    createDate: translate("Creation Date"),
    attach: translate('Attach File'),
    fileDesc: translate('File Description'),
    addFindingTitle: translate('Add Finding to an Opportunity'),
    findingSuccessMessage: translate('You have successfully added finding to opportunity')

  }
  const isDateFormatCorrect = (value, format) => {
    return (
      moment(value, format).format(format) === value ||
      !value ||
      value.length === 0
    )
  }
  const { onSubmit: upsertOpportunityMutation } = useMutation({
    query: mode === ACTIONS.EDIT ? UPDATE_OPPORTUNITY : CREATE_OPPORTUNITY,
    onError: (error) => {
      setError(error)
    },
    onSuccess: () => {mode === ACTIONS.EDIT ? trackEvent(USER_EVENTS.OPPORTUNITIES.events.SAVE_OPPORTUNITY, {mode: "EDIT"})
      : mode === ACTIONS.COPY ? trackEvent(USER_EVENTS.OPPORTUNITIES.events.SAVE_OPPORTUNITY, {mode: "COPY"})
      : trackEvent(USER_EVENTS.OPPORTUNITIES.events.SAVE_OPPORTUNITY, {mode: "CREATE"})}
  })
  const { onSubmit: createBulkEquipmentOpportunity } = useMutation({
    query: CREATE_BULK_EQUIPMENT_OPPORTUNITY,
    onError: (error) => {
      setError(error)
    }
  })
  const { onSubmit: deleteEquipmentOpportunity } = useMutation({
    query: DELETE_EQUIPMENT_OPPORTUNITY,
    onError: (error) => {
      setError(error)
    }
  })
  const { onSubmit: createIssueOpportunity } = useMutation({
    query: CREATE_ISSUE_OPPORTUNITY,
    onError: (error) => {
      setError(error)
    }
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })

  const { onSubmit: deleteIssueOpportunity } = useMutation({
    query: DELETE_ISSUE_OPPORTUNITY,
    onError: (error) => {
      setError(error)
    }
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const validate = (values) => {
    const errors = {}

    // const locationId = getField(values, 'location.locationId')
    const locationId = values.location.locationId

    if (!locationId) {
      errors['location'] = t('errors:buildingIsRequired')
    }

    // Validate opportunity name
    if (!isValidName(values.name)) {
      errors['name'] = t('errors:TheNameIsRequired')
    }
    if (values.name.length > MAX_LENGTH_OF_NAME) {
      errors['name'] = t('errors:TooLongName', { number: MAX_LENGTH_OF_NAME })
    }

    // Cost of Waiting date is required when Show Cost of Waiting is enabled
    const costOfWaitingDate = getField(
      values,
      'settings.costSavings.costOfWaiting.startDate'
    )
    if (getField(values, 'settings.costSavings.costOfWaiting.value')) {
      if (!costOfWaitingDate) {
        setField(
          errors,
          'settings.costSavings.costOfWaiting.startDate',
          'Date is required.'
        )
      }
    }

    // Check dates formatting
    const isProposalDateCorrect = isDateFormatCorrect(
      getField(values, 'settings.proposalDate'),
      DATE_FORMAT
    )
    if (!isProposalDateCorrect) {
      setField(
        errors,
        'settings.proposalDate',
        `Please enter a valid date in the format ${DATE_FORMAT}`
      )
    }

    const isCostOfWaitingDateCorrect = isDateFormatCorrect(
      costOfWaitingDate,
      DATE_FORMAT
    )
    if (!isCostOfWaitingDateCorrect) {
      setField(
        errors,
        'settings.costSavings.costOfWaiting.startDate',
        `Please enter a valid date in the format ${DATE_FORMAT}`
      )
    }

    const savings = getField(values, 'settings.costSavings.savings.value')
    if (
      Number(savings) > MAX_SIZE_OF_NUMBERS ||
      String(savings).length > MAX_LENGTH_OF_COST_SAVINGS
    ) {
      setField(
        errors,
        'settings.costSavings.savings',
        `Please enter a number to ${MAX_SIZE_OF_NUMBERS}`
      )
    }

    const costToImplement = getField(
      values,
      'settings.costSavings.costToImplement.value'
    )
    if (
      Number(costToImplement) > MAX_SIZE_OF_NUMBERS ||
      String(costToImplement).length > MAX_LENGTH_OF_COST_SAVINGS
    ) {
      setField(
        errors,
        'settings.costSavings.costToImplement',
        `Please enter a number to ${MAX_SIZE_OF_NUMBERS}`
      )
    }

    const lifecycle = getField(values, 'settings.costSavings.lifecycle.value')
    if (
      Number(lifecycle) > MAX_SIZE_OF_NUMBERS ||
      String(lifecycle).length > MAX_LENGTH_OF_COST_SAVINGS
    ) {
      setField(
        errors,
        'settings.costSavings.lifecycle.error',
        `Please enter a number to ${MAX_SIZE_OF_NUMBERS}`
      )
    }

    const timeToUse = getField(values, 'settings.costSavings.timeToUse.value')
    if (
      Number(timeToUse) > MAX_SIZE_OF_NUMBERS ||
      String(timeToUse).length > MAX_LENGTH_OF_COST_SAVINGS
    ) {
      setField(
        errors,
        'settings.costSavings.timeToUse.error',
        `Please enter a number to ${MAX_SIZE_OF_NUMBERS}`
      )
    }

    // Check if custom kpi is turned on but doesn't have name
    if (
      values.settings.kpi.some((kpi) => kpi.value && !isValidName(kpi.name))
    ) {
      setField(
        errors,
        'settings.kpi',
        values.settings.kpi
          .filter((kpi) => kpi.value && !isValidName(kpi.name))
          .map((kpi) => ({ ...kpi, missedName: t('errors:TheNameIsRequired') }))
      )
    }

    return errors
  }

  useEffect(() => {
    if (!upsertOpportunityId) return
    setOpportunity(
      getInitialOpportunity(accountId, buildingId, currentDate, userName)
    )
    setOpportunityId(null)
    // Navigate To List
    setReloadEvents(true)
    navigate(`/${PAGE_NAMES.OPPORTUNITIES}/${upsertOpportunityId}`, {
      state: { mode: ACTIONS.VIEW, id: upsertOpportunityId, action: mode }
    })
  }, [upsertOpportunityId])

  const handleCreateEquipment = async (opportunityID, newEquipmentList, accountId, locationId) => {
    if (newEquipmentList?.length > 0) {
      const equipmentsList = handleSplitingElements(newEquipmentList, 100)
      await Promise.all(
        equipmentsList?.map(item =>
          createBulkEquipmentOpportunity({
            input: JSON.stringify({
              equipmentList: item?.map((e) => e?.tisObjectId),
              opportunityList: [],
              modelType: 'Opportunity',
              modelId: opportunityID,
              action: 'Create',
              accountId,
              buildingId: locationId
            })
          })
        ))
    }
  }
  const handleDeleteEquipment = async (opportunityID, deleteEquipmentList, accountId, locationId) => {
    if (deleteEquipmentList?.length > 0) {
      const equipmentsList = handleSplitingElements(deleteEquipmentList, 200)
      await Promise.all(
        equipmentsList?.map(item =>
          createBulkEquipmentOpportunity({
            input: JSON.stringify({
              equipmentList: item?.map((e) => e?.tisObjectId),
              opportunityList: [],
              modelType: 'Opportunity',
              modelId: opportunityID,
              action: 'Delete',
              accountId,
              buildingId: locationId
            })
          })
        ))
    }
  }
  const handleCreateIssue = async (locationId, opportunityId, newList) => {
    for (const object of newList) {
      await createIssueOpportunity({
        input: { buildingId: locationId, opportunityId, issueId: object?.findingId }
      })
    }
  }
  const handleDeleteIssue = async (deleteList) => {
    for (const object of deleteList) {
      await deleteIssueOpportunity({
        input: { id: object?.issueOpportunityId }
      })
    }
  }
  const handleCreateAttachment = async (locationId, opportunityID, newList, isBuildingChangedInEditMode) => {
    for (const image of newList) {
      if (image?.name || (!image?.name && (image?.title || image?.caption))) {
        if ((isBuildingChangedInEditMode || mode === ACTIONS.COPY) && image?.buildingId) {      
          await copyFileFunc(image?.name, image?.buildingId, locationId, image?.fileName)
        }
        await createAttachmentMutation({
          input: {
            buildingId: locationId,
            description: image?.caption ? image?.caption : '',
            name: image?.fileName,
            opportunityId: opportunityID,
            sizeInBytes: image?.size,
            type: image?.type,
            category:
              image?.categoryType && image?.categoryType === CategoryType.File
                ? ATTACHMENT_CATEGORIES.ADD_FILES
                : ATTACHMENT_CATEGORIES.ADD_IMAGES,
            title: image?.title ? image?.title : '',
            displayName: image?.displayName,
            attachmentOrder: image?.attachmentOrder
          }
        })
      }
    }
  }
  const handleUpdateAttachment = async (updateList) => {
    for (const image of updateList) {
      await updateAttachmentMutation({
        input: {
          id: image?.originalImageId,
          description: image?.caption,
          title: image?.title,
          name: image?.categoryType === CategoryType.File ? image?.name : image?.original && image?.imageUrl ? image?.name : "",
          attachmentOrder: image?.attachmentOrder
        }
      })
    }
  }
  const handleDeleteAttachment = async (deleteList) => {
    for (const image of deleteList) {
      if (image?.imageId) {
        await deleteAttachmentMutation({
          input: {
            id: image?.imageId
          }
        })
      }
    }
  }
  const handleS3CreateAttachment = async (attachmentBody) => {
    await createEntryWithAttachmentMutation({
      input: JSON.stringify(attachmentBody)
    })
  }
  const handleUpsertOpportunity = async (
    inputs,
    values,
    equipmentsList,
    issuesList,
    fileUpsertDetails,
    imageUpsertDetails
  ) => {
    let opportunityID = null
    const bIds = [...new Set([opportunity?.location?.locationId, inputs.buildingId]?.filter(n => n))]
    const oIds = [...new Set([opportunity?.organization?.organizationId, inputs.accountId]?.filter(n => n))]
    await dispatch<any>(newRefreshToken(bIds, oIds, null, ["OpportunityAdmin", "AttachmentAdmin", "IssueAdmin"])).then(async () => {
      await upsertOpportunityMutation({ input: inputs }).then(async (res) => {
        opportunityID =
          mode === ACTIONS.EDIT
            ? res.data?.updateOpportunity?.id
            : res.data?.createOpportunity?.id
        const locationId = values?.location?.locationId
        const accountId = values?.organization?.organizationId
        // Adding Opportunity Equipments
        await handleCreateEquipment(
          opportunityID,
          equipmentsList?.newEquipmentList,
          accountId,
          locationId
        )

        // Delete Opportunity Equipments
        await handleDeleteEquipment(
          opportunityID,
          equipmentsList?.deleteEquipmentList,
          accountId,
          locationId
        )
        
        // Adding Opportunity Findings
        await handleCreateIssue(locationId, opportunityID, issuesList?.newList)

        // Deleting Opportunity Findings
        await handleDeleteIssue(issuesList?.deleteList)

        // Add File
        const newFileList = []
        const isBuildingChangedInEditMode = mode === ACTIONS.EDIT && opportunity?.location?.locationId !== locationId
        if (fileUpsertDetails.newFile && fileUpsertDetails.newFile?.name) {
          newFileList?.push({
            caption: fileUpsertDetails.newFile?.description ?? '',
            name: fileUpsertDetails.newFile?.name,
            fileName: mode === ACTIONS.COPY || isBuildingChangedInEditMode ? updateFileNameWithEpoch(fileUpsertDetails?.newFile?.displayName) : fileUpsertDetails.newFile?.name,
            size: fileUpsertDetails.newFile?.sizeInBytes,
            type: fileUpsertDetails.newFile?.type,
            categoryType: CategoryType.File,
            title: fileUpsertDetails.newFile?.name,
            displayName: fileUpsertDetails.newFile?.displayName,
            buildingId: fileUpsertDetails.newFile?.buildingId
          })
        }
        // Delete File
        const deleteFileList = []
        if (fileUpsertDetails?.deleteFile && fileUpsertDetails.deleteFile?.name) {
          if (fileUpsertDetails.deleteFile?.id) {
            deleteFileList.push({
              imageId: fileUpsertDetails.deleteFile?.id,
              originalImageId: fileUpsertDetails.deleteFile?.id,
              name: fileUpsertDetails.deleteFile?.name,
              fileName: fileUpsertDetails.deleteFile?.name,
              buildingId: fileUpsertDetails.deleteFile?.buildingId
            })
          }
        }

        // Update File
        const updateFileList = []
        if (fileUpsertDetails?.updateFile && fileUpsertDetails.updateFile?.name) {
          if (fileUpsertDetails.updateFile?.id) {
            updateFileList.push({
              originalImageId: fileUpsertDetails.updateFile?.id,
              name: fileUpsertDetails.updateFile?.name,
              fileName: fileUpsertDetails.updateFile?.name,
              caption: fileUpsertDetails.updateFile?.description,
              categoryType: CategoryType.File,
              title: fileUpsertDetails.updateFile?.name,
              buildingId: fileUpsertDetails.updateFile?.buildingId
            })
          }
        }
        // Update Image List Along With File List
        imageUpsertDetails.newList = [...imageUpsertDetails?.newList,...newFileList]
        imageUpsertDetails.updateList = [...imageUpsertDetails?.updateList,...updateFileList]
        imageUpsertDetails.deleteList = [...imageUpsertDetails?.deleteList,...deleteFileList]
        // Adding Images/File
        if (imageUpsertDetails?.newList) {
          await handleCreateAttachment(
            locationId,
            opportunityID,
            imageUpsertDetails?.newList,
            isBuildingChangedInEditMode
          )
        }

        // Update Images/Files
        if (imageUpsertDetails?.updateList) {
          await handleUpdateAttachment(imageUpsertDetails?.updateList)
        }

        // Delete Images/File
        if (imageUpsertDetails?.deleteList) {
          await handleDeleteAttachment(imageUpsertDetails?.deleteList)
        }

        // Upsert on S3 Bucket
        const createImages =
          imageUpsertDetails?.newList?.map((i) => ({
            buildingId: locationId,
            name: i?.fileName
          })) ?? []
        const deleteImages =
          imageUpsertDetails?.deleteList?.map((i) => ({
            attachmentId: i?.originalImageId,
            buildingId: i?.buildingId ?? locationId,
            name: i?.fileName
          })) ?? []
        const attachmentBody = {
          modelType: 'Opportunity',
          attachments: {}
        }

        if (createImages?.length)
          attachmentBody.attachments['create'] = createImages
        if (deleteImages?.length)
          attachmentBody.attachments['delete'] = deleteImages

        // Upsert on S3 bucket
        if (createImages?.length || deleteImages?.length) {
          await handleS3CreateAttachment(attachmentBody)
        }
      })
    })
    
    return opportunityID
  }

  const handleonSubmit = async (values, { resetForm }) => {
    const inputs = getOpportunityFormedInput(values, userInfo, mode)
    // Upsert Opportunity
    const equipmentsList = getEquipmentsForUpsert(
      opportunity,
      values?.tisObjects,
      mode
    )
    const issuesList = getIssuesForUpsert(opportunity, values.findings, mode)
    const imageUpsertDetails = getImagesForUpsert(
      opportunity?.settings?.images,
      values?.settings?.images,
      mode,
      opportunity?.location?.locationId,
      values?.location?.locationId
    )
    const fileUpsertDetails = getFileForUpsert(
      opportunity?.settings?.file,
      values?.settings?.file,
      mode,
      opportunity?.location?.locationId,
      values?.location?.locationId
    )
    const opportunityId = await handleUpsertOpportunity(
      inputs,
      values,
      equipmentsList,
      issuesList,
      fileUpsertDetails,
      imageUpsertDetails
    )
    resetForm()
    setUpsertOpportunityId(opportunityId)
  }
  const pageTitle = (
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange
  ) => {
    return (
      <div style={{ display: 'flex' }}>
        <PageTitleInput
          value={values.name}
          error={errors.name && touched.name ? errors.name : ''}
          name="name"
          label={optionsTranslated.title}
          onChange={(...args) => {
            setFieldTouched('name')
            handleChange(...args)
          }}
        />
        {hasCRUDPermissions &&
          accessControlFunc({
            id: 'tc.pages.opportunities.show-public-toggle',
            userAccess
          }) && (
            <div className="toggle-button">
              <label>{`${optionsTranslated.visible}:`}</label>
              <ToggleButtonHorizontal
                name="visibleToCustomer"
                checked={values.isPublic}
                onChange={() => setFieldValue('isPublic', !values.isPublic)}
              />
            </div>
          )}
      </div>
    )
  }

  const toolbar = (values, isSubmitting, errors, handleSubmit, handleReset) => (
    <div className="toolbar">
      <div className="toolbar-buttons">
        <button
          className={`primary ${isSubmitting ? 'wait' : ''}`}
          type="submit"
          onClick={handleSubmit}
          disabled={
            !hasEmptyProperties(errors) ||
            values.settings.images.some((i) => i.loading) ||
            values.settings.file.loading ||
            isSubmitting
          }
          data-testid="Opportunity_save-button"
        >
          {isSubmitting ? <div className="spinner" /> : optionsTranslated.save}
        </button>

        <button
          className="secondary"
          type="reset"
          disabled={isSubmitting}
          onClick={() => {
            handleReset()
            // cancelFromCreateMode()
            setOpportunity(
              getInitialOpportunity(
                accountId,
                buildingId,
                currentDate,
                userName
              )
            )
            setOpportunityInputValues(null)
            setOpportunityId(null)
            navigate(pages['Opportunities'].href)
          }}
        >
          {optionsTranslated.cancel}
        </button>
      </div>
    </div>
  )
  const onTargetChange = (changes, setFieldValue, values) => {
    if (
      changes['organization.organizationId'] &&
      values.findings &&
      values.findings.length
    ) {
      setConfirm({
        title: t('opportunities:RemoveFindingsTitle'),
        text: t('opportunities:RemoveFindingsConfirmText'),
        confirmText: t('common:Yes'),
        cancelText: t('common:No'),
        handleConfirm: () => {
          setFieldValue('findings', [])
          setConfirm(null)
          setRemoveAllFindings(true)
          Object.entries(changes).forEach(([key, value]) => {
            setFieldValue(
              key === 'organizationId' ? 'organization.organizationId' : key,
              value
            )
          })
        }
      })
    } else {
      Object.entries(changes).forEach(([key, value]) => {
        setFieldValue(key, value)
      })
      setFieldValue('assignedTo', {})
    }
  }

  const onCloseDialog = () => () => {
    setError(null)
  }

  const hideNotificationDialog = () => {
    setNotification(null)
  }
  const modalConfig = useMemo(
    () => ({
      heading:notification?.title,
      buttons:[{ text: 'Close', handleClick: hideNotificationDialog, type: 'cancel' }],
      handleClose: hideNotificationDialog
    }),
    [notification]
  )


  return (
    <>
      <Formik
        initialValues={opportunity}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleonSubmit}
        validate={validate}
      >
        {({
          values,
          dirty,
          isValid,
          handleSubmit,
          setValues,
          touched,
          errors,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleReset
        }) => {
          const onUpdateFields = (fields) => (values) => {
            setFieldValue(fields, values)
          }
          const onChangeDate = (path) => (date) => {
            date = date.target
              ? date.target.value
              : moment(date).format(DATE_FORMAT)
            setFieldValue(path, date)
          }
          const onUpdateFileField = (fields) => (values) => {
            setFieldValue(fields, _omit(values, 'fileFullName'))
          }

          const shouldBlockNavigation = () => {
            if (isSubmitting) {
              return false
            }
            return dirty
          }
          return (
            <InlineEditFormWrapper>
              <Form>
                <Container
                  className="inline-edit-form"
                  sColumns={12}
                  mColumns={12}
                  lColumns={12}
                  xlColumns={12}
                  width={width}
                >
                  <Content
                    xlColumn={12}
                    lColumn={12}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    border="none"
                  >
                    <Header
                      hideToolBar={false}
                      pageTitle={pageTitle(
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleChange
                      )}
                    >{toolbar(
                      values,
                      isSubmitting,
                      errors,
                      handleSubmit,
                      handleReset
                    )}</Header>
                  </Content>
                  <Content
                    xlColumn={12}
                    lColumn={12}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    background={COLORS.WHITE}
                  >
                    <Container
                      xlColumns={8}
                      lColumns={12}
                      mColumns={2}
                      sColumns={1}
                      width={width}
                      className="inline-edit-header"
                    >
                      <Content
                        xlColumn={5}
                        lColumn={9}
                        width={width}
                        border="none"
                      >
                        <TargetPathEdit
                          organization={values?.organization}
                          location={values?.location}
                          tisObjects={values.tisObjects || []}
                          onChange={(e, b) => {
                            setFieldValue(e, b)
                          }}
                          setValues={setValues}
                          values={values}
                          errors={errors}
                        />
                      </Content>
                      <Content
                        xlColumn={3}
                        lColumn={3}
                        className="proposal-info"
                        width={width}
                        border="none"
                      >
                        <div>
                          <label htmlFor="settings.proposedBy">
                          {<TranslateComponent>Created by</TranslateComponent>}:
                          </label>
                          <input
                            type="text"
                            value={values.settings.proposedBy}
                            name="settings.proposedBy"
                            onChange={handleChange}
                            maxLength={MAX_LENGTH_OF_PROPOSED}
                          />
                        </div>
                        <div
                          className={
                            getField(errors, 'settings.proposalDate')
                              ? 'proposal-date error'
                              : 'proposal-date'
                          }
                        >
                          <ModalDatePickerInput
                            date={
                              values.settings.proposalDate
                                ? values.settings.proposalDate
                                : ''
                            }
                            onChange={onChangeDate('settings.proposalDate')}
                            isRequired={false}
                            labelText={`${optionsTranslated.createDate}:`}
                            hasError={Boolean(
                              getField(errors, 'settings.proposalDate')
                            )}
                            errorMessage={getField(
                              errors,
                              'settings.proposalDate'
                            )}
                          />
                        </div>
                      </Content>
                    </Container>
                  </Content>
                  <Content
                    xlColumn={12}
                    lColumn={12}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    background={COLORS.WHITE}
                  >
                    <CostSavingsEdit
                      costSavings={values.settings.costSavings}
                      onChange={(fields) => {
                        setFieldValue('settings.costSavings', fields)
                      }}
                      errors={getField(errors, 'settings.costSavings')}
                      containerWidth={width}
                    />
                  </Content>
                  <Content
                    xlColumn={12}
                    lColumn={12}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    border="none"
                    background={COLORS.WHITE}
                  >
                      <KPIEditWrapper>
                        <KPIEdit
                          kpi={values.settings.kpi}
                          onChange={onUpdateFields('settings.kpi')}
                          errors={getField(errors, 'settings.kpi')}
                          showSpecificValue={false}
                          containerWidth={width}
                        />
                      </KPIEditWrapper>
                   </Content>
                  <Content
                    className="description"
                    xlColumn={6}
                    lColumn={6}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    background={COLORS.WHITE}
                  >
                    <TextEditor
                      content={values.settings.description}
                      textEditorToolbarOptions={TEXT_EDITOR_TOOLBAR_OPTIONS}
                      changeContent={(fields) => {
                        setFieldValue('settings.description', fields)
                      }}
                    />
                  </Content>
                  <Content
                    xlColumn={6}
                    lColumn={6}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    border="none"
                  >
                    <ImageBlockList
                      images={values.settings.images}
                      maxSize={6}
                      onUpdate={(fields) => {
                        setFieldValue('settings.images', fields)
                      }}
                      findings={values.findings}
                      showAddFindingButton={true}
                      onFindingsUpdate={(values, removingFinding) => {
                        setFieldValue('findings', values)
                        removingFinding &&
                          setNotification({
                            title: optionsTranslated.addFindingTitle,
                            text: optionsTranslated.findingSuccessMessage
                          })
                      }}
                      annotationsEnabled={true}
                      organizationId={values?.organization?.organizationId}
                      locationId={values?.location?.locationId}
                      values={values}
                    />
                  </Content>
                  <Content
                    xlColumn={12}
                    lColumn={12}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    background={COLORS.WHITE}
                  >
                    <Container
                      className="workflow-edit"
                      xlColumns={12}
                      lColumns={12}
                      mColumns={12}
                      sColumns={12}
                      width={width}
                    >
                      <Content
                        xlColumn={3}
                        lColumn={5}
                        mColumn={12}
                        sColumn={12}
                        width={width}
                        border="none"
                      >
                        <Priority
                          priority={values.settings.priority}
                          onChange={onUpdateFields('settings.priority')}
                        />
                      </Content>
                      <Content
                        xlColumn={5}
                        lColumn={7}
                        mColumn={12}
                        sColumn={12}
                        width={width}
                        border="none"
                      >
                        <FileUploader
                          location={
                            values.location && values.location.locationId
                          }
                          file={values.settings.file}
                          saveFullName={true}
                          onFileChange={onUpdateFileField('settings.file')}
                          componentName="finding-detail-edit-page"
                          titleText={`${optionsTranslated.attach}:`}
                          descriptionText={`${optionsTranslated.fileDesc}:`}
                        />
                      </Content>
                      <Content
                        className="bottom-options"
                        xlColumn={4}
                        lColumn={6}
                        mColumn={12}
                        sColumn={12}
                        width={width}
                        border="none"
                      >
                        <div>
                          <StatusEdit
                            status={t(`components:statuses>${values.status}`)}
                            nextStep={t(
                              `components:nextSteps>${values.settings.nextStep}`
                            )}
                            onChangeStatus={onUpdateFields('status')}
                            onChangeNextStep={onUpdateFields(
                              'settings.nextStep'
                            )}
                            statuses={statusOptionList(t)}
                            nextSteps={nextStepOptionList(t)}
                          />
                          <AssignedTo
                            defaultValue={values.assignedTo?.key}
                            onChange={({ key }) =>
                              setFieldValue('assignedTo', { key })
                            }
                            locationId={
                              (values.location && values.location.locationId) ||
                              values.locationId
                            }
                            setFieldValue={setFieldValue}
                            page="finding"
                          />
                        </div>
                      </Content>
                    </Container>
                  </Content>
                  <Content
                    xlColumn={12}
                    lColumn={12}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    border="none"
                  >
                    {toolbar(
                      values,
                      isSubmitting,
                      errors,
                      handleSubmit,
                      handleReset
                    )}
                  </Content>
                </Container>
                <StandardDialogs error={error} onCloseDialog={onCloseDialog} confirm={confirm} />
                {notification && <Modal {...modalConfig}>
                  <ConfirmationText>
                    {notification?.text}
                  </ConfirmationText>
                </Modal>
                }
              </Form>
            </InlineEditFormWrapper>
          )
        }}
      </Formik>
    </>
  )
}

export default OpportunityDetailEdit
