import styled from 'styled-components'
import * as Color from '@trane/trane-components/dist/color.js'
import * as Spacing from '@trane/trane-components/dist/spacing.js'
import SpinnerComponent from 'src/denali-ui/legacy/components/spinner/index.js'

export const Spinner = styled(SpinnerComponent)`
  padding-top: 20px;
  font-size: 100px;
  padding-bottom: 20px;
`

export const RowControlText = styled.p`
  font-family: arial;
  font-size: 16px;
  color: ${Color.PRIMARY_BLUE};
  margin: 0;
  padding: ${Spacing.SPACING_04} 0;
  cursor: pointer;
`
export const TableStyles = styled.table`
  background: white;
  border: 1px solid ${Color.DISABLED_BG};
  border-radius: 10px;
  width: 100%;
  border-spacing: 0px;
  ${(props) =>
    props.tableWidth
      ? `
        width: ${props.tableWidth};
    `
      : `width: 100%;
    `}
  &.dateCapitalize > tbody > tr > td:first-child {
    text-transform: capitalize;
  }
  &.dateCapitalize2thChild > tbody > tr > td:nth-child(2) {
    text-transform: capitalize;
  }
  &.dateCapitalize4thChild > tbody > tr > td:nth-child(4) {
    text-transform: capitalize;
  }
  &.dateCapitalize8thChild > tbody > tr > td:nth-child(8) {
    text-transform: capitalize;
  }
`

export const TableContainer = styled.div`
  width: 100%;
  overflow: ${(props) => (props.disableScroll ? 'initial' : 'auto')};
`

export const Title = styled.h1`
  color: ${Color.DARK_GREY};
  font-size: 20px;
  font-weight: bold;
`

export const ActionIcon = styled.div`
  padding: ${props => props.padding ? props.padding : "0 0 0 20px"};
  position: relative;
  svg {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -2px;
  }
`

export const HeaderDots = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${Color.DARK_GREY};
  cursor: pointer;
  padding: ${Spacing.SPACING_05} ${Spacing.SPACING_05} ${Spacing.SPACING_05} 0;
  width: 0;
`

export const RowCount = styled.p`
  color: ${Color.DARK_GREY};
  font-family: ArialMT;
  font-size: 16px;
  margin: 2px 0 0 ${Spacing.SPACING_05};
`

export const FiltersCount = styled.p`
  background: ${Color.ZEBRA_STRIPE_BG};
  color: ${Color.MED_GREY};
  font-family: Arial-BoldMT;
  font-size: 12px;
  width: 14px;
  border-radius: 7px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 2px;
`

export const FilterLabel = styled.label`
  color: ${Color.MED_GREY};
  font-family: ArialMT;
  font-size: 14px;
  margin-bottom: 3px;
`

export const FilterSelect = styled.select.attrs((props) => ({
  'data-testid': props?.dataTestId
}))`
  border: 1px solid ${Color.TEXT_HOVER_1};
  border-radius: 6px;
  padding: ${Spacing.SPACING_02} ${Spacing.SPACING_03};
`

export const ClearFilters = styled.p`
  color: ${Color.PRIMARY_BLUE};
  font-family: ArialMT;
  font-size: 16px;
  margin: ${Spacing.SPACING_04} 0;
  cursor: pointer;
`

export const TableHeadRow = styled.tr`
  th {
    // border-bottom: 1px solid ${Color.DISABLED_BG};
    width: auto;
  }
  border-bottom: 1px solid ${Color.DISABLED_BG};
`

export const HeadControl = styled.div`
  display: inline-block;
  position: absolute;
  margin-top: -4px;
  cursor: pointer;
  &.customControlIcon {
    position: relative;
    margin-top: -6px;
  }
`

export const OrderArrow = styled.div`
  font-size: 24px;
  height: 0px;
  width: 0px;
  &:first-child {
  }
  &:last-child {
    padding-top: 6px;
  }
  ${(props) =>
    props.active
      ? `
        color: ${Color.DARK_GREY};
        font-weight: bold;
    `
      : `
        color: ${Color.DM_GREY};
        font-weight: normal;
    `}
`

export const TableRow = styled.tr`
  position: relative;
  td {
    border-bottom: 1px solid ${Color.DISABLED_BG};
  }
  &:nth-child(odd) {
    td {
      background: ${Color.SNOW};
    }
  }
  .conditional-tooltip.tableRow {
    .conditional-tooltip__children--content * {
      vertical-align: middle;
    }
  }
  &:last-child {
    td {
      border-bottom: none;
      &:first-child {
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }
`

export const TableHead = styled.th`
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  padding: ${Spacing.SPACING_04};
  // padding: ${Spacing.SPACING_04}
  //   ${(props) => (props.onClick ? Spacing.SPACING_04 : '0')}
  //   ${Spacing.SPACING_04} ${Spacing.SPACING_04};
  color: ${Color.MED_GREY};
  ${(props) =>
    props.active
      ? `
        font-family: neue-haas-unica, sans-serif;
        font-size: 16px;
        font-weight: bold;
    `
      : `
        font-family: neue-haas-unica, sans-serif;
        font-size: 16px;
        font-weight: normal;
    `}
  max-width: ${(props) =>
    !props.fixedLayout && props.maxWidth ? props.maxWidth : '200px'};
  width: ${(props) =>
    !props.fixedLayout && props.width ? props.width : '100px'};
  &:first-child {
    padding-left: ${Spacing.SPACING_05};
  }
  &:nth-last-child(2) {
    // below styling seems super strange
    text-align: ${(props) => (props.fixedLayout ? 'right' : 'left')};
  }
  &:last-child {
    padding-right: ${Spacing.SPACING_05};
  }
  position: relative;
  &.customHederIcon {
    display: flex;
    justify-content: end;
  }
`
export const TableDown = styled.td`
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  flex: 0 1 auto;
  padding: ${Spacing.SPACING_03} ${Spacing.SPACING_04};
  color: ${(props) => (props.clickAble ? Color.PRIMARY_BLUE : Color.MED_GREY)};
  font-size: 14px;
  font-weight: normal;
  max-width: ${(props) =>
    !props.fixedLayout && props.maxWidth ? props.maxWidth : '200px'};
  overflow: ${(props) =>
    props?.tableRowOverflowHidden === false ? 'block' : 'hidden'};
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: arial;
  .textClickable {
    color: ${Color.PRIMARY_BLUE};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  div.conditional-tooltip {
    justify-content: ${(props) => (props.alignRight ? 'end' : 'start')};
  }
  a {
    color: ${Color.PRIMARY_BLUE};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &:first-child {
    padding-left: ${(props) =>
    `calc(${props.subLevel * 16}px + ${Spacing.SPACING_05});`};
  }
  &:last-child {
    padding-right: ${Spacing.SPACING_05};
  }
  &:nth-last-child(2) {
    text-align: ${(props) => (props.fixedLayout ? 'right' : 'left')};
    div.conditional-tooltip {
      justify-content: ${(props) => (props.fixedLayout ? 'end' : 'start')};
    }
  }
  position: relative;
`

export const Dots = styled.td`
  text-align: center;
  font-size: 22px;
  color: ${Color.DARK_GREY};
  cursor: pointer;
  padding: ${Spacing.SPACING_05} ${Spacing.SPACING_05} ${Spacing.SPACING_05}
    ${Spacing.SPACING_07};
  width: 0;
  position: relative;
`

export const PaginationText = styled.p`
  color: ${(props) => (props.color ? props.color : Color.DARK_GREY)};
  font-size: 14px;
  font-family: arial;
  font-weight: ${(props) => (props.weight ? props.weight : '700')};
  ${(props) =>
    props.selected && `border-bottom: 2px solid ${Color.PRIMARY_BLUE};`}
  margin: ${(props) =>
    props?.margin ? props?.margin : `0 ${Spacing.SPACING_04} 0 0`} !important;
  padding: ${(props) => props.padding && props.padding};
  cursor: ${(props) => props.cursor && props.cursor};
`

export const PaginationIcon = styled.figure`
  cursor: pointer;
  margin: 0 0 0 ${Spacing.SPACING_04};
`

export const RowPopup = styled.ul`
  background: ${Color.INPUT_BACKGROUND};
  border-radius: 6px;
  border: 1px solid ${Color.TEXT_HOVER_1};
  position: absolute;
  right: ${Spacing.SPACING_05};
  top: ${Spacing.SPACING_03};
  padding: 0 ${Spacing.SPACING_03} 0 ${Spacing.SPACING_03};
  z-index: 1;
  margin-top: 5px;
`

export const RowControl = styled.li`
  list-style: none;
`
export const NoRows = styled.div`
  color: ${Color.DARK_GREY};
  font-size: 14px;
  font-family: arial;
  font-weight: '700';
  padding: 10px;
  text-align: center;
  background: white;
  border-radius: 10px;
`

export const ExtraInputStyles = styled.span`
  input {
    font-feature-settings: inherit;
    background: rgb(255, 255, 255);
    font-family: inherit;
    font-size: 16px;
    font-stretch: inherit;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    height: auto;
    line-height: inherit;
    width: 251px;
  }
  p {
    top: 12px;
  }
`
