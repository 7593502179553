import { useSelector } from "react-redux";
import { selectBuilding } from "src/redux/slicers/buildingPicker";
import { getWeatherDetails, ParsedWeatherDetails, WeatherDetailsApiSuccess, WeatherDetailsTimeSeriesData } from 'src/pages/widgets/graphql'
import styles from "./home.module.scss"
import { useQuery } from "src/hooks/APIHooks";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDroplet, faCloud, faCloudBolt, faCloudDrizzle, faSun, faCloudSun, faSnowflake, faEye, faWind, faCloudFog, faSunHaze } from "src/denali-components/lib/pro-regular-svg-icons";
import { Flex, Grid, View } from "@aws-amplify/ui-react";
import { TranslateComponent } from "src/common/translations";
import store from "src/redux/store";

export const WeatherCard = ({}) => {
  return (
    <div className={styles.weather} data-testid="homeWidgetWeather">
      <WeatherCardContent />
    </div>
  )
}

const WeatherCardContent = ({}) => {
  const selectedOrgBuildingOrSalesOffice = useSelector(selectBuilding);
  const [weather, setWeather] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { refetchWeather } = initWeatherData(setWeather, setIsLoading);

  const weatherData = weather?.listWeatherTimeSeriesData?.items?.[0]?.data as WeatherDetailsTimeSeriesData;
  const cityState = getCityStateLabel(weather?.getBuilding);
  const weatherIcon = getWeatherIcon(weatherData?.Description);

  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const userSelectedUOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"

  const UNIT_CONVERSION = {
    MPH_TO_MPS: 0.44704,
    PRESSURE_INHG_TO_HPA: 33.8639,
    TEMPERATURE_F_TO_C: 5 / 9,
  }

  handleBuildingChangeWeatherUpdate(setIsLoading, refetchWeather);

  if(isLoading) {
    return (<TranslateComponent>Loading.</TranslateComponent>);
  }
  if(!selectedOrgBuildingOrSalesOffice?.id || (selectedOrgBuildingOrSalesOffice?.type !== 'location')) {
    return (<TranslateComponent>Please select a building to view weather.</TranslateComponent>);
  }
  if(!weatherData) {
    return (<TranslateComponent>Insufficient data to retrieve weather.</TranslateComponent>);
  }

  return (
    <Grid
      className={styles.weatherContainer}
      templateColumns={{base: '1fr', medium: '2fr 1fr'}}
      alignItems='center'
    >
      <View>
        <Flex
          alignItems='center'
          gap={4}
        >
          <View className={styles.weatherTemp} data-testid="weatherTemp">
            { weatherData?.TemperatureF &&
              <div>{userSelectedUOM === "SI"? `${Math.round((weatherData.TemperatureF - 32) * UNIT_CONVERSION.TEMPERATURE_F_TO_C)}`: `${Math.round(weatherData.TemperatureF)}`}°</div>
            }
          </View>
          <View className={styles.weatherIcon} data-testid="weatherIcon">
            { weatherIcon &&
              <FontAwesomeIcon icon={weatherIcon} />
            }

            <svg width="0" height="0">
              <linearGradient id="weatherGradient" gradientTransform="rotate(100)">
                <stop stopColor="#3C8AFF" offset="0.3311" />
                <stop stopColor="#2A2E90" offset="0.802" />
                <stop stopColor="#4791FF" offset="1.0363" />
              </linearGradient>
            </svg>
          </View>
        </Flex>
        { weatherData?.Description &&
          <div className={styles.weatherDescription} data-testid="weatherDescription"><TranslateComponent>{weatherData.Description}</TranslateComponent></div>
        }
        { cityState &&
          <div className={styles.weatherLocation} data-testid="weatherLocation"><TranslateComponent>{cityState}</TranslateComponent></div>
        }
      </View>
      <View>
        <Flex
          direction='column'
          gap={12}
          className={styles.weatherMetrics}
          data-testid="weatherMetrics"
        >
          { weatherData?.RelativeHumidity &&
            <View data-testid="weatherHumidity"><FontAwesomeIcon icon={faDroplet} />{weatherData.RelativeHumidity}</View>
          }
          { weatherData?.Wind &&
            <View data-testid="weatherWind"><FontAwesomeIcon icon={faWind} />{userSelectedUOM === "SI"? `${Math.round(weatherData.Wind * UNIT_CONVERSION.MPH_TO_MPS)} m/s`: `${Math.round(weatherData.Wind)} mph`}</View>
          }
          { weatherData?.Pressure &&
            <View data-testid="weatherPressure"><FontAwesomeIcon icon={faEye} />{userSelectedUOM === "SI"? `${Math.round(weatherData.Pressure * UNIT_CONVERSION.PRESSURE_INHG_TO_HPA)} hPa`: `${Math.round(weatherData.Pressure)} mi`}</View>
          }
        </Flex>
      </View>
    </Grid>
  )
}

function getWeatherIcon(description?:string) {
  if(!description || (description.trim() === '')) return null;

  const iconMap = {
    'Sunny': faSun,
    'Clear': faSun,
    'Mostly Sunny': faCloudSun,
    'Mostly Cloudy': faCloudSun,
    'Partly Cloudy': faCloudSun,
    'Cloudy': faCloud,
    'Isolated Storms': faCloudBolt,
    'Drizzle': faCloudDrizzle,
    'Rain Showers': faCloudDrizzle,
    'Light Rain': faCloudDrizzle,
    'Scattered Showers': faCloudDrizzle,
    'Isolated Showers': faCloudDrizzle,
  }
  if(iconMap[description?.trim()]) {
    return iconMap[description?.trim()];
  }
  
  const normalized = description.toLowerCase().trim();
  if(normalized.includes('sun')) {
    return faSun;
  }
  if(normalized.includes('cloud')) {
    return faCloud;
  }
  if(normalized.includes('fog')) {
    return faCloudFog;
  }
  if(normalized.includes('wind')) {
    return faWind;
  }
  if(normalized.includes('thunder')) {
    return faCloudBolt;
  }
  if(normalized.includes('haze') || normalized.includes('mist') || normalized.includes('smoke') || normalized.includes('dust')) {
    return faSunHaze;
  }
  if(normalized.includes('rain') || normalized.includes('shower') || normalized.includes('drizzle')) {
    return faCloudDrizzle;
  }
  if(normalized.includes('snow') || normalized.includes('ice') || normalized.includes('hail') || normalized.includes('sleet')) {
    return faSnowflake;
  }
  return null;
}

function handleBuildingChangeWeatherUpdate(setIsLoading, refetchWeather) {
  const selectedOrgBuildingOrSalesOffice = useSelector(selectBuilding);

  useEffect(() => {
    setIsLoading(true);
    refetchWeather({ buildingId: selectedOrgBuildingOrSalesOffice?.id });
  }, [selectedOrgBuildingOrSalesOffice]);
}

function getCityStateLabel(weatherBuilding) {
  let label = weatherBuilding?.city ? weatherBuilding?.city : '';
  label += weatherBuilding?.city && weatherBuilding?.state ? ', ' : '';
  label += weatherBuilding?.state ? weatherBuilding?.state : '';

  return label.trim();
}

function initWeatherData(setWeather, setIsLoading) {
  const { refetch: refetchWeather } = useQuery({
    query: getWeatherDetails,
    dataPath: 'data',
    disableInitialLoad: true,
    onSuccess: (response:WeatherDetailsApiSuccess) => {

      if(!response || !response?.listWeatherTimeSeriesData) {
        setWeather(null);
        setIsLoading(false);
        return;
      }

      setWeather({
        ...response,
        listWeatherTimeSeriesData: {
          items: response?.listWeatherTimeSeriesData?.items.map((item) => {
            return {
              ...item,
              data: JSON.parse(item.data) as WeatherDetailsTimeSeriesData,
            }
          })
        },
      } as ParsedWeatherDetails);
      setIsLoading(false);
    }
  });
  return {
    refetchWeather
  }
}