import React, { useState, useCallback, useEffect } from "react"
import { any, func, number, object } from "prop-types"
import Modal from 'src/components/legacy/components/modal/modal'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { DIALOG_TYPES } from 'src/components/legacy/components/standard-dialogs'
import { ChartSettingsContainer, SectionHeading, InputRow, Section, SectionName, InputContainer, Error, StyledUnAnchor } from "./styles"
import { useFormik } from "formik"
import * as Yup from 'yup'
import translate, { TranslateComponent } from 'src/common/translations'

const ChartMinMaxSettings = ({ update, settingsConfig, initialSectionValues}) => {
	const [modalOpen, setModalOpen] = useState(false)
	const toggleModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen])
	const [minMaxValues, setMinMaxValues] = useState(initialSectionValues)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [showErrorDialog, setShowErrorDialog] = useState(false)
	
    const toggleErrorDialog = () => {
        setShowErrorDialog(!showErrorDialog)
    }

    const toggleNotificationDialog = () => {
        setShowSuccessDialog(!showSuccessDialog)
    }

	const setDefaults = () => {
		toggleModal()
		formik.setValues(initialSectionValues)
		update(initialSectionValues)
	}

	useEffect(() => {
		setMinMaxValues(initialSectionValues)
	}, [initialSectionValues])
	
	const validationSchema = Yup.object().shape(
		settingsConfig?.sections.reduce((acc, section) => {
			acc[section.key+'min'] = Yup.number()
				.required(`Min value is required`)
				.typeError(`Min value must be a number`)
			acc[section.key+'max'] = Yup.number()
				.required(`Max value is required`)
				.typeError(`Max value must be a number`)
				.min(Yup.ref(section.key+'min'), 'Max value must be greater than Min value')
			
			return acc;
			}, {})
	)

	const formik = useFormik({
		initialValues: initialSectionValues,
		validationSchema,
		onSubmit: (values, actions) => {
			update(values, true)
			toggleModal()
		}
	})

	useEffect(() => {
		formik.setValues(initialSectionValues)
	}, [minMaxValues])

	const modalConfig = {
		gray: true,
		headingIcon: "icon icon-gear2 grey-icon",
		heading: settingsConfig && settingsConfig.heading ? settingsConfig.heading : "Settings",
		className: "chart-settings-modal",
		buttons: [
			{ text: <TranslateComponent>SAVE</TranslateComponent>, handleClick: formik.handleSubmit, type: "submit", disabled: !formik.isValid  },
			{ text: <TranslateComponent>CANCEL</TranslateComponent>, handleClick: setDefaults, type: "cancel" }
		],
		handleClose: setDefaults,
		// loading: saving
	}

	return (
		<ChartSettingsContainer>
			<StyledUnAnchor onClick={toggleModal} className="chart-link icon icon-gear2" />
            {showErrorDialog &&  <StandardDialogs
                                    confirm={DIALOG_TYPES.errors}
                                    onCloseDialog={() => toggleErrorDialog()}
                                />}
            {showSuccessDialog &&  <StandardDialogs
                                        confirm={DIALOG_TYPES.Notification}
                                        onCloseDialog={() => toggleNotificationDialog()}
                    />}
			{modalOpen && <Modal {...modalConfig}>               
				<form onSubmit={formik.handleSubmit}>
					<SectionHeading className="section-header">{settingsConfig?.sectionHeader}</SectionHeading> 
					{settingsConfig?.sections?.map((section, i) => (
					<Section key={section.name}>
							<React.Fragment key={section.name}>
								<InputRow>
									<SectionName><p>{section.name}: </p></SectionName>
									<InputContainer>
									{section.fields && Object.keys(section.fields).map((field) => {
										return (
											<TextInput
											    data-testid= {"axis-ranges_events"}
												key={field}
												className="field-input rx-col-auto"
												labelText={field}
												style={{"position":"relative","right":"26px", width: "75px"}}
												type="number"
												min={0}
												step="1"
												value={formik.values[section.key+field].toString()}
											    onChange={formik.handleChange}
												name={section.key+field}
											/>
										)}
										)}
									</InputContainer>
								</InputRow>
								{formik.errors ? <Error>{typeof formik.errors[section.key+'max']=='string' && <TranslateComponent>{formik.errors[section.key+'max']}</TranslateComponent>}</Error> : <></>}
							</React.Fragment>
					</Section>
					))}
				</form>
			</Modal>
			   }
		</ChartSettingsContainer>
			)
}

ChartMinMaxSettings.propTypes = {
	children: any,
	settingsConfig: object,
	updateSettingsConfig: func
}

export default ChartMinMaxSettings
