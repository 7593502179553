import moment from 'moment'
import { isArray } from 'lodash'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import { WidgetNames } from 'src/pages/widgets/helpers'
import _isEmpty from 'lodash/isEmpty'
import store from 'src/redux/store';

export const chartDateFormat = 'YYYY-MM-DDTHH:mm:ss'

export const chartAPIDataTimeFormat = 'YYYY-MM-DD HH:mm'

// Chart data helper functions
// All these functions are copied from Denali - CC4
export const intervalToMinutes = (inputType) => {
  const type = inputType?.slice(-1)
  const val = inputType?.slice(0, inputType?.length - 1)
  const calculatedMinutes =
    parseFloat(val) *
    (type === 'm' ? 1 : type === 'h' ? 60 : type === 'd' ? 24 * 60 : 1)

  return calculatedMinutes
}

export const convertTimeToMinutes = (timeString) => {
  const [hoursString, minutesString] = timeString.split(':')
  const hours = parseInt(hoursString)
  const minutes = parseInt(minutesString)

  const totalMinutes = hours * 60 + minutes
  return totalMinutes
}

export const getTimeIndex = (time, interval) => {
  const intervalsPerDay = (24 * 60) / interval
  const index = Math.floor(time / interval) % intervalsPerDay

  return index
}

export const chartYAxisPosition = {
  y1: 'leftLeft',
  y2: 'rightRight',
  y3: 'leftRight',
  y4: 'rightLeft'
}

// 2023/08/25 Constant values recieved from response which will be treated as null/gap
export const constantValues = ['_', '-', 'null', null, undefined, '']

let isString = (value) => typeof value === 'string'

// Method fills the data too
export const formatData = (
  data,
  startDate,
  endDate,
  tz,
  intervalMinutes,
  timeInterval = null,
  isDashboardKeyStripCall = false,
  isCurrentDayEnergyConsumption = false
) => {
  const equipmentPropertiesAndData = []
  const calculatedMin = intervalToMinutes(intervalMinutes)
  for (const id in data) {
    const equipmentData = data[id]
    const equipmentProperties = equipmentData?.properties
    let propertyData

    for (const propertyKey in equipmentProperties) {
      const property = equipmentProperties[propertyKey]
      const propertyIndex = !isDashboardKeyStripCall ? property?.index : property?.index?.index
      const propertyName = property?.name || ''
      propertyData = {
        equipmentId: id,
        propertyKey,
        propertyName,
        values: [],
        isDataAvailable: true
      }

      let currentTimestamp = moment(startDate, chartDateFormat)
      let endTimestamp = moment(endDate, chartDateFormat).add(1, 'days')

      const localTimeStamp = Boolean(tz)
        ? moment(
            moment.tz(moment(), tz).format('MM-DD-YYYY HH:mm'),
            'MM-DD-YYYY HH:mm'
          )
        : moment()

      let timestampAndValues = []
      let prevValue = null
      while (
        currentTimestamp.isSameOrBefore(endTimestamp) &&
        currentTimestamp.isSameOrBefore(localTimeStamp)
      ) {
        const dateKey = currentTimestamp.format('YYYY-MM-DD')
        const extractTime = currentTimestamp.format('HH:mm')
        const timetoMin = convertTimeToMinutes(extractTime)
        const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)
        let finalValue

        const dateAvailable = equipmentData?.values?.[dateKey]

        if (intervalMinutes === '1d') {
          if (dateAvailable) {
            const valueArray = equipmentData?.values?.[dateKey]
            const valueArrayIsArray = isArray(valueArray)
            const arrayValues = valueArrayIsArray ? valueArray : []
            const value = arrayValues[propertyIndex]
            const isNull =
              constantValues?.includes(value) ||
              value?.toString()?.startsWith('_')
            finalValue = isNull ? null : value
          } else {
            finalValue = null
            prevValue = null
          }
        } else {
          if (dateAvailable) {
            const valueArray =
              equipmentData?.values?.[dateKey]?.[timeIndexPosition]
            const valueArrayIsArray = isArray(valueArray)
            const arrayValues = valueArrayIsArray ? valueArray : []
            const value = isCurrentDayEnergyConsumption ? arrayValues[0] : arrayValues[propertyIndex]
            const isNull =
              constantValues?.includes(value) ||
              value?.toString()?.startsWith('_')
            const isTimeline = true
            const checkedValue =
              isNull || (isTimeline ? false : !isFinite(toNumber(value)))
                ? null
                : isTimeline
                ? value
                : parseFloat(value)
            if (
              ((isString(value) && value?.length === 0) ||
              !valueArrayIsArray) && !isCurrentDayEnergyConsumption
            ) {
              finalValue = prevValue
            } else {
              finalValue = checkedValue
            }
            if (timeIndexPosition === 95) {
              prevValue = null
            } else {
              prevValue = finalValue
            }
          } else {
            finalValue = null
            prevValue = null
          }
        }

        timestampAndValues.push({
          timeStamp: currentTimestamp.format(chartDateFormat),
          value: finalValue
        })

        currentTimestamp = currentTimestamp.add(calculatedMin, 'minutes')
      }
      // Checks the property has data, atleast a single timestamp
      const dataAvailableCheck = timestampAndValues?.some(
        (x) => x.value !== null
      )
      propertyData.isDataAvailable = dataAvailableCheck
      propertyData.values.push(timestampAndValues)
      equipmentPropertiesAndData.push(propertyData)
    }
  }
  return equipmentPropertiesAndData
}

export const sortKeysByDate = (values,aggrFuncName = '', intervalMinutes = '') => {
  const ordered = Object.keys(values)
    .sort()
    .reduce((obj, key) => {
      obj[key] = aggrFuncName === 'latest' && intervalMinutes !=='15m' ? Object.values(values[key])[0] : values[key]
      return obj
    }, {})
  return ordered
}

export const toFixedNumber = (num, digits = 2) => {
  const pow = Math.pow(0, digits)
  return Math.round(num * pow) / pow
}

export const aggrDataFormatter = (
  apidata,
  startDate,
  endDate,
  tz,
  interval = '1d'
) => {
  const data = {}
  for (const id in apidata) {
    const equipmentData = apidata[id]
    for (const aggregator in equipmentData) {
      const inputData = {
        properties: equipmentData[aggregator].properties,
        values: sortKeysByDate(equipmentData[aggregator].values || {})
      }
      data[aggregator] =
        formatData({ [id]: inputData }, startDate, endDate, tz, interval) || []
    }
  }
  return data
}

export const formatChartData = (
  chartAPIData,
  startDate,
  endDate,
  tz = moment.tz.guess(),
  intervalMinutes = '15m',
  aggrFunctionName = '' // Will get only on latest type
) => {
  try {
    const aggrData = (apidata) => {
      const data = {}
      for (const id in apidata) {
        const equipmentData = apidata[id]
        for (const aggregator in equipmentData) {
          const chartValues = sortKeysByDate(equipmentData[aggregator].values || {}, aggrFunctionName)
          const inputData = {
            properties: equipmentData[aggregator].properties,
            values: chartValues
          }
          data[aggregator] =
            formatData({ [id]: inputData }, startDate, endDate, tz, '1d') || []
        }
      }
      return data
    }

    const equipmentPropertiesAndData = {
      data:
        formatData(
          chartAPIData?.data,
          startDate,
          endDate,
          tz,
          intervalMinutes
        ) || [],
      // aggregatedData: aggrData(chartAPIData?.agg_data) || {}
      aggregatedData: aggrData(aggrFunctionName === 'latest' && intervalMinutes !== '15m' ? chartAPIData : chartAPIData?.agg_data) || {}
    }
    return equipmentPropertiesAndData
  } catch (error) {
    console.log(error)
  }
}

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const getBackendAggrName = {
  average: 'avg',
  min: 'min',
  max: 'max',
  latest: 'latest',
  sum: 'sum',
  median: 'median',
  peak: 'max'
}

export const aggregationDisplayName = {
  average: 'Average',
  min: 'Minimum',
  max: 'Maximum',
  latest: 'Latest',
  sum: 'Sum',
  median: 'Median',
  peak: 'Peak'
}

const chartPeriod = {
  'past-hour': {
    aggregation: '15-minutes',
    aggregationDisplayName: '15 Minutes',
    periodDisplayName: 'Past Hour'
  },
  'past-4-hours': {
    aggregation: '15-minutes',
    aggregationDisplayName: '15 Minutes',
    periodDisplayName: 'Last 4 Hour'
  },
  'past-12-hours': {
    aggregation: 'hourly',
    aggregationDisplayName: 'Hourly',
    periodDisplayName: 'Last Hour'
  },
  'past-24-hours': {
    aggregation: 'hourly',
    aggregationDisplayName: 'Hourly',
    periodDisplayName: 'Past 24 Hours'
  },
  'current-day': {
    aggregation: 'hourly',
    aggregationDisplayName: 'Hourly',
    periodDisplayName: 'Current Day'
  },
  'current-week': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Current week'
  },
  'current-month': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Current month'
  },
  'current-quarter': {
    aggregation: 'monthly',
    aggregationDisplayName: 'Monthly',
    periodDisplayName: 'Current Quarter'
  },
  'current-year': {
    aggregation: 'quarterly',
    aggregationDisplayName: 'Quarterly',
    periodDisplayName: 'Current Year'
  },
  'past-3-days': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Past 3 Days'
  },
  'past-7-days': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Past 7 Days'
  },
  'past-14-days': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Past 14 Days'
  },
  'past-30-days': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Past 30 Days'
  },
  'past-90-days': {
    aggregation: 'monthly',
    aggregationDisplayName: 'Monthly',
    periodDisplayName: 'Past 90 Days'
  },
  'past-3-months': {
    aggregation: 'monthly',
    aggregationDisplayName: 'Monthly',
    periodDisplayName: 'Past 3 months'
  },
  'past-6-months': {
    aggregation: 'monthly',
    aggregationDisplayName: 'Monthly',
    periodDisplayName: 'Past 6 months'
  },
  'past-12-months': {
    aggregation: 'quarterly',
    aggregationDisplayName: 'Quarterly',
    periodDisplayName: 'Past 12 months'
  },
  'previous-week': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Previous week'
  },
  'previous-month': {
    aggregation: 'daily',
    aggregationDisplayName: 'Daily',
    periodDisplayName: 'Previous month'
  },
  'previous-quarter': {
    aggregation: 'quarterly',
    aggregationDisplayName: 'Quarterly',
    periodDisplayName: 'Previous quarter'
  },
  'previous-year-full': {
    aggregation: 'quarterly',
    aggregationDisplayName: 'Quarterly',
    periodDisplayName: 'Previous year'
  },
  'today-yesterday': {
    aggregation: 'daily',
    aggregationDisplayName: '',
    periodDisplayName: 'Yesterday Vs Today'
  },
  'this-week-last-week': {
    aggregation: 'daily',
    aggregationDisplayName: '',
    periodDisplayName: 'Last week vs. Current Week'
  },
  'this-month-last-month': {
    aggregation: 'monthly',
    aggregationDisplayName: '',
    periodDisplayName: 'Last month vs. Current month'
  },
  'this-quarter-last-quarter': {
    aggregation: 'monthly',
    aggregationDisplayName: '',
    periodDisplayName: 'Last Quarter vs. Current Quarter'
  },
  'this-year-last-year': {
    aggregation: 'quarterly',
    aggregationDisplayName: '',
    periodDisplayName: 'Last Year vs. Current Year'
  },
  total: {
    aggregation: 'total',
    aggregationDisplayName: 'Total',
    periodDisplayName: 'Total'
  }
}

export const aggregationTime = {
  '15-minutes': {
    aggregation: '15-minutes',
    aggregationDisplayName: 'Minutes',
    periodDisplayName: '1 Hour'
  },
  '1-hour': {
    aggregation: 'hourly',
    aggregationDisplayName: 'Hourly',
    periodDisplayName: '1 Hour'
  },
  '1-day': {
    aggregation: 'daily',
    aggregationDisplayName: '1 Day',
    periodDisplayName: '1 Day'
  },
  '1-month': {
    aggregation: 'monthly',
    aggregationDisplayName: 'Daily',
    periodDisplayName: '1 Month'
  },
  '1-quarter': {
    aggregation: 'quarterly',
    aggregationDisplayName: 'Quarterly',
    periodDisplayName: '1 Quarter'
  }
}

export const getAggreationTime = (timeInterval, requestChart) => {
  if (requestChart && timeInterval === '15-minutes') {
    return 'hourly'
  } else {
    return aggregationTime[timeInterval]?.aggregation
  }
}

export const getDefaultAggregation = (selectedPeriod) => {
  return chartPeriod[selectedPeriod]?.aggregation
}

export const getAggregationDisplayName = (selectedPeriod) => {
  return chartPeriod[selectedPeriod]?.aggregationDisplayName
}

export const getSelectedPeriodDisplayName = (selectedPeriod) => {
  return chartPeriod[selectedPeriod]?.periodDisplayName
}

const getLatestValue = (data = []) => {
  let latestValue = 0
  let isPresent = false
  data?.reverse()?.forEach(element => {
    const timeValue = element?.value === null ? 0 : element?.value
    if (timeValue && Number(timeValue) !== 0 && !isPresent) {
      isPresent = true
      latestValue = Number(timeValue)
    }
  });
  return latestValue;
}
export const getLatestDisplayName = (data = [],selectedAggrValue) => {
  let latestValueDisplayName = null
  let isPresent = false
  if(selectedAggrValue === 'latest'){
    data?.reverse()?.forEach(element => {
      const timeValue = element?.value === null ? 0 : element?.value
      if (timeValue && Number(timeValue) !== 0 && !isPresent) {
        isPresent = true
        latestValueDisplayName = moment(element?.timeStamp).format("MM/DD")
      }
    });
  }
  return latestValueDisplayName;
}

const getSumValues = (data = []) => {
  return data?.reduce((sum, { value = 0 }) => {
    const timeValue = value === null ? 0 : value
    return sum + Number(parseFloat(timeValue).toFixed(2))
  }, 0)
}

const getAverageValues = (data = []) => {
  const firstNonNullValueTimeStamp = data.find(
    (x) => x.value !== null
  )?.timeStamp
  const nonNullItemIndex = data.findIndex(
    (x) => x.timeStamp === firstNonNullValueTimeStamp
  )
  let dataLength = data?.length
  if (nonNullItemIndex !== 0) {
    dataLength = data?.length - nonNullItemIndex
  }
  const sumValues = getSumValues(data)
  return sumValues > 0 ? sumValues / dataLength : 0
}

const getMedianValues = (data = []) => {
  const valuesArray = data.map((x) => x.value)
  valuesArray.sort((a, b) => a - b)
  const middleIndex = Math.floor(valuesArray.length / 2)
  if (valuesArray.length % 2 === 0) {
    return (valuesArray[middleIndex - 1] + valuesArray[middleIndex]) / 2
  } else {
    return valuesArray[middleIndex]
  }
}

const getMinValues = (data = []) => {
  const valuesArray = data.map((x) => x.value)
  return Math.min(...valuesArray)
}

const getFirstValue = (data = []) => {
  return Number(data?.[0]?.value) || 0
}

const getMaxValues = (data = []) => {
  const valuesArray = data.map((x) => x.value)
  return Math.max(...valuesArray)
}

export const aggreagatorSelection = (
  aggregatorFunction,
  values,
  startIndex,
  endIndex
) => {
  let aggrValue = 0
  switch (aggregatorFunction) {
    case 'latest':
      aggrValue = getLatestValue(values)
      break
    case 'sum':
      aggrValue = getSumValues(values?.slice(startIndex, endIndex))
      break
    case 'avg':
    case 'average':
      aggrValue = getAverageValues(values?.slice(startIndex, endIndex))
      break
    case 'median':
      aggrValue = getMedianValues(values?.slice(startIndex, endIndex))
      break
    case 'peak':
    case 'max':
      aggrValue = getMaxValues(values?.slice(startIndex, endIndex))
      break
    case 'min':
      aggrValue = getMinValues(values?.slice(startIndex, endIndex))
      break
    case 'first':
      aggrValue = getFirstValue(values?.slice(startIndex, endIndex))
      break
    default:
      aggrValue = 0
  }
  return aggrValue
}

const roundToNearest15MinutesTimeStamp = (timestamp,timeZone) => {
  try {
    const minutes = 15
    const ms = 1000 * 60 * minutes
    const newDate = new Date(timestamp)
    const formattedNewDate = new Date(Math.floor(newDate.getTime() / ms) * ms)
    return moment(formattedNewDate).tz(timeZone).format('YYYY-MM-DD HH:mm')
  } catch (error) {}
}

const roundOfToNearestHour = (timeStamp) => {
  try {
    const newDate = new Date(timeStamp)
    newDate.setHours(newDate.getHours() + Math.floor(newDate.getMinutes() / 60))
    newDate.setMinutes(0, 0, 0)
    return moment(newDate).format('YYYY-MM-DD HH:mm')
  } catch (error) {}
}

const minutesData = (values = [], selectedTimePeriod, widgetType, timeZone) => {
  try {
    const isEnergyDemandWidget = widgetType === WidgetNames.EnergyDemand
    let finalArray = []
    let hours = selectedTimePeriod?.match(/\d+/)?.[0] || 1
    if (values?.length === 0) {
      return []
    }
    const { startDate: startTimeStamp } = getStartAndEndDates(
      selectedTimePeriod,
      false,
      'YYYY-MM-DD HH:mm',
      timeZone
    )
    const roundingOff15MinsTime =
      roundToNearest15MinutesTimeStamp(startTimeStamp,timeZone)
    const startIndex =
      values.findIndex((x) => x.timeStamp === roundingOff15MinsTime) || 0
    const dataStartIndex = startIndex === -1 ? 0 : startIndex
    let endIndex = dataStartIndex + hours * 4
    endIndex = endIndex > values?.length ? values?.length : endIndex

    for (let i = dataStartIndex; i < endIndex; i++) {
      const aggrValue = values[i]?.value
      let timeStamp
      if (isEnergyDemandWidget) {
        timeStamp = moment(values[i]?.timeStamp).format()
      } else {
        timeStamp = moment(values[i]?.timeStamp)?.format('hh:mm A')
      }
      finalArray.push({
        timeStamp,
        aggrValue
      })
    }
    return finalArray
  } catch (error) {
    return []
  }
}

const quarterlyAggregation = (
  values = [],
  aggregatorFunction,
  selectedTimePeriod,
  timeZone
) => {
  try {
    const aggrValues = []
    if (values.length > 0) {
      const isPastYear = selectedTimePeriod === 'past-12-months'
      const maxIndex = values?.length
      const { startDate: startChartDate, endDate: endChartDate } =
        getStartAndEndDates(selectedTimePerio, false, null, timeZone)
      let currentTime = startChartDate
      let startIndex = 0
      const currentQuarterStart = moment(currentTime).startOf('quarter')
      const currentQuarterEnd = moment(currentTime).endOf('quarter')
      const daysInCurrentQuearter = currentQuarterEnd.diff(
        currentQuarterStart,
        'days'
      )
      let endIndex =
        daysInCurrentQuearter > maxIndex ? maxIndex : daysInCurrentQuearter
      if (isPastYear) {
        endIndex = currentQuarterEnd.diff(moment(startChartDate), 'days')
      }
      while (maxIndex >= endIndex) {
        const timeStamp = `Q${moment(currentTime)?.quarter()}${
          isPastYear ? '-' + moment(currentTime).format('YYYY') : ''
        }`
        let aggrValue = Number(
          aggreagatorSelection(aggregatorFunction, values, startIndex, endIndex)
        ).toFixed(2)
        aggrValues.push({
          timeStamp: timeStamp,
          value: aggrValue
        })
        startIndex = endIndex
        currentTime = moment(currentTime).add(3, 'months').toDate()
        const currentQuarterStart = moment(currentTime).startOf('quarter')
        const currentQuarterEnd = moment(currentTime).endOf('quarter')
        const daysInCurrentQuearter =
          currentQuarterEnd.diff(currentQuarterStart, 'days') + 1
        endIndex = endIndex + daysInCurrentQuearter
        if (endIndex > maxIndex && maxIndex > startIndex) {
          endIndex = maxIndex - startIndex + startIndex
        }
      }
    }
    return aggrValues
  } catch (error) {
    return []
  }
}

const monthlyAggregation = (
  values = [],
  aggregatorFunction,
  selectedTimePeriod,
  timeZone
) => {
  try {
    const aggrValues = []
    if (values.length > 0) {
      const maxIndex = values?.length
      const { startDate, endDate } = getStartAndEndDates(selectedTimePeriod, false, null, timeZone)
      const startChartDate = moment(startDate)
      // const endChartDate = moment(endDate)
      let currentTime = startChartDate
      let startIndex = 0
      let endIndex =
        moment(currentTime).daysInMonth() - moment(currentTime).format('DD') + 1
      while (maxIndex >= endIndex) {
        const timeStamp = moment(currentTime)?.format('MMM')
        let aggrValue = Number(
          aggreagatorSelection(aggregatorFunction, values, startIndex, endIndex)
        ).toFixed(2)
        aggrValues.push({
          timeStamp: timeStamp,
          value: aggrValue
        })
        startIndex = endIndex
        currentTime = moment(currentTime).add(1, 'months').toDate()
        endIndex += moment(currentTime).daysInMonth()
        if (endIndex > maxIndex && maxIndex > startIndex) {
          endIndex = maxIndex - startIndex + startIndex
        }
      }
    }
    return aggrValues
  } catch (error) {
    return []
  }
}

const dailyAggregation = (
  values = [],
  aggregatorFunction,
  selectedTimePeriod,
  timeZone
) => {
  // We don't need to aggr, we are only fomratting labels here
  try {
    const aggrValues = []
    if (values?.length > 0) {
      const { startDate, endDate } = getStartAndEndDates(selectedTimePeriod, false, null, timeZone)
      const startChartDate = moment(startDate)
      const endChartDate = moment(endDate)
      let currentTime = startChartDate
      let valueIndex = 0
      while (currentTime <= endChartDate) {
        const lDateFormat = getDateFormatByLocale('MM/DD');
        const timeStamp = moment(currentTime)?.format(
          selectedTimePeriod?.includes('week') ||
            selectedTimePeriod?.includes('7-days')
            ? 'ddd'
            : lDateFormat ? lDateFormat : 'MM/DD'
        )
        let aggrValue = values[valueIndex]?.value || 0
        aggrValues.push({
          timeStamp: timeStamp,
          value: Number(aggrValue?.toFixed(2))
        })
        currentTime = moment(currentTime).add(24, 'hours').toDate()
        valueIndex += 1
      }
    }
    return aggrValues
  } catch (error) {
    console.log(error)
    return []
  }
}

const hourlyAggregationPastTimeStampData = (
  values = [],
  aggregatorFunction,
  selectedTimePeriod,
  widgetType,
  timeZone
) => {
  try {
    const isPast24Hours = selectedTimePeriod === "past-24-hours"
    let finalArray = []
    const isEnergyDemandWidget = widgetType === WidgetNames.EnergyDemand
    let hours = selectedTimePeriod?.match(/\d+/)?.[0] || 1
    if (values?.length === 0) {
      return []
    }
    const { startDate: startTimeStamp, endDate } = getStartAndEndDates(
      selectedTimePeriod,
      false,
      'YYYY-MM-DD HH:mm',
      timeZone
    )
    const roundingOffToNearestHour = roundOfToNearestHour(startTimeStamp)
    const startIndex =
      values.findIndex((x) => x.timeStamp === roundingOffToNearestHour) || 0
    const dataStartIndex = startIndex === -1 ? 0 : startIndex
    let endIndex = dataStartIndex + hours * 4 + 4
    endIndex = endIndex > values?.length ? values?.length : endIndex

    for (let i = dataStartIndex; i < endIndex; ) {
      let timeStamp
      if (isEnergyDemandWidget) {
        timeStamp = moment(values[i]?.timeStamp).format()
      } else if(isPast24Hours) {
        timeStamp = moment(values[i]?.timeStamp)?.format('h A')
      }else{
        timeStamp = moment(values[i]?.timeStamp)?.format('hh:mm A')
      }
      let aggrValue = Number(
        aggreagatorSelection(aggregatorFunction, values, i, i + 4)
      ).toFixed(2)
      finalArray.push({
        timeStamp,
        aggrValue
      })
      i = i + 4
    }
    return finalArray
  } catch (error) {
    return []
  }
}

const hourlyAggregation = (
  values = [],
  aggregatorFunction,
  selectedTimePeriod,
  timeZone
) => {
  try {
    const aggrValues = []
    if (values?.length > 0) {
      const maxIndex = values?.length
      const { startDate, endDate } = getStartAndEndDates(
        selectedTimePeriod,
        false,
        null,
        timeZone
      )
      const startChartDate = moment(startDate)
      const endChartDate = moment(endDate)
        .add(1, 'days')
        .subtract(15, 'minutes')
        .toDate()
      let currentTime = startChartDate
      let startIndex = 0
      let endIndex = 4
      while (currentTime <= endChartDate && maxIndex > endIndex) {
        const timeStamp = moment(currentTime)?.format('h A')
        let aggrValue = Number(
          aggreagatorSelection(aggregatorFunction, values, startIndex, endIndex)
        ).toFixed(2)
        aggrValues.push({
          timeStamp: timeStamp,
          value: aggrValue
        })
        startIndex += 4
        endIndex += 4
        currentTime = moment(currentTime).add(60, 'minutes').toDate()
      }
    }
    return aggrValues
  } catch (error) {
    return []
  }
}

export const formatDataForWidgetChart = (
  valuesArray = [],
  aggregatorFunction,
  dataAggregation = 'daily',
  selectedTimePeriod = 'current-month',
  tZone,
  widgetType
) => {
  const timeZone = tZone || moment.tz.guess()
  try {
    if (valuesArray?.length > 0) {
      // if hourly means, we have requested 15 mins data, so no aggr from API
      switch (dataAggregation) {
        case '15-minutes':
          const minutesDataFiltered = minutesData(
            valuesArray,
            selectedTimePeriod,
            widgetType,
            timeZone
          )
          return minutesDataFiltered
        case 'hourly':
          if (selectedTimePeriod?.includes('past')) {
            const hourlyTimestampAggrData = hourlyAggregationPastTimeStampData(
              valuesArray,
              aggregatorFunction,
              selectedTimePeriod,
              widgetType,
              timeZone
            )
            return hourlyTimestampAggrData
          } else {
            const hourlyAggrData = hourlyAggregation(
              valuesArray,
              aggregatorFunction,
              selectedTimePeriod,
              timeZone
            )
            return hourlyAggrData
          }
        case 'daily':
          const dailyAggrData = dailyAggregation(
            valuesArray,
            aggregatorFunction,
            selectedTimePeriod,
            timeZone
          )
          return dailyAggrData
        case 'monthly':
          const monthlyAggrData = monthlyAggregation(
            valuesArray,
            aggregatorFunction,
            selectedTimePeriod,
            timeZone
          )
          return monthlyAggrData
        case 'quarterly':
          const quarterlyAggrData = quarterlyAggregation(
            valuesArray,
            aggregatorFunction,
            selectedTimePeriod,
            timeZone
          )
          return quarterlyAggrData
        default:
          return []
      }
    }
  } catch (error) {
    return []
  }
}

export const getInnerBarRecordCount = (selectedTimePeriod, startDt, endDt, timeZone) => {
  let totRecordCount = 0
  if (
    ['current-day', 'past-3-days', 'past-7-days'].includes(selectedTimePeriod)
  ) {
    const currentDateWithNear15Min = roundToNearest15MinutesTimeStamp(moment(),timeZone)
    const totHours = Math.trunc(
      (moment(currentDateWithNear15Min).hours() * 60) / 15
    )
    const totMinutes = Math.trunc(
      moment(currentDateWithNear15Min).minutes() / 15
    )
    totRecordCount = totHours + totMinutes
  }
  if (
    [
      'current-month',
      'past-3-months',
      'past-6-months',
      'current-year',
      'previous-quarter'
    ].includes(selectedTimePeriod)
  ) {
    totRecordCount = Number(
      (moment(endDt).diff(moment(startDt), 'days') ?? 0) + 1
    )
  }
  return totRecordCount
}

export const sortByDates = (array) => {
	return array?.values
		?.sort((a, b) => moment(a?.timeStamp) - moment(b.timeStamp))
		?.map((x) => {
			return {
				...x,
				timeStamp: moment(x?.timeStamp)?.format('MMM-YYYY')
			}
		})
}

// index property is missed in baseline data, so push the index props
export const formatDataWithIndex = (baselineData,isEnergyCost = true) => {
	const newBaselineData = {}
	for (const id in baselineData) {
		let equipmentData = null
    if(isEnergyCost){
      equipmentData = baselineData[id]
    }else{
      equipmentData = baselineData[id]["sum"]
    }
		const properties = {}
		const existingPropertiesObj = equipmentData?.properties || {}
		for (const propertyKey in existingPropertiesObj) {
			const index = existingPropertiesObj[propertyKey]
			properties[propertyKey] = {
				index,
				name: propertyKey
			}
		}
		newBaselineData[id] = {
			properties,
			values: equipmentData?.values
		}
	}
	return newBaselineData
}

export const formatMonthlyEnergyData = (data, isAdditionalValueAdd = false) => {
  try {
    for (const equipmentId in data) {
      const consumptionCost = {
        equipmentId: equipmentId,
        propertyKey: 'ConsumptionCost',
        propertyName: 'ConsumptionCost',
        values: [],
        isDataAvailable: false
      }

      const demandCost = {
        equipmentId: equipmentId,
        propertyKey: 'DemandCost',
        propertyName: 'DemandCost',
        values: [],
        isDataAvailable: false
      }

      const additionalCost = {
        equipmentId: equipmentId,
        propertyKey: 'AdditionalCost',
        propertyName: 'AdditionalCost',
        values: [],
        isDataAvailable: false
      }

      const gasConsumptionCost = {
        equipmentId: equipmentId,
        propertyKey: 'GasConsumptionCost',
        propertyName: 'GasConsumptionCost',
        values: [],
        isDataAvailable: false
      }

      const totalCost = {
        equipmentId: equipmentId,
        propertyKey: 'totalCost',
        propertyName: 'totalCost',
        values: [],
        isDataAvailable: false
      }

      const equipmentData = data[equipmentId]
      const equipmentProperties = equipmentData?.properties
      // Checking Whether we have calculation Or Not
      const isNoElectricalCostEstimate = _isEmpty(equipmentProperties['ConsumptionCost'])
      const isNoGasCostEstimate = _isEmpty(equipmentProperties['GasConsumptionCost'])
      const isNoTotalCostEstimate = isNoElectricalCostEstimate && isNoGasCostEstimate
      const consumptionCostIndex =
        equipmentProperties['ConsumptionCost']?.index
      const additionalCostIndex = equipmentProperties['AdditionalCost']?.index
      const demandCostIndex = equipmentProperties['DemandCost']?.index
      const gasConsumptionIndex =
        equipmentProperties['GasConsumptionCost']?.index

      for (const year in equipmentData?.values) {
        const monthlyValues = equipmentData?.values[year]
        for (const month in monthlyValues) {
          const values = monthlyValues[month]

          const consumptionCostMonthlyValue =
            values[consumptionCostIndex] || 0
          const demandCostMonthlyValue = values[demandCostIndex] || 0
          const additionalCostMontlyValue = values[additionalCostIndex] || 0
          const gasConsumptionCostMonthlyValue =
            values[gasConsumptionIndex] || 0

          const timeStamp = moment(`${month}-01-${year}`).format('MM-DD-YYYY')

          // push values to array
          consumptionCost.values.push({
            timeStamp,
            value: consumptionCostMonthlyValue
          })
          gasConsumptionCost.values.push({
            timeStamp,
            value: gasConsumptionCostMonthlyValue
          })
          demandCost.values.push({
            timeStamp,
            value: demandCostMonthlyValue
          })
          additionalCost.values.push({
            timeStamp,
            value: additionalCostMontlyValue
          })
          let totalCostMonthlyValue = 0 
          if (!isAdditionalValueAdd) {
            totalCostMonthlyValue = consumptionCostMonthlyValue +
              gasConsumptionCostMonthlyValue +
              demandCostMonthlyValue +
              additionalCostMontlyValue
          } else {
            totalCostMonthlyValue = consumptionCostMonthlyValue +
              gasConsumptionCostMonthlyValue
          }
            

          totalCost.values.push({
            timeStamp,
            value: totalCostMonthlyValue
          })
        }
      }

      consumptionCost.values = sortByDates(consumptionCost)
      demandCost.values = sortByDates(demandCost)
      additionalCost.values = sortByDates(additionalCost)
      gasConsumptionCost.values = sortByDates(gasConsumptionCost)
      totalCost.values = sortByDates(totalCost)

      const isDataAvailable = totalCost?.values?.some((x) => x?.value !== 0)

      return {
        consumptionCost,
        demandCost,
        additionalCost,
        gasConsumptionCost,
        totalCost,
        isDataAvailable,
        electricalCostError: { isNoEstimate: isNoElectricalCostEstimate, isNoDataAvailable: consumptionCost?.values?.length === 0 },
        gasCostError: { isNoEstimate: isNoGasCostEstimate, isNoDataAvailable: gasConsumptionCost?.values?.length === 0 },
        totalCostError: { isNoEstimate: isNoTotalCostEstimate, isNoDataAvailable: totalCost?.values?.length === 0 }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const LanguageCodeForIntl = {
    "pt-PT": 'pt',
    "es": 'pt',
    "es-MX": 'pt'
}
//Handling Date Format By Locale Setting
export const getDateFormatByLocale = (OriginalFormat) => {
  let FinalDateFormat = OriginalFormat;
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? LanguageCodeForIntl[selectedLanguage] ? LanguageCodeForIntl[selectedLanguage] : selectedLanguage : "en";
  
  if(selectedLanguage === "en")
    return OriginalFormat;

  let lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: '2-digit', day: '2-digit' }).formatToParts()
  switch (OriginalFormat) {
    case 'MM/DD':
    case 'DD/MM':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: '2-digit', day: '2-digit' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'month' ? 'MM' : 'DD'
        const Part2 = lDateFormat[2].type === 'month' ? 'MM' : 'DD'
        const literal = lDateFormat[1].type === "literal" ? lDateFormat[1].value : "/"
        FinalDateFormat = Part1 + literal + Part2;
      }
      return FinalDateFormat
    case 'MM/DD/YYYY':
    case 'YYYY-MM-DD':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: '2-digit', day: '2-digit', year: 'numeric' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'day' ? 'DD' : lDateFormat[0].type === 'month' ? 'MM' : 'YYYY'
        const Part2 = lDateFormat[2].type === 'day' ? 'DD' : lDateFormat[2].type === 'month' ? 'MM' : 'YYYY'
        const Part3 = lDateFormat[4].type === 'day' ? 'DD' : lDateFormat[4].type === 'month' ? 'MM' : 'YYYY'
        const literal = lDateFormat[1].type === "literal" ? lDateFormat[1].value : "/"
        FinalDateFormat = Part1 + literal + Part2 + literal + Part3;
      }
      return FinalDateFormat
    case 'M/D/YYYY':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: '2-digit', day: '2-digit', year: 'numeric' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'day' ? 'D' : lDateFormat[0].type === 'month' ? 'M' : 'YYYY'
        const Part2 = lDateFormat[2].type === 'day' ? 'D' : lDateFormat[2].type === 'month' ? 'M' : 'YYYY'
        const Part3 = lDateFormat[4].type === 'day' ? 'D' : lDateFormat[4].type === 'month' ? 'M' : 'YYYY'
        const literal = lDateFormat[1].type === "literal" ? lDateFormat[1].value : "/"
        FinalDateFormat = Part1 + literal + Part2 + literal + Part3;
      }
      return FinalDateFormat
    case 'MMM DD, YYYY':
    case 'MMM DD,YYYY':
    case 'hh:mm A MMM DD, YYYY':
    case 'MMM D, YYYY':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: 'short', day: '2-digit', year: 'numeric' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'day' ? 'DD' : lDateFormat[0].type === 'month' ? 'MMM' : 'YYYY'
        const Part2 = lDateFormat[2].type === 'day' ? 'DD' : lDateFormat[2].type === 'month' ? 'MMM' : 'YYYY'
        const Part3 = lDateFormat[4].type === 'day' ? 'DD' : lDateFormat[4].type === 'month' ? 'MMM' : 'YYYY'
        const literal1 = lDateFormat[1].type === "literal" ? lDateFormat[1].value === " de " ? " [de] " : lDateFormat[1].value : " "
        const literal2 = lDateFormat[3].type === "literal" ? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[3].value : ", "
        if(OriginalFormat === 'MMM DD, YYYY' || OriginalFormat === 'MMM DD,YYYY'  || OriginalFormat === 'MMM D, YYYY')
          FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3;
        else
          FinalDateFormat = 'hh:mm A ' + Part1 + literal1 + Part2 + literal2 + Part3;
      }
      return FinalDateFormat
    case 'MMM D, YYYY h:mm A':
    case 'MMM DD, YYYY hh:mm A':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: 'short', day: 'numeric', year: 'numeric' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'day' ? 'D' : lDateFormat[0].type === 'month' ? 'MMM' : 'YYYY'
        const Part2 = lDateFormat[2].type === 'day' ? 'D' : lDateFormat[2].type === 'month' ? 'MMM' : 'YYYY'
        const Part3 = lDateFormat[4].type === 'day' ? 'D' : lDateFormat[4].type === 'month' ? 'MMM' : 'YYYY'
        const literal1 = lDateFormat[1].type === "literal" ? lDateFormat[1].value === " de " ? " [de] " : lDateFormat[1].value : " "
        const literal2 = lDateFormat[3].type === "literal" ? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[3].value : ", "
        FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + ' h:mm A';
      }
      return FinalDateFormat
    case 'MMM D, YYYY, h:mm:ss A':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: 'short', day: 'numeric', year: 'numeric' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'day' ? 'D' : lDateFormat[0].type === 'month' ? 'MMM' : 'YYYY'
        const Part2 = lDateFormat[2].type === 'day' ? 'D' : lDateFormat[2].type === 'month' ? 'MMM' : 'YYYY'
        const Part3 = lDateFormat[4].type === 'day' ? 'D' : lDateFormat[4].type === 'month' ? 'MMM' : 'YYYY'
        const literal1 = lDateFormat[1].type === "literal" ? lDateFormat[1].value === " de " ? " [de] " : lDateFormat[1].value : " "
        const literal2 = lDateFormat[3].type === "literal" ? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[3].value : ", "
        FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + ' h:mm:ss A';
      }
      return FinalDateFormat
    case 'MMM YYYY':
    case 'MMM, YYYY':
    case 'MMM , YYYY':
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: 'short', year: 'numeric' }).formatToParts()
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === 'month' ? 'MMM' : 'YYYY'
        const Part2 = lDateFormat[2].type === 'month' ? 'MMM' : 'YYYY'
        const literal = lDateFormat[1].type === "literal" ? lDateFormat[1].value  === " de " ? " [de] " : lDateFormat[1].value : " "
        FinalDateFormat = Part1 + literal + Part2;
      }
      return FinalDateFormat
    default:
        return OriginalFormat
  }
}
