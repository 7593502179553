export const dark = {
  border: '1px solid var(--amplify-colors-neutral-20)',
  borderRadius: '1.5rem',
  padding: '1.25rem',
  input: {
    border: '1px solid var(--amplify-colors-neutral-20)',
    borderRadius: '0.75rem',
    backgroundColor: 'transparent'
  },
  chip: {
    borderRadius: '0.5rem',
  }
}

export const light = dark;