// Create some types for the Energy Intensity page.

import { type FrameAnchor } from "@observablehq/plot"
import { Dispatch, SetStateAction } from "react"
import { BuildingId, OrganizationId } from "src/components/buildingNavigation/graphql"
import { PlotChartCustomMinMax } from "src/denali-components/PlotChart/types"
import { IUseQueryRefetchProps } from "src/hooks/APIHooks"

// This will be the type for the chartData object that is passed in to the EnergyIntensity component.
export const enum MarkNames {
  GRADIENT = 'gradient',
  BENCHMARK = 'benchmark',
  MARKER = 'marker',
  EUI = 'eui',
  ECI = 'eci',
  EUI_TIP = 'euiTip',
  ECI_TIP = 'eciTip'
}

export const enum PlotChartValueType {
  BENCHMARK = 'benchmark',
  MARKER = 'marker',
  NONE = 'none'
}

export type ChartDataItem = {
  eci?: number
  electricUtilityData: ElectricUtilityData
  eui?: number
  gasUtilityData: GasUtilityData
  month: number
  year: number
  data?: number
  type?: string
  date?: Date
}

export type ChartControlUpdateData = {
  startDate: string
  endDate: string
  dateError: boolean
}

export type EnergyIntensityChartProps = {
  chartData: ChartDataItem[]
  chartDates: ChartDatesData
  euiEnabled: boolean
  eciEnabled: boolean
  diffDays: number
  benchmarks?: EnergyIntensityBenchmark[]
  benchmarksEnabled: Set<string>
  markers?: EnergyIntensityMarker[]
  markersEnabled: Set<string>
  isDefaultYAxis: boolean
  customMinMax?: PlotChartCustomMinMax
}

export type EnergyIntensityDateRange = {
  startDate: string
  endDate: string
}

export type ElectricUtilityData = {
  consumptionCost: number
  endDate?: string
  fees: number
  startDate?: string
  totalDemandCost: number
}

export type GasUtilityData = {
  endDate?: string
  startDate?: string
  totalCost: number
}

export type ChartDatesData = {
  chartStartDate: string
  chartEndDate: string
  dateError: boolean
}

export type EnergyIntensityMarker = {
  buildingIds: string[]
  checked: boolean
  description?: string
  id: string
  markerDate: string
  name: string
  type: string
}

export type EnergyIntensityBenchmark = {
  buildingId: string
  checked: boolean
  description?: string
  id: string
  markerDate: string
  name: string
  type: string
  updatedAt: string
  value: number
}

export type EnergyIntensityBenchmarkModalProps = {
  benchmark: EnergyIntensityBenchmark
  setModalType: (modalType: string) => void
  refetchBenchmarksData: (variables: any) => void
  buildingId: string
}

export type EnergyIntensityMarkerModalProps = {
  marker: EnergyIntensityMarker
  setModalType: (modalType: string) => void
  refetchMarkersData: (variables: any) => void
  organizationId: string
  buildingId: string
}

// Sidebar props, we get a list of enabled benchmarks and markers as well as the whole list.
// This way we can list all of the options and know which ones are enabled.
export type EnergyIntensitySidebarProps = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  isEUIActive?: boolean
  isECIActive?: boolean
  onEUIClick?: () => void
  onECIClick?: () => void
  addBenchmark?: () => void
  addEventMarker?: React.MouseEventHandler<HTMLButtonElement>
}

export type EnergyIntensityProps = {
  chartData: ChartDataItem[]
  chartDates: ChartDatesData | null
  allChartDates: ChartDatesData | null
  markers: EnergyIntensityMarker[]
  benchmarks: EnergyIntensityBenchmark[]
  onChartControlUpdate: (data: ChartControlUpdateData) => void
  testName: string
  isDefaultYAxis: boolean
  customMinMax: PlotChartCustomMinMax
  updateChartMinMax: (values: PlotChartCustomMinMax) => void
  onMarkersUpdate: (markers: EnergyIntensityMarker[]) => void
  onBenchmarksUpdate: (benchmarks: EnergyIntensityBenchmark[]) => void
}

export type UseEnergyIntensityBenchmarksAndMarkersTogglesProps = {
  benchmarks: EnergyIntensityBenchmark[]
  markers: EnergyIntensityMarker[]
  chartDates: ChartDatesData
}

export type EnergyIntensityBenchmarksAndMarkersContextType = {
  markers: EnergyIntensityMarker[]
  onToggleMarker: (marker: EnergyIntensityMarker) => void
  benchmarks: EnergyIntensityBenchmark[]
  onToggleBenchmark: (benchmark: EnergyIntensityBenchmark) => void
  totalEnergyCost: number
  chartDates: ChartDatesData
  dateRange: string
}

export type EnergyIntensityBenchmarksAndMarkersContextProviderProps = {
  children: React.ReactNode
  markers: EnergyIntensityMarker[]
  onToggleMarker: (marker: EnergyIntensityMarker) => void
  benchmarks: EnergyIntensityBenchmark[]
  onToggleBenchmark: (benchmark: EnergyIntensityBenchmark) => void
  totalEnergyCost: number
  chartDates: ChartDatesData
  dateRange: string
}

export type EnergyIntensityDatePickerDialogProps = {
  isOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  onDatesChange: (dates: EnergyIntensityDateRange) => void
  chartDates: ChartDatesData
}

export type EnergyIntensityChartMinMaxSettingsProps = {
  settingsConfig: EnergyIntensityChartMinMaxSettingsConfig
  updateSettingsConfig: (values: any, isSaved?: boolean) => void
  initialSectionValues: PlotChartCustomMinMax
  setIsChartSettingsOpen: (isOpen: boolean) => void
}

export type EnergyIntensityChartMinMaxSettingsConfig = {
  heading: string
  subHeader: string
  inputHeading: string
  inputMessage: string
  chartPage: boolean
  sectionHeader: string
  sections: EnergyIntensityChartMinMaxSettingsSectionConfig[] | null
}

export type EnergyIntensityChartMinMaxSettingsSectionConfig = {
  key: string
  name: string
  displayDecimal: boolean
  fields: {
    min: number
    max: number
  }
  errors: string | null
}

export type EnergyIntensityCalculateChartMinMaxProps = {
  chartData: ChartDataItem[]
  benchmarks: EnergyIntensityBenchmark[]
}

export type BenchmarkDetailProps = {
  mode: string
  setMode: (mode: string) => void
  benchmark: EnergyIntensityBenchmark
  setBenchmark: (benchmark: EnergyIntensityBenchmark) => void
  accountId: string
  onComplete: () => void
  buildingId: string
}

export type EnergyIntensityTogglesProps = {
  markers: EnergyIntensityMarker[]
  benchmarks: EnergyIntensityBenchmark[]
  onMarkersUpdate: (markers: EnergyIntensityMarker[]) => void
  onBenchmarksUpdate: (benchmarks: EnergyIntensityBenchmark[]) => void
}

export type EnergyIntensityToggles = {
  ranges: string[]
  setRanges: Dispatch<SetStateAction<string[]>>
  activeRange: string
  setActiveRange: Dispatch<SetStateAction<string>>
  chartView: string
  setChartView: Dispatch<SetStateAction<string>>
  euiEnabled: boolean
  setEuiEnabled: Dispatch<SetStateAction<boolean>>
  eciEnabled: boolean
  setEciEnabled: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  onToggleMarker: (marker: EnergyIntensityMarker) => void
  onToggleBenchmark: (benchmark: EnergyIntensityBenchmark) => void
}

export type EnergyIntensityStateProps = {
  buildingId: BuildingId
  organizationId: OrganizationId
  onMarkersUpdate: (markers: EnergyIntensityMarker[]) => void
  onBenchmarksUpdate: (benchmarks: EnergyIntensityBenchmark[]) => void
}

export type EnergyIntensityState = {
  markersData: EnergyIntensityMarker[]
  benchmarksData: EnergyIntensityBenchmark[]
  refetchMarkersData: IUseQueryRefetchProps
  refetchBenchmarksData: IUseQueryRefetchProps
}

export type EnergyIntensityMarksOrderState = {
  marksOrder: MarkNames[]
  setMarksOrder: Dispatch<SetStateAction<MarkNames[]>>
  plotValueType: PlotChartValueType
  setPlotValueType: Dispatch<SetStateAction<PlotChartValueType>>
}

export type EnergyIntensityCreateBenchmarkRuleProps = {
  value: number
  name: string
  stroke?: string
  strokeWidth?: number
  className?: string
  showTip?: boolean
  anchor?: FrameAnchor
  frameAnchor?: FrameAnchor
}

export type EnergyIntensityCreateMarkerRuleProps = {
  value: string | Date | ((d: any) => string | Date)
  name: string | ((d: any) => string)
  stroke?: string
  strokeWidth?: number
  strokeDasharray?: number
  className?: string
  showTip?: boolean
  anchor?: FrameAnchor
  frameAnchor?: FrameAnchor
}
