import { AutomatedTestSuppressionsHistoryListContainer } from '../styles'
import { getSearchParams } from 'src/common/helperFunctions.js'
import Table from 'src/components/Table/clientSideTable'
import translate, { TranslateComponent } from 'src/common/translations'
import Spinner from 'src/components/legacy/components/spinner/spinner'

const SuppressionsHistoryList = ({ suppressionHistoryList, loading }) => {
  const { buildingId } = getSearchParams()
  const optionsTranslated = {
    testName: translate('Test Name'),
    equipmentType: translate('Equipment Type'),
    name: translate('Name'),
    suppressionStart: translate('Suppression Start'),
    suppressionEnd: translate('Suppression End'),
    suppressionLevel: translate('Suppression Level')
  }

  const nameHeadings = [
    {
      title: optionsTranslated.testName,
      key: 'displayNameUI',
      maxWidth: '200px'
    },
    {
      title: optionsTranslated.equipmentType,
      key: 'equipTypeUI',
      maxWidth: '150px'
    },
    {
      title: optionsTranslated.name,
      key: 'Name',
      maxWidth: '200px'
    },
    {
      title: optionsTranslated.suppressionStart,
      key: 'startDate',
      maxWidth: '100px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>
            <p>{row?.startDate}</p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.suppressionEnd,
      key: 'endDate',
      maxWidth: '100px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>
            <p>{row.endDate === 'Indefinitely' ? <TranslateComponent>{row?.endDate}</TranslateComponent> : row.endDate}</p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.suppressionLevel,
      key: 'suppressionLevel',
      maxWidth: '200px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p><TranslateComponent>{row?.suppressionLevel}</TranslateComponent></p>
          </div>
        )
      }
    }
  ]

  return loading ? (
    <Spinner />
  ) : (
    <AutomatedTestSuppressionsHistoryListContainer>
      <div className="automated-tests-suppressions-history-list">
        <Table
          key={`AutomatedTestSuppressionsHistoryTable-${buildingId}`}
          header={nameHeadings || []}
          rows={suppressionHistoryList || []}
          search={true}
          searchFields={['displayNameUI']}
          rowControls={[]}
          fixedLayout={false}
        />
      </div>
    </AutomatedTestSuppressionsHistoryListContainer>
  )
}
export default SuppressionsHistoryList
