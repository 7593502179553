import { combineReducers } from '@reduxjs/toolkit'
import { appData, buildingPickerData, errorSlice, translationSlice , dashboardSlice, translationCombinedSlice } from './slicers'
import { RootState } from './RootState'
import { formData } from './slicers/formData'

const reducer = combineReducers<RootState>({
  appData: appData.reducer,
  formData: formData.reducer,
  buildingPicker: buildingPickerData.reducer,
  translationSlice: translationSlice.reducer,
  translationCombinedSlice: translationCombinedSlice.reducer,
  errorSlice: errorSlice.reducer,
  dashboard: dashboardSlice.reducer
})

export default reducer
