import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { UPDATE_EQUIPMENT_PROPERTY } from '../graphql/mutations'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import {
  dataMappingPropertiesFormatter,
  characterticsFormatter,
  getLastestValuesOfProperties,
  getChillerFilteredPropertyMetaData
} from './helper'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import {
  getAutomatedTestResultsByEquipmentType,
  getEquipmentDetailsbyId,
  GET_BUILDING_TOTAL_METER_EQUIPMENT,
  GET_BY_EQUIPMENT_META_DATA,
  GET_DEVICES_BY_DEVICE_ID
} from '../graphql/queries'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getDeviceMessageGroupId } from './MapDownload/graphql'
import {
  MapDownload,
  AssociatedEquipmentWrapper,
  AutomatedTests,
  Characteristics,
  DataMapping,
  Definitions
} from './index'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import { HeaderContainer } from '../styles'
import {
  TabsWrapper,
  DetailsWrapper,
  LeftSection,
  ImageDiv,
  DetailTable,
  RightSection,
  LoadFactoryButton,
  SerialNumberInput,
  SerialNumberActionButton,
  NoteText,
  ResizableSpinner
} from './style'
import { EQ_TYPE_CHILLER } from './helper'
import _orderBy from 'lodash/orderBy'
import {
  GET_DATA_CHARACTERISTICS_PAGINATED,
  GET_DATA_MAPPING_PROPERTIES_PAGINATED,
  GET_DATA_MAPPING_CALCULATED_PAGINATED
} from '../graphql/queries'
import CharacteristicCurves from './CharacteristicsCurves/CharacteristicCurves'
import { DETAILS_TEXT_NAME_PREFIX } from '../constants'
import { AT_STATUS_ENABLED_EQUIPMENTS } from './constants'
import colors from 'src/components/layouts/colors.json'
import IconSVG from 'src/components/Icon/index.js'
import Modal from 'src/components/legacy/components/modal/modal'

import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import { TranslateComponent } from '../../../common/translations'
import store from 'src/redux/store'
import { useLocation } from 'react-router'

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

const EquipmentDetails = () => {
  // Dimension hook to get all Dimensions
  const { loadingDimensions, defaultDimensionGroup, getAllDimensions } =
    useDimensionsHook()

  const { equipment: equipmentId, tab } = getSearchParams()
  const [equipmentType, setEquipmentType] = useState('')
  const [serialNumberDetails, setSerialNumberDetails] = useState(null)
  const [dataMappingProperties, setDataMappingProperties] = useState()
  const [data_Mapping_Source_Properties, setData_Mapping_Source_Properties] = useState([])
  const [data_Mapping_Calculated, setData_Mapping_Calculated] = useState([])
  const [data_Mapping_Properties, setData_Mapping_Properties] = useState([])
  const [characteristicsList, setCharacteristicsList] = useState([])
  const [formatedCharacteristicsList, setFormattedCharacteristicsList] =
    useState([])
  const [equipAssocatedDeviceDetails, setEquipAssocatedDeviceDetails] =
    useState(null)
  const [chillerFilteredPropertyMetaData, setChillerFilteredPropertyMetaData] =
    useState(null)
  const [lastestValueAPICalled, setLastestValueAPICalled] = useState(false)
  useState(false)

  const [reloadCharacteriticCurves, setReloadCharacteriticCurves] =
    useState(false)

  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const UOMbyProfile = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const activeAutomatedTest = queryParams.get('automatedtest')

  const [activeTab, setActiveTab] = useState(activeAutomatedTest ? 0 : 1)


  const {
    refetch: getPropertyLatestValues,
    loading: loadingPropertyLatestValues
  } = useQuery({
    query: GET_CHART_DATA,
    dataPath: 'data.getChartData',
    disableInitialLoad: true
  })

  // TO GET EQUIPMENTDETAILS
  const {
    data: equipmentDetails,
    refetch: refetchEquipmentById,
    loading: loadingEquipmentDetails
  } = useQuery({
    query: getEquipmentDetailsbyId,
    variables: { id: equipmentId },
    dataPath: 'data.getEquipment',
    errorPolicy: 'global'
  })

  const {
    data: deviceDetails,
    refetch: refetchDeviceDetails,
    loading: loadingDeviceDetails
  } = useQuery({
    query: getDeviceMessageGroupId,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDevice'
  })

  const {
    data: equipmentAssociatedDevices,
    refetch: refetchDevicesByDeviceId
  } = useQuery({
    query: GET_DEVICES_BY_DEVICE_ID,
    variables: { deviceId: equipmentDetails?.deviceId },
    dataPath: 'data.listDevicesByParent.items',
    disableInitialLoad: true,
    errorPolicy: 'global'
  })

  const [loadingDataMappingProperties, refetchDataProperties, dataProperties] =
    useAPIPagination(
      GET_DATA_MAPPING_PROPERTIES_PAGINATED,
      'data.getPropertyMappingByEquipmentID'
    )

    const [loadingDataMappingSourceProperties, refetchDataMappingSourceProperties, dataMappingSourceProperties] =
    useAPIPagination(
      GET_DATA_MAPPING_PROPERTIES_PAGINATED,
      'data.getPropertyMappingByEquipmentID'
    )

  const [
    loadingDataMappingCalculatedProperties,
    refetchDataCalculated,
    dataCalculated
  ] = useAPIPagination(
    GET_DATA_MAPPING_CALCULATED_PAGINATED,
    'data.getPropertyMappingByEquipmentID'
  )

  const [
    loadingDataMappingSOurceCalculatedProperties,
    refetchDataMappingSOurceCalculated,
    dataMappingSourceCalculated
  ] = useAPIPagination(
    GET_DATA_MAPPING_CALCULATED_PAGINATED,
    'data.getPropertyMappingByEquipmentID'
  )

  const [
    loadingCharacteristicsLoading,
    refetchCharacteristicsData,
    characteristicsListData
  ] = useAPIPagination(
    GET_DATA_CHARACTERISTICS_PAGINATED,
    'data.getPropertyMappingByEquipmentID'
  )

  const [
    loadingEquipmentMetaData,
    refetchEquipmentPropertiesMetaData,
    equipmentMetaData
  ] = useAPIPagination(GET_BY_EQUIPMENT_META_DATA, 'data.getByEquipmentType')

  const { refetch: refetchBuildingTotalMeterEquipment } = useQuery({
    query: GET_BUILDING_TOTAL_METER_EQUIPMENT,
    variables: {
      body: JSON.stringify({ bId: equipmentDetails?.building?.id })
    },
    disableInitialLoad: true
  })

  const equipmentCharacteristicMetaData = useMemo(
    () =>
      equipmentMetaData?.filter((x) => x.propertyType === 'Characteristic') ||
      [],
    [equipmentMetaData]
  )

  const equipmentPropertyMetaData = useMemo(
    () =>
      equipmentMetaData?.filter((x) => x.propertyType !== 'Characteristic') ||
      [],
    [equipmentMetaData]
  )

  useEffect(() => {
    tab && !isNaN(tab) && setActiveTab(parseInt(tab))
  }, [tab])

  useEffect(() => {
    equipmentType === 'VirtualMeter' && setActiveTab(2)
  }, [equipmentType])

  const dataMappingPropertiesAssignement = async () => {
    try {
      const filteredData = await dataMappingPropertiesFormatter(
        data_Mapping_Properties,
        data_Mapping_Calculated,
        equipmentPropertyMetaData,
        chillerFilteredPropertyMetaData
      )

      const chillerMetaDataCondition =
        equipmentType === EQ_TYPE_CHILLER
          ? chillerFilteredPropertyMetaData?.length > 0
          : true

      if (
        chillerMetaDataCondition &&
        !loadingDataMappingCalculatedProperties &&
        !loadingDataMappingProperties &&
        deviceDetails &&
        !loadingEquipmentMetaData &&
        !lastestValueAPICalled
      ) {
        setDataMappingProperties(filteredData)
        const result = await getLastestValuesOfProperties(
          filteredData,
          deviceDetails,
          equipmentDetails,
          getPropertyLatestValues,
          UOMbyProfile
        )
        setLastestValueAPICalled(true)
        result && setDataMappingProperties(result)
      } else {
        setDataMappingProperties([...filteredData])
      }
    } catch (error) {
      setDataMappingProperties([...filteredData])
    }
  }

  useEffect(() => {
    // Adding time delay, so that we will not miss any props/chars
    setTimeout(() => {
      dataMappingPropertiesAssignement()
    }, 100)
  }, [
    data_Mapping_Properties,
    data_Mapping_Calculated,
    equipmentPropertyMetaData,
    deviceDetails,
    characteristicsListData,
    chillerFilteredPropertyMetaData
  ])

  useEffect(() => {
    if (
      equipmentType === EQ_TYPE_CHILLER &&
      characteristicsListData?.length > 0 &&
      !(chillerFilteredPropertyMetaData?.length > 0)
    )
      (async () => {
        let chillerFilteredPropertyMetaData
        chillerFilteredPropertyMetaData =
          await getChillerFilteredPropertyMetaData(
            characteristicsListData,
            equipmentType
          )
        setChillerFilteredPropertyMetaData(chillerFilteredPropertyMetaData)
      })()
  }, [characteristicsListData, equipmentType])

  useEffect(() => {
    if (equipmentId && equipmentDetails) {
      refetchDataMappingProperties(true)
      refetchCharacteristics(true)
    }
  }, [equipmentId, equipmentDetails])

  useEffect(() => {
    try {
      if (
        !loadingCharacteristicsLoading &&
        !loadingEquipmentMetaData &&
        characteristicsListData &&
        equipmentCharacteristicMetaData?.length > 0
      ) {
        const formattedCharactertiscs = characterticsFormatter(
          characteristicsListData,
          equipmentCharacteristicMetaData,
          chillerFilteredPropertyMetaData
        )
        const charactertiscs = characterticsFormatter(
          characteristicsListData,
          equipmentCharacteristicMetaData,
          []
        )
        setCharacteristicsList(charactertiscs)
        setFormattedCharacteristicsList(formattedCharactertiscs)
      }
    } catch (error) {
      setCharacteristicsList([])
    }
  }, [
    loadingCharacteristicsLoading,
    loadingEquipmentMetaData,
    characteristicsListData,
    equipmentPropertyMetaData,
    chillerFilteredPropertyMetaData
  ])

  const IMG_URL = equipmentDetails?.imageUrl

  const details = useMemo(() => {
    const equipType = equipmentDetails?.type || '--'
    setEquipmentType(equipType)

    equipType !== '--' &&
      refetchEquipmentPropertiesMetaData({
        equipmentType: equipmentDetails?.type
      })

    return {
      equipmentName: equipmentDetails?.name || '--',
      location: equipmentDetails?.building?.name || '--',
      equipmentType: equipmentDetails?.family || '--',
      automatedTestAvailable: 0,
      automatedTestInUse: 0,
      type: equipType
    }
  }, [equipmentDetails])

  useEffect(() => {
    if (equipmentDetails) {
      // sets unit serial number details to state - only Chillers will be used as of now
      const serialNumberInfo =
        equipmentDetails?.equipmentProperties?.items?.[0] || {}
      setSerialNumberDetails(serialNumberInfo)
      equipmentDetails?.deviceId && refetchDevicesByDeviceId()
    }
  }, [equipmentDetails])

  useEffect(() => {
    try {
      if (
        equipmentAssociatedDevices &&
        equipmentAssociatedDevices?.length > 0
      ) {
        const activeDevice = equipmentAssociatedDevices?.find(
          (x) => x?.isActive
        )
        setEquipAssocatedDeviceDetails(activeDevice)
        activeDevice?.id &&
          refetchDeviceDetails({
            id: activeDevice?.id
          })
      }
    } catch (e) {}
  }, [equipmentAssociatedDevices])

  useEffect(() => {
    setData_Mapping_Calculated((data) => [...data, ...dataCalculated])
    setData_Mapping_Properties((data) => [...data, ...dataProperties])
  }, [dataProperties, dataCalculated])


  useEffect(() => {
    setData_Mapping_Source_Properties((data) => [...data, ...dataMappingSourceProperties,...dataMappingSourceCalculated])
  }, [dataMappingSourceProperties,dataMappingSourceCalculated])

  const getDataMappingSourceProperties = async () => {
    if ( !data_Mapping_Source_Properties?.length) {
      const id = equipmentDetails?.dataMappingEquipmentId || equipmentId
       Promise.all([
            refetchDataMappingSourceProperties({
                id
              }),
              refetchDataMappingSOurceCalculated({
                  id
                })
      ])
    }
  }

  // Refetch data mapping after properties edit from ATS page
  const refetchDataMappingProperties = async (forceFetch) => {
    if (forceFetch || dataMappingProperties?.length === 0) {
      setLastestValueAPICalled(false)
      setDataMappingProperties([])
      setData_Mapping_Calculated([])
      setData_Mapping_Properties([])
      Promise.all([
        refetchDataCalculated({ id: equipmentId }),
        refetchDataProperties({ id: equipmentId })
      ])
    }
  }

  const refreshAfterFactorySettings = () => {
    setReloadCharacteriticCurves(true)
    // set to false again, after curves are reloaded.
    setTimeout(() => {
      setReloadCharacteriticCurves(false)
    }, 5000)
    refetchCharacteristics(true)
  }

  // Refetch characteristics after charateristics edit from ATS page
  const refetchCharacteristics = (forceFetch) => {
    if (forceFetch || characteristicsList?.length === 0) {
      refetchCharacteristicsData({
        id: equipmentId,
        propertyType: 'Characteristic'
      })
    }
  }

  const updateSerialNumberCharacteristic = () => {
    // loads chars again to sync serial number
    refetchCharacteristics(true)
  }

  const { refetch: getAutomatedTestResults, data: equipmentAutomatedTests } =
    useQuery({
      query: getAutomatedTestResultsByEquipmentType,
      variables: {
        equipmentType: equipmentType,
        filter: {
          isActive: { eq: 1 }
        }
      },
      dataPath: 'data.getByAnalyticByEquipmentType.items',
      disableInitialLoad: true
    })

  useEffect(() => {
    getAllDimensions()
  }, [])

  useEffect(() => {
    if (
      equipmentType !== '--' &&
      equipmentType !== null &&
      equipmentType !== ''
    )
      getAutomatedTestResults()
  }, [equipmentType])

  const onSelectTab = useCallback((selectedTab) => {
    setActiveTab(selectedTab)
  }, [])

  const getEquipmentAutomatedTestsDetails = useMemo(() => {
    try {
      const handler = AT_STATUS_ENABLED_EQUIPMENTS[equipmentDetails?.type]
      // Currently we are supporting Chiller, AHU only, display the ATs count for other equipments
      if (!handler) {
        if (equipmentAutomatedTests) {
          // Just returns the ATs count from the config
          const tests = AT_STATUS_ENABLED_EQUIPMENTS.Others(
            equipmentAutomatedTests
          )

          details.automatedTestAvailable = tests.totalTestsCount || '--'
          // @ts-ignore
          details.automatedTestInUse = tests.inActiveTestsCount || '--'
        }

        return {
          automatedTestsData: [],
          isDisabled: true,
          testsInUse: 0,
          error: ''
        }
      }

      if (!equipmentAutomatedTests) return
      let tests = {}

      try {
        tests = handler(
          equipmentAutomatedTests,
          characteristicsList,
          dataMappingProperties
        )
      } catch (error) {
        console.log('handling error:', error)
      }

      details.automatedTestAvailable = tests.totalTestsCount || '--'
      // @ts-ignore
      details.automatedTestInUse = tests.inActiveTestsCount || '--'

      return {
        automatedTestsData: tests.automatedTests,
        isDisabled: tests.totalTestsCount === 0,
        testsInUse: tests.inActiveTestsCount
      }
    } catch (error) {
      console.log('error on handler block:', error)
      return {
        automatedTestsData: [],
        isDisabled: true,
        testsInUse: 0,
        error: 'Something went wrong'
      }
    }
  }, [equipmentAutomatedTests, dataMappingProperties, characteristicsList])

  const tabsConfig = [
    {
      title: 'Automated Tests',
      disabled: getEquipmentAutomatedTestsDetails?.isDisabled,
      show: true,
      component: (
        <AutomatedTests
        onEdit = {getDataMappingSourceProperties}
          automatedTestsData={
            getEquipmentAutomatedTestsDetails?.automatedTestsData
          }
          equipmentDetails={equipmentDetails}
          displayNameListProperties={data_Mapping_Source_Properties}
          defaultDimensionGroup={defaultDimensionGroup}
          atsDataMappingProperties={dataMappingProperties}
          isAllTestsMapped={
            details.automatedTestAvailable === details.automatedTestInUse
          }
          refetchDataMappingPropertiesATS={refetchDataMappingProperties}
          refetchCharacteristicsATS={refetchCharacteristics}
          isLoading={
            loadingDataMappingCalculatedProperties ||
            loadingDataMappingProperties ||
            loadingCharacteristicsLoading ||
            loadingEquipmentMetaData
          }
          activeAutomatedTest={activeAutomatedTest}
        />
      )
    },
    {
      title: 'Definitions',
      disabled: false,
      show: true,
      component: (
        <Definitions
          equipment={equipmentDetails}
          refetchEquipmentById={refetchEquipmentById}
          refetchCharacteristics={refetchCharacteristics}
        />
      )
    },
    {
      title: 'Characteristics',
      disabled: false,
      show: true,
      component: (
        <Characteristics
          refetchBuildingTotalMeterEquipment={
            refetchBuildingTotalMeterEquipment
          }
          defaultDimensionGroup={defaultDimensionGroup}
          equipmentDetails={equipmentDetails}
          equipmentCharacteristics={formatedCharacteristicsList}
          refetchCharacteristics={refetchCharacteristics}
          loadingCharacteristics={
            loadingCharacteristicsLoading ||
            loadingDimensions ||
            loadingEquipmentMetaData
          }
        />
      )
    },
    {
      title: 'Data Mapping',
      disabled: false,
      show: true,
      // @ts-ignore
      component: (
        <DataMapping
          equipmentDetails={equipmentDetails}
          displayNameListProperties={data_Mapping_Source_Properties}
          onEdit = {getDataMappingSourceProperties}
          editable={equipmentDetails?.type !== 'VirtualMeter'}
          defaultDimensionGroup={defaultDimensionGroup}
          refetchDataMappingProperties={refetchDataMappingProperties}
          datamapping_properties={dataMappingProperties || []}
          loadingPropertyLatestValues={loadingPropertyLatestValues}
          loadingDataMappingProperties={
            loadingDataMappingCalculatedProperties ||
            loadingDataMappingProperties ||
            loadingDimensions ||
            loadingEquipmentMetaData
          }
          UOMbyProfile={UOMbyProfile}
        />
      )
    },
    {
      title: 'Associated Equipment',
      disabled: false,
      show: true,
      component: (
        <AssociatedEquipmentWrapper
          equipment={equipmentDetails}
          refetchEquipmentById={refetchEquipmentById}
          defaultDimensionGroup={defaultDimensionGroup}
          equipmentCharacteristicMetaData={equipmentCharacteristicMetaData}
          equipmentPropertyMetaData={equipmentPropertyMetaData}
        />
      )
    },
    {
      title: 'Characteristics Curve',
      disabled: false,
      show: equipmentType && equipmentType === EQ_TYPE_CHILLER,
      component: (
        <CharacteristicCurves
          equipment={equipmentDetails}
          reloadCharacteriticCurves={reloadCharacteriticCurves}
        />
      )
    }
  ]

  const isATSloading = !!(
    loadingDataMappingCalculatedProperties ||
    loadingDataMappingProperties ||
    loadingCharacteristicsLoading ||
    loadingEquipmentMetaData
  )

  return (
    <>
      {loadingEquipmentDetails ? (
        <Spinner />
      ) : (
        equipmentDetails && (
          <>
            <HeaderContainer>
              <h1>
                <TranslateComponent>Equipment Setup</TranslateComponent>:{' '}
                {details.equipmentName}
              </h1>
            </HeaderContainer>
            <DetailsWrapper>
              <LeftSection>
                <ImageDiv>
                  <img src={IMG_URL} alt="" width={150} />
                </ImageDiv>
                <DetailTable>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <TranslateComponent>Location</TranslateComponent>:
                        </td>
                        <td>{details.location}</td>
                      </tr>
                      <tr>
                        <td>
                          <TranslateComponent>
                            System/Equipment
                          </TranslateComponent>
                          :
                        </td>
                        <td>{details.equipmentType}</td>
                      </tr>
                      <tr>
                        <td>
                          <TranslateComponent>
                            Automated tests available
                          </TranslateComponent>
                          :
                        </td>
                        <td>
                          {isATSloading ? (
                            <ResizableSpinner />
                          ) : (
                            details.automatedTestAvailable
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TranslateComponent>
                            Automated tests in use
                          </TranslateComponent>
                          :
                        </td>
                        <td>
                          {isATSloading ? (
                            <ResizableSpinner />
                          ) : (
                            details.automatedTestInUse
                          )}
                        </td>
                      </tr>
                      {(equipmentDetails?.type === 'Chiller' ||
                        equipmentDetails?.type === 'AHU') && (
                        <ChillerSerialNumberEdit
                          serialNumberDetails={serialNumberDetails}
                          equipmentDetails={equipmentDetails}
                          refreshAfterFactorySettings={
                            refreshAfterFactorySettings
                          }
                          updateSerialNumberCharacteristic={
                            updateSerialNumberCharacteristic
                          }
                        />
                      )}
                    </tbody>
                  </table>
                </DetailTable>
              </LeftSection>
              <RightSection>
                <MapDownload
                  loadingEquipmentDetails={loadingEquipmentDetails}
                  equipmentDetails={equipmentDetails}
                  equipAssocatedDeviceDetails={equipAssocatedDeviceDetails}
                />
              </RightSection>
            </DetailsWrapper>
            <TabsWrapper>
              <Tabs
                defaultActiveTab={activeTab}
                activeTab={activeTab}
                onSelect={onSelectTab}
              >
                {tabsConfig.map((item, key) => {
                  if (item.show) {
                    return (
                      <Tab
                        testName={DETAILS_TEXT_NAME_PREFIX(item.title)}
                        title={item.title}
                        disabled={item.disabled}
                        key={key}
                      >
                        {item.component}
                      </Tab>
                    )
                  } else {
                    return <></>
                  }
                })}
                {/*
                //TODO: Need to cover this below scenario in future scope
                tisObjectTypeGroupName === CHILLER_TIS_OBJECT_TYPE_GROUP_NAME && isAnaTechnician() ? 
                <Tab title="Characteristics Curves">
                    <div>Characteristics Curve</div>
                </Tab>
                ) : null } */}
              </Tabs>
            </TabsWrapper>
          </>
        )
      )}
    </>
  )
}

const loadFactorySettings = /* GraphQL */ `
  query loadFactoryCharacteristics($body: AWSJSON!) {
    loadFactoryCharacteristics(body: $body)
  }
`

const ChillerSerialNumberEdit = ({
  serialNumberDetails,
  equipmentDetails,
  refreshAfterFactorySettings,
  updateSerialNumberCharacteristic
}) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [serialNumberDB, setSerialNumberDB] = useState()
  const [modalType, setModalType] = useState('LoadFactory')
  const [modifiedSerialNumber, setModifiedSerialNumber] = useState()
  const [loadErrorMessage, setLoadErrorMessage] = useState('')
  const equipmentType = equipmentDetails?.type

  const user = useSelector(selectUserInfo)

  useEffect(() => {
    setSerialNumberDB(serialNumberDetails?.value)
    setModifiedSerialNumber(serialNumberDetails?.value)
  }, [serialNumberDetails])

  const { onSubmit: updateEquipmentProperty } = useMutation({
    query: UPDATE_EQUIPMENT_PROPERTY,
    dataPath: 'data.updateEquipmentProperty'
  })

  const { refetch: loadFactorySettingsQuery, loading: loadingFactorySettings } =
    useQuery({
      query: loadFactorySettings,
      disableInitialLoad: true,
      dataPath: 'data.loadFactoryCharacteristics'
    })

  const [showLoadCharacteristicsModal, setShowLoadCharacteristicsModal] =
    useState(false)

  const toggleLoadCharacteriticsModal = () => {
    setShowLoadCharacteristicsModal(!showLoadCharacteristicsModal)
    setLoadErrorMessage('')
    setModalType('LoadFactory')
  }

  const saveSerialNumber = async () => {
    try {
      // updates the serial number
      await updateEquipmentProperty({
        input: {
          id: serialNumberDetails?.id,
          value: modifiedSerialNumber,
          creationType: 'User'
        }
      })
      setSerialNumberDB(modifiedSerialNumber)
      updateSerialNumberCharacteristic(modifiedSerialNumber)
    } catch (e) {
    } finally {
      setIsEditMode(false)
    }
  }

  const getUpdatedByName = () => {
    const firstName =
      user.firstName?.charAt(0)?.toUpperCase() + user.firstName?.slice(1) || ''
    const lastName =
      user.lastName?.charAt(0)?.toUpperCase() + user.lastName?.slice(1) || ''
    return `${firstName} ${lastName}`
  }

  const saveLoadFactorySettings = async () => {
    try {
      const buildingId = equipmentDetails?.building?.id
      const equipmentId = equipmentDetails?.id
      if (buildingId && equipmentId) {
        const input = {
          bId: buildingId,
          eId: equipmentId,
          serialNo: serialNumberDB,
          updatedBy: getUpdatedByName()
        }

        const body = JSON.stringify(input)

        const loadFactoryResponse = await loadFactorySettingsQuery({
          body
        })

        const parsedResponse = JSON.parse(loadFactoryResponse)

        if (parsedResponse?.factoryChars?.error) {
          if (parsedResponse?.factoryChars?.error?.includes('Enum')) {
            setLoadErrorMessage(parsedResponse?.factoryChars?.error)
          }
          setModalType('LoadFactoryError')
        } else {
          refreshAfterFactorySettings()
          toggleLoadCharacteriticsModal()
        }
      }
    } catch (error) {
      setModalType('LoadFactoryError')
    }
  }

  const factoryModalConfig = {
    gray: true,
    isHideWhiteBackground: true,
    heading:
      modalType === 'LoadFactoryError'
        ? 'Unable to Load Factory Characteristics'
        : 'Confirm Load Factory Characteritics',
    handleClose: toggleLoadCharacteriticsModal,
    buttons: [
      ...(modalType === 'LoadFactory'
        ? [
            {
              text: 'Yes, Load Factory Characteristics',
              handleClick: () => {
                saveLoadFactorySettings()
              },
              type: 'save',
              disabled: loadingFactorySettings
            }
          ]
        : []),
      {
        text: 'Cancel',
        handleClick: toggleLoadCharacteriticsModal,
        type: 'cancel'
      }
    ]
  }

  return (
    <>
      <tr>
        <td>
          <TranslateComponent>Serial Number</TranslateComponent>
        </td>
        {isEditMode ? (
          <td>
            <div>
              <SerialNumberInput
                onChange={(evt) => {
                  setModifiedSerialNumber(evt?.target?.value || '')
                }}
                value={modifiedSerialNumber}
              />
              <SerialNumberActionButton
                onClick={() => setIsEditMode(false)}
                cancel={true}
              >
                Cancel
              </SerialNumberActionButton>
              <SerialNumberActionButton
                disabled={modifiedSerialNumber === serialNumberDB}
                onClick={saveSerialNumber}
              >
                Save
              </SerialNumberActionButton>
            </div>
          </td>
        ) : (
          <td>
            {serialNumberDB || '--'}{' '}
            <IconSVG
              style={{ marginBottom: -2 }}
              onClick={() => {
                setModifiedSerialNumber(serialNumberDB)
                setIsEditMode(true)
              }}
              name="edit"
              color={colors.TBA_DARKER_GREY}
              width="15px"
              height="15px"
            />
          </td>
        )}
      </tr>
      {serialNumberDB && !isEditMode && equipmentType === 'Chiller' && (
        <tr>
          <td></td>
          <td>
            <LoadFactoryButton onClick={toggleLoadCharacteriticsModal}>
              <TranslateComponent>
                Load Factory Characteristics
              </TranslateComponent>
            </LoadFactoryButton>
            <NoteText style={{}}>
              <TranslateComponent>
                This feature has only been validated for CenTraVac® chillers.
              </TranslateComponent>
            </NoteText>
          </td>
        </tr>
      )}
      {showLoadCharacteristicsModal && (
        <Modal testName="load-factory-modal" {...factoryModalConfig}>
          {loadingFactorySettings ? (
            <Spinner />
          ) : modalType === 'LoadFactoryError' ? (
            <strong>
              <p>
                {loadErrorMessage ||
                  `Factory Characteristics could not be populated based on the
                chiller serial number provided.`}
              </p>
            </strong>
          ) : (
            <div>
              <strong>
                <p>
                  Factory Characterisitics will override the existing settings
                  in both the Characteristics and Characteristic Curves tabs.
                </p>
              </strong>
              <p style={{ marginTop: 20 }}>
                Are you sure you want to load and save these settings?
              </p>
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export default React.memo(EquipmentDetails)
