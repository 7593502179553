import { Loader, Theme, ThemeProvider } from "@aws-amplify/ui-react"

import styles from './loader-overlay.module.scss'

const loaderTheme: Theme = {
  name: 'loader-theme',
  tokens: {
    components: {
      loader: {
        strokeFilled: { value: '{colors.neutral.80}' },
        large: {
          width: { value: '{fontSizes.xxxxl}' },
          height: { value: '{fontSizes.xxxxl}' },
        }
      }
    }
  }
}

export const LoaderOverlay = ({ isLoading }: { isLoading: boolean }) => {
  return <div className={`${styles.loaderOverlay} ${isLoading ? styles.visible : ''}`}>
    <ThemeProvider theme={loaderTheme}>
      <Loader size='large' />
    </ThemeProvider>
  </div>
}