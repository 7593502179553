import { useState, useCallback } from 'react'
import { Content } from 'src/components/layouts'
import Header from '../../../components/legacy/components/header/header'
import { useTranslation } from 'react-i18next'
import { TEXT_EDITOR_TOOLBAR_OPTIONS } from 'src/components/legacy/common/opportunity.js'
import Label from 'src/components/legacy/components/label/label'
import Select from 'src/components/legacy/components/select/select'
import { Form, Field } from 'formik'
import {
  hasEmptyProperties,
  getField
} from 'src/components/legacy/common/helpers.js'
import { PageTitleInput } from 'src/components/PageTitleInput/PageTitleInput'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { InlineEditFormWrapper } from '../../opportunities/styles'
import COLORS from 'src/components/legacy/common/colors.json'
import { Container } from 'src/components/layouts'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import TextEditor from 'src/components/text-editor'
import TargetPathEdit from 'src/components/target-path/TargetPathEdit'

import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import {
  formatFindingIssueFound,
  findingIssueFoundOptionList,
  statusOptionList,
  nextStepOptionList
} from 'src/components/legacy/common/finding'
import moment from 'moment'
import Priority from 'src/components/Priority/Priority'
import StatusEdit from 'src/components/Priority/StatusEdit/StatusEdit'
import AssignedTo from 'src/components/AssignedTo'
import KPIEdit from 'src/components/legacy/components/kpi/kpi-edit'
import { KPIEditWrapper } from '../styles'
import { useNavigate, useParams } from 'react-router-dom'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import FileUploader from 'src/components/file-upload/file-upload'
import ImageBlockList from 'src/components/legacy/components/images/image-block-list/image-block-list'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import _omit from 'lodash/omit'
import { ACTIONS } from 'src/constants'
import { deriveTestName } from '../helpers'
import translate, { TranslateComponent } from 'src/common/translations'
import { accessControlFunc } from 'src/components/accessControl'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'


const MAX_LENGTH_OF_PROPOSED = 200
const MAX_PAGE_WIDTH = 1220

const FindingEditForm = (props) => {
  const [t] = useTranslation()
  const { width } = useBreakPoint()
  const hasCRUDPermissions = true
  const navigate = useNavigate()
  const { id } = useParams();
  const userAccess = useSelector(selectUserAccess)
  const [, setRemoveAllOpportunities] = useState(false)
  const [dialogTypes, setDialogTypes] = useState({
    confirm: null,
    error: null
  })

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    isSubmitting,
    handleSubmit,
    handleReset,
    error,
    setError,
    settingsLoaded,
    mode,
    setValues
  } = props

  const pageTitle = (
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange
  ) => {
    return (
      <div style={{ display: 'flex' }}>
        <PageTitleInput
          value={values.name}
          error={errors.name && touched.name ? errors.name : ''}
          name="name"
          label={t('common:Title')}
          onChange={(...args) => {
            setFieldTouched('name')
            handleChange(...args)
          }}
        />
        {hasCRUDPermissions &&
          accessControlFunc({
            id: 'tc.pages.findings.show-public-toggle',
            userAccess
          }) && (
            <div className="toggle-button">
              <label>{`${t('common:Visible')}:`}</label>
              <ToggleButtonHorizontal
                name="visibleToCustomer"
                checked={values.visibleToCustomer}
                onChange={() =>
                  setFieldValue('visibleToCustomer', !values.visibleToCustomer)
                }
              />
            </div>
          )}
      </div>
    )
  }

  const toolbar = () => (
    <div className="toolbar">
      <div className="toolbar-buttons">
        <button
          className={`primary ${isSubmitting ? 'wait' : ''}`}
          type="submit"
          onClick={handleSubmit}
          disabled={!hasEmptyProperties(errors) || (errors.name && touched.name) ? true : false}
        >
          {isSubmitting ? <div className="spinner" /> : optionsTranslated.save}
        </button>

        <button
          className="secondary"
          disabled={isSubmitting}
          type="reset"
          onClick={() => {
            handleReset()          
            if(id){
              navigate(`/${PAGE_NAMES.FINDINGS}/${id}`, {
                state: { mode: ACTIONS.VIEW }
              })
            }
            else{
              navigate(`/${PAGE_NAMES.FINDINGS}`)
            }           
            setFieldValue('errors', {})
          }}
        >
          {optionsTranslated.cancel}
        </button>
      </div>
    </div>
  )

  const handleTargetChanges = useCallback(
    (setFieldValue, values) => (changes) =>
      onTargetChange(changes, setFieldValue, values),
    []
  )

  const onTargetChange = (changes, setFieldValue, values) => {
    if (
      changes['organization.organizationId'] &&
      values.opportunities &&
      values.opportunities.length
    ) {
      setDialogTypes({
        ...dialogTypes,
        confirm: {
          title: t('opportunities:RemoveOpportunitiesTitle'),
          text: t('opportunities:RemoveOpportunitiesConfirmText'),
          confirmText: t('common:Yes'),
          cancelText: t('common:No'),
          handleConfirm: () => {
            setFieldValue('opportunities', [])
            setDialogTypes({ ...dialogTypes, confirm: null })
            setRemoveAllOpportunities(true)
            Object.entries(changes).forEach(([key, value]) => {
              setFieldValue(key, value)
            })
          }
        }
      })
    } else {
      Object.entries(changes).forEach(([key, value]) => {
        setFieldValue(key, value)
      })
      setFieldValue('assignedTo', {})
    }
  }

  const onChangeDate = (path) => (date) => {
    date = date.target ? date.target.value : moment(date).format(DATE_FORMAT)
    setFieldValue(path, date)
  }

  const onUpdateFields = (fields) => (values) => {
    setFieldValue(fields, values)
  }

  const onCloseDialog = () => () => {
    setError(null)
  }

  const onUpdateFileField = (fields) => (values) => {
    setFieldValue(fields, _omit(values, 'fileFullName'))
  }

  const optionsTranslated = {
    target: translate('Target Date'),
    createdBy: translate("Created By"),
    createdDate: translate("Created Date"),
    foundDuring: translate("Found During"),
    attach: translate("Attach File"),
    fileDesc: translate("File Description"),
    save: translate("Save"),
    cancel: translate("Cancel"),
  }

  return (
    <InlineEditFormWrapper>
      <Form className="inline-edit-form" data-testid={deriveTestName()+ '_' + mode + "_Form"}>
        <Container
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
          width={width}
        >
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
            border="none"
          >
            <Header
              pageTitle={pageTitle(
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleChange
              )}
            >{toolbar()}</Header>
          </Content>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
            background={COLORS.WHITE}
          >
            <Container
              xlColumns={8}
              lColumns={12}
              mColumns={2}
              sColumns={1}
              width={width}
              className="inline-edit-header"
            >
              <Content xlColumn={5} lColumn={9} width={width} border="none">
                <TargetPathEdit
                  location={values.location}
                  organization={values.organization}
                  tisObjects={values.tisObjects || []}
                  onChange={(e, b) => {
                    setFieldValue(e, b)
                  }}
                  setValues={setValues}
                  values={values}
                  errors={errors}
                ></TargetPathEdit>
              </Content>
              <Content
                xlColumn={3}
                lColumn={3}
                width={width}
                className="proposal-info"
                border="none"
              >
                <div data-testid="proposedBy">
                  <label htmlFor="settings.proposedBy">
                    {optionsTranslated.createdBy}:
                  </label>
                  <input
                    type="text"
                    value={values.settings.proposedBy}
                    name="settings.proposedBy"
                    onChange={handleChange}
                    maxLength={MAX_LENGTH_OF_PROPOSED}
                  />
                </div>
                <div
                  data-testid="proposalDate"
                  className={
                    getField(errors, 'settings.proposalDate')
                      ? 'proposal-date error'
                      : 'proposal-date'
                  }
                >
                  <ModalDatePickerInput
                    date={
                      values.settings.proposalDate
                        ? values.settings.proposalDate
                        : ''
                    }
                    onChange={onChangeDate('settings.proposalDate')}
                    isRequired={false}
                    labelText={`${optionsTranslated.createdDate}:`}
                    hasError={Boolean(
                      getField(errors, 'settings.proposalDate')
                    )}
                    errorMessage={getField(errors, 'settings.proposalDate')}
                  />
                </div>
                <div className='found-during' data-testid="foundDuring">
                  <Label text={`${optionsTranslated.foundDuring}*:`} />
                  <Select
                    selectedItem={formatFindingIssueFound(
                      values.foundDuring,
                      t
                    )}
                    onChange={(value) => setFieldValue('foundDuring', value)}
                    options={findingIssueFoundOptionList(t)}
                  />
                </div>
              </Content>
            </Container>
          </Content>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
            background={COLORS.WHITE}
          >
            <KPIEditWrapper data-testid="KPI_Edit_Wrapper">
              <KPIEdit
                kpi={values.settings.kpi}
                onChange={onUpdateFields('settings.kpi')}
                errors={getField(errors, 'settings.kpi')}
                showSpecificValue={true}
                containerWidth={width}
              />
            </KPIEditWrapper>
          </Content>
          {settingsLoaded && <Content
            className="description"
            xlColumn={6}
            lColumn={6}
            mColumn={12}
            sColumn={12}
            width={width}
            background={COLORS.WHITE}
          >
            <TextEditor
              content={values.settings.description || ''}
              textEditorToolbarOptions={TEXT_EDITOR_TOOLBAR_OPTIONS}
              changeContent={onUpdateFields('settings.description')}
            />
            <fieldset className="chart-link" data-testid="chartLink">
              <label htmlFor="settings.chartLink">
                {<TranslateComponent>Chart Link</TranslateComponent>}:
              </label>
              <Field
                type="text"
                id="settings.chartLink"
                name="settings.chartLink"
                maxLength="2048"
              />
              <p className="tip">{<TranslateComponent>Copy the site address from the address bar of your browser.</TranslateComponent>}</p>
            </fieldset>
            <fieldset className="chart-link" data-testid="descriptionLink">
              <label htmlFor="settings.descriptionLink">
                {<TranslateComponent>Link Description</TranslateComponent>}:
              </label>
              <Field
                type="text"
                id="settings.descriptionLink"
                name="settings.descriptionLink"
                maxLength="256"
              />
            </fieldset>
          </Content>}
          <Content
            xlColumn={6}
            lColumn={6}
            mColumn={12}
            sColumn={12}
            width={width}
            border="none"
          >
            <ImageBlockList
              images={values.settings.images}
              maxSize={6}
              onUpdate={(fields) => {
                setFieldValue('settings.images', fields)
              }}
              showAddFindingButton={false}
              annotationsEnabled={true}
              organizationId={getField(values, 'organization.organizationId')}
              locationId={getField(values, 'location.locationId')}
              testName={deriveTestName()}
            />
          </Content>

          <Content
            className="opportunity-footer block"
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
          >
            <Container
              className="workflow-edit"
              xlColumns={12}
              lColumns={12}
              mColumns={12}
              sColumns={12}
              width={width}
            >
              <Content
                xlColumn={3}
                lColumn={5}
                mColumn={3}
                sColumn={12}
                width={width}
                border="none"
              >
                <Priority
                  priority={values.priority}
                  onChange={onUpdateFields('priority')}
                />
              </Content>
              <Content
                xlColumn={5}
                lColumn={7}
                mColumn={12}
                sColumn={12}
                width={width}
                border="none"
              >
                <FileUploader
                  location={values.location && values.location.locationId}
                  file={values.settings.file}
                  saveFullName={true}
                  onFileChange={onUpdateFileField('settings.file')}
                  componentName="finding-detail-edit-page"
                  titleText={`${optionsTranslated.attach}:`}
                  descriptionText={`${optionsTranslated.fileDesc}:`}
                />
              </Content>
              <Content
                className="bottom-options"
                xlColumn={4}
                lColumn={6}
                mColumn={12}
                sColumn={12}
                width={width}
                border="none"
              >
                <StatusEdit
                  status={t(`components:findingStatuses>${values.status}`)}
                  nextStep={t(`components:nextStepsFinding>${values.nextStep}`)}
                  onChangeStatus={onUpdateFields('status')}
                  onChangeNextStep={onUpdateFields('nextStep')}
                  statuses={statusOptionList(t)}
                  nextSteps={nextStepOptionList(t)}
                />
                <AssignedTo
                  defaultValue={values.assignedTo?.key}
                  onChange={({key}) => 
                    setFieldValue('assignedTo', {key})
                  }
                  locationId={
                    (values.location && values.location.locationId) ||
                    values.locationId
                  }
                  setFieldValue={setFieldValue}
                  page="finding"
                />

                <div>
                  <div className="target-date" data-testid="target-date">
                    <ModalDatePickerInput
                      date={
                        values.settings.targetDate
                          ? values.settings.targetDate
                          : ''
                      }
                      onChange={onChangeDate('settings.targetDate')}
                      isRequired={false}
                      labelText={`${optionsTranslated.target}:`}
                      hasError={Boolean(
                        getField(errors, 'settings.targetDate')
                      )}
                      errorMessage={getField(errors, 'settings.targetDate')}
                    />
                  </div>
                </div>
              </Content>
            </Container>
          </Content>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
            border="none"
          >
            {toolbar()}
          </Content>
        </Container>
        <StandardDialogs error={error} onCloseDialog={onCloseDialog} confirm={dialogTypes.confirm} />
      </Form>
    </InlineEditFormWrapper>
  )
}

export default FindingEditForm
