import IconSVG from 'src/components/Icon/index.js'
import colors from 'src/components/layouts/colors.json'
import { TranslateComponent } from 'src/common/translations'

export const CalculatedPointsList = ({
  pointsList,
  handleCalculatedPointEdit
}) => {
  return (
    <div className="calculated-points-list">
      <div className="calculated-points-header">
      <TranslateComponent>Select/Create Calculated points</TranslateComponent>:
      </div>
      <div className="points-list-container" >
        {pointsList.map((x) => (
          <div className="points-list-item" key={x?.id}>
            <span>{x.name}</span>
            <IconSVG
              key={Math.random()}
              onClick={() => handleCalculatedPointEdit(x?.id)}
              name="edit"
              color={colors.TBA_DARKER_GREY}
              width="12px"
              height="12px"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
