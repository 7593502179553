import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TranslateComponent } from 'src/common/translations'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Header from 'src/components/pageHeader/header'
import ErrorDialog from 'src/components/legacy/components/error-dialog/error-dialog.jsx'
import Offerings from './offerings'
import { getOfferingName } from 'src/pages/contracts-and-offerings/common/contracts-and-offerings'
import {
  LIST_CONTRACTS_BY_ACCOUNT,
  LIST_OFFERINGS_BY_ACCOUNT,
  GET_BUILDING_BY_ID_func
} from './queries'
import { API } from 'aws-amplify'

import './styles.scss'
import { useQuery } from '../../hooks/APIHooks'
import { getSearchParams } from '../../common/helperFunctions'
import moment from 'moment'
import {
  utcTimestampToDateFormat
} from 'src/components/legacy/common/time-helpers'
import{useConfig} from "src/pages/contracts-and-offerings/common/config"

export const ContractsAndOfferingPage = () => {
  const [t] = useTranslation()
  const { buildingId, organizationId } = getSearchParams()
  const [offerings, setOfferings] = useState([])
  const [loadTime, setLoadTime] = useState(0)
  const [startTime, setStartTime] = useState(0)
  const [contracts, setContracts] = useState([])
  const [offeringsByAccount, setOfferingsByAccount] = useState([])
  const [contractsNextToken, setContractsNextToken] = useState()
  const [offeringsNextToken, setOfferingsNextToken] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const {contracts:contractsConfig} = useConfig()
  const {
    refetch: refetchContractsByAccount,
    // error,
    loading: loading
  } = useQuery({
    query: LIST_CONTRACTS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listContractByAccount'
  })

  const {
    refetch: refetchBuildingDetails
  } = useQuery({
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  const {
    refetch: refetchOfferingsByAccount,
    // error,
    loading: loading1
  } = useQuery({
    query: LIST_OFFERINGS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listOfferingsByAccount'
  })

  const getContracts = async ({ input, nextToken }) => {
    const data = await refetchContractsByAccount({ ...input, nextToken })
    if (Array.isArray(data?.items)) {
      if (data.nextToken !== null) {
        setContracts((contracts) => [...contracts, ...data.items])
        setContractsNextToken(data.nextToken)
        getContracts({ input, nextToken: data.nextToken })
      } else {
        setContracts((contracts) => [...contracts, ...data.items])
        setContractsNextToken(data.nextToken)
      }
    }
  }

  const getOfferingsByAccount = async ({ input, nextToken }) => {
    setIsLoading(true)
    try{
    const data = await refetchOfferingsByAccount({ ...input, nextToken })
    setIsLoading(false)
    if (Array.isArray(data?.items)) {
      if (data.nextToken !== null) {
        setOfferingsByAccount((offerings) => [...offerings, ...data.items])
        setOfferingsNextToken(data.nextToken)
        getOfferingsByAccount({ input, nextToken: data.nextToken })
      } else {
        setOfferingsByAccount((offerings) => [...offerings, ...data.items])
        setOfferingsNextToken(data.nextToken)
      }
    }
  } catch (error) {
    console.error(error)
    setIsLoading(false)
  }
  }

  const dataLoading= (loading || loading1 || isLoading)

  useEffect(() => {
    if (organizationId) {
      setContracts([])
      setOfferings([])
	  setOfferingsByAccount([])
	  setOfferingsNextToken()
	  setContractsNextToken()
	  setLoadTime(0)
      setStartTime(new Date())
      Promise.all([
        getOfferingsByAccount({
          input: { id: organizationId },
          nextToken: offeringsNextToken
        }),
        getContracts({
          input: { id: organizationId },
          nextToken: contractsNextToken
        })
      ])
    }
  }, [organizationId])

  useEffect(() => {
    (async () => {
      if(contractsConfig?.length > 0){
      if (offeringsByAccount?.length > 0) {
		const offeringsWithContracts = offeringsByAccount.filter(
            (o) => o?.contractId
          )
          const offeringsWithoutContracts = offeringsByAccount.filter(
            (o) => o && !o.contractId
          )
        if (contracts?.length > 0) {
          setOfferingsWithContracts(offeringsWithContracts)
          setOfferingsWithoutContracts(offeringsWithoutContracts)
        }else if ((!contracts || contracts?.length === 0) && contractsNextToken === null) {
			setOfferingsWithoutContracts(offeringsWithoutContracts)
		}
      } else if(!offeringsByAccount?.length && offeringsNextToken === null){
		setOfferings([])
	}
}
    })()
  }, [offeringsByAccount,contracts,contractsConfig])

  const setOfferingsWithContracts = async (offeringsWithContracts) => {
    const offeringsLists = Object.values(
      offeringsWithContracts.reduce((acc, offering) => {
        if (acc[offering.contractId]) {
          acc[offering.contractId].push(offering)
        } else {
          acc[offering.contractId] = [offering]
        }
        return acc
      }, {})
    )
    const groupOfferingsByContract = offeringsLists.map((offeringList) => {
      if (offeringList?.length > 0) {
        const contract = contracts.find(
          (contrct) => contrct.id === offeringList[0].contractId
        )
        const contractClone = {...contract}
        delete contractClone?.id
        const offering = offeringList.reduce((acc, offering) => {
          acc = {
            ...acc,
            ...offering,
            ...contractClone,
            status: contractClone.status,
            name: getOfferingName(contractsConfig,offering.subscriptionCode) || offering.subscriptionCode 
          }
          acc['buildingIds'] = Array.from(
            new Set([...(acc.buildingIds || []), offering.buildingId])
          )
          //names and offeringCodes are subscriptionCodes
          acc['names'] = Array.from(
            new Set([
              ...(acc.names || []),
              getOfferingName(contractsConfig,offering.subscriptionCode) || offering.subscriptionCode 
            ])
          )
          acc['offeringCodes'] = Array.from(
            new Set([...(acc.offeringCodes || []), offering.subscriptionCode])
          )
          acc['offeringIds'] = [...(acc.offeringIds || []), offering.id]
          delete acc.buildingId
          delete acc.buildingName
          delete acc.name
          delete acc.id
          return acc
        }, {})
        return { ...offering, originalList: offeringList }
      }
    })
    const uniqueBuildingIds = groupOfferingsByContract.reduce(
      (acc, offering) => {
        if (offering) {
          acc = [
            ...acc,
            ...offering.buildingIds.filter(
              (id) => !acc.some((accId) => accId === id)
            )
          ]
          return acc
        }
        return acc
      },
      []
    )
    if (uniqueBuildingIds?.length > 0) {
      const res = await refetchBuildingDetails(null,null,GET_BUILDING_BY_ID_func(uniqueBuildingIds))
        const endTime = new Date()
        const timeDiff = (endTime - startTime) / 1000
        setLoadTime(timeDiff)
        const buildingsByOfferings = Object.values(res.data)
        setOfferings(offerings => [
          ...offerings,
          ...groupOfferingsByContract.map((offering) => {
            const buildings = buildingsByOfferings.filter((building) =>
              offering.buildingIds.includes(building?.id)
            )
            const buildingName = Array.from(
              new Set(buildings.map((building) => building?.name))
            )?.join(',')
            const accountName = Array.from(
              new Set(buildings.map((building) => building?.accountName))
            )?.join(',')
            return {
              ...offering,
              startDate: utcTimestampToDateFormat(offering.startDate),
          endDate: utcTimestampToDateFormat(offering.endDate),
          analyticsStartDate: utcTimestampToDateFormat(offering.analyticsStartDate),
              buildingName,
              buildings,
              accountName,
              name: offering.names.join(',')
            }
          })
        ])
    }
  }

  const setOfferingsWithoutContracts = async (offeringsWithoutContracts) => {
    setIsLoading(true)
    if (offeringsWithoutContracts?.length > 0) {
      const uniqueBuildingIds = Array.from(
        new Set(offeringsWithoutContracts.map(({ buildingId }) => buildingId))
      )
      const res = await refetchBuildingDetails(null,null,GET_BUILDING_BY_ID_func(uniqueBuildingIds))
        const endTime = new Date()
        const timeDiff = (endTime - startTime) / 1000
        setLoadTime(timeDiff)
        const buildingsByOfferings = Object.values(res.data)
        setOfferings(offerings => [
          ...offerings,
          ...offeringsWithoutContracts.map((offering) => {
            const building = buildingsByOfferings.find(
              (building) => building?.id === offering.buildingId
            )
            const buildingName = building?.name
            const accountName = building?.accountName
            return { ...offering,
              startDate: utcTimestampToDateFormat(offering.startDate),
              endDate: utcTimestampToDateFormat(offering.endDate),
              analyticsStartDate: utcTimestampToDateFormat(offering.analyticsStartDate),
              name : (getOfferingName(contractsConfig,offering.subscriptionCode) || offering.subscriptionCode),buildings:[building],
               buildingName,
                accountName }
          })
        ])
        setIsLoading(false)
    }else if(!offeringsWithoutContracts?.length && offeringsNextToken === null){
		setOfferings([])
	}
  }

  const workfrontUrl = 'https://traneus.my.workfront.com/login?nextURL=%2Fhome'

  const [error, setError] = useState(null)

  return (
    <section className="contracts-and-offerings-page">
      <Header
        hideToolBar={true}
        pageTitle={t('contracts-and-offerings:contractsAndOfferings')}
      />
      <p className="information">
        <TranslateComponent>
          Digital Service contracts including Connected Analytics Package (CAP)
          offering, Active Monitoring, and Digital Inspections can be requested
          using the Requested/Active button above. For Ensemble, Tenant
          Services, and Work Order Management please go to{' '}
          <a href={workfrontUrl} target="_blank" rel="noreferrer">
            Workfront
          </a>{' '}
          to activate contract. Utility Bill Management and standalone Coaching
          Services should be ordered using the Digital Service Order From found
          on <a href="https://hub.tranetechnologies.com/community/services/intelligent-services" target="_blank" rel="noreferrer">The Hub</a>.
        </TranslateComponent>
      </p>
      
      {offerings ? (
        <Offerings
          loadTime={loadTime}
          offerings={offerings}
          buildingId={buildingId}
          accountId={organizationId}
          loading= {dataLoading}
        />
      ) : (
        <Spinner />
      )}
      {error && <ErrorDialog text={error} handleClose={() => setError(null)} />}
    </section>
  )
}

export default ContractsAndOfferingPage
