import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const ChatHistoryContainer = styled.section`
  border-radius: 3px;
  border: 1px solid ${colors.TBA_DARK_GREY};
  display: flex;
  flex-direction: column;
  max-height: ${(props) => props.maxHeight};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 6px 14px;
`
export const MessageContainer = styled.div`
  align-self: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: ${(props) =>
    props.alignRight ? '8px 0 8px 36px' : '8px 36px 8px 0'};
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
`
export const EmptyMessageContainer = styled.div`
  color: ${colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 0px;
`
export const CreatedBy = styled.div`
  color: ${colors.BLUE_4};
  font-family: Lato;
  font-size: 12px;
  font-weight: 800;
  line-height: 12px;
  padding-bottom: 3px;
  text-transform: uppercase;
`
export const Message = styled.div`
  align-self: inherit;
  background: ${colors.BLUE_1};
  border-radius: 10px;
  border: 1px solid ${colors.BLUE_2};
  display: inline-block;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  padding: 12px 15px;
  text-align: left;
  width: auto;
`
export const SubMessage = styled.div`
  color: ${colors.BLACK};
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding-top: 3px;
  text-transform: ${props=>props.isCapital? "capitalize": "none"}
`

const ChatHistory = (props) => {
  const { maxHeight, messages, emptyMessage = '', isCapital=false } = props
  return (
    <ChatHistoryContainer maxHeight={maxHeight}>
      {!messages.length && emptyMessage ? (
        <EmptyMessageContainer className="empty-message">
          {emptyMessage}
        </EmptyMessageContainer>
      ) : (
        messages.map(
          ({ messageKey, createdBy, message, subMessage, isOwner }) => (
            <MessageContainer alignRight={isOwner} key={messageKey}>
              <CreatedBy>{createdBy}</CreatedBy>
              {message && <Message>{message}</Message>}
              <SubMessage isCapital={isCapital}>{subMessage}</SubMessage>
            </MessageContainer>
          )
        )
      )}
    </ChatHistoryContainer>
  )
}

ChatHistory.propTypes = {
  /** The max height of the chart history container */
  maxHeight: PropTypes.string,

  /** The message for the empty history */
  emptyMessage: PropTypes.string,

  /** The list of notes to be shown */
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      /** The message key */
      messageKey: PropTypes.string.isRequired,

      /** Сreated By */
      createdBy: PropTypes.string,

      /** The message text */
      message: PropTypes.string,

      /** The sub-message text */
      subMessage: PropTypes.string,

      /** Whether the message belongs to current user */
      isOwner: PropTypes.bool
    })
  )
}

ChatHistory.defaultProps = {
  maxHeight: '225px',
  messages: []
}

export default ChatHistory
