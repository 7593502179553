import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import translate from 'src/common/translations'
import IssuesFindingsList from 'src/pages/issuesFindings/IssuesFindingsList'
import FindingsListButtons from 'src/pages/issuesFindings/findings-list-buttons'

// Denali components
import {
  faRadar
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'

// Local imports
import { IssuesAndFindingsContainerProps } from './types.d'

export const IssuesAndFindingsContainer = ({ 
  uiMode, 
  handleGetFindingRecords, 
  loading, 
  locationName, 
  organizationName, 
  findings 
} : IssuesAndFindingsContainerProps) => {
  return (
    <Page
      title={translate('Issues/Findings')}
      icon={<FontAwesomeIcon icon={faRadar} />}
      iconColor={IconColor.blue}
      testid="issues/findings page"
      actions={
        <FindingsListButtons
          loading={loading}
          locationName={locationName}
          organizationName={organizationName}
          allOrganizations={false}
          findings={findings}
          uiMode={uiMode} 
        />
      }
    >
      <IssuesFindingsList 
        uiMode={uiMode} 
        handleGetFindingRecords={handleGetFindingRecords} 
      />
    </Page>
  )
}
