import { TranslateComponent } from 'src/common/translations'
import dropZoneStyles from 'src/denali-components/DropZone/drop-zone.module.scss'
import { Input } from '@aws-amplify/ui-react'


export const FileDropZone = ({
  DropArea,
  customUploadContent,
  showOnlyAcceptableFileDesc,
  allowedFileTypes,
  allowMultipleFiles,
  fileSelect
}) => {
  return (
    <DropArea className={dropZoneStyles.dropZone} htmlFor="fileElem" ref={fileSelect.dropAreaRef} data-testid="files-select">
      {customUploadContent ? (
        customUploadContent()
      ) : (
        <>
          <span className={dropZoneStyles.dropZoneText}>
            <TranslateComponent> Drop file here, or </TranslateComponent> <span className={dropZoneStyles.clickHere}><TranslateComponent> choose file </TranslateComponent></span>
          </span>
          {showOnlyAcceptableFileDesc ? <p>
            <TranslateComponent>Acceptable format: </TranslateComponent>
            {` .${allowedFileTypes.join(', .')}`}
            <TranslateComponent> (Max: 50mb)</TranslateComponent>
          </p> :
          <p>
            <TranslateComponent>Acceptable format: </TranslateComponent>
            {' '}
            .doc, .xls, .pdf,{' '}
            <TranslateComponent> (Max: 50mb)</TranslateComponent>
          </p>}
        </>
      )}
      <Input
        type="file"
        ref={fileSelect.fileInputRef}
        accept={`.${allowedFileTypes.join(', .')}`}
        id="fileElem"
        multiple={allowMultipleFiles}
        style={{ display: 'none' }}
        onChange={fileSelect.onOpen}
      />
    </DropArea>
  )
}