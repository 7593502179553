import GroupRenderer from './GroupRenderer'
import BaseSetup from '../../BaseSetup/BaseSetup'
import {
  Legend,
  LegendProvider
} from 'src/chart-library/CommonComponents/Legend'
import './XYChart.scss'
import clsx from 'clsx'
import { useMemo } from 'react'
import { v4 as uuid4 } from 'uuid'

const XYChart = (props: any) => {
  const chartID = useMemo(() => uuid4(), [])

  return (
    <div id={`XY-${chartID}`} className="XY-chart-wrapper" data-testid="XY-chart-wrapper_event">
      <LegendProvider  getSelectedItems={props?.legends?.getSelectedItems}>
        <BaseSetup props={props} chartID={chartID}>
          <GroupRenderer isTranslate={props.isTranslate} />
        </BaseSetup>
        {props?.legends && (
          <Legend
            className={clsx('XY-chart-legend', props?.legends?.className)}
          />
        )}
      </LegendProvider>
      {props?.children}
    </div>
  )
}

export default XYChart
