import Table from '../../components/Table/clientSideTable'
import { ACTIONS } from 'src/constants'
import { MODAL_TYPE, ROW_CONTROL_TEXT } from './constants'
import { useOpportunityContext } from './OpportunityContextProvider'
import {
  SEARCH_OPPORTUNITIES_BY_BUILDING_IDS
} from './graphql'
import { useEffect, useState } from 'react'
import {
  formatStatusWithTranslateFunc,
  formatNextStepWithTranslateFunc,
  KPI_KEYS,
  statusOptionList
} from '../../components/legacy/common/opportunity'
import { useQuery } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { getInitialOpportunity } from './helpers'
import translate from 'src/common/translations'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { accessControlFunc } from 'src/components/accessControl'
import { getSearchParams } from 'src/common/helperFunctions.js'


const OpportunityListForConsultation = ({ 
  handleGetOpportunitiesRecords = () => { }, 
  handleCheckBoxClick = () => { }, 
  enableShowPagination = false, 
  enableRowCheckbox = false, 
  selectedItems = [], 
  isFromConsultation = false,
  buildingIds=[], 
  buildingsList,
  handleOpportunityListData }: any) => {
  const {
    setMode,
    setOpportunity,
    reloadEvents,
    setReloadEvents,
    setModalType,
    setOpportunityId,
    setOpportunityInputValues,
    setIsDeletedDone,
    currentDate,
    userName,
    setDataList,
    setFilteredData
  } = useOpportunityContext()
  const {organizationId: accountId, buildingId} = getSearchParams()
  const [rows, setRows] = useState([])
  const [responseTime, setResponseTime] = useState(null)
  const [opportunitySelectedRows, setOpportunitySelectedRows] = useState([])
  const [t] = useTranslation()
  const userAccess = useSelector(selectUserAccess)
  const navigate = useNavigate()

  const optionsTranslated = {
    opportunity: translate('Opportunity'),
    roi: "ROI",
    price: translate('Price'),
    keyMetrics: translate('Key Metrics'),
    building: translate('Building'),
    status: translate('Status'),
    nextStep: translate('Next Step'),
    priority: translate('Priority')
  }

  const {
    data: oppoData,
    refetch: refetchSearchOpportunityList,
    responseTime: listResponseTime1
  } = useQuery({
    query: SEARCH_OPPORTUNITIES_BY_BUILDING_IDS,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data',
    onSuccess: () => {
      setIsDeletedDone(false)
    }
  })

  useEffect(() => {
    if(buildingIds && buildingIds.length >0){
      const variables = {
        filter: {
          or: buildingIds?.map(id => {
            return {
              "buildingId": { "eq": id }
            }
          })
        },
        limit: 1000,
        sort: [
          {
            "direction": "asc",
            "field": "title"
          }
        ]
      }
    refetchSearchOpportunityList(variables)
    }else {
      setRows([])
      setDataList([])
    }
  }, [buildingIds])

  const listDataItems = oppoData?.searchOpportunities?.items || []

  const nameHeadings = [
    {
      title: optionsTranslated.opportunity,
      key: 'title',
      maxWidth: '120px',
      onDataClick: (data) => {
        setOpportunityId(data.id)
        navigate(`/${PAGE_NAMES.OPPORTUNITIES}/${data.id}`, {
          state: { mode: ACTIONS.VIEW, id: data.id }
        })
      }
    },
    {
      title: optionsTranslated.roi,
      key: 'paybackTerm',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.price,
      key: 'price',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.keyMetrics,
      key: 'keyMetrics',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.building,
      key: 'buildingName',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.status,
      key: 'status',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.nextStep,
      key: 'nextStep',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.priority,
      key: 'priority',
      maxWidth: '120px'
    }
  ]

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
    }
  }, [reloadEvents])

  useEffect(() => {
    setMode(null)
    setOpportunity(
      getInitialOpportunity(accountId, buildingId, currentDate, userName)
    )
  }, [])

  const getKeyMetrics = (data) => {
    let metrics = ''
    if (data?.impactComfortDisplay)
      metrics = metrics.concat(' ', KPI_KEYS.Comfort)
    if (data?.impactComplianceDisplay)
      metrics = metrics.concat(',', KPI_KEYS.Compliance)
    if (data?.impactEnergyDisplay)
      metrics = metrics.concat(',', KPI_KEYS.EnergyUsage)
    if (data?.impactPerformanceDisplay)
      metrics = metrics.concat(',', KPI_KEYS.Performance)
    if (data?.impactReliabilityDisplay)
      metrics = metrics.concat(',', KPI_KEYS.Reliability)
    if (data?.impactCustom1Display)
      metrics = metrics.concat(',', data?.impactCustom1Name)
    if (data?.impactCustom2Display)
      metrics = metrics.concat(',', data?.impactCustom2Name)
    return metrics
  }

  useEffect(() => {
    if(selectedItems?.length === 0 && !isFromConsultation) return
    setOpportunitySelectedRows(selectedItems)
  }, [selectedItems])
  
  useEffect(() => {
    setResponseTime(listResponseTime1)
    const opportunityList = listDataItems?.map((row) => {
      const building = Array.isArray(buildingsList) ? buildingsList.find((b) => b.key === row?.buildingId) : null;
      const buildingName = building ? building.value : '';
      
      return {
        ...row,
        buildingName: buildingName,
        keyMetrics: getKeyMetrics(row),
        price: row?.price ? `$${row?.price}` : t('opportunities:NotEstimated'),
        paybackTerm: `${row?.paybackTerm ?? '0'} ${row?.paybackUnit ?? ''}`,
        status: formatStatusWithTranslateFunc(row?.status, t),
        nextStep: formatNextStepWithTranslateFunc(row?.nextStep, t),
        checkboxCheckedEnabled: opportunitySelectedRows
          ?.map((i) => i.key)
          ?.includes(row?.id)
      }})
    setRows(opportunityList)
    setDataList(opportunityList)
  }, [oppoData, opportunitySelectedRows, buildingsList])

  const handleUpdatedTableRows = (updatedRows) => {
    const updatedRowIds = updatedRows?.map(m=>m.id) ?? [];
    const filteredItems= rows.filter(i=>{
      return updatedRowIds.includes(i?.id);
    })
    handleGetOpportunitiesRecords(filteredItems)
    setFilteredData(filteredItems)
  }

  useEffect(() => {
    if (listDataItems?.length && handleOpportunityListData) {
      handleOpportunityListData(listDataItems);
    }
  }, [listDataItems?.length]);
  return (
    <Table
      key={`opportunityListTable-${buildingId}`}
      rows={rows ?? []}
      header={nameHeadings}
      loadTime={responseTime}
      search={true}
      searchFields={['title']}
      handleUpdatedTableRows={(updatedRows) => handleUpdatedTableRows(updatedRows)}
      rowControl={!isFromConsultation?[
        accessControlFunc({id: "tc.pages.opportunities.add", userAccess}) ? {
          text: ROW_CONTROL_TEXT.Copy,
          action: (data) => {
            setOpportunityId(data.id)
            navigate(`/opportunities/${data.id}`, {
              state: { mode: ACTIONS.COPY, id: data.id }
            })
          }
        } : null,
        accessControlFunc({id: "tc.pages.opportunities.edit", userAccess}) ? {
          text: ROW_CONTROL_TEXT.Edit,
          action: (data) => {
            setOpportunityId(data.id)
            navigate(`/opportunities/${data.id}`, {
              state: { mode: ACTIONS.EDIT, id: data.id }
            })
          }
        } : null,
        accessControlFunc({id: "tc.pages.opportunities.delete", userAccess}) ? {
          text: ROW_CONTROL_TEXT.Delete,
          action: (data) => {
            setOpportunityInputValues(data)
            setModalType(MODAL_TYPE.CONFIRMATION)
          }
        } : null
      ].filter(e => e !== null):[]}
      filtersList={[
        {
          label: 'All Status',
          key: 'status',
          id: 'statusSelectorFilter',
          defaultLabel: 'All Status',
          selectedValue: 'default',
          options: statusOptionList(t)?.map((s) => ({
            ...s,
            name: s?.value
          }))
        }
      ]}
      isShowPagination={enableShowPagination}
      rowCheckbox={enableRowCheckbox}
      checkboxClick={handleCheckBoxClick}
    />
  )
}

export default OpportunityListForConsultation
