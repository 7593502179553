// Project imports
import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { TranslateComponent } from 'src/common/translations'
import QuickAdd from 'src/pages/issuesFindings/quickAdd/QuickAdd'

// Denali imports.
import { faBuilding, faTachographDigital } from 'src/denali-components/lib/pro-solid-svg-icons'
import { getColor } from 'src/denali-components/helpers'
import tableStyles from 'src/denali-components/Table/table.module.scss'
import { Chip } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import { ChipPriority } from 'src/denali-components/Table/ChipPriority'
import { ChipStatus } from 'src/denali-components/Table/ChipStatus'
import { IconCell } from 'src/denali-components/Table/IconCell'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'

// Local imports.
import styles from './issues-findings.module.scss'

export const IssuesAndFindingsList = ({
  rows,
  optionsTranslated,
  rowControl,
  filtersList,
  listDataIsLoading,
  rowActionCallback
}) => {
  return (
    <>
      <QuickAdd />
      <ClientSideTable
        rows={rows}
        tableClassName={tableStyles.issuesFindingsPage}
        header={getHeadersWithRowRenderOverride(optionsTranslated)}
        search={true}
        searchFields={['title']}
        rowControl={rowControl}
        filtersList={filtersList}
        isDenaliTable={true}
        testName="issuesFindingsPage"
        listDataIsLoading={listDataIsLoading}
        noTableDataTitleText={'No issues or findings found'}
        rowAction={rowActionCallback}
      />
    </>
  )
}

const getHeadersWithRowRenderOverride = (optionsTranslated) => {
  return [
    {
      title: optionsTranslated.finding,
      key: 'title',
      width: 'auto',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.priority,
      key: 'priority',
      width: 'auto',
      customComponent: statusCell
    },
    {
      title: optionsTranslated.date,
      key: 'creationDate',
      width: 'auto',
      customComponent: dateCell
    },
    {
      title: optionsTranslated.assigned,
      key: 'assignedTo',
      width: 'auto',
      customComponent: accountCell
    }
  ]
}

const titleCell = (row) => {
  return (
    <IconCell iconType={'issues/findings'} randomizeColor={row.title}>
      <div className={styles.title}>{row.title}</div>
      <Chips>
        <Tooltip>
          <TooltipTrigger>
            <Chip text={row.buildingName} icon={faBuilding} />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Building</TranslateComponent>
          </TooltipContent>
        </Tooltip>
        {row.equipments && (
          <Tooltip>
            <TooltipTrigger>
              <Chip text={row.equipments} icon={faTachographDigital} />
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Equipment</TranslateComponent>
            </TooltipContent>
          </Tooltip>
        )}
      </Chips>
    </IconCell>
  )
}
const statusCell = (row) => {
  return (
    <div className={styles.statusRow}>
      <Tooltip>
        <TooltipTrigger>
          <ChipStatus statusText={row.status} />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>Status</TranslateComponent>
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <ChipPriority priorityText={row.priority} />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>Priority</TranslateComponent>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}
const dateCell = (row) => {
  return (
    <div className={styles.dateContainer}>
      {row.creationDate && (
        <div className={styles.dateInfo}>
          <p className={styles.subline}>Created on</p>
          <p className={styles.text}>{row.creationDate}</p>
        </div>
      )}
      {row.targetDate && (
        <div className={styles.dateInfo}>
          <p className={styles.subline}>Scheduled for</p>
          <p className={styles.text}>{row.targetDate}</p>
        </div>
      )}
      {row.nextStep && (
        <div className={styles.dateInfo}>
          <p className={styles.subline}>Next step</p>
          <p className={styles.text}>{row.nextStep}</p>
        </div>
      )}
    </div>
  )
}
const accountCell = (row) => {
  // TODO: This will need attention since a lot of the "assignedTo" values appear to be emails
  const initials = row.assignedTo
    ? row.assignedTo
        .toUpperCase()
        .split('.')
        .map((part) => part[0])
        .join('')
        .slice(0, 2)
    : 'NA'
  const color = getColor(initials)

  if (!row.assignedTo) {
    return null
  }

  return (
    <div className={styles.userBubble}>
      <div className={styles.icon} data-color={color}>
        {initials}
      </div>
      <div className={styles.assigned}>
        <p className={styles.subline}>Assigned to</p>
        <p className={styles.text}>{row.assignedTo}</p>
      </div>
    </div>
  )
}
