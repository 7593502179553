import { EnergyIntensityBenchmarksAndMarkersContextProviderProps } from './types'
import { EnergyIntensityBenchmarksAndMarkersContext } from './hooks/use-energy-intensity-benchmarks-and-markers-context'

export const EnergyIntensityBenchmarksAndMarkersContextProvider = ({ 
  children, 
  markers,
  onToggleMarker,
  benchmarks,
  onToggleBenchmark,
  totalEnergyCost,
  chartDates,
  dateRange
}: EnergyIntensityBenchmarksAndMarkersContextProviderProps) => {
  const contextData = {
    markers,
    onToggleMarker,
    benchmarks,
    onToggleBenchmark,
    totalEnergyCost,
    chartDates,
    dateRange
  }

  return (
    <EnergyIntensityBenchmarksAndMarkersContext.Provider value={contextData}>
      {children}
    </EnergyIntensityBenchmarksAndMarkersContext.Provider>
  )
}