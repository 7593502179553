// Library imports
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

// Project imports.
import { getSearchParams } from 'src/common/helperFunctions'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { UiMode } from 'src/redux/types/AppTypes'
import { selectBetaUser, selectUiMode, selectUserAccess } from 'src/redux/slicers/appData'

// Component imports
import { accessControlFunc } from 'src/components/accessControl'
import Checkbox from 'src/components/checkbox-sc/checkbox.sc.js'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import Spinner from 'src/components/legacy/components/spinner/spinner'

// Denali imports
import EnergyIntensitySidebar from 'src/denali-pages/EnergyIntensity/EnergyIntensitySidebar'

// Local imports
import BenchmarkDetail from './benchmark/BenchmarkDetail'
import {
  DELETE_EVENT_MARKER,
  LIST_EVENT_MARKERS_BY_ACCOUNT
} from '../eventMarkers/graphql'
import { EventMarkerDetail } from '../eventMarkers/EventMarkerDetail'
import { markerTypes, MARKER_OPTIONS } from './constants'
import { DELETE_BENCH_MARK, listBenchMarkersByBuilding } from './graphql'
import { Marker, MarkersContainer, Alter, AddContainer, Add } from './styles'

const Markers = ({
  chartDates,
  onMarkersUpdate,
  onBenchmarksUpdate,
  toggleBenchmarks,
  // Props pass through for new Denali component
  sidebarOpen = false,
  setSidebarOpen = (open: boolean) => {},
  euiEnabled = false,
  eciEnabled = false,
  setEuiEnabled = (enabled: boolean) => {},
  setEciEnabled = (enabled: boolean) => {}
}) => {
  const initialValues = {
    name: '',
    description: '',
    type: 'Comment',
    markerDate: moment().format('MM/DD/YYYY'),
    value: ''
  }
  // const { buildingId, accountId } = useEngergyIntensityContext()
  const { buildingId, organizationId: accountId } = getSearchParams()
  const [modalType, setModalType] = useState('')
  const [marker, setMarker] = useState(null)
  const [deleteMarkerType, setDeleteMarkerType] = useState(null)
  const [markerActionMode, setMarkerActionMode] = useState(null)
  const [markersFiltered, setMarkersFiltered] = useState([])
  const [benchMarkersFiltered, setBenchMarkersFiltered] = useState([])
  const [benchmarkActionMode, setBenchmarkActionMode] = useState(null)

  const userAccess = useSelector(selectUserAccess)
  const uiMode = useSelector(selectUiMode)
  const denaliTheme = uiMode === UiMode.denali
  const betaUser = useSelector(selectBetaUser)

  const {
    data: markersData,
    refetch: refetcMarkersData,
    loading: markersLoading
  } = useQuery({
    query: LIST_EVENT_MARKERS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listEventMarkersByAccount.items'
  })

  const {
    data: benchMarkerData,
    refetch: refetcBenchMarkerData,
    loading: benchmarksLoading
  } = useQuery({
    query: listBenchMarkersByBuilding,
    variables: { buildingId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBenchMarkersByBuilding.items'
  })

  const { onSubmit: deleteMarker } = useMutation({
    query: DELETE_EVENT_MARKER,
    onSuccess: () => {
      setModalType(MODAL_TYPE.DELETE_SUCCESS)
      refetcMarkersData({
        accountId: accountId,
        filter: { buildingIds: { contains: buildingId } }
      })
    }
  })

  const { onSubmit: deleteBenchmark } = useMutation({
    query: DELETE_BENCH_MARK,
    onSuccess: () => {
      setModalType(MODAL_TYPE.DELETE_SUCCESS)
      refetcBenchMarkerData({ buildingId })
    }
  })

  useEffect(() => {
    if (buildingId && !denaliTheme) {
      refetcBenchMarkerData({ buildingId })
      refetcMarkersData({
        accountId: accountId,
        filter: { buildingIds: { contains: buildingId } }
      })
    }
  }, [buildingId])

  useEffect(() => {
    if (markersData && !denaliTheme) {
      updateMarkersData()
    }
  }, [markersData])

  useEffect(() => {
    if (chartDates && !chartDates.dateError && markersData && !denaliTheme) {
      updateMarkersData()
    }
  }, [chartDates])

  const updateMarkersData = () => {
    const filtered = markersData?.filter(
      (marker) =>
        marker.markerDate >= chartDates.chartStartDate &&
        marker.markerDate <= chartDates.chartEndDate
    )
    const checkedMarkers = filtered?.map((marker) => {
      const markerExisting = markersFiltered?.find(
        (existingMarker) => existingMarker.id === marker.id
      )
      return {
        ...marker,
        checked: markerExisting ? markerExisting.checked : true
      }
    })
    setMarkersFiltered(checkedMarkers)
    onMarkersUpdate(checkedMarkers?.filter((marker) => marker.checked))
  }

  useEffect(() => {
    if (benchMarkerData && !denaliTheme) {
      const checkedBenchMarkers = benchMarkerData?.map((marker) => {
        const benchMarkExisting = benchMarkersFiltered?.find(
          (existingMarker) => existingMarker.id === marker.id
        )
        return {
          ...marker,
          checked: benchMarkExisting ? benchMarkExisting.checked : true
        }
      })
      setBenchMarkersFiltered(checkedBenchMarkers)
      onBenchmarksUpdate(
        checkedBenchMarkers?.filter((marker) => marker.checked)
      )
    }
  }, [benchMarkerData])

  const addBenchmark = () => {
    setDeleteMarkerType(MARKER_OPTIONS.BENCHMARK)
    setBenchmarkActionMode(ACTIONS.ADD)
    hideDeleteDialog()
  }
  const addEventMarker: React.MouseEventHandler = (event) => {
    setDeleteMarkerType(MARKER_OPTIONS.EVENTMAREKR)
    setMarkerActionMode(ACTIONS.ADD)
    hideDeleteDialog()
  }

  const hideDeleteDialog = () => {
    setModalType('')
  }

  const markerDeleteHandler = () => {
    if (deleteMarkerType === MARKER_OPTIONS.BENCHMARK) {
      deleteBenchmark({ input: { id: marker?.id } })
    } else if (deleteMarkerType === MARKER_OPTIONS.EVENTMAREKR) {
      setDeleteMarkerType(MARKER_OPTIONS.EVENTMAREKR)
      deleteMarker({ input: { id: marker?.id } })
    }
  }

  const modalConfig = useMemo(
    () => ({
      heading: (() => {
        const type =
          deleteMarkerType === MARKER_OPTIONS.BENCHMARK ? 'Benchmark' : 'Marker'

        switch (modalType) {
          case MODAL_TYPE.DELETE_SUCCESS:
            return `${type} Deleted`
          case MODAL_TYPE.CREATE:
            return `${type} Created`
          case MODAL_TYPE.UPDATE:
            return `${type} Updated`
          default:
            return `Delete ${
              deleteMarkerType === MARKER_OPTIONS.BENCHMARK
                ? 'Benchmark'
                : 'Marker'
            }`
        }
      })(),

      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: 'Yes',
                handleClick: markerDeleteHandler,
                type: 'valid'
              },
              {
                text: 'No',
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ]
          : [
              {
                text: 'Close',
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ],
      handleClose: hideDeleteDialog
    }),
    [modalType]
  )

  const renderConfirmationText = (modalType) => {
    const type =
      deleteMarkerType === MARKER_OPTIONS.BENCHMARK ? 'Benchmark' : 'Marker'
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return `Are you sure you would like to delete this ${type}? (${marker?.name})`
      case MODAL_TYPE.CREATE:
        return ` ${type} (${marker?.name}) successfully Created  `
      case MODAL_TYPE.UPDATE:
        return `${type} (${marker?.name}) successfully Updated `
      case MODAL_TYPE.DELETE_SUCCESS:
        return `${type} (${marker?.name}) successfully Deleted `
      default:
        return 'Delete Notification'
    }
  }

  const reloadEventMarkers = (e) => refetcMarkersData({
    accountId: accountId,
    filter: { buildingIds: { contains: buildingId } }
  })
  const reloadBenchMarkers = (e) => {
    console.log('reloadBenchMarkers', e)
    refetcBenchMarkerData({ buildingId })
  }
  const getMarkerInput = () =>
    markerActionMode === ACTIONS.ADD ? initialValues : marker
  const getBenchmarkerInput = () =>
    benchmarkActionMode === ACTIONS.ADD ? initialValues : marker
  const editFunc = (marker, markerType) => {
    setMarker(marker)
    if (markerType === MARKER_OPTIONS.BENCHMARK) {
      setBenchmarkActionMode(ACTIONS.EDIT)
      setDeleteMarkerType(MARKER_OPTIONS.BENCHMARK)
      hideDeleteDialog()
    } else if (markerType === MARKER_OPTIONS.EVENTMAREKR) {
      setMarkerActionMode(ACTIONS.EDIT)
      setDeleteMarkerType(MARKER_OPTIONS.EVENTMAREKR)
      hideDeleteDialog()
    }
  }
  const deleteFunc = (marker, markerType) => {
    setMarker(marker)
    setDeleteMarkerType(markerType)
    setModalType(MODAL_TYPE.CONFIRMATION)
  }

  const checkBoxChange = (e, marker, markerType) => {
    if (markerType === MARKER_OPTIONS.EVENTMAREKR) {
      const markersUpdated = markersFiltered?.map((filtered) => {
        return {
          ...filtered,
          checked:
            filtered.id === marker.id ? !filtered.checked : filtered.checked
        }
      })
      setMarkersFiltered(markersUpdated)
      onMarkersUpdate(markersUpdated?.filter((marker) => marker.checked))
    } else if (markerType === MARKER_OPTIONS.BENCHMARK) {
      const markersUpdated = benchMarkersFiltered?.map((filtered) => {
        return {
          ...filtered,
          checked:
            filtered.id === marker.id ? !filtered.checked : filtered.checked
        }
      })
      setBenchMarkersFiltered(markersUpdated)
      onBenchmarksUpdate(markersUpdated?.filter((marker) => marker.checked))
    }
  }

  const MarkerComponent = ({ marker, markerType }) => {
    return (
      <Marker
        key={`${marker.name}-${marker.id}`}
        data-testid={
          markerType === MARKER_OPTIONS.EVENTMAREKR
            ? `${marker?.name}-events_eventMarker`
            : `${marker?.name}-events_benchMark`
        }
      >
        <Checkbox
          checkedColor={
            markerType === MARKER_OPTIONS.EVENTMAREKR ? 'grey' : 'blue'
          }
          action={(e) => checkBoxChange(e, marker, markerType)}
          id={`marker-${marker.id}`}
          isChecked={marker.checked}
          label={marker.name}
        />
        <Alter
          onClick={(e) => editFunc(marker, markerType)}
          className="icon icon-edit"
        />
        <Alter
          onClick={(e) => deleteFunc(marker, markerType)}
          className="icon icon-trash"
        />
      </Marker>
    )
  }

  // @TODO: Remove ternary
  return (
    <>
      {denaliTheme && betaUser
      ? (
        <EnergyIntensitySidebar
          isOpen={sidebarOpen}
          onOpenChange={setSidebarOpen}
          isEUIActive={euiEnabled}
          isECIActive={eciEnabled}
          onEUIClick={() => setEuiEnabled(!euiEnabled)}
          onECIClick={() => setEciEnabled(!eciEnabled)}
          addBenchmark={addBenchmark}
          addEventMarker={addEventMarker}
        />
      ) 
      : (
        <>
          <MarkersContainer>
            {benchmarksLoading || markersLoading ? (
            <Spinner />
          ) : (
            <>
              {!toggleBenchmarks?.includes('eui') &&
                benchMarkersFiltered &&
                benchMarkersFiltered?.map((marker) => (
                  <MarkerComponent
                    marker={marker}
                    key={`${marker.name}-${marker.id}`}
                    markerType={MARKER_OPTIONS.BENCHMARK}
                  />
                ))}
              {markersFiltered &&
                markersFiltered?.map((marker) => (
                  <MarkerComponent
                    marker={marker}
                    key={`${marker.name}-${marker.id}`}
                    markerType={MARKER_OPTIONS.EVENTMAREKR}
                  />
                ))}
            </>
          )}
        </MarkersContainer>
          <AddContainer>
            <Add onClick={addBenchmark}>Add Benchmark</Add>
            {accessControlFunc({
              id: 'tc.pages.event-markers',
              userAccess
            }) && <Add onClick={addEventMarker}>Add Event Marker</Add>}
          </AddContainer>
        </>
        )}
      {benchmarkActionMode && (
        <BenchmarkDetail
          mode={benchmarkActionMode}
          setMode={(e) => setBenchmarkActionMode(e)}
          markerInput={getBenchmarkerInput()}
          setMarkerInput={(e) => setMarker(e)}
          initialValues={initialValues}
          buildingId={buildingId}
          accountId={accountId}
          setReloadEvents={(e) => reloadBenchMarkers(e)}
          setConfirmationType={setModalType}
        />
      )}
      {markerActionMode && (
        <EventMarkerDetail
          rows={markersData}
          mode={markerActionMode}
          setMode={(e) => setMarkerActionMode(e)}
          markerInput={getMarkerInput()}
          setMarkerInput={(e) => setMarker(e)}
          initialValues={initialValues}
          markerTypes={markerTypes}
          buildingId={buildingId}
          accountId={accountId}
          setReloadEvents={(e) => reloadEventMarkers(e)}
          setConfirmationType={setModalType}
        />
      )}
      {[
        MODAL_TYPE.CONFIRMATION,
        MODAL_TYPE.SUCCESS,
        MODAL_TYPE.CREATE,
        MODAL_TYPE.UPDATE,
        MODAL_TYPE.DELETE_SUCCESS
      ].includes(modalType) ? (
        <Modal {...modalConfig}>
          <ConfirmationText>
            {renderConfirmationText(modalType)}
          </ConfirmationText>
        </Modal>
      ) : null}
    </>
  )
}

export default Markers
