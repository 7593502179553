import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import {
  containerCss,
  inputRootCss,
  inputCss,
  svgCss,
  colorResolver
} from '../../styles/radio-checkbox'
import { shouldForwardProp } from '../../utils'
import { typographyFontFamily } from '../../ui-system/typography'

// --------------------------------------------- Styled Component ---------------------------------------------

const inputStatesDefaultColorInitFn = ({ hover, checked, disabled, error }) => {
  return css`
    // on focus
    &:focus ~ svg {
      .input-default {
        stroke: ${checked};
      }
    }

    // on hover
    &:hover:not(:disabled):not(:checked):not(.intermediate) ~ svg {
      .input-default,
      .input-intermediate {
        display: none;
      }
      .input-hover {
        display: block;
      }
    }

    // on checked
    &:checked ~ svg {
      .input-default {
        display: none;
      }
      .input-active {
        display: block;
      }
    }

    // on disabled
    &:disabled {
      cursor: initial;
    }

    // on disabled but not checked
    &:disabled:not(:checked):not(.intermediate) ~ svg {
      .input-default {
        display: none;
      }
      .input-inactive-disabled {
        display: block;
      }
    }

    // on disabled but checked
    &:disabled:checked ~ svg {
      .input-active {
        &-stroke {
          fill: ${disabled};
        }
      }
    }

    // on error without any state (disabled / checked)
    &.error:not(:disabled):not(:checked) ~ svg {
      .input-default {
        display: block;
        stroke: ${error};
      }
    }

    &.error:checked:not(:disabled) ~ svg {
      .input-default {
        display: none;
      }
      .input-active-error {
        display: block;
      }
    }

    // on intermidate
    &.intermediate ~ svg {
      .input-default,
      .input-active,
      .input-inactive-disabled {
        display: none;
      }
      .input-intermediate {
        display: block;
      }
    }

    &.intermediate:disabled ~ svg {
      .input-intermediate {
        display: block;
        &-stroke {
          fill: ${disabled};
        }
      }
    }
  `
}

const svgDefaultColorInitFn = ({
  checked,
  disabled,
  error,
  hover,
  unchecked
}) => {
  return css`
    .input-default {
      fill: #f9fafc;
    }

    .input-error {
      display: none;
      &-stroke {
        fill: ${error};
      }
      &-bg {
        fill: ${unchecked};
      }
    }

    .input-hover {
      display: none;
      &-stroke {
        //  fill: ${hover};
      }
      &-bg {
        fill: ${hover};
      }
    }

    .input-active,
    .input-intermediate {
      display: none;
      &-stroke {
        fill: ${checked};
      }
    }

    .input-active-error {
      display: none;
    }

    .input-inactive-disabled {
      display: none;
      fill: ${disabled};
    }
  `
}

const CheckboxRoot = shouldForwardProp('label', ['fontFamily'])`
  ${typographyFontFamily}
  ${containerCss}
`

const CheckboxInputRoot = styled.span(inputRootCss)

const CheckboxInput = styled.input(
  inputCss,
  colorResolver(inputStatesDefaultColorInitFn)
)

const Svg = styled.svg`
  ${svgCss}
  ${colorResolver(svgDefaultColorInitFn)}
`

// --------------------------------------------- Component ---------------------------------------------

const Checkbox = React.forwardRef(function Checkbox(inProps, inRef) {
  const {
    className,
    disabled,
    error,
    intermediate,
    inputStateColors,
    label,
    labelPosition,
    size,
    isDenaliTable=false
  } = inProps

  const inputRef = useRef(null)

  const setRef = (node) => {
    if (node) {
      inputRef.current = node
      inRef && (inRef.current = node)
    }
  }

  useEffect(() => {
    if (!inputRef?.current) return
    inputRef.current.intermediate = intermediate
  }, [inputRef.current, intermediate])

  return (
    <CheckboxRoot
      fontFamily="primary"
      size={size}
      className={className}
      disabled={disabled}
    >
      {labelPosition === 'left' && <span>{label}</span>}
      <CheckboxInputRoot style={labelPosition === 'left' ? {paddingLeft: "5px"} : {paddingRight: "5px"}} className="tableCheckboxBulkAction">
        <CheckboxInput
          onChange={() => null}
          {...inProps}
          className={`${error && 'error'} ${intermediate && 'intermediate'}`}
          type="checkbox"
          ref={setRef}
        />
        <Svg
          viewBox="0 0 15 16"
          xmlns="http://www.w3.org/2000/svg"
          inputStateColors={inputStateColors}
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            {/* Default */}
            <g
              className="input-default"
              transform="translate(-236.000000, -4020.000000)"
              fill="#F9FAFC"
              stroke="#949494"
            >
              <g transform="translate(236.000000, 4020.186262)">
                <rect x="0.5" y="0.5" width="14" height="14" rx="4"></rect>
              </g>
            </g>
          </g>

          {/* Error */}
          <g
            className="input-error"
            transform="translate(-236.000000, -4194.000000)"
          >
            <g transform="translate(236.000000, 4194.186262)">
              <rect
                className="input-error-stroke"
                fill="#991909"
                fillRule="nonzero"
                x="0"
                y="0"
                width="15"
                height="15"
                rx="4"
              ></rect>
              <rect
                className="input-error-bg"
                fill="#F9FAFC"
                x="1"
                y="1"
                width="13"
                height="13"
                rx="4"
              ></rect>
            </g>
          </g>

          {/* Hover */}
          <g
            className="input-hover"
            transform="translate(-236.000000, -4020.000000)"
          >
            <g transform="translate(236.000000, 4020.000000)">
              <g>
                <rect
                  className="input-hover-stroke"
                  stroke="#A5C2E7"
                  strokeWidth="1"
                  strokeLinejoin="square"
                  fill="#F9FAFC"
                  fillRule="evenodd"
                  x="0.5"
                  y="0.5"
                  width="14"
                  height="14"
                  rx="4"
                ></rect>
              </g>
              <path
                className="input-hover-bg"
                d={
                  isDenaliTable
                    ? "M11.0156 0.984375C11.3203 1.26562 11.3203 1.75781 11.0156 2.03906L5.01562 8.03906C4.73438 8.34375 4.24219 8.34375 3.96094 8.03906L0.960938 5.03906C0.65625 4.75781 0.65625 4.26562 0.960938 3.98438C1.24219 3.67969 1.73438 3.67969 2.01562 3.98438L4.5 6.44531L9.96094 0.984375C10.2422 0.679688 10.7344 0.679688 11.0156 0.984375Z"
                    : "M10.5151031,3.42832975 L12.1331371,4.60390026 L8.24682509,9.95363626 L7.07125459,11.5716702 L7.07075589,11.5716702 L5.4527219,10.3960997 L2.86686289,8.51700087 L4.04243339,6.89896689 L6.62786289,8.77732975 L10.5151031,3.42832975 Z"
                }
                transform={
                  isDenaliTable
                    ? "translate(1.5, 3)"
                    : ""
                }
                id="Success"
                fill="#A5C2E7"
              ></path>
            </g>
          </g>

          {/* Inactive & disabled */}
          <g
            className="input-inactive-disabled"
            transform="translate(-236.000000, -4310.000000)"
            fill="#E2E2E2"
          >
            <g transform="translate(236.000000, 4310.186262)">
              <rect x="0" y="0" width="15" height="15" rx="4"></rect>
            </g>
          </g>

          {/* Intermediate (active / disabled) */}
          <g
            className="input-intermediate"
            transform="translate(-236.000000, -4426.000000)"
          >
            <g transform="translate(236.000000, 4426.186262)">
              <rect
                className="input-intermediate-stroke"
                fill="#206FCF"
                x="0"
                y="0"
                width="15"
                height="15"
                rx="4"
              ></rect>
              <path
                className="input-intermediate-bg"
                d="M10.9235491,8.13616071 L4.07645089,8.13616071 C4.07087051,8.13616071 4.06808036,8.13616071 4.06808036,8.13616071 L4.05970982,6.42857143 L10.906808,6.42857143 C10.9123884,6.42857143 10.9151786,6.42857143 10.9151786,6.42857143 L10.9235491,8.13616071 Z"
                id=""
                fill="#FFFFFF"
              ></path>
            </g>
          </g>

          {/* selcted (active / disabled)  */}
          <g
            className="input-active"
            transform="translate(-236.000000, -4368.000000)"
          >
            <g transform="translate(236.000000, 4368.186262)">
              <rect
                className="input-active-stroke"
                fill="#E2E2E2"
                x="0"
                y="0"
                width="15"
                height="15"
                rx="4"
              ></rect>
              <path
                d={
                  isDenaliTable
                    ? "M11.0156 0.984375C11.3203 1.26562 11.3203 1.75781 11.0156 2.03906L5.01562 8.03906C4.73438 8.34375 4.24219 8.34375 3.96094 8.03906L0.960938 5.03906C0.65625 4.75781 0.65625 4.26562 0.960938 3.98438C1.24219 3.67969 1.73438 3.67969 2.01562 3.98438L4.5 6.44531L9.96094 0.984375C10.2422 0.679688 10.7344 0.679688 11.0156 0.984375Z"
                    : "M10.5151031,3.42832975 L12.1331371,4.60390026 L8.24682509,9.95363626 L7.07125459,11.5716702 L7.07075589,11.5716702 L5.4527219,10.3960997 L2.86686289,8.51700087 L4.04243339,6.89896689 L6.62786289,8.77732975 L10.5151031,3.42832975 Z"
                }
                transform={
                  isDenaliTable
                    ? "translate(1.5, 3)"
                    : ""
                }
                id="Success"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>

          {/* selected error */}
          <g
            className="input-active-error"
            transform="translate(-236.000000, -4252.000000)"
          >
            <g transform="translate(236.000000, 4252.186262)">
              <rect
                className="input-active-error-stroke"
                fill="#991909"
                fillRule="nonzero"
                x="0"
                y="0"
                width="15"
                height="15"
                rx="4"
              ></rect>
              <rect
                className="input-active-error-bg"
                fill="#206FCF"
                x="1"
                y="1"
                width="13"
                height="13"
                rx="4"
              ></rect>
              <path
                d={
                  isDenaliTable
                    ? "M11.0156 0.984375C11.3203 1.26562 11.3203 1.75781 11.0156 2.03906L5.01562 8.03906C4.73438 8.34375 4.24219 8.34375 3.96094 8.03906L0.960938 5.03906C0.65625 4.75781 0.65625 4.26562 0.960938 3.98438C1.24219 3.67969 1.73438 3.67969 2.01562 3.98438L4.5 6.44531L9.96094 0.984375C10.2422 0.679688 10.7344 0.679688 11.0156 0.984375Z"
                    : "M10.5151031,3.42832975 L12.1331371,4.60390026 L8.24682509,9.95363626 L7.07125459,11.5716702 L7.07075589,11.5716702 L5.4527219,10.3960997 L2.86686289,8.51700087 L4.04243339,6.89896689 L6.62786289,8.77732975 L10.5151031,3.42832975 Z"
                }
                transform={
                  isDenaliTable
                    ? "translate(1.5, 3)"
                    : ""
                }
                id="Success"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </Svg>
      </CheckboxInputRoot>
      {labelPosition === 'right' && <span>{label}</span>}
    </CheckboxRoot>
  )
})

Checkbox.propTypes = {
  /**
   * The option element to populate within select.
   */
  checked: PropTypes.bool,

  /**
   * If true, the option is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * If true, the component appears intermediate.
   */
  intermediate: PropTypes.bool,

  /**
   * Customize the states (checked, disabled, ...) of input color
   */
  inputStateColors: PropTypes.shape({
    checked: PropTypes.string,
    disabled: PropTypes.string,
    error: PropTypes.string,
    hover: PropTypes.string,
    unchecked: PropTypes.string
  }),

  /**
   * The label content.
   */
  label: PropTypes.any,

  /**
   * The label postion.
   */
  labelPosition: PropTypes.oneOf(['left', 'right']),

  /**
   * The size of the component. It uses font-size for the size (default 18px).
   * It accepts any font-size value with units.
   */

  size: PropTypes.string,

  /**
   * The value of the component.
   */
  value: PropTypes.any
}

Checkbox.defaultProps = {
  disabled: false,
  error: false,
  intermediate: false,
  labelPosition: 'right'
}

export default React.memo(Checkbox)
