export type AppTypes = {
  breadcrumbs: string[]
  roles: string[]
  selectedLanguage: string
  userInfo: {
    data?: IUser
    // pages?: IPage
  }
  uiMode: UiMode
  betaUser: boolean
  access: Map<string, boolean>,
  userAccessibleBuildingIds: string[],
  dismissedOnboarding: boolean,
}

export enum UiMode {
  standard,
  denali
}

export type IUser = {
  id: string
  email: string
  firstName: string
  lastName: string
  // locationIds: string[]
  // organizationIds: string[]
  // salesOfficeIds: string[]
  // adminLocationIds: string[]
  // adminSalesOfficeIds: string[]
  // adminOrganizationIds: string[]
  // superAdminLocationIds: string[]
  // superAdminOrganizationIds: string[]
  // superAdminSalesOfficeIds: string[]
  // roles: string[]
  // adminRoles: string[]
  // superAdminRoles: any
  jobTitle: any
  primarySalesOfficeId: any
  createdOn: any
  createdAt: any
  defaultUniqueAssetId: any
  userTypeId: string
  type?: UserType
  language: string
  unitOfMeasurement: string
  profileSettings: any
  // userType: string
}
// export type IPage = {
//   isPermittedAction: any
//   MenuItem: any
//   getMenuItemConfig: any
//   isPermittedInMenu: any
//   isPermittedPage: any
// }

export type UserType = {
  id: string
  name: string
}
