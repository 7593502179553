import { PDF_PAGE_TYPES } from 'src/components/legacy/common/pdf'
import PDFGenerator from 'src/components/print-components/pdf-generator'
import { useEffect, useState } from 'react'
import { useAttachment } from 'src/hooks/attachment'
import { PdfDownloadDetailViewPages } from '../helpers'
import PdfFindingDetailView from '../../../pages/issuesFindings/finding-details-download/pdf-finding-detail-view'
import PDFOpportunityDetailView from '../../../pages/opportunities/OpportunityDetail/opportunity-details-download/pdf-opportunity-detail-view'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"


const PdfDownloadDetailView = (props) => {
  const { downloadFileURL } = useAttachment()
  const { downloadData, type, uiMode } = props
  const [pdfDownloadData, setPdfDownloadData] = useState(null)

  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId)
      .then((res) => {
        item.original = res
      })
      .catch((err) => {
        console.log('error', err)
      })
    }
    return item
  }

  const fetchImageData = async () => {
    const downloadImageData = []
    const DownloadDetail = downloadData
    for (const image of DownloadDetail?.settings?.images) {
      let img = image
      if (!image?.original) {
        img = await fetchImage(image)
      }
      downloadImageData.push(img)
    }
    DownloadDetail.settings.images = downloadImageData

    // Download Findings Images Whichever Is Not Download Yet  
    const findingsData = []
    for (const finding of DownloadDetail?.findings ?? []) {    
      const downloadFindingImageData = []
      for (const image of finding?.images) {
        let img = image
        if (!image?.original) {
          img = await fetchImage(image)
        }
        downloadFindingImageData.push(img)
      }
      finding.images = downloadFindingImageData
      findingsData.push(finding)
    }
    DownloadDetail.findings = findingsData
    setPdfDownloadData(DownloadDetail)
  }

  const fetchImaging = async () => {
    await fetchImageData()
  }

  useEffect(() => {
    setPdfDownloadData(downloadData)
  }, [downloadData])

  const runAmplitudeLogic = () =>
  {
    if(type === PdfDownloadDetailViewPages.FINDING)
    {
      trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.DOWNLOAD_FINDING, {
        "file name": pdfDownloadData?.name ? pdfDownloadData?.name : null,
      })
    }
    else if (type === PdfDownloadDetailViewPages.OPPORTUNITY)
    {
      trackEvent(USER_EVENTS.OPPORTUNITIES.events.DOWNLOAD_OPPORTUNITY, {
        "file name": pdfDownloadData?.name ? pdfDownloadData?.name : null
      })
    }
  }

  return (
    <>
      {pdfDownloadData && (
        <PDFGenerator
          fileName={pdfDownloadData?.name}
          pageType={PDF_PAGE_TYPES.CUSTOM_PAGE}
          onPreloadData={fetchImaging}
          onTrigger = {() =>
            runAmplitudeLogic()
          }
          uiMode={uiMode}
        >
          {(type === PdfDownloadDetailViewPages.FINDING) && <PdfFindingDetailView
            downloadData={pdfDownloadData}
            isFooterCount={true}
            headerLocation={pdfDownloadData?.location?.locationName}
            headerOrganization={pdfDownloadData?.organization?.organizationName}
          />}
          {(type === PdfDownloadDetailViewPages.OPPORTUNITY) && <PDFOpportunityDetailView
            downloadData={pdfDownloadData}
            isFooterCount={true}
            headerLocation={pdfDownloadData?.location?.locationName || ''}
            headerOrganization={pdfDownloadData?.organization?.organizationName}
          />}
        </PDFGenerator>
      )}
    </>
  )
}

export default PdfDownloadDetailView
