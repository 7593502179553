import { useFormik } from 'formik'
import moment from 'moment'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import _sortBy from 'lodash/sortBy'

// Project imports
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { getSearchParams } from 'src/common/helperFunctions.js'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT,
} from 'src/components/legacy/common/time-helpers'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { useAttachment } from 'src/hooks/attachment'
import { useAppSelector } from 'src/redux/store'
import { selectUserInfo } from 'src/redux/slicers/appData'

// Local imports
import validationSchema from './formValidator'
import {
  addReportHandler,
  editReportHandler
} from './helper'
import {
  CREATE_DOCUMENT,
  GET_PRE_SIGNED_URL,
  CREATE_ATTACHMENT,
  GET_BUILDINGS_BY_ACCOUNT_ID,
  GET_BUILDINGS_BY_SALESOFFICE_ID,
  UPDATE_DOCUMENT,
  UPDATE_ATTACHMENT,
  GET_DOCUMENT_BY_ID,
  CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT
} from './graphql'
import { ACTIONS } from 'src/constants'
import { AddDocumentsStateProps } from './types'
import { setDocumentsAndReports } from 'src/redux/slicers/formData'
import { BuildingLocation } from 'src/models/location'
import { UiMode } from 'src/redux/types/AppTypes'


export const useAddDocumentsState = ({ 
  locationId,
  values, 
  mode, 
  goBack,
  onChangeBuildingsData,
  uiMode
} : AddDocumentsStateProps) => {
  const dispatch = useDispatch()
  const userInfo: any = useAppSelector(selectUserInfo)
  const params = useParams()

  const {
    organizationId,
    salesOfficeId
  } = getSearchParams()
  const [storedBuildingId, setStoredBuildingId] = useState('')
  const [deleteAttach, setDeleteAttach] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { copyFileFunc } = useAttachment()

  const { data: buildingsList, refetch: refetchBuildingsList } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    variables: { id: organizationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })

  const {
    data: salesOfficeBuildingsList,
    refetch: refetchSalesOfficeBuildingsList
  } = useQuery({
    query: GET_BUILDINGS_BY_SALESOFFICE_ID,
    variables: {
      id: salesOfficeId
    },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsBySalesOffice.items'
  })

  const id = params?.id

  const { data: fetchedDocument, refetch: refetchDocument } = useQuery({
    query: GET_DOCUMENT_BY_ID,
    variables: { id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDocument'
  })

  const modifiedValues = {
    ...values,
    documentName: fetchedDocument?.title,
    documentType: fetchedDocument?.type,
    documentStatus: fetchedDocument?.status,
    visibility: fetchedDocument?.visibility,
    location: { locationId: fetchedDocument?.building?.id },
    createdBy: fetchedDocument?.contact ?? `${userInfo?.firstName} ${userInfo?.lastName}`,
    attachments: fetchedDocument?.attachments?.items ?? [],
    createdDate: moment(fetchedDocument?.createdAt).format(DATE_FORMAT),
    note: fetchedDocument?.note,
    description: fetchedDocument?.description,
    startDate: fetchedDocument?.startDate
      ? moment(fetchedDocument?.startDate).format('MM/DD/YYYY')
      : moment(fetchedDocument?.createdAt).format('MM/DD/YYYY')
  }

  const formSubmitHandler = useCallback( async (values, onComplete=()=>{}) => {
    const previousBuildingId = storedBuildingId
    const newBuildingId = values?.location?.locationId
    const bId = [...new Set([locationId, newBuildingId])]
    if (isSubmitting) return
    setIsSubmitting(true)
    if (_isEmpty(values?.attachments)) return
    if (mode === ACTIONS.ADD) {
      const {
        documentStatus,
        documentType,
        documentName,
        visibility,
        location,
        createdDate,
        note,
        startDate
      } = values
      const input = {
        status: documentStatus,
        visibility,
        title: documentName,
        type: documentType,
        documentType,
        note,
        contact: `${userInfo?.firstName} ${userInfo?.lastName}`,
        buildingId: location?.locationId,
        accountId: organizationId || '',
        creationDate: moment(createdDate).format(BACKEND_DATE_FORMAT),
        startDate: startDate
          ? moment(startDate).format(BACKEND_DATE_FORMAT)
          : moment(createdDate).format(BACKEND_DATE_FORMAT),
        userId: userInfo?.id
      }
      await addReportHandler({
        input,
        attachments: values?.attachments,
        bId,
        newBuildingId,
        createDocument,
        createAttachmentMutation,
        createEntryWithAttachmentMutation,
        currentUserId: values?.currentUserId,
        dispatch
      })
    } else if (mode === ACTIONS.EDIT) {
      const {
        documentStatus,
        documentType,
        documentName,
        visibility,
        location,
        createdDate,
        note,
        description,
        startDate
      } = values

      const input = {
        status: documentStatus,
        visibility,
        title: documentName,
        type: documentType,
        documentType,
        note,
        id: params?.id,
        contact: `${userInfo?.firstName} ${userInfo?.lastName}`,
        buildingId: location?.locationId,
        accountId: organizationId || '',
        creationDate: moment(createdDate).format(BACKEND_DATE_FORMAT),
        startDate: moment(startDate).format(BACKEND_DATE_FORMAT),
        description
      }

      await editReportHandler({
        input,
        storedBuildingId,
        newBuildingId,
        previousBuildingId,
        attachments: values?.attachments,
        fetchedDocument,
        createAttachmentMutation,
        updateAttachmentMutation,
        deleteAttachmentMutation,
        createEntryWithAttachmentMutation,
        currentUserId: values?.currentUserId,
        deleteAttach,
        onUpdateDocument,
        goBack,
        dispatch,
        copyFileFunc
      })
    }
    setIsSubmitting(false)
    onComplete()
    goBack()
  }, [
    isSubmitting,
    setIsSubmitting,
    mode,
    fetchedDocument,
    dispatch,
    deleteAttach
  ]);

  const formik = useFormik({
    initialValues: modifiedValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values)=> formSubmitHandler(values)
  })

  useEffect(() => {
    if (mode === ACTIONS.EDIT) {
      setStoredBuildingId(fetchedDocument?.building?.id)
      refetchDocument()
    }
  }, [mode, fetchedDocument?.building?.id])

  // If fetched document changes then we try and update the redux store.
  useEffect(() => {
    if (fetchedDocument) {
      const mappedValues = {
        ...values,
        documentName: fetchedDocument?.title,
        documentType: fetchedDocument?.type,
        documentStatus: fetchedDocument?.status,
        visibility: fetchedDocument?.visibility,
        location: new BuildingLocation({ locationId: fetchedDocument?.building?.id }),
        createdBy: fetchedDocument?.contact ?? `${userInfo?.firstName} ${userInfo?.lastName}`,
        attachments: fetchedDocument?.attachments?.items ?? [],
        createdDate: moment(fetchedDocument?.createdAt).format(uiMode === UiMode.denali ? 'YYYY-MM-DD' : DATE_FORMAT),
        note: fetchedDocument?.note,
        description: fetchedDocument?.description,
        startDate: fetchedDocument?.startDate
          ? moment(fetchedDocument?.startDate).format(uiMode === UiMode.denali ? 'YYYY-MM-DD' : 'MM/DD/YYYY')
          : moment(fetchedDocument?.createdAt).format(uiMode === UiMode.denali ? 'YYYY-MM-DD': 'MM/DD/YYYY')
      }
      dispatch(setDocumentsAndReports(mappedValues))
    }
  }, [fetchedDocument])

  const { onSubmit: createDocument } = useMutation({
    query: CREATE_DOCUMENT,
    dataPath: 'data.createDocument',
    onSuccess: () => {
      trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_DOCUMENT, {
        mode: 'CREATE',
        'number of attachments': formik?.values?.attachments?.length,
        'building id': formik?.values?.location?.locationId
      })
    }
  })

  const { onSubmit: onUpdateDocument } = useMutation({
    query: UPDATE_DOCUMENT,
    dataPath: 'data.updateDocument',
    onSuccess: () => {
      trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_DOCUMENT, {
        mode: 'EDIT',
        'number of attachments': formik?.values?.attachments?.length,
        'building id': formik?.values?.location?.locationId
      })
    }
  })

  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })
  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })
  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  // Effects to trigger refetches.
  
  useEffect(() => {
    const listData = Array.isArray(buildingsList)
      ? buildingsList
      : Array.isArray(salesOfficeBuildingsList)
      ? salesOfficeBuildingsList
      : []

    if (listData.length > 0) {
      onChangeBuildingsData?.(listData)
    }
  }, [buildingsList, locationId, salesOfficeBuildingsList])

  useEffect(() => {
    if (organizationId) {
      refetchBuildingsList({ id: organizationId })
    }
  }, [organizationId])

  useEffect(() => {
    if (salesOfficeId) {
      refetchSalesOfficeBuildingsList({
        id: salesOfficeId
      })
    }
  }, [salesOfficeId])

  return {
    formik,
    formSubmitHandler,
    refetchDocument,
    refetchBuildingsList,
    refetchSalesOfficeBuildingsList,
    getPreSignedUrlMutation,
    createAttachmentMutation,
    updateAttachmentMutation,
    deleteAttachmentMutation,
    createEntryWithAttachmentMutation,
    buildingsList,
    salesOfficeBuildingsList,
    deleteAttach,
    setDeleteAttach,
    isSubmitting,
    setIsSubmitting,
    copyFileFunc
  }
}