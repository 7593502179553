import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Selectrix from 'react-selectrix'
import PropTypes from 'prop-types'
import './selectrix-wrapper.scss'
import { withMyHook } from './customHookAccessor'
import { isArray } from 'xstate/lib/utils'
import { TranslateComponent } from 'src/common/translations'

class SelectrixWrapper extends React.Component {
  
  static propTypes = {
    className: PropTypes.string,
    noResultsComponent: PropTypes.element,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    noResultsComponent: null,
    isLoading: false,
    onClose: () => {},
    onOpen: () => {},
    onChange: ()=>{}
  }
  
  constructor(props) {
    super(props)
    
    this.state = {
      placeholder: this.props.placeholder,
      closeHelperStyle: {
        display: 'none'
      }
    }

    this.selectrixWrapperRef = React.createRef()
  }

  componentDidUpdate() {
    if (this.props.noResultsComponent) {
      const wrapperElement = this.selectrixWrapperRef.current
      const rsBodyElement = wrapperElement.getElementsByClassName('rs-body')[0]
      const noResultsElement = wrapperElement.getElementsByClassName(
        'rs-custom-no-results'
      )[0]
      if (rsBodyElement && !noResultsElement) {
        const list = wrapperElement
          .getElementsByClassName('rs-body')[0]
          .getElementsByTagName('ul')[0]
        const child = document.createElement('li')
        child.className = 'rs-custom-no-results'
        child.style.display = 'none'
        list.appendChild(child)

        ReactDOM.render(this.props.noResultsComponent, child)
      }
    }
  }

  componentDidMount() {
    if (this.props.noResultsComponent) {
      const wrapperElement = this.selectrixWrapperRef.current
      wrapperElement.addEventListener('keyup', this.onKeyUp)
    }
  }

  componentWillUnmount() {
    const wrapperElement = this.selectrixWrapperRef.current
    wrapperElement.removeEventListener('keyup', this.onKeyUp)
  }
  
  onKeyUp = () => {
    const wrapperElement = this.selectrixWrapperRef.current
    const optionElements = wrapperElement.getElementsByClassName('rs-option')
    const noResultsElement = wrapperElement.getElementsByClassName(
      'rs-custom-no-results'
    )[0]

    noResultsElement &&
      (noResultsElement.style.display =
        !optionElements.length && noResultsElement ? 'block' : 'none')
  }

  onClose = () => {
    this.setState({
      closeHelperStyle: {
        display: 'none'
      }
    })

    this.setFocusOnSearch(false)

    // prevents calling onClose faster then onChange
    setTimeout(this.props.onClose, 0)
  }
 
onChange=(items)=>{
 this.props.isBuildingWrapper &&
  this.props.myHookValue.setSeletectedBuildings(isArray(items)?items.map((m)=>m?.key):[items?.key])
  this.props.onChange(items) 
}
  onOpen = () => {
    this.setState({
      closeHelperStyle: {
        display: 'block'
      }
    })

    this.setFocusOnSearch(true)

    this.props.onOpen()
  }

  // Dirty hack to set focus to Selectrix component's search field when the dropdown is opened
  setFocusOnSearch = (focused) => {
    const wrapperElement = this.selectrixWrapperRef.current
    window.setTimeout(() => {
      const input = wrapperElement.getElementsByClassName('searchable')[0]
      if (input) {
        // Explanation: autocomplete="off" is ignored, but you can change "off" to a random string, like "nope".
        input.setAttribute('autocomplete', 'nope')
        input[focused ? 'focus' : 'blur']()
      }
    }, 0)
  }

  render() {
    const {
      className,
      noResultsComponent,
      isLoading,
      options,
      testName='',
      selectAllButton = false,
      ...props
    } = this.props
    const searchComponentHeight = 28
    const totalItems = selectAllButton ?  options?.length + 1 : options?.length;
    const dropdownHeight =
      options?.length === 0 ? 0 : totalItems * 28 + searchComponentHeight
    const actualHeight = dropdownHeight >= 190 ? 190 : dropdownHeight;
    const placeholder = this.props.placeholder? this.props.placeholder: <TranslateComponent>{"Please Select"}</TranslateComponent>
        return (
      <div
        className={classNames(`selectrix-wrapper ${className}`, {
          'selectrix-wrapper--custom-no-results': noResultsComponent
        })}
        data-testid={`${testName || 'tcselectrix'}_dropdown`}
        ref={this.selectrixWrapperRef}
      >
        <div
          className="selectrix-wrapper__close-helper"
          style={this.state.closeHelperStyle}
        >
          &nbsp;
        </div>

        {/* {isLoading
					? <Spinner className="filterable-select-spinner" />
					: <></> Below code (<Selectrix> code) should be here once loader will be enabled
				} */}
        <Selectrix
          {...props}
          selectAllButton={selectAllButton}
          options={options}
          height={actualHeight}
                    onOpen={this.onOpen}
          onClose={this.onClose}
          onChange={this.onChange}
          placeholder={placeholder}
        />
      </div>
    )
  }
}
export default withMyHook(SelectrixWrapper)
