import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import _get from "lodash/get"
import {
	EditableViewStyled,
	ActionDelete,
	ActionAdd,
	EntryView,
	DeletePointWrapper,
	ChartDeleteOption,
	DeletePointLabel,
	DataEntryViewList,
	RadioButtonWrapper
} from './styles'

// components
import CharacteristicsCurve from './curveChart/index.tsx'
import { RadioGroup } from 'src/components'
import ClientSideTable from 'src/components/Table/clientSideTable'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import translate, { TranslateComponent } from "src/common/translations.tsx"


export const ENTRY_VIEWS = {
	DATA: 0,
	CHART: 1
}

export const CharacteristicCurveEntryView = ({ dialogRef, setFieldValue, values }) => {
	const { xAxis, circuitNumber, curveType: yAxis, characteristicCurvePointList, xAxisRange, yAxisRange, uomX, uomY } = values
	const [t] = useTranslation()
	const xAxisLabel = xAxis && circuitNumber && t(`equipment-setup:CharacteristicsCurves>XAxisParameter>${xAxis}`, { circuitNumber })
	const [selectedEntryView, setSelectedEntryView] = useState(ENTRY_VIEWS.CHART)
	const [selectedPoint, setSelectedPoint] = useState(null)
	const OPTIONS = [
		{
			label: t("equipment-setup:CharacteristicsCurves>EntryView>DataEntryView"),
			value: ENTRY_VIEWS.DATA,
			htmlFor: 'DATA'
		},
		{
			label: t("equipment-setup:CharacteristicsCurves>EntryView>ChartEntryView"),
			value: ENTRY_VIEWS.CHART,
			htmlFor: 'CHART'
		},
	]

	const onChangeEntryView = ({ value }) => {
		setSelectedPoint(null)
		setSelectedEntryView(value)
	}

	const onAddDataEntry = index => {
		// first two points are default and should be fixed on the first and second place
		const newDataEntryIndex = Math.max(index + 1, 2)
		// inserts a new point right after the first two default points or the row when add action was clicked
		setFieldValue("characteristicCurvePointList", [
			...characteristicCurvePointList.slice(0, newDataEntryIndex),
			{
				curveXAxisValue: "",
				curveYAxisValue: ""
			},
			...characteristicCurvePointList.slice(newDataEntryIndex),
		])
	}

	const onDeleteDataEntry = index => {
		const newCharacteristicCurvePointList = [...characteristicCurvePointList]

		newCharacteristicCurvePointList.splice(index, 1)

		setFieldValue("characteristicCurvePointList", [
			...newCharacteristicCurvePointList
		])
	}

	const onBlurXAxisValue = (event, { formik }) => {
		const { value } = event.target
		const { xAxisRange: { from, to } } = values
		const parsedValue = parseFloat(value)

		if (isNaN(parsedValue)) {
			return
		}

		formik?.setFieldValue("xAxisRange", {
			from: Math.min(parsedValue, from),
			to: Math.max(parsedValue, to),
		}, false)
	}

	const onBlurYAxisValue = (event, { formik }) => {
		const { value } = event.target
		const { yAxisRange: { from, to } } = values
		const parsedValue = parseFloat(value)

		if (isNaN(parsedValue)) {
			return
		}

		formik?.setFieldValue("yAxisRange", {
			from: Math.min(parsedValue, from),
			to: Math.max(parsedValue, to),
		}, false)
	}

	const headings = [
		{
		  name: 'titleIndex',
		  title: translate('Point'),
		  key: 'titleIndex',
		  maxWidth: '50px',
		  canSort: false
		},
		{
		  name: 'xAxisInput',
		  title: translate('X Axis'),
		  key: 'xAxisInput',
		  maxWidth: '120px',
		  canSort: false
		},
		{
		  name: 'yAxisInput',
		  title: translate(`${yAxis ? yAxis : 'Y Axis'}`),
		  key: 'yAxisInput',
		  maxWidth: '120px',
		  canSort: false
		},
		{
		  name: 'action',
		  title: '',
		  key: 'action',
		  maxWidth: '100px',
		  canSort: false
		}
	  ]

	const isDeleteIconEnabled = selectedEntryView == ENTRY_VIEWS.CHART && selectedPoint !== null && characteristicCurvePointList.length > 2

	const handleInputChange = (key, value) => {
		setSelectedPoint(null)
		setSelectedEntryView(value)
	}

	const nameColumnComponent = (item, index) => {
		return (
			<>{index+1}</>
		)
	}

	const xAxisLabelComponent = (item, index) => {
		return (
			<EditableViewStyled
					debounceTimeout={0}
					labelName="X"
					name={`characteristicCurvePointList.${index}.curveXAxisValue`}
					onBlur={onBlurXAxisValue}
					type={EditableView.COMPONENT_TYPE.INPUT}
					inputType={EditableView.INPUT_TYPE.NUMBER}
					value={characteristicCurvePointList[index].curveXAxisValue}
				/>
		)
	}
	const yAxisLabelComponent = (item, index) => {
		return (
			<EditableViewStyled
					debounceTimeout={0}
					labelName="Y"
					name={`characteristicCurvePointList.${index}.curveYAxisValue`}
					onBlur={onBlurYAxisValue}
					type={EditableView.COMPONENT_TYPE.INPUT}
					inputType={EditableView.INPUT_TYPE.NUMBER}
					value={characteristicCurvePointList[index].curveYAxisValue}
				/>
		)
	}

	const actionComponent = (item, index) => {
		const listLength = characteristicCurvePointList.length
				const isAddingDisabled = listLength >= 20
				const isDeletingDisabled = listLength < 3 && index - 1 < 2

				return (<>
					<ConditionalTooltip
						type={ConditionalTooltip.TYPE.ICON}
						align={ConditionalTooltip.ALIGN.CENTER}
						content={isDeletingDisabled ? null : <TranslateComponent>Remove this Data Point</TranslateComponent>}
						renderNode={_get(dialogRef, "current.el", null)}
					>
						<ActionDelete
							className="icon-button icon-delete-circle"
							disabled={isDeletingDisabled}
							type="button"
							onClick={() => onDeleteDataEntry(index)}
						/>
					</ConditionalTooltip>
					<ConditionalTooltip
						type={ConditionalTooltip.TYPE.ICON}
						align={ConditionalTooltip.ALIGN.CENTER}
						content={isAddingDisabled ? null : <TranslateComponent>Add another Data Point</TranslateComponent>}
						renderNode={_get(dialogRef, "current.el", null)}
					>
						<ActionAdd
							className="icon-button icon-addcircle"
							disabled={isAddingDisabled}
							type="button"
							onClick={() => onAddDataEntry(index)}
						/>
					</ConditionalTooltip>
				</>)
	}

	const onPointSelection = (point) => {
		const selectedPointIndex = characteristicCurvePointList
									?.findIndex(d => d.curveXAxisValue===point.curveXAxisValue && d.curveYAxisValue===point.curveYAxisValue)
		setSelectedPoint(selectedPointIndex)
	} 
	const onPointsUpdate = (points) => {
		const numberOfPoints = points?.length || 0
		if (numberOfPoints < 20 && numberOfPoints !== 0) {
			const dataPoints = numberOfPoints > 1 ? [...points] : [points[0], ...characteristicCurvePointList.slice(1)]
			setFieldValue("characteristicCurvePointList", dataPoints)
		}
	} 

	return (
		<EntryView className="rx-grid rx-direction-column rx-align-center rx-grid-bleed rx-no-wrap">
			<div style={{ display: "flex" }}>
				<RadioButtonWrapper>
					<RadioGroup
					label=''
					handleSelection={({
						target: { value, name }
					}) => {
						handleInputChange(name, value)
					}}
					radioKey="viewSelection"
					options={OPTIONS}
					selectedValue={OPTIONS.find(option => option.value == selectedEntryView)?.value}
					/>
				</RadioButtonWrapper>
				<DeletePointWrapper>
					{isDeleteIconEnabled && <ChartDeleteOption
							className="icon-button icon-delete-circle delete-curve"
							type="button"
							onClick={() => {
								onDeleteDataEntry(selectedPoint)
								setSelectedPoint(null)
							}}
					    >
						<DeletePointLabel>{t("equipment-setup:CharacteristicsCurves>EntryView>DeletePoint")}</DeletePointLabel>
                        </ChartDeleteOption>
				}
				</DeletePointWrapper>
			</div>
			{
				selectedEntryView == ENTRY_VIEWS.DATA
					? <DataEntryViewList className="rx-col-auto">
						<ClientSideTable
							header={headings}
							search={false}
							rows={characteristicCurvePointList.map((item, index) => {
								return {
								...item,
								titleIndex: nameColumnComponent(item, index),
								xAxisInput: xAxisLabelComponent(item, index),
								yAxisInput: yAxisLabelComponent(item, index),
								action: actionComponent(item, index),
								}
							})}
							fixedLayout={false}
						/>
       				</DataEntryViewList>
					: <div className="rx-col-grow">
						<CharacteristicsCurve 
							onPointSelection={onPointSelection}
							characteristicCurvePointList={characteristicCurvePointList}
							onPointsUpdate={onPointsUpdate}
							xAxisRange={xAxisRange}
							yAxisRange={yAxisRange}
							uomY={uomY}
						/>
       				</div>
			}
		</EntryView>
	)
}

CharacteristicCurveEntryView.propTypes = {
	dialogRef: PropTypes.object,
	setFieldValue: PropTypes.func,
	values: PropTypes.object,
}

export default CharacteristicCurveEntryView
