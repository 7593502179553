import { ReactNode } from 'react'
import { TranslateComponent } from 'src/common/translations'
import { getColor } from '../helpers'
import { Tooltip, TooltipTrigger, TooltipContent } from '../Tooltip/Tooltip'
import styles from './user-name-bubble.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from 'src/denali-components/lib/pro-solid-svg-icons'

interface UserNameBubbleProps {
  firstName: string
  lastName: string
  subLine?: ReactNode
  showFullName: boolean
  onDark?: boolean
  isSmall?: boolean
  iconOnly?: boolean
  showTooltip?: boolean
  tooltipContent?: string
  assignedTo?: boolean
}

export const UserNameBubble = ({
  firstName,
  lastName,
  subLine,
  showFullName,
  onDark,
  isSmall,
  iconOnly,
  showTooltip,
  tooltipContent,
  assignedTo
}: UserNameBubbleProps) => {
  const initials = getInitials(firstName, lastName);
  const color = getColor(initials);

  return (
    <div
      className={`${styles.userNameBubble} ${onDark && styles.onDark} ${
        isSmall && styles.isSmall} ${iconOnly && styles.iconOnly
      }`}
    >
      {showTooltip ? (
        <Tooltip>
          <TooltipTrigger testName="userNameBubbleTrigger">
            <div className={styles.icon} data-color={color}>
              {initials}
              {assignedTo && <div className={styles.assignedTo}>
                <FontAwesomeIcon icon={faLink} />
              </div>}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>{tooltipContent ? tooltipContent : 'Contact'}</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      ) : (
        <div className={styles.icon} data-color={color}>
          {initials}
        </div>
      )}
      {showFullName && (
        <div className={styles.text}>
          <div className={styles.name}>{`${firstName} ${lastName}`}</div>
          {subLine && ( <div className={styles.subLine}>{subLine}</div> )}
        </div>
      )}
    </div>
  )
}

const getInitials = (firstName: string, lastName: string) => {
  if (!lastName && firstName.includes('@')) {
    return firstName.toUpperCase().split('.').map((part) => part[0]).join('').slice(0, 2);
  } else if (!lastName) {
    return firstName.toUpperCase().split(' ').map((part) => part[0]).join('').slice(0, 2);
  }

  return `${firstName[0]}${lastName[0]}`;
}
