import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import ImageGalleryWrapper, {
  ImageGalleryItem
} from 'src/components/legacy/components/image-gallery-wrapper'

import 'react-image-gallery/styles/scss/image-gallery.scss'
import { ImagesFindingsSectionWrapper } from '../../styles'
import { useMemo } from 'react'

const renderFindingDescription = (finding, t) => (
  <span className="image-gallery-description">
    {finding?.images.length ? (
      <p>
        <strong>{_get(finding, 'images[0].title', '')}</strong>
        &nbsp;
        {_get(finding, 'images[0].description', '')}
      </p>
    ) : null}
    <p>
      <strong>{t('opportunities:ViewFinding')}:</strong>
      &nbsp;
      <Link to={`/findings/${finding.findingId}`} className="finding-link">
        {finding.name}
      </Link>
    </p>
  </span>
)

const ImagesFindingsSection = ({
  images,
  findings = [],
  isLazyLoading = false,
  handleDownloadImage = null,
  uiMode
}) => {
  const { t } = useTranslation()
  
  const items = useMemo(() => {
    return [
    ...findings.map((finding, i) => ({
      renderItem: () => (
        <ImageGalleryItem
          key={i}
          item={{
            original: finding?.original,
            originalTitle: finding.name
          }}
          renderDescription={renderFindingDescription(finding, t)}
        />
      )
    })),
    ...images.map(({ originalTitle, description, original, name, buildingId }) => ({
      original,
      originalTitle,
      description,
      name,
      buildingId
    }))
  ]},[images,findings])

  return (
    <ImagesFindingsSectionWrapper>
      <ImageGalleryWrapper
        items={items}
        showPlayButton={false}
        showBullets={true}
        showThumbnails={false}
        className="images-finding-section"
        isLazyLoading={isLazyLoading}
        handleDownloadImage={handleDownloadImage}
      />
    </ImagesFindingsSectionWrapper>
  )
}

ImagesFindingsSection.propTypes = {
  images: PropTypes.array,
  findings: PropTypes.array
}

ImagesFindingsSection.defaultProps = {
  showImagesSection: true,
  findings: []
}

export default ImagesFindingsSection
