import { useEffect, useState, FunctionComponent } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import {
  GET_BUILDING_DETAILS,
  getTestSettingsSummary,
  getSuggestionByTestName
} from './graphql'
import { useQuery } from 'src/hooks/APIHooks'
import { AutomatedSettingsListContainer } from './style'
import _isEmpty from 'lodash/isEmpty'
import translate,{TranslateComponent} from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { formatSettingSummary } from './util'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Table from 'src/components/Table/clientSideTable'
import { AutomatedTestSettingsModal } from './AutomatedSettingsModal'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setAutomatedTestHistory } from 'src/redux/slicers/buildingPicker'
import { groupByPropertykey } from 'src/pages/buildingData/util'
import moment from 'moment'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'

import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useAppDispatch } from 'src/redux/store'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import store from 'src/redux/store';
import { siUnitMapping, convertUnits, roundOffConvertedValues } from 'src/common/UOMConversions'

export const parameterScope = {
  G: 'Global',
  O: 'Organization',
  B: 'Building',
  E: 'Equipment'
}

const dailyRuntimeDays = {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday'
}

const searchFields = ['displayUIName', 'updatedBy', 'equipmentTypeUIName']

const AutomatedTestSettingsList = ({
  uniqEquipmentGroups = [],
  selectedEquipment,
  loadingContainer=false,
  equipmentsList = [],
  buildingsList = []
}) => {
  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const userSelectedUOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"

  const { buildingId, equipment } = getSearchParams()
  const navigate = useNavigate()

  const [loadingSummary, setLoadingSummary] = useState(false)

  const [loadingSingleTestSettings, setLoadingSingleTestSettings] =
    useState(false)

  const [error, setError] = useState(false)

  const [suggestionTestData, setSuggestionTestData] = useState(null)

  const [showTestSuggestionModal, setShowSuggessionModal] = useState(false)

  // We are storing, all parameters of a selected test, only for Short cycle
  // ATSs - based on Condenser type it varies and have select condenser type button
  // field is cpType
  const [selectedTestAllParameters, setSelectedTestAllParameters] = useState([])

  // Dimension hook to get all Dimensions
  const {
    loadingDimensions,
    allDimensions = [],
    getAllDimensions
  } = useDimensionsHook()

  const [options, setOptions] = useState([])
  const [option, setOption] = useState(0)
  const [showAutomatedTestSettingsModal, setShowAutomatedTestSettingsModal] =
    useState(false)

  const [selectedTest, setSelectedTest] = useState(null)

  const [selectedCpType, setSelectedCpType] = useState(null)

  const [allTestNamesList, setAllTestNamesList] = useState([])

  const [viewportTests, setViewportTests] = useState([])

  const [selectedTestData, setSelectedTestData] = useState(null)

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const activeAutomatedTest = queryParams.get('automatedtest')

  const optionsTranslated = {
    testName: translate('Test Name'),
    equipType: translate('Equipment Type'),
    suppressed: translate('Suppressed'),
    last: translate('Last Updated'),
    updated: translate('Updated By'),
    default: translate('Default Settings'),
    edit: translate('Edit'),
    history: translate('History'),
    analytics: translate('Analytics'),
  }

  const { refetch: refetchAutomatedSettingsSummary } = useQuery({
    query: getTestSettingsSummary,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getATSettings'
  })

  const {
    data: buildingDetails,
    refetch: getBuildingDetails,
    loading: loadingBuildingDetails
  } = useQuery({
    query: GET_BUILDING_DETAILS,
    variables: { id: buildingId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getBuilding'
  })

  const dispatch = useDispatch()

  // Translating searchable columns of Automated Tests 
  const appDispatch = useAppDispatch();
  const [finalSummaryLabels, setFinalSummaryLabels] = useState([])
  const [finalSummaryLabelsToTranslate, setFinalSummaryLabelsToTranslate] = useState([])

  const { translatedJSON } = useSelector((state) => state?.translationCombinedSlice)

  useEffect(() => {
    appDispatch(translateJSON({ json: finalSummaryLabelsToTranslate }));
  }, [finalSummaryLabelsToTranslate])

  useEffect(() => {
    if (translatedJSON) {
      const testList = selectedEquipment ? finalSummaryLabels : allTestNamesList
      const translatedFinalSummaryLabels = testList.map((test) => {
        return ({
          ...test,
          displayUIName: translatedJSON?.[test.displayName],
          equipmentTypeUIName: translatedJSON?.[test.equipmentType],
          updatedBy: test.updatedBy  ==='Analytics' ? optionsTranslated.analytics : test.updatedBy
        })
      })
      if (selectedEquipment) {
        setViewportTests(translatedFinalSummaryLabels)
      } else {
        setAllTestNamesList(translatedFinalSummaryLabels)
        filterAndSetTestsBasedOnBuildingEquipment(translatedFinalSummaryLabels)
      }
    }
  }, [translatedJSON, finalSummaryLabels])

  // End of translating Searchable columns

  const getSettingsByBuilding = async (buildingId) => {
    try {
      if (!equipment) {
        setLoadingSummary(true)
        setError(false)
        const input = {
          req_bid: buildingId,
          req_type: 'st',
          isSummary: 1,
          c_date: moment().locale('en').format(BACKEND_DATE_FORMAT)
        }
        const summaryData = await refetchAutomatedSettingsSummary({
          body: JSON.stringify(input)
        })
        const parsedData = JSON.parse(summaryData)
        const finalSummary = formatSettingSummary(parsedData?.settings || {})

        setAllTestNamesList(finalSummary)

        const translateFinalSummary = finalSummary.reduce((acc, item) => {
          if (item.displayName && !acc.hasOwnProperty(item.displayName)) {
            acc[item.displayName] = item.displayName;
          }
          if (item.equipmentType && !acc.hasOwnProperty(item.equipmentType)) {
            acc[item.equipmentType] = item.equipmentTypeUIName;
          }
          return acc;
        }, {});

        setFinalSummaryLabelsToTranslate(translateFinalSummary)
        // Sets filtered tests to viewport
        filterAndSetTestsBasedOnBuildingEquipment(finalSummary)

        setLoadingSummary(false)
      }
    } catch (e) {
      setLoadingSummary(false)
      setError(true)
    }
  }

  const toggleSuggestionModal = (apiDisplayName, equipmentType) => {
    if (apiDisplayName && typeof apiDisplayName === 'string') {
      setShowSuggessionModal(true)
      const testData = viewportTests?.find(
        (x) =>
          x?.apiDisplayName === apiDisplayName &&
          x?.equipmentType === equipmentType
      )
      setSuggestionTestData({
        testName: testData?.displayName,
        equipmentType: testData?.equipmentType,
        equipmentTypeUIName: testData?.equipmentTypeUIName
      })
    } else {
      setShowSuggessionModal(false)
      setSuggestionTestData(null)
    }
  }

  const getSettingsByEquipment = async (equipmentId, equipmentFamily) => {
    try {
      setLoadingSummary(true)
      setError(false)
      const input = {
        req_bid: buildingId,
        req_eid: equipmentId,
        req_fid: equipmentFamily,
        req_type: 'st',
        c_date: moment().locale('en').format(BACKEND_DATE_FORMAT),
        isSummary: 1
      }
      const summaryData = await refetchAutomatedSettingsSummary({
        body: JSON.stringify(input)
      })
      const parsedData = JSON.parse(summaryData)
      const finalSummary = formatSettingSummary(parsedData?.settings || {})
      setFinalSummaryLabels(finalSummary)

      const translateFinalSummary = finalSummary.reduce((acc, item) => {
        if (item.displayName && !acc.hasOwnProperty(item.displayName)) {
          acc[item.displayName] = item.displayName;
        }
        if (item.equipmentType && !acc.hasOwnProperty(item.equipmentType)) {
          acc[item.equipmentType] = item.equipmentTypeUIName;
        }
        return acc;
      }, {});
      
      setFinalSummaryLabelsToTranslate(translateFinalSummary)

      setLoadingSummary(false)
    } catch (e) {
      setLoadingSummary(false)
      setError(true)
    }
  }

  const refreshSettings = () => {
    if (uniqEquipmentGroups?.length > 0) {
      if (selectedEquipment) {
        getSettingsByEquipment(
          selectedEquipment?.key,
          selectedEquipment?.family
        )
      } else {
        getSettingsByBuilding(buildingId)
      }
    }
  }

  const onChangeCondenserType = (cpType) => {
    const selectedTestState = { ...selectedTest }
    let selectedATValues = selectedTestAllParameters?.atValue.filter(
      (x) => x?.cpType === cpType || !x?.cpType || x?.type === 'DetectionRate'
    )

    const selectedATGlobalValues = selectedTestAllParameters?.gbValue.filter(
      (x) => x?.cpType === cpType || !x?.cpType || x?.type === 'DetectionRate'
    )

    const detectionRateLimits = selectedTestState?.at.filter(
      (x) => x?.type === 'DetectionRate'
    )

    selectedATValues = [...selectedATValues, ...detectionRateLimits]

    setSelectedCpType(cpType)
    setSelectedTest({
      ...selectedTestState,
      at: selectedATValues,
      gt: selectedATGlobalValues
    })
  }

  useEffect(() => {
    getAllDimensions()
  }, [])

  useEffect(() => {
    getBuildingDetails()
  }, [buildingId])

  useEffect(() => {
    setSelectedTest(null)
    setSelectedCpType(null)
    setAllTestNamesList([])
    setViewportTests([])
    refreshSettings()
  }, [selectedEquipment, buildingId, uniqEquipmentGroups])

  const getTooltipText = (globalValue, scopeName = 'G') => {
    let text = ''
    switch (scopeName) {
      case parameterScope.G:
        text =
          'Global Level: This setting uses the global value and has never been changed.'
        break
      case parameterScope.O:
        text =
          'This setting has been changed at the building level, overriding the global setting.'
        text = `${text} (Global Setting: ${globalValue})`
        break
      case parameterScope.B:
        text =
          'Building Level: This setting has been changed at the building level, overriding the global setting.'
        text = `${text} (Global Setting: ${globalValue})`
        break
      case parameterScope.E:
        text =
          'Equipment Level: This setting has been changed at the equipment level, overriding the global and any building settings.'
        text = `${text} (Global Setting: ${globalValue})`
        break
    }
    return text
  }

  const filterAndSetTestsBasedOnBuildingEquipment = (testsSummary) => {
    const allTests = testsSummary || allTestNamesList
    if (allTests?.length > 0 && uniqEquipmentGroups?.length > 0) {
      const allBuildingEquipments = uniqEquipmentGroups?.map((x) => x?.family)

      const filteredTests = allTests?.filter((test) => {
        return allBuildingEquipments?.includes(test?.equipmentType)
      })

      // using localeCompare to sort values of non-English text
      setViewportTests(
        filteredTests?.sort((a, b) => a?.displayName.localeCompare(b?.displayName))
      )
    } else {
      setViewportTests([])
    }
  }

  const formatGlobalTraceSettings = (traceValues) => {
    const formatArray = []
    for (const key in traceValues) {
      const traceItem = traceValues[key]
      formatArray.push({
        ...traceItem
      })
      return formatArray
    }
  }

  const getATConvertedValue = (at) => {
    let keyName = "", val = "" 
    // Determine the key (intValue or floatValue) and its value 
    if (at.hasOwnProperty('intValue')) { 
      keyName = 'intValue'
    } else if (at.hasOwnProperty('floatValue')) { 
      keyName = 'floatValue'
    }

    val = at?.intValue !== undefined ? at?.intValue : at?.floatValue
    let uom = 'No Unit'
    const dimension = allDimensions?.find(
      (x) => x?.dimId === at?.dimID && x?.UoM !== 'dimensionless'
    )
    if (dimension ) {
      uom = dimension.UoM //IP UoM
      if(userSelectedUOM === 'SI') {
        const subType = dimension?.subType
        const siUoM = siUnitMapping.get(subType.toLowerCase())
        if (siUoM) {
          val = roundOffConvertedValues(convertUnits(val, uom, siUoM))
          if (at.boundary && Object.keys(at.boundary)) {
            Object.keys(at.boundary).forEach(key => at.boundary[key] = roundOffConvertedValues(convertUnits(at.boundary[key], uom, siUoM)))
          }
          if (at.range && Object.keys(at.range)) {
            Object.keys(at.range).forEach(key => at.range[key] = roundOffConvertedValues(convertUnits(at.range[key], uom, siUoM)))
          }
          uom = siUoM
        }
      }
    }
    return {keyName, val, uom}
  }

  const getATGlobalValue = (globalParamDetails, at) => {
    let val = globalParamDetails?.intValue || globalParamDetails?.floatValue || ''
    let uom = 'No Unit'
    const dimension = allDimensions?.find(
      (x) => x?.dimId === at?.dimID && x?.UoM !== 'dimensionless'
    )
    if (dimension ) {
      uom = dimension.UoM //IP UoM
      if(userSelectedUOM === 'SI') {
        const subType = dimension?.subType
        const siUoM = siUnitMapping.get(subType.toLowerCase())
        if (siUoM) {
          val = roundOffConvertedValues(convertUnits(val, uom, siUoM))
          uom = siUoM
        }
      }
  }
    return {val, uom}
  }

  const getFormattedSelectedTestDetailsForEdit = (testDetails, testData) => {
    try {
      const parsedData = JSON.parse(testDetails)
      const testKey = Object?.keys(parsedData?.settings)?.[0]
      const splitKey = testKey?.split('|')
      const testSettingsDetails = parsedData?.settings[testKey]

      let automatedTestSettings =
        testSettingsDetails?.atValue?.map((at) => {
          const globalValuesArray = testSettingsDetails?.gbValue || []
          const globalParamDetails = globalValuesArray?.find(
            (x) => x.name === at?.name
          )
          let {val: globalValue, uom} = getATGlobalValue(globalParamDetails, at)
          const paramRange = at?.rg?.split('|')
          if (paramRange?.length > 0) {
            at.range = {
              lower: Number(paramRange?.[0]),
              upper: Number(paramRange?.[1])
            }
          }

          const isNotEditable = at?.is_na === 1 || at?.is_admin === 1

          const multipleDimension = at?.dimID?.split(':') || []
          if (multipleDimension?.length > 1) {
            if (at.type === 'Limits') {
              uom = 'Minutes'
            } else {
              // If it has 2 dimension, it should be always hours and minutes
              uom = 'hourMinutes'
            }
          }

          const {keyName, val: atValue, uom: atUoM} = getATConvertedValue(at)
          const newATValue = keyName === "" ? {} : {[keyName]: atValue}
          return {
            ...at,
            isNotEditable: isNotEditable,
            tUnit: uom === 'hourMinutes' ? 'mins' : '',
            targetUOM: uom === 'Dimensionless' ? 'No unit' : uom,
            tooltipText: getTooltipText(globalValue, parameterScope[at?.stType]),
            ...newATValue
          }
        }) || []

      if (splitKey[1]?.includes('DailyRunTime')) {
        const dailyAnalyticLimits = []
        Object.keys(dailyRuntimeDays)?.map((day) => {
          const currentDayLimit = testSettingsDetails?.atValue?.find(
            (x) => x?.name === day
          )
          if (currentDayLimit) {
            dailyAnalyticLimits?.push({
              ...currentDayLimit,
              tUnit: 'mins',
              dailyRuntimeLimit: true
            })
          }
        })
        automatedTestSettings = [
          ...automatedTestSettings,
          ...dailyAnalyticLimits
        ]
      }

      let globalSettingsValues = testSettingsDetails?.gbValue || []

      // Detection rate is not part of analytics, so isdisplay and display name will be added in UI
      const detectionRateLimits = automatedTestSettings?.filter(
        (x) => x?.type === 'DetectionRate'
      )
      if (detectionRateLimits?.length > 0) {
        const redLimitIndex = automatedTestSettings?.findIndex(
          (x) => x?.name === 'redLimit'
        )
        if (redLimitIndex !== -1) {
          automatedTestSettings[redLimitIndex].displayName = 'Critical Limit'
          automatedTestSettings[redLimitIndex].isDisplay = 1
          automatedTestSettings[redLimitIndex].targetUOM = 'Percent'
        }

        const yellowLimitIndex = automatedTestSettings?.findIndex(
          (x) => x?.name === 'yellowLimit'
        )
        if (yellowLimitIndex !== -1) {
          automatedTestSettings[yellowLimitIndex].displayName =
            'Cautionary Limit'
          automatedTestSettings[yellowLimitIndex].isDisplay = 1
          automatedTestSettings[yellowLimitIndex].targetUOM = 'Percent'
        }

        const redLimitIndexGlobal = globalSettingsValues?.findIndex(
          (x) => x?.name === 'redLimit'
        )
        if (redLimitIndexGlobal !== -1) {
          globalSettingsValues[redLimitIndexGlobal].displayName =
            'Critical Limit'
          globalSettingsValues[redLimitIndexGlobal].isDisplay = 1
          globalSettingsValues[redLimitIndexGlobal].targetUOM = 'Percent'
        }

        const yellowLimitIndexGlobal = globalSettingsValues?.findIndex(
          (x) => x?.name === 'yellowLimit'
        )
        if (yellowLimitIndex !== -1) {
          globalSettingsValues[yellowLimitIndexGlobal].displayName =
            'Cautionary Limit'
          globalSettingsValues[yellowLimitIndexGlobal].isDisplay = 1
          globalSettingsValues[yellowLimitIndexGlobal].targetUOM = 'Percent'
        }
      }

      // if cpType presents - loads scroll as default for the first time

      const isCpTypeNeeded = automatedTestSettings?.some((x) => x?.cpType)

      let availableCondensersType = [] // Not all tests are available for all condenser types, so filter only from response

      if (isCpTypeNeeded) {
        availableCondensersType = groupByPropertykey(
          testSettingsDetails?.atValue,
          'cpType'
        )
          ?.filter((x) => x?.cpType !== 'undefined')
          ?.map((x) => x?.cpType)

        const defaultCpType = availableCondensersType?.[0] || ''

        setSelectedCpType(defaultCpType)

        setSelectedTestAllParameters(testSettingsDetails)
        // CpType param varies only for Limits, add other properties if they don't have cpType
        // Detection rate also same for all the equipment types
        automatedTestSettings = automatedTestSettings?.filter(
          (x) =>
            x?.cpType === defaultCpType ||
            !x?.cpType ||
            x?.type === 'DetectionRate'
        )

        globalSettingsValues = testSettingsDetails?.gbValue?.filter(
          (x) =>
            x?.cpType === defaultCpType ||
            !x?.cpType ||
            x?.type === 'DetectionRate'
        )
      } else {
        setSelectedCpType(null)
      }

      const isTestHasOscillationLimits = automatedTestSettings?.some(
        (x) => x?.subType.includes('Oscillation') && x?.type === 'Limits'
      )

      if (isTestHasOscillationLimits) {
        // Oscillation limit names are same with threshold limits, so not to confuse, added 'Oscillation' in the param name
        const filteredATSettings = automatedTestSettings?.filter(
          (x) => !(x?.subType.includes('Oscillation') && x?.type === 'Limits')
        )
        const oscillationLimitParams = automatedTestSettings
          ?.filter(
            (x) => x?.subType.includes('Oscillation') && x?.type === 'Limits'
          )
          ?.map((x) => {
            return {
              ...x,
              name: `Oscillation-${x?.name}`
            }
          })
        automatedTestSettings = [
          ...filteredATSettings,
          ...oscillationLimitParams
        ]

        const filteredATGlobalSettings = globalSettingsValues?.filter(
          (x) => !(x?.subType.includes('Oscillation') && x?.type === 'Limits')
        )
        const oscillationLimitParamsGlobalValues = globalSettingsValues
          ?.filter(
            (x) => x?.subType.includes('Oscillation') && x?.type === 'Limits'
          )
          ?.map((x) => {
            return {
              ...x,
              name: `Oscillation-${x?.name}`
            }
          })
        globalSettingsValues = [
          ...filteredATGlobalSettings,
          ...oscillationLimitParamsGlobalValues
        ]
      }

      let subTypeLimitGroups = []
      // group limits - one Test produces different exceptions
      // prop name: grp_limits
      // We are prefix  - subtype name for identification, as param name are same
      if (testSettingsDetails?.grp_limits > 0) {
        const limits = automatedTestSettings?.filter(
          (x) => x?.type === 'Limits'
        )
        const subtypeGroups = groupByPropertykey(limits, 'subType')
        subTypeLimitGroups = subtypeGroups?.map((x) => x?.subType)
        automatedTestSettings = automatedTestSettings.map((x) => {
          if (x?.type === 'Limits' && subTypeLimitGroups.includes(x?.subType)) {
            return {
              ...x,
              name: `${x?.subType}-${x?.name}`
            }
          } else {
            return {
              ...x
            }
          }
        })
      }

      const formattedTestDetails = {
        atKey: testKey,
        testDisplayName: splitKey[2]?.replace('XX', ''),
        testName: splitKey[1],
        equipmentType: splitKey[0],
        equipmentTypeUIName: testData?.equipmentTypeUIName,
        isDefault: testData?.isDefault,
        isSuppressed: testData?.isSuppressed,
        OscillationLimitsPresents: isTestHasOscillationLimits,
        subType:
          testSettingsDetails?.atValue?.find((x) => x?.name === 'lowerRedLimit')
            ?.subType || '',
        at: automatedTestSettings,
        gt: globalSettingsValues,
        subTypeLimitGroups: subTypeLimitGroups,
        gbTrace: formatGlobalTraceSettings(testSettingsDetails?.gbTrace),
        availableCondensersType: availableCondensersType
      }

      return formattedTestDetails
    } catch (e) {}
  }

  const getTestDetails = async (
    testForEdit,
    buildingIdentifier,
    equipmentId
  ) => {
    try {
      const testData = testForEdit || selectedTestData

      const bId = buildingIdentifier || buildingId

      const input = {
        req_bid: bId,
        req_eid: equipmentId ? equipmentId : 'NA',
        at_key: testData?.key,
        req_type: 'st',
        c_date: moment().locale('en').format(BACKEND_DATE_FORMAT),
        req_fid: testData?.equipmentType,
        isSummary: 0
      }
      const testDetails = await refetchAutomatedSettingsSummary({
        body: JSON.stringify(input)
      })

      const formattedDetails = getFormattedSelectedTestDetailsForEdit(
        testDetails,
        testData
      )

      return formattedDetails
    } catch (e) {}
  }

  useEffect(() => {
    const selectedTest = viewportTests?.find(
      (x) =>
        x?.displayName?.replace(/^\s+|\s+$/gm, '').replace(/\s+/g, '') ===
        activeAutomatedTest
    )
    if (selectedTest) {
      handleAutomatedTestSettingsEdit(selectedTest)
    }
  }, [viewportTests, activeAutomatedTest])
  
  const handleAutomatedTestSettingsEdit = async (testData) => {
    try {
      trackEvent(USER_EVENTS.BUILDING_DATA.events.CLICK_EDIT_AUTOMATED_TEST)
      setShowAutomatedTestSettingsModal(true)
      setLoadingSingleTestSettings(true)
      setSelectedTestData(testData)
      const testDetails = await getTestDetails(
        testData,
        buildingId,
        selectedEquipment?.key
      )

      setSelectedTest(testDetails)
      setLoadingSingleTestSettings(false)
    } catch (e) {
      console.log(e)
    }
  }

  const toggleSettingsModal = () => {
    setSelectedTest(null)
    setShowAutomatedTestSettingsModal(!showAutomatedTestSettingsModal)
  }

  const TickComponent = ({ showTick }) => {
    return showTick ? (
      <span
        style={{ fontSize: 12, color: 'black', textAlign: 'center' }}
        className="icon icon-check"
      />
    ) : null
  }

  const handleAutomatedTestHistory = () => {
    navigate({
      pathname: '/automated-test-history'
    })
  }

  const nameHeadings = [
    {
      title: optionsTranslated.testName,
      key: 'displayUIName',
      maxWidth: '300px',
      customComponent: (row) => {
        return (
          <div style={{ alignItems: 'center' }}>
            <p>{row?.displayUIName}<button 
              onClick={() =>
                toggleSuggestionModal(row?.apiDisplayName, row?.equipmentType)
              }
              className={'marker-icon icon-infocircle tooltip-container'}
            ></button>
            </p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.equipType,
      key: 'equipmentTypeUIName',
      maxWidth: '100px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{row?.equipmentTypeUIName}</p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.suppressed,
      key: 'isSuppressed',
      maxWidth: '30px',
      customComponent: (row) => {
        return <TickComponent showTick={row?.isSuppressed} />
      }
    },
    {
      title: optionsTranslated.last,
      key: 'updatedAt',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.updated,
      key: 'updatedBy',
      maxWidth: '120px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{row.updatedBy}</p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.default,
      key: 'isDefault',
      maxWidth: '30px',
      customComponent: (row) => {
        return <TickComponent showTick={row?.isDefault} />
      }
    }
  ]

  useEffect(() => {
    if (!_isEmpty(uniqEquipmentGroups)) {
      // Get Automated Tests Equipments Types
      const automatedTestsEquipmentTypes = _sortBy(_uniqBy(viewportTests?.map(e => ({
        equipmentType: e?.equipmentType,
        familyName: e?.equipmentTypeUIName
      })), s => s?.equipmentType)) ?? []
      // Get Equipments Which Is Associated With Automated Tests Data
      const theOptions = uniqEquipmentGroups?.filter(f => automatedTestsEquipmentTypes?.map(m => m?.equipmentType).includes(f?.family)).map((equipment) => {
        return {
          key: equipment.key,
          name: automatedTestsEquipmentTypes?.find(f => f?.equipmentType === equipment.family)?.familyName,
          value: equipment.family
        }
      })
      setOptions(theOptions)
      setOption(option ? 0 : 1)
    }
  }, [uniqEquipmentGroups, viewportTests])

  return loadingSummary || loadingContainer ? (
    <Spinner />
  ) : (
    <AutomatedSettingsListContainer key="settings-container">
      <div className="automated-tests-settings-list">
        {error ? (
          <p style={{ color: 'red', fontSize: 14 }}>
            <TranslateComponent>Something went wrong, please try again later.</TranslateComponent>
          </p>
        ) : (
          <Table
            key={`AutomatedTestSettingsTable-${buildingId} ${option}`}
            header={nameHeadings || []}
            rows={viewportTests || []}
            search={true}
            rowControl={[
              {
                text: optionsTranslated.edit,
                action: (data) => handleAutomatedTestSettingsEdit(data)
              },
              {
                text: optionsTranslated.history,
                action: (e) => {
                  dispatch(setAutomatedTestHistory(e.key))
                  handleAutomatedTestHistory()
                }
              }
            ]}
            tableClassName="dateCapitalize4thChild"
            searchFields={searchFields}
            isTranslateFilters={false}
            filtersList={[
              {
                label: 'All Equipment Types',
                key: 'equipmentType',
                filterName: 'typeFilter',
                id: 'typeSelectorFilter',
                defaultLabel: 'All Equipment Types',
                selectedValue: 'default',
                options: options
              }
            ]}
          />
        )}

        {showTestSuggestionModal && (
          <ShowSuggestionsModal
            suggestionTestData={suggestionTestData}
            toggleSuggestionModal={toggleSuggestionModal}
          />
        )}

        {showAutomatedTestSettingsModal && (
          <AutomatedTestSettingsModal
            key="test-settings-modal"
            buildingDetails={buildingDetails}
            selectedTest={selectedTest}
            toggleSettingsModal={toggleSettingsModal}
            equipmentsList={equipmentsList?.filter(
              (x) => x?.family === selectedTest?.equipmentType
            )}
            onChangeCondenserType={onChangeCondenserType}
            selectedEquipment={selectedEquipment}
            buildingsList={buildingsList}
            refreshSettings={refreshSettings}
            loadingTestDetails={loadingSingleTestSettings}
            selectedCptype={selectedCpType}
            getTestDetails={getTestDetails}
          />
        )}
      </div>
    </AutomatedSettingsListContainer>
  )
}

export default AutomatedTestSettingsList

const ShowSuggestionsModal = ({
  suggestionTestData,
  toggleSuggestionModal
}) => {
  const [suggestionDetails, setSuggestionDetails] = useState({})

  const optionsTranslated = {
    modalDesc: translate(suggestionDetails?.description),
    mostCauses: translate('Most likely causes are:')
  }

  const {
    data: suggestionData,
    refetch: refetchTestSuggestion,
    loading: loadingSuggestion
  } = useQuery({
    query: getSuggestionByTestName,
    disableInitialLoad: false,
    errorPolicy: 'global',
    dataPath: 'data.listExceptionSuggestions.items'
  })

  const getSuggestion = async () => {
    try {
      const variables = {
        filter: {
          equipmentType: { eq: suggestionTestData?.equipmentType },
          testName: { eq: suggestionTestData?.testName }
        }
      }

      const suggestion = await refetchTestSuggestion(variables)
      setSuggestionDetails(JSON.parse(suggestion?.[0]?.suggestion) || {})
    } catch (e) {}
  }

  useEffect(() => {
    if (suggestionTestData) {
      getSuggestion()
    }
  }, [suggestionTestData])

  const suggestionModalConfig = {
    gray: true,
    isHideWhiteBackground: false,
    heading:
    `${translate(suggestionTestData?.equipmentTypeUIName) || ''} - ${
      translate(suggestionTestData?.testName)
    }` || '',
    handleClose: toggleSuggestionModal, 
    buttons: [
      {
        text: translate('Close'),
        handleClick: toggleSuggestionModal,
        type: 'cancel'
      }
    ]
  }

  return (
    <Modal {...suggestionModalConfig}>
      {loadingSuggestion ? (
        <Spinner />
      ) : (
        <div style={{ fontSize: 14 }}>
          {optionsTranslated.modalDesc || ''}
          <p style={{ marginTop: 10 }}>
            <strong>{optionsTranslated.mostCauses}</strong>
          </p>
          <ul>
            {suggestionDetails?.causes?.map((cause) => (
             <li key={cause?.name}><TranslateComponent>{cause?.name || ''}</TranslateComponent></li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  )
}
