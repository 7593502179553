import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import {
  FloorAreaNote,
  UtilityBillContainer,
  TabsWrapper,
  ErrorContainer,
  Error,
  TextWrapper,
  ErrorIcon,
  UtilityBillTotalContainer,
  TabToolbar,
  ChooseBuildingMessage,
  UploadButton,
  ConfirmationText,
  CustomTableCell
} from './styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import Table from '../../components/Table/clientSideTable'
import ActionToolBar from 'src/components/legacy/components/action-toolbar'
import {
  getSearchParams,
  getSearchParamsString
} from './../../common/helperFunctions.js'
import {
  setTotalUtilityData,
  setElectricUtilityData,
  setGasUtilityData,
  setWaterUtilityData,
  getNameHeadingsTotal,
  nameHeadingsElectricBill,
  nameHeadingsGasBill,
  nameHeadingsWaterBill,
  formatDollarLabel,
  denaliFormatDollarLabel,
  getExcelHeaders,
  formatUnitOfMeasurmentNumberFormat
} from './helpers'
import Checkbox from 'src/denali-ui/components/Checkbox'
import { useTranslation } from 'react-i18next'
import {
  GET_UTILITY_BILLING_DATA_BY_LOCATIONID,
  GET_BUILDING_BY_ID,
  UPDATE_BUILDING,
  UPDATE_UTILITY_BILL
} from './graphql'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { GlobalStyle } from 'src/components/legacy/components/global-reset/global-reset'
import UtilityFileUploadModal from './UtilityFileUpload/UtilityFileUploadModal'
import ChartSettingsLink from '../../components/legacy/chartSettingsLink'
import IconSVG from 'src/components/Icon/index.js'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useMemo } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { saveAs } from 'file-saver'
import xlsxPopulate from 'xlsx-populate/browser/xlsx-populate'
import Tooltip from '../../components/legacy/components/tooltip/tooltip'
import { ActionButton } from '../energyIntensity/styles'
import { useNavigate } from 'react-router-dom'
import { translate } from '../../common/translations'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { UtilityBillData as DenaliUtilityBillData } from 'src/denali-pages/UtilityBillData/UtilityBillData'
import { useSelector } from 'react-redux'
import { selectUiMode } from 'src/redux/slicers/appData.ts'
import { UiMode } from 'src/redux/types/AppTypes.ts'
import { AccessControl } from 'src/components/accessControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from "src/denali-components/lib/pro-solid-svg-icons"
import tableStyles from 'src/denali-components/Table/table.module.scss'
import { Tooltip as DenaliTooltip, TooltipContent, TooltipTrigger } from 'src/denali-components/Tooltip/Tooltip'
import { TranslateComponent } from 'src/common/translations'
import { PageError } from 'src/denali-components/PageError/PageError'
import { Dialog } from 'src/denali-components/Dialog/Dialog'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { isUBMEnabled } from 'src/common/featureFlags'

export const UtilityBillData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [urlSearchParams] = useSearchParams()

  const [loadingUtilityBillData, fetchUtilityBillData,  utilityBillData ] = useAPIPagination(
    GET_UTILITY_BILLING_DATA_BY_LOCATIONID,
    'data.listUtilityDataByBuilding'
  )

  const translatedOptions = {
    download : translate("Please select one or more items to download."),
    delete: translate("Please select one or more items to delete.")
  }

  const { organizationId } = getSearchParams()
  const [errors, setErrors] = useState({})
  const [emptyMessage, setEmptyMessage] = useState('No Data to Display')
  const [totalEntries, setTotalEntries] = useState(null)
  const [electricEntries, setElectricEntries] = useState([])
  const [previousSearchText, setPreviousSearchText] = useState('')
  const [gasEntries, setGasEntries] = useState([])
  const [waterEntries, setWaterEntries] = useState([])
  const [showUploadBillModal, setShowUploadBillModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(null)
  const [utilityBillFetchComplete, setUtilityBillFetchComplete] =
    useState(false)
  const [tableControlValues, setTableControlValues] = useState(null)
  const [successNotification, setSuccessNotification] = useState({})
  const [selectedFilterYear, setSelectedFilterYear] = useState('default')
  const [buildingId, setBuildingId] = useState(null)
  const [isFilterChange, setFilterChange] = useState(false);

  const hasUtilityUploadAccess = true

  const searchParams = getSearchParams()
  useEffect(() => {
    if(searchParams)
      setBuildingId(searchParams?.buildingId)
  }, [searchParams, urlSearchParams])

  useEffect(() => {
    if(totalEntries === null) return;
    setUtilityBillFetchComplete(true)
  }, [totalEntries])
  

  const {
    onSubmit: updateUtilityBillData,
    loading: loadingUpdateUtilityMutation
  } = useMutation({
    query: UPDATE_UTILITY_BILL,
    dataPath: 'data.utilityDataUploader',
    errorPolicy: 'global'
  })

  const {
    data: buildingDetails,
    refetch: fetchBuildingDetails,
    loading: buildingDetailsLoading
  } = useQuery({
    query: GET_BUILDING_BY_ID,
    variables: { id: buildingId },
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getBuilding'
  })

  const { onSubmit: onUpdateBuilding } = useMutation({
    query: UPDATE_BUILDING,
    dataPath: 'data.updateBuilding',
    errorPolicy: 'global'
  })

  useEffect(() => {
    if (utilityBillData && utilityBillData.length > 0) {
      const sortedArray = utilityBillData.sort(sorterFunction)
      setTotalEntries(setTotalUtilityData(sortedArray))
      setElectricEntries(setElectricUtilityData(sortedArray))
      setGasEntries(setGasUtilityData(sortedArray))
      setWaterEntries(setWaterUtilityData(sortedArray))
    } else {
      !loadingUtilityBillData && setTotalEntries([])
    }
  }, [utilityBillData, loadingUtilityBillData])

  const refetchBuildingBillData = () => {
    resetAllStateVariables()
    fetchBuildingDetails()
    fetchUtilityBillData({ buildingId: buildingId })
  }

  const closeSuccessNotification = () => {
    setSuccessNotification({})
  }

  const successModalConfig = useMemo(
    () => ({
      heading: 'Success',
      buttons: [
        {
          text: 'Close',
          handleClick: closeSuccessNotification,
          type: 'cancel'
        }
      ],
      handleClose: closeSuccessNotification
    }),
    [successNotification]
  )

  const resetAllStateVariables = () => {
    setUtilityBillFetchComplete(false)
    setTotalEntries(null)
    setElectricEntries([])
    setGasEntries([])
    setWaterEntries([])
    setSelectedRows([])
  }

  useEffect(() => {
    resetAllStateVariables()
    if (buildingId) {
      fetchBuildingDetails({ id: buildingId })
      fetchUtilityBillData({ buildingId })
    } else {
      setUtilityBillFetchComplete(true)
      setEmptyMessage(
        'No data available at the organization level. Please choose a building.'
      )
    }
  }, [buildingId])

  const sorterFunction = (a, b) => {
    if (tableControlValues?.columnOrderDown) {
      if (a.year === b.year) {
        return a.month - b.month
      }
      return a.year > b.year ? 1 : -1
    } else {
      if (a.year === b.year) {
        return b.month - a.month
      }
      return a.year < b.year ? 1 : -1
    }
  }

  const getViewportStartAndEndIndexes = () => {
    try {
      // utility bill data is something special wrt common table
      // we need selection of entire table data for the viewport
      // based on filter and search text - we don't know what is there in viewport actually
      // so having this method to identify them
      if (selectedFilterYear === 'default' && previousSearchText === '') {
        const pageNumber = tableControlValues?.page + 1 || 1
        const rowsToShow = tableControlValues?.paginationCount || 30
        const startIndex = (pageNumber - 1) * rowsToShow
        const endIndex = pageNumber * rowsToShow - 1
        return { startIndex, endIndex }
      } else {
        const newSortedArray = [...totalEntries?.sort(sorterFunction)]
        // filter with search text
        let filterWithSearchText = newSortedArray?.filter((x) => {
          return x?.year.includes(previousSearchText)
        })

        // Filter with selected year
        let filteredData = filterWithSearchText?.filter((da) => {
          if (selectedFilterYear === 'default') {
            return da
          } else {
            return da?.year === selectedFilterYear
          }
        })

        const finalArrayLength = filteredData?.length - 1
        const startIndex =
          newSortedArray?.findIndex(
            (x) =>
              x.year === filteredData[0]?.year &&
              x?.month === filteredData[0]?.month
          ) || 0
        const endIndex =
          newSortedArray?.findIndex(
            (x) =>
              x.year === filteredData[finalArrayLength]?.year &&
              x?.month === filteredData[finalArrayLength]?.month
          ) || 0
        return { startIndex, endIndex }
      }
    } catch (error) {
      return {
        startIndex: 0,
        endIndex: 0
      }
    }
  }

  const actionButtonDisable = useMemo(() => {
    const isAnySelectedRows = totalEntries?.some(
      (x) => x.checkboxCheckedEnabled
    )
    return !isAnySelectedRows
  }, [totalEntries])

  const selectAllCheckBoxStatus = useMemo(() => {
    if (tableControlValues && totalEntries?.length > 0) {
      const newSortedArray = [...totalEntries?.sort(sorterFunction)]
      setTotalEntries(newSortedArray)
      const { startIndex, endIndex } = getViewportStartAndEndIndexes()

      const viewportArrayElements = newSortedArray?.slice(startIndex, endIndex)
      const isAllSelected = viewportArrayElements?.length > 0 && viewportArrayElements?.every(
        (x) => x?.checkboxCheckedEnabled
      )
      let isIntermediateSelected =
        !isAllSelected &&
        viewportArrayElements?.some((x) => x?.checkboxCheckedEnabled)
      const isActionsDisabled = !(isAllSelected || isIntermediateSelected)

      return {
        isAllSelected,
        isIntermediateSelected,
        isActionsDisabled
      }
    } else {
      return {
        isAllSelected: false,
        isIntermediateSelected: false,
        isActionsDisabled: true
      }
    }
  }, [
    tableControlValues,
    utilityBillData,
    selectedFilterYear,
    previousSearchText
  ])

  const updateLocationArea = async ({ variables }) => {
    try {
      if (variables?.floorAreaSquareFeet) {
        const input = {
          floorArea: variables.floorAreaSquareFeet,
          id: variables.locationId
        }
        const updatedBuildingDetails = await onUpdateBuilding({ input })
        if (updatedBuildingDetails) {
          const inputJSON = {
            deleteKeys: [],
            buildingId: buildingId,
            action: 'updatefloorarea',
            fileName: '',
            floorArea: variables?.floorAreaSquareFeet || 0
          }
          const deletedRowResponse = await updateUtilityBillData({
            requestBody: JSON.stringify(inputJSON)
          })
          refetchBuildingBillData()
        }
      }
    } catch (error) {}
  }

  const onTableControlsUpdate = (controlValues) => {
    if (controlValues && controlValues?.columnOrderDown !== undefined) {
      const selectedFilter = controlValues?.filters[0]?.selectedValue
      // clear selected rows if any on change on filter and search
      // As we have pagination, it may lead to mismatch counts if we don't clear selection
      if (
        selectedFilterYear !== selectedFilter ||
        controlValues?.searchText !== previousSearchText
      ) {
        const sortedArray = [...totalEntries]
        const newArray = sortedArray.map((x) => {
          return {
            ...x,
            checkboxCheckedEnabled: false
          }
        })
        setTotalEntries(newArray)
        setPreviousSearchText(controlValues?.searchText || '')
      }

      setSelectedFilterYear(selectedFilter)
      if (
        controlValues?.columnOrderDown !==
          tableControlValues?.columnOrderDown ||
        controlValues?.page !== tableControlValues?.page ||
        controlValues?.paginationCount !== tableControlValues?.paginationCount
      ) {
        setTableControlValues({ ...controlValues })
      }
    }
  }

  const toggleUploadBillShowModal = () => {
    setShowUploadBillModal(!showUploadBillModal)
    trackEvent(
      USER_EVENTS.UTILITY_MANAGEMENT.events.CLICK_UPLOAD_UTILITY_BILL_DATA
    )
  }

  const settingsConfig = {
    heading: 'Chart Settings',
    subHeading: 'Add/Edit Building Area',
    inputHeading: 'Total Building Area',
    inputMessage: ['Do not include non-heated/air-conditioned spaces']
  }

  const selectAllCheckBoxHanlder = (event) => {
    if (tableControlValues?.page !== undefined) {
      const sortedArray = [...totalEntries]
      // page index starts from 0
      const isChecked = event?.currentTarget?.checked || false
      const { startIndex, endIndex } = getViewportStartAndEndIndexes()
      const newArray = sortedArray?.map((x, index) => {
        return {
          ...x,
          // index condition checks currently displaying elements
          checkboxCheckedEnabled:
            index >= startIndex && index <= endIndex
              ? isChecked
                ? true
                : false
              : x?.checkboxCheckedEnabled
        }
      })
      // const sortedArray = newArray.sort(sorterFunction)
      setTotalEntries([...newArray])
      setTableControlValues({ ...tableControlValues })
    }
  }

  const rowCheckboxClickHanlder = (selectedRow) => {
    const totalUtilityBillRows = [...totalEntries]
    const selectedRowIndex = totalUtilityBillRows.findIndex(
      (x) => x.month === selectedRow.month && x.year === selectedRow.year
    )
    totalUtilityBillRows[selectedRowIndex].checkboxCheckedEnabled =
      !totalUtilityBillRows[selectedRowIndex]?.checkboxCheckedEnabled
    setTotalEntries(totalUtilityBillRows)
    setSelectedRows(
      totalUtilityBillRows?.filter((x) => x.checkboxCheckedEnabled === true)
    )
    setTableControlValues({ ...tableControlValues })
  }

  const getHeaderCheckbox = () => (
    <Checkbox
      id="all"
      key="all"
      intermediate={selectAllCheckBoxStatus?.isIntermediateSelected}
      onClick={selectAllCheckBoxHanlder}
      checked={selectAllCheckBoxStatus?.isAllSelected}
    />
  )

  const EuiMessage = () => (
    <Tooltip
      showArrow={false}
      content="EUI and ECI can only be calculated when there are consumption and cost values for this month and the preceding 11 months. If the building has both gas and electric, both gas and electric values must be present. If the building has only gas or only electric, the EUI and ECI can be calculated for the single utility type."
    >
      <span className="icon icon-infocircle" />
    </Tooltip>
  )

  const getECIComponent = (row) => {
    return row?.eci === undefined || row?.eci == 0 ? (
      <CustomTableCell>
        <EuiMessage key={`${row.year}${row.month}ECI`} />
      </CustomTableCell>
    ) : (
      <span>{formatDollarLabel(row?.eci)}</span>
    )
  }

  const getEUIComponent = (row) => {
    return row?.eui === undefined || row?.eui == 0 ? (
      <CustomTableCell>
        <EuiMessage key={`${row.year}${row.month}ECI`} />
      </CustomTableCell>
    ) : (
      <span>{formatUnitOfMeasurmentNumberFormat(row?.eui)}</span>
    )
  }

  const handleDeleteConfirm = async (selectedRowIds) => {
    try {
      const floorArea = buildingDetails?.floorArea || 0

      const inputJSON = {
        deleteKeys: selectedRowIds,
        buildingId: buildingId,
        action: 'delete',
        fileName: '',
        floorArea: floorArea
      }
      const deletedRowResponse = await updateUtilityBillData({
        requestBody: JSON.stringify(inputJSON)
      })
      setDeleteConfirmDialog(null)
      refetchBuildingBillData()
      setSuccessNotification({
        type: 'delete',
        message: `Successfully deleted utility bill data.`
      })
    } catch (error) {
      console.log('delete error')
    }
    trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.DELETE_UTILITY_BILL_DATA)
  }

  const totalSummary = useMemo(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (totalEntries && totalEntries?.length > 0) {
      const totalBill = totalEntries.filter((x) => Number(x?.year) === currentYear)?.map((filteredData) => filteredData?.totalBillAmount)
      const total = totalBill?.reduce((sum, a) => sum + a, 0)
      return total
    } else {
      return 0
    }
  })

  const uploadedYears = useMemo(() => {
    if (totalEntries && totalEntries?.length > 0) {
      const uploadedYears = totalEntries.map((x) => x.year)
      const uniqueYears = [...new Set(uploadedYears)]
      return uniqueYears?.map((x) => ({ name: x, value: x }))
    } else {
      return []
    }
  }, [totalEntries])

  useEffect(()=>{
    setFilterChange(true)
  },[uploadedYears?.length])

  const handleDeleteCancel = () => {
    setDeleteConfirmDialog(null)
  }

  const deleteHandler = (row) => {
    if (actionButtonDisable && !row) {
      return
    }
    let selectedRowIds = []
    if (row?.id !== undefined) {
      selectedRowIds.push(row.id)
    } else {
      //const {startIndex, endIndex } = getViewportStartAndEndIndexes()
      // const viewportArrayElements = totalEntries?.slice(0, endIndex)
      if (tableControlValues?.filters[0]?.selectedValue !== 'default') {
        selectedRowIds = totalEntries
          ?.filter(
            (x) =>
              x?.year === tableControlValues?.filters[0]?.selectedValue &&
              x.checkboxCheckedEnabled
          )
          ?.map((x) => x.id)
      } else {
        selectedRowIds = totalEntries
          ?.filter((x) => x.checkboxCheckedEnabled)
          ?.map((x) => x.id)
      }
    }
    trackEvent(
      USER_EVENTS.UTILITY_MANAGEMENT.events.CLICK_DELETE_UTILITY_BILL_DATA,
      { number: row ? 1 : selectedRowIds?.length }
    )
    setDeleteConfirmDialog({
      title: 'Delete',
      text: (
        <>
          <ConfirmationText>
            You are about to permanently delete{' '}
            {row ? 1 : selectedRowIds?.length} month of utility bill data from{' '}
            {buildingDetails?.name}. This will alter the EUI/ECI charts.
          </ConfirmationText>
          <ConfirmationText>Are you sure?</ConfirmationText>
        </>
      ),
      confirmText: t('common:Yes'),
      cancelText: t('common:No'),
      handleConfirm: () => {
        return handleDeleteConfirm(selectedRowIds)
      },
      handleCancel: handleDeleteCancel
    })
  }

  function getSheetData() {
    const headers = getExcelHeaders()
    const headerDisplayNames = headers.map((x) => x.displayName)
    const data = getSelectedDataForDownload()
    const sheetData = []
    sheetData.push(headerDisplayNames)
    data?.forEach((billData) => {
      const rowData = []
      headers.forEach((header) => {
        let sheetValue = ''
        if (header.propertyName === 'year' || header.propertyName === 'month') {
          sheetValue = String(billData?.[header.propertyName])
        } else {
          sheetValue = billData[header?.billType]?.[header?.propertyName] || ''
        }
        rowData.push(sheetValue)
      })
      sheetData.push(rowData)
    })
    return sheetData
  }

  const getSelectedDataForDownload = () => {
    const selectedDataForDownload = totalEntries
      ?.filter((x) => x?.checkboxCheckedEnabled)
      .map((billData) => {
        const currentBillData = utilityBillData?.find(
          (x) => x.month == billData.month && x.year == billData.year
        )
        return {
          year: billData.year,
          month: billData.month,
          electric: currentBillData?.electricUtilityData,
          gas: currentBillData?.gasUtilityData,
          water: currentBillData?.waterUtilityData
        }
      })
    return selectedDataForDownload || []
  }

  const downloadBillData = () => {
    if (actionButtonDisable) {
      return
    }
    xlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0)
      const sheetData = getSheetData()
      trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.DOWNLOAD_UTILITY_BILL_DATA, {"number of months":sheetData.length-1})
      sheet1
        .cell('A8')
        .value(
          `Columns with darker headers are required. If no fields are fillled out for a given utility type ("Gas"), that utility type will be ignored.`
        )
      sheet1
        .cell('A9')
        .value(
          `The "Year" and "Month" columns should just be numbers (e.g. "2020" and "10"). The "Start Date" and "End Date" columns should be either ISO8601 dates (2020-10-01), or a normal US date format (10/01/2020 or 10-01-2020)`
        )
      sheet1
        .cell('A10')
        .value(
          `Gas Consumption values should be entered in CCF (100 Cubic Feet). For reference: 1 therm = 100 kBtu = 1.037 CCF of Natural Gas`
        )
      sheet1
        .cell('A11')
        .value(
          `Note: Everything above this line is ignored by the importer. Feel free to use the space above this line to add information describing the data for your own purposes. Do not change anything below this line other than adding data beginning on line 15.`
        )
      sheet1.cell('A13').value('UTILITY_BILL_V1')
      sheet1.cell('C13').value('Electric')
      sheet1.cell('U13').value('Gas')
      sheet1.cell('AD13').value('Water')
      sheet1.cell('A15').value(sheetData)
      const range = sheet1.usedRange()
      sheet1.row(15).style('bold', true)
      sheet1.row(15).style('fill', 'BFBFBF')

      range.style('border', true)
      return workbook.outputAsync().then((res) => {
        saveAs(res, `${buildingDetails?.name}-utility-download.xlsx`)
      })
    })
  }

  const getRowDeleteButton = (row) => {
    if(uiMode === UiMode.denali) {
      return (
        <AccessControl id="tc.pages.utility-bill-data.delete">
          <DenaliTooltip>
            <TooltipTrigger testName="deleteUtilityBillDataTrigger">
              <FontAwesomeIcon icon={faTrash} className={tableStyles.trash} onClick={() => deleteHandler(row)} />
              <TooltipContent>
                <TranslateComponent>{'Delete'}</TranslateComponent>
              </TooltipContent>
            </TooltipTrigger>
          </DenaliTooltip>
        </AccessControl>
      )
    }
    return (
      <AccessControl id="tc.pages.utility-bill-data.delete">
        <IconSVG
          key={Math.random()}
          onClick={() => deleteHandler(row)}
          name="trash2"
          color="grey"
          width="15px"
          height="15px"
        />
      </AccessControl>
    )
  }

  const yearFilterLabel = translate('Year')
  const allYearsFilterLabel = translate('All Years')
  const totalTableFilterList = useMemo(() => {
    return [
      {
        key: 'year',
        filterName: 'yearFilter',
        id: 'yearSelectorFilter',
        filterLabel: yearFilterLabel,
        defaultLabel: allYearsFilterLabel,
        selectedValue: 'default',
        options: uploadedYears
      }
    ]
  }, [uploadedYears]);
  
  const navigateToCreateReport = () => {
    const search = getSearchParamsString()
    navigate(`/reports/energy-reports/add?${search}&reportType=EnergyUsageReport`)
  }

  const navigateToViewChart = () => {
    const search = getSearchParamsString()
    navigate(`/energy-intensity?${search}`)
  }

  const actionBarTitle = translate(isUBMEnabled ? 'Utility Bill Upload' : 'Utility Bill Data')

  const uiMode = useSelector(selectUiMode)
  if(uiMode === UiMode.denali) {
    return (
      <>
      <PageError buildingId={buildingId} organizationId={organizationId} message={emptyMessage} />
        <DenaliUtilityBillData
          totalsHeader={getNameHeadingsTotal(
            getHeaderCheckbox,
            getRowDeleteButton,
            getECIComponent,
            getEUIComponent
          )}
          totalEntries={totalEntries || []}
          electricHeader={nameHeadingsElectricBill}
          electricEntries={electricEntries || []}
          electricTableWidth="4500px"
          gasHeader={nameHeadingsGasBill}
          gasEntries={gasEntries}
          gasTableWidth="3000px"
          waterHeader={nameHeadingsWaterBill}
          waterEntries={waterEntries}
          waterTableWidth="2300px"
          filtersList={totalTableFilterList}
          checkboxClick={rowCheckboxClickHanlder}
          onUpdateControlsFunc={onTableControlsUpdate}
          columnOrderDown={
            tableControlValues?.columnOrderDown || false
          }
          listDataIsLoading={
            loadingUtilityBillData
            || buildingDetailsLoading
            || !utilityBillFetchComplete
          }
          deleteHandler={deleteHandler}
          downloadBillData={downloadBillData}
          toggleUploadBillShowModal={toggleUploadBillShowModal}
          actionButtonDisable={actionButtonDisable}
          buildingDetails={buildingDetails}
          buildingId={buildingId}
          hasUtilityUploadAccess={hasUtilityUploadAccess}
          navigateToCreateReport={navigateToCreateReport}
          navigateToViewChart={navigateToViewChart}
          settingsConfig={settingsConfig}
          updateLocationArea={updateLocationArea}
          errors={errors}
          formatDollarLabel={denaliFormatDollarLabel}
          totalSummary={totalSummary}
          uiMode={uiMode}
        />
        <UtilityFileUploadModal
          toggleUploadBillShowModal={toggleUploadBillShowModal}
          showUploadBillModal={showUploadBillModal}
          locationName={buildingDetails?.name}
          buildingId={buildingId}
          refetchBuildingBillData={refetchBuildingBillData}
          setSuccessNotification={setSuccessNotification}
          buildingFloorArea={buildingDetails?.floorArea}
          uiMode={uiMode}
        />
        {deleteConfirmDialog && (
          <DialogConfirm
            onAgree={deleteConfirmDialog?.handleConfirm}
            title={'Delete'}
            onClose={handleDeleteCancel}
          >
            <p>
              You are about to permanently delete{' '}
              {selectedRows.length ? selectedRows.length : '1 '} month(s) of utility bill data from{' '}
              {buildingDetails?.name}. This will alter the EUI/ECI charts.
            </p>
            <p>Are you sure?</p>
          </DialogConfirm>
        )}
        {successNotification?.type !== undefined && (
          <Dialog
            title="Success"
            onClose={closeSuccessNotification}
          >
            <TranslateComponent>{successNotification?.message}</TranslateComponent>
          </Dialog>
        )}
      </>
    )
  }

  return (
    <>
      <GlobalStyle />
      <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {loadingUtilityBillData ||
          (buildingDetailsLoading && utilityBillData !== null) ? (
            <Spinner />
          ) : (
            <UtilityBillContainer>
              <ActionToolBar
                title={actionBarTitle}
                borderBottom="2px solid #666666"
                titleSize="21px"
              >
                <>
                  {Boolean(buildingDetails?.floorArea) ||
                    (buildingId && !buildingDetails?.floorArea && (
                      <FloorAreaNote>
                        The EUI and ECI cannot be calculated because this
                        building does not have a floor area set.{' '}
                        {hasUtilityUploadAccess && (
                          <span>
                            Please
                            <ChartSettingsLink
                              locationId={buildingId}
                              value={buildingDetails?.floorArea}
                              settingsConfig={settingsConfig}
                              updateLocationArea={updateLocationArea}
                              chartView={true}
                            >
                              {' '}
                              add a floor area.
                            </ChartSettingsLink>
                          </span>
                        )}
                      </FloorAreaNote>
                    ))}
                  {Boolean(buildingDetails?.floorArea) && buildingId && (
                    <FloorAreaNote>
                      <TranslateComponent>The EUI and ECI calculations are based on the building's
                      floor area of</TranslateComponent>{' '}
                      {buildingDetails?.floorArea.toLocaleString()} ft².{' '}
                      {hasUtilityUploadAccess && (
                        <span data-testid="utility-floor-area-update-link">
                          <TranslateComponent>The floor area can be updated</TranslateComponent>
                          <ChartSettingsLink
                            chartView={true}
                            settingsConfig={settingsConfig}
                            value={buildingDetails?.floorArea}
                            locationId={buildingId}
                            updateLocationArea={updateLocationArea}
                          >
                            {' '}
                            here.
                          </ChartSettingsLink>
                        </span>
                      )}
                    </FloorAreaNote>
                  )}
                  <ActionButton
                    testName="utility-view-chart-button"
                    className="action medium"
                    onClick={navigateToViewChart}
                  >
                    <TranslateComponent>VIEW CHART</TranslateComponent>
                  </ActionButton>
                  <ActionButton
                    testName="utility-create-report-button"
                    className="action medium"
                    onClick={navigateToCreateReport}
                  >
                    <TranslateComponent>CREATE REPORT</TranslateComponent>
                  </ActionButton>
                </>
              </ActionToolBar>
              <div className="ytd-container">
                <span className="ytd">
                  <span className="tab-total">
                    {errors.totalSummaryError
                      ? '$0.00'
                      : formatDollarLabel(parseFloat(totalSummary))}
                  </span>
                  <TranslateComponent>year to date</TranslateComponent>
                </span>
              </div>
              {utilityBillFetchComplete && (
                <TabsWrapper>
                  <Tabs
                    testName="utilility-bill-data-page"
                    className="tabs"
                    trackChangeTab={(billViewType) =>
                      trackChangeTab(billViewType)
                    }
                    onSelect={(tabNum)=>{
                      if(tabNum === 0) //Total
                      {
                        trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Total"})
                      }
                      else if(tabNum === 1) //Electric
                      {
                        trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Electric"})
                      }
                      else if(tabNum === 2) //Gas
                      {
                        trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Gas"})
                      }
                      else if(tabNum === 3) //Water
                      {
                        trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Water"})
                      }
                  }}
                  >
                    <Tab title="Total">
                      <TabToolbar>
                        <div
                          data-testid="utility-bill-toolbar"
                          className="utility-bill-toolbar"
                        >
                          <AccessControl id="tc.pages.utility-bill-data.delete">
                          {actionButtonDisable ? (
                            <Tooltip
                              content={translatedOptions.delete}
                              showArrow={false}
                            >
                              <IconSVG
                                key={Math.random()}
                                className={`toolbar-icons ${
                                  actionButtonDisable ? 'disabled' : ''
                                }`}
                                onClick={() => deleteHandler()}
                                name="trash2"
                                color="grey"
                                width="15px"
                                height="15px"
                              />
                            </Tooltip>
                          ) : (
                            <IconSVG
                              key={Math.random()}
                              className={`toolbar-icons ${
                                actionButtonDisable ? 'disabled' : ''
                              }`}
                              onClick={() => deleteHandler()}
                              name="trash2"
                              color="grey"
                              width="15px"
                              height="15px"
                            />
                          )}
                          </AccessControl>
                          {actionButtonDisable ? (
                            <Tooltip
                              content={translatedOptions.download}
                              showArrow={false}
                            >
                              <IconSVG
                                key={Math.random()}
                                className={`toolbar-icons ${
                                  actionButtonDisable ? 'disabled' : ''
                                }`}
                                onClick={downloadBillData}
                                name="download"
                                color="grey"
                                width="15px"
                                height="15px"
                              />
                            </Tooltip>
                          ) : (
                            <IconSVG
                              key={Math.random()}
                              className={`toolbar-icons ${
                                actionButtonDisable ? 'disabled' : ''
                              }`}
                              onClick={downloadBillData}
                              name="download"
                              color="grey"
                              width="15px"
                              height="15px"
                            />
                          )}
                          <div>
                            <AccessControl id="tc.pages.utility-bill-data.create">
                              <UploadButton
                                onClick={toggleUploadBillShowModal}
                                disabled={!buildingId}
                                testName="utility-data-upload-button"
                                className="action medium"
                              >
                                UPLOAD DATA
                              </UploadButton>
                            </AccessControl>
                          </div>
                        </div>
                      </TabToolbar>
                      {errors.totalError ? (
                        <ErrorContainer>
                          <Error>
                            <ErrorIcon className="icon icon-error" />
                            <TextWrapper className="text-container">
                              Error loading total data
                            </TextWrapper>
                          </Error>
                        </ErrorContainer>
                      ) : (
                        <UtilityBillTotalContainer>
                          {buildingId ? (
                            <Table
                              testName="utilty-bill-data-list"
                              header={getNameHeadingsTotal(
                                getHeaderCheckbox,
                                getRowDeleteButton,
                                getECIComponent,
                                getEUIComponent
                              )}
                              rows={totalEntries || []}
                              rowCheckbox
                              search={true}
                              filtersList={totalTableFilterList}
                              checkboxClick={rowCheckboxClickHanlder}
                              isShowPagination={false}
                              searchFields={['year']}
                              onUpdateControlsFunc={onTableControlsUpdate}
                              columnOrderDown={
                                tableControlValues?.columnOrderDown || false
                              }
                              tableRowOverflowHidden={false}
                              isFilterChange={isFilterChange}
                            />
                          ) : (
                            <ChooseBuildingMessage>
                              {emptyMessage}
                            </ChooseBuildingMessage>
                          )}
                        </UtilityBillTotalContainer>
                      )}
                    </Tab>
                    <Tab title="Electric">
                      <TabToolbar>
                        <AccessControl id="tc.pages.utility-bill-data.create">
                          <UploadButton
                            onClick={toggleUploadBillShowModal}
                            disabled={!buildingId}
                            className="action medium"
                          >
                            UPLOAD DATA
                          </UploadButton>
                        </AccessControl>
                      </TabToolbar>
                      {errors.totalError ? (
                        <ErrorContainer>
                          <Error>
                            <ErrorIcon className="icon icon-error" />
                            <TextWrapper className="text-container">
                              Error loading total data
                            </TextWrapper>
                          </Error>
                        </ErrorContainer>
                      ) : (
                        <UtilityBillTotalContainer>
                          {buildingId ? (
                            <Table
                              header={nameHeadingsElectricBill}
                              tableWidth="4500px"
                              fixedLayout={false}
                              search={true}
                              filtersList={totalTableFilterList}
                              onUpdateControlsFunc={onTableControlsUpdate}
                              searchFields={['year']}
                              rows={electricEntries}
                              isShowPagination={false}
                              disableTableScroll = {false}
                              isFilterChange={isFilterChange}
                            />
                          ) : (
                            <ChooseBuildingMessage>
                              {emptyMessage}
                            </ChooseBuildingMessage>
                          )}
                        </UtilityBillTotalContainer>
                      )}
                    </Tab>
                    <Tab title="Gas">
                      <TabToolbar>
                        <AccessControl id="tc.pages.utility-bill-data.create">
                          <UploadButton
                            onClick={toggleUploadBillShowModal}
                            disabled={!buildingId}
                            className="action medium"
                          >
                            UPLOAD DATA
                          </UploadButton>
                        </AccessControl>
                      </TabToolbar>
                      {errors.totalError ? (
                        <ErrorContainer>
                          <Error>
                            <ErrorIcon className="icon icon-error" />
                            <TextWrapper className="text-container">
                              Error loading total data
                            </TextWrapper>
                          </Error>
                        </ErrorContainer>
                      ) : (
                        <UtilityBillTotalContainer>
                          {buildingId ? (
                            <Table
                              header={nameHeadingsGasBill}
                              rows={gasEntries}
                              tableWidth="3000px"
                              filtersList={totalTableFilterList}
                              searchFields={['year']}
                              search={true}
                              fixedLayout={false}
                              isShowPagination={false}
                              disableTableScroll = {false}
                              isFilterChange={isFilterChange}
                            />
                          ) : (
                            <ChooseBuildingMessage>
                              {emptyMessage}
                            </ChooseBuildingMessage>
                          )}
                        </UtilityBillTotalContainer>
                      )}
                    </Tab>
                    <Tab title="Water">
                      <TabToolbar>
                        <AccessControl id="tc.pages.utility-bill-data.create">
                          <UploadButton
                            onClick={toggleUploadBillShowModal}
                            disabled={!buildingId}
                            className="action medium"
                          >
                            UPLOAD DATA
                          </UploadButton>
                        </AccessControl>
                      </TabToolbar>
                      {errors.totalError ? (
                        <ErrorContainer>
                          <Error>
                            <ErrorIcon className="icon icon-error" />
                            <TextWrapper className="text-container">
                              Error loading total data
                            </TextWrapper>
                          </Error>
                        </ErrorContainer>
                      ) : (
                        <UtilityBillTotalContainer>
                          {buildingId ? (
                            <Table
                              header={nameHeadingsWaterBill}
                              rows={waterEntries}
                              tableWidth="2300px"
                              searchFields={['year']}
                              fixedLayout={false}
                              filtersList={totalTableFilterList}
                              search={true}
                              isShowPagination={false}
                              disableTableScroll = {false}
                              isFilterChange={isFilterChange}
                            />
                          ) : (
                            <ChooseBuildingMessage>
                              {emptyMessage}
                            </ChooseBuildingMessage>
                          )}
                        </UtilityBillTotalContainer>
                      )}
                    </Tab>
                  </Tabs>
                </TabsWrapper>
              )}
            </UtilityBillContainer>
          )}
        </Content>
      </Container>
      <UtilityFileUploadModal
        toggleUploadBillShowModal={toggleUploadBillShowModal}
        showUploadBillModal={showUploadBillModal}
        locationName={buildingDetails?.name}
        buildingId={buildingId}
        refetchBuildingBillData={refetchBuildingBillData}
        setSuccessNotification={setSuccessNotification}
        buildingFloorArea={buildingDetails?.floorArea}
      />
      <StandardDialogs confirm={deleteConfirmDialog} />
      {successNotification?.type !== undefined && (
        <Modal {...successModalConfig}>
          <ConfirmationText>{successNotification?.message}</ConfirmationText>
        </Modal>
      )}
    </>
  )
}

export default UtilityBillData
