import { useEffect, useState } from "react"
import CurveChart from "./CurveChart"
import { useSelector } from "react-redux"
import { selectUserPreferredUoM } from "src/redux/slicers/appData"

export default function CharacteristicsCurve({
    onPointSelection,
    characteristicCurvePointList,
    onPointsUpdate,
    xAxisRange,
    yAxisRange,
    uomY
}) {
    
    const userSelectedUoM = useSelector(selectUserPreferredUoM)
    const [points, setPoints] = useState([])
    useEffect(() => {
        const points = characteristicCurvePointList?.filter(point => point.curveXAxisValue && point.curveYAxisValue)
                        .map(point => [userSelectedUoM === "SI"? (Number(point.curveXAxisValue).toFixed(2)) : point.curveXAxisValue, userSelectedUoM === "SI"? (Number(point.curveYAxisValue).toFixed(2)) : point.curveYAxisValue ])
        setPoints(points)
    }, [characteristicCurvePointList])
    return <CurveChart 
        onPointSelection={onPointSelection}
        onPointsUpdate={onPointsUpdate}
        dataPoints={points}
        xAxisRange={[xAxisRange.from, xAxisRange.to]}
        yAxisRange={[yAxisRange.from, yAxisRange.to]}
        uomY={uomY}
     />
}