import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
  letter-spacing: 0.24px;
  // font-family: Arial;
  margin: 10px 0px;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  border: 1px solid #999999;
  border-radius: 2px;
`

export const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: ${(props) => props.theme.row.background.even};
  }
  tr:nth-child(odd) {
    background-color: ${(props) => props.theme.row.background.odd};
  }
`

export const TableRow = styled.tr`
  font-size: 12px;
  // font-family: Arial;
  height: ${(props) => props.cellHeight || 'auto'};
  color: ${(props) => props.theme.row.color};
  border-top: 1px solid ${(props) => props.theme.row.border};
  border-bottom: 1px solid ${(props) => props.theme.row.border};
  td.hoverOnly {
    div,
    span,
    button {
      visibility: hidden;
    }
  }
  &:hover {
    background: ${(props) => props.theme.row.hover.background};
    td.hoverOnly {
      div,
      span,
      button {
        visibility: visible;
      }
    }
  }
  td:nth-child(8) {
    text-transform: capitalize;
  }
  // &:last-child {
  //   td:first-child {
  //     border-bottom-left-radius: 8px;
  //   }
  //   td:last-child {
  //     border-bottom-right-radius: 8px;
  //   }
  // }
`
export const TableHead = styled.thead`
  background: ${(props) =>
    props.isError
      ? props.theme.head.error.background
      : props.theme.head.background};
  ${(props) =>
    props.isError &&
    `border-bottom: 2px solid ${props.theme.head.error.border};`}
  color: ${(props) => props.isError && props.theme.head.error.color};
  height: ${(props) => props.cellHeight || 'auto'};
  font-size: 12px;
  text-transform: uppercase;
  // font-family: NeueHaasUnica-Regular;

  // border-top-left-radius: 8px;
`

export const TableHeadCell = styled.th`
  color: ${(props) => props.theme.headCell.color};
  padding: ${(props) =>
    `${props.theme.headCell.spaces.top} ${props.theme.headCell.spaces.right} ${props.theme.headCell.spaces.bottom} 0`};
  cursor: pointer;
  &:first-child {
    padding-left: 8px;
  }
  &:last-child {
    // ${(props) => !props.header && 'border-top-right-radius: 8px;'}
    /* text-align: right; */
    padding-right: 14px;
  }
  vertical-align: middle;
  ${(props) =>
    props.align ? `text-align: ${props.align};` : 'text-align: left;'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TableStyle = styled.table`
  border-collapse: collapse;
  ${(props) =>
    props.fixedLayout || props.fixedLayout === undefined
      ? `table-layout: fixed;`
      : ''}
  border-spacing: 0px;
  border: 1px solid ${(props) => props.theme.border};
  // border-radius: ${(props) =>
    props.header ? '0px 0px 8px 8px' : '8px 8px 8px 8px'};
  width: 100%;
  input {
    height: auto;
  }
  ${(props) =>
    props.isError && `border: 2px solid ${props.theme.error.border};`}
  &:hover tr {
    th.hoverOnlyHeader {
      display: table-cell;
    }
  }
`

export const TableHeadRow = styled.tr`
  border-top-left-radius: 8px;
`

export const TableCell = styled.td`
  color: ${(props) => props.theme.cell.color};
  ${(props) =>
    props.fixedLayout || props.fixedLayout === undefined
      ? ''
      : props.maxWidth
      ? `max-width: ${props.maxWidth};min-width: ${props.maxWidth};`
      : ''}
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top: 1px solid ${(props) => props.theme.cell.border};
  ${(props) =>
    props.align ? `text-align: ${props.align}; ` : 'text-align: left;'};
  padding: 4px 18px 4px 0;

  &:first-child {
    padding-left: 5px;
  }
  &:last-child {
    // text-align: right;
    padding-right: 14px;
    display: flex;
    justify-content: flex-end;
  }
`

export const ArrowContainer = styled.div`
  padding: 0 0 0 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const ArrowSpan = styled.span`
  padding-left: 10px;
`

export const HeaderDiv = styled.div`
  background: ${(props) => props.theme.header.background};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  height: ${(props) => props.cellHeight || 'auto'};
  color: rgb(54, 54, 54);
  width: 100%;
  padding: 0px 14px 0px 14px;
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid ${(props) => props.theme.header.border};
  border-left: 1px solid ${(props) => props.theme.header.border};
  border-right: 1px solid ${(props) => props.theme.header.border};
`
