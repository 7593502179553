export const dark = {
  background: '{colors.white}',
  borderColor: '{colors.neutral.20}',
  color: '{colors.neutral.90}',
  notesColor: '{colors.neutral.50}',
  icon: '{colors.neutral.70}',

  energySavings: {
    background: '{colors.gradients.multi-color.20}',
    color: '{colors.white}',
    icon: '{colors.light.16}',
    shadow: '{colors.pink.shadow}'
  }
};

export const light = dark;