export const CREATE_EQUIPMENT = `mutation CREATE_EQUIPMENT(
  $input: CreateEquipmentInput!
) {
  createEquipment(input: $input) {
    id
  }
}`

export const UPDATE_EQUIPMENT_ASSOCIATION = `mutation UPDATE_EQUIPMENT_ASSOCIATION(
    $input: UpdateEquipmentInput!
) {
    updateEquipment(input: $input) {
        id
        parentId
    }
  }`

//delete equipment mutation
export const MARK_EQUIPMENT_AS_DELETED = `mutation MARK_EQUIPMENT_AS_DELETED(
    $body: AWSJSON
  ) {
    markEquipmentAsDeleted(body: $body)
  }`

// copy characterstics DataMapping mutation

export const COPY_CHARACTER_DATA_MAP = `mutation COPY_CHARACTER_DATA_MAP(
     $input:AWSJSON!
  ) {
    copyEquipmentDataMapping(body: $input)
  }`

export const CREATE_EQUIPMENT_PROPERTY = `mutation CREATE_EQUIPMENT_PROPERTY(
    $input:CreateEquipmentPropertyInput!
 ) {
  createEquipmentProperty(input: $input) {
    id
    equipmentId
    buildingId
  }
 }`
export const UPDATE_EQUIPMENT_PROPERTY = `mutation UPDATE_EQUIPMENT_PROPERTY(
  $input:UpdateEquipmentPropertyInput!
) {
  updateEquipmentProperty(input: $input) {
  id
  tisDisplayName
  value
}
}`

//update equipment name mutation
export const UPDATE_EQUIPMENT = `mutation UPDATE_EQUIPMENT(
  $input:  UpdateEquipmentInput!
) {
  updateEquipment(input: $input) {
  id
}
}`

export const UPDATE_EQUIPMENT_SYSTEM_PROPERTY_MAPPING = `mutation UPDATE_EQUIPMENT_SYSTEM_PROPERTY_MAPPING(
  $id: ID!
  $sourceDisplayName : String
  $sourceKey : String
  $sourceDataGroup : String
  $sourceMeasureType : String
  $sourceMeasureUnit : String,  
  $sourceEquipmentId : ID
  $sourceDeviceId : ID
  $sourceUri:String
  $value: String
  $creationType : creationTypeEnum
  $subPropertyDisplayName : String,
  $polarityNormal: Boolean

) {
  updateEquipmentProperty(input: {id: $id , sourceDisplayName: $sourceDisplayName, sourceKey: $sourceKey,sourceUri : $sourceUri, creationType: $creationType,
   sourceDataGroup: $sourceDataGroup, sourceMeasureType: $sourceMeasureType, sourceMeasureUnit: $sourceMeasureUnit ,
     sourceEquipmentId : $sourceEquipmentId, sourceDeviceId : $sourceDeviceId, value : $value, subPropertyDisplayName : $subPropertyDisplayName, polarityNormal: $polarityNormal}) {
    id
  }
}`

export const UPDATE_EQUIPMENT_CONSTANT_PROPERTY_MAPPING = `mutation UPDATE_EQUIPMENT_CONSTANT_PROPERTY_MAPPING(
  $id: ID!
  $creationType : creationTypeEnum
  $lvalue: String
  $sourceEquipmentId : ID
  $sourceDisplayName : String
  $sourceKey : String
  $sourceUri:String 
  $sourceDataGroup : String
  $sourceMeasureType : String
  $sourceMeasureUnit : String, 

) {
  updateEquipmentProperty(
  input: {
    id: $id, 
    creationType: $creationType,
    value: $lvalue, 
    sourceEquipmentId : $sourceEquipmentId, 
    sourceDisplayName: $sourceDisplayName, 
    sourceKey: $sourceKey,
    sourceUri : $sourceUri,
    sourceDataGroup: $sourceDataGroup,
    sourceMeasureType: $sourceMeasureType,
    sourceMeasureUnit: $sourceMeasureUnit
  }) {
    id
  }
}`

export const RESET_DATA_MAPPING = `mutation RESET_DATA_MAPPING(
  $input:AWSJSON!
) {
  resetDataMapping(body: $input)
}`

export const CREATE_CHARACTERISTIC_CURVE = `
  mutation createCharacteristicCurve($input: CreateCharacteristicCurveInput!) {
    createCharacteristicCurve(input: $input) {
      id
    }
  }
`
export const UPDATE_CHARACTERISTIC_CURVE = `
  mutation updateCharacteristicCurve($input: UpdateCharacteristicCurveInput!) {
    updateCharacteristicCurve(input: $input) {
      id
    }
  }
`
