import React, { useState, useCallback, useEffect } from 'react'
import PropTypes, { any, func, number, object, string } from 'prop-types'
import Modal from 'src/components/legacy/components/modal/modal'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import Select from 'src/components/legacy/components/select/select.jsx'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { DIALOG_TYPES } from 'src/components/legacy/components/standard-dialogs'
import { DialogChartSettings } from 'src/denali-components/Dialog/DialogChartSettings'
import { UiMode } from 'src/redux/types/AppTypes'

import {
  ChartSettingsContainer,
  InputMessage,
  SectionHeading,
  InputHeading,
  Unit,
  InputRow,
  InputField,
  Section,
  SectionName,
  InputContainer,
  Error,
  StyledAnchor
} from './styles'
import { TranslateComponent } from '../../../common/translations'

const ChartSettingsLink = ({
  value,
  locationId,
  children,
  settingsConfig,
  updateLocationArea,
  setEuiError,
  setEciError,
  chartView,
  updateSettingsConfig,
  uiMode
}) => {
  const floorAreaUnitOptions = { 'ft²': 'ft²', 'm²': 'm²' }
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen])
  const [saving, setSaving] = useState(false)
  const [floorArea, setFloorArea] = useState('')
  const [selectedUOM, setSelectedUOM] = useState('ft²')
  const [config, updateConfig] = useState(settingsConfig)
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  useEffect(() => {
    updateConfig(settingsConfig)
  }, [settingsConfig])

  useEffect(() => {
    setFloorArea(value)
  }, [value])

  const setDefaults = () => {
    setSelectedUOM('ft²')
    updateUOM('ft²')
    toggleModal()
  }

  const toggleErrorDialog = () => {
    setShowErrorDialog(!showErrorDialog)
  }

  const toggleNotificationDialog = () => {
    setShowSuccessDialog(!showSuccessDialog)
  }

  const updateFloorArea = (value) => {
    const inputValue = parseFloat(value)
    const newValue = inputValue > 0 ? inputValue : 0
    if (newValue) {
      setFloorArea(newValue)
    }
  }

  const updateUOM = (selection) => {
    if (selection !== selectedUOM) {
      switch (selection) {
        case 'm²':
          setFloorArea(floorArea / 10.764)
          break
        case 'ft²':
          setFloorArea(floorArea * 10.764)
          break
      }
      setSelectedUOM(selection)
    }
  }

  const convertSqFt = () => {
    if (selectedUOM === 'm²') {
      return floorArea * 10.764
    } else {
      return floorArea
    }
  }

  const submit = async () => {
    setSaving(true)
    const euiRangeError =
      settingsConfig.chartPage &&
      config &&
      config.sections[0] &&
      config.sections[0].subSections[0] &&
      config.sections[0].subSections[0].fields &&
      config.sections[0].subSections[0].fields.min &&
      config.sections[0].subSections[0].fields.min >
        config.sections[0].subSections[0].fields.max
    const eciRangeError =
      settingsConfig.chartPage &&
      config &&
      config.sections[0] &&
      config.sections[0].subSections[1] &&
      config.sections[0].subSections[1].fields &&
      config.sections[0].subSections[1].fields.min &&
      config.sections[0].subSections[1].fields.min >
        config.sections[0].subSections[1].fields.max
    if (euiRangeError || (eciRangeError && settingsConfig.chartPage)) {
      setEuiError(
        euiRangeError
          ? 'the max value must be greater than the min value.'
          : null
      )
      setEciError(
        eciRangeError
          ? 'the max value must be greater than the min value.'
          : null
      )
      setSaving(false)
    } else {
      if (settingsConfig.chartPage && !chartView) {
        updateSettingsConfig(config)
      }
      if (chartView) {
        try {
          const payload = convertSqFt()
          updateLocationArea({
            variables: {
              floorAreaSquareFeet: payload,
              locationId
            }
          })
        } catch (e) {
          setSaving(false)
          setModalOpen(false)
          ErrorDialog(e)
          return
        }
      }
      setSaving(false)
      setModalOpen(false)
      setShowSuccessDialog(true)
      // Notification({
      // 	title: "Success",
      // 	message: settingsConfig.chartPage ? "Building Floor area and Y Axis has been successfully updated" : "Building Floor area successfully updated"
      // })
    }
  }

  const modalConfig = {
    gray: true,
    headingIcon: 'icon icon-gear2 grey-icon',
    heading:
      settingsConfig && settingsConfig.heading
        ? settingsConfig.heading
        : 'Settings',
    className: 'chart-settings-modal',
    buttons: [
      {
        text: <TranslateComponent>SAVE</TranslateComponent>,
        handleClick: submit,
        type: 'submit',
        disabled: !floorArea && chartView
      },
      { text: <TranslateComponent>CANCEL</TranslateComponent>, handleClick: setDefaults, type: 'cancel' }
    ],
    handleClose: setDefaults,
    loading: saving,
    testName: 'utility-bill-modal'
  }

  if (uiMode === UiMode.denali) {
    return (
      <DialogChartSettings
        toggleModal={toggleModal}
        children={children}
        modalOpen={modalOpen}
        chartView={chartView}
        value={value}
        floorArea={floorArea}
        updateFloorArea={updateFloorArea}
        updateUOM={updateUOM}
        floorAreaUnitOptions={floorAreaUnitOptions}
        settingsConfig={settingsConfig}
        setDefaults={setDefaults}
        submit={submit}
      />
    )
  }

  return (
    <ChartSettingsContainer>
      {children ? (
        <StyledAnchor onClick={toggleModal}>{children}</StyledAnchor>
      ) : (
        <StyledAnchor
          onClick={toggleModal}
          className="chart-link icon icon-gear2"
        />
      )}
      {showErrorDialog && (
        <StandardDialogs
          confirm={DIALOG_TYPES.errors}
          onCloseDialog={() => toggleErrorDialog()}
        />
      )}
      {showSuccessDialog && (
        <StandardDialogs
          confirm={DIALOG_TYPES.Notification}
          onCloseDialog={() => toggleNotificationDialog()}
        />
      )}
      {modalOpen && (
        <Modal {...modalConfig}>
          {settingsConfig && settingsConfig.subHeader && chartView && (
            <SectionHeading>{settingsConfig.subHeader}</SectionHeading>
          )}
          <InputHeading>
            {settingsConfig && chartView && settingsConfig.inputHeading
              ? settingsConfig.inputHeading
              : ''}
          </InputHeading>
          {chartView && (
            <InputRow>
              <InputField
                type="number"
                placeholder={value || ''}
                value={floorArea ? floorArea : ''}
                step="any"
                min="0"
                onChange={(e) => updateFloorArea(e.target.value)}
              />
              <Select
                className="unit-select"
                selectedItem={selectedUOM}
                options={floorAreaUnitOptions}
                onChange={updateUOM}
              />
            </InputRow>
          )}

          {settingsConfig && settingsConfig.inputMessage && chartView && (
            <InputMessage>{settingsConfig.inputMessage}</InputMessage>
          )}

          {settingsConfig?.sections?.map((section, i) => (
            <Section key={section.sectionHeader}>
              <SectionHeading className="section-header">
                {section.sectionHeader}
              </SectionHeading>
              {section.subSections.length &&
                section.subSections.map((subSection, j) => (
                  <React.Fragment key={subSection.name}>
                    <InputRow>
                      <SectionName>
                        <p>{subSection.name}: </p>
                      </SectionName>
                      <InputContainer>
                        {subSection.fields &&
                          Object.keys(subSection.fields).map((field) => {
                            return (
                              <TextInput
                                key={field}
                                className="field-input rx-col-auto"
                                labelText={field}
                                style={{ position: 'relative', right: '26px' }}
                                type="number"
                                min={0}
                                step=".01"
                                value={
                                  config.sections[i].subSections[j].fields[
                                    field
                                  ]
                                    ? config.sections[i].subSections[j]?.fields[
                                        field
                                      ].toString()
                                    : '0'
                                }
                                placeholder={
                                  config.sections[i].subSections[j].fields[
                                    field
                                  ]
                                    ? config.sections[i].subSections[j]?.fields[
                                        field
                                      ].toString()
                                    : '0'
                                }
                                onChange={(e) => {
                                  try {
                                    const dupedConfig = JSON.parse(
                                      JSON.stringify(config)
                                    )
                                    dupedConfig.sections[i].subSections[
                                      j
                                    ].fields[field] = parseFloat(e.target.value)
                                    updateConfig(dupedConfig)
                                  } catch (error) {}
                                }}
                                name={field}
                              />
                            )
                          })}
                      </InputContainer>
                    </InputRow>
                    {subSection.errors ? (
                      <Error>{subSection.errors}</Error>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
            </Section>
          ))}
        </Modal>
      )}
    </ChartSettingsContainer>
  )
}

ChartSettingsLink.propTypes = {
  locationId: PropTypes.oneOfType([number, string]),
  value: any,
  children: any,
  settingsConfig: object,
  updateFloorArea: func,
  updateSettingsConfig: func,
  setEuiError: func,
  setEciError: func
}

export default ChartSettingsLink
