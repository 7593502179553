interface ImageGalleryImageProps {
  item: {
    original: string
    originalAlt: string
    srcSet: string
    sizes: string
    originalTitle: string
  },
  handleError: () => void
}

export const ImageGalleryImage = ({
  item,
  handleError
} : ImageGalleryImageProps) => {

  return (
    <div className="image-gallery-image-wrapper">
      <img
        className="image-gallery-image"
        src={item.original}
        alt={item.originalAlt}
        srcSet={item.srcSet}
        sizes={item.sizes}
        title={item.originalTitle}
        onError={handleError}
      />
    </div>
  )
}