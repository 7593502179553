import styled from 'styled-components'

export const StyledLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
`

export const StyledInfo = styled.div`
  font-size: 12px;
  font-weight: 400;
  height: 26px;
  line-height: 26px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledTitle = styled.h1`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.31px;
  line-height: 24px;
  width: auto;
  margin: 0 0 8px 0;
`

export const StyledDetails = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #999;
  padding-bottom: 20px;
`

export const StyledNoOfferingsDiv = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 800;
  color: #666666;
  padding-bottom: 15px;
`

export const StyledCustomLink = styled.div`
  color: #666;
  font-size: 12px;
  a {
    color: #666;
    text-decoration: underline;
  }
`

export const StyledGeoBlock = styled.div`
  border-bottom: 2px solid #666;
  background-color: #fff;
  border-top: 2px solid #666;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px 8px;
  position: relative;
`

export const ButtonSpacingContainer = styled.div`
  display: inline-flex;
  margin-right: 5px;
`
