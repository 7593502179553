import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import COLORS from 'src/components/legacy/common/colors.json'
import TARGET_TYPE_NAMES from 'src/common/targetTypeNamesIcons'
import IconSvg from 'src/components/Icon/index.js'
import SelectItem from './select-item'
import { TranslateComponent } from 'src/common/translations'

import classNames from 'classnames'
import './select.scss'

const DROPDOWN_TYPE = {
  TOP: 'top',
  BOTTOM: 'bottom'
}

export default class Select extends PureComponent {
  static propTypes = {
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loading: PropTypes.bool,
    selectedItem: PropTypes.node,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    disabledItems: PropTypes.array,
    status: PropTypes.string,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    ignoreOutsideClicksOnIds: PropTypes.array,
    placeholder: PropTypes.string,
    dropDownType: PropTypes.string,
    icon: PropTypes.string,
    selectedItemIcon: PropTypes.string,
    selectedItemEndIcon: PropTypes.string,
    showOptionsIcon: PropTypes.bool,
    search: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    testName: PropTypes.string,
    /** Defines whether to include placeholder to the dropdown option list */
    includePlaceholderOption: PropTypes.bool,
    isUserdefinedOptions: PropTypes.bool
  }

  static defaultProps = {
    options: {},
    selectedItem: '',
    className: '',
    isDisabled: false,
    status: 'active',
    disabledItems: [],
    ignoreOutsideClicksOnIds: [],
    onOpen: () => {},
    onClose: () => {},
    showOptionsIcon: false,
    dropDownType: DROPDOWN_TYPE.BOTTOM,
    loading: false,
    search: false,
    searchPlaceholder: '',
    isUserdefinedOptions: false
  }

  static buildOptions = (optionIterator, keyField, valueField) => {
    if (Array.isArray(optionIterator)) {
      // option => ({[option[keyField]]: option[valueField]})
      return optionIterator.reduce((accum, curr) => {
        if (curr[keyField]) {
          accum[curr[keyField]] = curr[valueField]
        }

        return accum
      }, {})
    } else {
      return Object.keys(optionIterator).reduce((accum, currKey) => {
        const curr = optionIterator[currKey]

        if (curr[keyField]) {
          accum[curr[keyField]] = curr[valueField]
        }

        return accum
      }, {})
    }
  }

  state = {
    isMenuOpen: false,
    rotation: 0,
    searchText: ''
  }

  componentDidMount = () => {
    window.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleOutsideClick)
  }

  closeMenu = () => {
    if (this.state.isMenuOpen) {
      this.toggleMenu(false)
    }
  }

  toggleMenu = (isOpen = null) => {
    if (this.props.isDisabled) {
      return
    }

    this.setState((prevState) => {
      isOpen = typeof isOpen === 'boolean' ? isOpen : !prevState.isMenuOpen

      isOpen ? this.props.onOpen() : this.props.onClose()

      return {
        isMenuOpen: isOpen,
        rotation: isOpen ? 180 : 0,
        searchText: ''
      }
    })
  }

  handleMenuItemClick = (itemKey) => {
    this.props.onChange(itemKey)
    this.closeMenu()
  }

  handleOutsideClick = (e) => {
    if (
      !this.selectElement?.contains(e.target) &&
      !this.props?.ignoreOutsideClicksOnIds?.includes(e.target.id)
    ) {
      this.closeMenu()
    }
  }

  isDisabledItem = (id) => {
    const { disabledItems } = this.props
    return disabledItems.includes(id)
  }

  getItemIcon = (equipmentFamily, selectItem = false) => {
    const icon =
      TARGET_TYPE_NAMES.getType(equipmentFamily).icon === 'other'
        ? 'location'
        : TARGET_TYPE_NAMES.getType(equipmentFamily).icon
    const iconType = `icon-${icon}`

    return (
      <>
        {icon !== 'virtual-meter' ? (
          <span className={`icon ${iconType}`} />
        ) : (
          <IconSvg
            name={icon}
            color={COLORS.TBA_DARKER_GREY}
            hover={COLORS.TBA_DARKER_GREY}
            viewBox="0 0 12 12"
            height="16px"
            width="16px"
            margin={selectItem ? '1px 6px 5px 6px' : '5px 6px 5px 10px'}
          />
        )}
      </>
    )
  }

  onSearchTextChange = (event) => {
    try {
      const text = event.target.value
      this.setState((prevState) => ({
        ...prevState,
        searchText: text
      }))
    } catch (error) {}
  }

  render = () => {
    const {
      options = [],
      selectedItem,
      placeholder,
      includePlaceholderOption,
      className,
      isDisabled,
      dark,
      status,
      dropDownType,
      icon,
      selectedItemIcon,
      selectedItemEndIcon,
      loading,
      testName = '',
      showOptionsIcon,
      searchPlaceholder = '',
      search = false,
      isUserdefinedOptions = false,
      isSelectTranslate=true
    } = this.props
    const { rotation, isMenuOpen, searchText } = this.state

    const isOptionsAsArray = Array.isArray(options)

    const items = !isOptionsAsArray
      ? Object.entries(options)
          .sort((a, b) => (a[1].localeCompare ? a[1].localeCompare(b[1]) : 0))
          .map(([key, description]) => ({ key, description }))
      : options

    const filterItems = items?.filter(
      (x) =>
        x?.value?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        x?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
    )

    return (
      <div
        className={`${dark ? 'dark-select' : ''} ${
          className ? className : ''
        } select-wrapper ${isDisabled ? 'disabled' : ''} ${
          isMenuOpen ? '' : 'open'
        }`}
        ref={(selectElement) => {
          this.selectElement = selectElement
        }}
        data-testid={`${testName || 'tc'}_dropdown`}
      >
        <div
          onClick={this.toggleMenu}
          className={`control ${className} ${
            status === 'error' ? 'error' : ''
          }`}
        >
          {selectedItemIcon && this.getItemIcon(selectedItemIcon)}
          <span
            className={classNames('control-label', {
              'control-placeholder': !selectedItem,
              'with-end-icon': selectedItemEndIcon
            })}
          >
          {
	          isUserdefinedOptions ? (selectedItem || placeholder) :  
              selectedItem ? <span><TranslateComponent>{selectedItem}</TranslateComponent></span> :
              placeholder === '— Select One —' ? <span>— <TranslateComponent>Select One</TranslateComponent> —</span> :
	            <span><TranslateComponent>{placeholder}</TranslateComponent></span>
          }
            {selectedItemEndIcon && (
              <span
                className={`icon ${selectedItemEndIcon} selected-item-end-icon`}
              />
            )}
          </span>
          <span className="control-icon">
            {loading ? (
              <span className="icon icon-loader spin-wheel" />
            ) : (
              <span
                className="select-expand-trigger icon icon-expand"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            )}
          </span>
        </div>
        {isMenuOpen && (
          <div className={`select-dropdown ${dropDownType}`}>
            {includePlaceholderOption && placeholder && (
              <SelectItem
                key={placeholder}
                handleMenuItemClick={this.handleMenuItemClick}
                title={placeholder}
                isUserdefinedOptions={isUserdefinedOptions}
              />
            )}
            {search && (
              <input
                class="input-search-text"
                type="text"
                placeholder={searchPlaceholder}
                onChange={this.onSearchTextChange}
                value={searchText}
              ></input>
            )}
            {filterItems.map(
              ({ key, description, value, type, itemEndIcon }) => (
                <SelectItem
                  key={key}
                  handleMenuItemClick={this.handleMenuItemClick}
                  value={key}
                  title={description || value}
                  getItemIcon={this.getItemIcon}
                  showOptionsIcon={showOptionsIcon}
                  type={type}
                  isDisabled={this.isDisabledItem(key)}
                  itemEndIcon={itemEndIcon}
                  isUserdefinedOptions={isUserdefinedOptions}
                  isSelectTranslate={isSelectTranslate}
                />
              )
            )}
          </div>
        )}
      </div>
    )
  }
}
