import translate, { TranslateComponent } from 'src/common/translations'
import styles from 'src/denali-components/File/file.module.scss'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileLines, faTrash } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Button } from '@aws-amplify/ui-react'
import { useAttachment } from 'src/hooks/attachment'
import { useEffect, useState } from 'react'

interface FileBadgeProps {
  fileId: string,
  file?: object,
  fileFullName?: string,
  name?: string,
  buildingId?: string,
  fileSize?: string,
  description?: string,
  date?: string,
  showDate?: boolean,
  showDescription?: boolean,
  showPreviewButton?: boolean,
  downloadButton?: string,
  deleteFile?: boolean,
  deleteFunc?: any
}

export const FileBadge = ({
  fileId,
  file,
  fileFullName,
  name,
  buildingId,
  fileSize,
  description,
  date,
  showDate,
  showDescription,
  showPreviewButton,
  downloadButton,
  deleteFile,
  deleteFunc
}: FileBadgeProps) => {

  const { downloadFileFunc, previewFileFunc } = useAttachment()
  const [preview, setPreview] = useState<string>('')

  useEffect(() => {
    previewFileFunc(name, buildingId).then((result='') => {
      setPreview(result || '')
    })
  }, [])

  return (
    <div className={styles.fileBadge}>
      <div className={styles.fileContent}>
        {preview.startsWith('blob:') ?
          <img className={styles.filePreview} src={preview} alt={name} />
        :
          <div className={styles.filePreview} data-color={preview}>
            <FontAwesomeIcon icon={getIcon(preview)}/>
          </div>
        }
        <div className={styles.fileInfo}>
          {fileFullName && <TranslateComponent>{fileFullName}</TranslateComponent>}
          <div className={styles.fileDateAndSize}>
            {showDate && <TranslateComponent>{date}</TranslateComponent>}
            {showDate && date && fileSize && ' • '}
            {fileSize && <TranslateComponent><span className={styles.fileSize}>{fileSize}</span></TranslateComponent>}
          </div>
        </div>
        {showDescription && description &&
          <div className={styles.fileDescription}>
            <TranslateComponent>{description}</TranslateComponent>
          </div>
        }
      </div>
      <div className={styles.fileActions}>
        {downloadButton === 'icon' && <Tooltip>
          <TooltipTrigger testName="downloadUtilityBillDataTrigger">
            <Button onClick={() => downloadFileFunc(name, buildingId)} className='button-gray button-icon'>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <TooltipContent>
              <TranslateComponent>Download</TranslateComponent>
            </TooltipContent>
          </TooltipTrigger>
        </Tooltip>}
        {downloadButton === 'text' &&
          <Button onClick={() => downloadFileFunc(name, buildingId)}>Download File</Button>
        }
        {deleteFile && <Button className='button-red button-icon' onClick={() => deleteFunc(fileId, fileFullName)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>}
      </div>
    </div>
  )
}

// Need icons for different file types
const getIcon = (preview) => {
  switch(preview) {
    case 'doc':
      return faFileLines
    default:
      return faFileLines
  }
}