import React, { useState, useEffect } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { createWildCardFilterMultiple, createWildCardFilter } from 'src/components/layouts/Table/wildCardFilter'
import { Table } from './table'

export const GraphQLTable = ({
  filtersList,
  search,
  searchFields,
  header,
  rowControl,
  showSpinner,
  query,
  subData,
  rowCheckbox,
  checkboxClick,
  checkboxChecked,
  rowEndCheckbox,
  checkboxEndClick,
  checkboxEndChecked,
  refetch = false,
  defaultSortColumn = 0,
  columnOrderDown = true,
  isRequiredQueryInputName,
  stringifyFields, // This is for when calling custom lambdas
  isTranslate=false,
  nestedArrayTranslate=false
}) => {
  const [loadTime, setLoadtime] = useState(0)
  const [tableRows, setRows] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currControls, setCurrControls] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const {
    data: data1,
    refetch: refetchData1,
    loading: loadingData1,
    responseTime
  } = useQuery({
    query: query?.query,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: query?.dataPath,
    variables: query?.variables
  })

  const { refetch: refetchData2 } = useQuery({
    query: subData?.query,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data'
  })

  useEffect(() => {
    if (currControls && refetch) {
      setIsLoading(false)
      refetchData1({
        ...currControls
      })
    }
  }, [refetch])

  useEffect(() => {
    const refetchFunc = async () => {
      setIsLoading(false)
      await refetchData1({
        ...currControls
      })
    }
    let timer = setTimeout(() => {
      refetchFunc()
    }, 500)
    return () => clearTimeout(timer)
  }, [currControls])

  useEffect(() => {
    if (data1?.items) {
      setTotalItems(data1.total ? data1.total : data1?.items?.length) //total entites
      const rowsToFormatData = header
        .map((h, i) => (h.formatData || h.formatRowData ? i : -1))
        .filter((h) => h !== -1)
      if (rowsToFormatData?.length) {
        const newItemArr = [...data1.items.filter(e => e !== null) || []]
        for (let i = 0; i < rowsToFormatData.length; i++) {
          for (let j = 0; j < newItemArr.length; j++) {
            if (header[rowsToFormatData[i]].formatData) {
              newItemArr[j][header[rowsToFormatData[i]].key] = header[
                rowsToFormatData[i]
              ].formatData(newItemArr[j][header[rowsToFormatData[i]].key])
            } else if (header[rowsToFormatData[i]].formatRowData) {
              newItemArr[j][header[rowsToFormatData[i]].key] = header[
                rowsToFormatData[i]
              ].formatRowData(newItemArr[j])
            }
          }
        }
        setRows(newItemArr)
      } else {
        setRows([...data1.items]) //total Items
      }
      setLoadtime(responseTime)
    }
  }, [data1])

  const onUpdateControls = (controls) => {
    const searchFilter =
    controls?.searchText?.trim() !== ''
    ? (Array.isArray(searchFields) && searchFields?.length !== 1)
        ? createWildCardFilterMultiple({
          attrNames: searchFields,
          value: controls?.searchText?.trim(),
        })
        : createWildCardFilter({
          attrName: searchFields,
          value: controls?.searchText?.trim() ?? '',
          isRequiredQueryInputName
        })
      : []
      const filtersToApply = controls?.filters?.length
      ? controls.filters.filter(
          (e) => !e.sub && e.selectedValue && e.selectedValue !== 'default'
        )
      : []
    const filtersToUse = filtersToApply
      .map((f) => ({ [f.key]: { eq: f.selectedValue } }))
      .concat(searchFilter)
    if (query?.variables?.filter) {
      filtersToUse.push(query.variables.filter)
    }
    setCurrControls({
      ...(query?.variables || {}),
      filter: filtersToUse?.length
        ? stringifyFields?.filter ? JSON.stringify({
            and: filtersToUse
          }) : {
            and: filtersToUse
          }
        : undefined,
      limit: controls.paginationCount,
      from: controls.page * controls.paginationCount,
      sort: stringifyFields?.sort ? JSON.stringify({
        field: header[controls.activeColumn].key,
        direction: controls.columnOrderDown ? 'asc' : 'desc'
      }) : {
        field: header[controls.activeColumn].key,
        direction: controls.columnOrderDown ? 'asc' : 'desc'
      }
    })
  }

  const SubDataRenderFunc = async (tableControls, row) => {
    const filtersToApply = tableControls?.filters?.length
      ? tableControls.filters.filter(
          (e) => e.sub && e.selectedValue && e.selectedValue !== 'default'
        )
      : []
    const filtersToUse = filtersToApply.map((f) => ({
      [f.key]: { eq: f.selectedValue }
    }))
    let filter = null

    if (subData?.filters) {
      if (subData?.filters?.or) {
        filter = {
          or: subData?.filters?.or
            .map((v) => {
              if (row[v.sourceFilterValue]) {
                return {
                  [v.subFieldName]: { [v.type]: row[v.sourceFilterValue] }
                }
              } else return null
            })
            .filter((e) => e)
        }
      } else if (subData?.filters?.and) {
        filter = {
          and: subData?.filters?.and
            .map((v) => {
              if (row[v.sourceFilterValue]) {
                return {
                  [v.subFieldName]: { [v.type]: row[v.sourceFilterValue] }
                }
              } else return null
            })
            .filter((e) => e)
        }
      } else {
        filter = {
          [subData?.filters.subFieldName]: {
            [subData?.filters.type]: row[subData?.filters.sourceFilterValue]
          }
        }
      }
    }

    const variables = {}
    if (subData?.variables?.length) {
      subData.variables.forEach((v) => {
        variables[v.queryVariableName] = row[v.parentFieldToUse]
      })
    }

    if (filtersToUse?.length) {
      return await refetchData2({
        ...variables,
        filter: { and: [...filtersToUse, filter] }
      })
    } else {
      return await refetchData2({ ...variables, filter })
    }
  }

  const subDataOpenFunction = async (tableControls, row) => {
    await SubDataRenderFunc(tableControls, row).then((result) => {
      const rowIndex = tableRows.findIndex((r) => r.id === row.id)
      const newTableRows = [...tableRows]
      const subDataFormatted = subData?.formatResponseData
        ? subData.formatResponseData(result[Object.keys(result)[0]].items)
        : result[Object.keys(result)[0]].items
      newTableRows[rowIndex] = {
        ...newTableRows[rowIndex],
        subData: subDataFormatted,
        open: true
      }
      setRows(newTableRows)
    })
  }
  return (
    <Table
      rows={tableRows}
      totalItems={totalItems}
      header={header}
      loadTime={loadTime}
      showSpinner={showSpinner && (loadingData1 || isLoading)}
      search={search}
      rowControl={rowControl}
      filtersList={filtersList}
      subDataOpenFunction={subData?.query && subDataOpenFunction}
      onUpdateControls={(controls) => {
        onUpdateControls(controls)
      }}
      rowCheckbox={rowCheckbox}
      checkboxClick={(r) => checkboxClick(r)}
      refetchData={refetchData1}
      checkboxChecked={(e) => {
        if(typeof checkboxChecked === 'function')
          return checkboxChecked(e)
      }}
      rowEndCheckbox={rowEndCheckbox}
      checkboxEndClick={(e) => checkboxEndClick(e)}
      checkboxEndChecked={(r) => checkboxEndChecked(r)}
      activeColumn={defaultSortColumn}
      columnOrderDown={columnOrderDown}
      isTranslate={isTranslate}
      nestedArrayTranslate={nestedArrayTranslate}
    />
  )
}

export default GraphQLTable

Table.propTypes = {}
