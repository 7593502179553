import { SelectField } from '@aws-amplify/ui-react'
import { useCallback } from 'react'
import { TranslateComponent } from 'src/common/translations'

export const DenaliVisibilitySelect = ({
  value,
  onChange,
  className,
  disabled=false
}: {
  value: string
  onChange?: (...event: any[]) => void
  className?: string
  disabled?: boolean
}) => {
  const onChangeHandler = useCallback(
    (event: { target: { value: string } }) => {
      const valAsBoolean = event.target.value === 'true'
      onChange(valAsBoolean)
    },
    [onChange]
  )

  return (
    <SelectField
      className={className ?? ''}
      label={'Visibility'}
      labelHidden
      value={value}
      onChange={onChangeHandler}
      disabled={disabled}
    >
      <option value={'true'}><TranslateComponent>Visible</TranslateComponent></option>
      <option value={'false'}><TranslateComponent>Not Visible</TranslateComponent></option>
    </SelectField>
  )
}
