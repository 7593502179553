import styled, { css } from 'styled-components'
import TARGET_TYPE_NAMES from 'src/common/targetTypeNamesIcons'
import IconSvg from 'src/components/legacy/components/icon-svg/icon'
import COLORS from 'src/components/legacy/common/colors.json'
import { Link } from 'react-router-dom'
import {
  getSelectedPeriodDisplayName,
  aggregationDisplayName
} from 'src/common/chartHelperFunctions'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import moment from 'moment/moment'
import { WidgetsIcons } from 'src/pages/widgets/helpers'
import './styles.scss'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import { WidgetNames } from '../../pages/widgets/helpers'
import {
  TooltipCombineComponent,
  TooltipBoldComponent,
  PropertyComponent
} from './style'
import { bottom } from '@popperjs/core'
import translate, { TranslateComponent } from '../../common/translations'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import store from 'src/redux/store';

export const chartAPIDataTimeFormat = 'YYYY-MM-DD HH:mm'

const hourlyDateFormat = 'hh:mm A MMM DD, YYYY'
const dailyCurrent = 'MMM YYYY'
const quarterly = 'Q1 YYYY'
const daily = 'MMM DD, YYYY'

export const WidgetChartContainer = styled.div`
  width: 280px;
  height: 280px;

  .bar-chart-dimention,
  .bar-chart-dimention-axis-text {
    ${(props) => {
      if (
        props?.widgetType === WidgetNames?.TimeComparisonBarChart &&
        props?.showLegends === true
      ) {
        return css`
          height: calc(100% - 3px);
          width: inherit;
        `
      } else {
        return css`
          height: calc(100% - 67px);
          width: inherit;
        `
      }
    }}
  }

  .group-bar-chart-dimention-axis-text,
  .engery-cost-chart-comp,
  .group-bar-chart-dimention {
    ${(props) => {
      if (
        (props?.widgetType === WidgetNames?.EnergyCost ||
          props?.widgetType === WidgetNames?.EnergyConsumption) &&
        props?.showLegends === true
      ) {
        return css`
          height: calc(100% - 85px);
          width: inherit;
        `
      } else {
        return css`
          height: calc(100% - 64px);
          width: inherit;
        `
      }
    }}
  }

  .bar-chart-dimention-comparison-bar {
    height: calc(100% - 115px);
    width: inherit;
  }
`

const TitleContainer = styled.div`
  display: flex;
  padding: 1px 5px;
  line-height: 1.3;
  align-items: center;
`
const TitleMainContainer = styled.div`
  display: flex;
  padding: 0px 10px;
  line-height: 1.3;
  justify-content: space-between;

  & .icon-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const WidgetNoDataParentContainer = styled.div`
  height: 100%;
  position: relative;
`

const BuildingNameComponent = styled.span`
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
`

const EquipmentNameComponent = styled.span`
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  text-overflow: ellipsis;
  margin-top: -3px;
`

const WidgetChartFooter = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  flex-direction: column;
`

const SelectedPeriodInfo = styled.span`
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // :first-letter {
  //   text-transform: capitalize;
  // }
`

const ChartInformation = styled.span`
  font-weight: 600;
  :first-letter {
    text-transform: capitalize;
  }
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
`

const IconContainer = styled.div`
  text-align: center;
  font-size: 140px;
  opacity: 0.3;
  margin: 10px;
`

const NoDataContentContainer = styled.div`
  position: absolute;
  top: 25%;
  text-align: center;
  font-size: 16px;
  a {
    color: black;
  }
`

const WidgetNoDataContainer = styled.div`
  background: lightgray;
  opacity: 0.2;
  height: 100%;
  .widget-no-data icon {
    font-size: 50px;
  }
`

const AggregationIntervalName = styled.span`
  display: inline-block; 
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`

const intervalDateFormat = (
  selectedTimePeriod,
  isComparision = false,
  aggregatorInterval,
  timeZone,
  isFullMonthYearName = false
) => {
  switch (selectedTimePeriod) {
    case 'current-day':
    case 'past-hour':
    case 'past-24-hours':
    case 'past-12-hours':
    case 'past-4-hours': {
      const lDateFormatByLocale = getDateFormatByLocale(hourlyDateFormat)
      const hourlyDateFormatByLocale = lDateFormatByLocale ? lDateFormatByLocale : hourlyDateFormat
      const { startDate, endDate } = getStartAndEndDates(
        selectedTimePeriod,
        false,
        hourlyDateFormatByLocale,
        timeZone
      )
      return `${startDate} ${isComparision ? 'Vs' : '-'} ${endDate}`
    }
    case 'current-week':
    case 'past-7-days':
    case 'past-14-days':
    case 'past-30-days':
    case 'past-90-days': {
      const lDateFormatByLocale = getDateFormatByLocale(daily)
      const dailydateFormatByLocale = lDateFormatByLocale ? lDateFormatByLocale : daily
      const { startDate, endDate } = getStartAndEndDates(
        selectedTimePeriod,
        false,
        dailydateFormatByLocale,
        timeZone
      )
      return `${startDate} ${isComparision ? 'Vs' : '-'} ${endDate}`
    }
    case 'current-month':
    case 'past-90-days': {
      const isCurrent = selectedTimePeriod.includes('current')
      const { startDate, endDate } = getStartAndEndDates(
        selectedTimePeriod,
        false,
        null,
        timeZone
      )
      const lDateFormatByLocale = getDateFormatByLocale(dailyCurrent)
      const dailyCurrentFormatByLocale = lDateFormatByLocale ? lDateFormatByLocale : dailyCurrent
      if (isCurrent) {
        return `${moment(startDate).format(dailyCurrentFormatByLocale ? dailyCurrentFormatByLocale : dailyCurrent)}`
      } else {
        return `${moment(startDate).format(dailyCurrentFormatByLocale ? dailyCurrentFormatByLocale : dailyCurrent)} ${
          isComparision ? 'Vs' : '-'
        } ${moment(endDate).format(dailyCurrentFormatByLocale ? dailyCurrentFormatByLocale : dailyCurrent)}`
      }
    }
    case 'past-12-months':
    case 'current-quarter':
    case 'current-year': {
      if (aggregatorInterval === 'monthly') {
        const { startDate,endDate } = getStartAndEndDates(
          selectedTimePeriod,
          false,
          null,
          timeZone
        )
        const startMonth = moment(startDate)?.format('MMM')
        const startYear = moment(startDate)?.format('YYYY')
        const endYear = moment(endDate)?.format('YYYY')
        return isFullMonthYearName? `${startMonth} ${startYear} - ${startMonth} ${endYear}` : `${moment(startDate).format('YYYY')}`
      } else {
        const isCurrent = selectedTimePeriod.includes('current-quarter')
        const { startDate, endDate } = getStartAndEndDates(
          selectedTimePeriod,
          false,
          null,
          timeZone
        )
        if (isCurrent) {
          const quarter = moment(startDate)?.quarter()
          const year = moment(startDate).format('YYYY')
          return `Q${quarter}${year}`
        } else {
          const startQuarter = moment(startDate)?.quarter()
          const endQuarter = moment(endDate)?.quarter()
          const startYear = moment(startDate).format('YYYY')
          const endYear = moment(endDate).format('YYYY')
          return `Q${startQuarter} ${startYear} ${
            isComparision ? 'Vs' : '-'
          } Q${endQuarter} ${endYear}`
        }
      }
    }
    default:
      return ''
  }
}

export const WidgetChartTitleComponent = ({
  buildingName,
  equipmentName,
  equipmentType,
  equipmentDetails,
  widgetDescription,
  widgetProperty,
  accountName = ''
}) => {
  const icon =
    TARGET_TYPE_NAMES.getType(equipmentType).icon === 'other'
      ? 'location'
      : TARGET_TYPE_NAMES.getType(equipmentType).icon
  const iconType = `icon-${icon}`

  const showDescription = (widgetDescription) => {
    return widgetDescription
      ?.split('')
      .map((item, index) => (index % 20 === 0 && index !== 0 ? item : item))
      .join('')
  }
  const lDateFormatbyLocale = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'
  return (
    <TitleMainContainer>
      {equipmentDetails &&
        equipmentDetails?.map((equipment, index) => {
          return (
            <TitleContainer key={index}>
              {icon !== 'virtual-meter' ? (
                <span className={`icon ${iconType}`} />
              ) : (
                <IconSvg
                  name={icon}
                  color={COLORS.TBA_DARKER_GREY}
                  hover={COLORS.TBA_DARKER_GREY}
                  viewBox="0 0 12 12"
                  height="14px"
                  width="14px"
                  margin="0px 4px 0px 0px"
                />
              )}
              <ConditionalTooltip
                position={bottom}
                multiline={true}
                width={'205px'}
                content={
                  <TooltipCombineComponent>
                    {equipment?.equipmentName || equipmentName ? (
                      <>
                        <TooltipBoldComponent>
                         <TranslateComponent>{equipment?.equipmentName || equipmentName}</TranslateComponent> 
                        </TooltipBoldComponent>
                        <span>&nbsp;<TranslateComponent>in</TranslateComponent> &nbsp;</span>
                      </>
                    ) : null}

                    {(equipment?.buildingName || buildingName) &&
                    (equipment?.accountName || accountName) ? (
                      <span>
                        {equipment?.buildingName || buildingName} <TranslateComponent>of</TranslateComponent> (
                        {equipment?.accountName || accountName})
                      </span>
                    ) : null}

                    {widgetProperty && (
                      <PropertyComponent>
                        <TooltipBoldComponent><TranslateComponent>Property</TranslateComponent>:</TooltipBoldComponent>
                        <span><TranslateComponent>{widgetProperty}</TranslateComponent></span>
                      </PropertyComponent>
                    )}
                  </TooltipCombineComponent>
                }
              >
                <div
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <BuildingNameComponent
                    className={
                      equipmentDetails?.length > 1 ? 'multipleBuildingName' : ''
                    }
                  >
                    {equipment?.buildingName || buildingName}
                  </BuildingNameComponent>
                  <EquipmentNameComponent
                    className={
                      equipmentDetails?.length > 1
                        ? 'multipleEquipmentName'
                        : ''
                    }
                  >
                    <TranslateComponent>{equipment?.equipmentName || equipmentName}</TranslateComponent>
                  </EquipmentNameComponent>
                </div>
              </ConditionalTooltip>
            </TitleContainer>
          )
        })}
      {(!equipmentDetails || equipmentDetails?.length === 0) && (
        <TitleContainer>
          {icon !== 'virtual-meter' ? (
            <span className={`icon ${iconType}`} />
          ) : (
            <IconSvg
              name={icon}
              color={COLORS.TBA_DARKER_GREY}
              hover={COLORS.TBA_DARKER_GREY}
              viewBox="0 0 12 12"
              height="14px"
              width="14px"
              margin="0px 4px 0px 0px"
            />
          )}
          <ConditionalTooltip
            position={bottom}
            multiline={true}
            width={'205px'}
            content={
              <TooltipCombineComponent>
                {equipmentName ? (
                  <>
                    <TooltipBoldComponent>
                      <TranslateComponent>{( equipmentName)}</TranslateComponent>
                    </TooltipBoldComponent>
                    <span>&nbsp;<TranslateComponent>in</TranslateComponent> &nbsp;</span>
                  </>
                ) : null}

                {buildingName && accountName ? (
                  <span>
                    {buildingName} of ({accountName})
                  </span>
                ) : null}

                {widgetProperty && (
                  <PropertyComponent>
                    <TooltipBoldComponent><TranslateComponent>Property</TranslateComponent>:</TooltipBoldComponent>
                    <span><TranslateComponent>{widgetProperty}</TranslateComponent></span>
                  </PropertyComponent>
                )}
              </TooltipCombineComponent>
            }
          >
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
                <BuildingNameComponent>
                  { buildingName}
                </BuildingNameComponent>
                <EquipmentNameComponent>
                  <TranslateComponent>{equipmentName}</TranslateComponent>
                </EquipmentNameComponent>             
            </div>
          </ConditionalTooltip>
        </TitleContainer>
      )}
      <div className="icon-content">
        <ConditionalTooltip
          type={ConditionalTooltip.TYPE.ICON}
          content={
            <div>
              <p style={{ whiteSpace: 'pre-wrap', width: 150 }}>
                {showDescription(widgetDescription)}
              </p>
              <p><TranslateComponent>Data Updated : </TranslateComponent>{`${moment().format(
                lDateFormatbyLocale ? lDateFormatbyLocale : TEXT_DATE_TIME_FORMAT
              )}`}</p>
            </div>
          }
        >
          <Icon width="15px" height="15px" name="infocircle2" color="#bfbfbf" />
        </ConditionalTooltip>
      </div>
    </TitleMainContainer>
  )
}

export const WidgetChartFooterComponent = ({
  aggregatorName,
  selectedPeriod,
  comparisonBar = false,
  intervalInfo,
  aggragatorInterval = '',
  footerInfo,
  timeZone,
  isDefaultForText = false,
  isFullMonthYearName = false
}) => {
  const firstWord = `${comparisonBar ? '' : aggragatorInterval}`
  let combineText = ` ${ firstWord && (firstWord.charAt(0).toUpperCase() + firstWord.slice(1))} ${aggregationDisplayName[aggregatorName] || ''} ${comparisonBar || isDefaultForText ? 'for' : 'of'} ${getSelectedPeriodDisplayName(selectedPeriod) || ''}`
  const firstWordFooterInfoCapitalize = `${footerInfo && footerInfo.charAt(0).toUpperCase() + footerInfo.slice(1)}`
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";
  const l_intervalInfo = intervalInfo
  ? intervalInfo
  : intervalDateFormat(
      selectedPeriod,
      false,
      aggragatorInterval,
      timeZone,
      isFullMonthYearName
    )
  return (
    <WidgetChartFooter className="widget-chart-preview-footer">
      <SelectedPeriodInfo style={{ "width": selectedLanguage !== "en" ? "100%" : ""}} title={l_intervalInfo}>
        {l_intervalInfo}
      </SelectedPeriodInfo> 
      {footerInfo ? (
        <ChartInformation type="footer" title={translate(firstWordFooterInfoCapitalize)}><TranslateComponent>{footerInfo}</TranslateComponent></ChartInformation>
      ) : (
        <div style={{"width":"100%", "text-align": "center"}}>
          <AggregationIntervalName title={translate(combineText)}><TranslateComponent>{combineText}</TranslateComponent></AggregationIntervalName>
        </div>
      )}
    </WidgetChartFooter>
  )
}

export const NoWidgetDataAvailable = ({
  buildingName = '',
  equipmentName = '',
  equipmentType = '',
  aggregatorName = '',
  selectedPeriod = '',
  widgetType = '',
  equipmentDetails,
  aggragatorInterval = '',
  widgetDescription,
  intervalInfo,
  footerInfo,
  timeZone,
  accountName = '',
  comparisonBar = false,
  isDefaultForText = false,
  isFullMonthYearName = false
}) => {
  const widgetIcon = WidgetsIcons[widgetType] || 'icon-linewidget'
  return (
    <WidgetNoDataParentContainer>
      <WidgetNoDataContainer>
        <WidgetChartTitleComponent
          buildingName={buildingName}
          equipmentName={equipmentName}
          equipmentType={equipmentType}
          equipmentDetails={equipmentDetails}
          widgetDescription={widgetDescription}
          accountName={accountName}
        />
        <IconContainer className="no-data-icon">
          <span className={`widget-no-data ${widgetIcon}`} />
        </IconContainer>
        <WidgetChartFooterComponent
          aggregatorName={aggregatorName}
          selectedPeriod={selectedPeriod}
          aggragatorInterval={aggragatorInterval}
          intervalInfo={intervalInfo}
          footerInfo={footerInfo}
          timeZone={timeZone}
          comparisonBar={comparisonBar}
          isDefaultForText={isDefaultForText}
          isFullMonthYearName={isFullMonthYearName}
        />
      </WidgetNoDataContainer>
      <NoDataContentContainer>
       <strong><p><TranslateComponent>No Data Available</TranslateComponent> </p></strong>
        <p>
          <TranslateComponent>Please check that the</TranslateComponent>{' '}
          <Link to={`/building-data`}>
            <u><TranslateComponent> Building Data Chart</TranslateComponent></u>
          </Link>{' '}
          <TranslateComponent>has equipment data and contact your Trane Representative if any is
          missing</TranslateComponent>
        </p>
      </NoDataContentContainer>
    </WidgetNoDataParentContainer>
  )
}
