import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import FileSelect from '../file-select-sc/file-select.sc'

export class MultipleFileUploader extends PureComponent {
  static propTypes = {
    allowMultipleFiles: PropTypes.bool,

    /**
     * The method to upload file
     * Called immediately after validation
     * @param {File} file
     * @returns {Promise<*>}
     */
    uploadMethod: PropTypes.func,

    /**
     * On change handler
     * @param {Object} bag
     * @param {string[]} bag.allowedFileTypes - The list of allowed file types
     * @param {Event} bag.event
     * @param {File[]} bag.files - The list of selected files
     * @param {function} bag.setError
     */
    onChange: PropTypes.func,

    /**
     * @typedef {Object} UploadingFile
     * @property {File} file
     * @property {boolean} loading
     * @property {*} [error]
     */

    /**
     * On change upload status handler
     * @param {UploadingFile[]} files
     */
    onChangeUploadStatus: PropTypes.func,

    /**
     * On drag enter handler
     * @param {Event} bag.event
     * @param {File[]} bag.files
     * @param {function} bag.setError
     */
    onDragEnter: PropTypes.func,

    /**
     * On drag leave handler
     * @param {Event} bag.event
     * @param {function} bag.setError
     */
    onDragLeave: PropTypes.func,

    /**
     * Shows only acceptable file types in description
     */
    showOnlyAcceptableFileDesc: PropTypes.bool
  }

  static defaultProps = {
    uploadMethod: () => new Promise(),
    onChange: () => {},
    onChangeUploadStatus: () => {},
    onDragEnter: () => {},
    onDragLeave: () => {},
    allowMultipleFiles: true,
    data: [],
    showOnlyAcceptableFileDesc: false
  }

  state = {
    error: null
  }

  /**
   * Handles onChange event
   * @param {Object} bag
   * @param {Event} bag.event
   * @param {File[]} bag.files - The list of selected files
   * @param {string[]} bag.allowedFileTypes - The list of allowed file types
   */
  onChange = (bag) => {
    const { onChange } = this.props

    // if no validation error calls onChange
    const validationResult = this.validate(bag)
    if (validationResult && typeof validationResult.then === 'function') {
      validationResult.then((validationError) => {
        if (!validationError) {
          onChange({ ...bag, setError: this.setError })

          this.upload(bag.files)
        }
      })
    } else if (!validationResult) {
      const contentValidation = Promise.all(
        bag.files.map(async (file) => {
          const buffer = await this.readUploadedFile(file)
          let header = ''
          let isTypeMatch = false
          const uint8Array = new Uint8Array(buffer).subarray(0, 4)

          for (let i = 0; i < uint8Array.length; i++) {
            header += uint8Array[i].toString(16)
          }
          // Check the file signature against known types
          if (header && file.type === 'image/png') {
            switch (header) {
              case '89504e47':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (
            (header && file.type === 'image/jpeg') ||
            file.type === 'image/jpg'
          ) {
            switch (header) {
              case 'ffd8ffe0':
              case 'ffd8ffe1':
              case 'ffd8ffe2':
              case 'ffd8ffe3':
              case 'ffd8ffe8':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (header && file.type === 'image/gif') {
            switch (header) {
              case '47494638':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (header && file.type === 'image/bmp') {
            switch (header.substring(0, 4)) {
              case '424d':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (header && file.type.includes('spreadsheet')) {
            switch (header) {
              case '504b34':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (header && file.type === 'application/pdf') {
            switch (header) {
              case '25504446':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (
            (header && file.type === 'application/vnd.ms-powerpoint') ||
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/msword'
          ) {
            switch (header) {
              case 'd0cf11e0':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (header && file.type.includes('officedocument')) {
            switch (header) {
              case '504b34':
              case '504b56':
              case '504b78':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (header && file.type === 'video/mpeg') {
            switch (header) {
              case '001ba':
              case '001b3':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          } else if (
            (header && file.type === 'text/plain') ||
            file.type === 'application/octet-stream' ||
            file.type === 'text/csv'
          ) {
            // CSV/Text files do not have magic numbers, and the possible outputs are always 'Text/Plain' or 'Application/octet-stream', depending on the file size.
            isTypeMatch = true
          } else if (
            file.name.split('.').pop().toLowerCase() === 'cdf'
          ) {
            // CDF files do not have magic numbers, and the possible outputs are always 'Application/octet-stream' and file type is empty.
            // From sample files getting 282a2043 as a magic bytes. used here to validate it's content type
            switch (header) {
              case '282a2043':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          }else if (
            (header &&
              file.type === '' &&
              file.name.split('.').pop().toLowerCase() === 'nb')
          ) {
            // NB files do not have magic numbers, and the possible outputs are always 'Application/octet-stream' and file type is empty.
            // From sample files getting 282a2a2a as a magic bytes. used here to validate it's content type
            switch (header) {
              case '282a2a2a':
                isTypeMatch = true
                break
              default:
                isTypeMatch = false
                break
            }
          }
          if (!isTypeMatch) {
            this.setState({
              error: i18next.t('components:multipleFileUpload>FileTypeNotValid')
            })
            return false
          } else {
            return true
          }
        })
      )
      contentValidation.then((upload) => {
        const x = upload.find((item) => item === false)
        if (x !== false) {
          onChange({ ...bag, setError: this.setError })
          this.upload(bag.files)
        }
      })
    }
  }

  /**
   * Handles onDragEnter event
   * @param {Object} bag
   * @param {Event} bag.event
   * @param {File[]} bag.files - The list of selected files
   */
  onDragEnter = (bag) => {
    const { onDragEnter } = this.props

    onDragEnter({ ...bag, setError: this.setError })
  }

  /**
   * Handles onDragLeave event
   * @param {Object} bag
   * @param {Event} bag.event
   */
  onDragLeave = (bag) => {
    const { onDragLeave } = this.props

    onDragLeave({ ...bag, setError: this.setError })
  }

  /**
   * Sets validation error
   * @param {string} error
   */
  setError = (error) => {
    this.setState({ error })
  }

  /**
   * Uploads files via uploadMethod function for each file from the list
   * Notifies via onChangeUploadStatus about uploading status of each file from the list
   * @param {File[]} files
   */
  upload = (files) => {
    const newFiles = files.map((file) => {
      const newFile = new File(
        [file],
        file.name.split('.').join('-' + Date.now() + '.'),
        {
          type: file.type
        }
      )
      newFile.title = file.name
      return newFile
    })
    const { uploadMethod, onChangeUploadStatus } = this.props
    let filesWithUploadStatus = newFiles.map((file) => ({
      file,
      loading: true
    }))

    setTimeout(() => {
      onChangeUploadStatus(filesWithUploadStatus)
      newFiles.map((file) => {
        uploadMethod(file)
          .then((uploadedFile) => {
            const { onChangeUploadStatus } = this.props
            const { fileId, fileUrl: filePath } = uploadedFile
            filesWithUploadStatus = updateFileProps(
              file.name,
              filesWithUploadStatus,
              { loading: false, fileId, filePath, title: file.title }
            )

            onChangeUploadStatus(filesWithUploadStatus)
          })
          .catch((error) => {
            const { onChangeUploadStatus } = this.props
            filesWithUploadStatus = updateFileProps(
              file.name,
              filesWithUploadStatus,
              { loading: false, error, title: file.title }
            )
            onChangeUploadStatus(filesWithUploadStatus)
          })
      })
    }, 0)
  }

  /**
   * Validates the selected files then starts uploading
   * @param {Object} bag
   * @param {Event} bag.event
   * @param {File[]} bag.files - The list of selected files
   * @param {string[]} bag.allowedFileTypes - The list of allowed file types
   * @return {(string|null)} - error text or null
   */
  validate = (bag) => {
    const { validate } = this.props
    const error = validate(bag)
    if (error && typeof error.then === 'function') {
      error.then((result) => {
        this.setState({ error: result })
      })
    } else {
      this.setState({ error })
    }
    return error
  }

  readUploadedFile = (file) => {
    const temporaryFileReader = new FileReader()
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort()
        reject(new DOMException('Problem parsing input file.'))
      }
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result)
      }
      temporaryFileReader.readAsArrayBuffer(file.slice(0, 8))
    })
  }

  render() {
    const {
      allowedFileTypes,
      disabled,
      note,
      allowMultipleFiles,
      customUploadContent,
      showOnlyAcceptableFileDesc,
      uiMode
    } = this.props
    const { error } = this.state
    return (
      <FileSelect
        allowedFileTypes={allowedFileTypes}
        disabled={disabled}
        error={error}
        note={note}
        onChange={this.onChange}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        allowMultipleFiles={allowMultipleFiles}
        customUploadContent={customUploadContent}
        showOnlyAcceptableFileDesc={showOnlyAcceptableFileDesc}
        uiMode={uiMode}
      />
    )
  }
}

/**
 * @typedef {Object} UploadingFile
 * @property {File} file
 * @property {boolean} loading
 * @property {*} [error]
 */

/**
 * Updates file props in the file list
 * @param {string} fileName
 * @param {UploadingFile[]} files
 * @param {Object} props
 * @returns {Object[]} - Updated list of files
 */
const updateFileProps = (fileName, files, props) =>
  files.map((uploadingFile) =>
    uploadingFile.file.name === fileName
      ? { ...uploadingFile, ...props }
      : uploadingFile
  )
