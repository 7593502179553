import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table/clientSideTable'
import {
  GET_WIDGET,
  GET_IMAGE_WIDGET,
  listWidgetsByBuilding,
  listWidgetsOrganization,
  DELETE_WIDGET,
  getAllDashboardByWidget
} from './graphql'
import moment from 'moment'
import { useWidgetsContext } from './WidgetsContextProvider'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import {
  MODAL_TYPE,
  WidgetNames,
  WidgetsIcons,
  getType,
  getWidgetDetails,
  widgetListFilteringOptionList,
  getWidgetType
} from './helpers'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'
import { ACTIONS } from 'src/constants/actions'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import {
  StyledTextWrapper,
  WidgetListWrapper,
  WidgetListWrappers
} from './styles'
import { CREATE_ENTRY_WITH_ATTACHMENT } from 'src/common/queries/attachment'
import { getSearchParams } from '../../common/helperFunctions'
import Spinner from 'src/components/Spinner'
import WidgetsTileList from './WidgetTileView/WidgetsTileList'
import { WidgetPreviewModal } from './WidgetPreviewModal'
import _uniqBy from 'lodash/uniqBy'
import { WIDGET_TYPES } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { GET_BUILDING_METER_TOTAL_EQUIPMENT } from 'src/common/queries/datasource'
import _sortBy from 'lodash/sortBy'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

const WidgetsList = (props) => {
  const [rows, setRows] = useState([])
  const { buildingId, organizationId } = getSearchParams()
  const {
    reloadEvents,
    setReloadEvents,
    setMode,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setWidgetDetails,
    widgetDetails,
    modalType,
    setModalType,
    setIsLoading,
    setOpenWidgetPicker,
    setWidgetBuilding,
    setHandleFormSubmit,
    mode,
    setWidgetBuildingDetails,
    setIsSubmitted,
    setIsFormValidationAlone,
    setSelectedBaseLine,
    setObjectPickerSelectedValue,
    showWidgetPreview,
    setShowWidgetPreview,
    selectedWidgetDetails,
    setSelectedWidgetDetails
  } = useWidgetsContext()
  const [t] = useTranslation()
  const [error, setError] = useState(null)
  const [widgetContent, setWidgetContent] = useState(null)
  const [widgetRow, setWidgetRow] = useState(null)
  const [getalldashboardList, setgetalldashboardList] = useState([])
  const [filterList, setFilterList] = useState([])
  const handleWidgetInitialize = () => {
    setMode('')
    setIsLoading(false)
    setIsOpenCreateWidget(false)
    setOpenWidgetPicker(false)
    setWidgetBuilding(null)
    setSelectedWidget(null)
    setWidgetDetails(null)
    setModalType('')
    setHandleFormSubmit(false)
    setWidgetDetails(null)
    setWidgetBuildingDetails(null)
    setIsSubmitted(false)
    setIsFormValidationAlone(false)
    setSelectedBaseLine(null)
    setObjectPickerSelectedValue([])
  }
  const {
    data: WidgetsList,
    loading: isWidgetLoading,
    refetch: refetchBuildingsList,
    responseTime
  } = useQuery(
    !buildingId
      ? {
          query: listWidgetsOrganization,
          dataPath: 'data.listWidgetsByAccount.items',
          variables: { accountId: organizationId },
          errorPolicy: 'ignore',
          disableInitialLoad: true,
          onSuccess: () => {
            handleWidgetInitialize()
          },
          onError: (error) => {
            setError(error)
            handleWidgetInitialize()
          }
        }
      : {
          query: listWidgetsByBuilding,
          dataPath: 'data.listWidgetsByBuilding.items',
          variables: { buildingId },
          disableInitialLoad: true,
          onSuccess: () => {
            handleWidgetInitialize
          },
          onError: (error) => {
            setError(error)
            handleWidgetInitialize()
          }
        }
  )

  const { refetch: getBuildingTotalMeterEquipment } = useQuery({
    query: GET_BUILDING_METER_TOTAL_EQUIPMENT,
    dataPath: 'data.getBuildingTotalMeterEquipment',
    disableInitialLoad: true
  })

  const getBuildingMeterTotalId = async (widgetData) => {
    if (
      (widgetData.widgetType === WidgetNames.EnergyConsumption ||
        widgetData.widgetType === WidgetNames.EnergyCost || widgetData.widgetType === WidgetNames.EnergyDemand) &&
      _isEmpty(widgetData?.widgeEquipment1Id) &&
      !_isEmpty(widgetData?.buildingId)
    ) {
      // if the equipment id is null, means widget created for Building meter total
      const totalMeter = await getBuildingTotalMeterEquipment({
        body: JSON.stringify({ bId: widgetData?.buildingId })
      })
      const res = await JSON.parse(totalMeter)
      const totalMeterList = res?.body?.equipments || []
      if (totalMeterList?.length > 0) {
        const meterDetails = totalMeterList?.[0]
        widgetData.widgeEquipment1Name = meterDetails?.name
        widgetData.widgeEquipment1Id = meterDetails.id
        widgetData.widgeEquipment1Type = 'VirtualMeter'
      }
    } 
    return widgetData
  } 

  const { refetch: refetchGetWidget } = useQuery({
    query:
      widgetRow?.widgetType === WidgetNames.ImageText
        ? GET_IMAGE_WIDGET
        : GET_WIDGET,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getWidget',
    onSuccess: async (data) => {
      const widgetData = {...data}
      if (widgetData) {
        const widgetDetailData = await getBuildingMeterTotalId(widgetData)
        setWidgetDetails(widgetDetailData)
        const widgets = getWidgetDetails(t, widgetDetailData?.widgetType)
        setSelectedWidget(widgets)
        setIsOpenCreateWidget(true)
      }
    },
    onError: (error) => {
      setError(error)
    }
  })

  const { refetch: refetchGetAllDashboardByWidget } = useQuery({
    query: getAllDashboardByWidget,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getWidget',
    onSuccess: (widgetData) => {
      if (widgetData) {
        setgetalldashboardList(widgetData?.dashboards?.items)
      }
    },
    onError: (error) => {
      setError(error)
    }
  })

  const widgetListStatic =[
    {originalName:"Time Comparison Bar Chart",  transName: 'singleComparisonBarWidget', widgetType: "single-comparison-bar" },
    {originalName:"Energy Cost", transName: 'energyCostWidget', widgetType: "energy-cost" },
    {originalName:"Energy Consumption", transName: 'energyConsumptionNewWidget', widgetType: "energy-consumption-new" },
    {originalName:"Energy Demand", transName: 'energyDemandWidget', widgetType: "energy-demand" },
    {originalName:"Data Comparison Bar Chart", transName: 'dualComparisonBarWidget', widgetType: "dual-comparison-bar" },
    {originalName:"Baseline Comparison", transName: 'energyConsumptionWidget', widgetType: "energy-consumption" },
    {originalName:"Data Monitoring Gauge", transName: 'gaugeWidget', widgetType: "gauge" },
    {originalName:"Data Monitoring Orb", transName: 'orbWidget', widgetType: "orb" },
    {originalName:"Image/Text Link", transName: 'imageTextWidget', widgetType: "image-text" },
    {originalName:"Weather", transName: 'weatherWidget', widgetType: "weather" },
    {originalName:"Trends Bar Chart", transName: 'barWidget', widgetType: "bar" },
    {originalName:"Trends Line Chart", transName: 'lineWidget', widgetType: "line" },
    {originalName:"Custom KPI", transName: 'customKPIWidget', widgetType: "custom-kpi" },
    {originalName:"Energy Star Score", transName: 'energyStarScoreWidget', widgetType: "energy-star-score" }
  ]

  const { onSubmit: deleteS3AttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const { onSubmit: deleteWidget } = useMutation({
    query: DELETE_WIDGET,
    onSuccess: () => {
      (async () => {
        const attachmentBody = {
          modelType: 'Widget',
          modelData: {
            delete: {
              id: widgetContent?.id
            }
          },
          attachments: {}
        }
        await deleteS3AttachmentMutation({
          input: JSON.stringify(attachmentBody)
        })
      })()
      setModalType(MODAL_TYPE.DELETE_SUCCESS)
      setWidgetDetails(null)
      setMode(null)
      setReloadEvents(true)
    }
  })

  useEffect(() => {
    if (buildingId) {
      refetchBuildingsList({ buildingId })
    } else if (organizationId) {
      refetchBuildingsList({ accountId: organizationId })
    }
  }, [buildingId, organizationId])

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
      if (buildingId) {
        refetchBuildingsList({ buildingId })
      } else if (organizationId) {
        refetchBuildingsList({ accountId: organizationId })
      }
    }
  }, [reloadEvents])

  const preview = translate("PREVIEW")
  useEffect(() => {

    const widgetTypesList = !_isEmpty(WidgetsList)
    ? WidgetsList?.map((widget) => ({
          name: widget?.widgetType,
          value: widget?.widgetType
      }))
    : []

      const uniqueTypeList = _uniqBy(widgetTypesList,"name")?.map((x) => {
        return {
          name: x?.name,
          value: x?.name
        }
      })
      
      const lDateFormat = getDateFormatByLocale('YYYY-MM-DD')
      setFilterList(uniqueTypeList)
    setRows(
      !_isEmpty(WidgetsList)
        ? _sortBy(WidgetsList?.filter(x => x?.widgetType !== WIDGET_TYPES.MULTIPLE_LINES).map((widget) => ({
            ...widget,
            type: getWidgetType(t, widget?.widgetType),
            creationDate: widget.creationDate
              ? moment(widget.creationDate).format(lDateFormat ? lDateFormat : 'YYYY-MM-DD')
              : '',
            preview: preview
          })),'name')?.reverse()
        : []
    )
  }, [WidgetsList, preview])
    
  useEffect(() => {
    props?.setIsWidgetLoading(isWidgetLoading)
  }, [isWidgetLoading])
  

  useEffect(() => {
    if (widgetRow) {
      refetchGetWidget({ widgetId: widgetRow?.id })
      setWidgetRow(null)
    }
  }, [widgetRow])
  //deletes only the widgets, have to be taken care of removing the reference from dashboard in later point

  const deleteWidgetHandler = () => {
    deleteWidget({ input: { id: widgetContent?.id } })
    trackEvent(USER_EVENTS.DASHBOARDS.events.DELETE_WIDGET, {
      name: widgetContent ? widgetContent.name : "",
      id: widgetContent ? widgetContent.id : "",
    })
  }

  const nameHeadings = [
    {
      title: translate('Widget Name'),
      key: 'name',
      maxWidth: '150px',
      customComponent: (data) => {
        const widgetIcon = WidgetsIcons[data?.widgetType]
        return (
          <>
            {data?.name && (
              <div className="custom-component">
                <span className={`icon ${widgetIcon}`}></span>
                <span className="data-span">{data?.name}</span>
              </div>
            )}
          </>
        )
      }
    },
    {
      title: translate('Description'),
      key: 'description',
      maxWidth: '200px'
    },
    {
      title: translate('Targeted Object/Type'),
      key: 'building.name',
      maxWidth: '150px',
      customComponent: (data) => {
        const targetIcon = getType(data?.widgetTargetType)?.icon
        const { building, widgeEquipment1Name, widgeEquipment2Name } =
          data || {}
        return (
          <>
            {data?.name && (
              <WidgetListWrappers>
                {!(
                  widgeEquipment1Name || widgeEquipment2Name
                ) &&
                  building?.name && (
                    <StyledTextWrapper
                      className={`data-span icon icon-${targetIcon}`}
                    >
                      {' '}
                      {building?.name}{' '}
                    </StyledTextWrapper>
                  )}
                {widgeEquipment1Name && (
                  <StyledTextWrapper
                    className={`icon icon-${
                      getType(data?.widgeEquipment1Type)?.icon
                    }`}
                  >
                    {widgeEquipment1Name}
                  </StyledTextWrapper>
                )}
                {widgeEquipment2Name && (
                  <StyledTextWrapper
                    className={`icon icon-${
                      getType(data?.widgeEquipment2Type)?.icon
                    }`}
                  >
                    {widgeEquipment2Name}
                  </StyledTextWrapper>
                )}
              </WidgetListWrappers>
            )}
          </>
        )
      }
    },
    {
      title: translate('Creation Date'),
      key: 'creationDate',
      maxWidth: '150px'
    },
    {
      title: '',
      key: 'preview',
      maxWidth: '150px',
      disabled: false,
      onDataClick: async (data) => {
        const widgetData = {...data}
        const widgetDetailData = await getBuildingMeterTotalId(widgetData)
        setShowWidgetPreview(true)
        setSelectedWidgetDetails(widgetDetailData)
      }
    }
  ]


  const hideCancelDialog = () => {
    setModalType('')
    setWidgetDetails(null)
    setWidgetContent(null)
    setMode(null)
  }

  const modalConfig = useMemo(
    () => ({
      heading:
        modalType === MODAL_TYPE.SUCCESS? mode === ACTIONS.EDIT ? "Edit Widget" : "Create new widget": "Delete Widget",
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: 'Yes',
                handleClick: deleteWidgetHandler,
                type: 'valid'
              },
              { text: 'No', handleClick: hideCancelDialog, type: 'cancel' }
            ]
          : [{ text: 'Close', handleClick: hideCancelDialog, type: 'cancel' }],
      handleClose: hideCancelDialog,
      ...((MODAL_TYPE.CONFIRMATION ||
        MODAL_TYPE.SUCCESS ||
        MODAL_TYPE.DELETE_SUCCESS) && {
        autoClose: 0
      })
    }),
    [modalType]
  )

  const translateDialogBox = {
    update: translate("You have successfully updated "),
    delete: translate("Are you sure you want to delete "),
    create: translate("You have successfully created "),
    dashboard: translate("Dashboard "),
    deletedSuccessfully: translate("You have successfully deleted "),
  }

  const renderConfirmationText = (modalType, content = '') => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        if (getalldashboardList && getalldashboardList?.length !== 0) {
          return (
            <>
              <p>
               <TranslateComponent> Are you sure you want to delete widget – </TranslateComponent>
                <strong>{content}</strong><TranslateComponent> from your Widget Library?</TranslateComponent>
              </p>
              <p>
               <strong><TranslateComponent>Warning</TranslateComponent></strong>: <TranslateComponent>This action will remove this widget
                from the following dashboards where it is currently used: </TranslateComponent>
              </p>
            <ul>
              {getalldashboardList?.map((item) => {
                return (
                  <li key={item?.dashboard?.id}>{`${translateDialogBox.dashboard} ${item?.dashboard?.name}`}</li>
                )
              })}
          
              </ul>
              </>
          )
        } else {
          return <>{translateDialogBox.delete} {content}?</>
          

        }
      case MODAL_TYPE.SUCCESS:
        return (
         <>{mode === ACTIONS.EDIT ?  translateDialogBox.update: translateDialogBox.create} {content}
          </> 
        ) 
      case MODAL_TYPE.DELETE_SUCCESS:
        return <>{translateDialogBox.deletedSuccessfully} {widgetContent?.name}</>
      default:
        return <>{translateDialogBox.deletedSuccessfully} {widgetDetails?.name}</>
    }
  }

  const handleDeleteWidgetFunc = async (widgetId) => {
    await refetchGetAllDashboardByWidget({
      id: widgetId
    })


  }

  const handleWidgetCopy = (data) => {
    setMode(ACTIONS.COPY)
    setWidgetRow(data)
  }
  const handleWidgetEdit = (data) => {
    setMode(ACTIONS.EDIT)
    setWidgetRow(data)
  }
  const handleWidgetDelete = async (data) => {
    await handleDeleteWidgetFunc(data?.id)
    setMode(ACTIONS.DELETE)
    setWidgetContent(data)
    setModalType(MODAL_TYPE.CONFIRMATION)
  }
  
  return (
    <>
      {isWidgetLoading ? (
        <Spinner page={true} />
      ) : (
        <WidgetListWrapper className="widget-list-wrapper">
          {rows?.length === 0 && (
            <h2>
              <TranslateComponent> There are no widgets.</TranslateComponent>
              <br /><TranslateComponent> Click the</TranslateComponent>{' '}
              <span
                className="action"
                onClick={() => props?.handleAddNewWidget()}
              >
              <TranslateComponent>  Create new widget</TranslateComponent>
              </span>{' '}
            <TranslateComponent>  button to create one now.</TranslateComponent>
            </h2>
          )}
          {rows?.length > 0 && !props?.isTileView && (
            <Table
              key={`WidgetsTable-${organizationId} ${buildingId}`}
              rows={rows}
              header={nameHeadings}
              loadTime={responseTime}
              search={true}
              searchFields={['name', 'widgetTarget', 'description']}
              isFilterChange={true}
              rowControl={[
                {
                  text: <TranslateComponent>Copy</TranslateComponent>,
                  action: (data) => {
                    handleWidgetCopy(data)
                    trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_COPY_WIDGET, {
                      location: 'Widget Library'
                    })
                  }
                },
                {
                  text: <TranslateComponent>Edit</TranslateComponent>,
                  action: (data) => {
                    handleWidgetEdit(data)
                    trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_EDIT_WIDGET, {
                      location: 'Widget Library'
                    })
                  }
                },
                {
                  text: <TranslateComponent>Delete</TranslateComponent>,
                  action: (data) => {
                    handleWidgetDelete(data)
                    trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_DELETE_WIDGET, {
                      location: 'Widget Library'
                    })
                  }
                }
              ]}
              filtersList={[
                {
                  key: 'type',
                  filterName: 'widgetTypeFilter',
                  id: 'widgetTypeSelectorFilter',
                  defaultLabel: 'All',
                  selectedValue: 'default',
                  options: widgetListFilteringOptionList(t, filterList, widgetListStatic)
                }
              ]}
              isTranslate={true}
            />
          )}
          {rows?.length > 0 && props?.isTileView && (
            <WidgetsTileList
            organizationId = {organizationId}
            buildingId = {buildingId}
              WidgetsList={rows}
              handleWidgetCopy={handleWidgetCopy}
              handleWidgetEdit={handleWidgetEdit}
              handleWidgetDelete={handleWidgetDelete}
            />
          )}
          {[
            MODAL_TYPE.CONFIRMATION,
            MODAL_TYPE.SUCCESS,
            MODAL_TYPE.DELETE_SUCCESS
          ].includes(modalType) ? (
            <Modal {...modalConfig}>
              <ConfirmationText>
                {renderConfirmationText(
                  modalType,
                  mode === ACTIONS.DELETE
                    ? widgetContent?.name
                    : widgetDetails?.name
                )}
              </ConfirmationText>
            </Modal>
          ) : null}
          {showWidgetPreview && (
            <WidgetPreviewModal widgetDetails={selectedWidgetDetails} />
          )}
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </WidgetListWrapper>
      )}
    </>
  )
}

export default React.memo(WidgetsList)
