import { Flex } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  components,
  type MultiValueGenericProps,
  type ControlProps,
  type MultiValueRemoveProps,
  type DropdownIndicatorProps,
  type PlaceholderProps
} from 'react-select';
import makeAnimated from 'react-select/animated';

// Denali imports.
import { getColor } from 'src/denali-components/helpers'
import { faChevronRight, faTachographDigital, faXmark } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Equipment } from 'src/denali-pages/types';

// Local imports.
import styles from './equipment-selector.module.scss';

// Custom Control component to display the selected equipment text heading.
const CustomControlComponent = (props: ControlProps) => (
  <Flex 
    className={styles.equipmentSelectorControlWrapper}
    direction="row" 
    alignItems="center"
    justifyContent="start"
    gap="var(--amplify-space-small)"
  >
    <div className={`${styles.equipmentSelectorControlImage} useColorClass`} data-icon-color="blue">
      <FontAwesomeIcon icon={faTachographDigital} />
    </div>
    <div className={styles.equipmentSelectorControlChildren}>
      <components.Control {...props} />
    </div>
  </Flex>
);

const CustomDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faChevronRight} color='var(--amplify-colors-neutral-50)' />
    </components.DropdownIndicator>
  );
};

const CustomMultiValueContainer = (props: MultiValueGenericProps<Equipment, true>) => {
  const color = getColor(props.data.type)
  return (
    <Flex 
      className={`${styles.equipmentSelectorMultiValueWrapper} useColorClass`} 
      data-icon-color={color} 
      direction="row" 
      alignItems="center" 
      gap="var(--amplify-space-xxxs)"
    >
      <components.MultiValueContainer {...props} />
    </Flex>
  );
};

const CustomMultiValueLabel = (props: MultiValueGenericProps<Equipment, true>) => {
  return (
    <Flex 
      className={styles.equipmentSelectorMultiValueLabelWrapper}
      direction="row" 
      alignItems="center" 
      gap="var(--amplify-space-xxxs)"
    >
      <FontAwesomeIcon icon={faTachographDigital} />
      <components.MultiValueLabel {...props} />
    </Flex>
  );
};

// Custom MultiValueRemove component to derive a color same as the label.
const CustomMultiValueRemove = (props: MultiValueRemoveProps<Equipment, true>) => {
  return (
    <Flex 
      direction="column" 
      alignItems="center"
      justifyContent="center"
    >
      <components.MultiValueRemove {...props}>
        <FontAwesomeIcon icon={faXmark} />
      </components.MultiValueRemove>
    </Flex>
  );
};

// Makes the components animate.
export const animatedComponents = makeAnimated({
  Control: CustomControlComponent,
  DropdownIndicator: CustomDropdownIndicator,
  IndicatorSeparator: null,
  MultiValueLabel: CustomMultiValueLabel,
  MultiValueContainer: CustomMultiValueContainer,
  // I don't know this is needed, TypeScript is complaining and I think it's because the animated
  // just re-exports the multiValueRemove type and it's not carrying through the types based on the input data
  MultiValueRemove: CustomMultiValueRemove as any
})