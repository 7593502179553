import { TranslateComponent } from "src/common/translations";
// import { IconCell } from "src/denali-components/Table/IconCell";
import { type FullDocument, type DocumentAttachment } from "src/pages/documentsAndReports/graphql"
import styles from "./reports.module.scss"
import moment from "moment";
// import { Button, Flex } from "@aws-amplify/ui-react";
import { FileBadge } from 'src/denali-components/File/FileBadge'

// downloadFile shared from src/pages/documentsAndReports/DocumentsAndReportsList.tsx
export const DocumentAttachments = ({document}: {document: FullDocument}) => {
  if(!document) return <></>;

  return (
    <div className={styles.documentModalAttachments}>
      <h2 className={styles.attachmentsHeadline}><TranslateComponent>Attached Files</TranslateComponent></h2>
        {
          document?.attachments?.items?.map(attachment =>
            <div key={attachment.id}>
              <FileBadge
                fileId={
                  attachment &&
                  attachment.id
                }
                fileFullName={attachment.name}
                name={attachment.title}
                buildingId={attachment.buildingId}
                fileSize={convertBytesToString(attachment.sizeInBytes)}
                description={attachment.description}
                date={moment(attachment.attachedAt).format('LLL')}
                showDate={true}
                showDescription={true}
                showPreviewButton={false}
                downloadButton={'text'}
                deleteFile={false}
              />
            </div>
          )
        }
    </div>
  )
}

const convertBytesToString = (bytes:number, iterations=1) => {
  if(iterations > 4) return `1000+ GB`;

  // Technically this is converting to Kibibyte (KiB) by dividing using 1024 (base 2) not Kilobyte (KB) using 1000 for base 10
  // This is very common and doesn't need to be precise so matching the old component's formula.
  const reduce = bytes / 1024;

  const suffixes = ['Bytes', 'KB', 'MB', 'GB'];
  return reduce < 1
    ? `${Math.round(bytes)} ${suffixes[iterations-1]}`
    : convertBytesToString(reduce, iterations+1)
}