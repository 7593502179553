import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getRandomColor } from 'src/common/chartHelperFunctions'
import ObjectPicker from './ObjectPicker'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import {
  GET_BUILDING_EQUIPMENT_TYPES,
  getEquipmentsByBuildingIDAndType,
  GET_EQUIPMENT_PROPERTIES_BY_ID,
  GET_EQUIPMENT_CALCULATED_PROPERTIES_BY_ID,
  SEARCH_BUILDINGS_BY_ACCOUNT
} from './graphql'
import { useTranslation } from 'react-i18next'
import IconSVG from 'src/components/Icon/index.js'
import { ACTIONS } from 'src/constants/actions'
import { BUILDING, EQUIPMENT, PROPERTY, resizePaneDrag } from './helpers'
import colors from 'src/components/legacy/common/colors.json'
import {
  Wrapper,
  StyledDivFoot,
  BottonSection,
  BottomHead,
  BottomList,
  BottomListItem,
  BottomText,
  StyleSeperator
} from './styles'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { useMultipleBuildingsAccess } from 'src/hooks/multipleBuildingAccess'
import { groupByPropertykey } from 'src/pages/buildingData/util'
import { useFilterEquipmentPropertiesQuery } from 'src/hooks/FilterRelavantEquipmentProperities/useFilterEquipmentProperties'
import { filterPropertiesBasedOnMetaData } from 'src/hooks/FilterRelavantEquipmentProperities/helpers'
import translate, { TranslateComponent } from '../../common/translations'
import { translateJSON } from '../../redux/slicers/translationCombinedSlice'
 import { useSelector, useDispatch  } from 'react-redux'

const ENERGY_METER_TYPES = ['GAS', 'ElectricMeter', 'VirtualMeter']

export const ChartObjectPicker = ({
  objects,
  update,
  buildingSelectLimit,
  equipmentSelectLimit,
  propertyUOMLimit = 4,
  mode,
  fullHeight = true,
  initialValues,
  setSelectedUniqueUOMsToParent,
  availableUniqueUOMs,
  error,
  claims,
  baselineEquipments = [],
  isEnergyWidget = false
}) => {
  const [buildingsCheckList, setBuildingsCheckList] = useState([])
  const { setSeletectedBuildings: setSeletectedBuildingsToken } =
    useMultipleBuildingsAccess()
  const [equipmentTypesCheckList, setEquipmentTypesCheckList] = useState([])
  const [equipmentsCheckList, setEquipmentsCheckList] = useState([])
  const [equipmentPropertiesCheckList, setEquipmentPropertiesCheckList] =
    useState([])
  const [checkedValues, setCheckedValues] = useState([])
  const [rootData, setRootData] = useState(initialValues || [])
  const [isRootDataLimit, setIsRootDataLimit] = useState(false)
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null)
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [step, setStep] = useState(-1)
  const { organizationId, buildingId } = getSearchParams()
  const { getFilteredProperties } = useFilterEquipmentPropertiesQuery()

  // Dimension hook to get all Dimensions
  const { loadingDimensions, allDimensions, getAllDimensions } =
    useDimensionsHook()

  const [errorText, setErrorText] = useState(null)
  const [propertySymbolsValues, setPropertySymbolsValues] = useState([])
  const [t] = useTranslation()

  const firstRef = useRef()
  const secondRef = useRef()
  const seperatorRef = useRef()

  const pickerType = objects[objects?.length - 1] || BUILDING

  useEffect(() => {
    if (firstRef?.current && secondRef?.current) {
      resizePaneDrag(firstRef, secondRef, seperatorRef, checkedValues)
    }
  }, [
    firstRef?.current,
    secondRef?.current,
    seperatorRef?.current,
    checkedValues?.length
  ])

  const [buildingsLoading, refetchAccount, accountData] = useAPIPagination(
    SEARCH_BUILDINGS_BY_ACCOUNT,
    'data.searchBuildings'
  )

  const [equipmentsLoading, refetchEquipments, equipments] = useAPIPagination(
    getEquipmentsByBuildingIDAndType,
    'data.listEquipmentsByBuildingAndActiveAndType'
  )

  const { refetch: refetchEquipmentTypes, loading: equipmentTypesLoading } =
    useQuery({
      query: GET_BUILDING_EQUIPMENT_TYPES,
      dataPath: 'data.getBuildingEquipmentTypes',
      disableInitialLoad: true
    })

  const { refetch: getEquipmentPropertiesQuery, loading: loadingProperties } =
    useQuery({
      query: GET_EQUIPMENT_PROPERTIES_BY_ID,
      dataPath: 'data.getPropertyMappingByEquipmentID.items',
      disableInitialLoad: true
    })

  const {
    refetch: getEquipmentCalculatedPropertiesQuery,
    loading: loadingCalculatedProperties
  } = useQuery({
    query: GET_EQUIPMENT_CALCULATED_PROPERTIES_BY_ID,
    dataPath: 'data.getPropertyMappingByEquipmentID.items',
    disableInitialLoad: true
  })

  // If the parent component wants to refresh object picker, pass new objects list ( basically just spread and pass)
  const memoizedObjects = useMemo(() => objects, [])

  useEffect(() => {
    if (objects.includes(PROPERTY)) {
      getAllDimensions()
    }
  }, [])

  useEffect(() => {
    try {
      const initilizeProperties = async () => {
        if (selectedEquipment) {
          await getEquipments(selectedEquipment, true)
          await getEquipmentProperties(selectedEquipment, true)
        }
      }
      if (allDimensions?.length > 0 && mode === ACTIONS.EDIT) {
        initilizeProperties()
      }
    } catch (error) {}
  }, [allDimensions])

  useEffect(() => {
    if (objects?.length) {
      setStep(objects.findIndex((step) => step))
    }
  }, [memoizedObjects])

  const getInitialData = async () => {
    try {
      if (
        [ACTIONS.EDIT, ACTIONS.COPY].includes(mode) &&
        initialValues?.length > 0
      ) {
        // this is to handle edit, if we have some initial data to set
        // Assuming that Equipmetns list is alwasys last steps in this
        // If it differes, have to handle step number dynamically
        // set building info from edit data

        // Ruturns no of arrays based on selected buildings
        const buildingGroups = groupByPropertykey(initialValues, 'buildingId')

        // Get the last building
        const buildingDetails = buildingGroups?.[0]

        // Ruturns no of arrays based on selected equipments
        const equipmentGroups = groupByPropertykey(
          buildingDetails?.properties || [],
          'equipmentId'
        )

        // This items has all details like building id, name, equipment id, equipment name
        const selectedEquipmentProperty =
          equipmentGroups?.[0]?.properties?.[0] || []

        const allBuildingIds = buildingGroups?.map((x) => x?.buildingId)

        // sets token for all selected building ids
        await setSeletectedBuildingsToken(allBuildingIds, claims ? claims : [])

        setSelectedBuilding({
          accountId: organizationId,
          accountName: selectedEquipmentProperty?.accountName,
          id: selectedEquipmentProperty?.buildingId,
          name: selectedEquipmentProperty?.buildingName,
          checked: true
        })

        setSelectedEquipmentType({
          name: selectedEquipmentProperty?.equipmentType,
          familyType: selectedEquipmentProperty?.equipmentType,
          checked: true
        })

        const selectedEquipment = {
          equipmentName: selectedEquipmentProperty?.equipmentName,
          id: selectedEquipmentProperty?.equipmentId,
          familyType: selectedEquipmentProperty?.equipmentType,
          equipmentId: selectedEquipmentProperty?.equipmentId,
          buildingId: selectedEquipmentProperty?.buildingId
        }

        setSelectedEquipment(selectedEquipment)

        await getAllDimensions()

        if (objects.includes(PROPERTY)) {
          setStep(selectedEquipment?.equipmentId ? 4 : 1)
        } else {
          setStep(selectedEquipment?.equipmentId ? 3 : 1)
        }
      }
    } catch (error) {
      setStep(1)
    }
  }

  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    try {
      if (accountData?.length > 0) {
        // Ruturns no of arrays based on selected buildings
        const buildingGroups = groupByPropertykey(initialValues, 'buildingId')

        // Get the last building
        const buildingDetails = buildingGroups?.[0]?.properties?.[0]

        const editData =
          [ACTIONS.EDIT, ACTIONS.COPY].includes(mode) && initialValues
            ? buildingDetails
            : {}
        setBuildingsCheckList(
          accountData
            .map((a) => ({
              name: a.name,
              id: a.id,
              checked: editData?.buildingId === a?.id,
              accountName: a.accountName || '',
              accountId: a.accountId || organizationId,
              familyType: 'location',
              data: 'building'
            }))
            .sort((a, b) => a?.name?.localeCompare(b?.name))
        )
      }
    } catch (error) {}
  }, [accountData])

  useEffect(() => {
    if (organizationId) {
      const filter = { accountId: { eq: organizationId }, isActive: { eq: 1 } }
      refetchAccount({ filter })
    }
  }, [organizationId])

  const getSelectedPropertyUoMs = () => {
    const propertyUniqueUOMs = checkedValues
      ?.map((x) => {
        return x?.symbol
      })
      .filter((x) => x && x !== '')
    setSelectedUniqueUOMsToParent?.(propertyUniqueUOMs)
    const uoms = [...propertyUniqueUOMs, ...availableUniqueUOMs]
    const uniqueUOMs = [...new Set(uoms)]
    return uniqueUOMs || []
  }

  const checkPropertiesLimitReached = (propertiesList) => {
    const propertyUniqueUOMs = getSelectedPropertyUoMs()
    const isLimitReached = propertyUniqueUOMs?.length >= propertyUOMLimit
    const propertyItems = propertiesList || equipmentPropertiesCheckList
    if (isLimitReached) {
      setEquipmentPropertiesCheckList(
        propertyItems?.map((property) => {
          return {
            ...property,
            disabled:
              isLimitReached &&
              !property.checked &&
              !propertyUniqueUOMs.includes(property?.symbol?.Symbol)
          }
        })
      )
    } else {
      setEquipmentPropertiesCheckList(
        propertyItems?.map((property) => {
          return {
            ...property,
            disabled: false
          }
        })
      )
    }
  }

  useEffect(() => {
    checkPropertiesLimitReached()
  }, [checkedValues])

  useEffect(() => {
    try {
      if (pickerType === PROPERTY) {
        try {
          // this is some special case, only to handle in calc points modal
          // where we don't have to show selected property, but have to be in step 4
          const isPropertyPresent = rootData?.every(
            (x) => x?.propertyKey && x?.propertyKey !== ''
          )
          isPropertyPresent && setCheckedValues(rootData)
        } catch (error) {
          return []
        }
      }
      update(rootData || [])
    } catch (e) {}
  }, [rootData])

  // Gets equipments types for a given building
  const getEquipmentTypes = async (buildingId) => {
    try {
      const requestBody = { requestBody: JSON.stringify([buildingId]) }
      const equipmentTypes = await refetchEquipmentTypes(requestBody)
      const res = JSON.parse(equipmentTypes)
      if (res && res.statusCode === 200) {
        let selectedEquipmentTypes = []
        if (rootData?.length > 0) {
          selectedEquipmentTypes = rootData
            ?.filter((x) => x.buildingId === buildingId)
            .map((x) => x?.equipmentType)
        }
        selectedEquipmentTypes = [...selectedEquipmentTypes]

        const equipmentTypesFormatted = Object.keys(
          res.body?.EquipmentType
        )?.map((type, i) => ({
          name: type,
          id: i,
          checked: false,
          familyType: Object.values(res.body?.EquipmentType)[i],
          data: 'EquipmentTypes'
        }))

        const equipmentTypes = equipmentTypesFormatted.map((x) => {
          return {
            ...x,
            checked: selectedEquipmentTypes.includes(x?.familyType)
          }
        })

        const equipmentTypesByWidgets = isEnergyWidget
          ? equipmentTypes?.filter((e) =>
              ENERGY_METER_TYPES.includes(e?.familyType)
            )
          : equipmentTypes
        setEquipmentTypesCheckList(
          equipmentTypesByWidgets.sort((a, b) =>
            a?.name?.localeCompare(b?.name)
          )
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    try {
      // If equipmentid presents in rootdata, which means a equipment is selected already
      // This will work for both edit and create
      // We may have to change a logic a bit, if we have to support more than 1 equipment selection
      let rootEquipmentIds = []
      if (rootData?.length > 0) {
        rootEquipmentIds = rootData
          ?.filter((x) => x.buildingId === selectedBuilding?.id)
          ?.map((x) => x?.equipmentId)
      }
      const selectedEquipmentIds = [...new Set(rootEquipmentIds)]
      if (equipments?.length) {
        setEquipmentsCheckList(
          equipments
            .map((a) => ({
              familyType: a.type,
              name: a.name,
              id: a.id,
              checked: selectedEquipmentIds.includes(a.id),
              data: 'equipmentCheckList',
              disabled: (() => {
                // This will run only for Baseline object picker
                // Have to disable equipments, which have baselines already
                if (baselineEquipments.length > 0) {
                  return baselineEquipments.includes(a.id)
                } else {
                  //return !selectedEquipmentIds.includes(a.id) && equipmentId
                  return false
                }
              })()
            }))
            .sort((a, b) => a?.name?.localeCompare(b?.name))
        )
      }
    } catch (e) {
      console.log(error)
    }
  }, [equipments])

  // Get equipments for given building and equipment type
  const getEquipments = async (equipmentInfo, isInitialEdit) => {
    try {
      const buildingId = isInitialEdit
        ? equipmentInfo?.buildingId
        : selectedBuilding.id
      const familyType = equipmentInfo?.familyType
      const equipments = await refetchEquipments({
        buildingId: buildingId,
        type: familyType
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getEquipmentProperties = async (equipmentInfo, isInitialEdit) => {
    try {
      if (equipmentInfo?.id) {
        try {
          const equipmentId = isInitialEdit
            ? equipmentInfo?.equipmentId
            : equipmentInfo?.id
          const [
            equipmentProperties,
            equipmentPropertiesCalculated,
            relavantProperties
          ] = await Promise.all([
            getEquipmentPropertiesQuery({
              equipmentId: equipmentId
            }),
            getEquipmentCalculatedPropertiesQuery({
              equipmentId: equipmentId
            }),
            getFilteredProperties({
              id: equipmentInfo?.id,
              type: equipmentInfo?.familyType
            })
          ])
          let allEquipmentProperties = [
            ...equipmentProperties,
            ...equipmentPropertiesCalculated
          ]

          if (relavantProperties?.length > 0) {
            allEquipmentProperties = filterPropertiesBasedOnMetaData(
              allEquipmentProperties,
              relavantProperties
            )
          }

          let selectedPropertiesId = []
          let selectedPropertiesList = []
          if (rootData?.length > 0) {
            selectedPropertiesId = rootData
              ?.filter((x) => equipmentInfo?.id === x?.equipmentId)
              ?.map((x) => x?.id)
          }

          if (allEquipmentProperties?.length > 0) {
            // Filter analog, not needed for Chart data
            const mappedProperties = allEquipmentProperties
              .filter((x) => x.targetDataType !== 'varchar')
              .map((a) => ({
                name: a.tisDisplayName || a.targetKey,
                key: a.targetKey,
                id: a.id,
                targetMeasureUnit: a.targetMeasureUnit,
                data: 'Properties',
                propertyColor: (() => {
                  const color = selectedPropertiesList?.find(
                    (property) => property?.key === a.targetKey
                  )?.propertyColor
                  return color || getRandomColor()
                })(),
                symbol:
                  allDimensions?.find((x) => x.UoM === a.targetMeasureUnit) ||
                  '',
                checked: selectedPropertiesId?.includes(a.id)
              }))
              .sort((a, b) => a?.name?.localeCompare(b?.name))

            const propertiesListFinal = mappedProperties.filter(
              (x) => x.name !== null
            )
            // Display only props with names or target keys
            setEquipmentPropertiesCheckList(propertiesListFinal)
            checkPropertiesLimitReached(propertiesListFinal)
          } else {
            setEquipmentPropertiesCheckList([])
          }
        } catch (error) {
          setEquipmentPropertiesCheckList([])
        }
      }
    } catch (error) {
      setEquipmentPropertiesCheckList([])
    }
  }

  const updateBuilding = async (buildingInfo) => {
    try {
      if (buildingInfo) {
        const itemsList = [...buildingsCheckList]
        let alreadyCheckedIndex = itemsList.findIndex((i) => i.checked)
        const index = itemsList.findIndex((i) => i.id === buildingInfo.id)
        if (pickerType === BUILDING) {
          if (buildingSelectLimit >= 2) {
            itemsList[index].checked = !buildingInfo.checked
          } else if (buildingSelectLimit === 1) {
            if (alreadyCheckedIndex !== -1) {
              itemsList[alreadyCheckedIndex].checked = false
            }
            if (alreadyCheckedIndex !== index) {
              itemsList[index].checked = !buildingInfo.checked
            }
          }
          const selected = itemsList
            .filter((v) => v.checked)
            .map((item) => {
              return {
                buildingId: item.id,
                buildingName: item.name,
                accountName: item.accountName,
                accountId: item.accountId,
                timeZone: item.tz
              }
            })
          setCheckedValues(selected)
          setRootData(selected)
          //  setBuildingIds(selected?.map((m) => m?.buildingId) ?? [])
        } else {
          const rootdataBuildings = rootData?.map((x) => x.buildingId)
          const isChecked = itemsList.find(
            (i) => i.id === buildingInfo.id
          )?.checked

          if (rootdataBuildings.includes(buildingInfo.id) || !isChecked) {
            const selectedbuildings = [
              ...rootData.map((x) => x?.buildingId),
              buildingInfo.id,
              buildingId
            ]
            const allBuildingIds = [...new Set(selectedbuildings)]
            await setSeletectedBuildingsToken(
              allBuildingIds,
              claims ? claims : []
            )
            setSelectedBuilding(buildingInfo)
            setSelectedEquipment(null)
            setSelectedEquipmentType(null)
            await getEquipmentTypes(buildingInfo.id)
            setStep(2)
          } else {
            const selectedBuildingIndex = itemsList.findIndex(
              (i) => i.id === buildingInfo.id
            )
            itemsList[selectedBuildingIndex].checked = false
            setSelectedBuilding(null)
            setBuildingsCheckList(itemsList)
          }
        }
        setBuildingsCheckList(itemsList)
      }
    } catch (error) {}
  }

  const updateEquipmentType = async (equipmentTypeInfo) => {
    try {
      if (equipmentTypeInfo) {
        const itemsList = [...equipmentTypesCheckList]

        const equipmentTypeIndex = itemsList.findIndex(
          (i) => i.familyType === equipmentTypeInfo.familyType
        )
        const isChecked = itemsList.find(
          (i) => i.familyType === equipmentTypeInfo.familyType
        )?.checked

        let selectedEquipmentTypesFamily = []
        if (rootData?.length > 0) {
          selectedEquipmentTypesFamily = rootData
            .filter((x) => x?.buildingId === selectedBuilding?.id)
            .map((x) => x?.equipmentType)
          selectedEquipmentTypesFamily = [
            ...new Set(selectedEquipmentTypesFamily)
          ]
        }
        if (
          !isChecked ||
          selectedEquipmentTypesFamily.includes(equipmentTypeInfo?.familyType)
        ) {
          setSelectedEquipmentType(equipmentTypeInfo)
          await getEquipments(equipmentTypeInfo)
          setStep(3)
        } else {
          itemsList[equipmentTypeIndex].checked = false
          setSelectedEquipmentType(null)
          setEquipmentTypesCheckList(itemsList)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateEquipment = async (equipmentInfo) => {
    try {
      if (equipmentInfo) {
        if (!objects.includes(PROPERTY)) {
          const selectedRootElement = {
            equipmentId: itemsList[index].id,
            equipmentName: itemsList[index].name,
            checked: itemsList[index].checked,
            buildingId: selectedBuilding?.id,
            buildingName: selectedBuilding?.name,
            accountName: selectedBuilding?.accountName,
            accountId: selectedBuilding?.accountId,
            equipmentType: selectedEquipmentType?.name,
            equipmentFamily: selectedEquipmentType?.familyType
          }

          if (selectedRootElement?.checked) {
            toRootData.push(selectedRootElement)
          } else if (!selectedRootElement?.checked) {
            toRootData = rootData.filter(
              (i) => i.equipmentId !== selectedRootElement.equipmentId
            )
          }
          setRootData(toRootData)
          const rootDataLimit = toRootData.length >= equipmentSelectLimit
          setIsRootDataLimit(rootDataLimit)
          setEquipmentsCheckList(
            itemsList.map((item) => ({
              ...item,
              disabled: (() => {
                // This will run only for Baseline object picker
                // Have to disable equipments, which have baselines already
                if (baselineEquipments.length > 0) {
                  return (
                    baselineEquipments.includes(item.id) ||
                    (rootDataLimit && !item.checked)
                  )
                } else {
                  return false
                }
              })()
            }))
          )
        } else {
          // If it is not equipment unselect, go to next step
          let selectedEquipments = []
          const currentEquipmentId = equipmentInfo?.id
          if (rootData?.length > 0) {
            selectedEquipments = rootData
              ?.filter((x) => x.buildingId === selectedBuilding?.id)
              ?.map((x) => x?.equipmentId)
            selectedEquipments = [...new Set(selectedEquipments)]
          }
          const itemsList = [...equipmentsCheckList]
          const equipmentIndex = itemsList.findIndex(
            (i) => i.id === equipmentInfo.id
          )
          const isChecked = itemsList.find(
            (i) => i.id === equipmentInfo.id
          )?.checked

          // if selected equipment id already in root, which means it has associated property, go to next step
          if (selectedEquipments?.includes(currentEquipmentId) || !isChecked) {
            const selectedEquipmentIds = [
              ...selectedEquipments,
              currentEquipmentId
            ]
            setEquipmentsCheckList(
              itemsList.map((item) => ({
                ...item,
                checked: selectedEquipmentIds?.includes(item?.id),
                disabled: false
              }))
            )
            setSelectedEquipment(equipmentInfo)
            await getEquipmentProperties(equipmentInfo)
            setStep(4)
          } else {
            itemsList[equipmentIndex].checked = false
            setEquipmentsCheckList(itemsList)
            setSelectedEquipment(null)
          }
        }
      }
    } catch (error) {}
  }

  const updateProperty = (propertyInfo) => {
    try {
      if (propertyInfo) {
        const propertiesList = [...equipmentPropertiesCheckList]
        let currentPropertyIndex = propertiesList.findIndex(
          (i) => i.id === propertyInfo.id
        )
        if (propertiesList[currentPropertyIndex]?.checked) {
          // if already checked, remove from selected list
          propertiesList[currentPropertyIndex].checked = false
          const propertyList = [...rootData]
          const newList = propertyList?.filter((x) => x.id !== propertyInfo?.id)
          setRootData(newList)
        } else {
          propertiesList[currentPropertyIndex].checked = true
          const propertyList = [...rootData]
          const selectedProperty = {
            buildingId: selectedBuilding?.id,
            equipmentId: selectedEquipment?.id,
            equipmentType: selectedEquipmentType?.familyType,
            id: propertyInfo?.id,
            propertyKey: propertyInfo.key,
            propertyName: propertyInfo.name,
            uom: propertyInfo?.symbol?.UoM || '',
            symbol: propertyInfo?.symbol?.Symbol || '',
            color: propertyInfo?.propertyColor,
            equipmentName: selectedEquipment?.name,
            buildingName: selectedBuilding?.name
          }
          propertyList.push(selectedProperty)
          setRootData(propertyList)
        }
        setEquipmentPropertiesCheckList(propertiesList)
      }
    } catch (error) {
      setEquipmentPropertiesCheckList([])
    }
  }

  const handleEquipmentPropertiesBackButton = () => {
    try {
      let selectedEquipments = []
      const currentEquipmentId = selectedEquipment?.id
      if (rootData?.length > 0) {
        selectedEquipments = rootData?.map((x) => x?.equipmentId)
      }
      currentEquipmentId && selectedEquipments.push(currentEquipmentId)
      const selectedEquipmentIds = [...new Set(selectedEquipments)]
      setEquipmentsCheckList((equipmentsCheckList) =>
        equipmentsCheckList.map((equipment) => {
          return {
            ...equipment,
            checked: selectedEquipmentIds.includes(equipment.id)
          }
        })
      )
      setStep(3)
    } catch (error) {}
  }

  const handleEquipmentBackButton = async () => {
    try {
      await getEquipmentTypes(selectedBuilding?.id)
      let selectedEquipmentTypes = []
      const currentEquipmentType = selectedEquipmentType?.familyType
      if (rootData?.length > 0) {
        selectedEquipmentTypes = rootData
          ?.filter((x) => x.buildingId === selectedBuilding?.id)
          ?.map((x) => x?.equipmentType)
        selectedEquipmentTypes = [...new Set(selectedEquipmentTypes)]
      }
      selectedEquipmentTypes = [...selectedEquipmentTypes, currentEquipmentType]
      setEquipmentTypesCheckList((equipmentTypesCheckList) =>
        equipmentTypesCheckList.map((item) => ({
          ...item,
          checked: selectedEquipmentTypes.includes(item?.familyType)
        }))
      )
      setStep(2)
    } catch (error) {}
  }

  const handleEquipmentTypesBackButton = () => {
    try {
      let selectedBuildingIds = [selectedBuilding?.id]
      if (rootData?.length > 0) {
        selectedBuildingIds = [
          ...selectedBuildingIds,
          ...rootData?.map((x) => x.buildingId)
        ]
      }
      setBuildingsCheckList((buildingsCheckList) =>
        buildingsCheckList.map((building) => {
          return {
            ...building,
            //disabled: currentBuildingIds.includes(building.id),
            checked: selectedBuildingIds.includes(building.id)
          }
        })
      )
      setStep(1)
    } catch (error) {}
  }

  const removeSelection = (c) => {
    try {
      if (objects.includes(PROPERTY)) {
        updateProperty(c)
      } else if (objects.includes(EQUIPMENT)) {
        setRootData((rootData) =>
          rootData.filter(
            (item) =>
              c.buildingId !== item.building &&
              c.equipmentId !== item.equipmentId
          )
        )
        setEquipmentsCheckList((equipmentsCheckList) =>
          equipmentsCheckList.map((item) => ({
            ...item,
            checked: c.equipmentId === item.id ? false : item.checked,
            disabled: false
          }))
        )
        setEquipmentTypesCheckList((equipmentTypesCheckList) =>
          equipmentTypesCheckList.map((item) => ({
            ...item,
            checked: c.equipmentType === item.name ? false : item.checked
          }))
        )
      } else {
        setRootData((rootData) =>
          rootData.filter((item) => c.buildingId !== item.buildingId)
        )
        setBuildingsCheckList((buildingsCheckList) =>
          buildingsCheckList.map((item) => ({
            ...item,
            checked: c.buildingId === item.id ? false : item.checked
          }))
        )
      }
    } catch (error) {}
  }

  const ofTranslate = translate("of")
  const inTranslate = translate("in")
  const atTranslate = translate("at")

  const { translatedJSON } = useSelector((state) => state?.translationCombinedSlice)
  const dispatch = useDispatch()

  const createSourceDisplayName = (table) => {
    const tempSourceDisplayList = table?.map(el => {
      return {
        translatedValue: el.propertyName,
        [el.id]: el.id
      }
    })
    return tempSourceDisplayList
  }

  const [translatedCheckedValues, setTranslatedCheckedValues] = useState([])
  useEffect(() => {
    if (checkedValues.length > 0)
      sourceTranslationHelper(checkedValues).then(res => joinArray(res))
  }, [dispatch, checkedValues])

  const joinArray = (response) => {
    const combinedArray = response && response?.map((item) => {
      const x = checkedValues.find((items => Object.keys(item)[1] == items?.id));
      return x ? { ...x, propertyName: item.translatedValue } : null;
    });
    setTranslatedCheckedValues(combinedArray)
  }

  const sourceTranslationHelper = async (dataTotranslate) => {
    let finalData;
    const tempList = createSourceDisplayName(dataTotranslate)
    const analaogList = await dispatch(translateJSON({ json: tempList })).then(res => {
      finalData = res.payload
    })
    return finalData
  }

  return (
    <Wrapper fullHeight={fullHeight} error={error || errorText}>
      <>
        {buildingsLoading ||
        equipmentTypesLoading ||
        equipmentsLoading ||
        loadingProperties ||
        loadingDimensions ||
        loadingCalculatedProperties ? (
          <Spinner />
        ) : (
          <>
            <>
              {step === 1 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  values={buildingsCheckList}
                  update={(item) => updateBuilding(item)}
                  error={error}
                  dataHeader={
                    <TranslateComponent>{'Buildings'}</TranslateComponent>
                  }
                  isFilteredBy={true}
                />
              )}
              {step === 2 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  update={(item) => updateEquipmentType(item)}
                  values={equipmentTypesCheckList}
                  error={error}
                  navigationHeader={
                    <div
                      onClick={handleEquipmentTypesBackButton}
                      onKeyDown={handleEquipmentTypesBackButton}
                    >
                      <IconSVG
                        name={'left-caret'}
                        margin="0px 4px"
                        color={colors.WHITE}
                        hover={colors.WHITE}
                        width="15px"
                      />
                      <span>
                        {' '}
                        <TranslateComponent>All Buildings</TranslateComponent>
                      </span>
                    </div>
                  }
                  dataHeader={
                    <span>
                      {' '}
                      <TranslateComponent>
                        {'Equipment Types'}
                      </TranslateComponent>
                    </span>
                  }
                  buildingName={`${selectedBuilding?.name} ${ofTranslate} ${selectedBuilding?.accountName}`}
                  isFilteredBy={true}
                  type="EquipmentTypes"
                />
              )}
              {step === 3 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  update={(item) => updateEquipment(item)}
                  values={equipmentsCheckList}
                  error={error}
                  navigationHeader={
                    <div
                      onClick={handleEquipmentBackButton}
                      onKeyDown={handleEquipmentBackButton}
                    >
                      <IconSVG
                        name={'left-caret'}
                        margin="0px 4px"
                        color={colors.WHITE}
                        hover={colors.WHITE}
                        width="15px"
                      />
                      <span>
                        <TranslateComponent>
                          {'All Equipment Types'}
                        </TranslateComponent>
                      </span>
                    </div>
                  }
                  dataHeader={
                    <span>
                      <TranslateComponent>{'Equipment'}</TranslateComponent>
                    </span>
                  }
                  buildingName={`${selectedEquipmentType?.name} ${atTranslate} ${selectedBuilding?.name} ${ofTranslate} ${selectedBuilding?.accountName}`}
                  isFilteredBy={true}
                />
              )}
              {step === 4 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  update={(item) => updateProperty(item)}
                  values={equipmentPropertiesCheckList}
                  error={error}
                  navigationHeader={
                    <div
                      onClick={handleEquipmentPropertiesBackButton}
                      onKeyDown={handleEquipmentPropertiesBackButton}
                    >
                      <IconSVG
                        name={'left-caret'}
                        margin="0px 4px"
                        color={colors.WHITE}
                        hover={colors.WHITE}
                        width="15px"
                      />
                      <span>
                        <TranslateComponent>
                          {'All Equipments'}
                        </TranslateComponent>
                      </span>
                    </div>
                  }
                  dataHeader={
                    <span>
                      <TranslateComponent>{`Property`}</TranslateComponent>
                    </span>
                  }
                  buildingName={`${
                    selectedEquipment?.name ||
                    selectedEquipment?.equipmentName ||
                    ''
                  }  ${ofTranslate} ${selectedEquipmentType?.name} ${atTranslate} ${
                    selectedBuilding?.name
                  } ${ofTranslate} ${selectedBuilding?.accountName}`}
                  isFilteredBy={true}
                  type="Properties"
                />
              )}
            </>
            {checkedValues?.length > 4 && (
              <StyleSeperator id="separator" ref={seperatorRef}>
                <div></div>
                <div></div>
              </StyleSeperator>
            )}
            <StyledDivFoot ref={secondRef}>
              {translatedCheckedValues?.length ? (
                <BottonSection>
                  <BottomHead>
                    <TranslateComponent>Selected</TranslateComponent>:
                  </BottomHead>
                  <BottomList>
                  {translatedCheckedValues?.map((c, i) => {
                       return (
                      <BottomListItem key={`close-${i}`}>
                        <IconSVG
                          onClick={() => {
                            removeSelection(c)
                          }}
                          name="close-circle"
                          color={colors.TBA_DARKER_GREY}
                          margin="2px 4px 0px 0px"
                          width="14px"
                          height="14px"
                        />
                        <BottomText>
                          <span
                            title={`${c?.propertyName} ${ofTranslate} ${c?.equipmentName} ${inTranslate} ${c?.buildingName}`}
                          >
                            <b>
                              {c?.propertyName}
                            </b>{' '}
                            <TranslateComponent>of</TranslateComponent>{' '}
                            {c?.equipmentName}
                            <TranslateComponent>in</TranslateComponent>
                            {c?.buildingName}
                          </span>
                        </BottomText>
                      </BottomListItem>
                    )
                    })}
                  </BottomList>
                </BottonSection>
              ) : (
                ''
              )}
            </StyledDivFoot>
          </>
        )}
      </>
    </Wrapper>
  )
}

export default React.memo(ChartObjectPicker)
