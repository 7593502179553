import XYChart from "../../../../../chart-library/Charts/XYChart";


export default function CurveChart({
  onPointSelection,
  onPointsUpdate,
  dataPoints,
  xAxisRange,
  yAxisRange,
  uomY
}) {

  const chartConfigData = {
    canvas: {
      canvasDetails: {
        0: {
          renderAreaShade: {
            fill: '#F4F4F4',
            opacity: '1'
          },
          tooltip: {
            triggerBy: 'item'
          }
        }
      }
    },
    x: [
      {
        key: 'x1',
        canvasIndex: '0',
        scale: {
          props: {
            defaultRange: xAxisRange,
            type: 'scaleLinear'
          }
        },
        axis: {
          axisStroke: '#CCCCCC',
          tickStroke: '#CCCCCC',
          strokeWidth: 1.2,
          tickCount: 7,
          visibleAt: ['bottomBottom'],
          // UoM is hardcoded for now, replace with dynamic value based on property
          format: (value, info) => value + (info.isLast ? ` %` : ''),
          textStyles: {
            fontWeight: 'bold'
          }
        },
        grid: [
          {
            tickCount: 7,
            stroke: '#CCCCCC',
            includeAxis: true
          }
        ]
      }
    ],
    y: [
      {
        key: 'y1',
        canvasIndex: '0',
        scale: {
          props: {
            defaultRange: yAxisRange,
            type: 'scaleLinear'
          }
        },
        axis: {
          axisStroke: '#CCCCCC',
          tickStroke: '#CCCCCC',
          strokeWidth: 1.2,
          tickCount: 7,
          visibleAt: ['leftLeft'],
          // UoM is hardcoded for now, replace with dynamic value based on property
          format: (value, info) => info.isLast ? `${value} ${uomY}` : value,
          textStyles: {
            fontWeight: 'bold'
          }
        },
        grid: [
          {
            tickCount: 7,
            stroke: '#CCCCCC',
            includeAxis: true
          }
        ]
      }
    ],
    series: {
      types: [
        {
          id: '0',
          name: '',
          seriesKey: '0',
          xAxisKey: 'x1',
          yAxisKey: 'y1',
          canvasIndex: '0',
          show: true,
          type: 'dragLine',
          tooltipDataModelFormatter: (props) => {
            return {
              ...props,
              groupBy: false,
              value: `X: ${props.xValue}  Y: ${props.yValue}`,
              color: null
            }
          },
          // On select of a point, this method will be called with selected points
          // This can be used for delete point functionality
          onSelect: (point) => {
            onPointSelection(point)
          },
          // On change of any point such as chaning the position / adding new point, this method will be called with all points
          onChange: (points) => {
            onPointsUpdate(points)
          },
          linkStyle: {
            stroke: '#367326'
          },
          nodeStyle: {
            stroke: '#367326',
            fillOnDrag: '#367326'
          }
        }
      ],
      data: {
        0: {
          data: dataPoints
        }
      }
    },
    width: 380,
    height: 400,
    dataLastUpdatedAt: +new Date()
  }

    return <XYChart {...chartConfigData}/>
}