import React, { memo, useState } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { createFormControlStyle } from '../../styles/styleCreators'
import { eventPub, isBoolean, isEqual } from '../../utils'
import { useFormFieldState } from '../FromControl'
import useClickAway from '../../utils/useClickAway'
import useListKeyNavigation from '../../utils/useListKeyNavigation'
import Popover from '../Popover'
import IconComp from '../Icon'
import { noWrap, scrollbar } from '../../styles/common'
import translate from 'src/common/translations'

const selectRootstyles = createFormControlStyle({ base: true })
const dropDwonIconStyles = createFormControlStyle({ icon: true })

const SelectRoot = styled.div`
  ${selectRootstyles}
  ${(props) => props.fullWidth && 'width:100%'}
`

const HiddenInput = styled.input`
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
`

const View = styled.div`
  align-items: center;
  align-self: stretch;
  box-sizing: inherit;
  cursor: pointer;
  display: flex;
  flex: 1;
  outline: none;
  padding: 8px;
  ${(props) =>
    props.size === 'small'
      ? 'padding: 2px 8px; min-height: 20px; font-size: 14px;'
      : 'padding: 8px; min-height: 36px;'}
  ${noWrap}
`
const TextView = styled.span(noWrap)

const IconRoot = styled.div`
  ${dropDwonIconStyles}
  ${({ disabled }) => disabled && ' opacity: 0.4;'}
  ${(props) => props.size === 'small' && 'padding: 2px 6px;'}
  transform: ${(props) => (props.open ? 'scaleY(-1)' : 'scaleY(1)')};
`

const DropdownRoot = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.width ?? 'auto'};
  max-height: ${(props) => props.maxHeight ?? 'auto'};
  height: auto;
  overflow: auto;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  outline: none;
  ${scrollbar}
`

const HiddenFocusableItem = styled.li`
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
`

const skipItemValidator = (nextEl) => {
  return (
    nextEl.disabled ||
    nextEl.getAttribute('aria-disabled') === 'true' ||
    nextEl.getAttribute('data-omit') === 'true'
  )
}

const displayFn = (value, renderFn, placeholder, selectedTranslate) => {
  if (!renderFn) {
    if (Array.isArray(value))
      return value.length === 0 ? placeholder : value.length + " " + selectedTranslate
    else return value
  }

  return renderFn(value, placeholder)
}

const popOverDefaultConfig = {
  placement: 'bottom-start'
}

const DropDownIcon = styled(IconComp)`
  width: inherit;
  height: inherit;
  path {
    fill: inherit;
  }
`

const defaultDropdownIcon = <DropDownIcon name="Down" hover="inherit" />

// --------------------------------------------- Component ---------------------------------------------

const SelectInput = React.forwardRef(function SelectInput(inProps, ref) {
  const allProps = useFormFieldState(inProps)
  const {
    autoWidth,
    children,
    className,
    // keycodes to close menu list on click of respective key (default : Enter - 27, Tab - 9)
    KeyCodes = [27, 9],
    disabled,
    enableMenuScroll,
    formControlStateClass,
    fullWidth,
    onFocus,
    icon = defaultDropdownIcon,
    IconProps = {},
    id,
    menuHeight,
    multiple,
    name,
    onBlur,
    onChange,
    onClick,
    onClickElementRef,
    onClose,
    onOpen,
    open: openProp,
    placeholder = '',
    popoverConfig: popoverConfigProp,
    readOnly,
    renderValue,
    RenderValueProps = {},
    value,
    size,
    ...rest
  } = allProps

  // eslint-disable-next-line no-const-assign
  const popoverConfig = {
    ...popOverDefaultConfig,
    ...(popoverConfigProp || {})
  }

  // Required props for hidden input
  const inputProps = {
    ...rest,
    disabled,
    id,
    name,
    onChange,
    placeholder,
    readOnly,
    value
  }

  // Refenece of root element(selectRoot)
  const [selectNode, setSelectNode] = React.useState(null)

  const [menuMinWidthState, setMenuMinWidthState] = React.useState(null)

  // Anchor Element for popover element
  const [referenceElementState, setReferenceElement] = useState(null)

  const [listContainerRef, handleOptionKeyBoardNavigation] =
    useListKeyNavigation({
      skipItemValidator
    })

  // Handler to share elRef with other functionalites
  const handleSelectRef = React.useCallback((node) => {
    if (node) {
      setSelectNode(node)
      setReferenceElement(node)
    }
  }, [])

  // check this is controlled / uncontrolled
  const { current: isOpenControlled } = React.useRef(isBoolean(openProp))

  const dropdownRef = React.useRef()

  // Dropdown open / close internal state
  const [openState, setOpenState] = useState(false)

  // Open popover based on user / interal state
  const open = selectNode !== null && (isOpenControlled ? openProp : openState)

  // Event handlers
  const updateDropdownOpenClose = (open, event) => {
    if (disabled || readOnly) return

    if (open && onOpen) {
      onOpen(event)
    } else if (onClose) {
      onClose(event)
    }

    if (!isOpenControlled) {
      setMenuMinWidthState(autoWidth ? null : selectNode.clientWidth)
      setOpenState(open)
      if (!open && onClickElementRef?.current) {
        onClickElementRef.current.focus()
      }
    }
  }

  // click away hook & listener
  const onClickAwayListener = (event) => {
    if (open) {
      updateDropdownOpenClose(!open, event)
    }
  }

  const [nodeRef, disableAutoCloseOnClickOfInsideDropdownFn] = useClickAway({
    active: open,
    onClickAway: onClickAwayListener,
    keyCodes: KeyCodes
  })

  const handleSelectOnKeyDown = (event) => {
    if (!readOnly) {
      const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter']

      if (validKeys.indexOf(event.key) !== -1) {
        event.preventDefault()
        updateDropdownOpenClose(!open, event)
      }
    }
  }

  const handleSelectOnClick = (open) => (event) => {
    onClick(event)
    updateDropdownOpenClose(!open, event)
  }

  const handleSelectOnChange = (event, child) => {
    if (onChange) {
      onChange(event, child)
    }
  }

  const optionValueChange = (event, child) => {
    let newValue

    // Don't act for disabled element or omit props presents
    if (
      event.currentTarget.hasAttribute('disabled') ||
      child.props.omit ||
      child.props.disableActionsOnly
    ) {
      disableAutoCloseOnClickOfInsideDropdownFn()
      return
    }

    if (multiple) {
      newValue = Array.isArray(value) ? value.slice() : []

      const propValue = child.props.value
      const itemIndex = newValue.indexOf(propValue)
      if (itemIndex === -1) {
        newValue.push(propValue)
      } else {
        newValue.splice(itemIndex, 1)
      }
    } else {
      newValue = child.props.value
    }

    if (child.props.onClick) {
      child.props.onClick(event)
    }

    if (value !== newValue) {
      if (onChange) {
        disableAutoCloseOnClickOfInsideDropdownFn()

        const callback = (clonedEvent) => {
          handleSelectOnChange(clonedEvent, child)
        }

        eventPub(event, newValue, name, callback)
      }
    }

    if (!multiple) {
      updateDropdownOpenClose(false, event)
    }
  }

  const handleOptionOnClick = (child) => (event) => {
    optionValueChange(event, child)
  }

  const handleOptionOnKeyDown = (child) => (event) => {
    if (event.key !== 'Enter') {
      return
    }

    optionValueChange(event, child)

    if (child.props.onKeyUp) {
      child.props.onKeyUp(event)
    }
  }

  // clone children & create items with new props
  const childrenArray = React.Children.toArray(children)

  const items = childrenArray.map((child, index) => {
    if (!React.isValidElement(child)) {
      return child
    }

    let selected

    if (multiple && Array.isArray(value)) {
      selected = (value || []).some((v) => v === child.props.value)
    } else {
      selected = isEqual(value, child.props.value)
    }

    return React.cloneElement(child, {
      onClick: handleOptionOnClick(child),
      onKeyDown: handleOptionOnKeyDown(child),
      selected,
      multiple,
      tabIndex: 0,
      'aria-disabled': isEqual(child.props.disabled, true),
      'data-omit': isEqual(child.props.omit, true)
    })
  })

  const selectedTranslate = translate("Selected")
  // create display value
  const viewRenderValue = displayFn(value, renderValue, placeholder, selectedTranslate)

  // Avoid performing a layout computation in the render method.
  let menuMinWidth = menuMinWidthState

  if (!autoWidth && isOpenControlled && selectNode) {
    menuMinWidth = selectNode.clientWidth
  }

  let menuMaxHeight = 'auto'

  if (enableMenuScroll) {
    menuMaxHeight = menuHeight ? menuHeight : '248px'
  }

  // render
  return (
    <SelectRoot
      className={`${className} ${formControlStateClass}, ${open && 'focused'}`}
      ref={handleSelectRef}
      fullWidth={fullWidth}
    >
      <View
        {...RenderValueProps}
        id={id}
        tabIndex={disabled ? -1 : 0}
        onClick={handleSelectOnClick(open)}
        onKeyDown={handleSelectOnKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={onClickElementRef}
        role="button"
        size={size}
      >
        {renderValue ? (
          viewRenderValue || placeholder
        ) : (
          <TextView>{viewRenderValue || placeholder}</TextView>
        )}
      </View>
      <HiddenInput
        tabIndex="-1"
        ref={ref}
        {...inputProps}
        disabled={disabled}
      />
      <IconRoot
        disabled={disabled}
        open={open}
        onClick={handleSelectOnClick(open)}
        size={size}
        {...IconProps}
      >
        {icon}
      </IconRoot>
      <div ref={dropdownRef}></div>
      {open &&
        referenceElementState &&
        ReactDOM.createPortal(
          <Popover
            ref={nodeRef}
            referenceElementState={referenceElementState}
            config={popoverConfig}
          >
            <DropdownRoot
              tabIndex="0"
              onKeyDown={handleOptionKeyBoardNavigation}
              ref={listContainerRef}
              aria-expanded={open}
              maxHeight={menuMaxHeight}
              width={`${menuMinWidth ? `${menuMinWidth}px` : 'auto'}`}
            >
              {/* Default focused element on Open */}
              <HiddenFocusableItem tabIndex="0" />
              {items}
            </DropdownRoot>
          </Popover>,
          dropdownRef.current
        )}
    </SelectRoot>
  )
})

SelectInput.propTypes = {
  /**
   * If true, the width of the popover will automatically be set according to the items inside the menu,
   * otherwise it will be at least the width of the select input.
   */
  autoWidth: PropTypes.bool,

  /**
   * The Option compoent to populate the select with. Refer Option component.
   */
  children: PropTypes.node,

  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * The id of the input element.
   */
  id: PropTypes.string,

  /**
   * Dropdown Icon for this component.
   */
  icon: PropTypes.node,

  /**
   * Props applied to Dropdown icon
   */
  IconProps: PropTypes.object,

  /**
   * If true, value must be an array and the menu will support multiple selections.
   */
  multiple: PropTypes.bool,

  /**
   * Name attribute of the input element.
   */
  name: PropTypes.string,

  /**
   * Callback fired when the value is changed.
   */
  onChange: PropTypes.func,

  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: PropTypes.func,

  /**
   * Callback fired when the component requests to be opened.
   */
  onOpen: PropTypes.func,

  /**
   * Type of the input element.
   */
  open: PropTypes.bool,

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,

  /**
   * Popover config to place dropdown menu.
   * Refer popper.js api (https://popper.js.org/docs/v2/constructors/#options) for config
   */
  popoverConfig: PropTypes.object,

  /**
   * It prevents the user from changing the value of the field
   */
  readOnly: PropTypes.bool,

  /**
   * Props applied to the clickable div element where selected value displayed.
   */
  RenderValueProps: PropTypes.object,

  /**
   * Render the selected value.
   */
  renderValue: PropTypes.func,

  /**
   * The value of the input element, required for a controlled component.
   */
  value: PropTypes.any
}

SelectInput.defaultProps = {
  autoWidth: false,
  disabled: false,
  error: false,
  fullWidth: false,
  multiple: false,
  placeholder: 'Select',
  readOnly: false
}

export default memo(SelectInput)

export { default as Option } from '../Option'
