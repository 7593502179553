import { Button, ButtonGroup } from '@aws-amplify/ui-react'
import Table from 'src/components/Table/clientSideTable'
import { TranslateComponent } from 'src/common/translations'
import { DenaliTableStyle } from 'src/denali-components/Table/table.d'
import { AccessControl } from 'src/components/accessControl'
import { DataError } from 'src/denali-components/Table/DataError'

/**
 * UtilityBillDataWater
 * Utility bill data - Water table
 */
export const UtilityBillDataWater = ({
  waterHeader,
  waterEntries,
  waterTableWidth,
  filtersList,
  checkboxClick,
  onUpdateControlsFunc,
  columnOrderDown,
  listDataIsLoading,
  toggleUploadBillShowModal,
  errors
}) => {
  if(errors.totalError)
    return <DataError tableName="water" />

  return (
    <>
      <Table
        testName="utility-bill-data-list"
        header={waterHeader}
        rows={waterEntries}
        tableWidth={waterTableWidth}
        rowCheckbox={false}
        search={true}
        filtersList={filtersList}
        checkboxClick={checkboxClick}
        isShowPagination={false}
        searchFields={['year']}
        onUpdateControlsFunc={onUpdateControlsFunc}
        columnOrderDown={columnOrderDown}
        tableRowOverflowHidden={false}
        isDenaliTable={true}
        denaliTableStyle={DenaliTableStyle.classic}
        listDataIsLoading={listDataIsLoading}
        denaliBulkActions={
          <ButtonGroup>
            <AccessControl id="tc.pages.utility-bill-data.create">
              <Button onClick={toggleUploadBillShowModal} data-testid='utilityBillDataUploadButton'><TranslateComponent>Upload Data</TranslateComponent></Button>
            </AccessControl>
          </ButtonGroup>
        }
      />
    </>
  )
}

