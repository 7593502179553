import { TranslateComponent } from '../common/translations'
import { pages } from './pages'
import { isUBMEnabled } from 'src/common/featureFlags'

export const sections = {
  Dashboards: {
    name: "Dashboards",
    icon: 'dashboard2',
    links: [pages['Widget Library']]
  },
  Service: {
    name: "Service",
    icon: 'tools2',
    links: [
      pages['Opportunities'],
      pages['Issues/Findings'],
      pages['Automated Inspections']
    ]
  },
  'Utility & Energy': {
    name: "Utility & Energy",
    icon: 'energy-performance',
    links: [
      pages['Portfolio Scorecard'],
      pages['Energy Intensity'],
      pages[isUBMEnabled ? 'Utility Bill Upload' : 'Utility Bill Data'],
      pages['Energy Cost Summary'],
      pages['Energy Consumption'],
      pages['Electrical Demand'],
      pages['Load Analysis'],
      pages['Rate Structure Library']
    ]
  },
  'Energy Performance': {
    name: "Energy Performance",
    icon: 'energy-performance',
    links: [
      pages['Portfolio Scorecard'],
      pages['Energy Cost Summary'],
      pages['Energy Consumption'],
      pages['Electrical Demand'],
      pages['Load Analysis'],
      pages['Rate Structure Library']
    ]
  },
  'Utility Management': {
    name: "Utility Management",
    icon: 'utility-management',
    links: [
      pages['Utility Bill Data'],
      pages['Energy Intensity']
    ]
  },
  Reports: {
    name: "Reports",
    icon: 'clipboard',
    links: [pages['Documents & Reports']]
  },
  'Building Performance': {
    name: "Building Performance",
    icon: 'building-performance',
    links: [
      pages['Automated Test Settings'],
      pages['Automated Test Suppressions']
    ]
  },
  // "GridFlex": {
  //     name: "GridFlex™",
  //     icon: "grid-services",
  //     links: [
  //         pages["Participating Buildings"],
  //         pages["Programs"],
  //         pages["Device Management"],
  //         pages["Share My Data"]
  //     ]
  // },
  // "Digital Maintenance": {
  //     name: "Digital Maintenance",
  //     icon: "digital-maintenance",
  //     links: [
  //         pages["Digital Maintenance"],
  //     ]
  // },
  // "Facility Management": {
  //     name: "Facility Management",
  //     icon: "building-management",
  //     links: [
  //         pages["Tenant Services"],
  //     ]
  // },
  'Remote Access': {
    name: "Remote Access",
    icon: 'building-automation',
    links: [pages['Connect to a Device'] /*, pages['Manage Devices']*/]
  },
  'Building Configuration': {
    name: "Building Configuration",
    icon: 'facility-settings',
    links: [
      pages['Building Setup'],
      pages['Alarm Notification'],
      pages['Equipment Setup'],
      pages['Notes'],
      pages['Event Markers'],
      pages['Baseline Library']
    ]
  },
  // "Responsive Controls": {
  //     name: "Responsive Controls",
  // 	icon: "responsive-controls",
  //     links: [
  //         pages["Customer Dashboard"],
  //         pages["Administration"],
  //         pages["Agent Configuration"],
  //         pages["Data Validation"]
  //     ]
  // },
  'Admin Tools': {
    name: "Admin Tools",
    icon: 'set-up',
    links: [
      pages['User Management'],
      pages['Service Provider Management'],
      // pages["Navigation Demo"],
      pages['Organization Management'],
      pages['Role Management'],
      pages['Permission Management'],
      pages['Application Management'],
      pages['User Type Management'],
      pages['Group Management']
    ]
  },
  'Resource Not Found':{
    name: "Resource not found",
    icon:'error',
    links: [
      pages['Resource Not Found']
    ]
  },
  'Notifications':{
    name: "Notifications",
    icon:'notification',
    links: [
    ]
  }
}
