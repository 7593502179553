// Need to create a new component to replace the old ChartMinMaxSettings component.
// Pass in the same settingsConfig object that is being used in the EnergyIntensity component for BC.

import { Button, Card, Flex, Input } from "@aws-amplify/ui-react"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import * as Yup from 'yup'

// Project imports
import { TranslateComponent } from "src/common/translations"

// Denali imports
import { Dialog } from "src/denali-components/Dialog/Dialog"
import { DenaliForm } from "src/denali-components/Form/DenaliForm"
import { DenaliFormElement } from "src/denali-components/Form/DenaliFormElement"
import { DenaliFormElementError } from "src/denali-components/Form/DenaliFormElementErrors"
import { PlotChartCustomMinMax } from "src/denali-components/PlotChart/types"

// Local imports
import styles from './energy-intensity.module.scss'
import { EnergyIntensityChartMinMaxSettingsProps } from "./types"

export const EnergyIntensityChartMinMaxSettings = ({
  settingsConfig,
  updateSettingsConfig,
  initialSectionValues,
  setIsChartSettingsOpen,
}: EnergyIntensityChartMinMaxSettingsProps) => {
  const [minMaxValues, setMinMaxValues] = useState(initialSectionValues)

  const setDefaults = () => {
		updateSettingsConfig(minMaxValues)
	}

	useEffect(() => {
		setMinMaxValues(initialSectionValues)
	}, [initialSectionValues])

	const validationSchema = useMemo(() => Yup.object().shape(
		settingsConfig?.sections.reduce((acc, section) => {
			acc[section.key + 'min'] = Yup.number()
				.required(`Min value is required`)
				.typeError(`Min value must be a number`)
			acc[section.key + 'max'] = Yup.number()
				.required(`Max value is required`)
				.typeError(`Max value must be a number`)
				.min(Yup.ref(section.key + 'min'), 'Max value must be greater than Min value')
			
			return acc;
			}, {})
	), [settingsConfig])

  const handleSubmit = useCallback((values: PlotChartCustomMinMax) => {
    updateSettingsConfig(values, true)
    setIsChartSettingsOpen(false)
  }, [])

  // When we close the dialog close the settings and reset formik.
  const handleOnClose = useCallback(() => {
    setIsChartSettingsOpen(false)
    setDefaults();
  }, []);

  return (
    <DenaliForm onSubmit={handleSubmit} schema={validationSchema} defaultValues={minMaxValues}>
      <Dialog
        title={settingsConfig.heading}
        onClose={handleOnClose}
        actions={<Button type="submit">Save</Button>}
      >
        <TranslateComponent className={styles.sectionHeader}>{settingsConfig?.sectionHeader}</TranslateComponent> 
        {settingsConfig?.sections?.map((section, i) => (
          <Card key={section.name}>
            <TranslateComponent>{section.name}</TranslateComponent>
            <Flex direction="row" justifyContent="space-between">
              {section.fields && Object.keys(section.fields).map((field) => {
                const fieldName = section.key + field
                return (
                  <>
                    <DenaliFormElement name={fieldName} key={field}>
                      <Input
                        data-testid= {"energyIntensityChartSettings"}
                        className="field-input rx-col-auto"
                        type="number"
                        min={0}
                        step="0.1"
                      />
                    </DenaliFormElement>
                    <DenaliFormElementError name={fieldName} />
                  </>
                )
              })}
            </Flex>
          </Card>
        ))}
      </Dialog>
    </DenaliForm>
  )
}

export default memo(EnergyIntensityChartMinMaxSettings)