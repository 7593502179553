import { useEffect, useState, useMemo } from 'react'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import {
  useEnergyCostDataHook,
  chartDateTimeRequest
} from 'src/pages/energyCostSummary/EnergyCostDataHook'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import { EnergyCostBar } from './components/EnergyCostBar'
import { EnergyCostStackedBar } from './components/EnergyCostStackedBar'
import { aggreagatorSelection } from 'src/common/chartHelperFunctions'
import moment from 'moment'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import translate from 'src/common/translations'

const timeComparisionAggreagation = {
  'current-day': 'daily',
  'past-3-days': 'daily',
  'past-7-days': 'daily',
  'current-month': 'daily',
  'previous-quarter': 'monthly',
  'past-3-months': 'monthly',
  'past-6-months': 'monthly',
  'current-year': 'yearly'
}

const compareTimeCompare = {
  'today-previous-day-full-compared': 'compare-day',
  'today-previous-week-full-compared': 'same-day-previous-week',
  'today-previous-month-full-compared': 'same-day-previous-month',
  'today-previous-year-full-compared': 'same-day-previous-year',
  'past-3-days-week-full-compared': 'same-day-previous-week-3',
  'past-3-days-month-full-compared': 'same-day-previous-month-3',
  'past-3-days-year-full-compared': 'same-day-previous-year-3',
  'past-7-days-week-full-compared': 'same-day-previous-week-7',
  'past-7-days-month-full-compared': 'same-day-previous-month-7',
  'past-7-days-year-full-compared': 'same-day-previous-year-7',
  'previous-month': 'compare-month',
  'current-month-previous-year-full': 'same-month-previous-year',
  'previous-previous-quarter-full-compared': 'previous-previous-quarter',
  'previous-quarter-year-full-compared': 'same-quarter-previous-year',
  'past-3-months-year-full-compared': 'past-3-months-year-compared',
  'past-6-months-year-full-compared': 'past-6-months-year-compared',
  'previous-year-full': 'previous-year',
  none: 'none'
}

// So many logics can be combined and simplified
// Due to time constraint, we will take optimization after first release
// All the constansts can also move to a single file and can be referred
export const EnergyCostWidgetPreview = ({ widgetDetails }) => {
  const compareText = translate("Compare")
  const [currentDayData, setCurrentDayData] = useState({})
  const [previousDayData, setPreviousDayData] = useState({})

  const { loadingEnergyCostData, getEnergyCostData, energyCostData } =
    useEnergyCostDataHook()

  const {
    loadingEnergyCostData: loadingCompareEnergyCost,
    getEnergyCostData: getCompareEnergyCostData,
    energyCostData: compareEnergyCostData
  } = useEnergyCostDataHook()

  const {
    loadingEnergyCostData: loadingCompareEnergyCostCurrentDay,
    getEnergyCostData: getCompareEnergyCostDataCurrentDay,
    energyCostData: energyCostDataCurrentDay
  } = useEnergyCostDataHook()

  const {
    loadingEnergyCostData: loadingCompareEnergyCostPreviousDay,
    getEnergyCostData: getCompareEnergyCostDataPreviousDay,
    energyCostData: compareEnergyCostDataPrevious
  } = useEnergyCostDataHook()

  // Use this data for inner bar, todate calculations - monthly selection
  // Monthly data we will get Demand and Monthly fee
  // Consumption cost till date, we have to get from this call
  const {
    loadingEnergyCostData: loadingCompareMonthEnergyCost,
    getEnergyCostData: getCompareInnerBarEnergyCostData,
    energyCostData: compareInnerBarEnergyCost
  } = useEnergyCostDataHook()

  const selectedTimePeriod = widgetDetails?.timePeriod?.split('/')?.[1]

  const compareSelectedTimePeriod =
    compareTimeCompare[widgetDetails?.comparedTo]

  const buildingId = widgetDetails?.buildingId

  const dataAggregation =
    timeComparisionAggreagation[selectedTimePeriod] || 'daily'

  const { startDate, endDate } = useMemo(
    () => getStartAndEndDates(selectedTimePeriod, false, null, widgetDetails?.timeZone),
    [selectedTimePeriod]
  )

  const { startDate: compareStartDate, endDate: compareEndDate } = useMemo(
    () => getStartAndEndDates(compareSelectedTimePeriod, false, null, widgetDetails?.timeZone),
    [compareSelectedTimePeriod]
  )

  const dataAvailablityCheck = useMemo(() => {
    return (
      energyCostData?.isDataAvailable ||
      energyCostDataCurrentDay?.isDataAvailable ||
      compareEnergyCostData?.isDataAvailable ||
      compareEnergyCostData?.isDataAvailable
    )
  }, [
    energyCostData,
    energyCostDataCurrentDay,
    compareEnergyCostData,
    compareEnergyCostDataPrevious
  ])

  const getPlotlineDetails = () => {
    return {
      label: widgetDetails?.benchMarkerLabel,
      color: widgetDetails?.benchmarkColor,
      benchMarkerValue: widgetDetails?.benchMarkerValue,
      benchMarker: widgetDetails?.benchMarker
    }
  }

  const showNormalBarChart = useMemo(() => {
    return (
      !widgetDetails?.isShowEnergyCostSegmentation ||
      selectedTimePeriod === 'current-day' ||
      selectedTimePeriod === 'past-3-days' ||
      selectedTimePeriod === 'past-7-days'
    )
  }, [selectedTimePeriod, widgetDetails?.isShowEnergyCostSegmentation])

  const getCurrentDayCost = () => {
    const request = {
      startDate: endDate,
      endDate: endDate,
      interval: chartDateTimeRequest.minutes,
      buildingId,
      equipmentId: widgetDetails?.equipmentId,
      equipmentType: widgetDetails?.equipmentType
    }
    getCompareEnergyCostDataCurrentDay(request)
  }

  const getPreviousEnergyDayCost = () => {
    const request = {
      startDate: compareEndDate,
      endDate: compareEndDate,
      interval: chartDateTimeRequest.minutes,
      buildingId,
      equipmentId: widgetDetails?.equipmentId,
      equipmentType: widgetDetails?.equipmentType
    }
    getCompareEnergyCostDataPreviousDay(request)
  }

  const getCurrentEnergyCost = (interval) => {
    const request = {
      startDate: startDate,
      endDate: endDate,
      interval: interval,
      buildingId,
      equipmentId: widgetDetails?.equipmentId,
      equipmentType: widgetDetails?.equipmentType
    }
    getEnergyCostData(request)
  }

  const getComparePeriodEnergyCost = (interval) => {
    const request = {
      startDate: compareStartDate,
      endDate: compareEndDate,
      interval: interval,
      buildingId,
      equipmentId: widgetDetails?.equipmentId,
      equipmentType: widgetDetails?.equipmentType
    }
    getCompareEnergyCostData(request)
  }

  const getCompareInnerBarEnergyCost = () => {
    // requesting data for compare month start and current date in that month
    // this will give todate - consumption cost for that month
    // this is for inner bar values
    let startDate = moment(compareEndDate).format('YYYY-DD-MM')
    let endDate = moment(compareEndDate).format('YYYY-DD-MM')
    const compareMonth = moment(compareEndDate).format('MM')
    const compareMonthYear = moment(compareEndDate).format('YYYY')
    const currentDay = moment().format('DD')
    const currentMonth = moment().format('MM')
    switch (selectedTimePeriod) {
      case 'past-3-months':
      case 'past-6-months':
      case 'current-month':
        startDate = moment(compareEndDate).startOf('month').format('YYYY-MM-DD')
        endDate = `${compareMonthYear}-${compareMonth}-${currentDay}`
        break
      case 'current-year':
        startDate = moment(compareEndDate).startOf('year').format('YYYY-MM-DD')
        endDate = `${compareMonthYear}-${currentMonth}-${currentDay}`
        break
      // case 'previous-quarter':
      //   const quarterStartDate = moment()
      //   const daysInCurrentQuarter =
      //   startDate = moment(compareEndDate)
      //     .startOf('quarter')
      //     ?.format('YYYY-DD-MM')
    }

    const request = {
      startDate: startDate,
      endDate: endDate,
      interval: chartDateTimeRequest.day,
      buildingId,
      equipmentId: widgetDetails?.equipmentId,
      equipmentType: widgetDetails?.equipmentType
    }
    getCompareInnerBarEnergyCostData(request)
  }

  useEffect(() => {
    switch (selectedTimePeriod) {
      case 'current-day':
        // takes 15-mins data
        getCurrentDayCost()
        if (widgetDetails?.comparedTo !== ':none') {
          getPreviousEnergyDayCost()
        }
        break
      case 'past-3-days':
      case 'past-7-days':
        // takes 15-mins data
        getCurrentDayCost()
        getCurrentEnergyCost('1d')
        if (widgetDetails?.comparedTo !== ':none') {
          getComparePeriodEnergyCost(chartDateTimeRequest.day)
          // this is for inner bar
          getPreviousEnergyDayCost()
        }
        break
      default:
        getCurrentEnergyCost(chartDateTimeRequest.month)
        if (widgetDetails?.comparedTo !== ':none') {
          getComparePeriodEnergyCost(chartDateTimeRequest.month)
          // this is for inner bar
          selectedTimePeriod !== 'previous-quarter' &&
            getCompareInnerBarEnergyCost()
        }
    }
  }, [selectedTimePeriod, widgetDetails])

  const getXLabelName = (isCompareBar) => {
    switch (selectedTimePeriod) {
      case 'current-day':
        if (isCompareBar) {
          return moment(compareStartDate).format('ddd')
        } else {
          return moment(startDate).format('ddd')
        }
      case 'current-month':
        if (isCompareBar) {
          return moment(compareStartDate).format('MMM YYYY')
        } else {
          return moment(startDate).format('MMM YYYY')
        }
      case 'previous-quarter':
        if (isCompareBar) {
          const q1 = moment(compareStartDate).quarter()
          return `Q${q1}-${moment(compareStartDate).format('YYYY')}`
        } else {
          const q1 = moment(startDate).quarter()
          return `Q${q1}-${moment(startDate).format('YYYY')}`
        }
      case 'current-year':
        if (isCompareBar) {
          return moment(compareStartDate).format('YYYY')
        } else {
          return moment(startDate).format('YYYY')
        }
      default:
        return ''
    }
  }

  const getIntervalInfo = () => {
    const lDateFormat = getDateFormatByLocale('MMM DD, YYYY')
    const lMonthDateFormat = getDateFormatByLocale('MMM, YYYY')
    switch (selectedTimePeriod) {
      case 'current-day':
        if (widgetDetails?.comparedTo === ':none') {
          return moment(startDate)?.format(lDateFormat ? lDateFormat : `MMM DD, YYYY`)
        } else {
          return `${moment(compareEndDate)?.format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')} - ${moment(
            startDate
          )?.format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')}`
        }
      case 'past-3-days':
      case 'past-7-days':
        if (widgetDetails?.comparedTo === ':none') {
          return `${moment(startDate)?.format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')} - ${moment(
            endDate
          )?.format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')}`
        } else {
          return `${moment(compareStartDate)?.format(
            lDateFormat ? lDateFormat : 'MMM DD, YYYY'
          )} - ${moment(endDate)?.format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')}`
        }
      case 'current-month':
        if (widgetDetails?.comparedTo === ':none') {
          return moment(startDate)?.format(lMonthDateFormat ? lMonthDateFormat : `MMM, YYYY`)
        } else {
          return `${moment(compareEndDate)?.format(lMonthDateFormat ? lMonthDateFormat : 'MMM, YYYY')} - ${moment(
            startDate
          )?.format(lMonthDateFormat ? lMonthDateFormat : 'MMM, YYYY')}`
        }
      case 'past-3-months':
      case 'past-6-months':
        if (widgetDetails?.comparedTo === ':none') {
          return `${moment(startDate)?.format(lMonthDateFormat ? lMonthDateFormat : 'MMM, YYYY')} - ${moment(
            endDate
          )?.format(lMonthDateFormat ? lMonthDateFormat : 'MMM, YYYY')}`
        } else {
          return `${moment(compareStartDate)?.format(lMonthDateFormat ? lMonthDateFormat : 'MMM, YYYY')} - ${moment(
            endDate
          )?.format(lMonthDateFormat ? lMonthDateFormat : 'MMM, YYYY')}`
        }
      case 'previous-quarter':
        if (widgetDetails?.comparedTo === ':none') {
          const q = moment(startDate)?.quarter()
          return `Q${q}-${moment(startDate).format('YYYY')}`
        } else {
          const q1 = moment(compareStartDate)?.quarter()
          const q2 = moment(endDate)?.quarter()
          return `Q${q1} ${moment(compareStartDate).format(
            'YYYY'
          )} - Q${q2} ${moment(endDate).format('YYYY')}`
        }
      case 'current-year':
        if (widgetDetails?.comparedTo === ':none') {
          return moment(startDate).format('YYYY')
        } else {
          return `${moment(compareStartDate).format('YYYY')} - ${moment(
            endDate
          ).format('YYYY')}`
        }
    }
  }

  useEffect(() => {
    const dataLength =
      energyCostDataCurrentDay?.consumptionCost?.values?.[0]?.length || 0
    if (energyCostDataCurrentDay) {
      const total = aggreagatorSelection(
        'sum',
        energyCostDataCurrentDay?.consumptionCost?.values?.[0],
        0,
        dataLength - 1
      )
      setCurrentDayData({
        costTotal: total,
        label: moment(endDate).format('ddd'),
        dataLength: dataLength
      })
    }

    if (compareEnergyCostDataPrevious) {
      const compareValues =
        compareEnergyCostDataPrevious?.consumptionCost?.values?.[0] || []
      const total = aggreagatorSelection(
        'sum',
        compareValues,
        0,
        compareValues?.length - 1
      )
      const innerTotal = aggreagatorSelection(
        'sum',
        compareEnergyCostDataPrevious?.consumptionCost?.values?.[0],
        0,
        dataLength
      )
      setPreviousDayData({
        costTotal: total,
        label: moment(compareEndDate).format('ddd'),
        innerTotal,
        dataLength: dataLength
      })
    }
  }, [energyCostDataCurrentDay, compareEnergyCostDataPrevious])

  return loadingEnergyCostData ||
    loadingCompareEnergyCost ||
    loadingCompareEnergyCostCurrentDay ||
    loadingCompareEnergyCostPreviousDay ||
    loadingCompareMonthEnergyCost ? (
    <Spinner />
  ) : dataAvailablityCheck ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      {showNormalBarChart ? (
        <EnergyCostBar
          energyCostData={energyCostData}
          compareEnergyCostData={compareEnergyCostData}
          rightBarColor={widgetDetails?.rightBarColor}
          leftBarColor={widgetDetails?.leftBarColor}
          toDateBarColor={widgetDetails?.toDateBarColor}
          dataAggregation={dataAggregation}
          selectedTimePeriod={selectedTimePeriod}
          compareSelectedTimePeriod={compareSelectedTimePeriod}
          currentDayData={currentDayData}
          previousDayData={previousDayData}
          getXLabelName={getXLabelName}
          plotlineDetails={getPlotlineDetails()}
          widgetDetails={widgetDetails}
          compareInnerBarEnergyCost={compareInnerBarEnergyCost}
          compareText={compareText}
        />
      ) : (
        <EnergyCostStackedBar
          energyCostData={energyCostData}
          compareEnergyCostData={compareEnergyCostData}
          dataAggregation={dataAggregation}
          selectedTimePeriod={selectedTimePeriod}
          compareSelectedTimePeriod={compareSelectedTimePeriod}
          getXLabelName={getXLabelName}
          plotlineDetails={getPlotlineDetails()}
          widgetDetails={widgetDetails}
          compareInnerBarEnergyCost={compareInnerBarEnergyCost}
        />
      )}
      <WidgetChartFooterComponent
        aggregatorName={''}
        selectedPeriod={selectedTimePeriod}
        comparisonBar={true}
        footerInfo={`${timeComparisionAggreagation[selectedTimePeriod]} Energy cost`}
        intervalInfo={getIntervalInfo() || ''}
        aggragatorInterval={dataAggregation}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={''}
      selectedPeriod={selectedTimePeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}    
      footerInfo={`${timeComparisionAggreagation[selectedTimePeriod]} Energy cost`}  
      aggragatorInterval={dataAggregation}
      intervalInfo={getIntervalInfo() || ''}
    />
  )
}
