import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _groupBy from 'lodash/groupBy'
import validationSchema from './formValidator'

// components
import { ChangeEvent } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { ACTIONS } from 'src/constants'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import styled from 'styled-components'
import ChatHistory from './chat-history-sc/chat-history.sc'
import Label from './label/label'
import {
  CREATE_NOTE,
  CREATE_NOTE_HISTORY,
  UPDATE_NOTE,
  CREATE_ATTACHMENT,
  CREATE_ENTRY_WITH_ATTACHMENT,
  getEquipmentDetailsbyId,
  DELETE_ATTACHMENT,
  UPDATE_ATTACHMENT
} from '../graphql'
import moment from 'moment/moment'
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useNavigate } from 'react-router-dom'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import {TEST_NAME_PREFIX} from "../constants"
import { useDispatch } from 'react-redux'
import { newRefreshToken } from 'src/redux/slicers/appData'
import _isEmpty from 'lodash/isEmpty'
import translate, { TranslateComponent } from 'src/common/translations'
import { getDateFormatByLocale } from '../../../common/chartHelperFunctions'

const InformationPanel = styled.div`
  display: flex;
`

const PanelItem = styled.div`
  width: 25%;
`
const NoteWrapper = styled.div`
  & .modal-text-wrapper .custom-label {
    padding: 0px !important;
  }
`

const PanelLabel = styled.label`
  display: block;
  height: 16px;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
`

const ACTION_HEADERS = {
  [ACTIONS.ADD]: 'notes:CreateNote',
  [ACTIONS.EDIT]: 'notes:EditNote',
  [ACTIONS.VIEW]: 'notes:ViewNote'
}

const NoteDetails = ({
  notesInput,
  mode,
  setMode,
  buildingName = '',
  equipmentName = '',
  initialValues,
  setNotesInput,
  buildingId,
  refetchList,
  downloadFileFunc,
  addFileFunc,
  testName
}: NoteDetailsProps) => {
  const [t] = useTranslation()
  const [confirm, setConfirm] = useState(null)  
  const [deleteAttach, setDeleteAttach] = useState([])
  const {
    buildingId: location,
    organizationId: organization,
    equipment
  } = getSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { onSubmit: createNotesMutation } = useMutation({
    query: CREATE_NOTE,
    onSuccess: () => {
      trackEvent(USER_EVENTS.NOTES.events.SAVE_NOTE, {mode:"CREATE"})
    }
  })

  const { onSubmit: updateNotesMutation } = useMutation({
    query: UPDATE_NOTE,
    onSuccess: () => {
      trackEvent(USER_EVENTS.NOTES.events.SAVE_NOTE, {mode:"EDIT"})
    }
  })

  const { onSubmit: createHistoryMutation } = useMutation({
    query: CREATE_NOTE_HISTORY
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })
  
  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  // TO GET EQUIPMENTDETAILS
  const { data: equipmentDetails, refetch: refetchEquipmentById } = useQuery({
    query: getEquipmentDetailsbyId,
    variables: { id: equipment },
    dataPath: 'data.getEquipment',
    disableInitialLoad: true
  })

  useEffect(() => {
    if (equipment) {
      refetchEquipmentById()
    }
  }, [equipment])

  const getModalHeading = () => {
        switch(ACTION_HEADERS[mode]) {
          case 'notes:CreateNote':
            return `Create Note`
            break;
          case 'notes:EditNote':
            return `Edit Note`
            break;
          case 'notes:ViewNote':
            return `View Note`
            break;
          default:
            return `Create Note`
        }
  }

  const formik = useFormik({
    initialValues: notesInput,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      await dispatch<any>(newRefreshToken([buildingId], null, null, ["NoteUser", "NoteAdmin", "HistoryUser", "HistoryAdmin", "AttachmentAdmin"]))
      mode === ACTIONS.ADD
        ? await createNotesMutation({
            input: {
              buildingId: buildingId,
              name: values.name,
              userId: values.currentUserId,
              type: values.type === 'All Note Types' ? 'Building' : values.type,
              equipmentId: equipmentDetails?.id,
              // createdBy: values.createdBy,
              analyticServiceAdvisoryId: undefined
            }
          }).then(async (response) => {
            if (
              (values.addComment && values.addComment !== '') ||
              values.attachments.filter((a) => !a.id)?.length
            ) {
              await createHistoryMutation({
                input: {
                  buildingId: buildingId,
                  noteId: response.data.createNote.id,
                  comment: values.addComment,
                  // createdBy: currentUser //values.createdBy
                  userId: values.currentUserId
                  //TODO: add user id here and grab user info
                }
              }).then(async (historyResponse) => {
                await Promise.all(
                  values.attachments
                    .filter((a) => !a.id)
                    .map((a) =>
                      createAttachmentMutation({
                        input: {
                          attachedAt: new Date(a.timestamp).toISOString(),
                          buildingId: buildingId,
                          description: a.description,
                          historyId: historyResponse?.data?.createHistory?.id,
                          name: a.fileName,
                          title: a.title,
                          noteId: response?.data?.createNote?.id,
                          sizeInBytes: a.fileSize,
                          userId: values.currentUserId,
                          type: a.type
                        }
                      })
                    )
                )
                  .then((response) =>
                    Promise.all(
                      response.map((r) => {
                        return r?.data?.createAttachment?.id
                          ? createEntryWithAttachmentMutation({
                              input: JSON.stringify({
                                modelType: 'Note',
                                attachments: {
                                  create: [
                                    {
                                      buildingId:
                                        r.data.createAttachment.buildingId,
                                      name: r.data.createAttachment.name
                                    }
                                  ]
                                }
                              })
                            })
                          : ''
                      })
                    )
                  )
                  .then(() => {
                    setMode(null)
                    setNotesInput(initialValues)
                    refetchList()
                  })
              })
            } else {
              setMode(null)
              setNotesInput(initialValues)
              refetchList()
            }
          })
        : await updateNotesMutation({
            input: {
              id: values.id,
              buildingId: buildingId,
              name: values.name,
              type: values.type,
              equipmentId: values?.equipment?.id,
              userId: values.userId,
              analyticServiceAdvisoryId: undefined
            }
          }).then(async (response) => {
            const shouldCreateHistory =
              (values.addComment && values.addComment !== '') ||
              values.attachments.filter((a) => !a.id)?.length
            const shouldUpdateAttachment = values.attachments.filter(
              (a) => a.id && a
            )?.length

            if (shouldCreateHistory) {
              await createHistoryMutation({
                input: {
                  buildingId: buildingId,
                  noteId: response.data.updateNote.id,
                  userId: values.currentUserId,
                  comment: values.addComment
                  // userId: currentUser //values.createdBy
                }
              }).then((response) => {
                Promise.all(
                  values.attachments
                    .filter((a) => !a.id)
                    .map((a) => {
                      return createAttachmentMutation({
                        input: {
                          attachedAt: new Date(a.timestamp).toISOString(),
                          buildingId: buildingId,
                          description: a.description,
                          type: a.type,
                          historyId: response?.data?.createHistory?.id,
                          name: a.fileName,
                          title: a.title,
                          noteId: values.id,
                          userId: values.currentUserId,
                          sizeInBytes: a.fileSize
                        }
                      })
                    })
                ).then((response) => {
                  Promise.all(
                    response.map((r) => {
                      return r?.data?.createAttachment?.id
                        ? createEntryWithAttachmentMutation({
                            input: JSON.stringify({
                              modelType: 'Note',
                              attachments: {
                                create: [
                                  {
                                    buildingId:
                                      r.data.createAttachment.buildingId,
                                    name: r.data.createAttachment.name
                                  }
                                ]
                              }
                            })
                          })
                        : ''
                    })
                  )
                })
              })
            }
            if (shouldUpdateAttachment) {
              await Promise.all(
                values.attachments
                  .filter((a) => a.id && a.isNew)
                  .map((a) => {
                    return createAttachmentMutation({
                      input: {
                        attachedAt: new Date(a.timestamp).toISOString(),
                        buildingId: buildingId,
                        description: a.description,
                        type: a.type,
                        historyId: a.historyId,
                        name: a.fileName,
                        title: a.title,
                        noteId: values.id,
                        userId: values.currentUserId,
                        sizeInBytes: a.fileSize
                      }
                    })
                  })
              ).then((response) => {
                Promise.all(
                  response.map((r) => {
                    return r?.data?.createAttachment?.id
                      ? createEntryWithAttachmentMutation({
                          input: JSON.stringify({
                            modelType: 'Note',
                            attachments: {
                              create: [
                                {
                                  buildingId:
                                    r.data.createAttachment.buildingId,
                                  name: r.data.createAttachment.name
                                }
                              ]
                            }
                          })
                        })
                      : ''
                  })
                )
              })
              await Promise.all(
                values.attachments
                  .filter((a) => a.id && a.isNew)
                  .map((a) =>
                    createEntryWithAttachmentMutation({
                      input: JSON.stringify({
                        modelType: 'Note',
                        attachments: {
                          delete: [
                            {
                              attachmentId: a.id,
                              buildingId: a.buildingId,
                              name: a.fileName
                            }
                          ]
                        }
                      })
                    })
                  )
              )
            }
            // Initialize S3 bucket attachment body
            const attachmentBody = {
              modelType: 'Note',
              attachments: {}
            }
            // Update File Attachment Description 
            await Promise.all(values?.attachments?.filter(f => f?.descriptionChanged && f?.id).map(d => updateAttachmentMutation({ input: { id: d?.id, description: d?.description } })))
            // Delete File Attachment
            await Promise.all(deleteAttach?.map(d => deleteAttachmentMutation({ input: { id: d?.id } }).then((res)=>{
              attachmentBody.attachments['delete'] = [
                ...(attachmentBody?.attachments?.['delete'] || []),
                {
                  attachmentId: d?.id,
                  buildingId,
                  name: d?.name
                }
              ]
            })))
            //Update S3 bucket While Delete File Attachments
            if (!_isEmpty(attachmentBody?.attachments)) {
              await createEntryWithAttachmentMutation({
                input: JSON.stringify(attachmentBody)
              })
            }
            setMode(null)
            setNotesInput(initialValues)
            refetchList()
          })
      navigate(`/notes?location=${location}&organization=${organization}`)
    }
  })

  const getButtons = (submitForm, isFormValid, isSubmitting) => {
    return mode === ACTIONS.VIEW
      ? [{ text: "CLOSE", handleClick: closeNoteDialog }]
      : [
          {
            text: isSubmitting ? <div className="spinner" /> : t('common:Save'),
            type: 'submit',
            handleClick: submitForm,
            disabled: !isFormValid || isSubmitting
          },
          {
            text: t('common:Cancel'),
            type: 'cancel',
            handleClick: closeNoteDialog
          }
        ]
  }

  const deleteOptionTranslated = {
    text : translate(`Are you sure want to delete`)
  }

  const showConfirm = (fileId, fileName) => {
    setConfirm({
      title: 'Delete File',
      text:  `${deleteOptionTranslated.text} ${fileName}?`,
      className: 'confirm-delete-dialog',
      confirmText: 'Yes',
      cancelText: 'No',
      handleConfirm: () => {
        setConfirm(null)
        onDeleteAttachment(fileId, fileName)
      }
    })
  }

  const warnBeforeReplace = (callback) => {
    setConfirm({
      title: t('notes:ReplaceAttachments'),
      text: t('notes:ReplaceAttachmentsConfirmation'),
      confirmText: t('notes:ReplaceText'),
      cancelText: t('common:Cancel'),
      handleConfirm: () => {
        callback()
        setConfirm(null)
      },
      handleCancel: () => {
        callback(t('notes:ReplaceAttachmentsError'))
        setConfirm(null)
      }
    })
  }

  const closeNoteDialog = () => {
    setMode(null)
    navigate(`/notes?location=${location}&organization=${organization}`)
  }

  const handleInputChange = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  const modalConfig = {
    gray: true,
    heading: getModalHeading(),
    customHeaders : mode === ACTIONS.ADD
    ? `${buildingName && `: ${buildingName}`}${
        equipmentName ? `: ${equipmentName}` : ''
      }`
    : notesInput.name && `: ${notesInput.name}`,
    className: 'wide-modal note-details-modal',
    buttons: getButtons(formik.handleSubmit, formik.isValid, formik.isSubmitting),
    handleClose: closeNoteDialog
  }

  const { values, errors } = formik

  const onDeleteAttachment = (fileId, fileName) => {
    if (fileId) {
      const updatedAttachments = values.attachments?.filter(
        (attachment) => attachment?.name !== fileName
      )
      formik.setFieldValue('attachments', updatedAttachments)
      const deleteAttachments = [...deleteAttach]
      deleteAttachments.push({ id: fileId, name: fileName})
      setDeleteAttach(deleteAttachments)
    } else {
      formik.setFieldValue(
        'attachments',
        values.attachments.filter(
          (attachment) => !(attachment.name == fileName)
        )
      )
    }
  }

  const getMessages = () => {
    try {
      return values?.histories
        ?.sort(
          (a, b) =>
            new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
        )
        ?.map((h) => ({
          messageKey: h?.id,
          createdBy: `${h?.user?.firstName ? h.user.firstName : ''} ${
            h?.user?.lastName ? h.user.lastName : ''
          }`,
          message: h?.comment || '',
          subMessage: `${moment(h.createdAt).format(getDateFormatByLocale("MMM D, YYYY h:mm A"))} ${
            h.attachments?.items?.length
              ? `- ${h.attachments?.items
                  .map((i) => i.name)
                  .join(' ')} ${translate("attached")}`
              : ''
          }`
        }))
    } catch (error) {}
  }

  const onDescriptionChange = (e) => {
    formik.setFieldValue(
      'attachments',
      values.attachments.map((attachment) =>
        attachment.fileName === e.fileName
          ? { ...attachment, description: e.value, descriptionChanged: true }
          : attachment
      )
    )
  }

  const onChangeUploadStatus = (newFiles) => {
    const attachments = [...values.attachments]
    for (let i = 0; i < newFiles.length; i++) {
      const { file, loading, error } = newFiles[i]
      if (attachments.findIndex((a) => a.title === file.title) !== -1) {
        const index = attachments.findIndex((a) => a.title === file.title)
        attachments[index] = {
          ...file,
          id: attachments[index].id,
          noteId: attachments[index].noteId,
          historyId: attachments[index].historyId,
          timestamp: new Date().valueOf(),
          buildingId: buildingId,
          fileName: file.name,
          name: file.name,
          title: file.title,
          filePath: `${buildingId}/${file.name}`,
          fileSize: file.size,
          type: file.type,
          isNew: true,
          loading,
          ...(error && { error: String(error) }),
          customClasses: loading ? 'loading' : error ? 'error' : ''
        }
      } else {
        attachments.push({
          ...file,
          timestamp: new Date().valueOf(),
          buildingId: buildingId,
          fileName: file.name,
          title: file.title,
          filePath: `${buildingId}/${file.name}`,
          fileSize: file.size,
          type: file.type,
          isNew: true,
          loading,
          ...(error && { error: String(error) }),
          customClasses: loading ? 'loading' : error ? 'error' : ''
        })
      }
    }

    formik.setFieldValue('attachments', [...attachments])
  }

  const translatedOptions = {
    noteLabel : "Note Name"
  }

  return (
    <Modal testName = {TEST_NAME_PREFIX(`${mode && t(ACTION_HEADERS[mode])}`)} {...modalConfig}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <NoteWrapper>
          {ACTIONS.VIEW !== mode ? (
            <TextInput
              labelText={translatedOptions.noteLabel}
              defaultValue={values?.name}
              name="name"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(name, value)
              }
              errorMessage={'Please enter a note name'}
              hasError={errors?.['name']}
            />
          ) : (
            <>
              <PanelLabel><TranslateComponent>{"Note Name"}</TranslateComponent></PanelLabel>
              <div>{values?.name}</div>
            </>
          )}
          <br />
          <InformationPanel>
            <PanelItem>
              <PanelLabel><TranslateComponent>Note Type</TranslateComponent></PanelLabel>
              <div><TranslateComponent>{values?.type}</TranslateComponent></div>
            </PanelItem>
            <PanelItem>
              <PanelLabel><TranslateComponent>{values?.type}</TranslateComponent></PanelLabel>
              <div>
                {values?.type !== 'Equipment'
                  ? buildingName
                  : mode === ACTIONS.ADD
                  ? equipmentDetails?.name
                  : values?.equipment?.name}
              </div>
            </PanelItem>
            <PanelItem>
              <PanelLabel><TranslateComponent>Created by</TranslateComponent></PanelLabel>
              <div>{values?.createdByName}</div>
            </PanelItem>
            {mode !== ACTIONS.ADD && (
              <PanelItem>
                <PanelLabel><TranslateComponent>Created On</TranslateComponent></PanelLabel>
                <div style={{textTransform: "capitalize"}}>{moment(values?.createdAtDetailTimestamp).format(getDateFormatByLocale('MMM D, YYYY h:mm A'))}</div>
              </PanelItem>
            )}
          </InformationPanel>
        </NoteWrapper>

        {mode !== ACTIONS.ADD && (
          <div className="note-history">
            <Label text={translate("Note History")} />
            <ChatHistory
              messages={getMessages()}
              isCapital={true}
              emptyMessage={translate('There is currently no note history to display')}
            />
          </div>
        )}
        {mode !== ACTIONS.VIEW && (
          <TextAreaInput
            labelText="Add Comment"
            defaultValue={values?.addComment}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleInputChange('addComment', value)
            }
            name="addComment"
            isRequired={false}
          />
        )}
        <div className="attached-files">
          <Label
            text={`${translate('Attached Files')} ${
              values?.attachments?.length
                ? `(${values.attachments.length})`
                : ''
            }`}
          />
          <AttachedFiles
            mode={mode}
            attachments={values.attachments?.map((item) => ({
              ...item,
              isEditable: true,
              fileName: item?.name,
              fileId: item?.id,
              fileSize: item?.sizeInBytes
            }))}
            onBeforeReplaceAttachment={(e) => warnBeforeReplace(e)}
            // onCancelUploadAttachment={this.onDeleteAttachment(values, setFieldValue)} //TODO: Need to know what to do here. Hard delete or soft
            onDeleteAttachment={(id, name) => showConfirm(id, name)}
            onDescriptionChange={(e) => onDescriptionChange(e)}
            onChangeUploadStatus={(files) => {
              onChangeUploadStatus(files)
            }}
            addFile={(e) => addFileFunc(e)}
            downloadFileFunc={(e) => downloadFileFunc(e)}
          />
        </div>
      </form>
      <StandardDialogs
        confirm={confirm}
        onCloseDialog={() => setConfirm(null)}
      />
    </Modal>
  )
}
export default NoteDetails
