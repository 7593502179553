import { svg } from 'htl'

export const linearGradientDef = svg`
    <defs>
      <linearGradient id="chartLinearGradient" gradientUnits="userSpaceOnUse" 
        x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color:rgb(141,126,242)" />
        <stop offset="33%" style="stop-color:rgb(232,162,206)" />
        <stop offset="66%" style="stop-color:rgb(145,175,249)" />
        <stop offset="100%" style="stop-color:rgb(173,140,255)" />
      </linearGradient>
    </defs>
  `

export const eciLineGradientDef = svg`
    <defs>
      <linearGradient id="eciLineGradient" gradientTransform="rotate(90)">
        <stop offset="20%" style="stop-color:rgb(254,249,237)" stop-opacity="0.75" />
        <stop offset="100%" style="stop-color:rgb(254,249,237)" stop-opacity="0.25" />
      </linearGradient>
    </defs>
  `
export const euiLineGradientTranslucentDef = svg`
    <defs>
      <linearGradient id="euiLineTranslucentGradient" gradientTransform="rotate(90)">
        <stop offset="0%" style="stop-color:rgb(141,126,242)" stop-opacity="0.25" />
        <stop offset="33%" style="stop-color:rgb(232,162,206)" stop-opacity="0.25" />
        <stop offset="66%" style="stop-color:rgb(145,175,249)" stop-opacity="0.25" />
        <stop offset="100%" style="stop-color:rgb(173,140,255)" stop-opacity="0.25" />
      </linearGradient>
    </defs>
  `

export const euiLineGradientDef = svg`
  <defs>
    <linearGradient id="euiLineGradient">
      <stop offset="0%" style="stop-color:rgb(141,126,242)" />
      <stop offset="33%" style="stop-color:rgb(232,162,206)" />
      <stop offset="66%" style="stop-color:rgb(145,175,249)" />
      <stop offset="100%" style="stop-color:rgb(173,140,255)" />
    </linearGradient>
  </defs>
`
