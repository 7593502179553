import { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { useQuery } from 'src/hooks/APIHooks'
import LabelBlank from '../LabelBlank'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { AssignedToFieldWrapper } from './styles'
import _uniqBy from 'lodash/uniqBy'
import { listUsersForAssignedToField } from './graphql'
import translate from 'src/common/translations'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'

export function AssignedTo({
  defaultValue = '',
  onChange,
  locationId,
  isToggleMode = false,
  readOnlyValue = '',
  isLoading = false,
  setFieldValue,
  page
}) {
  const {
    data: userList,
    refetch: refetchUserList,
    error,
    loading
  } = useQuery({
    query: listUsersForAssignedToField,
    variables: { id: locationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchUserByBuilding'
  })
  useEffect(() => {
    if (locationId) {
      refetchUserList({ id: locationId })
    }
  }, [locationId])

  const userListOption = useMemo(
    () => {
      try {
        let newUserList = []
        if(userList){
          const usersInfo = JSON.parse(JSON.parse(userList).body).filter(e => e)
          if(usersInfo){
            newUserList = _uniqBy(usersInfo, 'email')?.map(
              ({ email, firstName, lastName }) => ({
                key: email,
                label: `${firstName} ${lastName} <${email}>`
              })
            )
          }
        }
        newUserList.sort((a, b) => (a?.label > b?.label ? 1 : -1));
        return newUserList
      } catch(error){

      }
    },
    [userList]
  )

  const [isEditMode, setIsEditMode] = useState(false)
  const openEditMode = useCallback(() => setIsEditMode(true), [])
  const closeEditMode = useCallback(() => setIsEditMode(false), [])
  const onChangeOption = (settings) => {
    delete settings[1]
    setIsEditMode(false)
    if (page === 'finding') {
      settings.length
        ? onChange(
            userListOption.find(({ key }) => key === settings[0].key)
          )
        : setFieldValue('assignedTo', {})
    } else if (page === 'opportunity') {
      settings.length
        ? onChange(
            userListOption.find(({ key }) => key === settings[0].key)
          )
        : setFieldValue('settings.assignedTo', {})
    } else {
      settings.length
        ? onChange(
            userListOption.find(({ key }) => key === settings[0].key)
          )
        : onChange({})
    }
  }
  const optionsTranslated = {
    assigned: translate("Assigned To"),
    selectBuilding: translate("Please select building to see the user list"),
    selectOne: translate("Select One"),
    noUser: translate("No user found"),
    findUser: translate("Find a user"),
  }

  return (
    <AssignedToFieldWrapper>
      <div className="horizontal-control-block right assigned-to" data-testid="assigned-to">
        <LabelBlank
          className="footer-info-item"
          labelText={`${optionsTranslated.assigned}:`}
          value={!isEditMode && !isLoading && readOnlyValue}
          onClick={openEditMode}
        />
        {isToggleMode && !isEditMode && !isLoading ? (
          <span className="icon icon-edit" onClick={openEditMode} onKeyDown={openEditMode}/>
        ) : error ? (
          <p className="error">{optionsTranslated.selectBuilding}</p>
        ) : !isLoading ? (
          <>
            <SelectrixWrapper
              className="selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select user-list"
              defaultValue={[defaultValue]}
              onChange={onChangeOption}
              options={userListOption || []}
              placeholder={translate("— Select One —")}
              searchable={true}
              searchBoxInside={true}
              searchIndex={false}
              isLoading={loading}
              notFoundPrompt={optionsTranslated.noUser}
              inputPlaceholder={optionsTranslated.findUser + '...'}
              multiple={true}
              lifo={true}
            />
            {isToggleMode && (
              <span className="icon-close" onClick={closeEditMode} onKeyDown={closeEditMode}/>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </AssignedToFieldWrapper>
  )
}

AssignedTo.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  locationId: PropTypes.string,
  isToggleMode: PropTypes.bool,
  readOnlyValue: PropTypes.string,
  isLoading: PropTypes.bool,
  setFieldValue: PropTypes.func,
  page: PropTypes.string
}

export default AssignedTo
