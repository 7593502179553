import { useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import cloneDeep from 'lodash/cloneDeep'

// Project imports
import { ACCEPTED_FILE_TYPES } from 'src/pages/documentsAndReports/constants'
import { getAttachedDetails } from 'src/pages/documentsAndReports/helper'
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'

// Local imports  
import { AddDocumentAttachmentsProps } from './types'

export const AddDocumentAttachments = ({ 
  name, 
  warnBeforeReplace,
  showConfirm,
  addFileFunc,
  downloadFileFunc,
  uiMode
} : AddDocumentAttachmentsProps) => {
  const { getFieldState } = useFormContext();
  const { field } = useController({ name })

  const onChangeUploadStatusHandler = useCallback((files) => {
    const attachmentCopy = cloneDeep(field.value)
    const filesCopy = cloneDeep(files)
    const locationIdCopy = cloneDeep(getFieldState('location.locationId'))

    const attachFiles = getAttachedDetails(
      filesCopy,
      attachmentCopy,
      locationIdCopy
    )
    field.onChange(attachFiles)
  }, [field, getFieldState])

  const onDescriptionChangeHandler = useCallback((e) => {
    const updatedAttachments = field.value?.map((attachment) =>
      attachment?.title === e?.fileName
        ? { ...attachment, description: e?.value, descriptionChanged: true }
        : attachment
    )
    field.onChange(updatedAttachments)
  }, [field])

  return (
    <AttachedFiles
      attachments={field.value?.map((item) => ({
        ...item,
        isEditable: true,
        fileName: item?.title,
        fileId: item?.id,
        fileSize: item?.sizeInBytes
      }))}
      onBeforeReplaceAttachment={warnBeforeReplace}
      onDeleteAttachment={showConfirm}
      onDescriptionChange={onDescriptionChangeHandler}
      onChangeUploadStatus={onChangeUploadStatusHandler}
      addFile={addFileFunc}
      downloadFileFunc={downloadFileFunc}
      proposalFileTypes={ACCEPTED_FILE_TYPES}
      uiMode={uiMode}
      required={true}
    />
  )
}