export const GET_USER_BY_ID = `
query GET_USER_BY_ID($id: ID!) {
  getUser(id: $id) {
    azureId
    email
    endDate
    firstName
    gracePeriod
    id
    isActive
    lastName
    secondaryEmail
    startDate
    updatedAt
    userCreatedById
    userId
    userTypeId
    userUpdatedById
    createdAt
    apps {
      items {
        applicationID
        id
      }
    }
  }
}
`

export const LIST_CUSTOM_USER_ACCESS_BY_USER = `
query LIST_CUSTOM_USER_ACCESS_BY_USER($id: String!) {
  listCustomUserAccessByUser(userId: $id)
}
`

export const LIST_USER_ACCESSES_BY_USER_ID = `
query LIST_USER_ACCESSES_BY_USER_ID($id: ID!) {
  listUserAccessesByUserId(userId: $id) {
    items {
      appId
      applicationAccessId
      id
      resType
      resource
      roleId
      roleType
      startDate
      updatedAt
      app {
        id
        name
        description
      }
      role {
        id
        name
        resourceTypes
        userTypes
        type
        description
      }
      user {
        lastName
        endDate
        email
        firstName
        secondaryEmail
        startDate
        updatedAt
        userCreatedById
        userId
        userTypeId
        userUpdatedById
        isActive
        id
        defaultUniqueAssetId
        createdAt
        azureId
        accountId
      }
    }
  }
}
`

export const LIST_USERTYPES = `
query LIST_USERTYPES {
  listUserTypes {
    items {
      name
      id
      description
      authUserTypes
      createdAt
      userTypeId
    }
  }
}
`

export const LIST_APPLICATIONS = `
query LIST_APPLICATIONS {
  listApplications {
    items {
      name
      id
    }
  }
}
`

export const LIST_ROLES_BY_APP_ID = `
query LIST_ROLES_BY_APP_ID($id: ID!) {
  listRolesByAppId(appId: $id) {
    items {
      id
      name
      type
      tasks
      resourceTypes
      userTypes
      description
      type
    }
  }
}
`

export const LIST_CUSTOM_ROLES_BY_APP_ID = `
query LIST_CUSTOM_ROLES_BY_APP_ID($id: String!) {
  listCustomUserRolesByApp(appId: $id)
}
`

//TODO this needs to be converted to search for the total items
export const GET_SALESOFFICES = `
query GetSalesOffices (
  $filter: ModelSalesOfficeFilterInput
  $limit: Int
  $nextToken: String
)
{
listSalesOffices  (
filter: $filter      
limit: $limit
nextToken: $nextToken
) {
    items {
      id
      name
      code
      buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

export const SEARCH_SALESOFFICES = `
query SearchSalesOffices (
  $filter: SearchableSalesOfficeFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableSalesOfficeSortInput]
)
{
searchSalesOffices  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      name
      code
      district
      buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

export const SEARCH_CONTRACTOR_COMPANIES = `
query SearchContractorCompanies (
  $filter: SearchableContractorCompanyFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableContractorCompanySortInput]
)
{
searchContractorCompanies  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      name
      buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

export const LIST_BUILDINGS_BY_SALESOFFICE = `
query LIST_BUILDINGS_BY_SALESOFFICE(
  $id: ID!
  $filter: ModelBuildingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuildingsBySalesOffice(
    salesOfficeId: $id, 
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    ) {
    items {
      address
      accountName
      accountId
      address2
      buildingId
      city
      collectedDataTime
      connectionToleranceInMins
      contractorCompanyId
      country
      createdAt
      crmCustId
      deviceConnectivity
      floorArea
      groupAdmin
      groupUser
      id
      isActive
      lat
      long
      name
      offeringCodes
      postalCode
      salesOfficeCity
      salesOfficeCode
      salesOfficeDistrict
      salesOfficeId
      tz
      updatedAt
      salesOfficeName
      state
    }
  }
}
`

export const LIST_BUILDINGS_BY_CONTRACTOR_COMPANY = `
query LIST_BUILDINGS_BY_CONTRACTOR_COMPANY(
  $id: ID!
  $filter: ModelBuildingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuildingsByContractorCompany(
    contractorCompanyId: $id, 
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    ) {
    items {
      address
      accountName
      accountId
      address2
      buildingId
      city
      collectedDataTime
      connectionToleranceInMins
      contractorCompanyId
      country
      createdAt
      crmCustId
      deviceConnectivity
      floorArea
      groupAdmin
      groupUser
      id
      isActive
      lat
      long
      name
      offeringCodes
      postalCode
      salesOfficeCity
      salesOfficeCode
      salesOfficeDistrict
      salesOfficeId
      tz
      updatedAt
      salesOfficeName
      state
    }
  }
}
`

export const LIST_BUILDINGS_BY_ACCOUNT = `
query LIST_BUILDINGS_BY_ACCOUNT(
  $id: ID!
  $filter: ModelBuildingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBuildingsByAccount(
    accountId: $id, 
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    ) {
    items {
      address
      accountName
      accountId
      address2
      buildingId
      city
      collectedDataTime
      connectionToleranceInMins
      contractorCompanyId
      country
      createdAt
      crmCustId
      deviceConnectivity
      floorArea
      groupAdmin
      groupUser
      id
      isActive
      lat
      long
      name
      offeringCodes
      postalCode
      salesOfficeCity
      salesOfficeCode
      salesOfficeDistrict
      salesOfficeId
      tz
      updatedAt
      salesOfficeName
      state
    }
  }
}
`

export const GET_BUILDINGS = `
query GET_BUILDINGS (
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableBuildingSortInput]
){
  searchBuildings (
    filter: $filter      
    limit: $limit
    nextToken: $nextToken
    from: $from
    sort: $sort
) {
  nextToken
  total
  items {
    id
    name
    floorArea
    address2
    address
    accountId
    accountName
    city
    collectedDataTime
    connectionToleranceInMins
    country
    createdAt
    crmCustId
    lat
    long
    postalCode
    salesOfficeId
    salesOfficeName
    state
    tz
    updatedAt
  }
  }
}`

export const GET_RESOURCES = (resource) => {
  switch (resource) {
    case 'ContractorCompany':
      return `
      query GET_RESOURCES (
        $filter: Searchable${resource}FilterInput
        $limit: Int
        $nextToken: String
        $from: Int
        $sort: [Searchable${resource}SortInput]
      ){
        searchContractorCompanies (
          filter: $filter      
          limit: $limit
          nextToken: $nextToken
          from: $from
          sort: $sort
      ) {
        nextToken
        total
        items {
          id
          name
        }
        }
      }`
    case 'Building':
      return `
        query GET_RESOURCES (
          $filter: Searchable${resource}FilterInput
          $limit: Int
          $nextToken: String
          $from: Int
          $sort: [Searchable${resource}SortInput]
        ){
          search${resource}s (
            filter: $filter      
            limit: $limit
            nextToken: $nextToken
            from: $from
            sort: $sort
        ) {
          nextToken
          total
          items {
            id
            name
            salesOfficeId
            accountId
            accountName
            contractorCompanyId
            groupAdmin
            groupUser
          }
          }
        }`
    case 'SalesOffice':
      return `
      query GET_RESOURCES (
        $filter: Searchable${resource}FilterInput
        $limit: Int
        $nextToken: String
        $from: Int
        $sort: [Searchable${resource}SortInput]
      ){
        search${resource}s (
          filter: $filter      
          limit: $limit
          nextToken: $nextToken
          from: $from
          sort: $sort
      ) {
        nextToken
        total
        items {
          id
          name
           code
           district
        }
        }
      }`
    case 'Account':
      return `
      query GET_RESOURCES (
        $filter: Searchable${resource}FilterInput
        $limit: Int
        $nextToken: String
        $from: Int
        $sort: [Searchable${resource}SortInput]
      ){
        search${resource}s (
          filter: $filter      
          limit: $limit
          nextToken: $nextToken
          from: $from
          sort: $sort
      ) {
        nextToken
        total
        items {
          id
          name
        }
        }
      }`
    default:
      return `
      query GET_RESOURCES (
        $filter: Searchable${resource}FilterInput
        $limit: Int
        $nextToken: String
        $from: Int
        $sort: [Searchable${resource}SortInput]
      ){
        search${resource}s (
          filter: $filter      
          limit: $limit
          nextToken: $nextToken
          from: $from
          sort: $sort
      ) {
        nextToken
        total
        items {
          id
          name
        }
        }
      }`
  }
}

export const LIST_GROUPS = `
query LIST_RESOURCES (
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups (
    filter: $filter      
    limit: $limit
    nextToken: $nextToken
) {
  items {
    name
  }
  }
}`

export const GET_CONTRACTORCOMPANIES = `
query GET_CONTRACTORCOMPANIES {
  listContractorCompanies {
    items {
      id
      name
      buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

export const LIST_USER_ACCESSES_BY_RESOURCE_TYPE_AND_RESOURCE = `
query LIST_USER_ACCESSES_BY_RESOURCE_TYPE_AND_RESOURCE(
    $resType: BuildingGroup
    $filter: SearchableUserFilterInput
) {
  listUserAccessesByResourceTypeAndResource(resType: $resType) {
    items {
      id
      appId
      resource
      resType
      roleId
      roleType
      applicationAccessId
      gracePeriod
    }
  }
}
`

export const GET_ACCOUNTS = `
query GET_ACCOUNTS (
  $filter: SearchableAccountFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableAccountSortInput]
){
  searchAccounts (
    filter: $filter      
    limit: $limit
    nextToken: $nextToken
    from: $from
    sort: $sort
) {
    nextToken
    total
    items {
      id
      name
      buildings {
        items {
          id
          name
          accountId
          address 
          city
          state 
          salesOffice {
            id
            name
          }
        }
      }
    }
  }
}`

export const GET_USERS = `
query SEARCH_USERS(
    $filter: SearchableUserFilterInput
    $limit: Int
    $nextToken: String
    $from: Int
    $sort: [SearchableUserSortInput]
){
  searchUsers (
    filter: $filter      
    limit: $limit
    from: $from
    sort: $sort
    nextToken: $nextToken
    ){
    nextToken
    total
    items {
      email
      id
      userId
      isActive
      lastName
      createdAt
      firstName
      userTypeId
      startDate
      endDate
      type {
        name
        description
      }
    }
  }
}`

export const GET_USERS_LAMBDA = `
query GET_USERS_LAMBDA(
  $filter: AWSJSON
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: AWSJSON
){
  listUsersByAccesses (
    filter: $filter      
    limit: $limit
    from: $from
    sort: $sort
    nextToken: $nextToken
    ){
    nextToken
    total
    items {
      email
      id
      userId
      isActive
      lastName
      createdAt
      firstName
      userTypeId
      startDate
      endDate
      type {
        name
        description
      }
    }
  }
}`

export const getDataSalesOffices = `
query getSalesOffices {
  listSalesOffices {
    items {
      id
      name
      locations : buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

export const getContractorCompaniesData = `
query getContractorCompaniesData {
  listContractorCompanies {
    items {
      id
      name
      buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

// Buildings By Name
export const getBuildingsList = /* GraphQL */ `
  query GetBuildingsList(
    $filter: ModelBuildingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        locationId: id
        locationName: name
        serviceTeam: salesOffice {
          name
        }
        Organization: account {
          id
          name
        }
        locationAddressString: address
        city
        region: state
        country
      }
    }
  }
`

// Buildings by Service Team
export const getSalesOfficeslist = /* GraphQL */ `
  query GetSalesOffices(
    $filter: ModelSalesOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        district
        buildings {
          items {
            id
            name
            salesOfficeId
            accountId
            address
            city
            state
            country
          }
        }
      }
    }
  }
`

// List of Contractor Company

export const getContractorList = /* GraphQL */ `
  query getContractorList(
    $filter: ModelContractorCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        buildings {
          items {
            id
            name
            address
            city
            country
            state
            accountName
            accountId
          }
        }
      }
    }
  }
`

export const IS_USER_UNIQUE = `
	query checkEmail(
    $filter: ModelUserFilterInput ) {
    listUsers(filter: $filter) {
      items {
        email      
      }
    }
	}
`

export const GET_ACCOUNT_BY_ID = `
  query getAccountById($id: ID!) {
    getAccount(id: $id) {
      id
      name
    }
  }
`

export const GET_SALESOFFICE_BY_ID = `
  query getSalesOfficeById($id: ID!) {
    getSalesOffice(id: $id) {
      id
      name
    }
  }
`

export const GET_CONTRACTORCOMPANY_BY_ID = `
  query getContractorCompanyById($id: ID!) {
    getContractorCompany(id: $id) {
      id
      name
    }
  }
`

export const SEARCH_ACCOUNTS = `
query SEARCH_ACCOUNTS (
  $filter: SearchableAccountFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableAccountSortInput]
){
  searchAccounts (
    filter: $filter      
    limit: $limit
    nextToken: $nextToken
    from: $from
    sort: $sort
) {
    nextToken
    total
    items {
      id
      name
    }
  }
}`

export const SEARCH_BUILDINGS = `
query SearchBuildings (
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableBuildingSortInput]
)
{
searchBuildings  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      salesOfficeId
      accountId
      accountName
      groupAdmin
      groupUser
      contractorCompanyId
      buildingName: name
      address
      city
      state
    }
  }
}
`

export const SEARCH_BUILDINGS_SOLO = `
query SearchBuildings (
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableBuildingSortInput]
)
{
searchBuildings  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      name
      address
      city
      state
    }
  }
}
`
