import { useEffect, useState } from 'react'
import Table from '../../components/Table/clientSideTable'
import {
  listIssueFindingsByBuilding,
  listIssueFindingsOrganization,
  listIssueFindingsByMultipleBuildings
} from './graphql'
import moment from 'moment'
import { useIssuesFindingsContext } from './IssuesFindingsContextProvider'
import {
  findingIssueFoundFilterOptionList,
  statusOptionListForFilters
} from 'src/components/legacy/common/finding'
import { useQuery } from 'src/hooks/APIHooks'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { useNavigate } from 'react-router-dom'
import { ACTIONS } from 'src/constants'
import { MODAL_TYPE } from 'src/constants'
import translate from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { useSelector } from 'react-redux'
import { selectUserAccess, selectUserInfo } from 'src/redux/slicers/appData'

import { getDateRange } from 'src/pages/documentsAndReports/helper'
import { accessControlFunc } from 'src/components/accessControl'
import {isDateInBW} from "../documentsAndReports/consultation/helperFunction.js"
import { UiMode } from 'src/redux/types/AppTypes'
import { IssuesAndFindingsList } from 'src/denali-pages/IssuesAndFindings/IssuesAndFindingsList'
import { GET_CONSULTATION} from 'src/pages/documentsAndReports/graphql'
import { GET_BUILDINGS_BY_ACCOUNT_ID} from 'src/common/queries/datasource'


const IssuesFindingsList = ({ handleGetFindingRecords, uiMode }) => {
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId
  const startDate = searchParams?.startDate
  const endDate = searchParams?.endDate
  const consultationId = searchParams?.consultationId
  const [rows, setRows] = useState([])
  const [rowsHaveBeenSet, setRowsHaveBeenSet] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { reloadEvents, setReloadEvents, setFinding, setModalType } =
    useIssuesFindingsContext()
  const navigate = useNavigate()
  const [t] = useTranslation()

  const isDenali = uiMode === UiMode.denali

  const userAccess = useSelector(selectUserAccess)

  const optionsTranslated = {
    finding: translate('Finding Name'),
    building: translate('Building Name'),
    date: translate('Date Created'),
    equipment: translate('Equipment'),
    priority: translate('Priority'),
    status: translate('Status'),
    nextStep: translate('Next Step'),
    scheduled: translate('Scheduled For'),
    assigned: translate('Assigned To')
  }
  const { data: buildingsList, refetch: refetchBuildingsData } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    variables: { id: organizationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })
  const {
    data: consultationData,
    refetch: refetchConsultationDetails,
  } = useQuery(
    
      {
          query: GET_CONSULTATION,
          dataPath: 'data',
          variables: {id:consultationId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
  )
  useEffect(() =>{
    if(consultationId){
      refetchConsultationDetails({id:consultationId})
    }
  },[consultationId])

  useEffect(() => {
    if (organizationId) {
      refetchBuildingsData({ id: organizationId })
    }
  }, [organizationId])

  const multipleBuildingIds = consultationData?.getConsultation?.buildingIds;
  

  const { 
    data: issuesFindingsList, 
    refetch: refetchBuildingsList, 
    responseTime,
    loading
  } = useQuery(
    !buildingId
      ? {
          query: listIssueFindingsOrganization,
          dataPath: 'data',
          variables: { accountId: organizationId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
      : multipleBuildingIds && consultationId
      ? {
          query: listIssueFindingsByMultipleBuildings,
          dataPath: 'data',
          variables: multipleBuildingIds && consultationId
            ? {
                filter: {
                  or: multipleBuildingIds.map(id => ({
                    buildingId: { eq: id }
                  }))
                },
                limit: 1000,
                sort: [
                  {
                    direction: 'asc',
                    field: 'title'
                  }
                ]
              }
            : {},
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
      : {
          query: listIssueFindingsByBuilding,
          dataPath: 'data',
          variables: { buildingId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
  );
  useEffect(() => {
    if (multipleBuildingIds  && consultationId) {
      const variables = {
        filter: {
          or: multipleBuildingIds.map(id => ({
            buildingId: { eq: id }
          }))
        },
        limit: 1000,
        sort: [
          {
            direction: 'asc',
            field: 'title'
          }
        ]
      };
      setIsLoading(false)
      refetchBuildingsList(variables);
    } else if (buildingId && !consultationId) {
      setIsLoading(false)
      refetchBuildingsList({ buildingId });
    } else if (organizationId && !consultationId) {
      setIsLoading(false)
      refetchBuildingsList({ accountId: organizationId });
    }
  }, [buildingId,organizationId,multipleBuildingIds]);

  useEffect(() => {
    if (reloadEvents) {
      setTimeout(() => {
        setReloadEvents(false)
        if (buildingId) {
          refetchBuildingsList({ buildingId })
        } else if (organizationId) {
          refetchBuildingsList({ accountId: organizationId })
        }
      }, 1000);
    }
  }, [reloadEvents])


  useEffect(() => {
    if (loading || issuesFindingsList === null) {
      return
    }
    let issuesAndfindingsList = []
    let buildingsByAccount = []
    if(buildingId) {
      issuesAndfindingsList = issuesFindingsList?.searchIssues?.items || []
      const buildingDetails = issuesFindingsList?.getBuilding || null
      buildingsByAccount = buildingDetails ? [buildingDetails] : []
    } else {
      issuesAndfindingsList = issuesFindingsList?.searchIssues?.items || []
      buildingsByAccount = issuesFindingsList?.searchBuildings?.items || []
    }

    const records = !_isEmpty(issuesAndfindingsList)
    ? issuesAndfindingsList
        ?.filter((issue) =>
          !issue?.isVisible
            ? accessControlFunc({
                id: 'tc.pages.findings.view-private',
                userAccess
              })
            : issue?.isVisible
        )
        ?.map((issue) => {
          const buidling = buildingsByAccount.find(
            (x) => x.id === issue?.buildingId
          )
          return {
            ...issue,
            buildingName: buildingsList?.find(it=>it.key === issue.buildingId)?.value,
            building: {
              id: issue?.buildingId,
              name: buidling?.name || '',
              postalCode: buidling?.postalCode,
              address: buidling?.address,
              address2: buidling?.address2
            },
            creationDate: issue.creationDate
              ? moment(issue.creationDate).format('YYYY-MM-DD')
              : '',
            equipments: !_isEmpty(issue.equipments?.items)
              ? issue.equipments.items
                  ?.filter((x) => x.equipment !== null)
                  .map((eq) => `${eq.equipment?.type} > ${eq.equipment.name}`)
                  .join(',')
              : '',
            nextStep: t(`components:nextStepsFinding>${issue.nextStep}`),
            priority: t(`components:priority>${issue.priority}`),
            status: t(`components:findingStatuses>${issue.status}`)
          }
        })
    : []

    setRows(startDate && endDate ?  records 
      ?.filter((it) => (it?.opportunities?.items?.length === 0)&&(it?.isVisible === true))
      .filter((it) => it?.creationDate)
      .filter((it) => {
        return isDateInBW(startDate, endDate, it?.creationDate)
      }) : records)
    setRowsHaveBeenSet(true)
    handleGetFindingRecords(records)
  }, [issuesFindingsList, loading])

  const nameHeadings = [
    {
      title: optionsTranslated.finding,
      key: 'title',
      maxWidth: '200px',
      onDataClick: (data) => {
        navigate(`/${PAGE_NAMES.FINDINGS}/${data.id}`, {
          state: { mode: ACTIONS.VIEW }
        })
      }
    },
    {
      title: optionsTranslated.building,
      key: 'buildingName',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.date,
      key: 'creationDate',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.equipment,
      key: 'equipments',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.priority,
      key: 'priority',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.status,
      key: 'status',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.nextStep,
      key: 'nextStep',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.scheduled,
      key: 'targetDate',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.assigned,
      key: 'assignedTo',
      maxWidth: '50px'
    }
  ]

  const handleUpdatedTableRows = (updatedRows) => {
    const updatedRowIds = updatedRows?.map(m=>m.id) ?? []
    let issuesAndfindingsList = []
    let buildingsByAccount = []

    if(buildingId) {
      issuesAndfindingsList = rows || []
      const buildingDetails = issuesFindingsList?.getBuilding || null
      buildingsByAccount = buildingDetails ? [buildingDetails] : []
    } else {
      issuesAndfindingsList = rows || []
      buildingsByAccount = issuesFindingsList?.searchBuildings?.items || []
    }
    const records =  !_isEmpty(issuesAndfindingsList)
    ? issuesAndfindingsList?.filter(i => updatedRowIds.includes(i?.id))?.map((issue) => {
      const buidling = buildingsByAccount.find(x=>x.id === issue?.buildingId)
    return ({
        ...issue,
        building: {
          name: buidling?.name || '',
          postalCode:  buidling?.postalCode,
          address: buidling?.address,
          address2: buidling?.address2
        }
      })})
    
    : []
    handleGetFindingRecords(records)
  } 

  const filtersList = [
    {
      type:'dateRangeFilter',
      key: 'type',
      filterName: 'typeFilter',
      filterLabel: 'Type',
      id: 'typeSelectorFilter',
      defaultLabel: 'All Creation Dates',
      selectedValue: 'default',
      options: [
        // TODO: We need enums to prevent adding random strings to types on the backend. These will reflect the enums when done
        { name: 'In the last week', value: 'In the last week' },
        { name: 'In the last month', value: 'In the last month' },
        { name: 'In the last 3 months', value: 'In the last 3 months' },
        { name: 'In the last 6 months', value: 'In the last 6 months' },
        { name: 'In the last year', value: 'In the last year' }
      ]
    },
    {
      key: 'status',
      filterName: 'statusFilter',
      filterLabel: 'Status',
      id: 'statusSelectorFilter',
      defaultLabel: 'All Statuses',
      selectedValue: 'default',
      multiple:true,
      name: 'status',
      options: statusOptionListForFilters(t)
    },
    {
      key: 'foundDuring',
      filterName: 'foundDuringFilter',
      filterLabel: 'Found during',
      id: 'foundDuringSelectorFilter',
      defaultLabel: 'All Found During',
      selectedValue: 'default',
      name: 'foundDuring',
      options: findingIssueFoundFilterOptionList(t)
    }
  ]

  const rowControl = [
    {
      text: isDenali ? 'Copy' : t('opportunities:CopyFinding'),
      action: (data) => {
        navigate(`/${PAGE_NAMES.FINDINGS}/${data.id}`, {
          state: { mode: ACTIONS.COPY }
        })
      }
    },
    {
      text: isDenali ? 'Edit' : t('opportunities:EditFinding', {
        findingName: t('opportunities:Finding')
      }),
      action: (data) => {
        navigate(`/${PAGE_NAMES.FINDINGS}/${data.id}`, {
          state: { mode: ACTIONS.EDIT }
        })
      }
    },
    {
      text: isDenali ? 'Delete' : t('common:Delete', { item: t('opportunities:Finding') }),
      action: (data) => {
        setFinding(data)
        setModalType(MODAL_TYPE.CONFIRMATION)
      }
    }
  ]

  const rowActionCallback = (nameHeadings) => {
    return (event, row) => {
      if (event?.target?.tagName === 'BUTTON') return
      nameHeadings.find((heading) => heading.key === 'title').onDataClick(row)
      event?.preventDefault()
    }
  } 
  
  return (
    <>
    {isDenali ? (
      <IssuesAndFindingsList 
        rows={rows}
        optionsTranslated={optionsTranslated}
        rowControl={rowControl}
        filtersList={filtersList}
        listDataIsLoading={loading || !rowsHaveBeenSet}
        rowActionCallback={rowActionCallback(nameHeadings)}
      />
    ) : (
      <Table
        key={`IssuesFindingsTable-${organizationId} ${buildingId}`}
        rows={rows}
        header={nameHeadings}
        loadTime={responseTime}
        search={true}
        searchFields={['title']}
        handleUpdatedTableRows={(updatedRows)=>handleUpdatedTableRows(updatedRows)}
        getDateRange={getDateRange}
        showSpinner={loading || isLoading}
        rowControl={rowControl}
        filtersList={filtersList}
      />
    )}
    </>
  )
}

export default IssuesFindingsList
