import { useEffect, useRef } from "react";
import styles from './dialog.module.scss';
import { Button, Heading } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { TranslateComponent } from "src/common/translations";

export const Dialog = ({
  isOpen=true,
  onClose=()=>{},
  title='',
  actions=null,
  headerActions=null,
  showHeader=true,
  className='',
  width=null,
  children,
  ...props
}) => {
  const dialog = useRef(null);
  const [t] = useTranslation();
  useEffect(onOpenChange(isOpen, dialog), [isOpen]);
  return (
    <dialog className={`denali-dialog ${styles.dialog} ${className}`} ref={dialog} onCancel={onClose} data-testid={props?.['data-testid']} style={{width}}>
      {showHeader &&
        <div className={styles.title}>
          <div className={styles.titleHeading}>
            <Heading level={2} className={`${styles.titleHeadline} h4`}><TranslateComponent>{title}</TranslateComponent></Heading>
          </div>
          <div className={styles.titleButtons}>
            { headerActions }
            <Button onClick={onClose} aria-label={t('common:Close')} className={styles.closeButton} data-testid="dialogClose">
              <FontAwesomeIcon icon={faClose} width={16} height={16} />
            </Button>
          </div>
        </div>
      }
      
      {children}

      { actions && 
        <div className={styles.footer}>
          {actions}
        </div>
      }
    </dialog>
  )
}

function onOpenChange(isOpen, dialog) {
  return () => {
    if(!dialog.current) return;
    isOpen ? dialog.current?.showModal() : dialog.current?.close();
  }
}