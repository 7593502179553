import { type ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faFile, faFileLines, faTachographDigital, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faRadar } from '../lib/pro-solid-svg-icons'
import styles from "./table.module.scss"
import { consultationType, documentType, fileType, energyUsageReportType } from 'src/pages/documentsAndReports/helper'
import { getColor as getRandomColor } from 'src/denali-components/helpers'

export const IconCell = ({iconType, children, iconSize = IconSize.normal, randomizeColor }: IconCellProps )=> {
  const color = randomizeColor ? getRandomColor(randomizeColor) : getIconColor(iconType);
  const icon = getIcon(iconType);

  return (
    <div className={styles.iconCell}>
      <div className={styles.iconCellIcon} data-icon-color={color} data-size={iconSize}>
        <FontAwesomeIcon icon={icon} />
      </div>
      {children &&
        <div>
          {children}
        </div>
      }
    </div>
  )
}

export type IconCellProps = {
  iconType: string,
  children?: ReactNode,
  iconSize?: IconSize,
  randomizeColor?: string
}

export enum IconSize {
  normal='normal',
  fortyFour='fortyFour',
  fortyFourAlt='fortyFourAlt',
}

function getIcon(iconType) {
  switch (iconType) {
    case documentType:
      return faFileLines;
    case consultationType:
      return faUserGroup;
    case energyUsageReportType:
      return faBolt;
    case fileType:
      return faFile;
    case 'issues/findings':
      return faRadar;
    case 'SC/SC+':
    case 'CM':
    case 'Symbio 700':
    case 'Ensemble':
    case 'Symbio 800':
    case 'SCG':
    case 'deviceUnavailable':
      return faTachographDigital;
  }
  return faFileLines;
}

function getIconColor(iconType) {
  switch (iconType) {
    case documentType:
      return 'purple';
    case consultationType:
      return 'pink';
    case energyUsageReportType:
      return 'blue';
    case fileType:
      return 'gray-duo';
    case 'SC/SC+':
      return 'blue-duo';
    case 'CM':
      return 'purple-duo';
    case 'Symbio 700':
      return 'pink-duo';
    case 'Ensemble':
      return 'turquoise-duo';
    case 'Symbio 800':
      return 'green-duo';
    case 'SCG':
      return 'gold-duo';
    case 'deviceUnavailable':
      return 'red-duo';
  }
  return '';
}