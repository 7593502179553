import { TranslateComponent } from "src/common/translations";
import { Chip, ChipColor } from "./Chip"

export const ChipStatus = ({ statusText }:Props) => {
  if(statusText === 'inProgress') statusText = 'In Progress'
  const formattedText = formatText(statusText)
  const color = getColor(statusText);
  return (
    <Chip color={color}>
      <TranslateComponent>{formattedText}</TranslateComponent>
    </Chip>
  )
}

interface Props {
  statusText: ChipStatuses
}

export type ChipStatuses =
  'In Progress'
  | 'inProgress'
  | 'Ready for Review'
  | 'Collecting Data'
  | 'Complete'
  | 'Recommended'
  | 'Validation'
  | 'Train Deferred'
  | 'Customer Deferred'
  | 'Deferred'

function getColor(statusText) {
  switch(statusText.toLowerCase()) {
    case 'in progress':
      return ChipColor.blue
    case 'ready for review':
      return ChipColor.orange
    case 'collecting data':
      return ChipColor.purple
    case 'complete':
      return ChipColor.green
    case 'recommended':
      return ChipColor.purple
    case 'validation':
      return ChipColor.gold
    case 'customer deferred':
      return ChipColor.pink
    case 'train deferred':
      return ChipColor.orange
    case 'deferred':
      return ChipColor.default
  }
  return ChipColor.default
}

function formatText(statusText) {
  switch(statusText.toLowerCase()) {
    case 'inprogress':
      return 'In Progress'
    case 'readyforreview':
      return 'Ready for Review'
    case 'collectingdata':
      return 'Collecting Data'
    case 'complete':
      return 'Complete'
    case 'recommended':
      return 'Recommended'
    case 'validation':
      return 'Validation'
    case 'customerdeferred':
      return 'Customer Deferred'
    case 'traindeferred':
      return 'Train Deferred'
    case 'deferred':
      return 'Deferred'
  }
  return statusText
}