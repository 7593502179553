import styles from './card-details.module.scss'
import translate, { TranslateComponent } from 'src/common/translations'
import { formatCurrency } from 'src/common/currency'
import { Chip, ChipColor } from 'src/denali-components/Table/Chip'

interface CardDetailsProps {
  style?: string
  title: string
  icon?: any
  iconColor?: string
  primary?: number
  primaryFormat?: string
  chip?: string
  chipIcon?: any
  disclaimer?: string
  description?: Array<string>
}

export const CardDetails = ({
  style,
  title,
  icon,
  iconColor,
  primary,
  primaryFormat,
  chip,
  chipIcon,
  disclaimer,
  description
}: CardDetailsProps) => {
  return (
    <div className={styles.cardDetails} data-style={style}>
      <div className={styles.icon} data-color={iconColor}>
        {icon}
      </div>
      {title && <span className={styles.title}>
        <TranslateComponent>{title}</TranslateComponent>
      </span>}
      {primary && <span className={styles.primary}>
        {formatPrimary(primary, primaryFormat)} {chip && <Chip text={chip} icon={chipIcon} color={ChipColor.transparentWhite}/>}
      </span>}
      {disclaimer && <span className={styles.disclaimer}>
        <TranslateComponent>{disclaimer.charAt(0).toUpperCase() + disclaimer.slice(1)}</TranslateComponent>
      </span>}
      {description && description.map((paragraph, index) => (
        <div key={index} className={styles.description}>
          <p><TranslateComponent>{paragraph}</TranslateComponent></p>
        </div>
      ))}
    </div>
  )
}

export enum CardDetailsFormatPrimary {
  USD = 'USD',
  PERCENT = 'percent',
  YEAR = 'year'
}

const formatPrimary = (primary: number, primaryFormat: string) => {
  if (primaryFormat === CardDetailsFormatPrimary.USD) {
    return formatCurrency(Math.round(primary), 'USD')
  }
  if (primaryFormat === CardDetailsFormatPrimary.PERCENT) {
    return `${primary.toLocaleString('en-US')}%`
  }
  if (primaryFormat === CardDetailsFormatPrimary.YEAR) {
    return primary >= 1
      ? `${primary.toLocaleString('en-US')} years`
      : `${(primary * 12).toLocaleString('en-US')} months`
  }
  return primary
}
