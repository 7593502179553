import unionBy from 'lodash/unionBy'
import { type NavigateFunction } from 'react-router-dom'

// Project imports
import { ACTIONS } from 'src/constants/actions'
import { BuildingId } from 'src/components/buildingNavigation/graphql'
import { sortHelper } from 'src/common/helperFunctions'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { EQUIPMENT_LENGTH_WITH_CLEAR_ALL } from 'src/components/target-path/helper'
import { getAllEquipmentTypes } from 'src/components/target-path/helper'

// Denali imports
import { TisObject } from 'src/denali-pages/IssuesAndFindings/types.d'
import { BuildingBase, Equipment } from 'src/denali-pages/types'

export type EquipmentChangeSelectProps = {
  value: Equipment[]
  values: any
  tisObjectForSelector: any
  equipments: Equipment[]
  useClearAll?: boolean
}

export type EquipmentChangeSelectReturn = {
  selectedTisObjects: TisObject[]
}

export type BuildingListChangeProps = {
  buildingsList: BuildingBase[]
  buildingId: BuildingId
  onBuildingChangeSelect: ({key, value}: {key: string, value: string}) => void
  setBuildings: (buildings: BuildingBase[]) => void
}

export const handleBuildingListChange = ({ buildingsList, buildingId, onBuildingChangeSelect, setBuildings } : BuildingListChangeProps) => {
  const sortedBuildingList = sortHelper(buildingsList) 
  const list = sortedBuildingList?.map((building) => ({
    key: building.key,
    value: building.value,
    searchableValue: building.value
  }))
  setBuildings(list)
  const locationId = buildingId
  if (locationId) {
    const building = sortedBuildingList?.filter(
      (building) => building.key === locationId
    )[0]
    if (building) {
      onBuildingChangeSelect(building)
    }
  }
}

export const handleEquipmentChangeSelect = ({value, values, tisObjectForSelector, equipments, useClearAll = true} : EquipmentChangeSelectProps) => {
  const equipmentTypes = getAllEquipmentTypes(equipments)
  let selectedTisObjects = []
  if (value.some(({ key }) => key === 'all')) {
    selectedTisObjects = tisObjectForSelector.map(({ key }) => ({
      tisObjectId: key
    }))
  } else if (value.some(({ key }) => equipmentTypes.includes(key))) {
    const { key: equipmentType } = value.find(({ key }) =>
      equipmentTypes.includes(key)
    )
    const equipmentByType = equipments
      .filter(({ type }) => type === equipmentType)
      .sort((a, b) => a.value.localeCompare(b.value))
      .map(({ key }) => ({ tisObjectId: key }))
    selectedTisObjects = unionBy(
      values.tisObjects.map(({ tisObjectId }) => ({
        tisObjectId: tisObjectId
      })),
      equipmentByType,
      'tisObjectId'
    )
  } else if (
    value.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL &&
    values.tisObjects.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL &&
    useClearAll &&
    !value.some(({ key }) => key === 'clear')
  ) {
    selectedTisObjects = []
  } else {
    value.length &&
      (selectedTisObjects = value
        .filter(({ key }) => key !== 'clear')
        .map(({ key }) => ({ tisObjectId: key })))
  }

  return selectedTisObjects
}

export const handleAddDetailClick = (values, locationId, organizationId, navigate: NavigateFunction) => {
  const { name, location, tisObjects, comment, priority, status, nextStep, foundDuring, settings } = values
  const objectToPass = {
    name,
    location,
    tisObjects,
    comment,
    priority,
    status,
    nextStep,
    foundDuring,
    settings
  }
  navigate(
    `/${PAGE_NAMES.FINDINGS}/add?location=${locationId}&organization=${organizationId}`,
    { state: { 
      mode: ACTIONS.ADD,
      quickAddMode: true,
      ...objectToPass
    } }
  )
}
