import React, { useState, useEffect } from 'react'
import { MdMoreVert, MdArrowDropDown } from 'react-icons/md/index.esm.js'
import { OutsideHandler } from '@trane/trane-components/dist/simple/utils'
import { ConditionalTooltip } from '../legacy/components/tooltip/conditional-tooltip'
import Checkbox from 'src/denali-ui/components/Checkbox'
import { isClickOrKeyboardActivate } from "src/denali-components/helpers.ts"
import {
  RowControlText,
  TableRow,
  TableDown,
  Dots,
  RowPopup,
  RowControl
} from './components'
import { getPropByPath } from 'src/utils/index.ts'
import { RowActions } from 'src/denali-components/Table/RowActions'

export const TableRowItem = ({
  rowkey,
  header,
  row,
  rowAction,
  rowControl,
  setRowPopup,
  rowPopup,
  tableControls,
  fixedLayout,
  subDataOpenFunction,
  subDataCloseFunction,
  rowCheckbox,
  checkboxClick,
  checkboxChecked,
  rowEndCheckbox,
  checkboxEndClick,
  checkboxEndChecked,
  subLevel = 0,
  tableRowOverflowHidden = true,
  testName='',
  isDenaliTable = false,
}) => {
  const [subTableOpen, setSubTableState] = useState(false)
  const subTableData = row?.subData

  useEffect(() => {
    if (subTableOpen) {
      subDataOpenFunction?.(tableControls, row)
    } else {
      subDataCloseFunction?.(tableControls, row)
    }
  }, [subTableOpen, tableControls?.filters])

  const handleRowAction = (event) => {
    const isFromChildElement = (event?.target?.tagName === 'BUTTON');

    if(isFromChildElement) return;
    if(!isClickOrKeyboardActivate(event)) return;
    
    rowAction(event, row);
  }
  return (
    <>
      <TableRow
        data-testid={`${testName}-table-row-${rowkey}`}
        key={`Table-Row-${rowkey}`}
        onClick={rowAction ? handleRowAction : null}
        onKeyDown={rowAction ? handleRowAction : null}
        tabIndex={rowAction ? "0" : null}
        className={rowAction ? 'tr-clickable' : 'tr-readonly'}
      >
          
        {header.map((data, datakey) => {
          return (
            <TableDown
              alignRight={Boolean(data.alignRight)}
              key={`Table-Down-${rowkey}-${datakey}`}
              maxWidth={data?.maxWidth}
              fixedLayout={fixedLayout}
              subLevel={Boolean(row?.[data?.key]) ? subLevel : 0}
              lastColumn={Boolean(datakey === header.length - 1)}
              tableRowOverflowHidden={tableRowOverflowHidden}
              clickAble={typeof data.onDataClick === 'function'}
            >
              {data?.customComponent ? (
                <>{data?.customComponent(row)}</>
              ) : (
                <>
                  {data.type !== 'icon' ? (
                    <ConditionalTooltip
                    className = "tableRow tableRowBulkActionCheckbox"
                      type={ConditionalTooltip.TYPE.ICON}
                      conditional={true}
                      content={row?.[data?.key]}
                      testName={`${testName}-${data?.key}`}
                    >
                      {datakey === 0 && rowCheckbox ? typeof rowCheckbox === "function" 
                      ? rowCheckbox(row) 
                        ? (
                          <Checkbox
                          intermediate ={row.intermediate}
                          isDenaliTable={isDenaliTable}
                            onClick={() => checkboxClick?.(row)}
                            checked={row?.['checkboxCheckedEnabled'] !== undefined
                                ? row?.['checkboxCheckedEnabled']
                                : checkboxChecked !== undefined
                                ? checkboxChecked(row)
                                : undefined
                            }
                          />
                        )
                        : "" 
                      : (
                        <Checkbox
                        intermediate ={row.intermediate}
                        isDenaliTable={isDenaliTable}
                          onClick={() => checkboxClick?.(row)}
                          checked={row?.['checkboxCheckedEnabled'] !== undefined
                              ? row?.['checkboxCheckedEnabled']
                              : checkboxChecked !== undefined
                              ? checkboxChecked(row)
                              : undefined
                          }
                        />
                      ) : (
                        ''
                      )}
                      {datakey === 0 &&
                      (row?.subData?.length || subDataOpenFunction) ? (
                        <MdArrowDropDown
                        size="24"
                          name="Down"
                          transform={subTableOpen ? 'rotate(180)' : null}
                          key={`Table-Down-Expander-${rowkey}-${datakey}`}
                          onClick={() => setSubTableState(!subTableOpen)}
                        />
                      ) : (
                        ''
                      )}
                      <span
                       className={typeof data.onDataClick === 'function' ? "textClickable" : ""}
                        onClick={
                          typeof data.onDataClick === 'function' &&
                          row?.[data?.key]
                            ? () => data.onDataClick(row)
                            : undefined
                        }
                      >
                        {getPropByPath(row, data?.key)}
                      </span>
                      {rowEndCheckbox && datakey === header.length - 1 ? (
                        <Checkbox
                          onClick={() => checkboxEndClick?.(row)}
                          checked={
                            row?.['endCheckboxCheckedEnabled'] !== undefined
                              ? row?.['endCheckboxCheckedEnabled']
                              : checkboxEndChecked !== undefined
                              ? checkboxEndChecked(row)
                              : undefined
                          }
                        />
                      ) : (
                        ''
                      )}
                    </ConditionalTooltip>
                  ) : (
                    row?.[data?.key]
                  )}
                </>
              )}
            </TableDown>
          )
        })}
        { isDenaliTable && <RowActions rowControl={rowControl} row={row} /> }
        { !isDenaliTable && (
          <>
            {(typeof rowControl === 'function' && rowControl(row)?.length) ||
            (typeof rowControl !== 'function' && rowControl?.length) ? (
              <Dots onClick={() => setRowPopup(rowkey)}>
                <MdMoreVert />
                {rowPopup === rowkey && (
              <OutsideHandler condition={true} action={() => setRowPopup(false)}>
                <RowPopup>
                  {typeof rowControl === 'function'
                    ? rowControl(row).map((control, key) => {
                        return (
                          <RowControl key={`row-control-${key}`}>
                            <RowControlText onClick={() => {
                              control.action(row)
                              setRowPopup(false)
                            }}>
                              {control.text}
                            </RowControlText>
                          </RowControl>
                        )
                      })
                    : rowControl.map((control, key) => {
                        return (
                          <RowControl key={`row-control-${key}`}>
                            <RowControlText onClick={() => {
                              control.action(row)
                              setRowPopup(false)
                            }}>
                              {control.text}
                            </RowControlText>
                          </RowControl>
                        )
                      })}
                </RowPopup>
              </OutsideHandler>
            )}
              </Dots>
            ) : rowControl ? (
              <td />
            ) : (
              ''
            )}
          </>
        ) }
      </TableRow>

      {subTableOpen && subTableData
        ? subTableData.map((subDataRow, indexKey) =>
            subDataRow?.subData?.length ? (
              <>
                <TableRowItem
                  key={`Table-RowItem-Sub-${rowkey}-${indexKey}`}
                  subLevel={subLevel + 1}
                  rowkey={`Sub-${rowkey}-${indexKey}`}
                  header={header}
                  row={subDataRow}
                  rowControl={rowControl}
                  setRowPopup={setRowPopup}
                  rowPopup={rowPopup}
                  rowCheckbox={subDataRow.rowCheckbox}
                  rowEndCheckbox={subDataRow.rowEndCheckbox}
                  checkboxClick={(r) => subDataRow.checkboxClick?.(r)}
                  checkboxChecked={(e) => subDataRow.checkboxChecked?.(e)}
                  checkboxEndClick={(r) => subDataRow.checkboxEndClick?.(r)}
                  checkboxEndChecked={(e) => subDataRow.checkboxEndChecked?.(e)}
                />
              </>
            ) : (
              <TableRow key={`Table-Row-Sub-${rowkey}-${indexKey}`}>
                {header.map((data, datakey) => {
                  return (
                    <TableDown
                      alignRight={Boolean(data.alignRight)}
                      key={`Table-Down-${rowkey}-${indexKey}-${datakey}`}
                      maxWidth={data?.maxWidth}
                      fixedLayout={fixedLayout}
                      subLevel={subDataRow?.[data?.key] ? subLevel + 1 : 0}
                      tableRowOverflowHidden={tableRowOverflowHidden}
                    >
                      {data?.customComponent ? (
                        <>{data?.customComponent(subDataRow)}</>
                      ) : (
                        <>
                          <ConditionalTooltip
                            type={ConditionalTooltip.TYPE.ICON}
                            conditional={true}
                            content={subDataRow?.[data?.key]}
                          >
                            {((rowCheckbox && typeof rowCheckbox === "function" 
                              ? rowCheckbox(data) :
                              ( rowCheckbox && datakey === 0 && subDataRow?.[data?.key]))  ||
                            (rowCheckbox &&
                              !subDataRow?.[header[0].key] &&
                              datakey === 1)) ? (
                              <Checkbox
                                onClick={() => checkboxClick?.(subDataRow)}
                                checked={subDataRow?.['checkboxCheckedEnabled'] !== undefined
                                    ? subDataRow?.['checkboxCheckedEnabled']
                                    : checkboxChecked !== undefined
                                    ? checkboxChecked(subDataRow)
                                    : undefined
                                }
                              />
                            ) : (
                              ''
                            )}
                            <span
                              className={typeof data.onDataClick === 'function' ? "textClickable" : ""}
                              onClick={
                                typeof data.onDataClick === 'function' &&
                                subDataRow?.[data?.key]
                                  ? () => data.onDataClick(subDataRow)
                                  : undefined
                              }
                            >
                              {subDataRow?.[data?.key]}
                            </span>
                            {rowEndCheckbox && datakey === header.length - 1 ? (
                              <Checkbox
                                onClick={() => checkboxEndClick?.(subDataRow)}
                                checked={
                                  subDataRow?.['endCheckboxCheckedEnabled'] !== undefined
                                    ? subDataRow?.['endCheckboxCheckedEnabled']
                                    : checkboxEndChecked !== undefined
                                    ? checkboxEndChecked(subDataRow)
                                    : undefined
                                }
                              />
                            ) : (
                              ''
                            )}
                          </ConditionalTooltip>
                        </>
                      )}
                    </TableDown>
                  )
                })}
                {(typeof rowControl === 'function' &&
                  rowControl(subDataRow)?.length) ||
                (typeof rowControl !== 'function' && rowControl?.length) ? (
                  <Dots
                    onClick={() => setRowPopup(`Sub-${rowkey}-${indexKey}`)}
                  >
                    {rowPopup === `Sub-${rowkey}-${indexKey}` && (
                  <OutsideHandler
                    condition={true}
                    action={() => setRowPopup(false)}
                  >
                    <RowPopup>
                      {typeof rowControl === 'function'
                        ? rowControl(subDataRow)?.map((control, key) => {
                            return (
                              <RowControl key={`row-control-${key}`}>
                                <RowControlText
                                  onClick={() => {
                                    control.action(subDataRow)
                                    setRowPopup(false)
                                  }}
                                >
                                  {control.text}
                                </RowControlText>
                              </RowControl>
                            )
                          })
                        : rowControl?.map((control, key) => {
                            return (
                              <RowControl key={`row-control-${key}`}>
                                <RowControlText
                                  onClick={() => {
                                    control.action(subDataRow)
                                    setRowPopup(false)
                                  }}
                                >
                                  {control.text}
                                </RowControlText>
                              </RowControl>
                            )
                          })}
                    </RowPopup>
                  </OutsideHandler>
                )}
                    <MdMoreVert />
                  </Dots>
                ) : rowControl ? (
                  <td />
                ) : (
                  ''
                )}
              </TableRow>
            )
          )
        : ''}
    </>
  )
}

export default TableRowItem
