import { createSlice } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import Findings from 'src/models/findings'

import { FormDataTypes } from '../types/FormDataTypes'
import { TStore } from '../store'
import { Document } from 'src/models/document'
import { BuildingLocation } from 'src/models/location'
import moment from 'moment'

export const defaultDocumentValues = new Document({
  documentName: '',
  documentType: '',
  documentStatus: '',
  visibility: true,
  location: new BuildingLocation({
    locationId: '',
  }), 
  createdBy: '',
  createdDate: moment().format('YYYY-MM-DD'),
  note: '',
  attachments: [],
  description: '',
  startDate: moment().format('YYYY-MM-DD')
})

const initialState: FormDataTypes = {
  issuesAndFindings: new Findings({ organizationId: '', locationId: '', comment: '', tisObjects: [] }),
  documentsAndReports: cloneDeep(defaultDocumentValues),
  energyUsageReports: {}
}

export const formData = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    setIssuesAndFindings: (state, action) => {
      state.issuesAndFindings = cloneDeep(action.payload)
    },
    setDocumentsAndReports: (state, action) => {
      state.documentsAndReports = cloneDeep(action.payload)
    },
    setEnergyUsageReports: (state, action) => {
      state.energyUsageReports = action.payload
    }
  },
})

export const {
  setIssuesAndFindings,
  setDocumentsAndReports,
  setEnergyUsageReports
} = formData.actions

export const selectIssuesAndFindingsData = (state: TStore) => state.formData.issuesAndFindings
export const selectDocumentsAndReportsData = (state: TStore) => state.formData.documentsAndReports
export const selectEnergyUsageReportsData = (state: TStore) => state.formData.energyUsageReports
