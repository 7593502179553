import { useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Characteristics, DataMapping } from '../index'
import { InfoText, CurveNotDefinedText } from './styles'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import Modal from 'src/components/legacy/components/modal/modal'
import { GroupedPropertyUiModel } from './status/model'
import { TargetKeyType } from './status/constants'
import { TranslateComponent } from '../../../../common/translations'
import store from 'src/redux/store'

import './automated-test-settings-edit.scss'

export const AutomatedTestsSettingsEditDialog = ({
  toggleDialog,
  onDataMappingEdit,
  equipmentDetails,
  displayNameListProperties,
  defaultDimensionGroup,
  selectedTest,
  isCharacteristicCurveDefined = true,
  onSavePropertiesATSChangesHandler,
  onSaveCharacteristicsATSChangesHandler
}) => {
  const [
    showOnlyUndefinedRequiredProperties,
    setShowOnlyUndefinedRequiredProperties
  ] = useState(false)

  // TODO [REMOVE] : To test in DEV, keeping this for UI & Backend Team
  console.log('selectedTest :', selectedTest)

  const profileSettings =
    store?.getState().appData?.userInfo?.data?.profileSettings
  const UOMbyProfile = profileSettings
    ? JSON.parse(profileSettings)?.unitOfMeasurement === 'si'
      ? 'SI'
      : 'IP'
    : 'IP'

  const dialogRef = useRef()

  const modalConfig = {
    className: classNames(
      'equipment-setup-details-page',
      'automated-test-settings-edit',
      'layout-3-columns',
      'additional'
    ),
    gray: true,
    heading: `${equipmentDetails?.name}: ${selectedTest?.displayName}`,
    buttons: [
      {
        text: 'Close',
        handleClick: () => toggleDialog(),
        type: 'cancel'
      }
    ].filter(Boolean),
    handleClose: () => toggleDialog()
  }

  const toggleUndefinedPropsShow = () => {
    setShowOnlyUndefinedRequiredProperties(!showOnlyUndefinedRequiredProperties)
  }

  const atProperties = useMemo(() => {
    const { properties = [], groups = [] } = selectedTest.uiProperties ?? {}

    if (showOnlyUndefinedRequiredProperties) {
      const undefinedProps = filterUndefineProperties(properties)
      return handleProperties(undefinedProps)
    }

    const groupProps = handleGroupProperties(groups)

    const props = handleProperties(properties)

    return props.concat(groupProps)
  }, [selectedTest, showOnlyUndefinedRequiredProperties])

  const atCharacteristics = useMemo(() => {
    const { characteristics = [] } = selectedTest.uiProperties ?? {}

    if (showOnlyUndefinedRequiredProperties) {
      const undefinedProps = filterUndefineProperties(characteristics)
      return handleProperties(undefinedProps)
    }

    return handleProperties(characteristics)
  }, [selectedTest, showOnlyUndefinedRequiredProperties])

  return (
    <Modal
      testName={`${DETAILS_TEXT_NAME_PREFIX('automatedTestsDialog')}`}
      {...modalConfig}
      ref={dialogRef}
    >
      {!isCharacteristicCurveDefined && (
        <CurveNotDefinedText>
          Characteristics curve is not defined for this automated test
        </CurveNotDefinedText>
      )}
      {!selectedTest?.mappingStatus && (
        <InfoText>
          <TranslateComponent>
            For this automated test to run, all required inputs must be defined.
          </TranslateComponent>
        </InfoText>
      )}
      <Checkbox
        label={'Show undefined required properties and characteristics'}
        isChecked={showOnlyUndefinedRequiredProperties}
        id={'show-undefined-required'}
        toggleCheck={() => toggleUndefinedPropsShow()}
      />
      <div className="property-block">
        <DataMapping
          onEdit={onDataMappingEdit}
          equipmentDetails={equipmentDetails}
          displayNameListProperties={displayNameListProperties}
          defaultDimensionGroup={defaultDimensionGroup}
          atsDataMappingProperties={atProperties}
          atsPage={true}
          onSavePropertiesATSChangesHandler={onSavePropertiesATSChangesHandler}
          UOMbyProfile={UOMbyProfile}
        />
      </div>

      <div className="characteristic-block">
        <Characteristics
          equipmentDetails={equipmentDetails}
          atsTestCharacteristics={atCharacteristics}
          onSaveCharacteristicsATSChangesHandler={
            onSaveCharacteristicsATSChangesHandler
          }
          atsPage={true}
          defaultDimensionGroup={defaultDimensionGroup}
        />
      </div>
    </Modal>
  )
}

function filterUndefineProperties(properties = []) {
  return properties.filter((prop) => {
    return !prop.mappingStatus
  })
}

function handleProperties(properties = []) {
  return properties.map((prop) => prop.sourceDetails)
}

function handleGroupProperties(groups = []) {
  let inputProps = []

  const uiPropertiesItem = groups.reduce((av, cv) => {
    if (cv.targetKeyDetails?.length) av.push(cv)

    return av
  }, [])

  const containerheader = uiPropertiesItem.length
    ? GroupedPropertyUiModel(
        'Grouped Properties',
        '',
        'group-container-header',
        false
      )
    : null

  containerheader && inputProps.push(containerheader)

  for (let grp of uiPropertiesItem) {
    // group props
    const targetKeyDetails = grp.targetKeyDetails || []

    // find the rool level conditions whether it is OR or AND
    const hasOr =
      typeof grp.groupTargetKey === 'string'
        ? grp.groupTargetKey.includes('|')
        : grp.groupTargetKey?.rel === 'or'

    // Preferred group Title
    const groupHeaderModel = GroupedPropertyUiModel(
      'Preferred Group',
      hasOr
        ? 'One of these set of properties are required'
        : 'All properties are required',
      'group-header'
    )

    inputProps.push(groupHeaderModel)

    for (let index = 0; index < targetKeyDetails.length; index++) {
      const {
        targetKeyPositionAtGroup,
        condition,
        depth,
        sourceDetails,
        targetkeyType
      } = targetKeyDetails[index]

      // Characteristics details are also in tragetKeyDetails for debugging the mapping status
      // As grouped properties are property and displaying under property table where characteristics couldn't be displayed
      // Therefore, Need to skip characteristics from apprearing on UI
      if (targetkeyType === TargetKeyType.CHARACTERISTICS) {
        continue
      }

      // Root level conditions
      if (index > 0 && targetKeyPositionAtGroup === 0) {
        const model = GroupedPropertyUiModel(
          hasOr ? '-OR-' : '-AND-',
          '',
          'group-or-label',
          false,
          0
        )

        inputProps.push(model)
      }

      // Inner / Nested grouping conditions
      if (targetKeyPositionAtGroup !== 0 && condition === 'or') {
        const model = GroupedPropertyUiModel(
          '-OR-',
          '',
          'group-or-label',
          false,
          depth
        )
        inputProps.push(model)
      }

      // Actual properties
      inputProps.push({
        textIndentToRight: depth,
        ...sourceDetails,
        isGroupedByATStatus: true
      })
    }
  }

  return inputProps
}
