import { ReactNode, useEffect, useState } from 'react'
import { Loading } from '../Table/Loading'
import { useSelector } from 'react-redux'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'
import { useMatches } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { DenaliRouteHandle } from '../Sidebar/MainNavigation'
import {
  LAST_BUILDING_KEY,
  LAST_ORGANIZATION_KEY
} from '../BuildingSelector/use-selected-building'
import { RouteTokenWrapper } from './RouteTokenWrapper'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { RouteBuildingOfferingsWrapper } from './RouteBuildingOfferingsWrapper'

type RouteUrlParamWrapperProps = {
  children: ReactNode
}

export const RouteUrlParamWrapper = ({
  children
}: RouteUrlParamWrapperProps) => {
  const { isLoading, currentId, skipTokensWhileLoading } =
    useSyncUrlAndLocalStorageWithRedux()

  return (
    <div data-testid="RouteUrlParamWrapper">
      <RouteBuildingOfferingsWrapper>
        {isLoading && <Loading />}
        {!isLoading && (
        // Key forces tokens to refresh when a building changes
        // Replicates the way the old picker render the page so dependencies don't need to be changed on every page migration
        // skipTokensWhileLoading avoids a race condition with Cognito tokens, including the key ensures a rerender
          <RouteTokenWrapper key={`${currentId}-${skipTokensWhileLoading}`}>
            {children}
          </RouteTokenWrapper>
        )}
      </RouteBuildingOfferingsWrapper>
    </div>
  )
}

const useSyncUrlAndLocalStorageWithRedux = () => {
  const [currentId, setCurrentId] = useState(null)
  const selectedFromRedux = useSelector(selectBuilding)
  const matches = useMatches()
  const [, setSearchParams] = useSearchParams()
  const uiMode = useSelector(selectUiMode)

  const navigationProps = getNavigationProps(matches)
  const isUrlControlled = checkIsUrlControlled(navigationProps, uiMode)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isUrlControlled) {
      setIsLoading(false)
      setCurrentId(selectedFromRedux?.id || null)
      return
    }

    setIsLoading(true)
    if (!selectedFromRedux) return

    const { reduxOrganizationId, reduxBuildingId } = getIds(selectedFromRedux)

    setQueryParams(setSearchParams, reduxOrganizationId, reduxBuildingId)
    setLocalStorage(reduxOrganizationId, reduxBuildingId)
    setIsLoading(false)
    setCurrentId(selectedFromRedux?.id)
  }, [selectedFromRedux])

  return {
    isLoading,
    currentId,
    skipTokensWhileLoading: selectedFromRedux?.skipTokensWhileLoading
  }
}

const setQueryParams = (setSearchParams, orgId, buildingId) => {
  setSearchParams(
    (params) => {
      orgId ? params.set('organization', orgId) : params.delete('organization')

      buildingId
        ? params.set('location', buildingId)
        : params.delete('location')

      return params
    },
    { replace: true }
  )
}

const setLocalStorage = (orgId, buildingId) => {
  orgId
    ? localStorage.setItem(LAST_ORGANIZATION_KEY, orgId)
    : localStorage.removeItem(LAST_ORGANIZATION_KEY)

  buildingId
    ? localStorage.setItem(LAST_BUILDING_KEY, buildingId)
    : localStorage.removeItem(LAST_BUILDING_KEY)
}

const getIds = (selectedFromRedux) => {
  const reduxOrganizationId =
    selectedFromRedux?.type === 'organization'
      ? selectedFromRedux.id
      : selectedFromRedux.accountId

  const reduxBuildingId =
    selectedFromRedux?.type === 'location' ? selectedFromRedux.id : null

  return { reduxOrganizationId, reduxBuildingId }
}

const getNavigationProps = (matches) => {
  if (!matches) return
  const config = matches?.[matches.length - 1]?.handle as DenaliRouteHandle
  return config?.page?.navigationProps
}

const checkIsUrlControlled = (navigationProps, uiMode: UiMode): boolean => {
  // Old UI always lets the old picker control the URL
  if (uiMode !== UiMode.denali) return false

  // If we're using the new picker, move URL responsibility here instead of the picker
  if (navigationProps?.denaliBuildingPicker === true) return true

  // let the page render immediately
  return false
}
