import { useMemo } from 'react'
import RangeSelector from 'src/components/legacy/components/range-selector/range-selector'
import translate, { TranslateComponent } from 'src/common/translations'
import {
  ControlContainer,
  SubTitle,
  LevelIdenfifier,
  ControlsSection,
  FlexContainer,
  SettingsAccordionContainer,
  TextInput,
  RangeInfoText,
  ValidationErrorContainer
} from './style'
import { parameterScope } from './AutomatedTestSettingsList'

const LIMIT_NAMES = {
  upperYellowLimit: 'upperYellowLimit',
  upperRedLimit: 'upperRedLimit',
  lowerYellowLimit: 'lowerYellowLimit',
  lowerRedLimit: 'lowerRedLimit',
  redLimit: 'redLimit',
  yellowLimit: 'yellowLimit'
}

const RANGE_SELECTOR_NAMES = {
  leftCriticalValue: LIMIT_NAMES?.lowerRedLimit,
  leftCautionaryValue: LIMIT_NAMES?.lowerYellowLimit,
  rightCautionaryValue: LIMIT_NAMES?.upperYellowLimit,
  rightCriticalValue: LIMIT_NAMES?.upperRedLimit
}

const RangeSelectorWrapper = ({
  detectionRate = false,
  isSuppressed,
  onChangeLimts,
  initialValues,
  modifiedValuesObject,
  subType,
  boundaryValues
}) => {
  let upperRedLimitName = LIMIT_NAMES.upperRedLimit
  let upperYellowLimitName = LIMIT_NAMES.upperYellowLimit
  let lowerYellowLimitName = LIMIT_NAMES.lowerYellowLimit
  let lowerRedLimitName = LIMIT_NAMES.lowerRedLimit

  if (detectionRate) {
    upperRedLimitName = LIMIT_NAMES.redLimit
    upperYellowLimitName = LIMIT_NAMES.yellowLimit
  }

  if (subType) {
    lowerYellowLimitName = `${subType}-${LIMIT_NAMES?.lowerYellowLimit}`
    lowerRedLimitName = `${subType}-${LIMIT_NAMES?.lowerRedLimit}`
    upperYellowLimitName = `${subType}-${LIMIT_NAMES?.upperYellowLimit}`
    upperRedLimitName = `${subType}-${LIMIT_NAMES?.upperRedLimit}`
  }

  return (
    <RangeSelector
      style={{ maxWidth: '400px', margin: 10 }}
      isDisabled={isSuppressed}
      precision={1}
      sensitivity={5}
      minValue={boundaryValues?.lower === undefined ? 0 : boundaryValues?.lower}
      maxValue={boundaryValues?.upper === undefined ? 100 : boundaryValues?.upper}
      leftCriticalValue={
        initialValues[lowerRedLimitName] !== undefined && !detectionRate
          ? Number(
              modifiedValuesObject[lowerRedLimitName] !== undefined
                ? modifiedValuesObject[lowerRedLimitName]
                : initialValues[lowerRedLimitName]
            )
          : undefined
      }
      leftCautionaryValue={
        initialValues[lowerYellowLimitName] !== undefined && !detectionRate
          ? Number(
              modifiedValuesObject[lowerYellowLimitName] !== undefined
                ? modifiedValuesObject[lowerYellowLimitName]
                : initialValues[lowerYellowLimitName]
            )
          : undefined
      }
      rightCautionaryValue={
        initialValues[upperYellowLimitName] !== undefined
          ? Number(
              modifiedValuesObject[upperYellowLimitName] !== undefined
                ? modifiedValuesObject[upperYellowLimitName]
                : initialValues[upperYellowLimitName]
            )
          : undefined
      }
      rightCriticalValue={
        initialValues[upperRedLimitName] !== undefined
          ? Number(
              modifiedValuesObject[upperRedLimitName] !== undefined
                ? modifiedValuesObject[upperRedLimitName]
                : initialValues[upperRedLimitName]
            )
          : undefined
      }
      onChange={onChangeLimts}
    />
  )
}

const LimitInputContainer = ({
  parameterName,
  onInputChange,
  onInputElementFocus,
  limitValues,
  isModified,
  value,
  inputRef,
  error
}) => {
  const limitDetails = limitValues?.find((x) => x?.name === parameterName)

  const hasError = error ? true : false

  return (
    <div style={{ marginRight: 20 }}>
      <SubTitle style={{ marginButtom: 5 }}>
        <TranslateComponent>{limitDetails?.displayName}</TranslateComponent>
      </SubTitle>
      <FlexContainer>
        <TextInput
          hasError={hasError}
          key={parameterName}
          id={parameterName}
          onChange={onInputChange}
          style={{ background: `${isModified ? '#ffff9a' : 'white'}` }}
          type="text"
          onFocus={() => onInputElementFocus(parameterName)}
          ref={(ref) => (inputRef[parameterName] = ref)}
          value={value}
        ></TextInput>
        <SubTitle className="units"><TranslateComponent>{limitDetails?.targetUOM}</TranslateComponent></SubTitle>
        <LevelIdenfifier>
          <TranslateComponent>{parameterScope[limitDetails?.stType]}</TranslateComponent>
        </LevelIdenfifier>
        {limitDetails?.tooltipText ? (
          <button
            className={
              'marker-icon icon-infocircle tooltip-container tooltip-container--multiline'
            }
          >
            <div className="tooltip right"><TranslateComponent>{limitDetails?.tooltipText}</TranslateComponent></div>
          </button>
        ) : null}
      </FlexContainer>
    </div>
  )
}

export const DetectionRateControls = ({
  initialValues,
  detectionRates,
  modifiedValuesObject,
  onAnalyticParamChange,
  inputRef,
  onInputElementFocus,
  isSuppressed = false,
  setValidationError,
  validationError
}) => {
  const yellowLimitValue =
    modifiedValuesObject[LIMIT_NAMES?.yellowLimit] ||
    initialValues[LIMIT_NAMES?.yellowLimit]

  const redLimitValue =
    modifiedValuesObject[LIMIT_NAMES?.redLimit] ||
    initialValues[LIMIT_NAMES?.redLimit]

  const boundaryValues = {
    lower: 0,
    upper: 100
  }

  const validateLimits = (parameterName, value) => {
    try {
      const errors = validationError
      delete errors[parameterName]

      // value is not a valid number, set error and return
      if (isNaN(value)) {
        errors[parameterName] = 'Please enter a valid number'
        setValidationError(errors)
        return
      }

      if (parameterName === LIMIT_NAMES?.redLimit) {
        if (value > boundaryValues?.upper) {
          errors[parameterName] = 'Limit can not be greater than upper boundary'
        }

        if (value < yellowLimitValue) {
          errors[parameterName] = 'Limit can not be less than cautionary limit'
        }
      } else {
        if (value < boundaryValues?.lower) {
          errors[parameterName] = 'Limit can not be less than lower boundary'
        }

        if (value > redLimitValue) {
          errors[parameterName] = 'Limit can not be greater than critical limit'
        }
      }

      setValidationError(errors)
    } catch (e) {}
  }

  const updateParameterValue = (parameterName, value) => {
    onAnalyticParamChange(parameterName, value)
    validateLimits(parameterName, value)
  }

  // Direct change from text box
  const onInputChange = (e) => {
    const value = e?.target?.value
    const parameterName = e?.target?.id
    // Modified Regex to allow decimal point, allowing user to enter flaoting values if selected UOM is SI
    const onlyNumber = value && value?.match(/[0-9.]+/)?.[0]
    updateParameterValue(parameterName, onlyNumber)
  }

  // Comes from Range selection onchange
  const onChangeThresholdLimits = (values) => {
    Object.keys(values)?.map((parameterName) => {
      const modifiedValue = values[parameterName]
      if (modifiedValue) {
        const rangeLimitName =
          RANGE_SELECTOR_NAMES[parameterName] ===
          RANGE_SELECTOR_NAMES?.rightCautionaryValue
            ? LIMIT_NAMES.yellowLimit
            : LIMIT_NAMES.redLimit
        modifiedValuesObject[rangeLimitName] !== modifiedValue &&
          setTimeout(() => {
            updateParameterValue(rangeLimitName, modifiedValue)
          }, 70)
      }
    })
  }

  return detectionRates?.length === 0 ? null : (
    <ControlsSection isSuppressed={isSuppressed}>
      <SubTitle noCapitals={true}><TranslateComponent>Set your Detection Rate Threshold</TranslateComponent></SubTitle>
      <ControlContainer>
        <div className="range-selector-wrapper">
          <RangeSelectorWrapper
            key="range-selector-detection"
            detectionRate={true}
            isSuppressed={isSuppressed}
            onChangeLimts={onChangeThresholdLimits}
            initialValues={initialValues}
            modifiedValuesObject={modifiedValuesObject}
            boundaryValues={boundaryValues}
          />
        </div>
        <FlexContainer>
          <div>
            <LimitInputContainer
              onInputChange={onInputChange}
              onInputElementFocus={onInputElementFocus}
              limitValues={detectionRates}
              isModified={
                modifiedValuesObject[LIMIT_NAMES?.yellowLimit] ? true : false
              }
              value={
                modifiedValuesObject[LIMIT_NAMES?.yellowLimit] ||
                initialValues[LIMIT_NAMES?.yellowLimit]
              }
              inputRef={inputRef}
              parameterName={LIMIT_NAMES?.yellowLimit}
            />
            <ValidationErrorContainer>
              {validationError[LIMIT_NAMES?.yellowLimit] && (
                <span className="error">
                  <TranslateComponent>{validationError[LIMIT_NAMES?.yellowLimit]}</TranslateComponent>
                </span>
              )}
            </ValidationErrorContainer>
          </div>

          <div>
            <LimitInputContainer
              onInputChange={onInputChange}
              onInputElementFocus={onInputElementFocus}
              limitValues={detectionRates}
              value={
                modifiedValuesObject[LIMIT_NAMES?.redLimit] ||
                initialValues[LIMIT_NAMES?.redLimit]
              }
              isModified={
                modifiedValuesObject[LIMIT_NAMES?.redLimit] ? true : false
              }
              inputRef={inputRef}
              parameterName={LIMIT_NAMES?.redLimit}
            />
            <ValidationErrorContainer>
              {validationError[LIMIT_NAMES?.redLimit] && (
                <span className="error">
                  <TranslateComponent>{validationError[LIMIT_NAMES?.redLimit]}</TranslateComponent>
                </span>
              )}
            </ValidationErrorContainer>
          </div>
        </FlexContainer>
      </ControlContainer>
    </ControlsSection>
  )
}

export const OscillationLimits = ({
  initialValues,
  OscillationLimits,
  modifiedValuesObject,
  onAnalyticParamChange,
  inputRef,
  onInputElementFocus,
  setValidationError,
  validationError,
  isSuppressed = false
}) => {
  const upperRedLimitName = 'Oscillation-upperRedLimit'
  const upperYellowLimitName = 'Oscillation-upperYellowLimit'

  const boundaryValues = useMemo(() => {
    const boundary = OscillationLimits?.find(
      (x) =>
        x?.isDisplay === 1 &&
        x?.type === 'Limits' &&
        (x?.name === upperRedLimitName || x?.name === upperYellowLimitName)
    )?.boundary
    return boundary
  }, [OscillationLimits])

  const validateLimits = (parameterName, value) => {
    try {
      const errors = validationError
      delete errors[parameterName]

      // value is not a valid number, set error and return
      if (isNaN(value)) {
        errors[parameterName] = 'Please enter a valid number'
        setValidationError(errors)
        return
      }

      if (value > boundaryValues?.upper) {
        errors[parameterName] = 'Limit can not be greater than upper boundary'
      }

      if (value < boundaryValues?.lower) {
        errors[parameterName] = 'Limit can not be less than lower boundary'
      }
      setValidationError(errors)
    } catch (e) {}
  }

  const updateParameterValue = (parameterName, value) => {
    onAnalyticParamChange(parameterName, value)
    validateLimits(parameterName, value)
  }

  // Direct change from text box
  const onInputChange = (e) => {
    const value = e?.target?.value
    const parameterName = e?.target?.id
    const onlyNumber = value && value?.match(/\d+/)?.[0]
    updateParameterValue(parameterName, onlyNumber)
  }

  return (
    <ControlsSection isSuppressed={isSuppressed}>
      <SubTitle noCapitals={true}>
        <TranslateComponent>Set your Oscillation limit preferences</TranslateComponent>
      </SubTitle>
      <ControlContainer>
        {initialValues[upperYellowLimitName] !== undefined &&
          initialValues[upperYellowLimitName] !== undefined && (
            <FlexContainer>
              <div>
                <LimitInputContainer
                  onInputChange={onInputChange}
                  onInputElementFocus={onInputElementFocus}
                  limitValues={OscillationLimits}
                  isModified={
                    modifiedValuesObject[upperYellowLimitName] ? true : false
                  }
                  value={
                    modifiedValuesObject[upperYellowLimitName] === undefined
                      ? initialValues[upperYellowLimitName]
                      : modifiedValuesObject[upperYellowLimitName]
                  }
                  inputRef={inputRef}
                  parameterName={upperYellowLimitName}
                />
                <ValidationErrorContainer>
                  {validationError[upperYellowLimitName] && (
                    <span className="error">
                      <TranslateComponent>{validationError[upperYellowLimitName]}</TranslateComponent>
                    </span>
                  )}
                </ValidationErrorContainer>
              </div>
              <div>
                <LimitInputContainer
                  onInputChange={onInputChange}
                  onInputElementFocus={onInputElementFocus}
                  limitValues={OscillationLimits}
                  value={
                    modifiedValuesObject[upperRedLimitName] === undefined
                      ? initialValues[upperRedLimitName]
                      : modifiedValuesObject[upperRedLimitName]
                  }
                  isModified={
                    modifiedValuesObject[upperRedLimitName] ? true : false
                  }
                  inputRef={inputRef}
                  parameterName={upperRedLimitName}
                />
                <ValidationErrorContainer>
                  {validationError[upperRedLimitName] && (
                    <span className="error">
                      <TranslateComponent>{validationError[upperRedLimitName]}</TranslateComponent>
                    </span>
                  )}
                </ValidationErrorContainer>
              </div>
            </FlexContainer>
          )}
      </ControlContainer>
    </ControlsSection>
  )
}

export const LimitControls = ({
  initialValues,
  limitValues,
  modifiedValuesObject,
  onAnalyticParamChange,
  inputRef,
  onInputElementFocus,
  setValidationError,
  validationError,
  subType,
  isSuppressed = false
}) => {
  let lowerYellowLimitName = LIMIT_NAMES?.lowerYellowLimit
  let lowerRedLimitName = LIMIT_NAMES?.lowerRedLimit
  let upperYellowLimitname = LIMIT_NAMES?.upperYellowLimit
  let upperRedLimitName = LIMIT_NAMES?.upperRedLimit

  if (subType) {
    lowerYellowLimitName = `${subType}-${LIMIT_NAMES?.lowerYellowLimit}`
    lowerRedLimitName = `${subType}-${LIMIT_NAMES?.lowerRedLimit}`
    upperYellowLimitname = `${subType}-${LIMIT_NAMES?.upperYellowLimit}`
    upperRedLimitName = `${subType}-${LIMIT_NAMES?.upperRedLimit}`
  }

  const limitPrefixName = useMemo(() => {
    if (subType) {
      if (subType.includes('Critical')) {
        return 'Critical: '
      } else {
        return 'Sustain: '
      }
    }
    return ''
  })

  const boundaryValues = useMemo(() => {
    let boundary = limitValues?.find(
      (x) =>
        x?.isDisplay === 1 &&
        x?.type === 'Limits' &&
        (x?.name === lowerRedLimitName || x?.name === upperRedLimitName)
    )?.boundary
    if (subType) {
      boundary = limitValues?.find(
        (x) =>
          x?.isDisplay === 1 &&
          x?.type === 'Limits' &&
          (x?.name === lowerRedLimitName || x?.name === upperRedLimitName) &&
          x?.subType === subType
      )?.boundary
    }
    return boundary
  }, [limitValues])

  const validateUpperAndLowerLimits = (parameterName, value) => {
    // checks the limits, if it goes abouve or below the boundary values
    // modify the value to bounday value
    // Red can't be more lower than upper and less than yellow
    // Yellow can't be more than red and less than lower
    const errors = validationError
    delete errors[parameterName]

    // value is not a valid number, set error and return
    if (isNaN(value)) {
      errors[parameterName] = 'Please enter a valid number'
      setValidationError(errors)
      return
    }

    const isLowerLimits = parameterName?.includes('lower')

    const currentLowerRedLimitValue =
      modifiedValuesObject[lowerRedLimitName] !== undefined
        ? modifiedValuesObject[lowerRedLimitName]
        : initialValues[lowerRedLimitName]
    const currentLowerYellowLimitValue =
      modifiedValuesObject[lowerYellowLimitName] !== undefined
        ? modifiedValuesObject[lowerYellowLimitName]
        : initialValues[lowerYellowLimitName]
    const currentUpperRedLimitValue =
      modifiedValuesObject[upperRedLimitName] !== undefined
        ? modifiedValuesObject[upperRedLimitName]
        : initialValues[upperRedLimitName]
    const currentUpperYellowLimitValue =
      modifiedValuesObject[upperYellowLimitname] !== undefined
        ? modifiedValuesObject[upperYellowLimitname]
        : initialValues[upperYellowLimitname]

    if (parameterName?.includes('RedLimit')) {
      if (isLowerLimits) {
        if (value < boundaryValues?.lower) {
          errors[parameterName] = 'Limit can not be less than lower boundary'
        }
        if (value > currentLowerYellowLimitValue) {
          errors[parameterName] =
            'Limit can not be greater than lower cautionary limit'
        }
      } else {
        if (value < currentUpperYellowLimitValue) {
          errors[parameterName] =
            'Limit can not be less than upper cautionary limit'
        }
        if (value > boundaryValues?.upper) {
          errors[parameterName] = 'Limit can not be greater than upper boundary'
        }
      }
    } else {
      if (isLowerLimits) {
        if (value < currentLowerRedLimitValue) {
          errors[parameterName] =
            'Limit can not be less than lower critical limit'
        }
        const upperMaxLimit =
          currentUpperYellowLimitValue !== undefined
            ? currentUpperYellowLimitValue
            : boundaryValues?.upper
        if (value > upperMaxLimit) {
          errors[parameterName] = `Limit can not be greater than ${
            currentUpperYellowLimitValue !== undefined
              ? 'upper cautionary limit'
              : 'upper boundary'
          }`
        }
      } else {
        const lowerMinLimit =
          currentLowerYellowLimitValue !== undefined
            ? currentLowerYellowLimitValue
            : boundaryValues?.lower
        if (value < lowerMinLimit) {
          errors[parameterName] = `Limit can not be less than ${
            currentLowerYellowLimitValue !== undefined
              ? 'lower cautionary limit'
              : 'lower boundary'
          }`
        }
        if (value > currentUpperRedLimitValue) {
          errors[parameterName] =
            'Limit can not be greater than upper critical limit'
        }
      }
    }
    setValidationError(errors)
  }

  const updateParameterValue = (parameterName, value) => {
    onAnalyticParamChange(parameterName, value)
    validateUpperAndLowerLimits(parameterName, value)
  }

  // Direct change from text box
  const onInputChange = (e) => {
    const value = e?.target?.value
    const parameterName = e?.target?.id
    const onlyNumber = value?.replace(/[^-0-9.]/g, '')
    updateParameterValue(parameterName, onlyNumber)
  }

  // Comes from Range selection onchange
  const onChangeThresholdLimits = (values) => {
    Object.keys(values)?.map((parameterName) => {
      const modifiedValue = values[parameterName]
      const rangeLimitName = RANGE_SELECTOR_NAMES[parameterName]
      if (modifiedValue) {
        modifiedValuesObject[rangeLimitName] !== modifiedValue &&
          setTimeout(() => {
            updateParameterValue(rangeLimitName, modifiedValue)
          }, 70)
      }
    })
  }

  return (
    limitValues?.length > 1 && (
      <ControlsSection isSuppressed={isSuppressed}>
        <SubTitle noCapitals={true}>
          {limitPrefixName} <TranslateComponent>Set your critical and cautionary limit preferences</TranslateComponent>
        </SubTitle>
        <ControlContainer>
          <div className="range-selector-wrapper">
            <RangeSelectorWrapper
              key="range-selector"
              isSuppressed={isSuppressed}
              onChangeLimts={onChangeThresholdLimits}
              initialValues={initialValues}
              subType={subType}
              modifiedValuesObject={modifiedValuesObject}
              boundaryValues={boundaryValues}
            />
          </div>
          {initialValues[upperRedLimitName] !== undefined &&
            initialValues[upperYellowLimitname] !== undefined && (
              <>
                <FlexContainer>
                  <div>
                    <LimitInputContainer
                      onInputChange={onInputChange}
                      onInputElementFocus={onInputElementFocus}
                      limitValues={limitValues}
                      isModified={
                        modifiedValuesObject[upperYellowLimitname]
                          ? true
                          : false
                      }
                      value={
                        modifiedValuesObject[upperYellowLimitname] === undefined
                          ? initialValues[upperYellowLimitname]
                          : modifiedValuesObject[upperYellowLimitname]
                      }
                      inputRef={inputRef}
                      parameterName={upperYellowLimitname}
                      error={validationError[upperYellowLimitname]}
                    />
                    <ValidationErrorContainer>
                      {validationError[upperYellowLimitname] && (
                        <span className="error">
                          <TranslateComponent>{validationError[upperYellowLimitname]}</TranslateComponent>
                        </span>
                      )}
                    </ValidationErrorContainer>
                  </div>
                  <div>
                    <LimitInputContainer
                      onInputChange={onInputChange}
                      onInputElementFocus={onInputElementFocus}
                      limitValues={limitValues}
                      value={
                        modifiedValuesObject[upperRedLimitName] === undefined
                          ? initialValues[upperRedLimitName]
                          : modifiedValuesObject[upperRedLimitName]
                      }
                      isModified={
                        modifiedValuesObject[upperRedLimitName] ? true : false
                      }
                      inputRef={inputRef}
                      parameterName={upperRedLimitName}
                      error={validationError[upperRedLimitName]}
                    />
                    <ValidationErrorContainer>
                      {validationError[upperRedLimitName] && (
                        <span className="error">
                          <TranslateComponent>{validationError[upperRedLimitName]}</TranslateComponent>
                        </span>
                      )}
                    </ValidationErrorContainer>
                  </div>
                </FlexContainer>
              </>
            )}

          {initialValues[lowerRedLimitName] !== undefined &&
            initialValues[lowerYellowLimitName] !== undefined && (
              <FlexContainer>
                <div>
                  <LimitInputContainer
                    onInputChange={onInputChange}
                    onInputElementFocus={onInputElementFocus}
                    limitValues={limitValues}
                    value={
                      modifiedValuesObject[lowerYellowLimitName] === undefined
                        ? initialValues[lowerYellowLimitName]
                        : modifiedValuesObject[lowerYellowLimitName]
                    }
                    isModified={
                      modifiedValuesObject[lowerYellowLimitName] ? true : false
                    }
                    inputRef={inputRef}
                    parameterName={lowerYellowLimitName}
                    error={validationError[lowerYellowLimitName]}
                  />
                  <ValidationErrorContainer>
                    {validationError[lowerYellowLimitName] && (
                      <span className="error">
                        <TranslateComponent>{validationError[lowerYellowLimitName]}</TranslateComponent>
                      </span>
                    )}
                  </ValidationErrorContainer>
                </div>
                <div>
                  <LimitInputContainer
                    onInputChange={onInputChange}
                    onInputElementFocus={onInputElementFocus}
                    limitValues={limitValues}
                    value={
                      modifiedValuesObject[lowerRedLimitName] === undefined
                        ? initialValues[lowerRedLimitName]
                        : modifiedValuesObject[lowerRedLimitName]
                    }
                    isModified={
                      modifiedValuesObject[lowerRedLimitName] ? true : false
                    }
                    inputRef={inputRef}
                    error={validationError[lowerRedLimitName]}
                    parameterName={lowerRedLimitName}
                  />
                  <ValidationErrorContainer>
                    {validationError[lowerRedLimitName] && (
                      <span className="error">
                        <TranslateComponent>{validationError[lowerRedLimitName]}</TranslateComponent>
                      </span>
                    )}
                  </ValidationErrorContainer>
                </div>
              </FlexContainer>
            )}
        </ControlContainer>
      </ControlsSection>
    )
  )
}

export const SettingsAccordion = ({
  name,
  isOpen,
  disabled,
  toggleOpen,
  children
}) => {
  return (
    <SettingsAccordionContainer key="settings-accordion" disabled={disabled}>
      <FlexContainer onClick={toggleOpen}>
        <span className={`${isOpen ? 'open' : 'closed'} icon icon-expand`} />
        <SubTitle><TranslateComponent>{name}</TranslateComponent></SubTitle>
        <span className="icon icon-infocircle" />
      </FlexContainer>
      <hr></hr>
      {isOpen && (
        <div style={{ width: '100%' }}>
          {name !== 'Comments' && (
            <SubTitle><TranslateComponent>Configure parameter values</TranslateComponent></SubTitle>
          )}
          <div
            className={`settings-items ${
              name === 'Comments' ? 'settings-items-comments' : ''
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </SettingsAccordionContainer>
  )
}

export const SettingsParameterEdit = ({
  displayName,
  parameterName,
  disabled = false,
  identifierLevel,
  targetUnit,
  onAnalyticParamChange,
  isModified,
  value,
  range,
  inputRef,
  setValidationError,
  validationError,
  onInputElementFocus,
  tooltip = '',
  tooltipText = ''
}) => {
  const inputValue = useMemo(() => {
    if (targetUnit === 'mins') {
      return {
        hours: Math.trunc(value / 60),
        minutes: Math.trunc(value % 60)
      }
    } else {
      return value
    }
  }, [value])

  const hasError = validationError[parameterName]

  const validateLimits = (parameterName, value) => {
    try {
      const errors = validationError
      delete errors[parameterName]

      // value is not a valid number, set error and return
      if (isNaN(value)) {
        errors[parameterName] = 'Please enter a valid number'
        setValidationError(errors)
        return
      }

      if (value > range?.upper) {
        errors[parameterName] = 'Limit can not be greater than upper range'
      }

      if (value < range?.lower) {
        errors[parameterName] = 'Limit can not be less than lower range'
      }
      setValidationError(errors)
    } catch (e) {}
  }

  const onInputChange = (e) => {
    let value = e?.target?.value
    const id = e?.target?.id?.split('-')?.[1] || ''
    const onlyNumber = value?.replace(/[^-0-9.]/g, '')

    if (targetUnit === 'mins') {
      if (id === 'hour') {
        const minElem = document?.getElementById(`${parameterName}-minutes`)
        const minValue = minElem?.value || 0
        const totalMin = onlyNumber * 60 + Number(minValue)
        const maxTotalMin = totalMin > 1439 ? 1439 : totalMin
        onAnalyticParamChange(parameterName, maxTotalMin)
      } else {
        const hourElem = document?.getElementById(`${parameterName}-hour`)
        const hourValue = hourElem?.value || 0
        const totalMin = hourValue * 60 + Number(value)
        const maxTotalMin = totalMin > 1439 ? 1439 : totalMin
        onAnalyticParamChange(parameterName, maxTotalMin)
      }
    } else {
      onAnalyticParamChange(parameterName, onlyNumber)

      range && validateLimits(parameterName, onlyNumber)
    }
  }

  return (
    <div style={{ marginTop: 10 }}>
      <FlexContainer>
        <SubTitle><TranslateComponent>{displayName}</TranslateComponent></SubTitle>
        {tooltip ? (
          <button
            style={{ marginLeft: -3 }}
            className={
              'marker-icon icon-infocircle tooltip-container multiline'
            }
          >
            <div className="tooltip right"><TranslateComponent>{tooltip}</TranslateComponent></div>
          </button>
        ) : null}
      </FlexContainer>

      <div>
        <ControlContainer isModified={isModified} controlType="parameterEdit">
          {targetUnit === 'mins' ? (
            <div>
              <FlexContainer>
                <FlexContainer key={parameterName}>
                  <TextInput
                    disabled={disabled}
                    key={`${parameterName}-hour`}
                    id={`${parameterName}-hour`}
                    onChange={onInputChange}
                    type="text"
                    onFocus={() => onInputElementFocus(`${parameterName}-hour`)}
                    ref={(ref) => (inputRef[`${parameterName}-hour`] = ref)}
                    value={inputValue?.hours}
                    hasError={hasError}
                  ></TextInput>
                  <SubTitle><TranslateComponent>Hours</TranslateComponent></SubTitle>
                </FlexContainer>
                <FlexContainer key={parameterName}>
                  <TextInput
                    disabled={disabled}
                    ref={(ref) => (inputRef[`${parameterName}-minutes`] = ref)}
                    key={`${parameterName}-minutes`}
                    id={`${parameterName}-minutes`}
                    type="text"
                    onFocus={() =>
                      onInputElementFocus(`${parameterName}-minutes`)
                    }
                    onChange={onInputChange}
                    value={inputValue?.minutes}
                    hasError={hasError}
                  ></TextInput>
                  <SubTitle><TranslateComponent>Minutes</TranslateComponent></SubTitle>
                </FlexContainer>
              </FlexContainer>
              <ValidationErrorContainer>
                {validationError[parameterName] && (
                  <span className="error">
                    <TranslateComponent>{validationError[parameterName]}</TranslateComponent>
                  </span>
                )}
              </ValidationErrorContainer>
            </div>
          ) : (
            <>
              <div>
                <FlexContainer key={parameterName}>
                  <TextInput
                    disabled={disabled}
                    ref={(ref) => (inputRef[parameterName] = ref)}
                    key={parameterName}
                    type="text"
                    value={inputValue}
                    onFocus={() => onInputElementFocus(parameterName)}
                    onChange={onInputChange}
                    hasError={hasError}
                  ></TextInput>
                  <SubTitle><TranslateComponent>{targetUnit}</TranslateComponent></SubTitle>
                </FlexContainer>
                <ValidationErrorContainer>
                  {validationError[parameterName] && (
                    <span className="error">
                     <TranslateComponent>{validationError[parameterName]}</TranslateComponent>
                    </span>
                  )}
                </ValidationErrorContainer>
              </div>
            </>
          )}

          <div>
            <LevelIdenfifier><TranslateComponent>{identifierLevel}</TranslateComponent></LevelIdenfifier>
            {tooltipText ? (
              <button
                className={'marker-icon icon-infocircle tooltip-container'}
              >
                <div className="tooltip right"><TranslateComponent>{tooltipText}</TranslateComponent></div>
              </button>
            ) : null}
          </div>
        </ControlContainer>
        {range && (
          <RangeInfoText className="range-info"><TranslateComponent>Range: </TranslateComponent>{range?.lower} - {range?.upper}</RangeInfoText>
        )}
      </div>
    </div>
  )
}

export const CommentsEdit = ({
  setUserComments,
  userComments,
  componentName,
  inputRef,
  onInputElementFocus
}) => {
  const onChange = (event) => {
    setUserComments(event?.target?.value || '')
  }

  return (
    <textarea
      key={componentName}
      value={userComments}
      onChange={onChange}
      style={{ width: '100%' }}
      ref={(ref) => (inputRef[componentName] = ref)}
      onFocus={(e) => {
        onInputElementFocus(componentName)
        // Focus after the current text
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length
        )
      }}
      className="comments"
    />
  )
}
