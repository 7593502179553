import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUserInfo, selectUserAccess } from 'src/redux/slicers/appData'
import moment from 'moment/moment'
import { Trans } from 'react-i18next'
import ClientSideTable from 'src/components/Table/clientSideTable'
import { pages } from 'src/pages/pages.js'
import {
  UPDATE_OFFERING,
  GET_BUILDINGDETAILS_BY_ID_func,
  LIST_ATTACHMENTS_BY_CONTRACT
} from './queries'
import { UPDATE_CONTRACT } from './editActive/queries'
import { useMutation } from 'src/hooks/APIHooks'
import { API } from 'aws-amplify'
import {
  isValid,
  DATE_FORMAT,
  utcTimeEndOfTheDay,
  utcTimeStartOfTheDay,
  utcTimestampToDateFormat
} from 'src/components/legacy/common/time-helpers'

import { BPEPApproveDialog } from './offering-dialogs/common'
import { OfferingDialog } from './offering-dialogs/dialog'
import {
  getStatusOptionList,
  getTypeOptionList,
  STATUS_TO_DISPLAY_MAP,
  STATUS_TO_SAVE,
  STATUS,
  getStatusByValue
} from 'src/pages/contracts-and-offerings/common/contracts-and-offerings'
import Toolbar from 'src/components/legacy/components/toolbar/toolbar'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useQuery } from 'src/hooks/APIHooks'
import { accessControlFunc } from 'src/components/accessControl'

const daysToExpiration = ({ endDate }) => {
  let numberOfDaysToExpire = Infinity
  if (endDate) {
    // offering requests coming from location summary API only
    numberOfDaysToExpire = moment(endDate).diff(moment().startOf('day'), 'days')
    return numberOfDaysToExpire
  }
}

const getContractStatus = (offering) => {
  const numberOfDaysToExpire = daysToExpiration(offering)
  if (numberOfDaysToExpire > 30) {
    if (offering.status) {
      return offering.status === STATUS_TO_SAVE.APPROVED
        ? STATUS.get('Active')
        : STATUS.get(offering.status)
    } else {
      return STATUS.get('Active')
    }
  } else if (numberOfDaysToExpire <= 30 && numberOfDaysToExpire > 0) {
    return STATUS.get('Expiring')
  }
  return STATUS.get('Expired')
}

export const Offerings = (props) => {
  const [offerings, setOfferings] = useState(props.offerings)
  const user = useSelector(selectUserInfo)
  const userAccess = useSelector(selectUserAccess)
  const [state, setState] = useState({
    requestDialogType: null,
    prefilledValues: null,
    dialogSaveAction: () => {},
    dialogDeclineAction: () => {},
    saveChangesInProgress: false,
    notification: null,
    confirm: null,
    error: null,
    offeringRequestErrorPresent: false,
    dialogFromUrl: false,
    locationinfo: null
  })

  const [t] = useTranslation()
  const navigate = useNavigate()

  const {
	refetch: refetchAttachmentsByContract,
  } = useQuery({
	query: LIST_ATTACHMENTS_BY_CONTRACT,
	disableInitialLoad: true,
	errorPolicy: 'global',
	dataPath: 'data.listAttachmentsByContract.items',
  })


  useEffect(() => {
    props?.offerings &&
      setOfferings(
        props.offerings.map((offering) => ({
          ...offering,
          originalStatus: offering?.status,
          status: getContractStatus(offering)
        }))
      )
  }, [props.offerings])

  const { onSubmit: updateContract } = useMutation({
    query: UPDATE_CONTRACT
  })

  const { onSubmit: updateOffering } = useMutation({
    query: UPDATE_OFFERING
  })

  const handleOfferingDelete = async (offerings) => {
    const data = Promise.all(
      offerings?.map(async (item) => updateOffering({ input: { id: item,status:STATUS_TO_SAVE.DELETED } }))
    )
    return data
  }
  const handleContractDelete = async (contractId) => {
    const data = await updateContract({ input: { id: contractId,status:STATUS_TO_SAVE.DELETED } })
    return data
  }

  const approvalInput = ({ values, status = STATUS_TO_SAVE.APPROVED }) => {
    return {
      id: values.contractId,
      deviceCount: parseInt(values.numberOfDevices),
      buildingCount: parseInt(values.buildingCount),
      serviceNumber: values.serviceNumber,
      status,
      activateForLaterDate: values.activateForLaterDate,
      analyticsStartDate: utcTimeStartOfTheDay(values.analyticsStartDate),
      startDate: utcTimeStartOfTheDay(values.startDate),
      endDate:utcTimeEndOfTheDay(values.endDate),
      billingSalesOfficeCode: values.billingSalesOfficeCode,
      coachingServicesValue: values.coachingServicesValue,
      primaryBuilding: values.primaryBuilding,
      totalBaselineReportCount: values.totalBaselineReportCount,
      declinedReason: values.declinedReason,
      additionalContractNumbers: JSON.stringify({
        mechanicalServiceAgreementNumber:
          values.mechanicalServiceAgreementNumber,
        pactContractNumber: values.pactContractNumber
      })
    }
  }

  const nameHeadings = [
    {
      name: 'serviceNumber',
      title: 'Contract',
      key: 'serviceNumber',
      maxWidth: '75px'
    },
    {
      name: 'buildingName',
      title: 'Building Name',
      key: 'buildingName',
      maxWidth: '75px',
      customComponent : row =>   row.buildings?.map((b,index) => 
       b ?  <a key ={b.id} onClick = {e  =>   navigate(
              `/building-setup/${b.id}`
            )}>{`${b.name}${index !== row.buildings?.length -1 ? "," : ""} `}</a>
            : null)
    },
    {
      name: 'accountName',
      title: 'Organization Name',
      key: 'accountName',
      maxWidth: '100px'
    },
    {
      name: 'name',
      title: 'Offering',
      key: 'name',
      sortField: false
      // maxWidth: '100px'
    },
    {
      name: 'startDate',
      title: 'Start Date',
      key: 'startDate',
      sortField: false,
      customComponent: (row) => utcTimestampToDateFormat(row.startDate) || ''
      // maxWidth: '100px'
    },
    {
      name: 'endDate',
      title: 'End Date',
      key: 'endDate',
      sortField: false,
      customComponent: (row) => utcTimestampToDateFormat(row.endDate) || ''
      // maxWidth: '100px'
    },
    {
      name: 'link',
      title: 'Link',
      key: 'link',
      sortField: false
      // maxWidth: '100px'
    },
    {
      name: 'status',
      title: 'Status',
      key: 'status',
      sortField: false,
      customComponent: (row) => {
        if (row) {
          if (row.status === STATUS.get('Expiring')) {
            return (
              <div className="offering-status">
                <div className="error-message">
                  <span className="icon icon-error" />
                  <span className="error-text">
                    {STATUS_TO_DISPLAY_MAP.get(row.status)}
                  </span>
                </div>
              </div>
            )
          }
          return STATUS_TO_DISPLAY_MAP.get(row.status)
        }
      }
      // maxWidth: '100px'
    }
  ]
  const action = (searchRows, filter, filtersToApply) => {
    const _searchRows = searchRows?.filter((r) => {
      return filtersToApply.every((f) => {
        if (!(r[f.key] || r[f.name])) {
          return false
        }
        if (f.name === 'status') {
          return r[f.key]
            ? r[f.key] === getStatusByValue(f.selectedValue)
            : r[f.name] === getStatusByValue(f.selectedValue)
        } else if (f.name === 'name') {
          return r[f.name].includes(f.selectedValue)
        }
      })
    })
    return _searchRows
  }
  const setFilterSelectOptions = () => {
    return [
      {
        filterName: 'status',
        id: 'status',
        name: 'status',
        options: getStatusOptionList(),
        defaultLabel: 'All Statuses',
        selectedValue: 'default',
        action
      },
      {
        filterName: 'name',
        id: 'name',
        name: 'name',
        options: getTypeOptionList(),
        defaultLabel: 'All Offerings',
        selectedValue: 'default',
        action
      }
    ]
  }

  const isVisibleAction = (actionType, data) => {
    let isVisible = false
    switch (actionType) {
    	case 'Approve/Decline':
            isVisible = /Requested/i.test(data?.originalStatus) &&  accessControlFunc({id: "tc.pages.contracts-and-offerings.approve", userAccess})
    		break;
    	case 'Edit':
    		isVisible =  (/Requested|Declined/i.test(data?.originalStatus) && data.userId === user.id)  || (/Approved|Cancelled/i.test(data?.originalStatus) && accessControlFunc({id: "tc.pages.contracts-and-offerings.approve", userAccess}))
			break;
    	case 'Delete':
    		isVisible =  /Requested|Declined/i.test(data?.originalStatus) && data.userId === user.id
    		break;
    }
    return isVisible
  }

  const rowControls = (row) => {
    if (!row.contractId) {
      return []
    }
    let _rowControls = []
    if (isVisibleAction('Approve/Decline', row)) {
      _rowControls.push({
        text: 'Approve/Decline',
        action: async ({ contractId }) => {
          const offering = offerings.find(
            (offering) => offering.contractId === contractId
          )
          const {
            mechanicalServiceAgreementNumber = null,
            pactContractNumber = null
          } = JSON.parse(offering.additionalContractNumbers || '{}')
          if (offering) {
            const [{data:buildingDetails},attachments] = await Promise.all(
				[
					API.graphql({
						query: GET_BUILDINGDETAILS_BY_ID_func(offering.buildingIds)
					  }),
					  refetchAttachmentsByContract({id:contractId})
				]
			)
            setState((state) => ({
              ...state,
              requestDialogType: BPEPApproveDialog,
              prefilledValues: {
                ...(offering || {}),
				buildingCount:offering.buildingCount??offering.buildingIds?.length,
				numberOfDevices: parseInt(offering.deviceCount),
				attachments:attachments.map(attachment => ({
					...attachment,
					fileSize:attachment.sizeInBytes,
					fileName:attachment.name
				  })),
                mechanicalServiceAgreementNumber,
                pactContractNumber,
                ...(buildingDetails ? { buildingDetails } : {})
              }
            }))
          }
        }
      })
    }
    if (isVisibleAction('Edit', row)) {
      _rowControls.push({
        text: 'Edit',
        action: ({ contractId }) => {
          navigate(
            `${pages['Contracts & Offerings Edit'].href}`.replace(
              ':id',
              contractId
            )
          )
        }
      })
    }

    if (isVisibleAction('Delete', row)) {
      _rowControls.push({
        text: 'Delete',
        action: (data) => {
          setState((state) => ({
            ...state,
            confirm: {
              title: t('common:Delete', { item: 'Request' }),
              text: t('common:DeleteConfirm', { item: '' }),
              confirmText: t('common:Yes'),
              cancelText: t('common:No'),
              handleConfirm: async () => {
                const res = await Promise.all([
                  handleOfferingDelete(data?.offeringIds),
                  handleContractDelete(data?.contractId)
                ])
                const errors = res.flat()?.find(({ data }) => data.errors)
                if (!errors) {
                  setOfferings((offerings) =>
                    offerings.filter(
                      (offering) => offering.contractId !== data.contractId
                    )
                  )
                }
                setState((state) => ({
                  ...state,
                  confirm: null
                }))
              },
              handleCancel: () => {
                setState((state) => ({
                  ...state,
                  confirm: null
                }))
              }
            }
          }))
        }
      })
    }

    return _rowControls
  }

  const table = (
    <ClientSideTable
      // rowUniqueKey="contractId"
      // customClasses="offerings-table"
      // headings={this.headings(t)}
      // columns={filteredOfferingsRequestList}
      // usePagination={true}
      // resultsPerPage={50}
      // emptyMessage={t("building-configuration:BuildingSetupPage>Offerings>NoOfferings")}
      // isLoading={this.props.loading || loading || loadingByOrganization || loadingBySalesOffice}
      fixedLayout={false}
      loadTime={props.loadTime}
      key={`contracts-table-${props?.accountId || props?.buildingId}`}
      header={nameHeadings}
      search={true}
      searchFields={['buildingName']}
      showSpinner= {props?.loading}
      rows={offerings || []}
      // loadTime={responseTime}
      // isShowPagination={false}
      rowControl={rowControls}
      filtersList={setFilterSelectOptions()}
      tableClassName={"offeringsTable"}
      testName="contracts-and-offerings"
    />
  )

  // showSmallTable ? <SmallScreenTable
  // 	rowUniqueKey="contractId"
  // 	customClasses="offerings-table"
  // 	headings={this.headings(t)}
  // 	columns={filteredOfferingsRequestList}
  // 	usePagination={true}
  // 	resultsPerPage={50}
  // 	displayName={t("building-configuration:BuildingSetupPage>Offerings>Offerings")}
  // 	emptyMessage={t("building-configuration:BuildingSetupPage>Offerings>NoOfferings")}
  // 	isLoading={this.props.loading || loading || loadingByOrganization || loadingBySalesOffice}
  // />
  // 	:
  // <Table
  // 	rowUniqueKey="contractId"
  // 	customClasses="offerings-table"
  // 	headings={this.headings(t)}
  // 	columns={filteredOfferingsRequestList}
  // 	usePagination={true}
  // 	resultsPerPage={50}
  // 	emptyMessage={t("building-configuration:BuildingSetupPage>Offerings>NoOfferings")}
  // 	isLoading={this.props.loading || loading || loadingByOrganization || loadingBySalesOffice}
  // />
  ;('')

	const onCloseDialog = field => () => {
		setState({
			[field]: null
		})
	}

  return (
    <>
      <Toolbar>
        <button
          className="action small"
          disabled = {!(props?.accountId)}
          onClick={() => {
            pages['Rate Structure Library'].href
            navigate(
              `${pages['Contracts & Offerings Add'].href}?organization=${
                props?.accountId
              }${props?.buildingId ? `&location=${props.buildingId}` : ''}`
            )
          }}
        >
          <Trans i18nKey="building-configuration:BuildingSetupPage>Offerings>RequestActivate" />
        </button>
      </Toolbar>
      <div className="offering">
        {/* <ContractsAndOfferingsFilter
					onChange={this.handleFilterChange}
					onSearch={this.handleSearch}
					searchText={searchText}
					searchPlaceholder={t("contracts-and-offerings:findABuilding")}
					selectOptions={this.setFilterSelectOptions()}
					itemFoundText={t("contracts-and-offerings:itemFound", { length: filteredOfferingsRequestList.length, totalLength: offeringsRequestList.length })}
				/> */}
        {table}
      </div>
      {state.requestDialogType && (
        <OfferingDialog
          type={state.requestDialogType}
          values={state.prefilledValues}
          // loading={saveChangesInProgress}
          // locationInfo={locationInfo}
          // organizationId={organizationId}
          // salesOfficeId={salesOfficeId}
          // currentUser={currentUser}
          handleActivate={async (values) => {
            const input = approvalInput({ values, status: STATUS_TO_SAVE.APPROVED })
            const offeringIds =
              offerings.find(
                (offering) => offering.contractId === values.contractId
              )?.offeringIds || []
			//move update offerings to lamda
            const data = await Promise.all([
              ...offeringIds.map((id) => {
                return updateOffering({
                  input: {
                    id,
          analyticsStartDate: utcTimeStartOfTheDay(values.analyticsStartDate),
					startDate:utcTimeStartOfTheDay(values.startDate),
					endDate:utcTimeEndOfTheDay(values.endDate),
                    status: STATUS_TO_SAVE.APPROVED,
                    isActive: 1
                  }
                })
              }),
              updateContract({ input })
            ])
            const errors = data?.find((_data) => _data.error)
            if (!errors) {
              setState((state) => ({ ...state,
                requestDialogType: '',
                notification: {
                title: "Success",
                text: `Contract number ${input?.serviceNumber} has been activated.Analytics will begin running on ${moment(input?.startDate).format('MM/DD/YYYY')}.A message has been sent to the requestor.`
              }}))
              setOfferings(
                offerings.map((offering) => {
                  if (offering.contractId === values.contractId) {
                    const updatedOffering = {
                      ...offering,
                      ...input,
                      status: STATUS_TO_SAVE.APPROVED,
                      originalStatus: STATUS_TO_SAVE.APPROVED
                    }
                    return {
                      ...updatedOffering,
                      status: getContractStatus(updatedOffering)
                    }
                  } else {
                    return offering
                  }
                })
              )
            }
          }}
          handleDecline={async (values) => {
            const input = approvalInput({ values, status: STATUS_TO_SAVE.DECLINED })
            const offeringIds =
              offerings.find(
                (offering) => offering.contractId === values.contractId
              )?.offeringIds || []
            const data = await Promise.all([
              ...offeringIds.map((id) => {
                return updateOffering({
                  input: {
                    id,
                    analyticsStartDate:utcTimeStartOfTheDay(values.analyticsStartDate),
					startDate: utcTimeStartOfTheDay(values.startDate),
					endDate:utcTimeEndOfTheDay(values.endDate),
                    status: STATUS_TO_SAVE.DECLINED
                  }
                })
              }),
              updateContract({ input })
            ])
            const errors = data?.find((_data) => _data.error)
            if (!errors) {
              setState((state) => ({ ...state,
                requestDialogType: '',
                notification: {
                title: "Success",
                text: `The activation request has been declined and sent back to the requester for revision.`
              }}))
              setOfferings(
                offerings.map((offering) => {
                  if (offering.contractId === values.contractId) {
                    const updatedOffering = {
                      ...offering,
                      ...input,
                      status: STATUS_TO_SAVE.DECLINED,
                      originalStatus: STATUS_TO_SAVE.DECLINED    
                    }
                    return {
                      ...updatedOffering,
                      status: getContractStatus(updatedOffering)
                    }
                  } else {
                    return offering
                  }
                })
              )
            }
          }}
          handleCancel={() => {
            setState((state) => ({ ...state, requestDialogType: '' }))
            // this.setState({
            // 	requestDialogType: null,
            // 	prefilledValues: null,
            // 	dialogFromUrl: false,
            // 	dialogSaveAction: () => { },
            // 	dialogDeclineAction: () => { }
            // })
          }}
        />
      )}
      <StandardDialogs confirm={state?.confirm} notification={state?.notification} onCloseDialog ={onCloseDialog} />
    </>
  )
}
// }

export default Offerings
