import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ReactDOMServer from "react-dom/server"

import { schema } from "prosemirror-schema-basic"
import { EditorState } from "prosemirror-state"
import { EditorView } from "prosemirror-view"
import { baseKeymap } from "prosemirror-commands"
import { keymap } from "prosemirror-keymap"
import { Schema, Fragment, Slice, DOMSerializer } from "prosemirror-model"
import { dropCursor } from "prosemirror-dropcursor"

import Label from 'src/components/legacy/components/label/label'
import Tooltip from 'src/denali-ui/components/Tooltip'
import { TranslateComponent } from 'src/common/translations'


export const objToPlaceholder = object => `${object.propertyName}_${object.identifier}`

const attrs = {
	object: {},
}

const createTokenHtmlString = o => {
	const tooltipText = `${o.buildingName} - ${o.equipmentName} - ${o.equipmentFamily} - ${o.propertyName}`

	return ReactDOMServer.renderToStaticMarkup(
		<Tooltip content={tooltipText} SpanOrDiv={"span"}>
			<span>{o.propertyName}</span>
		</Tooltip>
	)
}

const calcPropNodeSpec = {
	attrs,
	inline: true,
	group: "inline",
	draggable: true,
	toDOM: node => ["span", {
		...node.attrs,
		class: "calcPropToken",
	}, (() => {
		const child = document.createElement("SPAN")
		child.innerHTML = `${createTokenHtmlString(node.attrs.object)}`
		return child
	})()],
}

const calcPropSchema = new Schema({
	nodes: schema.spec.nodes.addBefore("image", "calcProp", calcPropNodeSpec),
	marks: schema.spec.marks,
})

const calcPropType = calcPropSchema.nodes.calcProp

const findAndConcatTextNodes = slice => {
	if (slice.text) {
		return slice.text
	}

	if (!slice.content) {
		return ""
	}

	if (Array.isArray(slice.content)) {
		return slice.content.map(findAndConcatTextNodes).join(" ")
	}

	return findAndConcatTextNodes(slice.content)
}

const baseSerializer = DOMSerializer.fromSchema(calcPropSchema)
const clipboardSerializer = new DOMSerializer({
	...baseSerializer.nodes,
	calcProp(node) {
		return ["span", `___COPIEDNODE${JSON.stringify(node.attrs)}EDONDEIPOC___`]
	},
})

const transformPasted = slice => {
	try {
		const text = findAndConcatTextNodes(slice).split("EDONDEIPOC___")
		const nodes = []
	
		text.forEach(part => {
			let prefix, token
			if (part.indexOf("___COPIEDNODE") === 0) {
				token = part.replace("___COPIEDNODE", "")
			} else if (part.indexOf("___COPIEDNODE") !== -1) {
				[prefix, token] = part.split("___COPIEDNODE")
			} else {
				prefix = part
			}
	
			if (prefix) {
				nodes.push(calcPropSchema.text(prefix))
			}
	
			if (token) {
				nodes.push(
					calcPropSchema.text("\u00A0\u00A0"),
					calcPropType.create(JSON.parse(token)),
					calcPropSchema.text("\u00A0\u00A0"),
				)
			}
		})
	
		return new Slice(Fragment.from(nodes), 0, 0)
	} catch(error){
		
	}
	
}

const collectProperties = node => {
	const properties = []
	function flattenProseMirrorModel(node) {
		if (node.type === "text") {
			return [node.text.replace(/\s/g, "")]
		}

		if (node.type === "calcProp") {
			if (!properties.some(prop =>
				prop.identifier === node.attrs.object.identifier
			)) {
				properties.push(node.attrs.object)
			}
			return [` ${objToPlaceholder(node.attrs.object)} `]
		}

		if (!node.content || !node.content.length) {
			return []
		}

		return node.content.reduce((acc, item) => acc.concat(flattenProseMirrorModel(item)), [])
	}

	const calcFormula = flattenProseMirrorModel(node).join("")
	return { properties, calcFormula }
}

const createNode = modifyObject => {
	if (!modifyObject.calcFormula) {
		return
	}

	let calcFormulae = [modifyObject.calcFormula]

	;(modifyObject.properties || []).forEach(prop => {
		const oldFormulae = [...calcFormulae]
		calcFormulae = []
		oldFormulae.forEach(f => {
			if (typeof f !== "string") {
				calcFormulae.push(f)

				return
			}
			const parts = f.split(objToPlaceholder(prop))
			if (parts.length < 1) {
				return
			}

			calcFormulae.push(parts[0])

			for (let i = 1; i < parts.length; ++i) {
				calcFormulae.push({
					type: "calcProp",
					attrs: {
						object: prop,
					},
				})
				calcFormulae.push(parts[i])
			}
		})
	})

	return {
		type: "doc",
		content: [
			{
				type: "paragraph",
				content: calcFormulae.map(f => typeof f !== "string" ? f : {
					type: "text",
					text: f,
				}),
			},
		],
	}
}

export class FormulaEditor extends React.PureComponent {
	static propTypes = {
		hasError: PropTypes.bool,
		isValid: PropTypes.bool,
		modifyObject: PropTypes.object,
		onChange: PropTypes.func.isRequired,
		onBlur: PropTypes.func,
		stagedObjects: PropTypes.array,
		errorMessage: PropTypes.string,
		unstageObjects: PropTypes.func.isRequired,
	}

	static defaultProps = {
		hasError: false,
		isValid: true,
		modifyObject: {},
		stagedObjects: [],
		errorMessage: "",
		onBlur: () => void 0,
	}

	captureFormulaElement = ref => {
		if (!ref) {
			return
		}

		this.formulaElement = ref

		this.editorView = new EditorView(ref, {
			clipboardSerializer,
			transformPasted,
			state: this.editorState,
			dispatchTransaction: this.dispatchTransaction,
			handleDOMEvents: {
				blur: this.props.onBlur,
			},
		})
	}

	insertToken = object => {
		const { $from } = this.editorView.state.selection
		const index = $from.index()
		if (!$from.parent.canReplaceWith(index, index, calcPropType)) {
			return false
		}

		this.editorView.dispatch(this.editorView.state.tr.replaceSelection(new Slice(Fragment.from([
			calcPropSchema.text("\u00A0\u00A0"),
			calcPropType.create({ object }),
			calcPropSchema.text("\u00A0\u00A0"),
		]), 0, 0)))
	}

	dispatchTransaction = tx => {
		this.editorState = this.editorState.apply(tx)
		if (this.editorView) {
			this.editorView.updateState(this.editorState)
		}

		this.props.onChange(collectProperties(this.editorState.doc.toJSON()))
	}

	constructor(props) {
		super(props)
		const schemaConfig = {
			schema: calcPropSchema,
			plugins: [
				dropCursor(),
				keymap(baseKeymap)
			]
		}
		if (this.props.modifyObject && Object.keys(this.props.modifyObject).length) {
			this.editorState = EditorState.fromJSON(schemaConfig, {
				doc: createNode(this.props.modifyObject),
				selection: {
					anchor: 1,
					head: 1,
					type: "text",
				},
			})
		} else {
			this.editorState = EditorState.create(schemaConfig)
		}
	}

	componentDidUpdate() {
		if (this.props.stagedObjects.length) {
			this.props.stagedObjects.forEach(object => {
				this.insertToken(object)
			})
			this.props.unstageObjects()
		}
	}

	render() {
		const { hasError, isValid, errorMessage } = this.props

		return (
			<div className="modal-edit-textarea-wrapper">
				<Label text={<span className={classNames({ "has-error": hasError })}><TranslateComponent>Calculation Formula</TranslateComponent>*</span>} />
				<div>
					<div className={classNames("modal-calc-formula-input", { "has-error": hasError })} ref={this.captureFormulaElement} />
					{hasError && <span className="mini-error-text"><TranslateComponent>{errorMessage}</TranslateComponent></span>}
				</div>
			</div>
		)
	}
}
