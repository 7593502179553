import { memo, useMemo, useState } from 'react'
import {
  selectCanvas,
  selectCanvasDetails,
  selectChartInstanceId,
  useSelector
} from '../../BaseSetup/BaseSetup'
import ChartSVG from './ChartSVG'
import Chart from './Chart'
import { isEmpty } from 'lodash'
import { plotAreaID } from './helper'
import { Tooltip } from '../../CommonComponents/Bar/constants'
import ToolTip from '../../CommonComponents/Bar/Tooltip/Tooltip'
import {
  Tooltip as TooltipNew,
  TooltipLinkPointer
} from '../../CommonComponents/TooltipNew'

const GroupRenderer = ({ isTranslate=false }) => {
  const canvasDetails = useSelector(selectCanvasDetails)
  const canvas = useSelector(selectCanvas)
  const chartInstanceId = useSelector(selectChartInstanceId)

  const [tooltip, setTooltip] = useState<Tooltip | null>(null)

  if (isEmpty(canvasDetails)) {
    return <></>
  }

  return (
    <>
      <ChartSVG>
      <TooltipLinkPointer>
  {Object.keys(canvasDetails ?? {})?.map((canvasIndex: any) => {
    const canvasObject = canvasDetails?.[`${canvasIndex}`] ?? {}
    return (
      <g
        data-canvas={`canvas-${canvasIndex}`}
        key={`canvas-${canvasIndex}`}
        style={{
          transform: `translate(${canvasObject?.transformX ?? 0}px,${canvasObject?.transformY ?? 0}px)`
        }}
      >
        <TooltipNew
          isTranslate={isTranslate}
          canvasWidth={canvasObject?.width ?? 0}
          canvasHeight={canvasObject?.height ?? 0}
          Template={canvas?.canvasDetails?.[canvasIndex]?.tooltip?.template}
          hide={canvas?.canvasDetails?.[canvasIndex]?.tooltip?.hide}
          link={canvas?.canvasDetails?.[canvasIndex]?.tooltip?.link}
          triggerBy={canvas?.canvasDetails?.[canvasIndex]?.tooltip?.triggerBy}
          crosslineProps={canvas?.canvasDetails?.[canvasIndex]?.tooltip?.crosslineProps}
          tooltipTemplateProps={canvas?.canvasDetails?.[canvasIndex]?.tooltip?.tooltipTemplateProps}
          disableClip={true}
          renderingAreaClipPath={`${plotAreaID(chartInstanceId, canvasIndex)}`}
        >
          <clipPath id={`${plotAreaID(chartInstanceId, canvasIndex)}`}>
            <rect
              x={0}
              y={0}
              width={canvasObject?.width ?? 0}
              height={canvasObject?.height ?? 0}
              fill={'transparent'}
            />
          </clipPath>
          <rect
            id={`${plotAreaID(chartInstanceId, canvasIndex)}`}
            x={0}
            y={0}
            width={canvasObject?.width ?? 0}
            height={canvasObject?.height ?? 0}
            fill={'transparent'}
          />
          {canvas?.canvasDetails?.[canvasIndex]?.renderAreaShade && (
            <rect
              pointerEvents="none"
              x={0}
              y={0}
              width={canvasObject?.width ?? 0}
              height={canvasObject?.height ?? 0}
              fill={
                canvas?.canvasDetails[canvasIndex]?.renderAreaShade
                  ?.fill
              }
              fillOpacity={canvas?.canvasDetails?.[canvasIndex]?.renderAreaShade?.opacity ?? 1}
            />
          )}
          <Chart canvasIndex={canvasIndex} setTooltip={setTooltip} />
        </TooltipNew>
      </g>
    )
  })}
</TooltipLinkPointer>

      </ChartSVG>

      {tooltip !== null ? (
        <ToolTip isGroupBar={true} tooltip={tooltip} />
      ) : null}
    </>
  )
}

export default memo(GroupRenderer)
