export const light = {
  backgroundColor: '{colors.neutral.10}',
  borderColor: 'transparent',
  borderRadius: '9999px',
  color: '{colors.neutral.90}',
  inputBorderRadius: '16px',
  fontSize: '0.875rem',
  inputFontSize: '1rem',
  lineHeight: 'normal',
  paddingBlockStart: '0.75rem',
  paddingBlockEnd: '0.75rem',
  paddingInlineStart: '1rem',
  paddingInlineEnd: '1rem',
  iconColor: '{colors.neutral.90}',
}