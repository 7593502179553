import { formatNumberToStandardPrecision } from "src/common/standard-precision-format"
import { formatCurrencyPrecision } from "src/common/currency"
import { TranslateComponent } from "src/common/translations"


export const formatUnitOfMeasurmentNumberFormat = d => formatNumberToStandardPrecision(d)

export const formatDollarLabel = d => d < 0 ? `-$${formatCurrencyPrecision(d).substring(1)}` : `$${formatCurrencyPrecision(d)}`

export const denaliFormatDollarLabel = d => d < 0 ? `-$${numberWithCommas(d).substring(1)}` : `$${numberWithCommas(d).split(".")[0]}`

export const numberWithCommas = x => {
	const parts = x.toString().split(".")
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	return parts.join(".")
}

export const getTotalBill = (utilityItem) => {
    let totalBillAmount = 0;
    if(utilityItem?.electricUtilityData?.totalBill) {
      totalBillAmount += utilityItem?.electricUtilityData?.totalBill
    } 
    if(utilityItem?.gasUtilityData?.totalCost) {
      totalBillAmount += utilityItem?.gasUtilityData?.totalCost
    } 
    if(utilityItem?.waterUtilityData?.totalCost){
      totalBillAmount += utilityItem?.waterUtilityData?.totalCost
    }
    return totalBillAmount
}

export const setTotalUtilityData = (utilityData) => {
	const totalUtilityData = []
	utilityData?.map((data)=> {
		const utilityItem = {}
		const totalUtilityBill = getTotalBill(data)
    utilityItem.id = data?.id
		utilityItem.year = data?.year?.toString()
		utilityItem.month = data?.month
		utilityItem.totalBill = totalUtilityBill !== null && totalUtilityBill !== 0 && !isNaN(totalUtilityBill) ? formatDollarLabel(parseFloat(totalUtilityBill)) : "--"
    utilityItem.totalBillAmount = totalUtilityBill !== null && parseFloat(totalUtilityBill)
		utilityItem.electricBill = data?.electricUtilityData?.totalBill !== null && data?.electricUtilityData?.totalBill !== 0 && !isNaN(data?.electricUtilityData?.totalBill) ? formatDollarLabel(parseFloat(data?.electricUtilityData?.totalBill)) : "--"
		utilityItem.gasBill = data?.gasUtilityData?.totalCost !== null && data?.gasUtilityData?.totalCost !== 0 && !isNaN(data?.gasUtilityData?.totalCost) ? formatDollarLabel(parseFloat(data?.gasUtilityData?.totalCost)) : "--"
		utilityItem.waterBill = data?.waterUtilityData?.totalCost !== null && data?.waterUtilityData?.totalCost !== 0 && !isNaN(data?.waterUtilityData?.totalCost) ? formatDollarLabel(parseFloat(data?.waterUtilityData?.totalCost)) : "--"
		utilityItem.eci = data?.eci?.toFixed(2)
    utilityItem.eui = data?.eui?.toFixed(2)
    utilityItem.checkboxCheckedEnabled = false
    // utilityItem.checkboxChecked = true
		totalUtilityData.push(utilityItem)
	})
  return totalUtilityData
 }

 export const setElectricUtilityData = (utilityData) => {
   const elecricBillData = []
   utilityData?.map((data)=> {
	   const utilityItem = {}
	   const electricUtilityData = data?.electricUtilityData
	   if(electricUtilityData) {
		 utilityItem.year = data?.year?.toString()
		 utilityItem.month = data?.month
		 utilityItem.startDate = electricUtilityData?.startDate || "--"
		 utilityItem.endDate = electricUtilityData?.endDate || "--"
		 utilityItem.consumption = electricUtilityData?.consumption || "--"
		 utilityItem.consumptionCost = electricUtilityData?.consumptionCost !== null && electricUtilityData?.consumptionCost !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.consumptionCost)) : "--"
		 utilityItem.peakDemandRealPower = electricUtilityData?.peakDemandRealPower !== null && electricUtilityData?.peakDemandRealPower !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(electricUtilityData?.peakDemandRealPower)) : "--"
		 utilityItem.peakDemandApparentPower = electricUtilityData?.peakDemandApparentPower !== null && electricUtilityData?.peakDemandApparentPower !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(electricUtilityData?.peakDemandApparentPower)) : "--"
		 utilityItem.peakDemandCost = electricUtilityData?.peakDemandCost !== null && electricUtilityData?.peakDemandCost !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.peakDemandCost)) : "--"
		 utilityItem.totalDemandCost = electricUtilityData?.totalDemandCost !== null && electricUtilityData?.totalDemandCost !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.totalDemandCost)) : "--"
		 utilityItem.intermediateDemandRealPower = electricUtilityData?.intermediateDemandRealPower !== null && electricUtilityData?.intermediateDemandRealPower !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(electricUtilityData?.intermediateDemandRealPower)) : "--"
		 utilityItem.intermediateDemandApparentPower = electricUtilityData?.intermediateDemandApparentPower !== null && electricUtilityData?.intermediateDemandApparentPower !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(electricUtilityData?.intermediateDemandApparentPower)) : "--"
		 utilityItem.intermediateDemandCost = electricUtilityData?.intermediateDemandCost !== null && electricUtilityData?.intermediateDemandCost !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.intermediateDemandCost)) : "--"
		 utilityItem.baseDemandRealPower = electricUtilityData?.baseDemandRealPower  !== null && electricUtilityData?.baseDemandRealPower  !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(electricUtilityData?.baseDemandRealPower)) : "--"
		 utilityItem.baseDemandApparentPower = electricUtilityData?.baseDemandApparentPower  !== null && electricUtilityData?.baseDemandApparentPower  !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(electricUtilityData?.baseDemandApparentPower)) : "--"
		 utilityItem.baseDemandCost = electricUtilityData?.baseDemandCost  !== null && electricUtilityData?.baseDemandCost  !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.baseDemandCost)) : "--"
		 utilityItem.powerFactor = electricUtilityData?.powerFactor  !== null && electricUtilityData?.powerFactor  !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.powerFactor)) : "--"
		 utilityItem.powerFactorCost = electricUtilityData?.powerFactorCost  !== null && electricUtilityData?.powerFactorCost  !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.powerFactorCost)) : "--"
		 utilityItem.fees = electricUtilityData?.fees  !== null && electricUtilityData?.fees  !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.fees)) : "--"
		 utilityItem.totalBill = electricUtilityData?.totalBill  !== null && electricUtilityData?.totalBill  !== 0 ? formatDollarLabel(parseFloat(electricUtilityData?.totalBill)) : "--"
		 elecricBillData.push(utilityItem)
	   }
   })
   return elecricBillData
 }

 export const setGasUtilityData = (utilityData) => {
   const gasBillData = []
   utilityData?.map((data)=> {
	   const utilityItem = {}
	   const gasUtilityData = data?.gasUtilityData
	   if(gasUtilityData) {
		 utilityItem.year = data?.year?.toString()
		 utilityItem.month = data?.month
		 utilityItem.startDate = gasUtilityData?.startDate || "--"
		 utilityItem.endDate = gasUtilityData?.endDate || "--"
		 utilityItem.consumptionTransportation = gasUtilityData?.consumptionTransportation !== null && gasUtilityData?.consumptionTransportation !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(gasUtilityData?.consumptionTransportation)) : "--"
		 utilityItem.consumptionCommodity = gasUtilityData?.consumptionCommodity !== null && gasUtilityData?.consumptionCommodity !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(gasUtilityData?.consumptionCommodity)) : "--"
		 utilityItem.gasType = gasUtilityData?.gasType !== null && gasUtilityData?.gasType !== 0 ? gasUtilityData?.gasType : "--"
		 utilityItem.transportationCost = gasUtilityData?.transportationCost  !== null && gasUtilityData?.transportationCost  !== 0 ? formatDollarLabel(parseFloat(gasUtilityData?.transportationCost)) : "--"
		 utilityItem.commodityCost = gasUtilityData?.commodityCost  !== null && gasUtilityData?.commodityCost  !== 0 ? formatDollarLabel(parseFloat(gasUtilityData?.commodityCost)) : "--"
		 utilityItem.fees = gasUtilityData?.fees  !== null && gasUtilityData?.fees  !== 0 ? formatDollarLabel(parseFloat(gasUtilityData?.fees)) : "--"
		 utilityItem.totalCost = gasUtilityData?.totalCost  !== null && gasUtilityData?.totalCost  !== 0 ? formatDollarLabel(parseFloat(gasUtilityData?.totalCost)) : "--"
		 gasBillData.push(utilityItem)
	   }
   })
   return gasBillData
 }

 export const setWaterUtilityData = (utilityData) => {
   const waterBillData = []
   utilityData?.map((data)=> {
	   const utilityItem = {}
	   const waterUtilityData = data?.waterUtilityData
	   if(waterUtilityData) {
		 utilityItem.year = data?.year?.toString()
		 utilityItem.month = data?.month
		 utilityItem.startDate = waterUtilityData?.startDate || "--"
		 utilityItem.endDate = waterUtilityData?.endDate || "--"
		 utilityItem.consumption = waterUtilityData?.consumption !== null && waterUtilityData?.consumption !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(waterUtilityData?.consumption)) : "--"
		 utilityItem.sewage = waterUtilityData?.sewage !== null && waterUtilityData?.sewage !== 0 ? formatUnitOfMeasurmentNumberFormat(parseFloat(waterUtilityData?.sewage)) : "--"
		 utilityItem.consumptionCost = waterUtilityData?.consumptionCost  !== null && waterUtilityData?.consumptionCost  !== 0 ? formatDollarLabel(parseFloat(waterUtilityData?.consumptionCost)) : "--"
		 utilityItem.sewageCost = waterUtilityData?.sewageCost  !== null && waterUtilityData?.sewageCost  !== 0 ? formatDollarLabel(parseFloat(waterUtilityData?.sewageCost)) : "--"
		 utilityItem.fees = waterUtilityData?.fees  !== null && waterUtilityData?.fees  !== 0 ? formatDollarLabel(parseFloat(waterUtilityData?.fees)) : "--"
		 utilityItem.totalCost = waterUtilityData?.totalCost  !== null && waterUtilityData?.totalCost  !== 0 ? formatDollarLabel(parseFloat(waterUtilityData?.totalCost)) : "--"
		 waterBillData.push(utilityItem)
	   }
   })
   return waterBillData
 }

 export const getNameHeadingsTotal = (getTableHeaderComponent, getRowDeleteButtonComponent, getECIComponent, getEUIComponent) => {
  return [
    {
      title: "",
      key: '',
      columnType: 'number',
      width:'200px',
      canSort:false,
      customHeaderComponent: (tableConfig) => {
        return <>{getTableHeaderComponent(tableConfig)}</>
      },
    },
    {
    title: <TranslateComponent>Year</TranslateComponent>,
    key: 'year',
    columnType: 'number',
    width: '200px',
    canSort: true
    },
    {
    title: <TranslateComponent>Month</TranslateComponent>,
    key: 'month',
    width: '200px',
    canSort: false
    },
    {
    title: <TranslateComponent>Total</TranslateComponent>,
    key: 'totalBill',
    width: '200px',
    canSort: false
    },
    {
    title: <TranslateComponent>Electric</TranslateComponent>,
    key: 'electricBill',
    width: '200px',
    canSort: false
    },
    {
    title: <TranslateComponent>Gas</TranslateComponent>,
    key: 'gasBill',
    width: '200px',
    canSort: false
    },
    {
    title: <TranslateComponent>Water</TranslateComponent>,
    key: 'waterBill',
    width: '200px',
    canSort: false
    },
    {
    title: <TranslateComponent>EUI</TranslateComponent>,
    key: 'eui',
    width: '200px',
    canSort: false,
    customComponent: (row) => {
      return <>{getEUIComponent(row)}</>
      }
    },
    {
    title: <TranslateComponent>ECI</TranslateComponent>,
    key: 'eci',
    maxWidth: '200px',
    canSort: false,
    customComponent: (row) => {
        return <>{getECIComponent(row)}</>
      } 
    },
    {
      title: '',
      key: '',
      maxWidth: '150px',
      canSort: false,
      alignRight:true,
      customComponent: (row) => {
        return <>{getRowDeleteButtonComponent(row)}</>
      } 
    }
 ]

 }
 

export const nameHeadingsElectricBill = [
 {
   title: <TranslateComponent>Year</TranslateComponent>,
   key: 'year',
   columnType: 'number',
   maxWidth: '50px',
   billType:"electric",
   canSort: true
 },
 {
   title: <TranslateComponent>Month</TranslateComponent>,
   key: 'month',
   columnType: 'number',
   maxWidth: '50px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Electric Billing Start Date</TranslateComponent>,
   key: 'startDate',
   maxWidth: '110px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Electric Billing End Date</TranslateComponent>,
   key: 'endDate',
   maxWidth: '110px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Consumption (kWh)</TranslateComponent>,
   key: 'consumption',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Consumption Cost ($)</TranslateComponent>,
   key: 'consumptionCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Peak Demand (Real Power, kW)</TranslateComponent>,
   key: 'peakDemandRealPower',
   columnType: 'number',
   maxWidth: '150px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Peak Demand (Apparent Power, kVA)</TranslateComponent>,
   key: 'peakDemandApparentPower',
   columnType: 'number',
   maxWidth: '150px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Peak Demand Cost ($)</TranslateComponent>,
   key: 'peakDemandCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>TOTAL\nDemand Cost ($)</TranslateComponent>,
   key: 'totalDemandCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Intermediate Demand (Real Power, kW)</TranslateComponent>,
   key: 'intermediateDemandRealPower',
   columnType: 'number',
   maxWidth: '160px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Intermediate Demand (Apparent Power, kVa)</TranslateComponent>,
   key: 'intermediateDemandApparentPower',
   columnType: 'number',
   maxWidth: '160px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Intermediate Cost ($)</TranslateComponent>,
   key: 'intermediateDemandCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Base Demand (Real Power, kW)</TranslateComponent>,
   key: 'baseDemandRealPower',
   columnType: 'number',
   maxWidth: '130px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Base Demand (Apparent Power, kVa)</TranslateComponent>,
   key: 'baseDemandApparentPower',
   columnType: 'number',
   maxWidth: '150px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>KW Base Cost ($)</TranslateComponent>,
   key: 'baseDemandCost',
   columnType: 'number',
   maxWidth: '80px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Power Factor Value</TranslateComponent>,
   key: 'powerFactor',
   columnType: 'number',
   maxWidth: '80px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Power Factor Cost ($)</TranslateComponent>,
   key: 'powerFactorCost',
   columnType: 'number',
   maxWidth: '80px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Total Misc Fees ($)</TranslateComponent>,
   key: 'fees',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
 {
   title: <TranslateComponent>Total Electric Bill ($)</TranslateComponent>,
   key: 'totalBill',
   columnType: 'number',
   maxWidth: '100px',
   billType:"electric",
   canSort: false
 },
]

export const nameHeadingsGasBill = [
 {
   title: <TranslateComponent>Year</TranslateComponent>,
   key: 'year',
   columnType: 'number',
   maxWidth: '50px',
   billType:"gas",
   canSort: true
 },
 {
   title: <TranslateComponent>Month</TranslateComponent>,
   key: 'month',
   columnType: 'number',
   maxWidth: '50px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Gas Consumption Bill Start Date</TranslateComponent>,
   key: 'startDate',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Gas Consumption Bill End Date</TranslateComponent>,
   key: 'endDate',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Consumption Transportation (CCF)</TranslateComponent>,
   key: 'consumptionTransportation',
   columnType: 'number',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Consumption Commodity (CCF)</TranslateComponent>,
   key: 'consumptionCommodity',
   columnType: 'number',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Type (Propane OR Natural)</TranslateComponent>,
   key: 'gasType',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Transportation Cost ($)</TranslateComponent>,
   key: 'transportationCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Commodity Cost ($)</TranslateComponent>,
   key: 'commodityCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Total Gas Fees ($)</TranslateComponent>,
   key: 'fees',
   columnType: 'number',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
 {
   title: <TranslateComponent>Total Gas Cost ($)</TranslateComponent>,
   key: 'totalCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"gas",
   canSort: false
 },
]

export const nameHeadingsWaterBill = [
 {
   title: <TranslateComponent>Year</TranslateComponent>,
   key: 'year',
   columnType: 'number',
   maxWidth: '50px',
   billType:"water",
   canSort: true
 },
 {
   title: <TranslateComponent>Month</TranslateComponent>,
   key: 'month',
   columnType: 'number',
   maxWidth: '50px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Water Bill Start Date</TranslateComponent>,
   key: 'startDate',
   maxWidth: '50px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Water Bill End Date</TranslateComponent>,
   key: 'endDate',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Consumption  (Gallons)</TranslateComponent>,
   key: 'consumption',
   columnType: 'number',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Sewage (Gallons)</TranslateComponent>,
   key: 'sewage',
   columnType: 'number',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Consumption Cost ($)</TranslateComponent>,
   key: 'consumptionCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Sewage Cost ($)</TranslateComponent>,
   key: 'sewageCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Total Water Fees ($)</TranslateComponent>,
   key: 'fees',
   columnType: 'number',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
 {
   title: <TranslateComponent>Total Water Cost ($)</TranslateComponent>,
   key: 'totalCost',
   columnType: 'number',
   maxWidth: '100px',
   billType:"water",
   canSort: false
 },
]


export const getExcelHeaders = () => {
  let commonHeaders = []
  commonHeaders.push({ displayName: "Year", propertyName: "year" })
  commonHeaders.push({ displayName: "Month", propertyName: "month" })

  const combinedHeader = [...nameHeadingsElectricBill, ...nameHeadingsGasBill, ...nameHeadingsWaterBill]
  const combinedHeadersList =  combinedHeader.map(x=> {
    return {
      displayName: x.title,
      propertyName: x.key,
      billType: x.billType
    }
  })
  const headersList = combinedHeadersList?.filter(x => !(x.displayName === '' || x.propertyName === '' || x.propertyName === 'year' || x.propertyName === 'month'))
  
  return [...commonHeaders, ...headersList]
}







