import React from "react"
import Button from "src/denali-ui/components/Button"
import TraneTheme from 'src/denali-ui/components/ThemeProvider'

import { Image1, Image2, BackgroundBrandingContainer, Panel, AzurePageMimicHeader, AzurePageMimicBodyText, ButtonWrapper } from "./termsAndConditions"
import { TranslateComponent } from 'src/common/translations'


const UnauthorizedUserScreen = ({ logoutHandler, textDescription, textHeader, customButtons }) => (
		<TraneTheme>
			<BackgroundBrandingContainer>
				<Image1 id="background_background_image" data-tenant-branding-background="true" src="https://corpextdev.blob.core.windows.net/root/images/leftImage.jpg" alt="Illustration" aria-hidden="true" />
				<Image2 id="background_background_image_float" src="https://corpextdev.blob.core.windows.net/root/images/TraneLogoCircles.svg" aria-hidden="true" />
			</BackgroundBrandingContainer>
			<Panel>
				<AzurePageMimicHeader>
					<TranslateComponent>{textHeader ? textHeader : "No Trane Connect Account Found"}</TranslateComponent>
				</AzurePageMimicHeader>
				<AzurePageMimicBodyText>
					{textDescription ? textDescription : "We were unable to log you into Trane Connect because there is no Trane Connect account associated with the email you provided. Please contact your Trane Account Manager for further assistance."}
				</AzurePageMimicBodyText>
				<ButtonWrapper style={{ "text-align": "start" }}>
					{customButtons ? customButtons : <Button variant="primary" size="medium" onClick={() => logoutHandler()}>
						Logout
					</Button>}
				</ButtonWrapper>
			</Panel>
		</TraneTheme>
)


export default UnauthorizedUserScreen

