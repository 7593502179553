import styles from 'src/denali-pages/Opportunities/opportunities.module.scss'
import { CardImpact } from 'src/denali-components/Cards/CardImpact/CardImpact'
import { TranslateComponent } from 'src/common/translations'

export const DetailImpact = ({
  opportunity
}) => {
  return (
    <div className={styles.detailColumn} >
      <span className={styles.detailHeading}><TranslateComponent>Impact</TranslateComponent></span>
      <div className={styles.detailRow}>
        {opportunity.settings?.kpi?.filter(kpi => kpi.value).map((kpi, index) => (
          <CardImpact
            key={index}
            type={kpi.name}
            impact={kpi.priority}
            count={opportunity.settings?.kpi?.filter(kpi => kpi.value).length}
          />
        ))}
      </div>
    </div>
  )
}
