import { type ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'src/hooks/APIHooks'
import { LIST_OFFERINGS_BY_BUILDING } from 'src/pages/contracts-and-offerings/queries'
import { getBuildingOfferings, selectUiMode } from 'src/redux/slicers/appData'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'
import { Loading } from '../Table/Loading'
import { UiMode } from 'src/redux/types/AppTypes'

type RouteBuildingOfferingsWrapperProps = {
  children: ReactNode
}

export const RouteBuildingOfferingsWrapper = ({
  children
}: RouteBuildingOfferingsWrapperProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const selectedFromRedux = useSelector(selectBuilding)
  const [lastReduxId, setLastReduxId] = useState(selectedFromRedux?.id)
  const { refetch: refetchBuildingOfferings } = getBuildingOfferingsQuery()
  const uiMode = useSelector(selectUiMode)
  const isControlled = checkIsControlled(uiMode)

  useEffect(() => {
    if (!isControlled) {
      setIsLoading(false)
      return
    }

    const { id, type } = selectedFromRedux || {}
    const reduxBuildingId = type === 'location' ? id : null      

    if (reduxBuildingId && lastReduxId !== reduxBuildingId) {
      /**
       * Update Redux building offerings when buildingId changes for src/common/featureRestrictionHOC.js
       * See getBuildingOfferings in src/components/buildingNavigation/building-navigation.tsx
       *
       * Cache initial value because:
       * - When navigating by links the value will be the same and offerings to not need to be updated.
       * - On first page load init will be null until the building is loaded, then offerings will update.
       * - When picking a different building, init will be different and offerings will update.
       */
      setIsLoading(true)
      getBuildingOfferings(
        reduxBuildingId,
        refetchBuildingOfferings,
        dispatch
      ).then(() => {
        setIsLoading(false)
        setLastReduxId(id)
      })
      return
    }

    setIsLoading(false)
  }, [selectedFromRedux])

  return (
    <div data-testid="RouteBuildingOfferingsWrapper">
      {isLoading ? <Loading /> : <>{children}</>}
    </div>
  )
}

const getBuildingOfferingsQuery = () => {
  return useQuery({
    query: LIST_OFFERINGS_BY_BUILDING,
    disableInitialLoad: true,
    dataPath: 'data.listOfferingsByBuilding.items'
  })
}

const checkIsControlled = (uiMode: UiMode): boolean => {
  // Old UI always lets the old building picker control the Building Offerings
  if (uiMode === UiMode.denali) return true

  // let the page render immediately
  return false
}
