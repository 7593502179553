import { Heading } from '@aws-amplify/ui-react'
import styles from './page.module.scss'
import translate, { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrid2 } from '../lib/pro-solid-svg-icons'
import { Banner as DenaliBanner } from '../Banner/Banner'

export const Page = ({
  children,
  title,
  titleTools,
  icon=<FontAwesomeIcon icon={faGrid2} />,
  iconColor=IconColor.default,
  backgroundColor=BackgroundColor.default,
  actions=null,
  showHeading=true,
  testid='',
}: {
  children: React.ReactNode,
  title: string,
  titleTools?: React.ReactNode,
  icon?: React.ReactNode,
  iconColor?: IconColor,
  backgroundColor?: BackgroundColor,
  actions?: React.ReactNode,
  showHeading?: boolean,
  testid?: string,
}) => {
  const translatedBannerContent = translate('You are using the beta version of the new design, and some features may not be available yet.')
  return (
    <>
    <DenaliBanner backgroundColor={backgroundColor} content={translatedBannerContent} />
    <main className={styles.page} data-background={backgroundColor} data-testid={testid}>
    {showHeading && 
      <header>
        <div className={styles.pageIcon} data-color={iconColor}>
          {icon}
        </div>
        <div className={styles.left}>
          <Heading level={1} className={`h4 ${styles.pageHeader}`} data-testid="pageHeader">
            <TranslateComponent>{title}</TranslateComponent>
          </Heading>
          {titleTools && (
            titleTools
          )}
        </div>
        <div className={styles.right}>
          { actions }
        </div>
      </header>}
      { children }
    </main>
    </>
  )
}

export enum IconColor {
  default='default',
  purple='purple',
  blue='blue',
  orange='orange',
  lightOrange='lightorange',
}

export enum BackgroundColor {
  default='default',
  alt='alt',
  invert='invert',
  //TODO: to remove
  purple='purple'
}