import translate, { TranslateComponent } from 'src/common/translations'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { UtilityBillDataTotal } from './UtilityBillDataTotal'
import { UtilityBillDataElectric } from './UtilityBillDataElectric'
import { UtilityBillDataGas } from './UtilityBillDataGas'
import { UtilityBillDataWater } from './UtilityBillDataWater'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import {
  faGrid,
  faBolt,
  faFireFlameSimple,
  faDroplet,
  faUtilityPoleDouble
} from "src/denali-components/lib/pro-solid-svg-icons"
import { Button, Flex, Tabs, TabItem } from '@aws-amplify/ui-react'
import tabStyles from 'src/denali-components/Tabs/tabs.module.scss'
import tableStyles from 'src/denali-components/Table/table.module.scss'
import styles from './utility-bill-data.module.scss'
import ChartSettingsLink from 'src/components/legacy/chartSettingsLink'

/**
 * UtilityBillData
 * View replacement for src/pages/utilityBillData/index.jsx
 */
export const UtilityBillData = ({
  totalsHeader,
  totalEntries,
  electricHeader,
  electricEntries,
  electricTableWidth,
  gasHeader,
  gasEntries,
  gasTableWidth,
  waterHeader,
  waterEntries,
  waterTableWidth,
  filtersList,
  checkboxClick,
  onUpdateControlsFunc,
  columnOrderDown,
  listDataIsLoading,
  deleteHandler,
  downloadBillData,
  toggleUploadBillShowModal,
  actionButtonDisable,
  buildingDetails,
  buildingId,
  hasUtilityUploadAccess,
  navigateToCreateReport,
  navigateToViewChart,
  settingsConfig,
  updateLocationArea,
  errors,
  formatDollarLabel,
  totalSummary,
  uiMode
}) => {
  return (
    <Page
      title={translate('Utility Bill Data')}
      icon={<FontAwesomeIcon icon={faUtilityPoleDouble} />}
      iconColor={IconColor.lightOrange}
      testid="utility-bill-data-page"
      actions={[
        <Button
          key="view"
          className="button-gray"
          data-testid="ViewChartButton"
          ariaLabel="View Chart"
          onClick={navigateToViewChart}
        >
          View Chart
        </Button>,
        <Button
          key="create"
          data-testid="CreateReportButton"
          ariaLabel="Create Report"
          onClick={navigateToCreateReport}
        >
          Create Report
        </Button>
      ]}
    >
      <div className={tableStyles.offsetBanner}>
        <Flex justifyContent="space-between" data-testid='floorAreaCalc'>
          <span>
            {Boolean(buildingDetails?.floorArea) ||
              (buildingId && !buildingDetails?.floorArea && (
                <span>
                  The EUI and ECI cannot be calculated because this building
                  does not have a floor area set
                </span>
              ))}
            {Boolean(buildingDetails?.floorArea) && buildingId && (
              <span>
                The EUI and ECI calculations are based on the building's floor
                area of {buildingDetails?.floorArea.toLocaleString()} ft²
              </span>
            )}
          </span>
          <span>
            {hasUtilityUploadAccess && (
              <div className="blue-link" data-testid='updateFloorAreaLink'>
                <ChartSettingsLink
                  chartView={true}
                  settingsConfig={settingsConfig}
                  value={buildingDetails?.floorArea}
                  locationId={buildingId}
                  updateLocationArea={updateLocationArea}
                  uiMode={uiMode}
                >
                  Update Floor Area
                </ChartSettingsLink>
              </div>
            )}
          </span>
        </Flex>
      </div>
      <div className={tableStyles.tableWrapper}>
        <div className={styles.ytdContainer}>
          <span className={styles.ytd}>
            <span className={styles.ytdTotal}>
              {errors.totalSummaryError
                ? '$0.00 '
                : formatDollarLabel(parseFloat(totalSummary))}
            </span>
            Year to date
          </span>
        </div>
        <Tabs
          defaultIndex={0}
          testId="utility-bill-data-tabs"
          className={tabStyles.denaliTabs}
        >
          <TabItem
            title={
              <>
                <FontAwesomeIcon icon={faGrid} /> <TranslateComponent>Total</TranslateComponent>
              </>
            }
            onClick={() => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Total"})}
            className={tabStyles.denaliTabItem}
          >
            <UtilityBillDataTotal
              {...{
                totalsHeader,
                totalEntries,
                filtersList,
                checkboxClick,
                onUpdateControlsFunc,
                columnOrderDown,
                listDataIsLoading,
                deleteHandler,
                downloadBillData,
                toggleUploadBillShowModal,
                actionButtonDisable,
                errors
              }}
            />
          </TabItem>
          <TabItem
            title={
              <>
                <FontAwesomeIcon icon={faBolt} /> <TranslateComponent>Electric</TranslateComponent>
              </>
            }
            onClick={() => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Electric"})}
            className={tabStyles.denaliTabItem}
          >
            <UtilityBillDataElectric
              {...{
                electricHeader,
                electricEntries,
                electricTableWidth,
                filtersList,
                checkboxClick,
                onUpdateControlsFunc,
                columnOrderDown,
                listDataIsLoading,
                deleteHandler,
                downloadBillData,
                toggleUploadBillShowModal,
                actionButtonDisable,
                errors
              }}
            />
          </TabItem>
          <TabItem
            title={
              <>
                <FontAwesomeIcon icon={faFireFlameSimple} /> <TranslateComponent>Gas</TranslateComponent>
              </>
            }
            onClick={() => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Gas"})}
            className={tabStyles.denaliTabItem}
          >
            <UtilityBillDataGas
              {...{
                gasHeader,
                gasEntries,
                gasTableWidth,
                filtersList,
                checkboxClick,
                onUpdateControlsFunc,
                columnOrderDown,
                listDataIsLoading,
                deleteHandler,
                downloadBillData,
                toggleUploadBillShowModal,
                actionButtonDisable,
                errors
              }}
            />
          </TabItem>
          <TabItem
            title={
              <>
                <FontAwesomeIcon icon={faDroplet} /> <TranslateComponent>Water</TranslateComponent>
              </>
            }
            onClick={() => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH, {"tab":"Water"})}
            className={tabStyles.denaliTabItem}
          >
            <UtilityBillDataWater
              {...{
                waterHeader,
                waterEntries,
                waterTableWidth,
                filtersList,
                checkboxClick,
                onUpdateControlsFunc,
                columnOrderDown,
                listDataIsLoading,
                deleteHandler,
                downloadBillData,
                toggleUploadBillShowModal,
                actionButtonDisable,
                errors
              }}
            />
          </TabItem>
        </Tabs>
      </div>
    </Page>
  )
}
