import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import {
  BACKEND_DATE_FORMAT,
  utcTimestampToStringDate
} from 'src/components/legacy/common/time-helpers'
import { ChartDateControlsDiv } from './chart-date-controls'
import { handleAdjustRange, setEndDateForEnergyCostPages } from './config'
import { useEffect } from 'react'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import translate, { TranslateComponent } from 'src/common/translations'

const ENERGY_COST_PAGES = 'energy-cost'
const ENERGY_CONSUMPTION_PAGE = 'energy-consumption'
const LOAD_ANALYSIS = 'load-analysis'
const ELECTRICAL_DEMAND = 'electrical-demand'
const ENERGY_COST_RANGE_ENDDATE_CHANGES = ['1M', '3M', '6M', '1Y']

const ChartDateControls = (props) => {
  const { rangesList, selectMonth } = props
  // For energy consumption,, end date should be one day prior
  const maxEndDate =
    [ENERGY_CONSUMPTION_PAGE, LOAD_ANALYSIS].includes(props?.entity)
      ? moment().subtract(1, 'days').format(BACKEND_DATE_FORMAT)
      : moment().format(BACKEND_DATE_FORMAT)
  const defaultStartDate = [ELECTRICAL_DEMAND].includes(props?.entity) ?   
         moment()
        .subtract(1, 'month').add(1,"day")
        .format(BACKEND_DATE_FORMAT)
        :moment()
        .subtract(1, 'month')
        .format(BACKEND_DATE_FORMAT)
  const [startDate, setStartDate] = useState(
    props.startDate || defaultStartDate
  )
  const [startError, setStartError] = useState(false)
  const [endDate, setEndDate] = useState(props.endDate || maxEndDate)
  const [endError, setEndError] = useState(false)
  const [ranges, setRanges] = useState([])
  const [activeRange, setActiveRange] = useState(props?.activeRange)
  const [isRangeChangedByDate,setIsRangeChangedByDate] = useState(true)

  const optionsTranslated = {
    dateRange: translate('Date Range'),
    adjustZoom: translate('Adjust Zoom')
  }

  useEffect(() => {
    props?.onUpdate?.({ startDate, endDate, endError, startError, activeRange })
  }, [startDate, endDate, startError, endError, setActiveRange])

  useEffect(() => {
    // Set Date Range Based on Start and End Dates
    if (isRangeChangedByDate) 
      setDateRangeBasedOnDates(startDate, endDate)
  }, [ranges,startDate,endDate])
  

  const setDateRangeBasedOnDates = (startDate, endDate) => {
    if (rangesList?.length === 0) return
    let selectedRangeValue = null
    rangesList.forEach(range => {
      const rangeValue = ranges?.find(f=>f?.type === range)
      const dates = { startDate: null, endDate: null }
      if (
        props?.entity === ENERGY_COST_PAGES &&
        ENERGY_COST_RANGE_ENDDATE_CHANGES.includes(range)
      ) {
        const EnergyCostDates = setEndDateForEnergyCostPages(range)
        dates.startDate = EnergyCostDates.startDate
        dates.endDate = EnergyCostDates.endDate
      } else {
        dates.endDate = maxEndDate
        dates.startDate =  moment(rangeValue?.date).format(BACKEND_DATE_FORMAT) 
      }
      if (startDate === dates?.startDate && endDate === dates?.endDate) {        
        selectedRangeValue = { ...rangeValue, active: true }
      }
    });
    const activeRange = startError || endError ? null : selectedRangeValue ? selectedRangeValue?.type : selectedRangeValue
    setActiveRange(activeRange)
  }

  const getDerivedStateFromProps = () => {
    const maxDate = moment.utc(maxEndDate, BACKEND_DATE_FORMAT)
    const isNextDate = [ENERGY_CONSUMPTION_PAGE, LOAD_ANALYSIS, ELECTRICAL_DEMAND].includes(props?.entity)
    const updatedRanges = []
    for (const range of rangesList) {
      const rangeData = handleAdjustRange(maxDate, range, isNextDate)
      updatedRanges.push(rangeData)
    }
    setRanges(updatedRanges)
  }

  const changeRange = (date, index) => {
    try {
      const { minStartDate } = props
      setIsRangeChangedByDate(false)
      setActiveRange(ranges[index]?.type)
      const rangesArray = ranges.map((range, ind) => {
        if (ind === index) {
          return {
            ...range,
            active: true
          }
        } else {
          return {
            ...range,
            active: false
          }
        }
      })
      setRanges(rangesArray)
      setEndError(false)
      setStartError(false)

      const currentActiveRange =
        rangesArray?.find((x) => x?.active === true)?.type || '1M'
      if (
        props?.entity === ENERGY_COST_PAGES &&
        ENERGY_COST_RANGE_ENDDATE_CHANGES.includes(currentActiveRange)
      ) {
        const dates = setEndDateForEnergyCostPages(currentActiveRange)
        setEndDate(dates?.endDate)
        setStartDate(dates?.startDate)
      } else {
        setEndDate(maxEndDate)
        setStartDate(
          date
            ? index !== 0
              ? moment(date).format(BACKEND_DATE_FORMAT)
              : moment(date).format(BACKEND_DATE_FORMAT)
            : minStartDate || '1970-01-01'
        )
      }
    } catch (error) {}
  }

  const changeRangeHandler = (date, index) => () => {
    changeRange(date, index)
    trackEvent(USER_EVENTS.CHARTS.events.CHANGE_DATE_RANGE, {
      date: date,
      range: ranges[index]?.type
    })
  }

  const onStartChange = (date) => {
    const utcStartDate = utcTimestampToStringDate(date)
    setIsRangeChangedByDate(true)
    trackEvent(USER_EVENTS.CHARTS.events.CHANGE_DATE, {
      date: moment(date).format(BACKEND_DATE_FORMAT),
      mode: 'Start'
    })
    if (!endDate) {
      const dateError =
        utcStartDate > (props.endDate || maxEndDate)
      setStartError(dateError)
      setStartDate(utcStartDate)
      if (!date || dateError) {
        return
      }
      if (!dateError) {
        setEndError(dateError)
      }
    } else {
      const dateError = utcStartDate > endDate
      setStartError(dateError)
      setStartDate(utcStartDate)
      if (!date || dateError) {
        return
      }
      if (!dateError) {
        setEndError(dateError)
      }
    }
  }

  const onEndChange = (date) => {
    const utcEndDate = utcTimestampToStringDate(date)
    setIsRangeChangedByDate(true)
    trackEvent(USER_EVENTS.CHARTS.events.CHANGE_DATE, {
      date: moment(date).format(BACKEND_DATE_FORMAT),
      mode: 'End'
    })
    if (!startDate) {
      const dateError =
        utcEndDate < (props.startDate || defaultStartDate)
      setEndError(dateError)
      setEndDate(utcEndDate)
      if (!date || dateError) {
        return
      }
      if (!dateError) {
        setStartError(dateError)
      }
    } else {
      const dateError = utcEndDate < startDate
      setEndError(dateError)
      setEndDate(utcEndDate)
      if (!date || dateError) {
        return
      }
      if (!dateError) {
        setStartError(dateError)
      }
    }
  }

  React.useEffect(() => {
    getDerivedStateFromProps()
  }, [])

  const hasError = (startError || endError) && !(startError && endError)
  const errorMessage = hasError
    ? 'Start Date must be prior to End Date'
    : ''
  const translatedErrorMessage = translate(errorMessage)

  return (
    // @ts-ignore
    <ChartDateControlsDiv testName={props?.testName || ''}>
      <div className="ChartDateControlsClass">
        <div className="ranges">
          <strong>
            {optionsTranslated.adjustZoom}:&nbsp;&nbsp;&nbsp;&nbsp;
          </strong>
          {ranges &&
            ranges.map((range, index) => (
              <div
                className={
                  activeRange === range.type
                    ? 'active range-level'
                    : 'range-level'
                }
                key={range.type + index}
                onClick={changeRangeHandler(range.date, index)}
              >
                {range.type}&nbsp;&nbsp;
              </div>
            ))}
        </div>
        <div className="dates-container">
          <div className="dates">
            <div>{optionsTranslated.dateRange}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="date-picker-section">
              <ModalDatePickerInput
                hasError={startError}
                isRequired={false}
                date={startDate || props.startDate || props.defaultStartDate}
                onChange={onStartChange}
                dateType="startDate"
                disableComponentErrorMessage={true}
                disableBeforeGivenDate={props.startDisableBeforeGivenDate}
                disableAfterGivenDate={props.startDisableAfterGivenDate}
                selectMonth={selectMonth ? true : false}
                errorMessage={translatedErrorMessage}
              />
              <div className="date-picker-center">—</div>
              <ModalDatePickerInput
                hasError={endError}
                isRequired={false}
                endDateSelector={true}
                date={endDate || props.endDate || props.maxEndDate}
                onChange={onEndChange}
                dateType="endDate"
                disableComponentErrorMessage={true}
                disableBeforeGivenDate={props.endDisableBeforeGivenDate}
                disableAfterGivenDate={props.endDisableAfterGivenDate}
                selectMonth={selectMonth ? true : false}
                errorMessage={translatedErrorMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </ChartDateControlsDiv>
  )
}

ChartDateControls.propTypes = {
  rangesList: PropTypes.array.isRequired,
  onUpdate: PropTypes.func,
  selectMonth: PropTypes.bool
}

export default ChartDateControls
