import { BuildingId } from "src/components/buildingNavigation/graphql"
import { useQuery } from "src/hooks/APIHooks"
import { ListBenchmarksByBuildingResult } from "../eventMarkers/types"

export const listUtilityDataByBuilding = /* GraphQL */ `
  query listUtilityDataByBuilding($buildingId: ID!, $filter: ModelBuildingUtilityDataFilterInput, $year: ModelIntKeyConditionInput) {
    listUtilityDataByBuilding(filter: $filter, buildingId: $buildingId, year: $year, limit: 1000) {
        items {
            eci
            eui
            month
            year
            electricUtilityData {
              startDate
              endDate
              totalDemandCost
              consumptionCost
              fees
              totalBill
            }
            gasUtilityData {
              startDate
              endDate
              totalCost
            }
          }
    }
  }
`

export const listBenchMarkersByBuilding = /* GraphQL */ `
  query listBenchMarkersByBuilding($buildingId: ID!) {
    listBenchMarkersByBuilding(buildingId: $buildingId) {
      items {
        buildingId
        description
        id
        markerDate
        name
        type
        value
        updatedAt
      }
    }
  }
`
// For keeping the naming consistent with the other queries.
export const LIST_BENCHMARKS_BY_BUILDING = listBenchMarkersByBuilding

export const listBenchmarksByBuildingId = ({ buildingId } : { buildingId: BuildingId }) : ListBenchmarksByBuildingResult => useQuery({
  query: LIST_BENCHMARKS_BY_BUILDING,
  variables: { buildingId },
  disableInitialLoad: true,
  errorPolicy: 'all',
  dataPath: 'data.listBenchMarkersByBuilding.items'
})

export const CREATE_BENCH_MARK = /* GraphQL */ `
  mutation createBenchMarker($input: CreateBenchMarkerInput!) {
    createBenchMarker(input: $input) {
      id
    }
  }
`

export const UPDATE_BENCH_MARK = /* GraphQL */ `
  mutation updateBenchMarker($input: UpdateBenchMarkerInput!) {
    updateBenchMarker(input: $input) {
      id
    }
  }
`

export const DELETE_BENCH_MARK = /* GraphQL */ `
  mutation deleteBenchMarker($input: DeleteBenchMarkerInput!) {
    deleteBenchMarker(input: $input) {
      id
    }
  }
`