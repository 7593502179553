import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ACTIONS } from 'src/constants/actions'
import moment from 'moment'
import { TEXT_DATE_TIME_FORMAT_WITH_SECONDS } from './time-helpers'
import { UiMode } from 'src/redux/types/AppTypes'
import styles from 'src/denali-pages/Reports/reports.module.scss'
import { FileBadge } from 'src/denali-components/File/FileBadge'
import dialogStyles from 'src/denali-components/Dialog/dialog.module.scss';

// components
import ClientSideTable from 'src/components/Table/clientSideTable'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip.jsx'
import ProgressBar from '../legacy/components/progress-bar/progress-bar'
import { convertSizeToUnits } from 'src/components/legacy/components/file-select-sc/file-select.sc.jsx'
import translate from '../../common/translations'
import { Button, Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "src/denali-components/lib/pro-solid-svg-icons"
import { getDateFormatByLocale } from '../../common/chartHelperFunctions'

const DescriptionColumnComponent = (props) => {
  const [t] = useTranslation()
  const {
    fileId,
    showDownloadButton,
    isDownloadEnabled,
    showCloseIcon,
    fileName,
    description = '',
    isNew = false,
    isEditable = false,
    loading,
    error,
    onDeleteAttachment,
    onCancelUploadAttachment,
    onChange,
    mode,
    downloadFileFunc,
    uiMode
  } = props
  const showCancel = typeof onCancelUploadAttachment === 'function'

  if (uiMode === UiMode.denali) {
    return (
      <div className={styles.attachmentDescriptionField}>
        <Input
          className={dialogStyles.denaliInput}
          name="description"
          placeholder="File Description"
          type="text"
          value={description}
          onChange={(e) => onChange({ fileName, value: e.target.value })}
        />
        <Button className='button-red button-icon' onClick={() => onDeleteAttachment(fileId, fileName)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    )
  }

  return isNew || (isEditable && ACTIONS.VIEW !== mode) ? (
    <div className="description-wrapper">
      {!error &&
        (loading ? (
          <div style={{ width: '200px' }}>
            <ProgressBar progress={1} />
          </div>
        ) : (
          <input
            className="description-box"
            name="description"
            type="text"
            value={description}
            onChange={(e) => onChange({ fileName, value: e.target.value })}
          />
        ))}
      <button
        type="button"
        className={classNames('icon-button', 'icon-trash2', {
          hidden: loading
        })}
        onClick={() => onDeleteAttachment(fileId, fileName)}
      />
      {showCloseIcon && (showCancel || loading) && (
        <button
          type="button"
          className="icon-button icon-close-circle"
          onClick={() => onCancelUploadAttachment(fileId, fileName)}
        />
      )}
    </div>
  ) : (
    <div className={`${showDownloadButton ? 'doc-download' : ''}`}>
      {description}
      {showDownloadButton && (
        <button
          className="action primary download-button"
          disabled={isDownloadEnabled}
          onClick={(e) => downloadFileFunc(e)}
        >
          <span>{t('common:Download')}</span>
          <span className="icon icon-download" />
        </button>
      )}
    </div>
  )
}

DescriptionColumnComponent.propTypes = {
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  description: PropTypes.string,
  isNew: PropTypes.bool,
  isEditable: PropTypes.bool,
  loading: PropTypes.bool,
  onDeleteAttachment: PropTypes.func,
  onCancelUploadAttachment: PropTypes.func,
  onChange: PropTypes.func,
  mode: PropTypes.string,
  showDownloadButton: PropTypes.bool,
  isDownloadEnabled: PropTypes.bool,
  showCloseIcon: PropTypes.bool
}

const AdditionalRowRendererComponent = ({ error, tooltipRenderNode }) => {
  const [t] = useTranslation()

  return (
    <ConditionalTooltip
      type={ConditionalTooltip.TYPE.ICON}
      align={ConditionalTooltip.ALIGN.CENTER}
      content={error}
      {...(tooltipRenderNode && { renderNode: tooltipRenderNode })}
    >
      <span
        className={classNames('icon-button', 'icon-error', { hidden: !error })}
      />
      <span className="error-text">
        {t('components:multipleFileUpload>ErrorHasOccurred', { error })}
      </span>
    </ConditionalTooltip>
  )
}

AdditionalRowRendererComponent.propTypes = {
  error: PropTypes.string,
  tooltipRenderNode: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(Element)
  ])
}

export const SupportingDocuments = (props) => {
  const dateTimeColumnComponent = (item) => {
    const { attachedAt } = item
    return moment(attachedAt).format(getDateFormatByLocale(TEXT_DATE_TIME_FORMAT_WITH_SECONDS))
  }

  const nameColumnComponent = (item) => {
    const { title } = item
    const { tooltipRenderNode, downloadFileFunc } = props
    return (
      <ConditionalTooltip
        type={ConditionalTooltip.TYPE.ICON}
        conditional={true}
        content={title}
        {...(tooltipRenderNode && { renderNode: tooltipRenderNode })}
      >
        <a onClick={() => downloadFileFunc(item.fileName)} data-testid="file-download">{title}</a>
      </ConditionalTooltip>
    )
  }

  const sizeColumnComponent = (item) => {
    const { fileSize } = item
    const { tooltipRenderNode } = props
    return (
      <ConditionalTooltip
        type={ConditionalTooltip.TYPE.ICON}
        conditional={true}
        children={fileSize ? convertSizeToUnits(fileSize) : ''}
        content={fileSize ? convertSizeToUnits(fileSize) : ''}
        {...(tooltipRenderNode && { renderNode: tooltipRenderNode })}
      />
    )
  }

  const descriptionColumnComponent = (item) => {
    const {
      fileId,
      fileName,
      fileUrl,
      filePath,
      description,
      isNew,
      isEditable,
      loading,
      error
    } = item
    const {
      onDeleteAttachment,
      onCancelUploadAttachment,
      mode,
      showDownloadButton,
      isDownloadEnabled,
      showCloseIcon,
      downloadFileFunc
    } = props

    return (
      <DescriptionColumnComponent
        mode={mode}
        fileId={fileId}
        fileName={fileName}
        description={description}
        downloadFileFunc={(e) => downloadFileFunc(e)}
        isNew={isNew}
        isEditable={isEditable}
        loading={loading}
        error={error}
        onDeleteAttachment={onDeleteAttachment}
        onCancelUploadAttachment={onCancelUploadAttachment}
        onChange={(e) => {
          props.onDescriptionChange(e)
        }}
        fileUrl={fileUrl || filePath}
        showDownloadButton={showDownloadButton}
        isDownloadEnabled={isDownloadEnabled}
        showCloseIcon={showCloseIcon}
        uiMode={props.uiMode}
      />
    )
  }

  const { data, t, fieldsToDisplay, onDeleteAttachment } = props
  const headings = [
    {
      name: 'timestamp',
      title: translate('Date and Time'),
      key: 'timestamp',
      maxWidth: '120px'
    },
    {
      name: 'fileName',
      title: translate("File Name"),
      key: 'fileName',
      maxWidth: '120px',
      sortField: "fileNameSortField"
    },
    {
      name: 'fileSize',
      title: translate('File Size'),
      key: 'fileSize',
      maxWidth: '100px',
      sortField: "fileSizeSortField"
    },
    {
      name: 'description',
      title: translate('File Description'),
      key: 'description',
      maxWidth: '140px',
      sortField: "descriptionSortField"
    }
  ].filter((heading) => fieldsToDisplay.includes(heading.key))

  if (props.uiMode === UiMode.denali) {
    return (
      <div className={styles.documentModalAttachments}>
        {data.map((item) => {
          return (
            <FileBadge
              key={`${item?.id}-${item.fileName}`}
              fileId={item?.id}
              fileFullName={item.fileName}
              name={item.title}
              buildingId={item.buildingId}
              fileSize={''} // Not available in the data until after submission
              description={item.description}
              date={moment(item.attachedAt).format('LLL')}
              showDate={true}
              showDescription={false}
              showPreviewButton={false}
              downloadButton={''}
              deleteFile={true}
              deleteFunc={props.onDeleteAttachment}
            />
          )
        })}
      </div>
    )
  }

  return (
    <ClientSideTable
      header={headings}
      rows={data.map((item) => {
        return {
          ...item,
          fileNameSortField: item?.fileName,
          fileName: nameColumnComponent(item),
          timestamp: dateTimeColumnComponent(item),
          fileSize: sizeColumnComponent(item),
          fileSizeSortField: item?.fileSize?.toString(),
          description: descriptionColumnComponent(item),
          descriptionSortField: item?.description
        }
      })}
      fixedLayout={false}
      tableClassName="dateCapitalize"
    />
  )
}

const convertBytesToString = (bytes, iterations=1) => {
  if(iterations > 4) return `1000+ GB`;

  // Technically this is converting to Kibibyte (KiB) by dividing using 1024 (base 2) not Kilobyte (KB) using 1000 for base 10
  // This is very common and doesn't need to be precise so matching the old component's formula.
  const reduce = bytes / 1024;

  const suffixes = ['Bytes', 'KB', 'MB', 'GB'];
  return reduce < 1
    ? `${Math.round(bytes)} ${suffixes[iterations-1]}`
    : convertBytesToString(reduce, iterations+1)
}

SupportingDocuments.propTypes = {
  data: PropTypes.array.isRequired,
  mode: PropTypes.string,
  onDeleteAttachment: PropTypes.func,
  onCancelUploadAttachment: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  t: PropTypes.func,
  fieldsToDisplay: PropTypes.arrayOf(PropTypes.string),
  tooltipRenderNode: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(Element)
  ]),
  showDownloadButton: PropTypes.bool,
  isDownloadEnabled: PropTypes.bool,
  showCloseIcon: PropTypes.bool
}

SupportingDocuments.defaultProps = {
  fieldsToDisplay: ['timestamp', 'fileName', 'fileSize', 'description'],
  showDownloadButton: false,
  isDownloadEnabled: true,
  showCloseIcon: true
}

export default withTranslation()(SupportingDocuments)
