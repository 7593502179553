import { createContext, useContext } from "react"
import { EnergyIntensityBenchmarksAndMarkersContextType } from '../types.d'

export const EnergyIntensityBenchmarksAndMarkersContext = createContext<EnergyIntensityBenchmarksAndMarkersContextType>(null)

export const useEnergyIntensityBenchmarksAndMarkersContext = () => {
  const context = useContext(EnergyIntensityBenchmarksAndMarkersContext)

  if (context == null) {
    throw new Error('EnergyIntensityMarkers/Benchmarks components must be wrapped in <EneryIntensityBenchmarksAndMarkersProvider />')
  }

  return context
}
