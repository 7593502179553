import { useEffect, useState, useMemo } from 'react'
import EnergyConsumptionWidget from 'src/pages/widgets/widget-library/energyWidgets/consumption/EnergyConsumptionChart'
import { useQuery } from 'src/hooks/APIHooks'
import {
  formatDataForWidgetChart,
  sortKeysByDate
} from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import moment from 'moment/moment'
import {
  SimpleLegend,
  createSimpleLegendModel
} from '../../../../chart-library/CommonComponents/Legend'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import { ENERGY_CONSUMPTION_UOMS } from 'src/pages/widgets/helpers'
import {
  formatData,
  aggreagatorSelection,
  getInnerBarRecordCount
} from 'src/common/chartHelperFunctions'
import {
  BACKEND_DATE_FORMAT,
  MONTH_YEAR_FORMAT
} from 'src/components/legacy/common/time-helpers'
import { useTranslation } from 'react-i18next'
import { isValidUOMForGetChartData } from 'src/pages/dashboards/helper'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import store from 'src/redux/store';
import translate from 'src/common/translations'

export const chartDateFormat = 'YYYY/MM/DD'

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

const timeComparisionAggreagation = {
  'current-day': 'daily',
  'past-3-days': 'daily',
  'past-7-days': 'daily',
  'current-month': 'monthly',
  'compare-quarter': 'quarterly',
  'previous-quarter': 'quarterly',
  'past-3-months': 'monthly',
  'past-6-months': 'monthly',
  'current-year': 'yearly'
}

const compareTimeCompare = {
  'today-previous-day-full-compared': 'compare-day',
  'today-previous-week-full-compared': 'same-day-previous-week',
  'today-previous-month-full-compared': 'same-day-previous-month',
  'today-previous-year-full-compared': 'same-day-previous-year',
  'past-3-days-week-full-compared': 'same-day-previous-week-3',
  'past-3-days-month-full-compared': 'same-day-previous-month-3',
  'past-3-days-year-full-compared': 'same-day-previous-year-3',
  'past-7-days-week-full-compared': 'same-day-previous-week-7',
  'past-7-days-month-full-compared': 'same-day-previous-month-7',
  'past-7-days-year-full-compared': 'same-day-previous-year-7',
  'previous-month': 'compare-month',
  'current-month-previous-year-full': 'same-month-previous-year',
  'previous-previous-quarter-full-compared': 'previous-previous-quarter',
  'previous-quarter-year-full-compared': 'same-quarter-previous-year',
  'past-3-months-year-full-compared': 'past-3-months-year-compared',
  'past-6-months-year-full-compared': 'past-6-months-year-compared',
  'current-year': 'compare-year',
  'previous-year-full': 'compare-year',
  none: 'none'
}

export const EnergyConsumptionWidgetPreview = ({ widgetDetails }) => {
  const [t] = useTranslation()
  const currentDate = moment()
  .tz(widgetDetails?.timeZone || moment.tz.guess())
  .format(BACKEND_DATE_FORMAT)
  const currentSelectedPeriod = widgetDetails?.selectedTimePeriod
  const compareSelectedPeriod =
    compareTimeCompare[widgetDetails?.comparedTo] || ':none'
  const selectedAggrValue = 'sum'

  const dataAggregation =
    timeComparisionAggreagation[widgetDetails?.selectedTimePeriod] || 'daily'

  const { refetch: getChartDataForBuilding, loading: loadingChartData } =
    useQuery({
      query: GET_CHART_DATA,
      dataPath: 'data.getChartData',
      disableInitialLoad: true
    })

  const { startDate: currentPeriodStartDate, endDate: currentPeriodEndDate } =
    getStartAndEndDates(
      currentSelectedPeriod,
      false,
      null,
      widgetDetails?.timeZone
    )

  const { startDate: comparePeriodStartDate, endDate: comparePeriodEndDate } =
    getStartAndEndDates(
      compareSelectedPeriod,
      true,
      null,
      widgetDetails?.timeZone
    )
  const [widgetIntervalInfo, setWidgetIntervalInfo] = useState('')

  const [barChartData, setBarChartData] = useState({})

  const [currentPeriodChartData, setCurrentPeriodChartData] = useState(null)
  const [currentDayChartData, setCurrentDayChartData] = useState(null)
  const [comparePeriodChartData, setcomparePeriodChartData] = useState(null)
  const [previousDay15MinsData, setPreviousDay15MinsData] = useState(null)
  const [temperatureData, setTemperatureData] = useState(null)

  const TranslateCalculated = translate("Calculated")

  const checkNeedsWeatherData = (period) => {
    if (
      period === 'current' &&
      dataAggregation === 'daily' &&
      widgetDetails?.selectedTimePeriod !== 'current-day' &&
      (widgetDetails?.isShowTemperature ||
        widgetDetails?.isShowTemperature === '1')
    ) {
      return { min: ['TemperatureF'], max: ['TemperatureF'] }
    } else {
      return {}
    }
  }

  const getChartRequestBody = (startDate, endDate, period = null, isCurrentDay = false) => {
    const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
    const UOMbyProfile = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"
    const chartDataRequestFormat = {
      startDate: startDate,
      endDate: endDate,
      category: 'equipment', // equipment or building level data needed
      chartType: 'line',
      building: {
        id: widgetDetails?.buildingId,
        pId: [],
        type: ''
      },
      equipment: [
        {
          eId: [{ id: widgetDetails.equipmentId }],
          type: widgetDetails.equipmentType,
          pId:
            !isCurrentDay
              ? [
                {
                  ['sum']: [widgetDetails?.property]
                }
              ]
              : isCurrentDay ? [widgetDetails?.property] : []
        }
      ],

      baseline: {
        eId: widgetDetails.equipmentId,
        pId: []
      },
      equipmentType: widgetDetails.equipmentType,
      ...(period && {
        weather: checkNeedsWeatherData(period) || {}
      }),
      settings: [],
      buildingDateTime: '2024-01-08 23:26:43',
      systemOfMeasure: UOMbyProfile
    }
    const uom =
      ENERGY_CONSUMPTION_UOMS?.find(
        (u) => u?.displayName === widgetDetails?.unitsOfMeasure
      )?.name || ''
    if (
      isValidUOMForGetChartData(uom)
    ) {
      chartDataRequestFormat.equipment[0]['uom'] = {
        [widgetDetails?.property]: uom
      }
    }
    return chartDataRequestFormat
  }

  const getParsedChartData = async (
    startDate,
    endDate,
    selectedTimePeriod = 'current-day',
    period = 'current'
  ) => {
    const chartDataRequestFormat = getChartRequestBody(startDate, endDate, period, selectedTimePeriod === 'current-day')

    const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
    const chartData = await getChartDataForBuilding(requestBody)
    const parsedChartData = JSON.parse(chartData)
    return parsedChartData
  }

  const getCurrentDateData = async (startDate, endDate) => {
    const parsedChartData = await getParsedChartData(startDate, endDate)
    const currentPeriodData = parsedChartData?.body
    const formattedDataWithProperIndexCurrent = formatBaselineDataWithIndex(
      currentPeriodData?.data
    )

    const actualCompsumptionCurrent = formatData(
      formattedDataWithProperIndexCurrent || [],
      startDate,
      endDate,
      widgetDetails?.timeZone,
      '15m',
      null,
      false,
      true
    )
    return actualCompsumptionCurrent?.[0]?.values?.[0] ?? []
  }

  const getChartData = async (startDate, endDate, period) => {
    try {
      if (widgetDetails.equipmentId) {
        const parsedChartData = await getParsedChartData(
          startDate,
          endDate,
          widgetDetails?.selectedTimePeriod,
          period
        )
        if (period === 'current') {
          setCurrentPeriodChartData(parsedChartData?.body)
        } else if (period === 'compare') {
          setcomparePeriodChartData(parsedChartData?.body)
        } else {
          setPreviousDay15MinsData(parsedChartData?.body)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCurrentDayData = async (startDate, endDate) => {
    const chartDataRequestFormat = getChartRequestBody(startDate, endDate, null, true)
    const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
    const chartData = await getChartDataForBuilding(requestBody)
    const parsedChartData = JSON.parse(chartData)
    setCurrentDayChartData(parsedChartData?.body)
  }

  useEffect(() => {
    (async () => {
      // Get Current Day Data
      if (!['current-day', 'previous-quarter'].includes(widgetDetails?.selectedTimePeriod)) {
        await getCurrentDayData(currentDate, currentDate)
      }
      await getChartData(
        currentPeriodStartDate,
        currentPeriodEndDate,
        'current'
      )
      footerIntervalInfo()
      if (widgetDetails?.comparedTo !== ':none') {
        await getChartData(
          comparePeriodStartDate,
          comparePeriodEndDate,
          'compare'
        )
      }

      if (
        ['past-3-days', 'past-7-days'].includes[
          widgetDetails?.selectedTimePeriod
        ]
      ) {
        const previousDay = moment().subtract(1, 'days').format(chartDateFormat)
        await getChartData(previousDay, previousDay, 'compare')
      }
    })()
    return () => {
      // this now gets called when the component unmounts
    }
  }, [widgetDetails])

  const getAverageValue = (data, dataLength) => {
    try {
      const sumValue = data?.reduce((sum, { value = 0 }) => {
        const timeValue = value === null ? 0 : value
        return sum + Number(parseFloat(timeValue).toFixed(2))
      }, 0)
      return Number(sumValue / dataLength || 0).toFixed(2)
    } catch (error) {
      return 0
    }
  }

  const getPlotLinesData = (data, dataLength = null) => {
    if (widgetDetails?.benchMarker?.includes('constant')) {
      return {
        plotLableName: widgetDetails?.benchMarkerLabel || '',
        plotLineValue: widgetDetails?.benchMarkerValue || 0,
        plotLineColor: widgetDetails?.benchmarkColor
      }
    } else if (widgetDetails?.benchMarker?.includes('appropriate')) {
      return {
        plotLableName: TranslateCalculated,
        plotLineValue: getAverageValue(data, dataLength),
        plotLineColor: widgetDetails?.benchmarkColor
      }
    } else {
      return null
    }
  }


  const renderBarChartElement = (data, dt, format, barColor, name = null) => {
    const aggregateValue =
      data
        ?.filter(
          (f) =>
            moment(f?.timeStamp).format(
              format === 'YYYY'
                ? format
                : format === 'MMM'
                ? MONTH_YEAR_FORMAT
                : BACKEND_DATE_FORMAT
            ) ===
            moment(dt).format(
              format === 'YYYY'
                ? format
                : format === 'MMM'
                ? MONTH_YEAR_FORMAT
                : BACKEND_DATE_FORMAT
            )
        )
        ?.map((m) => m?.value ?? 0)
        ?.reduce((sum, a) => sum + a, 0) ?? 0
    return {
      displayName: name ? name : moment(dt)?.format(format),
      name: name ? name : dt,
      value: aggregateValue,
      formatDt:
        format === 'YYYY'
          ? moment(dt)?.format('YYYY')
          : format === 'MMM'
          ? moment(dt)?.format('MMM, YYYY')
          : moment(dt)?.format('MM/DD'),
      color: barColor
    }
  }

  const getDaysArray = (start, end, period = 'days') => {
    const result = []
    const startDate = moment(start)
    const endDate = moment(end)
    switch (period) {
      case 'days':
        for (
          const arr = [], dt = new Date(startDate);
          dt <= new Date(endDate);
          dt.setDate(dt.getDate() + 1)
        ) {
          arr.push(moment(new Date(dt)).format(BACKEND_DATE_FORMAT))
          result.push(moment(new Date(dt)).format(BACKEND_DATE_FORMAT))
        }
        break
      case 'months':
        if (startDate <= endDate) {
          var date = startDate.startOf('month')

          while (date < endDate.endOf('month')) {
            result.push(date.format(BACKEND_DATE_FORMAT))
            date.add(1, period)
          }
        }
        break
      case 'years':
        if (startDate < endDate) {
          var date = startDate.startOf('year')

          while (date < endDate.endOf('year')) {
            result.push(date.format(BACKEND_DATE_FORMAT))
            date.add(1, period)
          }
        }
        break
    }

    return result
  }

  const getQuarterName = (date, isQuarterPeriod) => {
    return isQuarterPeriod
      ? `Q${moment(date).quarter()} ${moment(date).format('YYYY')}`
      : ''
  }
  const renderInsideBarChartElement = (
    currentDataSize,
    data,
    dt,
    format,
    barColor,
    name = null,
    displayName = null,
    isGroupBar = false
  ) => {
    const aggregateValue =
      data
        ?.filter(
          (f) =>
            moment(f?.timeStamp).format(
              format === 'YYYY'
                ? format
                : format === 'MMM'
                ? MONTH_YEAR_FORMAT
                : BACKEND_DATE_FORMAT
            ) ===
            moment(dt).format(
              format === 'YYYY'
                ? format
                : format === 'MMM'
                ? MONTH_YEAR_FORMAT
                : BACKEND_DATE_FORMAT
            )
        )
        ?.slice(0, currentDataSize)
        ?.map((m) => Number(m?.value ?? 0))
        ?.reduce((sum, a) => sum + a, 0) ?? 0
    return isGroupBar
      ? {
          displayName: displayName
            ? displayName
            : name
            ? name
            : moment(dt)?.format(format),
          name: name ? name : dt,
          values: [
            { value: aggregateValue, color: barColor },
            { value: 0, color: barColor, isSkip: true }
          ]
        }
      : {
          displayName: displayName
            ? displayName
            : name
            ? name
            : moment(dt)?.format(format),
          name: name ? name : dt,
          value: aggregateValue,
          color: barColor
        }
  }

  const calculateSingleBarData = async (
    selectedTimePeriod,
    compareSelectedTimePeriod,
    chartDataCurrent,
    comparePeriodData
  ) => {
    let barChartData = []
    let insideBarData = []
    // const seletcedCompareTimePeriodValue = compareTimeCompare[compareSelectedTimePeriod]
    const isMonthPeriod = ['current-month'].includes(selectedTimePeriod)
    const isYearPeriod = ['current-year'].includes(selectedTimePeriod)
    const isQuarterPeriod = ['previous-quarter'].includes(selectedTimePeriod)
    const period =
      isYearPeriod || isQuarterPeriod
        ? 'years'
        : isMonthPeriod
        ? 'months'
        : 'days'
    const formatter =
      isYearPeriod || isQuarterPeriod ? 'YYYY' : isMonthPeriod ? 'MMM' : 'ddd'
    // Get Date Range Based On Current Time Period
    const tiemPeriodDateRange = getDaysArray(
      currentPeriodStartDate,
      currentPeriodEndDate,
      period
    )
    // Get Start & End Dates Based On Configuration Period Selection
    if (compareSelectedTimePeriod !== ':none') {
      // Get Date Range Based On Comparison Time Period
      const compareDateRange = getDaysArray(
        comparePeriodStartDate,
        comparePeriodEndDate,
        period
      )
      // Get Quarter Name
      const quarterName = getQuarterName(
        comparePeriodStartDate,
        isQuarterPeriod
      )
      // Push Comparison Element, If Having Proper Comparison Data
      const timeCount = getInnerBarRecordCount(
        selectedTimePeriod,
        currentPeriodStartDate,
        currentPeriodEndDate,
        widgetDetails?.timeZone
      )
      for (const dateRange of compareDateRange) {
        const barElement = renderBarChartElement(
          comparePeriodData,
          dateRange,
          formatter,
          widgetDetails?.leftBarColor,
          quarterName
        )
        barChartData.push(barElement)
        // Form Data For Inner Bar
        const innerBarDt = compareDateRange[compareDateRange?.length - 1]

        if (innerBarDt && comparePeriodData?.length > 0) {
          const data =
            selectedTimePeriod === 'current-day'
              ? await getCurrentDateData(
                  moment(innerBarDt).format(BACKEND_DATE_FORMAT),
                  moment(innerBarDt).format(BACKEND_DATE_FORMAT)
                )
              : comparePeriodData
          const insideBarElement = renderInsideBarChartElement(
            timeCount,
            data,
            innerBarDt,
            formatter,
            widgetDetails?.toDateBarColor,
            barElement.name,
            barElement.displayName
          )
          insideBarData.push(insideBarElement)
        }
      }
    }
    // Get Quarter Name
    const quarterName = getQuarterName(currentPeriodStartDate, isQuarterPeriod)
    // Push Time Period Data
    tiemPeriodDateRange?.forEach((date) => {
      const barElement = renderBarChartElement(
        chartDataCurrent,
        date,
        formatter,
        widgetDetails?.rightBarColor,
        quarterName
      )
      barChartData.push(barElement)
    })
    return { barChartData, insideBarData }
  }

  const calculateGroupBarData = async (
    selectedTimePeriod,
    compareSelectedTimePeriod,
    chartDataCurrent,
    comparePeriodData
  ) => {
    let barChartData = []
    let insideBarData = []
    // Get Start & End Dates Based On Configuration Period Selection
    const isMonthPeriod = ['past-3-months', 'past-6-months'].includes(
      selectedTimePeriod
    )
    const isDaysPeriod = ['past-3-days', 'past-7-days'].includes(
      selectedTimePeriod
    )
    const formatter = isDaysPeriod ? 'ddd' : isMonthPeriod ? 'MMM' : 'ddd'
    const period = isDaysPeriod ? 'days' : isMonthPeriod ? 'months' : 'days'
    // Get Date Range Based On Current Time Period
    const tiemPeriodDateRange = getDaysArray(
      currentPeriodStartDate,
      currentPeriodEndDate,
      period
    )
    tiemPeriodDateRange?.forEach((date) => {
      // Get Aggregate Value For Particular Date
      const barElement = renderBarChartElement(
        chartDataCurrent,
        date,
        formatter,
        widgetDetails?.rightBarColor
      )
      barChartData.push(barElement)
    })
    // Making Bar Chart Data Based On  Current Date Configuration
    barChartData = barChartData?.map((m) => ({
      displayName: m?.displayName,
      name: m?.name,
      values: [
        {
          value: m?.value,
          color: m?.color,
          name: m?.formatDt,
          isSingleBarTooltip: true
        }
      ]
    }))

    if (compareSelectedTimePeriod !== ':none') {
      // Get Date Range Based On Comparison Time Period
      const compareDateRange = getDaysArray(
        comparePeriodStartDate,
        comparePeriodEndDate,
        period
      )
      const compareDataValue = []
      const timeCount = getInnerBarRecordCount(
        selectedTimePeriod,
        currentPeriodStartDate,
        currentPeriodEndDate,
        widgetDetails?.timeZone
      )
      compareDateRange.forEach(async (dateRange, ind) => {
        const currentBarData = barChartData[ind] ?? null
        // Get Aggregate Value For Particular Date
        const barElement = renderBarChartElement(
          comparePeriodData,
          dateRange,
          formatter,
          widgetDetails?.leftBarColor,
          currentBarData?.displayName
        )
        compareDataValue.push(barElement)
        // Push Comparison Element, If Having Proper Comparison Data
        barChartData?.forEach((element) => {
          if (element?.displayName === barElement?.displayName) {
            element.values = [
              {
                value: barElement?.value,
                color: barElement?.color,
                name: barElement?.formatDt,
                isSingleBarTooltip: true
              },
              ...element?.values
            ]
          }
        })
        // Form Data For Group Inner Bar
        const innerBarDt = compareDateRange[compareDateRange?.length - 1]
        if (
          compareDateRange?.length - 1 === ind &&
          comparePeriodData?.length > 0
        ) {
          const data = isDaysPeriod
            ? await getCurrentDateData(
                moment(innerBarDt).format(BACKEND_DATE_FORMAT),
                moment(innerBarDt).format(BACKEND_DATE_FORMAT)
              )
            : comparePeriodData
          const insideBarElement = renderInsideBarChartElement(
            timeCount,
            data,
            innerBarDt,
            formatter,
            widgetDetails?.toDateBarColor,
            currentBarData?.name,
            currentBarData?.displayName,
            true
          )
          insideBarData.push(insideBarElement)
        }
      })
      // Push Empty Element, If Doesn't Have Comparison Data
      barChartData?.forEach((element) => {
        if (
          !compareDataValue
            ?.map((m) => m?.displayName)
            ?.includes(element?.displayName)
        ) {
          element.values = [
            {
              value: 0,
              color: widgetDetails?.leftBarColor,
              isSingleBarTooltip: true
            },
            ...element?.values
          ]
        }
      })
    }
    return { barChartData, insideBarData }
  }

  const calculateAreaChartData = (temperatureData) => {
    const result = []
    temperatureData?.values?.map((item) => {
      item?.forEach((a, i) => {
        result.push({ x: i, values: a?.value, tempDate: a?.timeStamp })
      })
    })
    return result
  }

  const formatBarChartData = async (
    chartDataCurrent,
    comparecomparePeriodData,
    previousdayData,
    temperatureData
  ) => {
    const selectedPeriod = widgetDetails?.selectedTimePeriod
    const selectedComparePeriod = widgetDetails?.comparedTo
    let barData = []
    let dataLength = null
    let groupBarData = []
    if (
      [
        'current-day',
        'current-month',
        'current-year',
        'previous-quarter'
      ].includes(selectedPeriod)
    ) {
      barData = await calculateSingleBarData(
        selectedPeriod,
        selectedComparePeriod,
        chartDataCurrent,
        comparecomparePeriodData
      )
      dataLength = 1
    }
    if (
      ['past-3-days', 'past-7-days', 'past-3-months', 'past-6-months'].includes(
        selectedPeriod
      )
    ) {
      groupBarData = await calculateGroupBarData(
        selectedPeriod,
        selectedComparePeriod,
        chartDataCurrent,
        comparecomparePeriodData
      )
      dataLength = groupBarData?.barChartData?.length
    }
    const plotLineDetails = getPlotLinesData(chartDataCurrent, dataLength)
    const areaChartDetails =
      ['past-3-days', 'past-7-days'].includes(selectedPeriod) &&
      (widgetDetails?.isShowTemperature === '1' ||
        widgetDetails?.isShowTemperature === true)
        ? calculateAreaChartData(temperatureData) ?? []
        : []

    const EnergyConsumptionChartData = {
      measurement: widgetDetails?.symbol,
      groupBarData: [],
      groupInsideBarData: [],
      barData: [],
      insideBarData: [],
      plotLineData: plotLineDetails,
      areaData: areaChartDetails,
      widgetType: 'EnergyConsumption'
    }
    EnergyConsumptionChartData.barData = barData?.barChartData ?? []
    EnergyConsumptionChartData.groupBarData = groupBarData?.barChartData ?? []
    EnergyConsumptionChartData.insideBarData = barData?.insideBarData ?? []
    EnergyConsumptionChartData.groupInsideBarData =
      groupBarData?.insideBarData ?? []
    setBarChartData(EnergyConsumptionChartData)
  }

  // index property is missed in baseline data, so push the index props
  const formatBaselineDataWithIndex = (baselineData) => {
    const newBaselineData = {}
    for (const id in baselineData) {
      const equipmentData = baselineData[id]
      const properties = {}
      const existingPropertiesObj = equipmentData?.properties || {}
      for (const propertyKey in existingPropertiesObj) {
        const index = existingPropertiesObj[propertyKey]
        properties[propertyKey] = {
          index: index,
          name: propertyKey
        }
      }
      newBaselineData[id] = {
        properties,
        values: equipmentData?.values
      }
    }
    return newBaselineData
  }

  const getAggrValueFromAPIResponse = (apiData, startDate, endDate) => {
    const data = {}
    for (const id in apiData) {
      const equipmentData = apiData[id]
      for (const aggregator in equipmentData) {
        const inputData = {
          properties: equipmentData[aggregator].properties,
          values: sortKeysByDate(equipmentData[aggregator].values || {})
        }
        data[aggregator] =
          formatData(
            { [id]: inputData },
            startDate,
            endDate,
            widgetDetails?.timeZone,
            '1d'
          ) || []
      }
    }
    return data['sum']?.[0]?.values?.[0] || []
  }

  const formatMinMaxTemperatureData = (data) => {
    if (Object.keys(data)?.length > 0) {
      const details = {
        propertyName: 'Temperature',
        propertyKey: 'Temperature'
      }
      const temperatureValues = []
      for (const id in data) {
        const equipmentData = data[id]
        details.equipmentId = id
        const maxTempValues = equipmentData?.max?.values
        const minTempValues = equipmentData?.min?.values

        for (const date in maxTempValues) {
          const minTempValue = Number(minTempValues[date]?.[0]?.[0] || 0)
          const maxTempValue = Number(maxTempValues[date]?.[0]?.[0] || 0)
          temperatureValues.push({
            timeStamp: date,
            value: [maxTempValue, minTempValue]
          })
        }
      }
      details.values = [temperatureValues]
      return details
    }
  }

  const getCurrentDaySumValues = (data) => {
    let sum = 0
    if (data?.length === 0) return
    data?.forEach((item,ind) => {
      sum = sum + Number(item?.value ?? 0)
    })
    return [{ timeStamp: data[0]?.timeStamp, value: sum }]
  }

  useEffect(() => {
    try {
      // if either of data fetch is true, just returns -> no plotting until we get all required data

      if (
        widgetDetails?.comparedTo !== ':none' &&
        comparePeriodChartData === null &&
        currentPeriodChartData === null
      ) {
        return
      }

      let currentPeriodValues = []
      let comparePeriodValues = []
      let previousDay15MinsValues = []
      let temperatureData = null

      if (previousDay15MinsData) {
        const formattedDataWithProperIndexPreviousDay =
          formatBaselineDataWithIndex(previousDay15MinsData?.baselineData)

        const actualCompsumptionPreviousData = formatData(
          formattedDataWithProperIndexPreviousDay || [],
          moment().subtract(1, 'days')?.format(chartDateFormat),
          moment().subtract(1, 'days')?.format(chartDateFormat),
          widgetDetails?.timeZone,
          '15m'
        )
        previousDay15MinsValues =
          actualCompsumptionPreviousData?.[0]?.values?.[0]
      }

      if (currentPeriodChartData) {
        temperatureData = formatMinMaxTemperatureData(
          currentPeriodChartData?.data || {}
        )
        setTemperatureData(temperatureData || null)
      }

      let currentPeriodDataAvailable = false
      let comparePeriodDataAvailable = false

      if (currentSelectedPeriod === 'current-day') {
        if (currentPeriodChartData) {
          const formattedDataWithProperIndexCurrent =
            formatBaselineDataWithIndex(currentPeriodChartData?.data)
          const actualCompsumptionCurrent = formatData(
            formattedDataWithProperIndexCurrent || [],
            currentPeriodStartDate,
            currentPeriodEndDate,
            widgetDetails?.timeZone,
            '15m',
            null,
            false,
            true
          )
          currentPeriodDataAvailable =
            actualCompsumptionCurrent?.[0]?.isDataAvailable
          currentPeriodValues =  getCurrentDaySumValues(actualCompsumptionCurrent?.[0]?.values[0] ?? [])
        }

        if (comparePeriodChartData) {
          const formattedDataWithProperIndexCompare =
            formatBaselineDataWithIndex(comparePeriodChartData?.data)

          const actualCompsumptionCompare = formatData(
            formattedDataWithProperIndexCompare || [],
            comparePeriodStartDate,
            comparePeriodEndDate,
            widgetDetails?.timeZone,
            '15m',
            null,
            false,
            true
          )
          comparePeriodDataAvailable =
            actualCompsumptionCompare?.[0]?.isDataAvailable
          comparePeriodValues = getCurrentDaySumValues(actualCompsumptionCompare?.[0].values[0] ?? [])
        }
      } else {
        // Get Current Day Values
        let currentDayValues = []
        if(currentDayChartData){
          const formattedDataWithProperIndex =
            formatBaselineDataWithIndex(currentDayChartData?.data)

          const actualCompsumption = formatData(
            formattedDataWithProperIndex || [],
            currentDate,
            currentDate,
            widgetDetails?.timeZone,
            '15m',
            null,
            false,
            true
          )
          currentDayValues = getCurrentDaySumValues(actualCompsumption?.[0].values[0] ?? [])
        }
        currentPeriodValues = getAggrValueFromAPIResponse(
          currentPeriodChartData?.agg_data,
          currentPeriodStartDate,
          currentPeriodEndDate
        )
        if(currentDayValues?.length > 0){
          const currentValue = currentDayValues[0]
          if (currentValue) {
            currentPeriodValues = currentPeriodValues?.map(element => element.timeStamp === currentValue?.timeStamp ? { ...element, value: currentValue?.value } : element)
          }
        }
        currentPeriodDataAvailable = currentPeriodValues?.some(
          (x) => x?.value !== null
        )

        comparePeriodValues = getAggrValueFromAPIResponse(
          comparePeriodChartData?.agg_data,
          comparePeriodStartDate,
          comparePeriodEndDate
        )

        comparePeriodDataAvailable = comparePeriodValues?.some(
          (x) => x?.value !== null
        )
      }

      const isDataAvailable =
        currentPeriodDataAvailable || comparePeriodDataAvailable

      if (!isDataAvailable) {
        return
      }

      formatBarChartData(
        currentPeriodValues,
        comparePeriodValues,
        previousDay15MinsData,
        temperatureData
      )
    } catch (error) {
      console.log(error)
    }
  }, [comparePeriodChartData, currentPeriodChartData, previousDay15MinsData, currentDayChartData])

  // TODO : filled with mock. Integration is required with live data
  const simpleLegends = useMemo(() => {
    if (widgetDetails?.comparedTo !== ':none') {
      return [
        createSimpleLegendModel({
          text: `${t(`time>${`:stp/${widgetDetails?.comparedTo}`}`, {
            nsSeparator: '>'
          })}`,
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.leftBarColor, //widgetDetails?.currentDemandColor,
            size: 92
          }
        }),
        createSimpleLegendModel({
          text: `${t(`time>${`:stp/${widgetDetails?.selectedTimePeriod}`}`, {
            nsSeparator: '>'
          })}`,
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.rightBarColor, //widgetDetails?.peakDemandColor,
            size: 92
          }
        })
      ]
    }
    return []
  }, [widgetDetails?.comparedTo])

  const footerIntervalInfo = () => {
    const isMonth = [
      'current-month',
      'past-3-months',
      'past-6-months'
    ]?.includes(widgetDetails?.selectedTimePeriod)
    const isYear = ['current-year']?.includes(widgetDetails?.selectedTimePeriod)
    const isQuarter = ['previous-quarter']?.includes(
      widgetDetails?.selectedTimePeriod
    )
    const lDateFormat = getDateFormatByLocale('MMM DD, YYYY')
    const lMonthDateFormat = getDateFormatByLocale('MMM , YYYY')
    const dateFormatValue = (dt) =>
      isMonth
        ? moment(dt).format(lMonthDateFormat ? lMonthDateFormat : 'MMM , YYYY')
        : isYear
        ? moment(dt).format('YYYY')
        : isQuarter
        ? getQuarterName(dt, true)
        : moment(dt).format(lDateFormat ? lDateFormat : 'MMM DD, YYYY')
    const isShowStartDt = ![
      'current-year',
      'previous-quarter',
      'current-month'
    ]?.includes(widgetDetails?.selectedTimePeriod)
    const compareStartDate = dateFormatValue(comparePeriodStartDate)
    const currentStartDate = dateFormatValue(currentPeriodStartDate)
    const currentEndtDate = dateFormatValue(currentPeriodEndDate)
    setWidgetIntervalInfo(
      `${
        widgetDetails?.comparedTo !== ':none'
          ? `${compareStartDate} - `
          : isShowStartDt
          ? `${currentStartDate} - `
          : ''
      } ${currentEndtDate}`
    )
  }

  return loadingChartData ? (
    <Spinner />
  ) : barChartData && Object.keys(barChartData)?.length > 0 ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <EnergyConsumptionWidget data={barChartData} />
      <SimpleLegend
        className="dashboard-widget-simple-legend"
        isStandaloneLegend
        hide={simpleLegends?.length === 0}
        legends={simpleLegends}
      />
      <WidgetChartFooterComponent
        intervalInfo={widgetIntervalInfo}
        aggregatorName={selectedAggrValue}
        selectedPeriod={currentSelectedPeriod}
        aggragatorInterval={dataAggregation}
        footerInfo={`${timeComparisionAggreagation[currentSelectedPeriod]} Energy Consumption`}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={currentSelectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
      aggragatorInterval={dataAggregation}
      intervalInfo={widgetIntervalInfo}      
      footerInfo={`${timeComparisionAggreagation[currentSelectedPeriod]} Energy Consumption`}
    />
  )
}
