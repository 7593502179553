import styles from './table.module.scss'

export const Skeleton = ({ isClassic }: { isClassic: boolean}) => {
  return (
    <div className={styles.skeletonRoot} data-testid='skeletonTableRoot'  data-classic={isClassic}>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </div>
  )
}

const SkeletonRow = () => {
  return <div className={styles.skeletonRow}></div>
}