import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import _get from "lodash/get"
import _sortBy from "lodash/sortBy"
import _some from "lodash/some"
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input"
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import NotificationItem from "./notification-item"
import "./index.scss"
import "react-phone-number-input/style.css"
import _isEmpty from "lodash/isEmpty"
import { TranslateComponent } from "../../common/translations"
import { useSelector } from "react-redux"
import { selectUserInfo } from "../../redux/slicers/appData"
import { LIST_USER_NOTIFICATIONS, UPDATE_OWN_SELF } from "./notifications-settings/graphql"
import { useMutation, useQuery } from "../../hooks/APIHooks"
import { UPDATE_SUBSCRIBER} from "./notifications-settings/mutations"
import { GET_USER_SETTINGS_BY_USERID,UPDATE_USER_SETTINGS_BY_USERID,CREATE_USER_SETTINGS_BY_USERID} from 'src/common/userSettingAPI'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'


export const NotificationSettings = () => {
	const [t] = useTranslation()
	const userInfo = useSelector(selectUserInfo)
	const [loading,setLoading] = useState(true)
	const [errors, setErrors] = useState({})
	const [userSettingsId,setUserSettingsId] = useState(null)
	const [values, setValues] = useState({
		dataloss: [],
		intelligentServices: [],
		datalossEnabled: false,
		intelligentServicesEnabled: false
	})
	const [smsNumber, setSMSNumber] = useState("")
	const { onSubmit: updateOwnUser } = useMutation({
		query: UPDATE_OWN_SELF,
		dataPath: 'data'
	})

	const { onSubmit: updateNotificationSetting,  loading: updateLoading } = useMutation({
		query: UPDATE_SUBSCRIBER,
		dataPath: 'data',
	})

	const { onSubmit: updateUserSetting,loading:updateUserSettingsLoading} = useMutation({
		query: UPDATE_USER_SETTINGS_BY_USERID
	})

	const { onSubmit: createUserSetting,loading:createUserSettingsLoading} = useMutation({
		query: CREATE_USER_SETTINGS_BY_USERID,
		dataPath:"data.createUserSetting"
	})

	const {data:userSettingData,refetch:refetchUserSettings } = useQuery({
		query: GET_USER_SETTINGS_BY_USERID,
		dataPath: 'data.getUserSettingByUserId.items',
		disableInitialLoad:true
	})

	const [refetchLoading, userNotificationsRefetch, notificationsList] =
    useAPIPagination(LIST_USER_NOTIFICATIONS, 'data.listNotificationSettingByUser')

	

	useEffect(() => {
		if (smsNumber && smsNumber !== userInfo.phoneNumber) {
			if (!isValidPhoneNumber(smsNumber)) {
				setErrors({...errors, smsNumber: "Phone number is not valid."})
			} else {
				const newErrors = {...errors}
				delete newErrors.smsNumber
				setErrors(newErrors)
				updateOwnUser({requestBody: JSON.stringify({id: userInfo.id, phoneNumber: smsNumber})})
			}
		}
	}, [smsNumber])



	useEffect(() => {
		(async() => {
			if(notificationsList){
		const filteredDatalossData = notificationsList.filter(e => e.type === "dataloss")
			const filteredIntelligentServicesData = notificationsList.filter(e => e.type === "is")
			const userSettingsData = await refetchUserSettings({filter : {type : {eq : "notification"}},userId:userInfo.id})
			const userSettings = userSettingsData?.[0]
			if(userSettings){
				setUserSettingsId(userSettings.id)
				setValues({
					...values,
					dataloss: filteredDatalossData,
					intelligentServices: filteredIntelligentServicesData,
					intelligentServicesEnabled: userSettings.enableIS,
					datalossEnabled: userSettings.enableDataLoss
				})
			setLoading(false)
		}else{
			setValues({
				...values,
				dataloss: filteredDatalossData,
				intelligentServices: filteredIntelligentServicesData
			})
			setLoading(false)
		}
	}
	})()
	}, [notificationsList])


	useEffect(() => {
		if (userInfo?.id) {
			userNotificationsRefetch({userId: userInfo.id})
		}
		if (userInfo.phoneNumber) {
			setSMSNumber(userInfo.phoneNumber)
		}
	  }, [userInfo])

	return loading ? <Spinner/> : <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
	<Content
	  xlColumn={12}
	  lColumn={12}
	  mColumn={12}
	  sColumn={12}
	  border="none"
	>
	  <PageHeader
		pageTitle={<TranslateComponent>Notification Settings</TranslateComponent>}
	  />
	</Content>
	<Content
	  xlColumn={12}
	  lColumn={12}
	  mColumn={12}
	  sColumn={12}
	  border="none"
	>
		<>
		<div className="notification-header">
			<p><span>Email: &nbsp;</span>{userInfo?.email}</p>
			<p className="feedback">
				<Trans i18nKey="notifications:Feedback">
					To change your name and/or email address in Trane Connect, please fill out a support
					request ticket by <a href="#" onClick={() => zendeskHelper.openWidget()}>clicking
						here
				</a>.
				</Trans>
			</p>
			<div className="sms-input">
				<label htmlFor="sms">{t("notifications:PhoneNumber")}</label>
				<PhoneInput
					name="smsNumber"
					placeholder="+99 999 999 9999"
					value={smsNumber || ""}
					onChange={value => {
						setSMSNumber(value)
					}}
				/>
				<p className="error">{errors.smsNumber && errors.smsNumber}</p>
			</div>
		</div>
		</>
	</Content>

	{
		[
			{
				key: "intelligentServices", 
				linkText: "Set Intelligent Services Notifications Parameters",
				values: values.intelligentServices || [],
				enabled: values?.intelligentServicesEnabled,
				showSettingsLink: true,
				title: "Intelligent Services Notifications",
				text: "By allowing notification emails from Intelligent Services, you will get emailed when buildings analytics are activated, when findings or opportunities have been assigned to and more to come!",
				onToggleSwitchChange: async (index, checked, categoryKey ) => {
					if(userSettingsId){
					await updateUserSetting({input: {id:userSettingsId,enableIS: checked}})
					setValues({...values,
						intelligentServicesEnabled: checked})
				}else{
					const data = await createUserSetting({input: {type:"notification",userId:userInfo.id,enableIS: checked}})
					setUserSettingsId(data.id)
					setValues({...values,
						intelligentServicesEnabled: checked})
				}
				}
			},
			{
			key: "dataloss", 
			linkText: "Notification Parameters",
			values: values.dataloss || [],
			enabled: values?.datalossEnabled,
			showSettingsLink: true,
			title: "Building Data Collection Notification",
			text: "Receive a notification when data collection has been delayed by one hour or more. Only applies to buildings that are subscribed to Energy Performance/Building Performance, Collected Analytics Package or Digital Inspections.",
			onToggleSwitchChange: async (index, checked, categoryKey) => {
				if(userSettingsId){
				await updateUserSetting({input: {id:userSettingsId,enableDataLoss: checked}})
				setValues({...values,
					datalossEnabled: checked})
				}else{
					const data = await createUserSetting({input: {type:"notification",userId:userInfo.id,enableDataLoss: checked}})
					setUserSettingsId(data.id)
					setValues({...values,
						 datalossEnabled: checked})
					}
			}
		}
	].map(({ key, linkText, values, showSettingsLink, enabled, text, title, onToggleSwitchChange }, i) => {
			return (
				<Content
					key={key}
					xlColumn={12}
					lColumn={12}
					mColumn={12}
					sColumn={12}
					border="none"
				>
					<NotificationItem
						key={key}
						index={i}
						categoryKey={key}
						values={values}
						text={text}
						title={title}
						enabled={enabled}
						linkText={linkText}
						showSettingsLink={showSettingsLink}
						onToggleSwitchChange={onToggleSwitchChange}
						onSave={() => Promise.resolve(userNotificationsRefetch({userId: userInfo.id}))}
						disableChange={refetchLoading || updateLoading || createUserSettingsLoading || updateUserSettingsLoading}
					/>
					
				</Content>
			)
		})
		}

	<Content
	  xlColumn={12}
	  lColumn={12}
	  mColumn={12}
	  sColumn={12}
	  border="none"
	>
	
	</Content>


</Container>
}

NotificationSettings.propTypes = {
	email: PropTypes.string
}

export default NotificationSettings
