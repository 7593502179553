// A simple hook that gets the current dimensions of ref.current and returns
// that as an object.
import { RefObject, useMemo, useSyncExternalStore } from 'react'

// Simple function to subscribe to the resize window event and set the callback.
function subscribe(callback: (e: Event) => void) {
  window.addEventListener('resize', callback)
  return () => {
    window.removeEventListener('resize', callback)
  }
}

function useDimensions(ref: RefObject<HTMLElement>, props: any[] = []) {
  const dimensions = useSyncExternalStore(subscribe, () => {
    return JSON.stringify({
      width: ref.current?.offsetWidth ?? 0,
      height: ref.current?.offsetHeight ?? 0
    });
  })
  return useMemo(() => JSON.parse(dimensions), [dimensions, ...props])
}

export { useDimensions }
