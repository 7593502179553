import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import {
  ACTIONS,
  PLACEHOLDER,
  DATA_TYPES,
  DEFAULT_POSSIBLE_STATE,
  STATE_OPTIONS,
  CHARACTERISTIC_TYPES,
  DATACOLLECTIONRATE
} from './constants'
import Select from '../../../../components/legacy/components/select/select'
import StandardDialogs from '../../../../components/legacy/components/standard-dialogs'
import DateInput from '../../../../components/legacy/components/datepicker-input'
import {
  DATE_FORMAT,
  isValid
} from '../../../../components/legacy/common/time-helpers'
import './style'
import translate, { TranslateComponent } from '../../../../common/translations'
import { siUnitMapping, convertUnits } from '../../../../../src/common/UOMConversions'

export const resetToDefault = (characteristic, setFieldValue) => {
  const { dataType, possibleStatesList, targetKey } = characteristic
  setFieldValue(targetKey, {
    ...characteristic,
    possibleStatesList: [
      ...(dataType === DATA_TYPES.ENUMERATED || possibleStatesList?.length > 1
        ? possibleStatesList.map((item) => ({
            ...item,
            selected: item.default
          }))
        : [DEFAULT_POSSIBLE_STATE])
    ]
  })
}

export const generateStateKey = ({
  propertyStateValue: key,
  propertyStateName: value
}) => `${key}_${value}`

const mapPossibleStatesList = ({
  characteristicDisplayName,
  possibleStatesList,
  dataType
}) => {
  switch (characteristicDisplayName) {
    case CHARACTERISTIC_TYPES.STATE_OPTION:
      return [
        ...(possibleStatesList?.length > 0 &&
        possibleStatesList[0]?.propertyStateName
          ? STATE_OPTIONS.map((state) => ({
              ...state,
              selected: possibleStatesList.some(
                ({ propertyStateName, selected }) =>
                  propertyStateName === state.propertyStateName && selected
              )
            }))
          : STATE_OPTIONS)
      ]
    default:
      return [
        ...(dataType === DATA_TYPES.ENUMERATED || possibleStatesList?.length > 1
          ? possibleStatesList
          : [
              (possibleStatesList !== undefined && possibleStatesList[0]) ||
                DEFAULT_POSSIBLE_STATE
            ])
      ]
  }
}

export const CharacteristicItem = ({
  mode,
  characteristic,
  setFieldValue,
  errorMessage,
  equipmentType,
  testName
}) => {
  const [t] = useTranslation()
  const [confirm, setConfirm] = useState(null)

  characteristic.possibleStatesList = mapPossibleStatesList(characteristic)
  const {
    characteristicDisplayName,
    value,
    possibleStatesList,
    dataType,
    userDefinedIndicator,
    readOnly,
    uomSymbol,
    targetKey,
    isFactorySetting,
    UOM,
    unitOfMeasurement,
    targetMeasureType    
  } = characteristic

  const options = possibleStatesList.map((item) => ({
    key: generateStateKey(item),
    value: item.propertyStateName
  }))
  const isEnumerated =
    dataType === DATA_TYPES.ENUMERATED || possibleStatesList?.length > 1

  const defaultState = possibleStatesList.find((item) => item.default)
  const selectedState = possibleStatesList.find((item) => item.selected)

  const characteristicValue = isEnumerated
    ? characteristicDisplayName === DATACOLLECTIONRATE
      ? selectedState?.propertyStateName || defaultState?.propertyStateName
      : selectedState?.propertyStateName
    : possibleStatesList[0]?.propertyStateName

  /*   const systemDefault = isEnumerated
    ? !userDefinedIndicator || selectedState === defaultState
    : !userDefinedIndicator || characteristicValue === null */

  /* 
    As per the input, creationType will be appear on the UI based on the value below 

    creationType = 'User'  -> User Override
    creationType = 'Factory' ->  Factory Setting
    For Rest of type -> System Default  
    
    */
  const isFactory = isFactorySetting
  const systemDefault = !isFactorySetting && !userDefinedIndicator

  const optionsTranslated = {
    confirmText : translate("Yes"),
    cancelText : translate("No"),
    resetDescription: translate("This operation will reset the current value to its default setting."),
    resetText : translate("Are you sure you want to Reset to Default?"),
    factorySetting: translate("Factory Setting"),
    systemDefault:"System Default",
    userOverride: translate("User Override")
  }

  const handleResetClick = () => {
    setConfirm({
      title: translate("RESET ALL TO DEFAULTS"),
      text: optionsTranslated.resetText,
      description: optionsTranslated.resetDescription,
      confirmText: optionsTranslated.confirmText,
      cancelText: optionsTranslated.cancelText,
      handleConfirm: () => {
        setConfirm(null)
        resetToDefault(characteristic, setFieldValue)
      },
      handleCancel: () => {
        setConfirm(null)
      }
    })
  }
  const getCharacteristicInput = (dataType, name) => {
    switch (dataType) {
      case DATA_TYPES.ENUMERATED:
        return (
          <Select
            testName={`${testName}_${name}`}
            options={options}
            placeholder={"-- Select One --"}
            includePlaceholderOption={true}
            selectedItem={characteristicValue}
            onChange={(selectedKey) => {
              if (
                !selectedKey &&
                [
                  DATACOLLECTIONRATE,
                  CHARACTERISTIC_TYPES.STATE_OPTION
                ].includes(characteristicDisplayName)
              ) {
                return
              }
              setFieldValue(targetKey, {
                ...characteristic,
                userDefinedIndicator: true,
                possibleStatesList: [
                  ...possibleStatesList.map((item) => ({
                    ...item,
                    selected: generateStateKey(item) === selectedKey
                  }))
                ]
              })
            }}
          />
        )
      case DATA_TYPES.DATE:
        return (
          <DateInput
            testName={`${testName}_${name}`}
            date={isValid(characteristicValue, DATE_FORMAT) || ''}
            format={DATE_FORMAT}
            onChange={(value) =>
              setFieldValue(targetKey, {
                ...characteristic,
                userDefinedIndicator: true,
                possibleStatesList: [
                  {
                    ...DEFAULT_POSSIBLE_STATE,
                    propertyStateName: value,
                    propertyStateValue: value
                  }
                ]
              })
            }
          />
        )
      default:
        return (
          <div className="input-group">
            <input
              data-testid={`${testName}_${name}`}
              defaultValue={(UOM === "SI" && characteristicValue !== null && characteristicValue !== undefined) ? convertUnits(characteristicValue,unitOfMeasurement,siUnitMapping.get(targetMeasureType),targetMeasureType) : characteristicValue || ''} 
              onChange={({ target: { value } }) => {
                const inputValue = value === '' ? null : value
                setFieldValue(targetKey, {
                  ...characteristic,
                  userDefinedIndicator: true,
                  possibleStatesList: [
                    {
                      ...DEFAULT_POSSIBLE_STATE,
                      propertyStateName: UOM === "SI" ? convertUnits(inputValue,siUnitMapping.get(targetMeasureType),unitOfMeasurement,targetMeasureType): inputValue,
                      propertyStateValue: UOM === "SI" ? convertUnits(inputValue,siUnitMapping.get(targetMeasureType),unitOfMeasurement,targetMeasureType) : inputValue
                    }
                  ]
                })
              }}
            />
            {uomSymbol && <span className="uom"><TranslateComponent>{uomSymbol}</TranslateComponent></span>}
          </div>
        )
    }
  }
  return (
    <>
      <label>
        <TranslateComponent>
        {characteristicDisplayName === CHARACTERISTIC_TYPES.STATE_OPTION
          ? CHARACTERISTIC_TYPES.POSSIBLE_STATES
          : characteristicDisplayName}
          </TranslateComponent>
      </label>
      <div className="param__value" style={{ letterSpacing: '0em' }}>
        {mode === ACTIONS.VIEW || readOnly ? (
          <>
            <span>
             {isNaN(characteristicValue) ?
            <TranslateComponent>
              {dataType === DATA_TYPES.DATE
                ? isValid(characteristicValue, DATE_FORMAT) || ''
                : `${UOM === "SI" && characteristicValue && !isNaN(characteristicValue) ? parseFloat(convertUnits(characteristicValue,unitOfMeasurement,siUnitMapping.get(targetMeasureType),targetMeasureType)).toFixed(2) : characteristicValue || PLACEHOLDER} ${
                    (characteristicValue && uomSymbol) || ''
                  }`}
             </TranslateComponent>:
             <>
             {dataType === DATA_TYPES.DATE
                ? isValid(characteristicValue, DATE_FORMAT) || ''
                : `${UOM === "SI" && characteristicValue && !isNaN(characteristicValue) ? parseFloat(convertUnits(characteristicValue,unitOfMeasurement,siUnitMapping.get(targetMeasureType),targetMeasureType)).toFixed(2) : characteristicValue || PLACEHOLDER}`}
                <TranslateComponent>
                {` ${
                    (characteristicValue && uomSymbol) || ''
                  }`}
                </TranslateComponent>
             </>}
            </span>
            <span className="char-override-indicator-container">
              {isFactory ? (
                <>
                  <span className="value-type">{optionsTranslated.factorySetting}</span>
                  <button
                    type="button"
                    className={
                      'marker-icon icon-infocircle tooltip-container tooltip-container--multiline'
                    }
                  >
                    <div className="tooltip right">
                      <TranslateComponent>equipment specific values retrieved from the factory
                      sources, based on serial number</TranslateComponent>
                    </div>
                  </button>
                </>
              ) : systemDefault ? (
                <>
                  <span className="value-type">
                 <TranslateComponent>{optionsTranslated.systemDefault}</TranslateComponent>
                  </span>
                  <button
                    type="button"
                    className={
                      'marker-icon icon-infocircle tooltip-container tooltip-container--multiline'
                    }
                  >
                    <div className="tooltip right">
                    <TranslateComponent>Values that are common for this type of equipment, not
                      pulled from factory sources</TranslateComponent>
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <span className="value-type">
                  {optionsTranslated.userOverride}
                  </span>
                  <button
                    type="button"
                    className={
                      'marker-icon icon-infocircle tooltip-container tooltip-container--multiline'
                    }
                  >
                    <div className="tooltip right">
                      <TranslateComponent>Values that a user has manually entered or edited</TranslateComponent>
                    </div>
                  </button>
                </>
              )}
            </span>
          </>
        ) : (
          <div className="fields-group">
            {getCharacteristicInput(
              isEnumerated ? DATA_TYPES.ENUMERATED : dataType,
              characteristicDisplayName === CHARACTERISTIC_TYPES.STATE_OPTION
                ? CHARACTERISTIC_TYPES.POSSIBLE_STATES
                : characteristicDisplayName
            )}
            <span className="char-override-indicator-container">
              {isFactory ? (
                <>
                  <span className="value-type edit-view">{optionsTranslated.factorySetting}</span>
                </>
              ) : systemDefault ? (
                <>
                  <span className="value-type edit-view">
                    <TranslateComponent>{optionsTranslated.systemDefault}</TranslateComponent>
                  </span>
                </>
              ) : (
                <>
                  <span className="value-type">
                    <TranslateComponent>{optionsTranslated.userOverride}</TranslateComponent>
                  </span>
                </>
              )}
            </span>

            {!CHARACTERISTIC_TYPES.STATE_OPTION && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
              <a className="reset" onClick={handleResetClick}>
                {systemDefault ? '' : t('equipment-setup:ResetToDefault')}
              </a>
            )}
          </div>
        )}
        {errorMessage && <div className="error-message"><TranslateComponent>{errorMessage}</TranslateComponent></div>}
        <StandardDialogs confirm={confirm} />
      </div>
    </>
  )
}

CharacteristicItem.propTypes = {
  characteristic: PropTypes.object,
  mode: PropTypes.string,
  setFieldValue: PropTypes.func
}

export default CharacteristicItem
