import Table from '../../components/Table/clientSideTable'
import { ACTIONS } from 'src/constants'
import { useEffect } from 'react'
import { API } from 'aws-amplify'
import { SEARCH_BUILDINGS } from '../organizationManagement/createOrganization/queries/queries'

const nameHeadings = [
  {
    title: 'Group Name',
    key: 'name',
    maxWidth: '200px'
  },
  {
    title: 'Resource Type',
    key: 'resourceType',
    maxWidth: '200px'
  },
  {
    title: 'Access Type',
    key: 'accessType',
    maxWidth: '200px'
  },
]

const ListPage = ({
  responseTime,
  rows,
  setMode,
  setInput,
  reloadEvents,
  setReloadEvents,
  setDeleteId,
  setShowDeleteDialog,
  loading
}) => {
  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
    }
  }, [reloadEvents])
  return (
    <Table
      key={`Table`}
      rows={rows}
      header={nameHeadings}
      loadTime={responseTime}
      search={true}
      searchFields={['name', 'building.name']}
      showSpinner={loading}
      rowControl={[
        {
          text: 'Copy Group',
          action: async (data) => {
            const { name, description, resourceType, accessType, isActive } = data
            setMode(ACTIONS.COPY)
            await Promise.all([API.graphql({
              query: SEARCH_BUILDINGS,
              variables: {limit: 1000, filter: accessType === "Admin" ? {groupAdmin: {eq: name} } : {groupUser: {eq: name}}}
            })]).then(res => {
              setInput({
                name: 'Copy of ' + name,
                description, 
                resourceType,
                accessType,
                isActive,
                selectedBuildings: res[0]["data"].searchBuildings.items || []
              })
            })
          }
        },
        {
          text: 'Edit',
          action: async (data) => {
            const { name, description, resourceType, accessType, isActive } = data
            setMode(ACTIONS.EDIT)
            await Promise.all([API.graphql({
              query: SEARCH_BUILDINGS,
              variables: {limit: 1000, filter: accessType === "Admin" ? {groupAdmin: {eq: name} } : {groupUser: {eq: name}}}
            })]).then(res => {
              setInput({
                name,
                description, 
                resourceType,
                accessType,
                isActive,
                initialSelectedBuildings: res[0]["data"].searchBuildings.items || [],
                selectedBuildings: res[0]["data"].searchBuildings.items || []
              })
            })           
          }
        },
        {
          text: 'Delete',
          action: (data) => {
            if (data?.name) {
              setDeleteId(data?.name)
              const { name, description, resourceType, accessType, isActive } = data
              setInput({
                name,
                description,
                resourceType,
                accessType,
                isActive,
                selectedBuildings: []
              })
              setShowDeleteDialog(true)
            }
          }
        }
      ]}
    />
  )
}

export default ListPage
