import { AccessControl } from 'src/components/accessControl'
import { Link } from 'react-router-dom'
import { Button } from 'src/components/inputs/button'
import { Button as AmplifyButton } from '@aws-amplify/ui-react'
import { getUrlSearchParamValue } from 'src/components/legacy/common/helpers'
import { ACTIONS } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'

interface CreateOpportunityButtonProps {
  amplifyButton?: boolean
  title?: string
  buildingId?: string
  accountId?: string
  handleCreateOpportunityClicked: () => void
}

export const CreateOpportunityButton = ({
  amplifyButton,
  title,
  buildingId,
  accountId,
  handleCreateOpportunityClicked
}: CreateOpportunityButtonProps) => {
  return (
    <AccessControl id="tc.pages.opportunities.add">
      <Link
        to={{
          pathname: '/Opportunities/add',
          search: getUrlSearchParamValue(buildingId, accountId)
        }}
        state={{ mode: ACTIONS.ADD, id: null }}
      >
        {amplifyButton ? (
          <AmplifyButton
            data-testid={'createOpportunityButton'}
            ariaLabel={translate(title)}
            className="button"
            onClick={handleCreateOpportunityClicked}
          >
            <TranslateComponent>{title}</TranslateComponent>
          </AmplifyButton>
        ) : (
          <Button onClick={handleCreateOpportunityClicked}>
            <TranslateComponent>{title}</TranslateComponent>
          </Button>
        )}
      </Link>
    </AccessControl>
  )
}
