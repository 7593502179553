import { Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEvent, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Project imports
import { trackEvent, identifyEvent } from 'src/amplitude'
import { USER_EVENTS } from 'src/amplitude-categories'
import { AccessControl } from 'src/components/accessControl'
import { TranslateComponent } from 'src/common/translations'
import { selectBetaUser, selectUiMode, setBetaUser, setUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'

// Denali imports
import { faSparkles } from 'src/denali-components/lib/pro-solid-svg-icons'

// Local imports
import styles from './banner.module.scss'

export const Banner = ({ backgroundColor, content }) => {
  const banner = useRef(null)
  const uiMode = useSelector(selectUiMode)
  const betaUser = useSelector(selectBetaUser)
  const dispatch = useDispatch()
  const location = useLocation()
  const isChecked = (uiMode === UiMode.denali)
  const toggleUiMode = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    dispatch(setUiMode(isChecked ? UiMode.standard : UiMode.denali))
    identifyEvent({"user interface": isChecked ? 'legacy' : 'denali' })
    trackEvent(USER_EVENTS.NAVIGATION.events.CLICK_CHANGE_UI,{"changed to": isChecked ? 'legacy' : 'denali' })
  }, [dispatch, isChecked])

  const toggleBetaUser = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    identifyEvent({"beta access": betaUser ? 'disabled' : 'enabled' })
    trackEvent(USER_EVENTS.NAVIGATION.events.CLICK_CHANGE_BETA_ACCESS,{"changed to": betaUser ? 'disabled' : 'enabled' })
    dispatch(setBetaUser(!betaUser))
  }, [dispatch, betaUser])

  return (
    <aside className={`denali-banner ${styles.banner}`} ref={banner} data-testid="banner" data-background={backgroundColor}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faSparkles} width={14} height={14} size="lg"/>
          <svg width="0" height="0">
                <linearGradient id="linear-gradient" gradientTransform="rotate(100)">
                <stop stopColor="#33F0F6" offset="-0.2327" />
                <stop stopColor="#1E00FF" offset="1.5028" />
                </linearGradient>
          </svg>
        </div>
        <p className={styles.copy}>
          <TranslateComponent>{content}</TranslateComponent>
        </p>
        <Flex direction="row" justifyContent="flex-end" gap="1rem" className={styles.links}>
          {location.pathname.includes('energy-intensity') && (
            <AccessControl id="tc.beta">
              <a className={styles.betaUser} href="#" onClick={toggleBetaUser}>
                <TranslateComponent>Beta Access</TranslateComponent>
              </a>
            </AccessControl>
          )}
          <a href="#" onClick={toggleUiMode}>
            <TranslateComponent>Switch to the old design</TranslateComponent>
          </a>
        </Flex>
      </div>
    </aside>
  )
}