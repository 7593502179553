import { Dialog } from 'src/denali-components/Dialog/Dialog'
import { Button, Flex } from "@aws-amplify/ui-react";
import dialogStyles from 'src/denali-components/Dialog/dialog.module.scss';
import dropZoneStyles from 'src/denali-components/DropZone/drop-zone.module.scss'
import { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'

export const DialogFileUpload = ({
  toggleUploadBillShowModal,
  locationName,
  downloadTemplate,
  errors,
  fileData,
  fileDescription,
  onChangeUploadStatus,
  onRemoveFile,
  onDescriptionChange,
  onCloseModal,
  addFileFunc,
  uploadFile,
  loadingFile,
  uiMode
}) => {
  return (
    <Dialog
      title='Upload Utility Data'
      onClose={toggleUploadBillShowModal}
      width='759px'
      className={dialogStyles.withDivider}
      data-testid='utilityUploadModal'
    >
      <div className={dialogStyles.dialogRow}>
        <Flex direction='column'>
          <span className={dialogStyles.rowLabel}>
            <TranslateComponent>Download Template</TranslateComponent>
          </span>
          <p>
            <TranslateComponent>Use this template to format your utility bill data prior to uploading. <br/>
            Import .xlsx file of Utility Bill to {locationName ? locationName : ''}</TranslateComponent>
          </p>
        </Flex>
        <Flex direction='column'>
          <Button className='button-gray' onClick={downloadTemplate} data-testid='downloadButton' id='downloadButton'>
            <TranslateComponent>Download</TranslateComponent>
          </Button>
        </Flex>
      </div>
      <div className={dialogStyles.dialogRow}>
        <Flex direction='column' width='100%'>
          <div className={errors.duplicate ? dropZoneStyles.filesError : dropZoneStyles.attachedFiles }>
            <AttachedFiles
              attachments={fileData?.map((item) => ({
              ...item,
              isEditable: true,	
              description: fileDescription,
              fileName: item?.title,
              fileId: item?.id,
              fileSize: item?.fileSize
              }))}
              onChangeUploadStatus={(files) => {
                  onChangeUploadStatus(files)
              }}
              onDeleteAttachment={(id, name) => onRemoveFile(id, name)}
              onDescriptionChange={(e) => onDescriptionChange(e)}
              addFile={(e) => addFileFunc(e)}
              proposalFileTypes={["xlsx"]}
              showOnlyAcceptableFileDesc={true}
              fieldsToDisplay={['fileName', 'fileSize', 'description']}
              errors={errors}
              uiMode={uiMode}
            />
          </div>
        </Flex>
      </div>
      {errors?.duplicate && <div className={dropZoneStyles.duplicateError}>
        <div>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </div>
        <div>
          <p><TranslateComponent>This file contains data for months that have already been imported. <br/>
          If you would like to overwrite the overlapping data, click "Overwrite" </TranslateComponent></p>
        </div>
      </div>}
      {errors?.attachments && (
        <p className="error"><TranslateComponent>{('reports:AttachmentEmpty')}</TranslateComponent></p>
      )}
      <div className={dialogStyles.dialogRow}>
        <span className={dialogStyles.required}><TranslateComponent>* required fields</TranslateComponent></span>
        <div className={dialogStyles.actions}>
          <Button
            onClick={onCloseModal}
            className='button-gray'
          >
            <TranslateComponent>Cancel</TranslateComponent>
          </Button>
          {errors.duplicate ? 
            <Button
              onClick={()=>{ uploadFile('override') }}
              disabled={fileData.length === 0 || loadingFile}
            >
              <TranslateComponent>Overwrite</TranslateComponent>
            </Button> :
            <Button
              onClick={uploadFile}
              disabled={fileData.length === 0 || loadingFile}
            >
              <TranslateComponent>Upload</TranslateComponent>
            </Button>
          }
        </div>
      </div>
    </Dialog>
  )
}