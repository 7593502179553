import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox.jsx'
import Select from 'src/components/legacy/components/select/select.jsx'
import {
  KPI_LEVEL_LIST,
  KPI_SPECIFIC_VALUE,
  formatKPILevel
} from 'src/components/legacy/common/opportunity.js'
import { KPI_SETTINGS } from './kpi.jsx'
import { DebounceInput } from 'src/components/legacy/components/debounce-input'
import { Container, Content } from 'src/components/layouts'
import { convertStringToValidNumber,isNumberValue } from '../../../legacy/common/helpers.js'
import './kpi.scss'
import i18next from 'i18next'
import { TIME_PERIOD_OPTION_LIST_FOR_KPI_DROP_DOWN } from 'src/components/legacy/common/consultation.js'
import { TranslateComponent } from "src/common/translations"

export class KPIEdit extends PureComponent {
  static propTypes = {
    kpi: PropTypes.array.isRequired,
    showSpecificValue: PropTypes.bool,
    errors: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    containerWidth: PropTypes.number
  }

  static defaultProps = {
    errors: [],
    showSpecificValue: false
  }

  update = (value, field, index) => {
    const { kpi, onChange } = this.props

    const editedKPI = [...kpi]
    editedKPI[index] = { ...editedKPI[index], ...{ [field]: value } }

    onChange(editedKPI)
  }

  render() {
    const { kpi, errors, showSpecificValue, t, containerWidth } = this.props
    const kpiList = showSpecificValue
      ? [...KPI_LEVEL_LIST, KPI_SPECIFIC_VALUE]
      : KPI_LEVEL_LIST
    const kpiValues = kpiList.map((item) => ({
      key: item,
      value: formatKPILevel(item, t)
    }))
    const timePeriodOptions = TIME_PERIOD_OPTION_LIST_FOR_KPI_DROP_DOWN.map(
      ({ key, value }) => ({ key, value })
    )
    const kpiSettings = KPI_SETTINGS(t)
    return (
      <Container
        className="list-kpi-metric edit-mode"
        width={containerWidth}
        xlColumns={5}
        lColumns={4}
        mColumns={3}
        sColumns={2}
      >
        {kpi.map(
          ({ name, custom, value, priority, savings = '', timePeriod }, i) => {
            // saving and timePeriod only for specific value
            const customKPIError = errors.find((kpi) => kpi.custom === custom)
            const kpiError = errors.find((kpi) => kpi.name === name)
            return (
              <Content
                className={classNames(
                  'kpi-edit',
                  custom ? 'custom' : name,
                  {
                    'disabled': !value
                  }
                )}
                key={i}
                width={containerWidth}
              >
                <Checkbox
                  label={`Show ${
                    custom
                      ? "Custom"
                      : kpiSettings.get(name).name
                  } `}
                  key={custom ? "Custom" : kpiSettings.get(name).name}
                  isChecked={value}
                  id={`${name}-check-${i}`}
                  toggleCheck={() => this.update(!value, 'value', i)}
                />
                {custom && (
                  <Fragment>
                    <fieldset className={`${customKPIError ? 'invalid' : ''}`}>
                      <label className="custom-name">
                        {<TranslateComponent>Custom Metric Name</TranslateComponent>}*
                      </label>
                      <input
                        type="text"
                        name="custom"
                        value={name}
                        onChange={({ target }) =>
                          this.update(target.value, 'name', i)
                        }
                        disabled={!value}
                        maxLength="30"
                      />
                      {customKPIError && (
                        <p className="error">{customKPIError.missedName}</p>
                      )}
                    </fieldset>
                  </Fragment>
                )}
                <fieldset
                  className={`${
                    kpiError && kpiError.specificValueError && value
                      ? 'invalid'
                      : ''
                  }`}
                >
                  <label className="impact">
                    {<TranslateComponent>Impact</TranslateComponent>}*
                  </label>
                  <Select
                    options={kpiValues}
                    onChange={(priority) => {
                      this.update(priority, 'priority', i)
                      if (priority === KPI_SPECIFIC_VALUE && !timePeriod) {
                        setTimeout(() => {
                          this.update(timePeriodOptions[0].key, 'timePeriod', i)
                        }, 0)
                      }
                    }}
                    selectedItem={formatKPILevel(priority, t)}
                    isDisabled={!value}
                  />
                  {priority === KPI_SPECIFIC_VALUE && (
                    <div className="spefic-value">
                      <div>
                        <label
                          htmlFor="spefic-value-savings"
                          className="impact"
                        >
                          $
                        </label>
                        <input
                          className="spefic-value-savings"
                          id={'spefic-value-savings'}
                          name={'spefic-value-savings'}
                          value={convertStringToValidNumber(savings)}
                          onChange={({ target }) => {
                            const value = target.value
                            if (isNumberValue(value)) {
                              this.update(Number(value), 'savings',i)
                            }
                          }}
                          disabled={!value}
                        />
                      </div>
                      <Select
                        options={timePeriodOptions}
                        selectedItem={
                          timePeriodOptions?.find(t=>t?.key === timePeriod)?.value || timePeriodOptions[0].value
                        }
                        onChange={(val) => this.update(val, 'timePeriod', i)}
                        isDisabled={!value}
                      />
                    </div>
                  )}
                  {value && kpiError && (
                    <p className="error">{kpiError.specificValueError}</p>
                  )}
                </fieldset>
              </Content>
            )
          }
        )}
      </Container>
    )
  }
}

export default withTranslation()(KPIEdit)
