import { TranslateComponent } from 'src/common/translations'
import styles from './card-impact.module.scss'

interface CardImpactProps {
  impact: string
  type: string
  count: number
}

export const CardImpact = ({ impact, type, count }: CardImpactProps) => {
  return (
    <div className={styles.cardImpact} data-impact={impact} data-count={count >= 5 ? 'multiRow' : ''}>
      <span className={styles.impact}><TranslateComponent>{impact.charAt(0).toUpperCase() + impact.slice(1)}</TranslateComponent></span>
      <span className={styles.type}><TranslateComponent>{`${formatType(type)} Impact`}</TranslateComponent></span>
    </div>
  )
}

const formatType = (type) => {
  if (type === 'energyUsage') {
    return 'Energy'
  }

  return type.charAt(0).toUpperCase() + type.slice(1)
}