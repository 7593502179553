import { IAlarm } from './types'
import { useMemo } from 'react'
import { initialValues, useAlarmContext } from './AlarmContextProvider'
import ClientSideTable from 'src/components/Table/clientSideTable'
import { Button } from 'src/components/inputs/button'
import { RightSideWrapper, TableWrapper } from './styles'
import { ADD_NOTIFICATION, DELETE_SUBSCRIBER_CONFIRMATION } from './AlarmContainer'
import translate from 'src/common/translations'
import config from 'src/aws-exports.js'


const Alarms = () => {
  
  const optionsTranslated = translate("First Name")
  const optionsTranslated2 =  translate('Last Name')
  const optionsTranslated3 =  translate('Email Address')
  const optionsTranslated4 =  translate('Subscribed To')
  const optionsTranslated5 = translate('Add Subscriber')
  const optionsTranslated6 =  translate('SET ALARM NOTIFICATIONS')
  const {
    setSubscriber,
    setMode,
    buildingId,
    setConfirmationModalType,
    subscribers,
    loadingSubscribers,
    responseTime,
    selectedSubscribers,
    setSelectedSubscribers,
    testName,
    isLoading
  } = useAlarmContext()
  const SEARCH_FIELDS = [
    'firstName',
    'lastName',
    'emailId'
  ]
  
  const nameHeadings = useMemo(
    () => [
      {
        name: 'firstName',
        title: optionsTranslated,
        key: 'firstName',
        maxWidth: '75px'
      },
      {
        name: 'lastName',
        title: optionsTranslated2,
        key: 'lastName',
        maxWidth: '75px'
      },
      {
        name: 'emailId',
        title: optionsTranslated3,
        key: 'emailId',
        maxWidth: '100px'
      },
      {
        name: 'subscribedTo',
        title: optionsTranslated4,
        key: 'subscribedTo',
        canSort:false,
        // maxWidth: '100px'
      }
    ],
    [optionsTranslated, optionsTranslated2, optionsTranslated3, optionsTranslated4]
  )

const ACTIONS = {
    ADD: translate('Add'),
    VIEW: translate('View'),
    EDIT: translate('Edit'),
    COPY: translate('Copy'),
    DELETE: translate('Delete'),
    DOWNLOAD: translate('Download')
  }
  

  const addSubscriber = () => {
    setSubscriber(initialValues)
    setMode("Add")
  }
  const addNotification = () => {
    setMode(ADD_NOTIFICATION)
  }
  const selectSubscriberHandler = (sub: any) => {
    const subscribersInit = (selectedSubscribers || [])
    const isFound = subscribersInit?.find(
      (subscribe) => subscribe.id === sub.id
    )
    if (isFound) {
      const filteredSubs =  selectedSubscribers?.filter((subscribe) => subscribe?.id !== sub?.id) 
      setSelectedSubscribers(filteredSubs || [])
    } else {
      const selectedSubs = [...subscribersInit, sub];
      setSelectedSubscribers(selectedSubs)
    }
  }
  if (loadingSubscribers)
    return (
      <div className="page-loading-area">
        <span className="spinner" />
      </div>
    )
  return (
    <TableWrapper>
      <RightSideWrapper>
        <Button testName={testName} onClick={() => addSubscriber()}>
          {optionsTranslated5}
        </Button>
        <Button testName={testName}
          onClick={() => addNotification()}
          disabled={selectedSubscribers?.length === 0}
        >
          {optionsTranslated6}
        </Button>
      </RightSideWrapper>
      <ClientSideTable
      testName={testName}
        fixedLayout={false}
        key={`alarms-table-${buildingId}`}
        header={nameHeadings}
        search={true}
        rows={subscribers}
        loadTime={responseTime}
        rowCheckbox
        checkboxClick={selectSubscriberHandler}
        isShowPagination={false}
        checkboxChecked={() => {}}
        showSpinner={loadingSubscribers || isLoading}
        rowControl={data => data?.emailId !== config?.appEnvironment?.DEFAULT_SUBCRIBER_FOR_BUILDING &&  [
          {
            text: ACTIONS.EDIT,
            action: (data: IAlarm) => {
              setSubscriber(data)
              setMode("Edit")
            }
          },
          {
            text: ACTIONS.DELETE,
            action: (data: IAlarm) => {
              setSubscriber(data)
              setConfirmationModalType(DELETE_SUBSCRIBER_CONFIRMATION)
            }
          }
        ]}
        searchFields={SEARCH_FIELDS}
      />
    </TableWrapper>
  )
}

export default Alarms
