import { useEffect, useState } from 'react'

import { sortHelper } from 'src/common/helperFunctions'
import { GET_EQUIPMENT_LIST } from 'src/common/queries/datasource'
import { usePaginateQuery } from 'src/hooks/usePaginateQuery'
import { Equipment } from 'src/pages/issuesFindings/types'

// Local imports.
import { EquipmentSelectorState, EquipmentSelectorStateProps } from './types'
import { getEquipmentSelectOptions } from 'src/components/target-path/helper'

export const useEquipmentSelectorState = ({ locationId }: EquipmentSelectorStateProps) : EquipmentSelectorState => {
  const { data: equipmentsList, refetch: refetchEquipmentsList } = usePaginateQuery({
    query: GET_EQUIPMENT_LIST,
    variables: { id: locationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment'
  })
  // If locationId changes, refetch the equipments list.
  useEffect(() => {
    refetchEquipmentsList({ id: locationId })
  }, [locationId])

  // If the equipments list changes, sort it and update the equipments state.
  useEffect(() => {
    if (equipmentsList) {
      const sortedEquipmentsList = sortHelper(equipmentsList)  
      setEquipments(sortedEquipmentsList)
    }
  }, [equipmentsList])

  const [equipments, setEquipments] = useState<Equipment[]>([])
  const [selectedTisObjects, setSelectedTisObjects] = useState([])

  const equipmentMappedOptions = getEquipmentSelectOptions(
    equipments,
    selectedTisObjects
  ).map(equipment => ({
    ...equipment,
    label: equipment.value
  }))

  return { equipments: equipmentMappedOptions, selectedTisObjects, setSelectedTisObjects }
}