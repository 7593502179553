import { listBenchmarksByBuildingId } from 'src/pages/energyIntensity/graphql'
import { listEventMarkersByAccount } from 'src/pages/eventMarkers/graphql'

import { EnergyIntensityState, EnergyIntensityStateProps } from '../types.d';
import { useEffect } from 'react';

export const useEnergyIntensityState = ({ buildingId, organizationId, onMarkersUpdate, onBenchmarksUpdate }: EnergyIntensityStateProps): EnergyIntensityState => {
  // GraphQL query to fetch markers.
  const { data: markersData, refetch: refetchMarkersData } = listEventMarkersByAccount()

  // GraphQL query to fetch benchmarks.
  const { data: benchmarksData, refetch: refetchBenchmarksData } = listBenchmarksByBuildingId({ buildingId })

  // If the building ID changes, we need to refetch the benchmarks and markers.
  useEffect(() => {
    if (buildingId) {
      refetchBenchmarksData({ buildingId })
      refetchMarkersData({
        accountId: organizationId,
        filter: { buildingIds: { contains: buildingId } }
      })
    }
  }, [buildingId])

  // If the markers or benchmarks data is fetched, we "reset" everything to back on.
  // @TODO: Refactor to named function and shove down to the bottom.
  useEffect(() => {
    if (markersData) {
      // Make sure they are all toggled on if the data is fetched.
      const toggledMarkers = markersData.map((m) => ({...m, checked: true}))
      onMarkersUpdate(toggledMarkers)
    }
  }, [markersData])

  useEffect(() => {
    if (benchmarksData) {
      const toggledBenchmarks = benchmarksData.map((b) => ({...b, checked: true}))
      onBenchmarksUpdate(toggledBenchmarks)
    }
  }, [benchmarksData])

  return {
    markersData,
    benchmarksData,
    refetchMarkersData,
    refetchBenchmarksData
  }
}