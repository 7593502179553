import { GET_PRE_SIGNED_URL } from 'src/common/queries/attachment'
import { useMutation } from 'src/hooks/APIHooks'
import { saveAs } from 'file-saver'

export const useAttachment = () => {
  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })

  const addFileFunc = async (e: any, buildingId) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "UPLOAD", "key": "${e.name}",  "buildingId": "${buildingId}"}`
      })
        .then(async (res) => {
          const requestOptions: RequestInit = {
            method: 'PUT',
            body: e,
            redirect: 'follow',
            headers: { 'Content-Type': '' } // image/png
          }
          return await fetch(
            JSON.parse(res.data.getPreSignedUrl).body,
            requestOptions
          ).catch((error) => console.log('error', error))
        })
        .catch((error) => console.log('error', error))
    } catch(error){

    }
    
  }

  const downloadFileFunc = async (name, buildingId) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "DOWNLOAD", "key": "${name}", "buildingId": "${buildingId}" }`
      }).then(async (res) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            return await saveAs(responseBlob, name)
          })
          .catch((error) => console.log('error', error))
      })
    } catch(error){

    }

  }
  const downloadFileURL = async (name, buildingId, tempFolder = false) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "${
          tempFolder ? 'TEMP_DOWNLOAD' : 'DOWNLOAD'
        }", "key": "${name}", "buildingId": "${buildingId}" }`
      }).then(async (res) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            return URL.createObjectURL(responseBlob)
          })
          .catch((error) => console.log('error', error))
      })
    } catch(error){

    }
  
  }

  const copyFileFunc = async (name, oldBuildingId, newBuildingId, newName = null) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "${
          'DOWNLOAD'
        }", "key": "${name}", "buildingId": "${oldBuildingId}" }`
      }).then(async (res) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            const filedetails = new File([responseBlob], newName ? newName : name)          
            return addFileFunc(filedetails, newBuildingId)
          })
          .catch((error) => console.log('error', error))
      })
    } catch(error){
      
    }

  }

  const previewFileFunc = async (name, buildingId) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "DOWNLOAD", "key": "${name}", "buildingId": "${buildingId}" }`
      }).then(async (res) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            const fileExtension = name.split('.').pop().toLowerCase();
            switch (fileExtension) {
              case 'png':
              case 'jpg':
              case 'jpeg':
                return URL.createObjectURL(responseBlob)
              case 'doc':
              case 'docx':
                return 'doc'
              case 'pdf':
                return 'pdf'
              default:
                return 'doc'
            }
          })
          .catch((error) => console.log('error', error))
      })
    } catch (error) {
      console.log('error', error)
    }
  }


  return { addFileFunc, downloadFileFunc, downloadFileURL, copyFileFunc, previewFileFunc }
}
