export class BuildingLocation {
  locationId: string
  locationName: string

  constructor(resource: any = {}) {
    const {
      locationId,
      locationName
    } = resource

    this.locationId = locationId
    this.locationName = locationName
  }
}