export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
  $sort: [SearchableBuildingSortInput]
) {
    searchBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        name
        accountId
        accountName
        salesOfficeId
        salesOfficeName
        id
        isActive
      }
      nextToken
      total
    }
  }`

export const SEARCH_ACCOUNTS = `query SEARCH_ACCOUNTS(
    $filter: SearchableAccountFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableAccountSortInput]
  ) {
      searchAccounts(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sort: $sort
      ) {
        items {
          name
          id
          isActive
          buildings(filter: {isActive: {eq: 1}}) {
            items {
              name
              id
            }
          }
        }
        nextToken
        total
      }
    }`
  
export const SEARCH_SALES_OFFICES = `query SEARCH_SALES_OFFICES(
      $filter: SearchableSalesOfficeFilterInput
      $limit: Int
      $nextToken: String
      $sort: [SearchableSalesOfficeSortInput]
    ) {
        searchSalesOffices(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sort: $sort
        ) {
          items {
            name
            id
            state
            code
          }
          nextToken
          total
        }
      }`
    

export const LIST_USER_NOTIFICATIONS = `query LIST_USER_NOTIFICATIONS(
    $userId: ID!,
    $nextToken: String
  ) {
    listNotificationSettingByUser(userId: $userId,nextToken:$nextToken) {
      nextToken
      items {
        accountId
        buildingId
        contactId
        deviceId
        equipmentId
        name
        id
        salesOfficeId
        type
        userId
        settings {
          nMin
          nRestore
          nStatus
          nType
          remMin
        }
      }
    }
    }`

export const generatePreLoadSelectedBuildingsQuery = (buildingIds = [], salesOfficeIds, accountIds) => {

  const buildingIdQuery =  buildingIds && buildingIds.length ? `query BUILDINGS {
    searchBuildings(
      filter: {or: [${buildingIds.map(e => `{id: {eq: "${e}"}},`)}]}
      limit: 1000
    ) {
      items {
        name
        accountId
        accountName
        salesOfficeId
        salesOfficeName
        id
      }
    }
  }` : null

  const salesOfficeIdQuery = salesOfficeIds && salesOfficeIds.length ? `query SALES_OFFICES {
    searchSalesOffices(
      filter: {or: [${salesOfficeIds.map(e => `{id: {eq: "${e}"}},`)}]}
      limit: 1000
    ) {
      items {
        name
        id
      }
    }
  }` : null

  const organizationIdQuery = accountIds && accountIds.length ? `query ORGANIZATIONS {
    searchAccounts(
      filter: {or: [${accountIds.map(e => `{id: {eq: "${e}"}},`)}]}
      limit: 1000
    ) {
      items {
        name
        id
      }
    }
  }` : null

  return [buildingIdQuery, salesOfficeIdQuery, organizationIdQuery]

}

export const UPDATE_OWN_SELF = /* GraphQL */ `
  mutation UPDATE_OWN_SELF($requestBody: AWSJSON!) {
    mutateOwnUser(requestBody: $requestBody)
  }
`