import React from 'react'
import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
import PropTypes from 'prop-types'

// components
import { MultipleFileUploader } from 'src/components/legacy/components/multi-file-upload/multi-file-upload'
import { isAllowedFileType } from 'src/components/legacy/components/file-select-sc/file-select.sc'
import { ACTIONS } from './common'
import { withTranslation } from 'react-i18next'
import SupportingDocuments from './supporting-documents'
import translate, { TranslateComponent } from '../../common/translations'
import dropZoneStyles from 'src/denali-components/DropZone/drop-zone.module.scss'

export const AttachedFilesContainer = styled.section`
  border-radius: 3px;
  border: 1px solid ${colors.TBA_DARK2_GREY};
  display: flex;
  flex-direction: column;
  max-height: ${(props) => props.maxHeight};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 14px;
`

const MAX_FILE_SIZE = 50
const MAX_FILE_SIZE_MULTIPLIER = 1024 * 1024
const PROPOSAL_TYPE_FILE = [
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'docb',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'ppt',
  'pot',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppsx',
  'ppsm',
  'sldx',
  'sldm',
  'jpg',
  'png',
  'gif',
  'tif',
  'eps',
  'bmp',
  'svg',
  'webp',
  'aif',
  'm4a',
  'mp3',
  'mpa',
  'wav',
  'wma',
  'm4v',
  'mov',
  'mp4',
  'mpg',
  'wmv',
  'pdf',
  'txt',
  'rtf',
  'csv',
  'jpeg'
]

const AttachedFiles = (props) => {
  const { t, downloadFileFunc } = props
  const uploadMethod = (file) => {
    const { addFile } = props
    return addFile(file).then((result) => {
      if (result && result.statusCode && result.statusCode !== 200) {
        throw result.reason || result.statusText
      }
      return result
    })
  }

  const validate = async (bag) => {
    const { attachments, onBeforeReplaceAttachment, proposalFileTypes } = props
    const { files: newFiles } = bag
    let isWrongSize = false
    let isWrongType = false
    let isDoubleExtention = false
    let isTheSameFileName = false
    let isLongFileName = false
    let countDeleted = 0

    newFiles.forEach((file) => {
      isWrongSize =
        isWrongSize || file.size > MAX_FILE_SIZE * MAX_FILE_SIZE_MULTIPLIER
      isWrongType =
        isWrongType ||
        !isAllowedFileType(file.name, proposalFileTypes || PROPOSAL_TYPE_FILE)
      isDoubleExtention = file.name.split('.').length - 1 > 1
      isLongFileName = file.name.length > 255
      isTheSameFileName =
        isTheSameFileName ||
        attachments.some(
          (attachment) =>
            attachment.title === file.name && !attachment.shouldBeDeleted
        )
    })
    attachments.forEach((files) => {
      files.shouldBeDeleted ? countDeleted++ : countDeleted
    })
    if (newFiles.length > 0 && (attachments.length + newFiles.length) > 10) {
      return <TranslateComponent>The maximum number of files has been reached. You cannot upload more than 10 files</TranslateComponent>
    }
    if (!newFiles.length) {
      return null
    } else if (attachments.length - countDeleted > 9) {
      return <TranslateComponent>The maximum number of files has been reached. You cannot upload more than 10 files</TranslateComponent>
    } else if (isWrongSize) {
      return <TranslateComponent>{`File exceeds file size restrictions. Please try again with a file less than ${MAX_FILE_SIZE}MB.`}</TranslateComponent>
    } else if (isWrongType) {
      return <TranslateComponent>File type is not allowed. Please use an accepted file type.</TranslateComponent>
    } else if (isDoubleExtention) {
      return <TranslateComponent>The file name is not valid. A file name cannot contain a special character dot(.)</TranslateComponent>
    } else if (isLongFileName) {
      return <TranslateComponent>File name exceeds length restrictions. Please try again with file name less than 255 characters.</TranslateComponent>
    } else if (isTheSameFileName) {
      return new Promise((resolve) => {
        onBeforeReplaceAttachment?.((result) => {
          resolve(result)
        })
      })
    }
    return null
  }

  const {
    allowMultipleFiles,
    maxHeight,
    attachments,
    mode,
    onDeleteAttachment,
    showDownloadButton,
    isDownloadEnabled,
    onDescriptionChange,
    onChangeUploadStatus,
    onCancelUploadAttachment,
    fieldsToDisplay,
    tooltipRenderNode,
    proposalFileTypes,
    showCloseIcon,
    showOnlyAcceptableFileDesc,
    errors,
    uiMode,
    required
  } = props
  return (
    <AttachedFilesContainer maxHeight={maxHeight}>
      {mode !== ACTIONS.VIEW || errors?.duplicate ? (
        <>
          <span className={dropZoneStyles.dropZoneLabel}><TranslateComponent>Attach Files</TranslateComponent>{required && '*'}</span>
          <MultipleFileUploader
            allowedFileTypes={proposalFileTypes || PROPOSAL_TYPE_FILE}
            allowMultipleFiles={allowMultipleFiles}
            disabled={false}
            note={translate(`Files cannot be greater than ${MAX_FILE_SIZE}MB.`)}
            onChangeUploadStatus={onChangeUploadStatus}
            validate={validate}
            uploadMethod={(bag) => uploadMethod(bag)}
            showOnlyAcceptableFileDesc={showOnlyAcceptableFileDesc}
            uiMode={uiMode}
          />
        </>
      ) : (
        <></>
      )}
      {attachments && attachments.length ? (
        <>
          <span className={dropZoneStyles.dropZoneLabel}><TranslateComponent>Attached Files</TranslateComponent></span>
          <SupportingDocuments
            mode={mode}
            data={attachments}
            downloadFileFunc={(e) => downloadFileFunc(e)}
            onCancelUploadAttachment={onCancelUploadAttachment}
            onDeleteAttachment={onDeleteAttachment}
            onDescriptionChange={(e) => onDescriptionChange(e)}
            fieldsToDisplay={fieldsToDisplay}
            tooltipRenderNode={tooltipRenderNode}
            showDownloadButton={showDownloadButton}
            isDownloadEnabled={isDownloadEnabled}
            showCloseIcon={showCloseIcon}
            uiMode={uiMode}
          />
        </>
      ) : (
        <></>
      )}
    </AttachedFilesContainer>
  )
}

AttachedFiles.defaultProps = {
  maxHeight: '600px',
  files: [],
  allowMultipleFiles: true
}

AttachedFiles.propTypes = {
  /** Restrict multiple files*/
  allowMultipleFiles: PropTypes.bool,
  /** The max height of the chart history container */
  maxHeight: PropTypes.string,
  /** dialog mode one of (ADD, EDIT, VIEW) */
  mode: PropTypes.string,
  onBeforeReplaceAttachment: PropTypes.func,
  onDeleteAttachment: PropTypes.func,
  showDownloadButton: PropTypes.bool,
  isDownloadEnabled: PropTypes.bool,
  onCancelUploadAttachment: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  onChangeUploadStatus: PropTypes.func,
  /** The list of files to be shown */
  attachments: PropTypes.array,
  addFile: PropTypes.func,
  fieldsToDisplay: PropTypes.arrayOf(PropTypes.string),
  tooltipRenderNode: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(Element)
  ]),
  /** Accepted file types list */
  proposalFileTypes: PropTypes.array,
  /** Show cancel icon after file upload */
  showCloseIcon: PropTypes.bool
}

export default withTranslation()(AttachedFiles)
