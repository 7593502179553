import { BuildingLocation } from "./location"

export class Document {
  documentName: string
  documentType: string
  documentStatus: string
  visibility: boolean
  location?: BuildingLocation
  createdBy: string
  createdDate: string
  note: string
  attachments: DocumentAttachment[]
  description: string
  startDate: string

  constructor(resource: any = {}) {
    const {
      documentName,
      documentType,
      documentStatus,
      visibility,
      location,
      createdBy,
      createdDate,
      note,
      attachments,
      description,
      startDate
    } = resource

    this.documentName = documentName
    this.documentType = documentType
    this.documentStatus = documentStatus
    this.visibility = visibility
    this.location = location
    this.createdBy = createdBy
    this.createdDate = createdDate
    this.note = note
    this.attachments = attachments
    this.description = description
    this.startDate = startDate
  }
}

export type DocumentAttachment = {
  id: string
  buildingId: string
  name: string
  displayName: string
  type: string
  sizeInBytes: number
  description: string
  title: string
  category: string
  attachmentOrder: number
  attachedAt: string
}
