import { ButtonGroup, Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from './table.module.scss'
import { Filter } from './Filter'
import translate from 'src/common/translations'
import { SortMenu } from './SortMenu'
import { DenaliTableStyle } from './table.d'

export const TableHeader = ({
  search,
  filters,
  onSearchChange,
  onFilterChange,
  header,
  selectedColumn,
  isAscending,
  handleSort,
  denaliTableStyle=DenaliTableStyle.cards,
  bulkActions
}) => {

 const searchTranslate = translate("Search")
  return (
    <div className={styles.header}>
      <div className={styles.optionsContainer}>
        {denaliTableStyle === DenaliTableStyle.cards && 
          <div className={styles.sort}>
            <ButtonGroup size="small">
              <SortMenu
                header={header}
                handleSort={handleSort}
                selectedColumn={selectedColumn}
                isAscending={isAscending}
              />
            </ButtonGroup>
          </div>
        }

        {filters && filters.length && (
          <div className={styles.filters}>
            <ButtonGroup size="small">
              {filters?.map((filter) => {
                return (
                  <Filter
                    key={filter.id}
                    filter={filter}
                    onChange={onFilterChange}
                  />
                )
              })}
            </ButtonGroup>
          </div>
        )}
      </div>

      {search && (
        <div className={`${styles.search} ${bulkActions ? styles.searchWithBulkActions : ''}`}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <Input
            id="search"
            placeholder={searchTranslate}
            aria-description="Search"
            type="search"
            onChange={onSearchChange}
          />
        </div>
      )}
      {bulkActions && (
        <div className={styles.bulkActions}>
          {bulkActions}
        </div>
      )}
    </div>
  )
}
