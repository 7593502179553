import { useSelector } from 'react-redux'

// Project imports
import { getSearchParams } from "src/common/helperFunctions.js"
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'

// Denali imports
import { AddDocumentContainer } from "src/denali-pages/Reports/AddDocumentContainer"

// Local imports
import Documents from "./AddDocuments"
import EditEnergyUsageReport from "./EUReport/EditEUReport"

const CreateEditDocumentAndReport = ({ mode }) => {
	const { reportType } = getSearchParams()
  const uiMode = useSelector(selectUiMode)
  switch (true) {
    case (uiMode === UiMode.denali && !reportType):
      return <AddDocumentContainer mode={mode} uiMode={uiMode} />
    case (reportType === "VariableRefrigerantFlowReport"): // VRF Report
    // return <ViewVRFReport {...{ report, ...props }} setNotificationDialog={setNotificationDialog} />
    case (reportType === "EnergyUsageReport"): // Energy Usage
      return <EditEnergyUsageReport mode={mode} testParam="test params" />
    default:
      return <Documents mode={mode} />
  }
}

export default CreateEditDocumentAndReport