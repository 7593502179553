// Package imports
import { Flex } from '@aws-amplify/ui-react'
import moment from 'moment'
import { useCallback, useEffect } from 'react'

// Project imports
import { setEndDateForEnergyIntensityPages } from 'src/components/chartDateControls/config'
import { getSearchParams } from 'src/common/helperFunctions'
import Spinner from 'src/components/Spinner'
import Markers from 'src/pages/energyIntensity/Markers'
import { getTotalBill } from 'src/pages/utilityBillData/helpers'

// Denali component imports
import ErrorMessage from 'src/denali-components/ErrorMessage/ErrorMessage'
import PlotBarChartControls from 'src/denali-components/PlotChart/BarChart/PlotBarChartControls'

// Local imports
import styles from './energy-intensity.module.scss'
import { calculateChartMinMax, energyIntensityDataTransformation } from './helpers'
import { useEnergyIntensityState } from './hooks/use-energy-intensity-state'
import { useEnergyIntensityToggles } from './hooks/use-energy-intensity-toggles'
import { 
  ChartControlUpdateData,
  ChartDatesData,
  EnergyIntensityDateRange, 
  EnergyIntensityProps
} from './types'

// Local components
import { EnergyIntensityBarChart } from './EnergyIntensityBarChart'
import { EnergyIntensityLineChart } from './EnergyIntensityLineChart'
import { EnergyIntensityBenchmarksAndMarkersContextProvider } from './EnergyIntensityBenchmarksAndMarkersContext'
import { EnergyIntensityDatePickerDialog } from './EnergyIntensityDatePickerDialog'

// This the primary content for the Energy Intensity page. Will need to accept some props for things like
// chartData, markers, benchmarks, etc.
export const EnergyIntensity = ({
  chartData,
  chartDates,
  allChartDates,
  markers,
  benchmarks,
  onChartControlUpdate,
  testName = 'energyIntensityPage',
  isDefaultYAxis,
  customMinMax,
  updateChartMinMax,
  onMarkersUpdate,
  onBenchmarksUpdate
}: EnergyIntensityProps) => {
  const { buildingId, organizationId } = getSearchParams()

  const { 
    ranges, 
    setRanges,
    activeRange, 
    setActiveRange, 
    chartView, 
    setChartView, 
    euiEnabled, 
    setEuiEnabled, 
    eciEnabled, 
    setEciEnabled, 
    isModalOpen, 
    setIsModalOpen, 
    sidebarOpen, 
    setSidebarOpen,
    onToggleMarker,
    onToggleBenchmark
  } = useEnergyIntensityToggles({ markers, benchmarks, onMarkersUpdate, onBenchmarksUpdate })

  const { benchmarksData } = useEnergyIntensityState({
    buildingId,
    organizationId,
    onMarkersUpdate,
    onBenchmarksUpdate
  })

  // If the benchmarks or chart data changes, we need to update the min and max values for the chart.
  // Doing it this way preserves BC with the old UI.
  useEffect(() => {
    // If we are using the default Y Axis values, we need to calculate the min/max values for the chart
    // when data is loaded.
    if (isDefaultYAxis && chartData && benchmarks) {
      // Calculate the min/max values for the chart.
      const minMaxValues = calculateChartMinMax({chartData, benchmarks})
      updateChartMinMax(minMaxValues)
    }
  }, [benchmarksData, chartData])

  // Update the active range for the chart. We take a string selection and then derive the dates based on that.
  // We pass the current chart dates for instances like "all" where we just use the entire range of data.
  // This syncs the data up to keep compatibility with the old UI.
  const onUpdateActiveRange = useCallback((range: string) => syncDatesUp(range, chartDates, allChartDates, setActiveRange, onChartControlUpdate), [chartDates])

  // If we use the date picker to custom pick dates then make sure to update the active range and the chart control.
  // This syncs the data up to keep compatibility with the old UI.
  const onDatesChange = useCallback((dates: EnergyIntensityDateRange) => {
    onChartControlUpdate({
      startDate: dates.startDate,
      endDate: dates.endDate,
      dateError: false
    })
  }, [])

  // Open the date picker dialog and set the active range to CUSTOM.
  const onShowDatePickerDialog = useCallback(() => {
    setActiveRange('CUSTOM')
    setIsModalOpen(true)
  }, [])

  // Are we waiting for a building to be selected?
  const waitingForBuilding = !buildingId && organizationId

  if (waitingForBuilding) {
    return (
      <ErrorMessage>
        <p>
          Organizational EUI/ECI indices are not yet available, but coming soon!
          Please select a building to see its EUI/ECI score.
        </p>
      </ErrorMessage>
    )
  }

  // If we are waiting for data to finished being massaged.
  const waitingForData = chartDates == null || chartData == null;
  if (waitingForData) {
    return <Spinner data-testid="energy-intensity-spinner" />
  }

  const start = moment(chartDates?.chartStartDate).toDate()
  const end = moment(chartDates?.chartEndDate).toDate()
  const data = energyIntensityDataTransformation({
    chartData,
    startDate: start,
    endDate: end
  })

  // If the difference between the start and end dates is greater than 1 year, let's use a condensed date format.
  const diffTime = Math.abs(end.getTime() - start.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  // Now let's calculate the total energy cost using the formula from the Utility Bill Data page.
  // Filter the list to only what's within the chart dates.
  const filteredChartData = chartData?.filter((data) => {
    return moment([data?.year, data?.month]).isBetween(start, end, 'month', '[]')
  })
  const totalEnergyCost = filteredChartData?.reduce((sum, data) => sum + getTotalBill(data), 0);

  return (
    <>
      <EnergyIntensityBenchmarksAndMarkersContextProvider 
        markers={markers} 
        benchmarks={benchmarks} 
        onToggleMarker={onToggleMarker}
        onToggleBenchmark={onToggleBenchmark}
        totalEnergyCost={totalEnergyCost}
        chartDates={chartDates}
        dateRange={activeRange}
      >
        <Flex
          direction="row"
          gap={16}
          justifyContent="space-between"
          alignItems="flex-start"
          data-testid={testName}
        >
          <div className={styles.energyIntensityChart}>
            <div className={styles.energyIntensityChartControls}>
              <PlotBarChartControls
                testName={testName}
                activeRange={activeRange}
                onUpdateActiveRange={onUpdateActiveRange}
                rangesList={ranges}
                onUpdateDateRanges={setRanges}
                chartViewType={chartView}
                onChangeChartViewType={setChartView}
                onShowDatePickerDialog={onShowDatePickerDialog}
              />
            </div>
            {chartView == 'bar' 
              ? <EnergyIntensityBarChart
                  chartData={data}
                  chartDates={chartDates}
                  euiEnabled={euiEnabled}
                  eciEnabled={eciEnabled}
                  diffDays={diffDays}
                  benchmarks={benchmarks}
                  benchmarksEnabled={new Set([])}
                  markers={markers}
                  markersEnabled={new Set([])}
                  isDefaultYAxis={isDefaultYAxis}
                  customMinMax={customMinMax}
                /> 
              : <EnergyIntensityLineChart
                  chartData={data}
                  chartDates={chartDates}
                  euiEnabled={euiEnabled}
                  eciEnabled={eciEnabled}
                  diffDays={diffDays}
                  benchmarks={benchmarks}
                  benchmarksEnabled={new Set([])}
                  markers={markers}
                  markersEnabled={new Set([])}
                  isDefaultYAxis={isDefaultYAxis}
                  customMinMax={customMinMax}
                />
            }
          </div>
          <Markers
            chartDates={chartDates}
            onMarkersUpdate={onMarkersUpdate}
            onBenchmarksUpdate={onBenchmarksUpdate}
            toggleBenchmarks={onToggleBenchmark}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            euiEnabled={euiEnabled}
            eciEnabled={eciEnabled}
            setEuiEnabled={setEuiEnabled}
            setEciEnabled={setEciEnabled}
          />
        </Flex> 
        {isModalOpen && <EnergyIntensityDatePickerDialog isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onDatesChange={onDatesChange} chartDates={chartDates} />}
      </EnergyIntensityBenchmarksAndMarkersContextProvider>
    </>
  )
}

export default EnergyIntensity

const syncDatesUp = (
  range: string, 
  chartDates: ChartDatesData, 
  allChartDates: ChartDatesData, 
  setActiveRange: (range: string) => void, 
  onChartControlUpdate: (dates: ChartControlUpdateData) => void
) => {
  const props = {
    activeRange: range,
    chartStartDate: chartDates?.chartStartDate,
    chartEndDate: chartDates?.chartEndDate,
    allDates: allChartDates,
  }
  const dates: EnergyIntensityDateRange = setEndDateForEnergyIntensityPages(props)
  setActiveRange(range)
  onChartControlUpdate({
    startDate: dates.startDate,
    endDate: dates.endDate,
    dateError: false
  })
}