import { useBreakPoint } from 'src/hooks/breakPoint'
import { Content } from './layouts'

type ContentProps = {
  border?: string
  radius?: string
  background?: string
  xlColumn?: number
  lColumn?: number
  sColumn?: number
  mColumn?: number
  sRow?: number
  mRow?: number
  lRow?: number
  xlRow?: number
  display?: string
  children?: React.ReactNode
}
const ContentWrapper = (props: ContentProps) => {
  const { width } = useBreakPoint()
  const {
    border,
    radius,
    xlColumn,
    sColumn,
    lColumn,
    mColumn,
    sRow,
    mRow,
    lRow,
    xlRow,
    background,
    display,
    children
  } = props
  return (
    <Content
      border={border}
      radius={radius}
      background={background}
      sColumn={sColumn}
      mColumn={mColumn}
      lColumn={lColumn}
      xlColumn={xlColumn}
      sRow={sRow}
      mRow={mRow}
      lRow={lRow}
      xlRow={xlRow}
      width={width}
      display={display}
    >
      {children}
    </Content>
  )
}

export default ContentWrapper
