import React from 'react'
import PropTypes from 'prop-types'
import { UiMode } from 'src/redux/types/AppTypes'

/* videoAvailability is a json object that lists all inline help videos currently available in the S3 ui-protected-assets its respective total time as its value */
import getAllVideos from '../../../legacy/common/videos/connect-videos.js'
import { toTitleCase } from '../../../legacy/common/helpers'
import { connect } from 'react-redux'

// lodash
import get from 'lodash/get'

// components
import GeneralModal from '../../../legacy/components/general-modal/general-modal'
import VideoThumbnail from '../../../legacy/components/videos/thumbnail.jsx'

// styles
import './inline-help.scss'
import i18next from 'i18next'

import { Trans } from 'react-i18next'
import translate, { TranslateComponent } from "src/common/translations"
import { DenaliInlineHelp } from 'src/denali-components/Help/DenaliInlineHelp'

export class InlineHelp extends React.PureComponent {
  static propTypes = {
    inlineHelpText: PropTypes.array,
    inlineHelpHeading: PropTypes.string,
    videoSrc: PropTypes.string,
    isTerms: PropTypes.bool,
    // global redux user roles prop
    roles: PropTypes.array.isRequired,
    page: PropTypes.string
  }

  static defaultProps = {
    inlineHelpText: [],
    isTerms: true,
    inlineHelpHeading: ''
  }

  state = {
    modalOpen: false
  }

  constructor(props) {
    super(props)

    this.state.currentRoute = props.page
    ;(this.state.currentVideo =
      Object.entries(getAllVideos(this.props.roles))
        .map(([videoName, videoInfo]) => ({ videoName, ...videoInfo }))
        .filter(
          (video) => video.isAvailable && video.videoName === props.page
        )[0] || null),
      (this.state.heading =
        i18next.t(
          get(getAllVideos(this.props.roles), props.page, { title: '' }).title
        ) || props.inlineHelpHeading)
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  getVideoBlock = () => {
    const { currentVideo } = this.state

    const videoDescription = i18next.t('components:inlineHelp>VideoDescription') // will add this once Lorem Ipsum copy is updated: currentVideo.description
    const title =
      toTitleCase(currentVideo.title) || i18next.t('dashboard:Dashboard')

    return (
      <div className="help-block">
        <h1>
          {i18next.t('components:inlineHelp>IntroductionTo', {
            title: i18next.t(currentVideo.title)
          })}
        </h1>
        <div className="video-panel-top">
          <VideoThumbnail
            thumbnail={`${currentVideo.videoName}-thumbnail.png`}
            src={`${currentVideo.videoName}.mp4`}
            title={title}
            description={videoDescription}
            length={`${currentVideo.length}`}
          />
        </div>
      </div>
    )
  }

  getHelpContents = () => (
    <div className="inline-help-main">
      <div className="help-block">
        <h1>
          {this.props.isTerms
            ? <TranslateComponent>Terms you need to know</TranslateComponent>
            : <TranslateComponent>Using this feature</TranslateComponent>}
        </h1>
        <div
          className={`inline-help-container inline-help-column-${Math.min(
            this.props.inlineHelpText.length,
            3
          )}`}
        >
          {this.props.inlineHelpText.map((e, i) => {
            const text = typeof e.body === 'function' ? e.body() : e.body
            return (
              <div key={i} className="inline-help-block">
                <div className="inline-help-heading">{e.heading}</div>
                {React.isValidElement(text) ? ( // if Trans passed
                  <div>{text}</div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                )}
              </div>
            )
          })}
        </div>
      </div>
      {this.state.currentVideo &&
        this.state.currentVideo.isAvailable &&
        this.getVideoBlock()}
      <div className="inline-help-divider" />
      <div className="further-assistance-container">
        <div className="further-assistance-text">
          <b>{<TranslateComponent>Need Further Assistance?</TranslateComponent>}</b>
          <span className="contact-support">
            <div>
              <TranslateComponent>Contact our Support Staff using the</TranslateComponent>{' '}
              <a
                onClick={() => {
                  this.toggleModal()
                  document
                    .querySelector('.menu .nav-dropdown:first-child button')
                    .click()
                }}
              >
                <TranslateComponent>Support/Feedback</TranslateComponent>
              </a>{' '}
              <TranslateComponent> menu located in the upper right corner of the page.</TranslateComponent>
              </div>
          </span>
        </div>
      </div>
    </div>
  )

  render = () => {
    const { modalOpen, heading } = this.state

    const modalConfig = {
      buttons: [
        {
          text: <TranslateComponent>Close</TranslateComponent>,
          handleClick: this.toggleModal,
          type: 'cancel'
        }
      ],
      heading: <TranslateComponent>Help</TranslateComponent>,
      panelOneClass: 'col-lg-1 col-md-2',
      panelTwoClass: 'col-md-3 col-lg-3',
      className: 'main-general-modal wide-modal',
      toggleModal: this.toggleModal,
      showObjectPicker: false,
      showPanelOne: false,
      tabs: [
        {
          header: <TranslateComponent>Overview</TranslateComponent>,
          content: this.getHelpContents()
        }
      ]
    }

    if (this.props.uiMode === UiMode.denali) {
      return (
        <DenaliInlineHelp help={this} modalOpen={modalOpen} />
      )
    }

    return (
      <span>
        <button
          className="icon-help tooltip-container"
          onClick={() => {
            this.toggleModal()
          }}
        />
        {modalOpen ? <GeneralModal {...modalConfig} /> : null}
      </span>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    page: state.appData.userInfo.pages,
    roles: state.appData.userInfo.data?.roles ?? []
  }
}

export default connect(mapStateToProps, {})(InlineHelp)
