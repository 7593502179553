import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faZap,
  faDollar,
  faLeaf,
  faAtomSimple
} from 'src/denali-components/lib/pro-solid-svg-icons'
import styles from 'src/denali-pages/Opportunities/opportunities.module.scss'
import { CardDetails } from 'src/denali-components/Cards/CardDetails/CardDetails'

interface DetailCostSavingsProps {
  costSavings: {
    enabled: boolean
    value?: number
    tag?: string
  }
  costToImplement: {
    enabled: boolean
    value?: number
    priceType?: string
  }
  simpleROI?: {
    enabled: boolean
    value?: number
    priceType?: string
  }
  simplePayback?: {
    enabled: boolean
    value?: number
  }
  showPotentialSavings: boolean
  potentialSavings?: {
    value: number
    date?: string
  }
}

export const DetailCostSavings = ({
  costSavings,
  costToImplement,
  simpleROI,
  simplePayback,
  showPotentialSavings,
  potentialSavings,
}: DetailCostSavingsProps) => {

  return (
    <div className={styles.detailRow}>
      {costSavings?.enabled && <CardDetails
        style='energy-savings'
        title='Energy Savings'
        icon={<FontAwesomeIcon icon={faZap} />}
        primary={costSavings?.value}
        primaryFormat='USD'
        // chip='38.70%' // TODO: This is a placeholder
        chipIcon={faArrowUp}
        disclaimer={formatTag(costSavings?.tag)}
      />}
      {costToImplement?.enabled && <CardDetails
        title='Price'
        icon={<FontAwesomeIcon icon={faDollar} />}
        iconColor='blue'
        primary={costToImplement?.value}
        primaryFormat='USD'
        disclaimer={costToImplement?.priceType}
      />}
      {simpleROI?.enabled && <CardDetails
        title='Simple ROI'
        icon={<FontAwesomeIcon icon={faLeaf} />}
        iconColor='green'
        primary={simpleROI?.value}
        primaryFormat='percent'
        // TODO: Double-check where does this value come from?
        disclaimer={costToImplement?.priceType}
      />}
      {/* No idea if this is right */}
      {simplePayback?.enabled && <CardDetails
        title='Simple Payback'
        icon={<FontAwesomeIcon icon={faLeaf} />}
        iconColor='green'
        primary={simplePayback?.value}
        primaryFormat='year'
        // TODO: Double-check where does this value come from?
        disclaimer={costToImplement?.priceType}
      />}
      {showPotentialSavings && <CardDetails
        title='Potential Savings'
        icon={<FontAwesomeIcon icon={faAtomSimple} />}
        iconColor='orange'
        primary={potentialSavings?.value}
        primaryFormat='USD'
        disclaimer={`If implemented on ${potentialSavings?.date}`}
      />}
    </div>
  )
}

const formatTag = (tag) => {
  switch (tag) {
    case 'weeks':
      return 'Per Week'
    case 'months':
      return 'Per Month'
    case 'years':
      return 'Per Year'
    case 'total':
      return 'Total'
    default:
      return 'Per Month'
  }
}
