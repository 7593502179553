import { IconCell, IconSize } from "src/denali-components/Table/IconCell"
import styles from "./reports.module.scss"
import { Chips } from "src/denali-components/Table/Chips"
import { Chip } from "src/denali-components/Table/Chip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { TranslateComponent } from "src/common/translations"
import { ChipStatus } from "src/denali-components/Table/ChipStatus"
import { UserNameBubble } from "src/denali-components/UserNameBubble/UserNameBubble"
import moment from "moment"
import { type FullDocument } from "src/pages/documentsAndReports/graphql"

export const DocumentSummary = ({document}:DocumentSummaryProps) => {
  if(!document) return <></>;
  
  return (
    <>
      <div className={styles.documentSummary}>
        <IconCell iconType="Document" iconSize={IconSize.fortyFour} />
        <h3 className={styles.documentModalTitle}>{document.title}</h3>
        {document?.note &&
          <p className="body-md">{document?.note}</p>
        }

        <Chips className={styles.documentModalChips}>
          {document?.status &&
            <ChipStatus statusText={document.status} />
          }
          <Chip>
            { document?.visibility === true && 
              <>
                <FontAwesomeIcon icon={faEye} />
                <TranslateComponent>Visible</TranslateComponent>
              </>
            }
            { document?.visibility !== true && // May be null or false depending if the toggle in the old UI was modified
              <>
                <FontAwesomeIcon icon={faEyeSlash} />
                <TranslateComponent>Not Visible</TranslateComponent>
              </>
            }
          </Chip>
          {document?.building?.name &&
            <Chip text={document?.building?.name} />
          }
          {document?.type &&
            <Chip text={document?.type} />
          }
        </Chips>

        {document.contact &&
          <UserNameBubble
            showFullName
            firstName={document.contact}
            lastName=''
            subLine={
              document.createdAt
                ? ( <><TranslateComponent>Created on </TranslateComponent>{moment(document.startDate).format('LL')}</> )
                : null
            }/>
        }
        {!document.contact && document.createdAt &&
          <><TranslateComponent>Created on </TranslateComponent>{moment(document.startDate).format('LL')}</>
        }
      </div>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const formatNote = (note) => {
  // TODO: will remove 
  // this function was an attempt to allow rtf and string data to be compatible in the same UI
  // will know if we need to pursue this further in the near future.
  if (typeof note !== 'string') {
    return ''
  }

  if (!note.includes('text=')) {
    return note
  }

  const content = [...note.matchAll(/text=([\s\S]*?)(?=, type=|$)/g)]
  const joinedContent = content.map(match => match[1].trim()).join(' ')

  return joinedContent
}



export type DocumentSummaryProps = {
  document: FullDocument
}